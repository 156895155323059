import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Container = styled(Box)`
  display: flex;
  align-items: stretch;
  gap: 0;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1;
`;

export const Overlay = styled.div`
  border: none;
  outline: none;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #572580;
  opacity: 0.7;
  transition: 0.7s all ease-in;
  z-index: 107;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0;
  background: white;
  overflow: hidden;
  border-radius: 15px;

  input {
    line-height: 22px;
    background: transparent;
    position: relative;
    border: 1px solid transparent;
    width: 100%;
    font-size: 12px;
    padding: 12px;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    color: rgba(153, 153, 153, 0.8);

    &::placeholder {
      color: rgba(153, 153, 153, 0.8);
    }
  }

  .animation {
    transform: translate(-100%, 0);
    transition: 0.3s;
    transition-delay: 0.2s;
  }

  .animation-scale {
    transform: scale(0.5);
    transition: 0.3s;
    transition-delay: 0.3s;
  }

  input {
    transition: 0.1s all ease-in;
    transition-delay: 0.67s;
  }

  button[type="submit"] {
    transition: 0.1s all ease-in;
    transition-delay: 0.67s;
  }

  .search-btn {
    border-radius: 15px;
    border-radius: 0;
  }

  &.open {
    z-index: 203;

    input {
      border-radius: 0px;
      border-bottom: 1px solid #f1f1f1;
    }

    .animation {
      transform: translate(0, 0);
    }

    .animation-scale {
      transform: scale(1);
    }

    &.rounded-border {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;
