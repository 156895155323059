import Select from "react-select";
import { DocumentSelectType, SelectDocumentTypeProps } from "./types";

const options: DocumentSelectType[] = [
  {
    id: 1, type: 'cpf', label: 'CPF',
  },
  {
    id: 2, type: 'passport', label: 'PASSAPORTE',
  }
]

export const SelectDocumentType = ({
  id,
  label,
  error,
  value,
  handleChange,
  placeholder,
  name,
  disabled
}: SelectDocumentTypeProps) => {

  const getValue = (value: string) => {
    return options.filter((o) => value === o.type);
  }

  return (
    <div className={`field-holder ${error ? "field-holder-invalid" : ""}`}>
      <label
        htmlFor={id}
        className='text-gray text-semibold _purple'
      >
        {label}
      </label>
      <Select
        menuPosition="fixed"
        classNamePrefix="react-select"
        className={error ? "react-select input _opacity-gray react-select-error" : "react-select input _opacity-gray"}
        id={id}
        value={getValue(value)}
        onChange={(selectedOption) => {
          const event: any = {
            target: {
              name,
              value: selectedOption ? selectedOption.type : '',
            },
          };
          handleChange(event);
        }}
        options={options}
        placeholder={placeholder}
        isSearchable
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        getOptionValue={(option) => option.label}
        getOptionLabel={(option) => option.label}
        name={name}
        isDisabled={disabled}
      />
      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  )

}