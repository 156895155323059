import React from "react";
import InputMask from "react-input-mask";

import { getIn } from "formik";

const InputControlled = ({
  id,
  label,
  value,
  type,
  required,
  purple,
  multiline,
  onChange,
  onFocus,
  placeholder,
  mask,
  maskChar,
  autocomplete = "off",
  small,
  readOnly,
  maxLength,
  min,
  description,
  field,
  form,
  ...rest
}) => {
  const error = getIn(form.errors, field.name);
  const submited = getIn(form.submitCount);

  return (
    <div
      className={`
            field-holder 
            ${error && submited ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
        >
          {label}
          {description && (
            <div className="tooltip-holder on">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      {multiline ? (
        <textarea
          rows="30"
          cols="6"
          className="input textarea"
          name={id}
          id={id}
          placeholder={placeholder}
          value={value}
          required={required && "Obrigatório"}
          onChange={onChange}
          onFocus={onFocus}
          autoComplete={autocomplete}
          readOnly={readOnly}
          {...field}
         />
      ) : (
        <InputMask
          maskChar={maskChar || null}
          mask={mask}
          type={type}
          className="input _opacity-gray"
          name={id}
          id={id}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          required={required ? "Obrigatório" : ""}
          placeholder={placeholder}
          autoComplete={autocomplete}
          readOnly={readOnly}
          maxLength={maxLength}
          min={min}
          {...field}
          {...rest}
        />
      )}
      {error && submited > 0 && (
        <span className={`error-message ${!label}`}>{error}</span>
      )}
    </div>
  );
};

export default InputControlled;
