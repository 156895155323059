import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";
import { Box } from "styles/globalComponents";
import Warning from "Assets/Images/atention.svg";

interface ReScheduleModalProps {
  isOpen: boolean;
  closeModal: (openAnotherModal: boolean) => void;
}

export const ReScheduleModal = ({
  isOpen,
  closeModal
}: ReScheduleModalProps) => {


  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => closeModal(false)}
      modalIcon={Warning}
      renderHeading={() => (
        <h2 style={{ textAlign: 'center' }}>
          Reagendar atendimento
        </h2>
      )} 
      renderActions={() => (
        <Box display="flex" direction="column">

          <p style={{
            marginBottom: '-1.5em',
            color: 'var(--color-hgray-text)',
            textAlign: 'center',
            fontFamily: 'var(--font-text)',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '160%',
            margin: '0px',
          }}>
            <strong>Atenção:</strong> Você só poderá realizar um reagendamento de atendimento em cada pedido. Após remarcar para uma nova data, o agendamento atual será automaticamente excluído.
          </p>

          <Button width="full" minHeight="3.7em" fontWeight="600" onClick={() => closeModal(true)}>
            Selecionar Novo Horário
          </Button>

          <Button width="full" outlined minHeight="3.7em" fontWeight="600" onClick={() => closeModal(false)}>
            Voltar para Pedido
          </Button>
        </Box >
      )}
    />
  )

}