import React from 'react'



export default function SchedulesPacient({ type, data }) {
    return (
        <div className="history-schedules">
            <p className="history-title-schedule">{type}</p>

            <div className="history-schedules-before">
                {data.map(({calendar, date}, _) => (
                    <div className="history-container" key={_}>
                        <p> <strong>{date}</strong> - {calendar}</p>
                    </div>
                ))}

            </div>
        </div>
    )
}
