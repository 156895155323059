import { useHistory, useParams } from "react-router";

import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";

import AtentionIcon from "Assets/Images/atention.svg";

interface FormModalProps {
  isOpen: boolean;
  closeModal: (modal: "form") => void;
}

export const FormModal = ({ closeModal, isOpen }: FormModalProps) => {
  const { budget } = useBudgetContext();

  const { person_type } = useParams<{ person_type: string }>();

  const history = useHistory();

  const redirectToFillForm = () => {
    history.push(`/ficha-de-atendimento/finalizar-cadastro/${budget?.id}`, {
      personType: person_type,
    });
  };

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => closeModal("form")}
      modalIcon={AtentionIcon}
      renderHeading={() => <h2>Atenção!</h2>}
      renderDescription={() => (
        <p>
          Para realizar o pagamento, você precisa finalizar o cadastro. Deseja
          continuar?
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">
          {" "}
          <Button minHeight="48" width="full" onClick={redirectToFillForm}>
            FINALIZAR CADASTRO
          </Button>
        </Box>
      )}
    />
  );
};
