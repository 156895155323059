import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Covenant } from "Dtos/ICovenant";
import { PersonRequest, UpdateRequestPerson } from "Dtos/IPerson";

import {
  Combos,
  createCovenantCredentials,
  createPerson,
  updateCovenantCredentials,
  updatePerson as updatePersonRequest,
} from "Requests/Person";

import { selectPacient, setBudgetInfo } from "Actions/ServiceForm";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { handleRequestErrors } from "Utils/Errors";
import { createToast } from "Utils/toastFunc";

import { CovenantForm } from "./CovenantInputFields/interface";
import {
  Combos as ComboState,
  CovenantSendParams,
  FormRegisterValues,
  ModalClientProps as useModalClientProps,
} from "./interface";
import {
  arrangeClientInfosToSetForm,
  prepareDataToSendRequest,
  transformSomePersonInfo,
} from "./utils";

const combos = {
  countries: [],
  genders: [],
};

export const useModalClient = ({
  initialModalsState,
  resetModalClient,
  personInfo,
}: useModalClientProps) => {
  const { incompleteRegister, updateRegisterForm, selectCovenant } =
    initialModalsState;

  const { data = combos, isLoading } = useQuery<ComboState>(
    ["genders", "countries"],
    () =>
      Combos({
        combos: ["genders", "countries"],
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const [modals, setModals] = useState({
    incompleteRegister,
    updateRegisterForm,
    selectCovenant,
    updatedSuccessfully: false,
    covenantInfoOnly: false,
  });

  const [modalDynamicInfo, setModalDynamicInfo] = useState({
    heading: "",
    description: "",
  });

  const [loadingInfo, setLoadingInfo] = useState(false);

  const selectedCovenant = useRef<null | Covenant>(null);

  const clientInfo = useRef<FormRegisterValues>({} as FormRegisterValues);

  const { patientSelected, setCovenant } = useBudgetContext();

  const { isLead } = patientSelected;

  const history = useHistory();

  const dispatch = useDispatch();

  const headingIncompleteRegister = isLead
    ? "Cadastro incompleto"
    : "Cadastro sem dados de convênio";

  const buttonIncompleteRegister = isLead
    ? "Completar cadastro"
    : "Incluir dados de convênio";

  const openModal = (modal: string) => {
    const modalsOpts = {
      incompleteRegister: false,
      updateRegisterForm: false,
      updatedSuccessfully: false,
      selectCovenant: false,
      covenantInfoOnly: false,
    };
    modalsOpts[modal as keyof typeof modals] = true;
    setModals(modalsOpts);
  };

  const closeModal = (modal: string) => {
    setModals((prev) => ({ ...prev, [modal]: false }));
    resetModalClient();
  };

  const setCovenantId = (covenant: Covenant) => {
    selectedCovenant.current = covenant;
    setCovenant(covenant);
    dispatch(setBudgetInfo({ covenantInfo: covenant }));
  };

  const confirmSelectedCovenant = () => {
    if (selectedCovenant.current) {
      setModalDynamicInfo({
        heading: "Confirmar dados de convênio",
        description:
          "Por favor, revise e confirme os campos abaixo para prosseguir com o orçamento de convênio:",
      });
      openModal("covenantInfoOnly");
      return;
    }

    createToast("error", "Selecione um convênio para avançar.");
  };

  const includeNewCovenant = () => {
    selectedCovenant.current = null;
    setModalDynamicInfo({
      heading: "Cadastrar novo convênio",
      description:
        "Preencha os campos abaixo para finalizar a atualização do cadastro dessa pessoa e prosseguir com o orçamento de convênio:",
    });
    openModal("covenantInfoOnly");
  };

  const handleCovenantRegister = async (values: CovenantForm) => {
    try {
      const { id } = patientSelected;

      const { covenant, plan, plan_card_number } = values;

      const covenant_credentials = {
        covenant_id: covenant,
        covenant_plan_id: plan,
        credential_number: plan_card_number || null,
      };

      if (!selectedCovenant.current) {
        await createCovenantInfo(id, covenant_credentials);
      }
      if (selectedCovenant.current && covenant_credentials) {
        const { id: idCredential } = selectedCovenant.current ?? {};
        await updateCovenantInfo(id, covenant_credentials, idCredential);
      }
      openModal("updatedSuccessfully");
    } catch (error: any) {
      createToast("error", error.message);
    }
  };

  const createCovenantInfo = async (
    id: number,
    covenant_credentials: CovenantSendParams
  ) => {
    try {
      const covenants_credentials: Covenant = await createCovenantCredentials(
        id,
        covenant_credentials
      );

      setCovenantId(covenants_credentials);

      createToast("success", "Convênio salvo com sucesso!");
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível criar os dados de convênio.  ",
        throwError: true,
      });

      if (error.errors) {
        throw new Error(error.errors[0].message);
      }

      throw new Error("Não foi possível cadastrar este convênio!");
    }
  };

  const updateCovenantInfo = async (
    id: number,
    covenant_credentials: CovenantSendParams,
    idCredential: number
  ) => {
    try {
      const response: PersonRequest = await updateCovenantCredentials(
        id,
        covenant_credentials,
        idCredential
      );

      const { covenants_credentials } = response;

      const foundedSelectedCovenant = covenants_credentials.find(
        (covenant) => covenant.id === idCredential
      );

      if (foundedSelectedCovenant) {
        setCovenantId(foundedSelectedCovenant);
      }

      createToast("success", "Convênio atualizado com sucesso!");
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível atualizar os dados do convênio.  ",
        throwError: true,
      });

      if (error.errors) {
        throw new Error(error.errors[0].message);
      }

      throw new Error("Não foi possível atualizar este convênio!");
    }
  };

  const redirectAttendanceType = () => {
    closeModal("updatedSuccessfully");

    history.push(
      `/ficha-de-atendimento/person/tipo-de-atendimento?btype=covenant`
    );
  };

  const setClientInfo = () => {
    const clientData = personInfo ?? patientSelected;
    const initialFormValues = arrangeClientInfosToSetForm(clientData);
    clientInfo.current = initialFormValues;
  };

  const formatUpdateClientInfo = (values: FormRegisterValues) => {
    return prepareDataToSendRequest(values);
  };

  const changePersonObject = (personObject: UpdateRequestPerson) => {
    return transformSomePersonInfo(personObject, personInfo);
  };

  const updatePerson = async (values: FormRegisterValues) => {
    const { covenant_credentials, personObject } =
      formatUpdateClientInfo(values);

    if (isLead) {
      const personCreated = await createPerson(personObject);
      dispatch(selectPacient(personCreated));

      const { id } = personCreated;

      await createCovenantInfo(id, covenant_credentials);

      return;
    }

    const { uniqueId, replacePersonInfo } = changePersonObject(personObject);
    const { id } = await updatePersonRequest(replacePersonInfo, uniqueId);

    await createCovenantInfo(id, covenant_credentials);
  };

  const submitRegisterForm = async (values: FormRegisterValues) => {
    try {
      setLoadingInfo(true);

      await updatePerson(values);
      createToast("success", "Cadastro atualizado com sucesso!");
      openModal("updatedSuccessfully");
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível atualizar os dados deste usuário.  ",
        showToast: false,
      });
      throw error;
    } finally {
      setLoadingInfo(false);
    }
  };

  const addMissingData = () => {
    openModal("updateRegisterForm");
    setClientInfo();
  };

  return {
    modals,
    modalDynamicInfo,
    headingIncompleteRegister,
    buttonIncompleteRegister,
    selectedCovenant,
    clientInfo: clientInfo.current,
    loadingInfo: isLoading || loadingInfo,
    combos: data,
    closeModal,
    includeNewCovenant,
    confirmSelectedCovenant,
    setCovenantId,
    redirectAttendanceType,
    addMissingData,
    submitRegisterForm,
    handleCovenantRegister,
  };
};
