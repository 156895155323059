import React from "react";

import { hasPathInArray, validateArrayType } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";
import { Container } from "./styles";

export const ProductCategory = ({ product }: ProductProps) => {
  const { category, subcategories } = product ?? {};

  const subcategoriesList = subcategories?.filter(
    (subcategory) => subcategory.parent_id === null
  );

  const subsubCategoriesList = subcategories?.filter(
    (subcategory) => subcategory.parent_id !== null
  );

  return (
    <AccordionCollapse title="Categoria do Produto" cssClass="category">
      <Container>
        <div>
          <span role="heading" className="heading" aria-level={5}>
            Categoria
          </span>
          <S.RowContent role="row" className="row-content">
            {category?.category}
          </S.RowContent>
        </div>

        {validateArrayType(subcategoriesList) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Subcategoria
            </span>

            {subcategoriesList?.map((subcategory) => (
              <S.RowContent
                role="row"
                className="row-content"
                key={subcategory.id}
                mb="0px"
              >
                {subcategory?.subcategory}
              </S.RowContent>
            ))}
          </div>
        )}

        {hasPathInArray([4], category?.id) &&
          validateArrayType(subsubCategoriesList) && (
            <div>
              <span role="heading" className="heading" aria-level={5}>
                Sub-Subcategoria
              </span>

              {subsubCategoriesList?.map((subcategory) => (
                <S.RowContent
                  role="row"
                  className="row-content"
                  key={subcategory.id}
                  mb="0px"
                >
                  {subcategory.subcategory}
                </S.RowContent>
              ))}
            </div>
          )}
      </Container>
    </AccordionCollapse>
  );
};
