import styled, { css } from "styled-components";

interface ContainerAccordionProps {
  bordered: boolean;
  padding?: string;
  pl?: string;
  pr?: string;
  pb?: string;
  pt?: string;
}

interface HeaderAccordionProps {
  centered: boolean;
  open: boolean;
}

export const ContainerAccordion = styled.section.attrs(
  ({ padding }: { padding: string }) => ({
    padding: padding || "16px 31px",
  })
)<ContainerAccordionProps>`
  ${({ padding }) =>
    css`
      padding: ${padding};
    `}

  height:fit-content;
  align-self: flex-start;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 16px;

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid rgba(196, 196, 196, 0.5);
    `}

  min-width: 215px;
  width: 100%;
`;

export const HeaderAccordion = styled.header<HeaderAccordionProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;

  img {
    transform: rotate(0deg);
    position: absolute;
    top: 10px;
    right: 0;
  }

  ${({ open }) =>
    open &&
    css`
      img {
        transform: rotate(180deg);
      }
    `}

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: space-between;
    `}
`;

export const TitleAccordion = styled.p`
  margin: 0;

  padding: 0;
  width: 100%;
  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.5px;
  color: var(--color-purple);
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
`;

export const ContentAccordion = styled.div`
  animation: fadeIn 0.8s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
