import { convertDeadlines } from "Pages/Product/ProductForm/utils";

import { ListProduct } from "Hooks/useProduct/interface";
import { Combos } from "Hooks/useProductCombos/interfaces";
import { RegionUnits } from "Hooks/useRegions/interface";

import { validateArrayType } from "Utils/Arrays";

const getWekDay = (dayId: number) => {
  switch (dayId) {
    case 1:
      return "Segunda-Feira";
    case 2:
      return "Terça-Feira";
    case 3:
      return "Quarta-Feira";
    case 4:
      return "Quinta-Feira";
    case 5:
      return "Sexta-Feira";
    case 6:
      return "Sábado";

    default:
      return "";
  }
};

export const mountAdditionalProductData = (
  productInfo: ListProduct | undefined,
  combos: Combos | undefined,
  units: RegionUnits[] | undefined
) => {
  return {
    category: {
      id: String(productInfo?.category.id),
      category: productInfo?.category.category,
    },
    application_id: productInfo?.application?.id,
    channels: validateArrayType(productInfo?.channels)
      ? productInfo?.channels.map((channel) => ({
          id: channel.id,
          name: channel.name,
        }))
      : undefined,

    subcategories: validateArrayType(productInfo?.subcategories)
      ? productInfo?.subcategories?.map((subcategory) => ({
          id: subcategory.id,
          parent_id: subcategory.parent_id,
          subcategory: subcategory.subcategory,
          subcategories: validateArrayType(subcategory.subcategories)
            ? subcategory.subcategories
            : [],
        }))
      : undefined,

    diets: validateArrayType(productInfo?.diets)
      ? productInfo?.diets.map((diet) => ({ id: diet.id, diet: diet.diet }))
      : undefined,

    genders: validateArrayType(productInfo?.genders)
      ? productInfo?.genders.map((gender) => ({
          id: gender.id,
          gender: gender.sub_gender,
        }))
      : undefined,

    kits: validateArrayType(productInfo?.kits)
      ? productInfo?.kits.map((kit) => ({ id: kit.id, product: kit.product }))
      : undefined,

    partners: validateArrayType(productInfo?.partners)
      ? productInfo?.partners.map((partner) => ({
          id: partner.list.id,
          price: partner.price,
          partner: combos?.partners.find(
            (partCombo) => partCombo.id === partner.list.id
          )?.list,
          deadline: convertDeadlines(partner.result_time).label,
          time: convertDeadlines(partner.result_time).time,
        }))
      : undefined,

    services: validateArrayType(productInfo?.services)
      ? productInfo?.services.map((service) => ({
          id: service.id,
          attendance: service.attendance,
        }))
      : undefined,

    services_periods: validateArrayType(productInfo?.services_periods)
      ? productInfo?.services_periods?.map((servicePer) => ({
          id: servicePer.day_id,
          week_day: getWekDay(servicePer.day_id),

          start_hr: combos?.resut_times
            .find((result) => result.id === servicePer.start_id)
            ?.hour.replace(":00", ""),

          end_hr: combos?.resut_times
            .find((result) => result.id === servicePer.end_id)
            ?.hour.replace(":00", ""),
        }))
      : undefined,

    units:
      productInfo?.units.include[0] === 0
        ? units
        : units
            ?.map((unit) => ({
              ...unit,
              units: unit.units?.filter((uns) =>
                productInfo?.units.include.includes(uns.id)
              ),
            }))
            .filter((unit) => unit.units?.length),
  };
};
