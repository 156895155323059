import React, { Component } from "react";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};
class Pagination extends Component {
  constructor(props) {
    super(props);

    this.setupPagination();

    this.state = { currentPage: 1, pages: [] };
  }

  setupPagination = (_) => {
    const {
      totalRecords = null,
      pageLimit = 30,
      pageNeighbours = 0,
    } = this.props;
    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
  };

  goToPage = (page) => {
    const { onPageChanged = (f) => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    };

    this.setState(
      (prevState) => ({
        ...prevState,
        currentPage,
      }),
      () => {
        onPageChanged(paginationData);
      }
    );
  };

  handleClick = (e, page) => {
    e.preventDefault();

    this.goToPage(page);
  };

  handleMoveLeft = (e) => {
    e.preventDefault();

    this.goToPage(this.state.currentPage - 1);
  };

  handleMoveRight = (e) => {
    e.preventDefault();

    this.goToPage(this.state.currentPage + 1);
  };

  handleChangePerPage = ({ target: { value, name } }) => {
    if (!value.length) return;

    if (/^\d+$/.test(value) && value.length < 4) {
      const { onChangePerPage } = this.props;

      onChangePerPage && onChangePerPage(value, name);
    }
  };

  fetchPageNumbers = () => {
    const {totalPages} = this;
    const {currentPage} = this.state;
    const {pageNeighbours} = this;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];

          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];

          break;
        }

        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];

          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  componentDidMount() {
    this.goToPage(1);

    this.setState((prevState) => ({
      ...prevState,
      pages: this.fetchPageNumbers(),
    }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.totalRecords !== prevProps.totalRecords) {
      this.setupPagination();

      this.setState((prevState) => ({
        ...prevState,
        pages: this.fetchPageNumbers(),
      }));
    }

    if (this.props.inactive) {
      this.goToPage(1);
      this.props.setInactive(false);
    }
  }

  render() {
    if (!this.totalRecords || this.totalPages === 1) return null;

    const { perPage } = this.props;
    const { currentPage } = this.state;
    return (
      <>
        <ul className="pagination">
          {!!perPage && (
            <li className="page-link">
              <input
                min="1"
                max="1000"
                className="input _pagination"
                id="per_page"
                name="per_page"
                type="number"
                onChange={this.handleChangePerPage}
                value={perPage}
              />
            </li>
          )}
          {this.state.pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className="page-link page-text">
                  <button
                    type="button"
                    className="button _pagination"
                    href="#"
                    aria-label="Previous"
                    onClick={this.handleMoveLeft}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="page-link page-text">
                  <button
                    type="button"
                    className="button _pagination"
                    href="#"
                    aria-label="Next"
                    onClick={this.handleMoveRight}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              );

            return (
              <li
                key={index}
                className={`page-link ${
                  currentPage === page ? " page-current" : ""
                } ${page === this.totalRecords ? "total-records" : ""}`}
              >
                <button
                  type="button"
                  className="button _pagination"
                  href="#"
                  onClick={(e) => this.handleClick(e, page)}
                >
                  {page}
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

export default Pagination;
