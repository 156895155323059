import React from "react";

import { Field, useFormikContext } from "formik";
import { Box } from "styles/globalComponents";

import { AccordionCollapse } from "Components/AccordionCollapse";
import SwitchInputControlled from "Components/Form/Controlled/SwitchInputControlled";

import { FormValuesProps } from "../../interface";

export const AnamneseForm = () => {
  const { values } = useFormikContext<FormValuesProps>();
  return (
    <AccordionCollapse title="Anamnese do Paciente">
      <Box mt="15" className="input-container column">
        <Field
          name="rule.need_height"
          id="rule.need_height"
          value={values.rule.need_height}
          label="altura"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
        <Field
          name="rule.need_weight"
          id="rule.need_weight"
          value={values.rule.need_weight}
          label="peso"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
        <Field
          name="rule.need_diseases"
          id="rule.need_diseases"
          value={values.rule.need_diseases}
          label="doenças crônicas"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
        <Field
          name="rule.need_medicament"
          id="rule.need_medicament"
          value={values.rule.need_medicament}
          label="Medicamentos"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
        <Field
          name="rule.need_last_menstruation"
          id="rule.need_last_menstruation"
          value={values.rule.need_last_menstruation}
          label="data da última menstruação"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
        <Field
          name="rule.need_mother_name"
          id="rule.need_mother_name"
          value={values.rule.need_mother_name}
          label="nome da mãe"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
        <Field
          name="rule.need_symptoms"
          id="rule.need_symptoms"
          value={values.rule.need_symptoms}
          label="sintomas"
          component={SwitchInputControlled}
          textOn="Sim"
          textOff="Não"
        />
      </Box>
    </AccordionCollapse>
  );
};
