import React from "react";

import { ORDER_BY } from "Pages/Product/helperData";
import { useFilters } from "Pages/ServiceForm/SelectProducts/useFilters";
import { Box } from "styles/globalComponents";

import { CATEGORIES } from "Constants/Categories";

import { hasPathInArray } from "Utils/Arrays";

import { Button } from "Components/Button";
import Select from "Components/Form/Select";
import SelectSearch from "Components/Form/SelectSearch";

import { InputOverlayProductList } from "./InputOverlayList";
import { BudgetFiltersProps } from "./interface";
import {
  SearchInput,
  SearchFilters,
  FiltersContainer,
  OrderInput,
} from "./styles";

export const BudgetProductsFilter = ({
  getProducts,
  fetchNewPage,
}: BudgetFiltersProps) => {
  const {
    handleTypeSearch,
    search,
    productsCategories,
    handleSearchProducts,
    partners,
    handleChangeFilter,
    categoriesOpts,
    subcategoriesOpts,
    services,
    clearFilter,
    orderProductsAlphabetically,
    status,
  } = useFilters({
    getProducts,
    fetchNewPage,
  });

  return (
    <FiltersContainer>
      <SearchInput className="row">
        <div className="sm-12 ">
          <InputOverlayProductList
            handleTypeSearch={handleTypeSearch}
            value={search.search}
            handleSearchProducts={handleSearchProducts}
          />
        </div>
      </SearchInput>

      <SearchFilters className="row">
        {!productsCategories?.includes(CATEGORIES.consult) && (
          <>
            <p className="filter-by">Filtrar por: </p>
            {productsCategories?.includes(CATEGORIES.image) && (
              <Box className="w-select">
                {/* @ts-ignore */}
                <Select
                  id="partner"
                  value={search?.partner_id}
                  placeholder="Parceiros"
                  optionName="list"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFilter("partner_id", e.target.value)
                  }
                  options={partners}
                />
              </Box>
            )}
            {productsCategories &&
              !hasPathInArray(productsCategories, [
                CATEGORIES.image,
                CATEGORIES.infusion,
              ]) && (
                <Box className="w-select">
                  {/* @ts-ignore */}
                  <Select
                    id="category"
                    value={search?.categories}
                    optionName="category"
                    placeholder="Categoria"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeFilter("categories", e.target.value)
                    }
                    options={categoriesOpts}
                  />
                </Box>
              )}

            {subcategoriesOpts && subcategoriesOpts.length >= 1 && (
              <Box className="w-select">
                {/* @ts-ignore */}
                <Select
                  id="subCategory"
                  value={search?.subcategories}
                  placeholder="Subcategoria"
                  optionName="subcategory"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFilter("subcategories", e.target.value)
                  }
                  options={subcategoriesOpts}
                />
              </Box>
            )}
          </>
        )}

        {!productsCategories?.includes(CATEGORIES.image) &&
          !productsCategories?.includes(CATEGORIES.consult) && (
            <Box className="w-auto">
              {/* @ts-ignore */}

              <SelectSearch
                id="services"
                value={search.services}
                isMulti
                isMoreSelect
                options={services}
                readOnly={!services?.length}
                valueKey="attendance"
                labelKey="attendance"
                placeholder="Atendimento"
                onChange={(value: any) => handleChangeFilter("services", value)}
              />
            </Box>
          )}

        {!productsCategories?.includes(CATEGORIES.consult) && (
          <>
            <Box className="w-select">
              {/* @ts-ignore */}
              <Select
                id="status"
                value={search?.status_id}
                placeholder="Status"
                optionName="status"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeFilter("status_id", e.target.value)
                }
                options={status}
              />
            </Box>

            <Box
              display="flex"
              justify="center"
              className="container-btn-actions"
            >
              <Button
                classButton="action-btn"
                type="submit"
                onClick={clearFilter}
                outlined
              >
                Limpar
              </Button>
              <Button
                type="submit"
                onClick={handleSearchProducts}
                classButton="action-btn"
              >
                Filtrar
              </Button>
            </Box>

            <OrderInput display="flex" ml="auto" justify="flex-end">
              <Select
                id="order_type"
                options={ORDER_BY}
                optionName="name"
                value={search.order_type}
                placeholder="Ordenar por"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  orderProductsAlphabetically(e.target.value)
                }
              />
            </OrderInput>
          </>
        )}
      </SearchFilters>
    </FiltersContainer>
  );
};
