import * as Yup from "yup";

export const schema = Yup.object({
  covenant: Yup.string().required("Insira um nome para o convênio"),
  external_link: Yup.string()
    .url("External link informado está em um formato inválido"),
  covenant_plans: Yup.array()
    .of(Yup.string())
    .min(1, "Insira no mínimo um plano para o convênio"),
  covenant_checklist: Yup.array().min(1, "Insira o checklist do convênio"),
  covenant_channels: Yup.array()
    .of(
      Yup.object({
        id: Yup.number(),
        name: Yup.string(),
      })
    )
    .min(1, "Inserir um canal para o convênio"),
  units: Yup.object({
    include: Yup.array().min(1, "Selecione ao menos uma unidade"),
  }),
  file: Yup.array().of(Yup.mixed()).min(1, "Insira uma logo para o Convênio"),
});
