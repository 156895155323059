export const GetLargerID = (array) => {
  const ids = array.map((o) => o.id);

  if (ids.length > 0) {
    return Math.max.apply(null, ids);
  }
  return Math.max(ids);
};

export const inArray = (array, property) => {
  let inArr = false;

  array.forEach((item) => {
    if (item[property]) {
      inArr = true;
    }
  });

  return inArr;
};

export function insertAt(array, index, elements) {
  return array.splice(index, 0, ...elements);
}

export const comparerArray = (otherArray, key) => {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other[key] == current[key];
      }).length === 0
    );
  };
};

export const validateArrayType = (array) => {
  return Boolean(array && Array.isArray(array) && array?.length);
};

export const hasPathInArray = (array, path) => {
  return array.some((item) => path?.includes(item));
};
