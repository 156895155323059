import styled from "styled-components";

export const HeaderContainer = styled.div`

  .flex-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;

    h2 {
      font-family: var(--font-display);
      font-size: 20px;
      line-height: 28px;
      margin: 0;
      display: flex;
      align-items: center;
      color: var(--color-gray-text);
    }
  }
`;
