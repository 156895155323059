import React from "react";
import { useHistory } from "react-router";

import { Box } from "styles/globalComponents";

import { useGetBudgetsPerson } from "Hooks/useGetBudgetsPerson";

import { LoadingText } from "Components/LoadingText";
import { Pagination } from "Components/Pagination";

import { Filter } from "./components/Filters";
import { ParamsFilter } from "./components/Filters/interface";
import { ListBudgets } from "./components/ListBudgets";
import { PageContainer, PageContent } from "./styles";

export const BudgetTab = () => {
  const {
    data,
    setBudgetsFilters,
    isLoading: loading,
    isError,
    pagination,
  } = useGetBudgetsPerson();

  const history = useHistory();

  function handlePagination(e: { currentPage: number }) {
    if (pagination?.current_page === e.currentPage) return;
    setBudgetsFilters({ page: e.currentPage });
  }

  const handleGetBudgetDetail = (budget_id: number) => {
    history.push(`/orcamento/${budget_id}`);
  };

  const searchBudgets = (filters: ParamsFilter) => {
    setBudgetsFilters(filters);
  };

  if (isError) {
    return (
      <PageContainer className="entry-app-content ">
        <PageContent className="content-holder ">
          <Box mt="100">
            <h3>
              Não foi possível obter a listagem de orçamentos ! Atualize a
              página.
            </h3>
          </Box>
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <>
      <div>
        <Filter searchBudgets={searchBudgets} />

        {loading ? (
          <Box mt="100">
            <LoadingText text="Carregando lista de orçamentos. Aguarde !" />
          </Box>
        ) : null}

        {data?.data.length && !loading ? (
          <ListBudgets
            data={data.data}
            handleGetBudgetDetail={handleGetBudgetDetail}
          />
        ) : null}

        {!data?.data.length && !loading ? (
          <Box mt="60">
            <h3 className="text-center text-purple">
              Nenhum orçamento encontrado com os filtros selecionados
            </h3>
          </Box>
        ) : null}
      </div>

      {!loading && (
        <Pagination
          key={pagination?.current_page}
          totalRecords={pagination?.total_pages}
          currentPageSelected={pagination?.current_page}
          pageLimit={1}
          pageNeighbours={1}
          onPageChanged={(e) => handlePagination(e)}
        />
      )}
    </>
  );
};
