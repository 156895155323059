import React from "react";
import { Link } from "react-router-dom";

import {
  editAppointment,
  cancelAppointments,
  rescheduleAppointments,
} from "Requests/Schedules";

import { Loading } from "Utils/Loading";
import { createToast } from "Utils/toastFunc";

import { formatterObjectDataSubmit } from "../../Requests/Formatters/Schedule";

export default function SelectTypeModal({
  modalOpen,
  setModalOpen,
  modalDismiss,
  data,
  hour,
  checkShow,
  sendEmail,
  notes,
  objectEdit,
}) {
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit(id, data, success, callback, formatter) {
    try {
      setLoading(true);
      await callback(id, formatter ? formatterObjectDataSubmit(data) : data);

      createToast("success", success);
      modalDismiss();
    } catch (error) {
      createToast("error", "Ocorreu um erro ao realizar essa operação");
    } finally {
      setLoading(false);
    }
  }
  const handleClick = (id) => setModalOpen(id);
  return (
    <div className="modal-schedule-buttons">
      {modalOpen === "details" && (
        <div>
          {data.person_id && (
            <Link
              to={`/ficha-cliente/${data.person_id}`}
              onClick={() => modalDismiss()}
            >
              <button className="button _white" id="history">
                Historico
              </button>
            </Link>
          )}
          <button
            className="button _white"
            id="edit"
            onClick={({ target }) => handleClick(target.id)}
          >
            Editar
          </button>
          <button
            className="button _white"
            id="change"
            onClick={({ target }) => handleClick(target.id)}
          >
            Remarcar
          </button>
          <button
            className="button _white"
            id="cancel"
            onClick={({ target }) => handleClick(target.id)}
          >
            Cancelar
          </button>
        </div>
      )}

      {modalOpen === "edit" && (
        <div>
          <button
            className="button _purple"
            id="history"
            onClick={() =>
              handleSubmit(
                data.id,
                objectEdit,
                "Esse agendamento fo editado com sucesso!",
                editAppointment,
                true
              )
            }
          >
            {loading ? <Loading /> : "Salvar"}
          </button>
          <button
            className="button _white"
            id="history"
            onClick={() => modalDismiss()}
          >
            Cancelar edição
          </button>
        </div>
      )}

      {modalOpen === "change" && (
        <div>
          <button
            className="button _purple"
            id="history"
            onClick={() =>
              handleSubmit(
                data.id,
                hour.timezone,
                "Esse agendamento fo remarcado com sucesso!",
                rescheduleAppointments
              )
            }
          >
            {loading ? <Loading /> : "Confirmar remarcação"}
          </button>
          <button
            className="button _white"
            id="history"
            onClick={() => modalDismiss()}
          >
            Cancelar
          </button>
        </div>
      )}

      {modalOpen === "cancel" && (
        <div>
          <button
            className="button _purple"
            id="history"
            onClick={() =>
              handleSubmit(
                data.id,
                { noShow: !checkShow, noEmail: !sendEmail, cancelNote: notes },
                "Esse agendamento foi cancelado com sucesso!",
                cancelAppointments
              )
            }
          >
            Cancelar agendamento
          </button>
          <button
            className="button _white"
            id="history"
            onClick={() => setModalOpen("details")}
          >
            Voltar
          </button>
        </div>
      )}
    </div>
  );
}
