import { Box } from "styles/globalComponents";
import Success from "Assets/Images/successIcon.svg";
import { Modal } from "Components/Modals/Modal";
import { FormattedAttendanceType, LastAddressType } from "Stores/budget-order";
import { Button } from "Components/Button";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";


interface OrderConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  formattedAttendances: FormattedAttendanceType[];
  lastAddress: LastAddressType | null;
  orderId: string
}


export const OrderConfirmationModal = ({
  isOpen,
  closeModal,
  formattedAttendances,
  lastAddress,
  orderId
}: OrderConfirmationModalProps) => {

  const history = useHistory();

  const [renderText, setRenderText] = useState("")

  useEffect(() => {
    if (lastAddress) {
      const hasOnlyAttendance = formattedAttendances.length === 1;
      let resultString = ''

      if (hasOnlyAttendance) {
        const isUnity = formattedAttendances[0].type === 'unity_collect';

        if (isUnity) {
          resultString = `Pedido confirmado com atendimento na unidade <strong>${formattedAttendances[0].unity_name}</strong>`
        } else {
          resultString = `Pedido confirmado com agendamento para o dia <strong>${moment(formattedAttendances[0].home_collect_date).format('DD/MM/YYYY')}</strong> entre <strong>${formattedAttendances[0].home_collect_hour} às ${moment(formattedAttendances[0].home_collect_hour, 'HH:mm:ss').add(60, 'minutes').format('HH:mm')}</strong> no endereço <strong>${lastAddress.street}, ${lastAddress.number} - ${lastAddress.neighborhood} - ${lastAddress.city} - ${lastAddress.uf}</strong>.`
        }
      } else {
        const hasUnityCollect = formattedAttendances.some((attendance) => attendance.type === "unity_collect");
        const hasHomeCollect = formattedAttendances.some((attendance) => attendance.type === "home_collect");
        
        if (hasUnityCollect && hasHomeCollect) {
          resultString = `Pedido confirmado com um atendimento na Unidade <strong>${formattedAttendances[0].unity_name}</strong>
           e outro atendimento domiciliar no dia <strong>${moment(formattedAttendances[1].home_collect_date).format('DD/MM/YYYY')}</strong> 
           entre <strong>${formattedAttendances[1].home_collect_hour} às ${moment(formattedAttendances[1].home_collect_hour, 'HH:mm:ss').add(60, 'minutes').format('HH:mm')}</strong> 
           no endereço <strong>${lastAddress.street}, ${lastAddress.number} - ${lastAddress.neighborhood} - ${lastAddress.city} - ${lastAddress.uf}</strong>.
           `
        }
      }

      setRenderText(resultString)
    }

  }, [])


  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      closeIcon={false}
      renderHeading={() => (
        <h2 style={{ textAlign: 'center' }}>
          {" "}
          Pedido confirmado
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }} dangerouslySetInnerHTML={{ __html: renderText }} />
      )}
      renderActions={() => (
        <Box display="flex" direction="column">
          <Button width="full" minHeight="3.7em" fontWeight="600" onClick={() => history.push('/pedidos')}>
            voltar para pedidos
          </Button>

          <Button width="full" outlined minHeight="3.7em" fontWeight="600" onClick={() => history.push(`/ficha-de-pedido/${orderId}`)}>
            ver detalhes dos pedidos
          </Button>

          <Button width="full" textButton fontWeight="600" onClick={() => history.push('/ficha-de-atendimento/paciente')}>
            criar novo orçamento
          </Button>
        </Box >
      )}
    />
  )

}
