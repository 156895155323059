import React from "react";

interface InputCpfProps {
  id: string;
  label: string;
  value: string;
  placeholder: string;
  error: string;
  disabled?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
// The name is Cpf, but it can be used to passport document number
export const InputCpf = ({
  id,
  label,
  value,
  placeholder,
  error,
  disabled,
  handleChange
}: InputCpfProps) => {

  return (
    <div
      className={`
          field-holder 
          ${error ? "field-holder-invalid" : ""}
      `}
    >
      {label && (
        <label
          htmlFor={id}
          className='text-gray text-semibold'
        >
          {label}
        </label>
      )}
      <input
        id={id}
        name={id}
        type="text"
        className="input _opacity-gray"
        onChange={(e) => handleChange(e)}
        value={value}
        placeholder={placeholder}
        maxLength={14}
        readOnly={disabled}
      />
      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  )

}