import React, { FormEvent, useEffect, useState } from "react";

import { PagesProps, PaginationProps } from "./interface";
import { PaginationContent } from "./style";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range: Array<string | number> = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};
export const Pagination = ({
  onPageChanged,
  pageLimit = 30,
  currentPageSelected,
  pageNeighbours = 0,
  totalRecords = 0,
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(() => {
    if (currentPageSelected) return currentPageSelected;
    return 1;
  });

  const [pages, setPages] = useState<PagesProps>();

  const goToPage = (page: number) => {
    const pageToGo = Math.max(0, Math.min(page, totalRecords));

    const paginationData = {
      currentPage: pageToGo,
      totalPages: totalRecords,
      pageLimit,
      totalRecords,
    };

    setCurrentPage(pageToGo);
    onPageChanged(paginationData);
  };

  const handleClick = (e: FormEvent, page: number | string) => {
    e.preventDefault();

    goToPage(Number(page));
  };

  const handlePrevPage = (e: FormEvent) => {
    e.preventDefault();

    goToPage(currentPage - 1);
  };

  const handleNextPage = (e: FormEvent) => {
    e.preventDefault();

    goToPage(currentPage + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalRecords > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalRecords - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalRecords - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];

          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];

          break;
        }

        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];

          break;
        }
      }

      return setPages([1, ...pages, totalRecords]);
    }

    return setPages(range(1, totalRecords));
  };

  useEffect(() => {
    fetchPageNumbers();
  }, [totalRecords]);

  return (
    <PaginationContent>
      <ul className="pagination">
        {pages?.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <li key={index} className="page-link page-text">
                <button
                  type="button"
                  className="button _pagination"
                  aria-label="Previous"
                  onClick={handlePrevPage}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
            );

          if (page === RIGHT_PAGE)
            return (
              <li key={index} className="page-link page-text">
                <button
                  type="button"
                  className="button _pagination"
                  aria-label="Next"
                  onClick={handleNextPage}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            );

          return (
            <li
              key={index}
              className={`page-link ${
                currentPage === page ? " page-current" : ""
              } ${page === totalRecords ? "total-records" : ""}`}
            >
              <button
                type="button"
                className="button _pagination"
                onClick={(e) => handleClick(e, page)}
              >
                {page}
              </button>
            </li>
          );
        })}
      </ul>
    </PaginationContent>
  );
};
