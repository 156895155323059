import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;

  .partner-holder {
    thead th {
      color: var(--color-gray-text);
      font-family: var(--font-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }

    tbody td {
      width: 80px;
      text-align: left;

      color: var(--color-gray-text);
      font-family: var(--font-text);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    tbody td.partner {
      width: 50px;

      font-weight: 600;
    }
  }
`;
