import React from 'react'

export default function CreditsAvaliable({image, alt = "", type, title, subtitle}) {
    return (
        <div className={`card-labi-credits-avaliable ${type ? 'other-style-card-credits' : ""}`}>
            <div className="card-labi-credits-image">
                <img src={image} alt={alt} />
            </div>

            <div>
                <p>{title}</p>
                <strong>{subtitle}</strong>
            </div>
        </div>
    )
}
