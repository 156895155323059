import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  h3 {
    font-family: var(--font-display);
    color: var(--color-purple);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 32px;
  }

  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-items {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin: 0 0 8px;
    }
  }

  .form-item {
    width: 100%;
    margin: 0 0 32px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
