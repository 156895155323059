import FormatCurrency from 'Utils/FormatCurrency';

export const GetInstallments = async (value) => {
    try {
        const response = await fetch(`https://api-hmg.labiexames.com.br/installments?value=${value}`);

        const json = await response.json();

        return json.body.map(item => ({
            ...item,
            totalValueDisplay: FormatCurrency(item.total),
            valuePerInstallmentDisplay: FormatCurrency(item.value_per_installment)
        }));
    } catch (error) {
        console.log(error);

        return [];
    }
}