import React from "react";
import Modal from "react-modal";

export const ModalInformation = ({
  modalVisible,
  modalDismiss,
  title,
  icon,
  message,
  messageButton,
  cancelButton,
  onClick,
  skipButtonMessage = "",
  onSkipButtonClick = () => {},
  children,
  borderButton,
}) => {
  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-content-information"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="container-modal-information">
        <div className="box-image-modal-information">
          <img src={icon} alt="" />
        </div>

        <h2>{title}</h2>
        <p>
          {message}
          {children}
        </p>

        <div className="flex-buttons-modal-information">
          {cancelButton && (
            <div className="container-button-information">
              <button
                className={`button _white ${
                  borderButton ? "" : "_transparentButton"
                }`}
                onClick={modalDismiss}
              >
                Cancelar
              </button>
            </div>
          )}

          {messageButton && (
            <div className="container-button-information">
              <button
                type="button"
                className="button _action"
                onClick={onClick}
              >
                {messageButton}
              </button>
            </div>
          )}
        </div>

        {skipButtonMessage && (
          <div className="container-button-information">
            <button
              className="button _white _transparentButton"
              onClick={onSkipButtonClick}
            >
              {skipButtonMessage}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
