import React from "react";

import { Box } from "styles/globalComponents";

import { hasPathInArray } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";

export const ProductDescription = ({ product }: ProductProps) => {
  const { category, product_description } = product ?? {};

  const {
    application,
    availability_sus,
    composition,
    description,
    dosage,
    how_to,
    indicated,
    orientation,
    presentation,
    private_differentials,
    protection,
    public_differentials,
    restriction,
    results,
    why_do,
    not_indicated,
    reactions,
  } = product_description ?? {};

  return (
    <AccordionCollapse title="Descrição" cssClass="description">
      <Box mt="16">
        <div>
          <span role="heading" className="heading" aria-level={5}>
            Descrição (O que é )
          </span>
          <S.RowContent
            role="row"
            className="row-content"
            dangerouslySetInnerHTML={{ __html: description || "Não informado" }}
          />
        </div>

        {hasPathInArray([1, 2, 3, 5, 7], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Por que fazer
            </span>
            <S.RowContent role="row" className="row-content">
              {why_do || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([5, 7], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Orientações
            </span>
            <S.RowContent role="row" className="row-content">
              {orientation || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([1, 2, 3, 5, 7, 8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Como é feito
            </span>
            <S.RowContent role="row" className="row-content">
              {how_to || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([4, 8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Protege contra
            </span>
            <S.RowContent role="row" className="row-content">
              {protection || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([4, 8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Indicado para
            </span>
            <S.RowContent role="row" className="row-content">
              {indicated || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([4, 5, 8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Contraindicado para
            </span>
            <S.RowContent role="row" className="row-content">
              {not_indicated || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([4], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Por que esquema de doses
            </span>
            <S.RowContent role="row" className="row-content">
              {dosage || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([4, 8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Como é aplicado
            </span>
            <S.RowContent role="row" className="row-content">
              {application || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Apresentação do medicamento
            </span>
            <S.RowContent role="row" className="row-content">
              {presentation || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([4], category?.id) && (
          <>
            <div>
              <span role="heading" className="heading" aria-level={5}>
                Disponibilidade no SUS
              </span>
              <S.RowContent role="row" className="row-content">
                {availability_sus || "Não informado"}
              </S.RowContent>
            </div>

            <div>
              <span role="heading" className="heading" aria-level={5}>
                Diferenciais vacina pública
              </span>
              <S.RowContent role="row" className="row-content">
                {public_differentials || "Não informado"}
              </S.RowContent>
            </div>

            <div>
              <span role="heading" className="heading" aria-level={5}>
                Diferenciais vacina privada
              </span>
              <S.RowContent role="row" className="row-content">
                {private_differentials || "Não informado"}
              </S.RowContent>
            </div>
            <div>
              <span role="heading" className="heading" aria-level={5}>
                Possíveis reações
              </span>
              <S.RowContent role="row" className="row-content">
                {reactions || "Não informado"}
              </S.RowContent>
            </div>
            <div>
              <span role="heading" className="heading" aria-level={5}>
                Composição
              </span>
              <S.RowContent role="row" className="row-content">
                {composition || "Não informado"}
              </S.RowContent>
            </div>
          </>
        )}

        {hasPathInArray([1, 2, 3], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Resultados possíveis
            </span>
            <S.RowContent role="row" className="row-content">
              {results || "Não informado"}
            </S.RowContent>
          </div>
        )}

        {hasPathInArray([7], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Restrições
            </span>
            <S.RowContent role="row" className="row-content">
              {restriction || "Não informado"}
            </S.RowContent>
          </div>
        )}
      </Box>
    </AccordionCollapse>
  );
};
