import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { RegisterForm } from "Components/ServiceForm/RegisterForm";
import Breadcrumb from "Components/Shared/Breadcrumb";

import TrashIcon from "Assets/Images/trash_cancel.png";

import CartDetails from "../components/Cart/CartDetails";
import { TermModal } from "../components/Modals/Term";

const FinishRegisterForm = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);

  const serviceFormState = useSelector((state) => state.service_form);
  const patientSelected = serviceFormState.pacient;

  const { budget, deleteBudget } = useBudgetContext();

  const closeModal = () => setModalTerm(false);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder page-budget">
        <div className="page-heading with-cart">
          <div className="wrapper-header-content m-bottom-1 order-budget">
            <PageHeader title="Formas de pagamento" isBudget>
              <Breadcrumb
                list={[
                  {
                    path: "/orcamento",
                    label: "Orçamento",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Criar novo",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Formas de Pagamento",
                    current: true,
                  },
                ]}
              />
              <p className="register-form-sub-title">Dados Cadastrais</p>
            </PageHeader>
          </div>
        </div>

        <div className="cart-order-budget">
          <RegisterForm
            lead={patientSelected}
            leadId={budget?.lead.id || false}
            budget_id={budget?.id || false}
            isRegisteredProp={() => setIsRegistered(true)}
          />

          <CartDetails>
            {!!budget?.budget_type && !!budget?.id && (
              <button
                onClick={() => setModalTerm(true)}
                type="button"
                disabled={!isRegistered}
                className="button _block _xs  generate-margin-1 radiusButtonBorder"
              >
                PROSSEGUIR PARA PAGAMENTO
              </button>
            )}

            <Box className="cart-details-header" mt="10">
              <button
                className="button-cancel button-mb"
                onClick={deleteBudget}
              >
                CANCELAR CONSULTA <img src={TrashIcon} alt="cancelar" />
              </button>
            </Box>
          </CartDetails>
        </div>
      </div>
      <TermModal closeModal={closeModal} isOpen={modalTerm} />
    </PageWrapper>
  );
};

export default FinishRegisterForm;
