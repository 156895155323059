import styled, { css } from "styled-components";

interface UnitsProps {
  height: number;
}

export const ContentArticle = styled.div`
  margin-bottom: 24px;
`;

export const ContentTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 45px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
  padding-bottom: 17px;
  position: relative;

  img {
    width: 92px;
    height: 43px;
  }
  p {
    margin: 0;
    color: #572580;
    font-size: 24px;
    font-family: "Saira";
    margin-left: 10px;

    span {
      font-weight: 700;
    }
  }

  button {
    position: absolute;
    right: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
  margin-bottom: 17px;
  margin-top: 6px;
`;

export const Annex = styled.div`
  border: 1px solid rgba(195, 195, 195, 0.5);
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-bottom: 25px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 74.8px;
    height: 45px;
  }

  .button-container {
    display: flex;
    flex-direction: column;

    .border-button {
      border-radius: 30px;
    }

    .border-button-download {
      border-radius: 30px;
      background-color: white;
      border: 1px solid #572580;
      color: #572580;
      margin-top: 5px;
    }
  }

  .annex-title-img {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-left: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 144px;
    }
  }
`;

export const UnitsContainer = styled.div<UnitsProps>`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  ${({ height }) => css`
    height: ${height}px;
  `}
`;

export const Table = styled.div`
  max-width: 300px;
  h5 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 160%;
    margin: 0;

    text-align: center;

    color: #333333;
  }

  .table-line {
    grid-column-start: 1;
    grid-column-end: 6;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
  }

  .division {
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);

    p {
      margin: 0;
      padding: 18px 0 3px 0px;
      font-size: 12px;
      line-height: 160%;
      text-align: center;
      color: #572580;

      min-height: 39px;
    }
  }

  .division:last-child {
    border-bottom: none;
  }
`;

export const ListUl = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #666666;
`;
