import React, { useState, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ComponentPaginationProps } from "Pages/PersonDuplicated/interface";
import {
  PersonProps,
  PhonesPersonProps,
} from "Pages/ServiceForm/SelectPacient/interface";

import API from "Services/Api";

import { Store } from "Reducers/interface";

import { FormatDate } from "Utils/FormatterDate";
import { MaskTelephone, MaskCelphone, MaskCPF } from "Utils/Masks";
import Slugify from "Utils/Slugify";
import validatePermissions from "Utils/validatePermissions";

import Input from "Components/Form/Input";
import DetailsPersonModal from "Components/Modals/DetailsPersonModal";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { PaginationProps, TargetProps } from "./interface";

export function ListPersons() {
  const user_permissions = useSelector(
    (state: Store) => state.User.permissions
  );

  const [loading, setLoading] = useState(true);
  const [persons, setPersons] = useState<PersonProps[]>([]);
  const [pagination, setPagination] = useState<PaginationProps | null>(null);
  const [lastPagePagination, setLastPagePagination] = useState(1);
  const [personID, setPersonID] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchinput, setSearchInput] = useState({ value: "", error: "" });

  async function fetchPersons() {
    setLoading(true);

    try {
      const response = await API.get(`/persons`);

      setPagination(response.data.meta.pagination);
      setPersons(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchSearchPerson(parsed: string) {
    setLoading(true);
    setPagination(null);

    try {
      const searchValueFormatted = formatSearchValue(parsed);
      const response = await API.get(`/persons?search=${searchValueFormatted}`);
      setPersons(response.data.data ? response.data.data : []);
      setPagination(response.data.meta ? response.data.meta.pagination : {});
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPagination(e: number) {
    setLoading(true);

    try {
      let response;

      if (searchinput.value !== "") {
        const parsed = Slugify(searchinput.value);
        const searchValueFormatted = formatSearchValue(parsed);

        response = await API.get(
          `/persons?search=${searchValueFormatted}&page=${e}`
        );
      } else {
        response = await API.get(`/persons?page=${e}`);
      }

      setPersons(response.data.data);
      setPagination(response.data.meta ? response.data.meta.pagination : {});
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  function _renderPersonPhone(phone: PhonesPersonProps) {
    return `+${phone.ddi} (${phone.ddd}) ${
      String(phone.phone).length === 8
        ? MaskTelephone(phone.phone)
        : String(phone.phone).length === 9
        ? MaskCelphone(phone.phone)
        : ""
    }`;
  }

  const formatDocument = (person: PersonProps) => {
    if (person?.documents && person?.documents?.length) {
      if (person.documents[0].type === "cpf") {
        return MaskCPF(person?.documents[0]?.number);
      }
      return person?.documents[0]?.number;
    }

    return "Não informado";
  };

  function formatSearchValue(string: string) {
    const find = "/";
    const re = new RegExp(find, "g");

    return string.replace(re, "-");
  }

  function handleSearchPerson(value: string) {
    setSearchInput({
      ...searchinput,
      error: "",
    });

    if (value.length < 3) {
      setSearchInput({
        ...searchinput,
        error: "Digite no minímo 3 caracteres.",
      });
      setLastPagePagination(1);

      return;
    }

    setPersons([]);
    fetchSearchPerson(searchinput.value);
  }

  function handlePagination(e: ComponentPaginationProps) {
    if (lastPagePagination === e.currentPage) return;

    fetchPagination(e.currentPage);

    setLastPagePagination(e.currentPage);
  }

  function handleChange({ target: { value } }: TargetProps) {
    setSearchInput((prevState) => ({
      ...prevState,
      value,
    }));

    if (value === "") {
      setSearchInput({
        value: "",
        error: "",
      });

      setPagination(null);
      setPersons([]);
      fetchPersons();
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  useEffect(() => {
    fetchPersons();
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <DetailsPersonModal
        // @ts-ignore
        personID={personID}
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        loading={LoadingImage}
        wait={false}
        setPersonID={setPersonID}
      />
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Todas as pessoas">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Pessoas",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Todas as pessoas",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            {validatePermissions("Create Persons", user_permissions) && (
              <div className="actions-holder">
                {/* @ts-ignore */}
                <Link
                  to="/pessoa/0"
                  className="button-min-width button _white _action"
                >
                  Adicionar Novo
                </Link>
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit} className="form-heading">
            <div className="row">
              <div className="sm-12 md-4 lg-3">
                <div className="search-input">
                  {/* @ts-ignore */}
                  <Input
                    id="search"
                    type="search"
                    placeholder="Buscar por Nome, CPF, E-mail ou Telefone."
                    error={searchinput.error}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="sm-12 md-3">
                <div className="search-button">
                  <button
                    type="submit"
                    onClick={() => {
                      handleSearchPerson(searchinput.value);
                    }}
                    className="button _action"
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="page-content page-content-list">
          <table className="table-content small-separate details">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Documento</th>
                <th>Data de Nascimento</th>
                <th>Telefone</th>
                <th>Email</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={7} className="text-center">
                    <span className="loading-persons">
                      <img src={LoadingImage} alt="" />
                    </span>
                  </td>
                </tr>
              ) : !persons.length ? (
                <tr>
                  <td colSpan={7} className="text-center text-purple">
                    Oops! Parece que nada foi encontrado...
                  </td>
                </tr>
              ) : (
                <>
                  {persons?.map((person) => (
                    <tr key={person?.id}>
                      <td>
                        {person?.social_name
                          ? person?.social_name
                          : `${person?.full_name}`}
                      </td>
                      <td>{formatDocument(person)}</td>
                      <td>
                        {FormatDate(person?.birthdate) || "Não informado"}
                      </td>
                      <td>
                        {person?.phones?.length && person?.phones[0]?.phone
                          ? _renderPersonPhone(person?.phones[0])
                          : "Não informado"}
                      </td>
                      <td>
                        {person?.emails?.length
                          ? person?.emails[0]?.email
                          : "Não informado"}
                      </td>
                      <td className="text-right table-action">
                        {validatePermissions(
                          "Consult Persons",
                          user_permissions
                        ) && (
                          // @ts-ignore
                          <Link
                            // @ts-ignore
                            to={`/ficha-cliente/${person?.id}`}
                            // @ts-ignore
                            className="button _white _action"
                            // @ts-ignore
                            type="button"
                          >
                            <span className="details-text">Visualizar</span>
                          </Link>
                        )}
                        {validatePermissions(
                          "Update Persons",
                          user_permissions
                        ) && (
                          // @ts-ignore
                          <Link
                            // @ts-ignore
                            to={`/pessoa/${person?.id}`}
                            // @ts-ignore
                            className="button _blue _action"
                          >
                            <span className="details-text">Editar</span>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="pagination-wrapper">
        {!loading && (
          <>
            <Pagination
              totalRecords={pagination?.total_pages}
              currentPageSelected={pagination?.current_page}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e: ComponentPaginationProps) =>
                handlePagination(e)
              }
            />
          </>
        )}
      </div>
    </PageWrapper>
  );
}
