import styled from "styled-components";

export const Container = styled.div`
  padding: 43px 0 0;

  h2 {
    font-family: var(--font-display);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-purple);
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .categories-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 841px;
    border-radius: 24px;
    overflow: hidden;
    border: 1px solid #f0f0f0;

    button {
      padding: 28px 0;
      background: #ffffff;
      border: none;
      border-bottom: 1px solid #f0f0f0;

      display: flex;
      justify-content: center;

      font-family: var(--font-display);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: var(--color-lgray-text);

      cursor: pointer;

      &:hover {
        background: #f0f0f03b;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 25px;
        width: 250px;
      }

      .image-container {
        background: #f0f0f0;
        border-radius: 100%;
        padding: 12px;
        width: 30px;
        height: 30px;

        img {
          width: 30px;
          height: 30px;
          object-fit: none;
        }
      }

      span {
        white-space: nowrap;
      }
    }
  }
`;
