import React from "react";
import AsyncSelect from "react-select/async";

import { getProducts } from "Requests/Products";

const MultiValueContainer = () => null;

const CustomClearText = () => "Remover todos";

const fetchProducts = async (inputValue, type = [0]) => {
  const filters = {
    search: inputValue,
    categories: type.join(","),
  };

  const listResponse = await getProducts({
    page: 1,
    filters,
    searchBudget: true,
  });

  const resultsFounded = listResponse.data.filter((item) => {
    return (
      item.product.toLowerCase().includes(inputValue) ||
      item.sku.toLowerCase().includes(inputValue)
    );
  });

  return resultsFounded;
};

const loadOptions = (inputValue, type = 1) => {
  if (inputValue.length >= 2) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchProducts(inputValue.toLowerCase(), type));
      }, 450);
    });
  }
};

const ExamsListIncluded = ({
  fieldName,
  list,
  error,
  manageListExam,
  placeholder,
  label,
  description,
  displayCodeKey,
  displayNameKey,
  idKey,
  productType,
  clearOptions,
  cssClass = "",
}) => {
  const ClearIndicator = (props) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div className="text-danger clear-indicator" onClick={clearOptions}>
          {children}
        </div>
      </div>
    );
  };

  function changeOptions(option) {
    if (!option) return;

    manageListExam(option);
  }

  function removeListItem(option) {
    const newList = list.filter((item) => item.id !== option.id);

    manageListExam(newList);
  }

  return (
    <div id={fieldName} className={`exams-list-included ${cssClass}`}>
      <div
        className={`
                field-holder
                ${error ? "field-holder-invalid" : ""}
            `}
      >
        {label && (
          <label htmlFor={fieldName} className="text-gray text-semibold">
            {label}
            {description && (
              <div className="tooltip-holder">
                <span className="tooltip-icon">
                  <i className="icon-help" />
                </span>
                <div className="tooltip-description">{description}</div>
              </div>
            )}
          </label>
        )}
        <AsyncSelect
          classNamePrefix="react-select"
          className="react-select input _opacity-gray"
          id={fieldName}
          value={list}
          loadOptions={(value) => loadOptions(value, productType)}
          searchable
          onChange={(selectedOption) => {
            changeOptions(selectedOption);
          }}
          isMulti
          backspaceRemovesValue={false}
          placeholder={placeholder || "Exames inclusos"}
          loadingMessage={() => "Carregando produtos..."}
          noOptionsMessage={() => "Nenhum produto encontrado"}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => `${option.id} - ${option.product}`}
          components={{
            MultiValueContainer,
            ClearIndicator,
          }}
        />
        {error !== "" && (
          <span
            className={`error-message ${!label ? "error-without-label" : ""}`}
          >
            {error}
          </span>
        )}
      </div>
      <ul className="tags-holder">
        {list.map((item) => {
          return (
            <li key={item[idKey]}>
              <span className="tag-content">
                {item[displayCodeKey].toUpperCase()} | {item[displayNameKey]}
              </span>
              <span
                onClick={(_) => removeListItem(item)}
                className="text-danger icon-close"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ExamsListIncluded.defaultProps = {
  displayCodeKey: "code",
  displayNameKey: "name",
  idKey: "id",
};

export default ExamsListIncluded;
