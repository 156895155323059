import styled from "styled-components";

export const OverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 21px;
  height: 22px;
  margin-left: 5px;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .indication::before {
    content: "";
    display: block;

    width: 100%;
    height: 10px;
    position: absolute;
    top: 15px;
    right: 7px;
    width: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    border: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
  }

  .indication-right-side::before {
    top: 5px;
    right: -4px;
  }

  .indication-left-side::before {
    transform: rotate(135deg);
    left: -14px;
    top: 4px;
  }
`;

export const Content = styled.div`
  position: absolute;

  background-color: #fff;

  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 10px;
  padding: 20px;
  z-index: 10;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666;
    margin-bottom: 0;
  }

  min-width: 200px;
  min-height: 100%;
  width: 100%;
  left: -100px;
  top: 21px;

  &.right-side {
    top: -25px !important;
    left: 20px !important;
  }

  &.left-side {
    left: -251px;
    top: -4px;
    width: 193px;
  }
`;
