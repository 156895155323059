export const formatObjToSubmit = (data) => {
  let submitForm = {};

  Object.keys(data).forEach((key) => {
    if (data[key].value !== undefined && !Array.isArray(data[key].value)) {
      submitForm = {
        ...submitForm,
        [key]: data[key].value,
      };
    } else {
      submitForm = {
        ...submitForm,
        [key]: data[key],
      };
    }
  });

  return submitForm;
};

export const formatObjToForm = (data) => {
  let dataForm = {};

  Object.keys(data).forEach((key) => {
    let value;

    if (data[key] != null) {
      value = typeof data[key] !== "object" ? data[key] : data[key].value;
    } else {
      value = "";
    }

    dataForm = {
      ...dataForm,
      [key]: {
        error: "",
        value,
      },
    };
  });

  return dataForm;
};

export function validateObjectType(obj) {
  if (!obj) return false;
  if (!Object.keys(obj).length) return false;
  if (Array.isArray(obj)) return false;
  if (typeof obj !== "object") return false;

  return true;
}

export function orderObject(object) {
  return Object.keys(object)
    .sort()
    .reduce((result, key) => {
      result[key] = object[key];
      return result;
    }, {});
}

export function isObjectPropertiesEmpty(obj) {
  return Object.values(obj).some(Boolean);
}
