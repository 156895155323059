import React from 'react'

import { toDate } from 'date-fns-tz'

export default function SelectHoursBox({ data, setDate }) {
    const [value, setValue] = React.useState({});
    const [selected, setSelected] = React.useState("");

    const formatter = data.map(hour => {
        const output = toDate(`${hour.time}`)
        const _data = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric', minute: 'numeric', hour12: false
        }).format(output)

        return { time: _data, timezone: hour.time };
    })

    function handleClick(hour) {
        if (selected) {
            const getEl = document.getElementById(`schedule-hour-select.${selected}`);
            getEl.classList.remove('clicked');
        }

        setSelected(hour.time)
        setDate(hour);
        const getEl = document.getElementById(`schedule-hour-select.${hour.time}`);
        getEl.classList.add('clicked');
    }

    return (
        <div className="schedule-select-hours">
            {formatter.map((hour, i) => (
                <div onClick={() => handleClick(hour)} id={`schedule-hour-select.${hour.time}`} key={hour.time}><p>{hour.time}</p></div>
            ))}
        </div>
    )
}
