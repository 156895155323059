import { ReactNode, createContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { BudgetDocuments, BudgetProps } from "Dtos/IBudget";
import { Covenant } from "Dtos/ICovenant";

import { resetBudget } from "Actions/ServiceForm";

import { useQueryParams } from "Hooks/useQueryParams";

import { Store } from "Reducers/interface";

import { createToast } from "Utils/toastFunc";

import { BudgetContextProps } from "./interface";

export const BudgetContext = createContext<BudgetContextProps | null>(null);

export const BudgetProvider = ({ children }: { children: ReactNode }) => {
  const { budget: budgetStored } = useSelector(
    (state: Store) => state.service_form
  );

  const {
    categories,
    covenantInfo,
    type,
    zipCode: zipcode,
    collect_type,
  } = budgetStored ?? {};

  const [selectedCovenant, setSelectedCovenant] = useState<null | Covenant>(
    () => {
      if (covenantInfo) return covenantInfo;
      return null;
    }
  );

  const [productsCategoriesSelected, setProductsCategoriesSelected] = useState<
    undefined | number[]
  >(() => {
    if (categories) return categories;
    return undefined;
  });

  const [budgetType, setBudgetType] = useState<string | undefined>(() => {
    if (type) return type;
    return undefined;
  });

  const [zipCode, setZipCode] = useState(() => {
    if (zipcode) return zipcode.replace("-", "");
    return "";
  });

  const [serviceSelected, setServiceSelected] = useState<undefined | string>(
    () => {
      if (collect_type) return collect_type;
      return undefined;
    }
  );

  const [modalMedicalAttach, setModalMedicalAttach] = useState(false);
  const [budget, setBudget] = useState<BudgetProps | null>(null);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [partnerSelected, setPartnerSelected] = useState(0);
  const [documents, setDocuments] = useState<BudgetDocuments[]>([]);

  const couponCode = useRef<null | string>("");

  const history = useHistory();

  const params = useQueryParams();

  const btype = params.get("btype");

  const { person_type } = useParams<{ person_type: string }>();

  const { order, pacient } = useSelector((state: Store) => state.service_form);
  const patientSelected = pacient;
  const orderSelected = order;

  const isCovenantUnitAttendance = !!budget?.is_covenant && !budget?.zip_code;

  const isCovenantBudget = btype === "covenant";

  const budgetsPossible = new Set(["covenant", "private"]);

  const dispatch = useDispatch();

  const setBudgetInfo = (budgetInfo: BudgetProps | null) => {
    setBudget(budgetInfo);
  };

  const setCovenant = (covenant: Covenant) => {
    setSelectedCovenant(covenant);
  };

  const setCouponCode = (coupon: string) => {
    couponCode.current = coupon;
  };

  const toggleLoadingCartState = (toggle: boolean) => {
    setLoadingCart(toggle);
  };

  const resetBudgetStates = () => {
    setBudgetInfo(null);
    setDocuments([]);
    setPartnerSelected(0);
  };

  const resetBudgetInformation = () => {
    setBudgetInfo(null);
    setDocuments([]);
    setPartnerSelected(0);
    setServiceSelected(undefined);
    setProductsCategoriesSelected(undefined);
    setBudgetType(undefined);
    setSelectedCovenant(null);
    dispatch(resetBudget());
    setModalMedicalAttach(false);
    setZipCode("");
    setCouponCode("");
  };

  function showMessageCartIsAnotherPacient() {
    createToast(
      "error",
      "Oops! O carrinho que você está tentando acessar está indisponível"
    );
  }

  if (
    orderSelected?.patient?.id &&
    patientSelected?.id != orderSelected?.patient?.id
  ) {
    showMessageCartIsAnotherPacient();
    history.push("/orcamentos");
  }

  if (btype && !budgetsPossible.has(btype)) {
    createToast("error", "Este tipo de orçamento não é possível no momento");
    history.push(`/ficha-de-atendimento/paciente`);
  }

  const values = useMemo(
    () => ({
      budget,
      loadingCart,
      couponCode: couponCode.current,
      productsCategories: budgetStored?.categories,
      documents,
      person_type,
      loadingBudget,
      partnerSelected,
      patientSelected,
      selectedCovenant,
      zipCode,
      budgetType,
      productsCategoriesSelected,
      serviceSelected,
      isCovenantUnitAttendance,
      isCovenantBudget,
      modalMedicalAttach,
      setServiceSelected,
      toggleLoadingCartState,
      setCouponCode,
      setBudgetInfo,
      setPartnerSelected,
      setCovenant,
      setZipCode,
      resetBudgetStates,
      setLoadingBudget,
      setDocuments,
      setModalMedicalAttach,
      setBudgetType,
      setProductsCategoriesSelected,
      resetBudgetInformation,
    }),
    [
      budget?.cart,
      budget?.general_information,
      budget?.documents_base64,
      documents,
      loadingBudget,
      partnerSelected,
      loadingCart,
      selectedCovenant,
      zipCode,
      modalMedicalAttach,
      patientSelected,
      isCovenantBudget,
      serviceSelected,
      productsCategoriesSelected,
      budgetType,
    ]
  );

  return (
    <BudgetContext.Provider value={values}>{children}</BudgetContext.Provider>
  );
};
