import React, { useState, cloneElement } from "react";

const Tabs = ({ 
    children,
    active
}) => {
    const [activeIndex, setActiveIndex] = useState(active || 0);

    const handleTabClick = (tabIndex) => {
        if(tabIndex !== activeIndex) {
            setActiveIndex(tabIndex);
        }
    }

    const cloneTabElement = (tab, index = 0) => {
        if (!tab) return;
        
        return (
            cloneElement(tab, {
                onClick: () => handleTabClick(index),
                tabIndex: index,
                isActive: index === activeIndex,
                key: index
            })
        );
    }

    const renderChildrenTabs = () => {
        if(!Array.isArray(children)) {
            return cloneTabElement(children);
        }

        return children.map(cloneTabElement);
    }

    const renderActiveTabContent = () => {
        if(children[activeIndex]) {
            return children[activeIndex].props.children;
        }

        return children.props.children;
    }

    return (
        <section className="tabs-holder">
            <ul className="tabs-list">
                { renderChildrenTabs() }
            </ul>
            <div className="tabs-content">
                { renderActiveTabContent() }
            </div>
        </section>
    );
};

export default Tabs;