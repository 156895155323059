import { useRef, useState } from "react";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useProducts } from "Hooks/useProducts";

import { Button } from "Components/Button";
import { OverlayInputText } from "Components/Form/OverlayInputText";

import { OverlayProductList } from "../OverlayProductList";
import { Container } from "./styles";

interface InputOverlayListProps {
  handleTypeSearch: (search: string) => void;
  value: string;
  handleSearchProducts: () => void;
}

export const InputOverlayProductList = ({
  handleTypeSearch,
  handleSearchProducts,
  value,
}: InputOverlayListProps) => {
  const [overlayOpen, setOverlayOpen] = useState<null | boolean>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const {
    selectedCovenant,
    zipCode,
    isCovenantBudget,
    budget,
    productsCategoriesSelected,
  } = useBudgetContext();

  const { productsList, loadingProducts, setProductFilters } = useProducts({
    enableReq: false,
    initialFilters: {},
  });

  const toggleOverlay = (status: boolean) => {
    if (!value) setProductFilters({});
    setOverlayOpen(status);
  };

  const isHomeCollect = zipCode || budget?.zip_code;

  const handleDebounce = (search: string) => {
    setProductFilters({
      search,
      categories: productsCategoriesSelected?.join(","),
      plan_id: isCovenantBudget ? selectedCovenant?.covenant_plan.id : "",
      zip_code: zipCode || "",
      ...(selectedCovenant && {
        collect_type: isHomeCollect ? "home_collect" : "unity_collect",
      }),
    });
  };

  const handleType = (typeSearch: string) => {
    handleTypeSearch(typeSearch);

    if (timeout.current) clearInterval(timeout.current);

    timeout.current = setTimeout(() => {
      handleDebounce(typeSearch);
    }, 2000);
  };

  return (
    <Container>
      <OverlayInputText
        name="searchProducts"
        type="search"
        searchValue={value}
        placeholder="Buscar por nome, código, ou sinônimo"
        onChange={(searchText) => handleType(searchText)}
        setOverlayOpen={toggleOverlay}
        isOverlayOpen={overlayOpen}
      >
        <Button
          transparentHover
          classButton="search-btn"
          onClick={handleSearchProducts}
        >
          {" "}
          Buscar
        </Button>
      </OverlayInputText>

      {overlayOpen && (
        <OverlayProductList
          loadingProducts={loadingProducts}
          productsList={productsList}
          hasSearched={!!value}
        />
      )}
    </Container>
  );
};
