import { InputCep } from "Components/Form/InputCep/index";
import { Field, Form, Formik } from "formik";
import Input from "Components/Form/Input";
import * as Yup from "yup";
import { Button } from "Components/Button";
import { useState } from "react";
import { useSchedule } from "Hooks/useSchedule";
import { toast } from "react-toastify";
import { createScheduleHomeCollect, fetchDatesHomeCollect, fetchHoursHomeCollect } from "Requests/HomeCollect";
import PageWrapper from "Components/Pages/Wrapper";
import { CreateScheduleType } from "Requests/HomeCollect/types";
import moment from "moment";
import { useShallow } from "zustand/react/shallow";
import { useBudgetOrder } from "Stores/budget-order";
import { getCollectRangeCep } from "Requests/CollectRangeCep";
import LoadingPurple from "Assets/Images/tinyficated/loading-purple.gif";
import { maskCep } from "Components/Form/InputCep/functions";
// import { CalendarContainer } from "react-datepicker";
import { useHistory } from "react-router";
import { TitleCheckoutCollect } from "../../../style";
import {
  AddressPatientFormProps,
  AppointmentScheduleType,
  ArraysType,
  CalendarDateType,
  CalendarHourType,
  ObjectsType,
} from "./types";
import { FormValuesAdressType } from "../../types";
import { getNextAttendanceIndex, mountSkusFromAttenndance } from "../../functions";
import { CalendarContainer } from "./styles";
import { CollectCallendar } from "../CollectCalendar";

export const AddressPatientForm = ({
  address,
  setAddress,
  currentIndex,
  setCurrentIndex,
  setSelectedId,
  order,
  setOrder,
  setHasAllAttendances,
  enabledButton,
  setEnabledButton,
}: AddressPatientFormProps) => {

  const [currentComponent, setCurrentComponent] = useBudgetOrder(useShallow((state) => [
    state.currentComponent,
    state.setCurrentComponent,
  ]));

  const history = useHistory();

  const { getZipcodeStateConverted } = useSchedule();

  const [booleans, setBooleans] = useState({
    showScheduleComponent: false,
    loadingDate: false,
    loadingHours: false,
    loadingSubmit: false,
    loadingCep: false,
  });

  const [arrays, setArrays] = useState<ArraysType>({
    dates: [],
    hours: [],
  });

  const [objects, setObjects] = useState<ObjectsType>({
    appointment: {
      appointment_id: "",
      calendar_id: "",
      query_hour: "",
      query_date: "",
      type: "date",
      weekday: "",
      date: "",
      hour: "",
    },
    hour: {
      appointment_id: "",
      calendar_id: "",
      query_hour: "",
    },
  });

  const addressSchema = Yup.object().shape({
    street: Yup.string().required("Endereço é obrigatório"),
    neighborhood: Yup.string().required("Bairro é obrigatório"),
    number: Yup.string().required("Número é obrigatório").max(10, "Campo deve ser no máximo 10"),
    city: Yup.string().required("Cidade é obrigatório"),
    state: Yup.string().required("Estado é obrigatório"),
  });


  const handleSubmit = (values: FormValuesAdressType, setSubmitting: (value: boolean) => void) => {
    setBooleans((booleans) => ({
      ...booleans,
      showScheduleComponent: true,
      loading: true,
    }));

    fetchDates(values, setSubmitting);
  };

  const fetchDates = async (values: FormValuesAdressType, setSubmitting: (value: boolean) => void) => {

    try {
      setBooleans((booleans) => ({
        ...booleans,
        loadingDate: true,
      }));
      const state = getZipcodeStateConverted({ address: { ...values } });
      const formattedSkus = mountSkusFromAttenndance(order, currentIndex);

      const response: CalendarDateType = await fetchDatesHomeCollect({
        person_id: order.payer_patient.id,
        polygon: address.kml.polygon,
        sku: formattedSkus,
        state,
      });

      const formattedResponse: CalendarDateType = formatDates(response);

      setArrays((arrays) => ({
        ...arrays,
        dates: formattedResponse,
      }));
      setSubmitting(false);
      setBooleans((booleans) => ({
        ...booleans,
        loadingDate: false,
      }));
      setOrder({
        ...order,
        lastAddress: {
          ...order.lastAddress,
          zip_code: values.zip_code.replace("-", ""),
          street: values.street,
          neighborhood: values.neighborhood,
          city: values.city,
          uf: values.state,
          number: values.number,
          complement: values.complement,
          reference_point: values.reference_point,
        },
      });
    } catch (err) {
      toast.error("Falha ao consultar Datas para Agendamento.");
      setSubmitting(false);
      setBooleans((booleans) => ({
        ...booleans,
        loadingDate: false,
        loading: false,
        showScheduleComponent: false,
      }));
    }
  };

  const formatDates = (dates: CalendarDateType) => {

    const newArray: CalendarDateType = [];

    for (let i = 0; i < dates.length; i += 2) {
      // Verifica se existe o próximo array para combinar
      let combinacao = [...dates[i]]; // Copia os elementos do array atual
      if (dates[i + 1]) {
        combinacao = combinacao.concat(dates[i + 1]); // Junta o próximo array
      }
      newArray.push(combinacao); // Adiciona ao novo array
    }

    return newArray;

  };

  const handleSchedule = async () => {
    try {

      setBooleans((booleans) => ({
        ...booleans,
        loadingSubmit: true,
      }));

      const schedule: CreateScheduleType = {
        appointmentID: objects.appointment.appointment_id,
        calendarID: objects.appointment.calendar_id,
        date: moment(objects.appointment.query_hour).format("YYYY-MM-DD"),
        hour_query: objects.appointment.query_hour,
        person_id: String(order.payer_patient.id),
        time: objects.appointment.hour,
        products: mountSkusFromAttenndance(order, currentIndex),
        budget_id: order.budget_id,
      };
      const data = await createScheduleHomeCollect(schedule);
      setBooleans((booleans) => ({
        ...booleans,
        loadingSubmit: false,
      }));

      const formattedAttendanceIndex = order.formattedAttendances.findIndex((item) =>
        item.ids_attendance.includes(order.attendances[currentIndex].id),
      );

      const clonedFormattedAttendances = [...order.formattedAttendances];
      const clonedLabelAttendances = [...order.attendanceLabel];

      clonedFormattedAttendances[formattedAttendanceIndex].id_scheduling = data.id_scheduling;
      clonedFormattedAttendances[formattedAttendanceIndex].home_collect_date = objects.appointment.query_hour;
      clonedFormattedAttendances[formattedAttendanceIndex].calendar_id = objects.appointment.calendar_id;
      clonedFormattedAttendances[formattedAttendanceIndex].home_collect_hour = objects.appointment.hour;

      clonedLabelAttendances.push({
        collect_address: `${order.lastAddress.street}, ${order.lastAddress.number} - ${order.lastAddress.city}`,
        label: order.attendances[currentIndex].title,
        unity: "",
        ids_attendance: [order.attendances[currentIndex].id],
      });

      setOrder({
        ...order,
        appointment_type_id: objects.appointment.appointment_id,
        attendanceLabel: clonedLabelAttendances,
        session_start: data.session_start,
        session_end: String(moment(data.session_end, "YYYY-MM-DD HH:mm:ss").valueOf()),
      });
      setCurrentComponent("defaultCollect");
      setSelectedId("");
      const nextIndex = getNextAttendanceIndex(order.attendances, order.formattedAttendances);
      if (nextIndex === -1) {
        history.push("/pedidos/criar-pedido/identificacao");
        return setHasAllAttendances(true);
      }

      setCurrentIndex(nextIndex);
    } catch (err) {
      setBooleans({ ...booleans, loadingSubmit: false });
      console.error(err);
      toast.error("Falha ao realizar agendamento");
    }

  };

  const handleSelectCalendar = async (appointment: AppointmentScheduleType) => {

    try {
      setObjects((objects) => ({
        ...objects,
        appointment,
      }));
      setBooleans((booleans) => ({
        ...booleans,
        loadingHours: true,
      }));

      const state = getZipcodeStateConverted({ address: { ...address } });
      const formattedSkus = mountSkusFromAttenndance(order, currentIndex);

      const response: CalendarHourType = await fetchHoursHomeCollect({
        polygon: address.kml.polygon,
        sku: formattedSkus,
        date: appointment.query_date,
        state,
      });

      setBooleans((booleans) => ({
        ...booleans,
        loadingHours: false,
      }));

      setArrays((arrays) => ({
        ...arrays,
        hours: response,
      }));
    } catch (err) {
      toast.error("Falha ao consultar Horários para Agendamento.");
    }
  };

  const handleSelectHour = async (appointment: AppointmentScheduleType) => {
    setObjects((objects) => ({
      ...objects,
      appointment: {
        ...objects.appointment,
        appointment_id: appointment.appointment_id,
        calendar_id: appointment.calendar_id,
        query_hour: appointment.query_hour,
        hour: appointment.hour,
      },
    }));
  };

  const handleChangeCep = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const cep = maskCep(e.target.value) || "";

      setAddress({
        city: "",
        neighborhood: "",
        number: "",
        state: "",
        street: "",
        kml: {
          layer: "",
          map: "",
          polygon: "",
        },
        zip_code: cep,
        complement: "",
        reference_point: "",
      });

      if (cep.length < 9) {
        setArrays({
          dates: [],
          hours: [],
        });
        return setBooleans((booleans) => ({
          ...booleans,
          showScheduleComponent: false,
        }));
      }

      setBooleans((booleans) => ({
        ...booleans,
        loadingCep: true,
      }));


      const fetchedData = await getCollectRangeCep({ zip_code: cep }).then((fetchedData: any) => fetchedData ? fetchedData.data.data : null);

      if (fetchedData) {
        setAddress({
          ...fetchedData.address, number: "", kml: { ...fetchedData.kml },
        });
        setObjects((objects) => ({
          ...objects,
          appointment: {
            ...objects.appointment,
            date: "",
          },
        }));
        if (fetchedData) toast.success("Seu CEP está coberto pela Coleta Domiciliar do Labi.");
      }

      setBooleans((booleans) => ({
        ...booleans,
        loadingCep: false,
      }));

    } catch (err: any) {
      toast.error(err?.message);
      setBooleans((booleans) => ({
        ...booleans,
        loadingCep: false,
      }));
    }
  };


  return (
    <>
      <TitleCheckoutCollect>Confirme o local
        do {order.attendances[currentIndex].title.toLowerCase()}</TitleCheckoutCollect>

      <div
        style={{ width: "100%", display: "flex", flexDirection: "row", gap: 20 }}
      >
        <div style={{ width: "48%" }}>
          <InputCep
            label="CEP"
            mask="99999-999"
            id="cep"
            value={address.zip_code}
            error={false}
            placeholder="00000-00"
            onChange={(e) => handleChangeCep(e)}
          />
        </div>
        <div style={{ width: "40%", marginTop: "1em" }}>

          {booleans.loadingCep ?
            <div style={{ width: "3em" }}>
              <img
                className="loading loading-small loading-align-center"
                src={LoadingPurple}
                alt="Labi Saúde - Carregando"
                loading="lazy"
                style={{ marginTop: "1em" }}
              />
            </div>

            : <></>}
        </div>
      </div>

      <Formik
        initialValues={address}
        validateOnChange={false}
        validationSchema={addressSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, isSubmitting }) => (
          <Form>
            <div
              style={{ width: "100%", marginTop: "1em", display: "flex", flexDirection: "row", gap: 20 }}
            >

              <div style={{
                width: "52%",
              }}>
                <Field
                  name="street"
                  id="street"
                  label="RUA*"
                  component={Input}
                  value={values.street}
                  onChange={handleChange}
                  error={touched.street && errors.street && errors.street}
                  placeholder="Digite o endereço"
                />
              </div>
              <div style={{ width: "25%" }}>
                <Field
                  name="number"
                  id="number"
                  label="NÚMERO*"
                  component={Input}
                  value={values.number}
                  onChange={handleChange}
                  error={touched.number && errors.number && errors.number}
                  placeholder="Digite o número da residência"
                />
              </div>
              <div style={{ width: "25%" }}>
                <Field
                  name="complement"
                  id="complement"
                  label="Complemento"
                  component={Input}
                  value={values.complement}
                  onChange={handleChange}
                  error={touched.complement && errors.complement && errors.complement}
                  placeholder="Digite o complemento"
                />
              </div>

            </div>

            <div style={{ width: "100%", marginTop: "1em", display: "flex", flexDirection: "row", gap: 20 }}>
              <div style={{ width: "50%" }}>
                <Field
                  name="reference_point"
                  id="reference_point"
                  label="Ponto de Referência"
                  component={Input}
                  value={values.reference_point}
                  onChange={handleChange}
                  error={touched.reference_point && errors.reference_point && errors.reference_point}
                  placeholder="Digite o ponto de Referência"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  name="neighborhood"
                  id="neighborhood"
                  label="BAIRRO*"
                  component={Input}
                  value={values.neighborhood}
                  onChange={handleChange}
                  error={touched.neighborhood && errors.neighborhood && errors.neighborhood}
                  placeholder="Digite o bairro"
                />
              </div>
            </div>

            <div style={{ width: "100%", marginTop: "1em", display: "flex", flexDirection: "row", gap: 20 }}
            >
              <div style={{ width: "50%" }}>
                <Field
                  name="city"
                  id="city"
                  label="Cidade*"
                  component={Input}
                  value={values.city}
                  onChange={handleChange}
                  error={touched.city && errors.city && errors.city}
                  placeholder="Digite a cidade"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Field
                  name="state"
                  id="state"
                  label="ESTADO*"
                  component={Input}
                  value={values.state}
                  onChange={handleChange}
                  error={touched.state && errors.state && errors.state}
                  placeholder="Digite o estado"
                />
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: "16px", paddingTop: "16px" }}>
              <Button
                width="100%"
                minHeight="3.7em"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Consultar Datas
              </Button>
            </div>

          </Form>
        )}
      </Formik>


      {booleans.showScheduleComponent ?
        <PageWrapper className="app-content-checkout" loading={booleans.loadingDate || booleans.loadingHours}>
          <CalendarContainer>
            <CollectCallendar
              appointment={objects.appointment}
              handleSelectDate={handleSelectCalendar}
              handleSelectHour={handleSelectHour}
              dates={arrays.dates}
              hours={arrays.hours}
              loadingDate={booleans.loadingDate}
              loadingHours={booleans.loadingHours}
            />
          </CalendarContainer>
        </PageWrapper>
        : <></>}

      <div style={{
        bottom: 0,
        width: "100%",
        backdropFilter: "saturate(180%) blur(5px)",
        WebkitBackdropFilter: "saturate(180%) blur(20px)",
        display: "flex",
        justifyContent: "space-between",
        padding: "1em 0",
        borderRadius: "1em",
        marginTop: "10%",
      }}>
        <div style={{ width: "10em", marginLeft: "-3em" }}>
          {currentComponent === "homeCollect" ?
            <Button textButton width="full" minHeight="3.7em" fontWeight="600"
              onClick={() => setCurrentComponent("defaultCollect")}>
              voltar
            </Button>
            : <></>
          }
        </div>

        <div style={{ width: "10em" }}>
          <Button
            width="full"
            minHeight="3.7em"
            fontWeight="600"
            onClick={() => handleSchedule()}
            disabled={objects.appointment.query_hour === ""}
            loading={booleans.loadingSubmit}>
            Avançar
          </Button>
        </div>
      </div>
    </>
  );

};