import React from "react";

import { Field, useFormikContext } from "formik";
import { Box } from "styles/globalComponents";

import { useProductCombos } from "Hooks/useProductCombos";

import { AccordionCollapse } from "Components/AccordionCollapse";
import Select from "Components/Form/Select";

import { FormValuesProps, HandleChangeFields } from "../../interface";

export const StatusForm = ({ setSelectField }: HandleChangeFields) => {
  const { errors, values, touched, handleBlur } =
    useFormikContext<FormValuesProps>();

  const { combos } = useProductCombos({ includeCombo: ["*"] });

  return (
    <AccordionCollapse title="Status do Produto">
      <Box mt="15">
        <div className="input-container">
          <Field
            name="status_id"
            id="status_id"
            options={combos?.status}
            onBlur={handleBlur}
            optionName="status"
            value={values.status_id}
            label="Status do Produto*"
            component={Select}
            error={touched.status_id && errors.status_id && errors.status_id}
            onChange={setSelectField}
          />
        </div>
      </Box>
    </AccordionCollapse>
  );
};
