import axios from "axios";
import { getToken } from "Services/Authentication";

const BASE_URL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_WP}/wp-json/api`,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${getToken()}`
  },
});

export const cancelAppointment = async (id: number) => {

  try {
    const data = await API.post(
      `${BASE_URL}/appointments/cancel/${id}`,
      {
        noEmail: true,
        noShow: true
      }
    )

    return { data }
  } catch (error: any) {
    console.error(error)
  }
}