import { Box } from "styles/globalComponents";

import { useBudgetCartModals } from "Hooks/useBudgetCartModals";

import { Button } from "Components/Button";
import { BudgetActionsModals } from "Components/Modals/BudgetActionsModals";

import { ButtonsActionsContainer } from "../style";
import { BudgetActionsProps } from "./interface";

export const BudgetActions = ({ data }: BudgetActionsProps) => {
  const budgetActionsModal = useBudgetCartModals();

  const { onOpenModal, setBudgetModals } = budgetActionsModal;

  const emailLead = data.info?.lead?.email ?? "";
  const emailPatient = data.info.patient?.email ?? "";
  const email = data.info.patient ? emailPatient : emailLead;

  const isBudgetCanceled = data.info.status?.id === 3;
  const disabledMotionBtn = data.info.motion_integrated_at;
  const isCovenant = data.info.is_covenant;

  const openModalsInfo = (modal: string) => {
    onOpenModal(modal);

    if (data?.info) {
      setBudgetModals({ ...data.info, email });
    }
  };

  return (
    <ButtonsActionsContainer display="flex" mt="20">
      {isBudgetCanceled ? null : (
        <Button
          textButton
          color="red"
          onClick={() => openModalsInfo("cancelBudget")}
        >
          Cancelar orçamento
        </Button>
      )}

      <Box display="flex" justify="flex-end">
        <Button textButton onClick={() => openModalsInfo("editBudget")}>
          Editar orçamento
        </Button>
      </Box>

      <Button outlined onClick={() => openModalsInfo("resendEmail")}>
        enviar
      </Button>

      {isCovenant && (
        <Button
          onClick={() => openModalsInfo("motionCovenant")}
          disabled={Boolean(disabledMotionBtn)}
        >
          Enviar para o motion
        </Button>
      )}

      <BudgetActionsModals {...budgetActionsModal} />
    </ButtonsActionsContainer>
  );
};
