interface IQueryParams {
  [key: string]: string;
}

// create a query string from an object
export function createQueryString(params: IQueryParams) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
