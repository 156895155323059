import axios from "axios";
import { getToken } from "Services/Authentication";
import { DependentData } from "./types";

const BASE_URL = process.env.REACT_APP_API_URL;



export const getDependents = async (person_id: number) => {
  try {

    const data = await axios.get(
      `${BASE_URL}/persons/${person_id}/dependents`,
      {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      }
    );

    return data.data
  } catch (error) {
    console.error(error)
  }
}

export const createDependent = async (dependentData: DependentData, responsible_id: number) => {
  try {


    const API = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      },
    });


    const response = await API.post(`/persons/${responsible_id}/dependents`, {
      ...dependentData
    });

    return response.data
  } catch (err) {
    console.error(err)
    throw "Ops! Ocorreu um erro ao criar a dependente";
  }
}

export const updateDependent = async (dependentData: DependentData, responsible_id: number, dependent_id: number) => {
  try {

    const API = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      },
    });

    const response = await API.put(`/persons/${responsible_id}/dependents/${dependent_id}`, {
      ...dependentData
    });
    return response.data
  } catch (err) {
    console.error(err)
    throw "Ops! Ocorreu um erro ao atualizar o dependente";
  }
}