import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import RootReducer from "Reducers";

import RootMiddleware from "Middlewares";

const persistConfig = {
  key: "main",
  storage,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(persistedReducer, RootMiddleware);

const persistor = persistStore(store);

export { store, persistor };
