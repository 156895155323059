import React from "react";

import Input from "Components/Form/Input";
import Select from "Components/Form/Select";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import PanelContent from "Components/Panels/PanelContent";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

import {
  createRegion,
  deleteRegion,
  getRegion,
  getRegions,
  updateRegion,
  getDivisions,
} from "../../../Requests/Units";
import { createToast } from "../../../Utils/toastFunc";
import { ImagesPanel } from "../components/ImagesPanel";

export function ListRegions() {
  const [newRegion, setNewRegion] = React.useState({
    name: "",
    division_id: "",
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [isNumberEdit, setIsNumberEdit] = React.useState(0);
  const [divisions, setDivisions] = React.useState([]);
  const [proFiles, setProFiles] = React.useState([]);
  const [proFilesImageMap, setProFilesImageMap] = React.useState([]);
  const [objectData, setObjectData] = React.useState({
    images_container: [],
    image_map: [],
    images: 0,
    url: "",
    listImages: { value: [], error: "" },
    imageMap: {
      value: "",
    },
  });

  function Loading() {
    return (
      <span className="loading-persons">
        <img src={LoadingImage} alt="" />
      </span>
    );
  }

  async function fetchNewRegion(region) {
    try {
      setLoading(true);

      const formData = new FormData();

      const icon = objectData.images_container[0].image_1;

      formData.append("icon", icon);
      formData.append("region", newRegion.name);
      formData.append("division_id", newRegion.division_id);

      const res = await createRegion(formData);

      if (res) {
        setIsButtonDisabled(true);
        setNewRegion({
          name: "",
          division_id: "",
        });
        setObjectData({
          images_container: [],
          image_map: [],
          images: 0,
          url: "",
          listImages: { value: [], error: "" },
          imageMap: {
            value: "",
          },
        });
        createToast("success", "Nova região criada com sucesso");
      }

      const getRegion = await getRegions();
      setRegions(getRegion);
      setNewRegion({
        name: "",
        division_id: "",
      });

      setLoading(false);
      return res;
    } catch (error) {
      createToast("error", "um erro inesperado ocorreu!");
    }
  }
  async function handleEditRegion() {
    try {
      setLoading(true);

      const formData = new FormData();

      if (objectData?.images_container.length) {
        const icon = objectData?.images_container[0]?.image_1;
        formData.append("icon", icon);
      }

      formData.append("region", newRegion.name);
      formData.append("division_id", newRegion.division_id);

      const res = await updateRegion(isNumberEdit, formData);

      if (res) createToast("success", "região editada com sucesso");

      const getRegion = await getRegions();

      setRegions(getRegion);
      setNewRegion({
        name: "",
        division_id: "",
      });
      setObjectData({
        images_container: [],
        image_map: [],
        images: 0,
        url: "",
        listImages: { value: [], error: "" },
        imageMap: {
          value: "",
        },
      });

      setIsEdit(false);
      setIsNumberEdit(0);
      setIsButtonDisabled(true);

      setLoading(false);
      return res;
    } catch (error) {
      createToast("error", "um erro inesperado ocorreu!");
    }
  }

  function formatDivision(array) {
    const newArray = array.map((item) => ({
      id: item.id,
      name: item.division,
    }));
    return newArray;
  }

  async function fetchList() {
    try {
      setLoading(true);
      const res = await getRegions();
      const response = await getDivisions();

      setDivisions(formatDivision(response));
      setRegions(res);
      setLoading(false);
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  async function DeleteRegion(id) {
    try {
      setLoading(true);
      const res = await deleteRegion(id);
      if (res.status === 200) {
        const res = await getRegions();
        setRegions(res);

        if (id === isNumberEdit) {
          setIsEdit(false);
          setNewRegion({
            name: "",
            division_id: "",
          });
          setIsNumberEdit(0);
        }
        createToast("success", "Região deletada com sucesso!");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  async function EditRegion(id) {
    try {
      setLoading(true);
      const res = await getRegion(id);
      setIsEdit(true);

      if (res.image)
        setObjectData({
          ...objectData,
          images: 1,
          listImages: { value: [{ img: res.image, id: 1 }], error: "" },
        });
      setProFiles([]);

      setNewRegion({
        name: res.region,
        division_id: res.division_id,
      });
      setIsNumberEdit(res.id);

      setLoading(false);
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  const handleCreateRegion = () => fetchNewRegion(newRegion);
  const handleEdit = (id) => EditRegion(id);

  function sendFormDataImageMap(files) {
    const [file] = files;
    setObjectData((prev) => ({
      ...prev,
      image_map: [file],
    }));
    setProFilesImageMap([file]);
  }

  function sendFormData(files) {
    const [file] = files;

    setObjectData((prev) => {
      return {
        ...prev,
        images: 1,
        images_container: [
          {
            [`image_${1}`]: file,
          },
        ],
      };
    });

    setProFiles([file]);
  }

  React.useEffect(() => {
    if (objectData.images === 0 && newRegion == "") {
      setIsButtonDisabled(true);
    }
    if (newRegion != "" && objectData.images > 0) {
      setIsButtonDisabled(false);
    }
  }, [objectData, newRegion]);

  React.useEffect(() => {
    fetchList();
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Todas as Regiões">
              <Breadcrumb
                list={[
                  {
                    path: "/unidades",
                    label: "Unidades",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Todas regiões",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
        </div>
        <div className="page-content page-content-list">
          <div className="row">
            <div className="md-6">
              <PanelContent
                title={isEdit ? "Editar Região" : "Registrar Região"}
                loading={loading}
              >
                <ImagesPanel
                  loading={loading}
                  setLoading={setLoading}
                  sendFormData={sendFormData}
                  proFiles={proFiles}
                  proFilesImageMap={proFilesImageMap}
                  sendFormDataImageMap={sendFormDataImageMap}
                  objectData={objectData}
                  setObjectData={setObjectData}
                  isRegion
                />
                <Input
                  required
                  id="newRegion"
                  value={newRegion.name}
                  onChange={({ target }) =>
                    setNewRegion({ ...newRegion, name: target.value })
                  }
                  type="text"
                  placeholder="Digite o nome da Região"
                />

                <Select
                  required
                  label="Divisão*"
                  id="type"
                  placeholder="Informe a divisão"
                  value={newRegion.division_id}
                  error={newRegion.error}
                  options={divisions}
                  onChange={({ target }) =>
                    setNewRegion({ ...newRegion, division_id: target.value })
                  }
                />

                <div
                  className="button-holder text-right"
                  style={{ marginBottom: "10px" }}
                >
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className="button _action"
                    onClick={isEdit ? handleEditRegion : handleCreateRegion}
                  >
                    {isEdit ? "Editar" : "Criar"} Região
                  </button>
                </div>
              </PanelContent>
            </div>
            <div className="md-6">
              <table className="table-content small-separate details">
                <thead>
                  <tr>
                    <th>Imagem</th>
                    <th>Região</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {regions.length > 0 && !loading ? (
                    regions.map((region) => (
                      <tr key={region.id}>
                        <td>
                          {region?.image ? (
                            <img
                              src={region?.image}
                              alt={region?.region}
                              className="image-region"
                            />
                          ) : (
                            <div className="box-fake-image">
                              <div className="fake-image" />
                            </div>
                          )}
                        </td>
                        <td>{region.region}</td>
                        <td />
                        <td className="text-right table-action regionTable">
                          <button
                            type="button"
                            className="button _blue _action"
                            onClick={() => handleEdit(region.id)}
                          >
                            Editar
                          </button>
                          <button
                            type="button"
                            className="button _yellow _action"
                          >
                            <span
                              className="details-text"
                              onClick={() => DeleteRegion(region.id)}
                            >
                              Excluir
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center text-purple">
                        {loading ? (
                          <Loading />
                        ) : (
                          "Oops! Parece que nada foi encontrado..."
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
