import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Element } from "react-scroll";

import { Formik, Form, Field } from "formik";
import { Header, PaginationWrapper } from "Pages/HomeOrders/styles";
import { ListTable } from "Pages/PersonDuplicated/components/ListTable";
import {
  ComponentPaginationProps,
  DataPersonPagination,
  DuplicatedPerson,
  DataPersonsDuplicate,
  SearchFormInterface,
  FetchDataInterface,
} from "Pages/PersonDuplicated/interface";
import { SearchContent } from "Pages/PersonDuplicated/styles";

import { allDuplicatedList } from "Requests/Person";

import { selectPersonDuplicatedFlow } from "Actions/DuplicatePerson";

import { createToast } from "Utils/toastFunc";

import InputControlled from "Components/Form/Controlled/Input";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

export const List = () => {
  const dispatch = useDispatch();
  const formikRef = useRef<any>(null);
  const [data, setData] = useState<DuplicatedPerson[]>();
  const [pagination, setPagination] = useState<DataPersonPagination>({
    current_page: 1,
    total_pages: 1,
    total: 1,
  });
  const [loading, setLoading] = useState(false);
  const [formSearch, setFormSearch] = useState<SearchFormInterface>({
    search: "",
  });
  async function handlePagination(e: ComponentPaginationProps) {
    if (pagination?.current_page === e.currentPage) return;
    await fetchData({ page: e.currentPage, formSearch });
  }

  async function fetchData({ formSearch, page }: FetchDataInterface) {
    try {
      setLoading(true);

      const response = await allDuplicatedList(page, formSearch);

      const dataResponse: DataPersonsDuplicate = response.data;
      setData(dataResponse.data);

      setPagination({
        current_page: dataResponse.current_page,
        total_pages: dataResponse.last_page,
        total: dataResponse.total,
      });

      return dataResponse.data;
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao solicitar as pessoas duplicadas!"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const space = formSearch.search === "";
    fetchData({ formSearch });
  }, [formSearch]);

  useEffect(() => {
    dispatch(
      selectPersonDuplicatedFlow({
        origin: "duplicated-list",
      })
    );
  }, []);

  function handleSubmitSearch(values: SearchFormInterface) {
    setFormSearch(values);
  }
  return (
    <PageWrapper className="entry-app-content order-home-list">
      <div className="content-holder">
        <Header>
          <h1>Cadastros Duplicados</h1>
          <Breadcrumb
            list={[
              {
                path: "/pessoas",
                label: "PESSOAS",
                current: false,
              },
              {
                path: "#",
                label: "CADASTROS DUPLICADOS",
                current: true,
              },
            ]}
          />
        </Header>

        <div>
          <div className="page-content page-content-list" id="ScrollDomiciliar">
            <Element name="ScrollableDomiciliarOrders" />
            <Formik
              initialValues={formSearch}
              validateOnBlur
              enableReinitialize
              validateOnChange={false}
              onSubmit={handleSubmitSearch}
              innerRef={formikRef}
            >
              {({ values }) => {
                return (
                  <Form>
                    <SearchContent>
                      <Field
                        placeholder="Buscar por Nome, CPF ou Telefone."
                        name="search"
                        type="text"
                        className="input _opacity-gray"
                        component={InputControlled}
                      />
                      <button type="submit" className="button _action">
                        Filtrar
                      </button>
                      {formSearch.search !== "" && (
                        <div>
                          <button
                            type="button"
                            className="button _transparentButton _alt"
                            onClick={() => handleSubmitSearch({ search: "" })}
                          >
                            Limpar
                          </button>
                        </div>
                      )}
                    </SearchContent>
                  </Form>
                );
              }}
            </Formik>

            <ListTable data={data} loading={loading} />
          </div>
        </div>
      </div>

      <PaginationWrapper style={{ justifyContent: "center" }}>
        {!loading && (
          <Pagination
            totalRecords={pagination?.total_pages}
            currentPageSelected={pagination?.current_page}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e: ComponentPaginationProps) => handlePagination(e)}
          />
        )}
      </PaginationWrapper>
    </PageWrapper>
  );
};
