import React from "react";

import { getIn } from "formik";

import { TextEllipsis } from "Utils/Uploads";

const SelectControlled = ({
  name,
  label,
  value,
  required,
  purple,
  onChange,
  placeholder,
  options,
  small,
  onFocus,
  optionName,
  readOnly,
  field,
  form,
}) => {
  const error = getIn(form.errors, field.name);
  const submited = getIn(form.submitCount);

  return (
    <div
      className={`
            field-holder 
            ${error && submited ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={name}
          className={`text-gray text-semibold ${purple && "_purple"}`}
        >
          {label}
        </label>
      )}
      <div className="select-field-holder">
        <select
          className="input select _opacity-gray"
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          required={required && "Obrigatório"}
          disabled={readOnly}
          onFocus={onFocus}
          {...field}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options?.map((option) => (
            <option key={option.id} value={option.id}>
              {option[optionName] || TextEllipsis(option.name, 30)}
            </option>
          ))}
        </select>
        {!readOnly && <span className="arrow-icon">{">"}</span>}
      </div>
      {error && submited > 0 && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default SelectControlled;
