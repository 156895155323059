import { useContext } from "react";

import { BudgetContext } from "Context/Budget";

export const useBudgetContext = () => {
  const budgetContext = useContext(BudgetContext);

  if (budgetContext === null) {
    throw new Error("Context not provided");
  }

  return {
    ...budgetContext,
  };
};
