import React, { useState } from "react";
import Modal from "react-modal";

import InfoImage from "Pages/Orders/ListPlansOrders/components/DetailsInfoImage";

import { useRegions } from "Hooks/useRegions";

import { TextEllipsis } from "Utils/String";
import { showUnitsAvailable } from "Utils/Units/Units";

import { Accordion } from "Components/Accordion";
import { LoadingText } from "Components/LoadingText";

import CloseModal from "Assets/Images/close-modal.svg";

import { AccordionStates, ModalViewInsuranceProps } from "./interface";
import {
  ContentArticle,
  ContentTitle,
  Line,
  ListUl,
  Table,
  UnitsContainer,
} from "./styles";

export function ModalViewInsurance({
  modalVisible,
  modalDismiss,
  covenantInfo,
  loadingOrder,
}: ModalViewInsuranceProps) {
  const [accordionStates, setAccordionStates] = useState<AccordionStates>({
    resume: true,
    unitsServe: true,
    checklist: true,
    plans: true,
    access: true,
    contact: true,
    annexe: true,
    channels: true,
  });

  const { regions } = useRegions();

  const handleAccordion = (accordionName: keyof AccordionStates) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  const covenant = {
    ...covenantInfo,
    units: showUnitsAvailable(covenantInfo?.units, regions),
  };

  const unitHeight =
    covenant?.units
      ?.map((unit: any) => (unit.units?.length + 1) * 30.8)
      .sort((a: any, b: any) => a - b)
      .filter(Boolean)
      .at(-1) ?? 0;

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header modal-covenant modal-overflow scroll-purple"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      {loadingOrder ? (
        <LoadingText text="Carregando dados do convênio" />
      ) : (
        <>
          <ContentTitle>
            <img src={covenant?.image} alt="" />
            <p>
              Informações sobre convênio <span>{covenant?.covenant}</span>
            </p>

            <button onClick={modalDismiss}>
              <img src={CloseModal} alt="fechar" />
            </button>
          </ContentTitle>

          <ContentArticle>
            <Accordion
              title="Resumo"
              handleOpen={() => handleAccordion("resume")}
              open={accordionStates.resume}
              notCenter={false}
            >
              <Line />
              {accordionStates.resume && <p>{covenant?.description}</p>}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Unidades que atendem"
              handleOpen={() => handleAccordion("unitsServe")}
              open={accordionStates.unitsServe}
              notCenter={false}
            >
              <Line />

              {accordionStates.unitsServe && covenant?.units && (
                <UnitsContainer height={unitHeight} className="">
                  {covenant?.units.map((region: any) => (
                    <Table key={region.id}>
                      <h5>{region.region}</h5>
                      <div className="table-line">
                        {region?.units.map((unit: any) => (
                          <div className="division" key={unit.id}>
                            <p>{TextEllipsis(unit.name, 15)}</p>
                          </div>
                        ))}
                      </div>
                    </Table>
                  ))}
                </UnitsContainer>
              )}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Checklist"
              handleOpen={() => handleAccordion("checklist")}
              open={accordionStates.checklist}
              notCenter={false}
            >
              <Line />

              {accordionStates.checklist && (
                <ListUl>
                  {covenant?.covenant_checklists?.map(
                    (item: any, index: any) => (
                      <li key={index}>{item.checklist_item}</li>
                    )
                  )}
                </ListUl>
              )}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Planos que atendem"
              handleOpen={() => handleAccordion("plans")}
              open={accordionStates.plans}
              notCenter={false}
            >
              <Line />

              {accordionStates.plans && (
                <ListUl>
                  {covenant?.covenant_plans?.map((item: any, index: any) => (
                    <li key={index}>{item.covenant_plans_name}</li>
                  ))}
                </ListUl>
              )}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Acessos"
              handleOpen={() => handleAccordion("access")}
              open={accordionStates.access}
              notCenter={false}
            >
              <Line />

              {accordionStates.access && <p>{covenant?.access}</p>}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Contato"
              handleOpen={() => handleAccordion("contact")}
              open={accordionStates.contact}
              notCenter={false}
            >
              <Line />

              {accordionStates.contact && <p>{covenant?.contact}</p>}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Anexos"
              handleOpen={() => handleAccordion("annexe")}
              open={accordionStates.annexe}
              notCenter={false}
            >
              <Line />

              {accordionStates.annexe && (
                <div className="row">
                  {covenant?.covenant_images?.map((item: any, index: any) => (
                    <div className="md-6" key={index}>
                      <InfoImage
                        url={item?.img}
                        name={item?.name}
                        type={item.type}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Accordion>
          </ContentArticle>

          <ContentArticle>
            <Accordion
              title="Canais"
              handleOpen={() => handleAccordion("channels")}
              open={accordionStates.channels}
              notCenter={false}
            >
              <Line />

              {accordionStates.channels && (
                <ListUl>
                  {covenant?.channels?.map(
                    (channel: { name: string; id: number }) => (
                      <li key={channel.id}>{channel.name}</li>
                    )
                  )}
                </ListUl>
              )}
            </Accordion>
          </ContentArticle>
        </>
      )}
    </Modal>
  );
}
