import UploadAreaAnnex from "Components/Form/UploadAnnex";

interface AttachFilesProps {
  openFiles: any;
  closeModal: any;
  budget: any;
}

export const AttachFiles = ({
  budget,
  closeModal,
  openFiles,
}: AttachFilesProps) => {
  return (
    <div>
      {/* @ts-ignore */}
      <UploadAreaAnnex
        uploadFile={openFiles}
        formDataFileString="documents[]"
        fileAccept=".jpeg, .png, .pdf"
        importPath={`/budgets/documents/upload/${budget?.id}`}
        multi
        children={
          <button className="button transparent-btn" onClick={closeModal}>
            VOLTAR
          </button>
        }
      />
    </div>
  );
};
