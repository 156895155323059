import Axios from "axios";
import { getToken } from "Services/Authentication";


export const updatePersonBudget = async (budget_id: number, person_id: number) => {

  try {
    const API = Axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      },
    });

    const response = await API.post(`/budgets/${budget_id}/update_person/${person_id}`);

    return response.data

  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao atualizar o orçamento";
  }

}