import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as yup from "yup";

import {
  getOptionsRoles,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  activateUser,
} from "Requests/Users";

import { createToast } from "Utils/toastFunc";
import validateSchema from "Utils/validateSchema";
import { YupValidateCpf } from "Utils/Validator";

import { initialStateMessages } from "mocks";

import Checkbox from "Components/Form/Checkbox";
import Input from "Components/Form/Input";
import Select from "Components/Form/Select";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import PanelContent from "Components/Panels/PanelContent";
import Breadcrumb from "Components/Shared/Breadcrumb";
import SwitchInput from "Components/Switch/Input";

import { FormatterUser } from "../../Requests/Formatters/Users";
import { getAllUnits } from "../../Requests/Units";

const emptyForm = {
  status: "",
  name: "",
  cpf: "",
  email: "",
  role: "",
  password: "",
  password_confirmation: "",
  all_units: false,
  units: [],
};

function ViewUser({ match }) {
  const history = useHistory();
  const user_update = useRef(match.params.id && match.params.id > 0);
  const user_logged = useSelector((state) => state.User.userData);
  const [objectData, setObjectData] = useState(emptyForm);
  const [fieldsErrors, setFieldsErrors] = useState(emptyForm);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(initialStateMessages);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    fetchOptionsRoles();

    if (user_update.current) fetchUser();
  }, []);

  useEffect(() => {
    if (
      Object.keys(messages).some(
        (item) => item !== "wait" && messages[item].length
      )
    ) {
      setTimeout(() => {
        setMessages && setMessages(initialStateMessages);
      }, 3000);
    }
  }, [messages]);

  React.useEffect(() => {
    fetchUnits();
  }, []);

  async function fetchUnits() {
    try {
      setLoading(true);

      const { data } = await getAllUnits(null, "user_units");

      const newArray = data.map((item) => ({ id: item.id, option: item.name }));
      setOptions(newArray);

      setLoading(false);
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  async function fetchOptionsRoles() {
    await getOptionsRoles()
      .then((res) => setOptionsRoles(res))
      .catch((error) =>
        handleMessageChange(
          "Ocorreu um erro ao buscar as opções de regras de usuários!",
          "error"
        )
      );
  }

  async function fetchUser() {
    setLoading(true);
    handleMessageChange("Carregando informações do usuário!", "wait");

    try {
      const user = await getUser(match.params.id);

      setObjectData({
        ...objectData,
        ...user,
      });

      if (match.params.id > 0) {
        if (user.units.length) {
          const newArray = user.units.map((item) => ({
            id: item.id,
            option: item.name,
          }));
          setObjectData((prev) => ({
            ...prev,
            units: newArray,
          }));
        }
      }

      handleMessageChange("Carregado com sucesso!", "sucesso");
    } catch (error) {
      handleMessageChange(
        "Ocorreu um erro ao buscar as informações do usuário!",
        "error"
      );
    }

    setLoading(false);
  }

  function handleMessageChange(value, name) {
    setMessages({
      ...messages,
      [name]: value,
    });
  }

  function handleChange({ target: { value, name } }) {
    setObjectData({
      ...objectData,
      [name]: value,
    });
  }

  function handleFocus({ target: { name } }) {
    setFieldsErrors({
      ...fieldsErrors,
      [name]: "",
    });
  }

  async function handleCreateUser(data) {
    setLoading(true);
    handleMessageChange("Realizando cadastro do usuário", "wait");
    try {
      const formatter = FormatterUser(data);
      await createUser(formatter);

      handleMessageChange("Usuário cadastrado com sucesso!", "success");

      setTimeout(() => history.push("/usuarios"), 2000);
    } catch (error) {
      const errors = error?.response?.data?.errors;
      const fieldsWithErrors = Object.keys(error.response.data.errors)[0];

      const errorMessage =
        errors[fieldsWithErrors][0] || "Falha ao cadastrar o usuário!";
      handleMessageChange(errorMessage, "error");
    }

    setLoading(false);
  }

  async function handleUpdateUser(data) {
    setLoading(true);
    handleMessageChange("Atualizando dados do usuário", "wait");

    try {
      const user_active = !data.deleted_at;
      const changed_status = user_active !== data.status;
      const formatter = FormatterUser(data);
      await updateUser(formatter);

      if (changed_status)
        (await data.status) ? activateUser(data.id) : deleteUser(data.id);

      handleMessageChange("Dados atualizados com sucesso!", "success");

      setTimeout(() => history.push("/usuarios"), 2000);
    } catch (error) {
      const errors = error?.response?.data?.errors;

      const fieldsWithErrors = Object.keys(error.response.data.errors)[0];

      const errorMessage =
        errors[fieldsWithErrors][0] ||
        "Falha ao atualizar os dados do usuário!";
      handleMessageChange(errorMessage, "error");
    }

    setLoading(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const schema = {
        update: yup.bool().default(user_update.current),
        name: yup.string().required("O campo é obrigatório"),
        cpf: yup
          .string()
          .required("O campo é obrigatório")
          .test("YupValidateCpf", "Informe um CPF válido", YupValidateCpf),
        email: yup
          .string()
          .required("O campo é obrigatório")
          .email("informe um endereço de email válido"),
        role: yup.string().required("O campo é obrigatório"),
        password: yup
          .string()
          .min(6, "A senha precisa ter no mínimo 6 caracteres")
          .when("update", (update, schema) => {
            return update ? schema : schema.required("O campo é obrigatório");
          }),
        password_confirmation: yup
          .string()
          .when("password", (password, schema) => {
            return password
              ? schema.oneOf(
                  [yup.ref("password")],
                  "As senhas devem ser iguais"
                )
              : schema;
          }),
      };

      const _objectData = { ...objectData };

      if (user_update.current && !_objectData.password) {
        delete _objectData.password;
        delete _objectData.password_confirmation;
      }

      await validateSchema(schema, _objectData);

      user_update.current
        ? handleUpdateUser(_objectData)
        : handleCreateUser(_objectData);
    } catch (error) {
      setFieldsErrors({ ...fieldsErrors, ...error });
    }
  }

  function _renderMessages({ success, wait, error }) {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  }

  React.useEffect(() => {
    objectData.all_units &&
      setObjectData((prev) => ({
        ...prev,
        units: [],
      }));
  }, [objectData.all_units]);

  return (
    <PageWrapper className="entry-app-content">
      <form
        className="content-holder content-holder-no-filter remove-padding"
        onSubmit={handleSubmit}
      >
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader
              title={match.params.id > 0 ? "Editar Usuário" : "Criar Usuário"}
            >
              <Breadcrumb
                list={[
                  {
                    path: "/usuarios",
                    label: "Usuários",
                    current: false,
                  },
                  {
                    path: "/usuarios",
                    label: "Todos os usuários",
                    current: false,
                  },
                  {
                    path: "#",
                    label:
                      match.params.id > 0 ? "Editar Usuário" : "Criar Usuário",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
            <div className="actions-holder">
              <button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                className="link link-danger"
              >
                Voltar
              </button>
              <button type="submit" className="button _action">
                {match.params.id > 0 ? "Editar" : "Criar"}
              </button>
            </div>
          </div>
          {_renderMessages(messages)}
        </div>
        <div className="page-content">
          <div className="row">
            <div className="md-12">
              <PanelContent title="Informações Do Usuário" loading={loading}>
                <div className="row">
                  {user_update.current && user_logged.id !== objectData.id && (
                    <div className="sm-12 md-12">
                      <SwitchInput
                        label="Status"
                        id="status"
                        onChange={(value, name) =>
                          handleChange({ target: { value, name } })
                        }
                        value={objectData.status}
                        textOn="Ativo"
                        textOff="Inativo"
                      />
                    </div>
                  )}
                  <div className="sm-12 md-6">
                    <Input
                      label="Nome"
                      id="name"
                      type="text"
                      value={objectData.name}
                      error={fieldsErrors.name}
                      placeholder="Nome*"
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="sm-12 md-6">
                    <Input
                      label="CPF"
                      id="cpf"
                      type="text"
                      value={objectData.cpf || ""}
                      error={fieldsErrors.cpf}
                      mask="999.999.999-99"
                      placeholder="XXX.XXX.XXX-XX"
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="sm-12 md-6">
                    <Input
                      label="Email"
                      id="email"
                      type="text"
                      value={objectData.email}
                      error={fieldsErrors.email}
                      placeholder="Email*"
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </div>

                  <div className="sm-12 md-6">
                    <Select
                      label="Nível de Acesso"
                      id="role"
                      placeholder="-"
                      value={objectData.role}
                      error={fieldsErrors.role}
                      onChange={handleChange}
                      options={optionsRoles}
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="sm-12 md-6">
                    <Input
                      label="Senha"
                      id="password"
                      type="password"
                      placeholder="******"
                      value={objectData.password}
                      error={fieldsErrors.password}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="sm-12 md-6">
                    <Input
                      label="Confirmar Senha"
                      id="password_confirmation"
                      type="password"
                      placeholder="******"
                      value={objectData.password_confirmation}
                      error={fieldsErrors.password_confirmation}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="sm-12 md-6">
                    <SelectSearch
                      label="Selecione as Unidades"
                      id="units"
                      type="text"
                      value={objectData.units}
                      error={
                        fieldsErrors.units.length <= 0 ? "" : fieldsErrors.units
                      }
                      isMulti
                      options={options}
                      loading={options?.length === 0}
                      readOnly={options?.length === 0 || objectData.all_units}
                      valueKey="id"
                      labelKey="option"
                      placeholder={
                        objectData.all_units
                          ? "Todas unidades selecionadas"
                          : "Selecione as Unidades"
                      }
                      onChange={(value) =>
                        setObjectData((prev) => ({
                          ...prev,
                          units: value,
                        }))
                      }
                    />
                  </div>
                  <div className="sm-12 md-6">
                    <Checkbox
                      id="all_units"
                      label=" "
                      descriptionText="Todas Unidades?"
                      value={objectData.all_units}
                      onChange={() => {
                        setFieldsErrors((prev) => ({ ...prev, units: "" }));
                        return setObjectData((prev) => ({
                          ...prev,
                          all_units: !prev.all_units,
                        }));
                      }}
                    />
                  </div>
                </div>
              </PanelContent>
            </div>
          </div>
        </div>
      </form>
    </PageWrapper>
  );
}

export default ViewUser;
