import React from "react";

import { TextEllipsis } from "Utils/Uploads";

const Select = ({
  id,
  label = "",
  value,
  error = "",
  required = false,
  purple = false,
  onChange,
  placeholder,
  options,
  small = false,
  onFocus = () => {},
  optionName,
  readOnly = false,
  onBlur = undefined,
}) => {
  return (
    <div
      className={`
            field-holder 
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple && "_purple"}`}
        >
          {label}
        </label>
      )}
      <div className="select-field-holder">
        <select
          className="input select _opacity-gray"
          name={id}
          id={id}
          value={value}
          onChange={onChange}
          required={required && "required"}
          disabled={readOnly}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {!options && !placeholder && (
            <option value={value?.id}>{value?.category}</option>
          )}

          {placeholder && <option value="">{placeholder}</option>}
          {options?.map((option) => (
            <option
              key={option.id}
              value={option.id}
              disabled={option?.disabled}
            >
              {option[optionName] || TextEllipsis(option.name, 30)}
            </option>
          ))}
        </select>
        {!readOnly && <span className="arrow-icon">{">"}</span>}
      </div>

      {error !== "" && (
        <span
          className={`error-message ${
            !label && !error ? "error-without-label" : ""
          }`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Select;
