import React, { useState, useRef } from "react";

import API from "Services/Api";

import ShowFileFormat from "Utils/ShowFileFormat";

import { createToast } from "../../Utils/toastFunc";
import Dropzone from "./Dropzone";
import ProgressBar from "./ProgressBar";




const UploadArea = (props) => {
  const [uploading, setUploading] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const uploadProgressRef = useRef(uploadProgress);

  function mimeType(file_name) {
    const split_name = file_name.split(".");
    const type = split_name[split_name.length - 1];

    switch (type) {
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      case "xls":
        return "application/vnd.ms-excel";

      default:
        return "text/csv";
    }
  }

  function blobToFile(theBlob, fileName) {
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  function onFilesAdded(newFiles) {
    const listFiles = files.concat(newFiles);
    if (props.only && listFiles.length > 1) {
      createToast("error", "Você pode importar somente um arquivo.");
      setFiles([]);
    } else {
      setFiles(listFiles);
    }
  }

  const setStateRef = (data, stateRef, callback) => {
    stateRef.current = data;

    callback();
  };

  function sendRequest(file) {
    const formData = new FormData();
    if (props.baseFormData) {
      const basedFormData = props.baseFormData();

      for (const pair of basedFormData.entries()) {
        formData.append(pair[0], pair[1]);
      }
    }

    if (file.type === "") {
      const blob = new Blob([file], { type: mimeType(file.name) });

      formData.append(
        props.formDataFileString,
        blobToFile(blob, file.name),
        file.name
      );
    } else {
      formData.append(props.formDataFileString, file, file.name);
    }

    const configAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      onUploadProgress: (event) => {
        if (event.lengthComputable) {
          const copy = { ...uploadProgressRef.current };

          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          };

          setStateRef(copy, uploadProgressRef, () => setUploadProgress(copy));
        }
      },
    };

    return API.post(props.importPath, formData, configAxios)
      .then((response) => {
        const copy = { ...uploadProgressRef.current };

        copy[file.name] = {
          state: "done",
          percentage: 100,
        };

        setStateRef(copy, uploadProgressRef, () => setUploadProgress(copy));

        props.finished && props.finished(response.data);

        return response;
      })
      .catch((error) => {
        const copy = { ...uploadProgressRef.current };

        copy[file.name] = {
          state: "upload_error",
          percentage: 0,
        };

        setStateRef(copy, uploadProgressRef, () => setUploadProgress(copy));

        return error.response;
      });
  }

  async function uploadFiles() {
    setUploading(true);

    const promises = [];
    files.forEach((file) => {
      promises.push(sendRequest(file));
    });

    try {
      const response = await Promise.all(promises);

      setSuccessUpload(true);
      setUploading(false);

      props.uploadFile && props.uploadFile(response);

      setTimeout(() => {
        setStateRef({}, uploadProgressRef, () => setUploadProgress({}));

        setFiles([]);

        setSuccessUpload(false);
        setUploading(false);
      }, 5000);
    } catch (error) {
      setSuccessUpload(true);
      setUploading(false);
    }
  }

  function _allowExtensions() {
    return <span>* Formatos permitidos: {props.fileAccept}</span>;
  }

  function _renderValidateFiles(file) {
    let message;

    switch (uploadProgressRef.current[file.name].state) {
      case "upload_done":
        message = (
          <span className="text-success">
            Arquivo importado com sucesso. Aguarde enquanto processamos o
            arquivo...
          </span>
        );
        break;
      case "done":
        message = (
          <span className="text-success">Arquivo processado com sucesso!</span>
        );
        break;
      case "upload_error":
        message = (
          <span className="text-danger">
            Oops, essa não! Parece que tivemos um problema para importar o seu
            arquivo...
          </span>
        );
        break;
      case "error":
        message = (
          <span className="text-danger">
            Oops, essa não! Parece que tivemos um problema para processar o seu
            arquivo...
          </span>
        );
        break;
      default:
        message = (
          <span className="text-gray">
            Aguarde enquanto importamos o seu arquivo...
          </span>
        );
        break;
    }

    return <div className="upload-feedback">{message}</div>;
  }

  function _renderActions() {
    return (
      <>
        {_allowExtensions()}
        <div className="holder-actions">
          {successUpload ? (
            <button
              className="button _danger"
              onClick={() => {
                setFiles([]);
                setSuccessUpload(false);
              }}
            >
              Limpar
            </button>
          ) : (
            <button
              className="button _white"
              disabled={files.length === 0 || uploading}
              onClick={uploadFiles}
            >
              Importar
            </button>
          )}
        </div>
      </>
    );
  }

  function _renderProgress(file) {
    const uploadProgressBar = uploadProgressRef.current[file.name];

    if (uploading || successUpload) {
      return (
        <div
          className={`
                    progress-wrapper
                    ${
                      uploadProgressBar && uploadProgressBar.state === "done"
                        ? "--success"
                        : ""
                    }
                `}
        >
          <ProgressBar
            progress={uploadProgressBar ? uploadProgressBar.percentage : 0}
          />
        </div>
      );
    }
  }
  return (
    <div className="upload-area">
      <div className="content">
        <Dropzone
          fileAccept={props.fileAccept}
          multi={props.multi}
          onFilesAdded={onFilesAdded}
          disabled={uploading || successUpload}
          elementDescr={
            <div className="upload-area-info">
              {props.write
                ? props.write
                : "Arraste ou clique aqui para selecionar os arquivos"}
            </div>
          }
        />
        {props.typeCustom && (
          <div className="file-info">
            {files.map((file) => {
              return (
                <div className="file-upload" key={file.name}>
                  <div className="info">
                    <p>
                      <strong>Nome do arquivo:</strong>
                      {file.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {!props.typeCustom && (
        <div className="file-info">
          {files.map((file) => {
            return (
              <div className="file-upload" key={file.name}>
                <div className="info">
                  <p>
                    <strong>Nome do arquivo:</strong>
                    {file.name}
                  </p>
                  <p>
                    <strong>Formato:</strong>
                    {ShowFileFormat(
                      file.type ? file.type : mimeType(file.name)
                    )}
                  </p>
                </div>
                {_renderProgress(file)}
                {uploadProgressRef.current[file.name] &&
                  _renderValidateFiles(file)}
              </div>
            );
          })}
        </div>
      )}
      <div className="actions">{_renderActions()}</div>
    </div>
  );
};

UploadArea.defaultProps = {
  multi: false,
  fileAccept: ".xls, .xlsx, .csv",
};

export default UploadArea;
