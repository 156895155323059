import React from "react";
import Modal from "react-modal";

import {Loading} from 'Utils/Loading'

import PageHeader from "Components/Pages/Header";

export function ModalSendMotionProduct({
  modalVisible,
  modalDismiss,
  order,
  unit,
  loading,
  handleSubmit
}) {

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="modal-wrapper-header">
        <PageHeader title={`Você deseja realmente enviar ao Motion o pedido Nº ${order}?`} />
      </div>
      {loading ? (
        <div style={{marginTop: "1rem"}}>
          <Loading classCss="loading-centered"/>
        </div>
      ): (
        <>
          <div className="flexItems" style={{marginTop: "1rem", flexWrap: "wrap"}}>
            <label>Unidade selecionada:</label>
            <p style={{marginTop: ".5rem"}}>{unit}</p>
          </div>
          <div className="description-list modal-close-badge-flex">
              <div className="cart-flex-buttons">
                    <button className="button" onClick={handleSubmit}>Confirmar</button>
    
                    <button className="button closeButton" onClick={modalDismiss}>Voltar</button>
              </div>
          </div>
        </>
      )}
    </Modal>
  );
}

