import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Element } from "react-scroll";

import { IPersonData } from "Dtos/IPerson";
import { Formik, Form } from "formik";
import { Header } from "Pages/HomeOrders/styles";
import { ModalHandler } from "Pages/RegistrationCleaning/components/ModalHandler";
import {
  ModalInterface,
  RouteParamsInterface,
  DataPerson,
} from "Pages/RegistrationCleaning/interface";
import {
  TitleSectionReview,
  LeftPaddingContainer,
  Footer,
} from "Pages/RegistrationCleaning/styles";

import { duplicateRegistration, updatePersonDuplicate } from "Requests/Person";

import {
  selectPersonDuplicate,
  resetPersonDuplicatedFlow,
} from "Actions/DuplicatePerson";
import { selectPacient } from "Actions/ServiceForm";

import { Store } from "Reducers/interface";

import { createToast } from "Utils/toastFunc";

import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { ReviewForm } from "./ReviewForm";
import { schema } from "./schema";

export const Review = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef<any>(null);
  const { document } = useParams<RouteParamsInterface>();
  const [modalResource, setModalResource] = useState<ModalInterface>({
    isVisible: false,
    handleDismiss: () => {},
    title: "",
    modal: "default",
    handleModal: () => {},
    messageButton: "",
    messageButtonCancelModal: "",
    icon: "",
  });

  const { PersonDuplicate, PersonDuplicatedFlow } = useSelector(
    (store: Store) => store
  );

  const [formDocuments, setFormDocuments] = useState<IPersonData>({
    ...PersonDuplicate,
    edit: {
      ...PersonDuplicate.edit,
      type_document:
        PersonDuplicate?.edit?.documents &&
        PersonDuplicate?.edit?.documents[0]?.type,
      ...(PersonDuplicate?.edit?.documents &&
      PersonDuplicate?.edit?.documents[0]?.type === "cpf"
        ? {
            cpf:
              PersonDuplicate?.edit?.documents &&
              PersonDuplicate?.edit?.documents[0]?.number,
          }
        : {
            passport:
              PersonDuplicate?.edit?.documents &&
              PersonDuplicate?.edit?.documents[0]?.number,
            passport_issuing_country:
              PersonDuplicate?.edit?.documents &&
              PersonDuplicate?.edit?.documents[0]?.country_id,
          }),
      email: PersonDuplicate?.edit?.default_email?.email,
      phone: PersonDuplicate?.edit?.default_phone
        ? `${PersonDuplicate?.edit?.default_phone?.ddi}${PersonDuplicate?.edit?.default_phone?.ddd}${PersonDuplicate?.edit?.default_phone?.phone}`
        : undefined,
    },
    dependents: [
      ...PersonDuplicate?.dependents?.map((item: DataPerson) => {
        return {
          ...item,
          type_document: item.documents && item.documents[0]?.type,

          ...(item.documents && item.documents[0]?.type === "cpf"
            ? {
                cpf: item.documents && item.documents[0]?.number,
              }
            : {
                passport: item.documents && item.documents[0]?.number,
                passport_issuing_country:
                  item.documents && item.documents[0]?.country_id,
              }),

          email: item?.default_email?.email,
          phone: item?.default_phone
            ? `${item?.default_phone?.ddd}${item?.default_phone?.phone}`
            : undefined,
        };
      }),
    ],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!PersonDuplicate?.main) {
      return history.push(`/limpeza-cadastro/${document}/merge`);
    }

    setModalResource({
      ...modalResource,
      isVisible: true,
      modal: "validation_modal",
      handleModal: () =>
        setModalResource({ ...modalResource, isVisible: false }),
    });

    fetchData();
  }, []);

  async function fetchData(page?: any) {
    try {
      const except = [
        PersonDuplicate?.main,
        ...PersonDuplicate?.merge,
        ...PersonDuplicate?.dependents?.map((item: DataPerson) => item.id),
      ];

      const response = await duplicateRegistration(document, page, {
        exclude: except,
        all_ids: true,
      });
      const idsToRemove: number[] = response.data;

      setFormDocuments((prev: IPersonData) => ({
        ...prev,
        remove: idsToRemove,
      }));

      dispatch(
        selectPersonDuplicate({
          ...PersonDuplicate,
          remove: idsToRemove,
        })
      );
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao solicitar as pessoas duplicadas!"
      );
    }
  }

  const handleSubmitForm = async (formData: IPersonData) => {
    setLoading(true);
    try {
      const { main, dependents, edit } = formData;
      edit.documents = [
        {
          ...(edit.documents && edit.documents[0]),
          type: edit.type_document,
          number: (edit?.type_document === "cpf"
            ? edit?.cpf
            : edit?.passport
          )?.replace(/[^a-zA-Z0-9]/g, ""),
          country_id:
            edit.type_document === "cpf" ? null : edit.passport_issuing_country,
        },
      ];
      dependents.forEach((item: DataPerson) => {
        // only letters and numbers on document number
        item?.type_document &&
          (item?.cpf || item?.passport) &&
          (item.documents = [
            {
              ...(item.documents && item.documents[0]),
              type: item.type_document,
              number: (item?.type_document === "cpf"
                ? item?.cpf
                : item?.passport
              )?.replace(/[^a-zA-Z0-9]/g, ""),
              country_id:
                item.type_document === "cpf"
                  ? null
                  : item.passport_issuing_country,
            },
          ]);
      });
      const person = await updatePersonDuplicate(main, formData);
      const { origin, order_id } = PersonDuplicatedFlow;

      setModalResource({
        ...modalResource,
        isVisible: true,
        modal: "submit_modal",
        handleModal: () => {
          setModalResource({ ...modalResource, isVisible: false });

          if (origin === "duplicated-list") {
            dispatch(resetPersonDuplicatedFlow());
            history.push("/pessoas-duplicadas");
          } else if (origin === "send-motion") {
            history.push(`/pedidos-envio/${order_id}`);
          } else {
            dispatch(
              selectPacient({
                ...person,
                contact: person?.default_email?.contact,
                ddi: person?.default_phone?.ddi,
                ddd: person?.default_phone?.ddd,
                phone: person?.default_phone?.phone,
                isPerson: true,
              })
            );
            history.push(
              `/ficha-de-atendimento/person/selecionar-produtos/undefined`
            );
          }
        },
      });
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao atualizar as pessoas duplicadas, tente novamente!"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <PageWrapper className="entry-app-content order-home-list">
      <ModalHandler {...modalResource} />
      <div className="content-holder">
        <Header>
          <h1>Validação das informações</h1>
          <Breadcrumb
            list={[
              {
                path: "/pessoas",
                label: "PESSOAS",
                current: false,
              },
              {
                path: "#",
                label: "Validação das informações",
                current: true,
              },
            ]}
          />
        </Header>

        <LeftPaddingContainer>
          <div className="page-content page-content-list" id="ScrollDomiciliar">
            <TitleSectionReview>Informações do cliente</TitleSectionReview>

            <Element name="ScrollableDomiciliarOrders" />

            <Formik
              initialValues={formDocuments}
              validationSchema={schema}
              validateOnBlur
              enableReinitialize
              validateOnChange={false}
              onSubmit={handleSubmitForm}
              innerRef={formikRef}
            >
              {({ values }) => {
                return (
                  <Form>
                    <div className="content">
                      <ReviewForm
                        type_document={values.edit?.type_document}
                        prefix="edit"
                      />
                    </div>
                    {values.dependents?.map((dependent, key) => (
                      <div className="dependent-list" key={dependent.id}>
                        <TitleSectionReview>
                          Informações do dependente {key + 1}
                        </TitleSectionReview>
                        <div className="content">
                          <ReviewForm
                            type_document={dependent?.type_document}
                            prefix={`dependents[${key}]`}
                          />
                        </div>
                      </div>
                    ))}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </LeftPaddingContainer>
      </div>

      <Footer>
        <button
          className="button"
          onClick={() => formikRef?.current?.submitForm()}
          disabled={loading}
        >
          {loading ? (
            <img className="loading small" src={LoadingImage} alt="loading" />
          ) : (
            "salvar alterações"
          )}
        </button>
      </Footer>
    </PageWrapper>
  );
};
