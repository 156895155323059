import { useRef } from "react";
import { SwitchInputProps } from "./types";

export const SwitchInput = ({
  label,
  name,
  enabledText,
  disabledText,
  value,
  handleChange
}: SwitchInputProps) => {

  const inputRef = useRef<HTMLInputElement>(null);
  
  return (
    <div
      className='field-holder-small'
    >
      <div
        onClick={() => handleChange(name, !value)}
        className={`
                switch-holder 
                ${value ? "_active" : ""}
            `}
      >
        <span className="switch-item">
          <small />
        </span>
        {(enabledText || disabledText) && (
          <span className="switch-text" style={{ color: 'rgba(87, 37, 128, 1)', fontSize: '13px', fontWeight: 600 }}>
            {value ? enabledText : disabledText}
          </span>
        )}
      </div>
      <input

        id={name}
        name={name}
        ref={inputRef}
        style={{
          display: "none",
        }}
        type="checkbox"
        checked={value}
        readOnly
      />
    </div>
  )
}