import { useQuery } from "react-query";

import { getOrdersCombos } from "Requests/Combos";

import { validateObjectType } from "Utils/Objects";

import { typeService } from "mocks";

import { CombosRequest, Error, OrdersCombos } from "./interfaces";
import {
  defaultCovenant,
  defaultDatesOpts,
  defaultStatus,
  defaultUnits,
} from "./utils";

export const useOrdersCombos = (): CombosRequest => {
  const { data, error, isError, isLoading } = useQuery<OrdersCombos, Error>(
    ["orders-combos"],
    () => getOrdersCombos(),
    {
      refetchOnWindowFocus: false,
    }
  );

  let addDefaultValues;

  if (data?.covenants || data?.status || data?.units || data?.date_types) {
    addDefaultValues = {
      covenants: [defaultCovenant, ...data.covenants],
      status: [defaultStatus, ...data.status],
      units: [defaultUnits, ...data.units],
      date_types: [defaultDatesOpts, ...data.date_types],
      service_type: typeService
    };
  }

  return {
    ordersCombo: validateObjectType(addDefaultValues)
      ? addDefaultValues
      : undefined,
    loadingOrdersCombos: isLoading,
    ordersCombosError: isError ? error.message : null,
  };
};
