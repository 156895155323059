import React from "react";

const Autocomplete = ({
	id,
	purple,
	label,
	onChange,
	onClick,
	placeholder,
	value,
	error,
	activeSuggestion,
	showSuggestions,
	filteredSuggestions,
	noSuggestionMessage,
	withCreate
}) => {
	let suggestionsListComponent;

	if (showSuggestions && value) {
		if (filteredSuggestions?.length) {
			suggestionsListComponent = (
				<ul className="suggestions">
					{filteredSuggestions.map((suggestion, index) => {
						let className;
						if (index === activeSuggestion) {
							className = "suggestion-active";
						}

						return (
							<li
								className={`${className  } suggestion`}
								key={index}
								id={suggestion.id}
								onClick={onClick}
							>
								{suggestion.drug || (
									<>
										{suggestion.first_name || suggestion.full_name}&nbsp;
										<br />
										<strong style={{ fontSize: "12px" }}>
											{suggestion?.documents[0]?.number}
											&nbsp;
											<div>{suggestion.birthdate}</div>
										</strong>
									</>
								)}
							</li>
						);
					})}
				</ul>
			);
		} else {
			suggestionsListComponent = (
				<div className="no-suggestions">
					<em>
						{withCreate ? (
							<span style={{ cursor: "pointer" }}>{noSuggestionMessage}</span>
						) : (
							noSuggestionMessage
						)}
					</em>
				</div>
			);
		}
	}

	return (
		<>
			<div className="suggestion-wrapper">
				{label && (
					<label
						htmlFor={id}
						className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
					>
						{label}
					</label>
				)}
				<input
					type="text"
					id={id}
					name={id}
					placeholder={placeholder}
					onChange={onChange}
					value={value}
					className="input _opacity-gray suggestions-input"
					autoComplete="off"
				/>
				<div className="suggestions-list">{suggestionsListComponent}</div>
			</div>
		</>
	);
};

export default Autocomplete;
