import React from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function MiniMonthCalendar({ setDate }) {
    const [value, onChange] = React.useState(new Date());

    React.useEffect(() => {
        const _data = new Intl.DateTimeFormat('en-US').format(value).split('/');
        const formatter = `${_data[2]}-${_data[0]}-${_data[1]}`;

        setDate(formatter)
    }, [value]);

    return (
        <div className="not">
            <Calendar
                onChange={onChange}
                value={value}
                className="calendar-month-mini"
                tileClassName="calendar-title-mini-month"
            />
        </div>
    )
}
