import styled from "styled-components";

export const CovenantGrid = styled.div`
  padding: 10px 20px;
  overflow: auto;
  padding-top: 0;

  .container-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px auto;

    .head-content {
      grid-column-start: 1;
      grid-column-end: 7;
      background: #f5f5f5;
      border-radius: 20px 20px 0 0;

      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      align-items: center;

      span {
        font-family: var(--font-display);
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        color: #572580;
        text-transform: uppercase;
        text-align: left;
        min-width: 100px;
      }
    }

    .body-content {
      grid-column-start: 1;
      grid-column-end: 6;
      width: 100%;

      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .loading-image {
        width: 100%;
        grid-column-start: 3;
        grid-column-end: 3;
        margin-top: 50px;

        .loading-persons {
          display: flex;
          justify-content: start;
          align-items: center;
          height: 100%;
          width: 100%;
        }
      }

      & > div {
      }
    }
  }
`;

export const NoResults = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
