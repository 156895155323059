import styled, { css } from "styled-components";

export const ContainerSymptom = styled.div`
  min-height: 567px;
  min-width: 320px;

  background: #ffffff;
  border-radius: 7px;
  border: 3px solid #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  p {
    text-align: center;
    color: #666666;

    font-family: var(--font-text);
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  ${(props) =>
    props?.active &&
    css`
      background-color: rgba(171, 146, 191, 0.35);
      border: 3px solid rgba(87, 37, 128, 0.35);

      p {
        color: #ffffff;
        font-weight: 600;
      }
    `}

  @media screen and (min-width: 980px) {
    min-width: 341px;
  }
`;
