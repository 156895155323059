import { useEffect, useRef } from "react";

import * as workerTimers from "worker-timers";

export default function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = workerTimers.setInterval(tick, delay);
      return () => workerTimers.clearInterval(id);
    }
  }, [delay]);
}
