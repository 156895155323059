import styled from "styled-components";

import CartBag from "Assets/Images/bag-product-white.svg";
import TrashIcon from "Assets/Images/trashIcon.png";

export const Container = styled.tr`
  width: 100%;
  padding: 24px 20px;
  box-sizing: border-box;

  .button-cart {
    width: 134px;

    .cart-bag-remove::after,
    &:hover .cart-bag-remove::after {
      content: "";
      width: 15px;
      height: 15px;

      display: block;
      -webkit-mask: url(${TrashIcon}) no-repeat center;
      mask: url(${TrashIcon}) no-repeat center;
      transition: all 0.33s ease-in-out 0s;
    }

    .cart-bag-remove::after {
      background: white;
    }

    &:hover .cart-bag-remove::after {
      background: var(--color-purple);
    }

    .cart-bag::after,
    &:hover .cart-bag::after {
      content: "";
      width: 15px;
      height: 15px;

      display: block;
      -webkit-mask: url(${CartBag}) no-repeat center;
      mask: url(${CartBag}) no-repeat center;
      transition: all 0.33s ease-in-out 0s;
    }

    .cart-bag::after {
      background: white;
    }

    &:hover .cart-bag::after {
      background: var(--color-purple);
    }
  }
`;
