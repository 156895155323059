const FormatCurrency = (value) => {
  const number = parseFloat(value).toFixed(2).split(".");

  number[0] = number[0].split(/(?=(?:...)*$)/).join(".");

  return number.join(",");
};

export default FormatCurrency;

export function formatBRLCurrency(value, withPrefix = false) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
    .format(Number(value))
    .replace("R$", withPrefix ? "R$" : "");
}