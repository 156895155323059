import React from "react";
import ReactJson from "react-json-view";
import Modal from "react-modal";

import { Button } from "Components/Button";

import { ModalLogDetailsProps } from "./interface";
import { ModalContent } from "./style";

export const ModalLogDetails = ({
  isOpen,
  onClick,
  loading,
  log,
  onClickClose,
}: ModalLogDetailsProps) => {
  let format = "";
  if (log?.created_at) {
    const separete = log?.created_at.toString().split(" ");
    const getTime = separete[0].split("-");
    const getHour = separete[1].split(":");
    format = `${getTime[2]}/${getTime[1]}/${getTime[0]} - ${getHour[0]}:${getHour[1]}`;
  }
  return (
    <Modal
      isOpen={isOpen}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-content-information"
      shouldCloseOnOverlayClick
      onRequestClose={onClickClose}
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
    >
      <ModalContent>
        <h2>Detalhes do Log</h2>
        <p style={{ textAlign: "left" }}>
          <strong>Usuário:</strong> {log?.user?.name}
          <br />
          <strong>Action:</strong> {log?.action}
          <br />
          <strong>Modulo:</strong> {log?.module}
          <br />
          <strong>Data:</strong> {format}
          <br />
        </p>
        <p>Detalhes:</p>
        <ReactJson src={log?.request} theme="tube" />
        <br />
        {/* @ts-ignore */}
        <Button onClick={onClick} loading={loading}>
          Fechar
        </Button>
      </ModalContent>
    </Modal>
  );
};
