import React from "react";

import UploadImagesUnits from "Components/Form/UploadImagesUnits";
import UploadInsurance from "Components/Form/UploadInsurance";
import PanelContent from "Components/Panels/PanelContent";

export function ImagesPanel({
  loading,
  proFiles,
  sendFormData,
  sendFormDataImageMap,
  proFilesImageMap,
  objectData,
  isRegion,
  removeImagesIds,
  onToggleImages,
}) {
  const { listImages, imageMap } = objectData;

  return (
    <>
      {isRegion ? (
        <div className="row" style={{ justifyContent: "space-around" }}>
          {listImages.value.length > 0 ? (
            <div>
              <div className="md-8">
                <label className="text-gray text-semibold text-center">
                  Imagens da Unidade
                </label>
                <UploadImagesUnits
                  importPath="/product/import"
                  fileAccept=".png, .jpg, .jpeg"
                  allowExtensions={["png", "jpg", "jpeg"]}
                  files={proFiles}
                  multi
                  sendFormData={sendFormData}
                />
              </div>
            </div>
          ) : (
            <div className="md-5">
              <label className="text-gray text-semibold text-center">
                Imagens da Unidade
              </label>
              <UploadImagesUnits
                importPath="/product/import"
                fileAccept=".png, .jpg, .jpeg"
                allowExtensions={["png", "jpg", "jpeg"]}
                files={proFiles}
                multi
                sendFormData={sendFormData}
              />
            </div>
          )}

          {listImages.value.length > 0 && (
            <div className="grid-img md-4">
              {listImages.value.map((item) => (
                <>
                  <span>
                    <img src={item.img} alt="" key={item.id} />
                  </span>
                </>
              ))}
            </div>
          )}
        </div>
      ) : (
        <PanelContent title="Imagens da Unidade" loading={loading}>
          <div className="row" style={{ justifyContent: "space-around" }}>
            {listImages.value.length > 0 ? (
              <div>
                <div className="md-8">
                  <label className="text-gray text-semibold text-center">
                    Imagens da Unidade
                  </label>
                  <UploadImagesUnits
                    importPath="/product/import"
                    fileAccept=".png, .jpg, .jpeg"
                    allowExtensions={["png", "jpg", "jpeg"]}
                    files={proFiles}
                    multi
                    sendFormData={sendFormData}
                  />
                </div>
              </div>
            ) : (
              <div className="md-5">
                <label className="text-gray text-semibold text-center">
                  Imagens da Unidade
                </label>
                <UploadImagesUnits
                  importPath="/product/import"
                  fileAccept=".png, .jpg, .jpeg"
                  allowExtensions={["png", "jpg", "jpeg"]}
                  files={proFiles}
                  multi
                  sendFormData={sendFormData}
                />
              </div>
            )}

            {listImages.value.length > 0 && (
              <div className="grid-img md-4">
                {listImages.value.map((item) => {
                  const isDelete = removeImagesIds.includes(item.id);

                  return (
                    <>
                      <span
                        className={isDelete && "delete"}
                        onClick={() => onToggleImages(item.id)}
                      >
                        <img src={item.img} alt="" key={item.id} />
                      </span>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </PanelContent>
      )}
      {!isRegion && (
        <PanelContent title="Imagem do Mapa" loading={loading}>
          <div className="row" style={{ justifyContent: "space-around" }}>
            {imageMap.value !== null && imageMap.value !== "" ? (
              <div>
                <div className="md-8">
                  <UploadInsurance
                    importPath="/unidades/0"
                    fileAccept=".png, .jpg, .jpeg"
                    allowExtensions={["png", "jpg", "jpeg"]}
                    files={proFilesImageMap}
                    sendFormData={sendFormDataImageMap}
                  />
                </div>
              </div>
            ) : (
              <div className="md-5">
                <label className="text-gray text-semibold text-center">
                  Imagem do Mapa
                </label>
                <UploadInsurance
                  importPath="/unidades/0"
                  fileAccept=".png, .jpg, .jpeg"
                  allowExtensions={["png", "jpg", "jpeg"]}
                  files={proFilesImageMap}
                  sendFormData={sendFormDataImageMap}
                />
              </div>
            )}
            {imageMap.value !== null && imageMap.value !== "" && (
              <div className="grid-imgMap md-4">
                <span>
                  <img src={imageMap.value} alt="" />
                </span>
              </div>
            )}
          </div>
        </PanelContent>
      )}
    </>
  );
}
