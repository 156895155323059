import { useRef, useState } from "react";

import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";

import { SendEmailProps } from "./interface";
import { Container } from "./style";

export const SendEmailButton = ({
  placeholder,
  value,
  label,
  submit,
  minHeight
}: SendEmailProps) => {
  const [loadingEmail, setLoadingEmail] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const submitEmail = async (email: string | undefined) => {
    if (!email) {
      return createToast("error", "E-mail inválido");
    }
    setLoadingEmail(true);
    await submit(email);
    setLoadingEmail(false);
  };

  return (
    <Container>
      <input
        ref={ref}
        type="text"
        name="coupon_code"
        placeholder={placeholder}
        defaultValue={value}
      />
      <div className="separator" />
      <Button
        onClick={() => submitEmail(ref.current?.value)}
        loading={loadingEmail}
        textButton
        padding="0"
        minHeight={minHeight}
        
      >
        {label}
      </Button>
    </Container>
  );
};
