const leadsFormatter = (leads) => {
  if (!leads?.length || !leads) return [];

  const newLeads = leads.map((lead) => ({
    ...lead,
    full_name: lead?.name,
    isLead: true,
    phones: lead.phone ?[
      {
        ddd: lead?.ddd,
        phone: lead.phone
      }
    ]: [],
    emails: lead?.email ? [
      {
        email: lead.email,
      }
    ] : []
  }));

  return newLeads;
};

const formatterPerson = (persons) => {
  if (!persons || !persons.length) return [];

  const newPersons = persons.map((person) => ({ ...person, isPerson: true }));
  return newPersons;
};

export const formatterResponseSearchPacient = ({
  leads: leadsResponse,
  persons,
}) => {
  const leads = leadsFormatter(leadsResponse);
  const newPersons = formatterPerson(persons);

  return [...leads, ...newPersons];
};
