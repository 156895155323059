const date = new Date();
const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export function getMaxDaysMonth(month, year) {
  const data = new Date(year, month + 1, 0);
  return data.getDate();
}

export function getDaysMonth(year, month) {
  let daysMonth = [];
  const maxDays = getMaxDaysMonth(month, year);

  for (let day = 1; day <= maxDays; day++) {
    daysMonth = [...daysMonth, day];
  }
  return daysMonth;
}

export function getNameWeek(month, year, day) {
  const date = new Date(year, month, day);
  const weekday = date.getDay();
  const names = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];

  return {
    labelWeek: names[weekday],
    namesWeek: names,
  };
}

export function getNameWeekSearch([day, month, year], fullLabel) {
  const date = new Date(year, month - 1, day);
  const weekday = date.getDay();
  const names = !fullLabel
    ? ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"]
    : ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sabado"];

  return names[weekday];
}

export function getScheduleInfo() {
  const getmonth = date.getMonth() + 1;
  const month = getmonth < 10 ? `${0}${getmonth}` : getmonth;

  const getday = date.getDate();
  const day = getday;

  const month_label = months[getmonth - 1];
  const month_number = getmonth - 1;
  const year = date.getFullYear();
  return {
    day,
    month,
    month_label,
    month_number,
    months,
    year,
  };
}

export function getWeek() {
  const day = date.getDay();
  const names = [
    "DOMINGO",
    "SEGUNDA",
    "TERÇA",
    "QUARTA",
    "QUINTA",
    "SEXTA",
    "SÁBADO",
  ];
  const isToday = names[day];

  return {
    names,
    isToday,
  };
}

export function getStartDayWeek(year, month) {
  const _date = new Date(year, month, 1);

  const dateTimeFormat = new Intl.DateTimeFormat("pt-BR", {
    weekday: "long",
  }).format(_date);

  const replaceDateTime = dateTimeFormat.replace("-feira", "");

  const formated = replaceDateTime.toUpperCase();

  return {
    dayWeekStart: formated,
    dayinWeek: _date.getDay(),
  };
}

export function gerenerateTableDays(year, month) {
  const { arr: matriz, daysMax } = generateMatriz(year, month);
  const { dayinWeek } = getStartDayWeek(year, month);
  const matriz_max_index = matriz.length - 1;

  let number = 1;
  let week1 = [];

  matriz.forEach((week, index) => {
    if (index === 0) {
      week1 = week; // reference memory matriz
    }
  });

  week1.forEach((day, i) => {
    // change memory in matriz and week1
    if (i === dayinWeek) week1[dayinWeek] = 1;

    if (i > dayinWeek) {
      number++;
      week1[i] = number;
    }
  });

  matriz.forEach((week, index_week) => {
    week.forEach((day, i) => {
      if (index_week > 0) {
        if (number === daysMax) return;
        number++;
        week[i] = number;
      }
    });
  });

  if (matriz[matriz_max_index].every((item) => item === null))
    matriz[matriz_max_index] = [];

  return matriz;
}

function generateMatriz(year, month) {
  const daysInMonth = getDaysMonth(year, month);
  const daysMax = daysInMonth.length;
  const number_of_array = daysInMonth.length === 31 ? 6 : 5;

  let arr = [];
  for (let i = 1; i <= number_of_array; i++) {
    arr = [...arr, [null, null, null, null, null, null, null]];
  }

  return { arr, daysMax };
}

export function filterHoursWeek(data, array_total, is_clinic) {
  // added is clinic
  const array = is_clinic ? [] : ["09", "10"];
  const _data = { ...data };

  array.forEach((hour) => {
    if (_data[`${hour}:00`]) {
      const fourteenHoursFiltered = _data[`${hour}:00`];
      delete _data[`${hour}:00`];

      if (_data[`${hour}:30`]) {
        _data[`${hour}:30`] = [
          ...fourteenHoursFiltered,
          ..._data[`${hour}:30`],
        ];
        // _data[hour + ':30'].shift();
      }
    }
  });

  const keys = Object.keys(_data);
  keys.forEach((hour) => {
    if (_data[hour].length < array_total.length) {
      const amount = array_total.length - _data[hour].length;

      for (let i = 0; i < amount; i++) {
        _data[hour].push(null);
      }
    }
  });

  return _data;
}
