import { useQuery } from "react-query";

import { getProductsSubCategories } from "Requests/Categories";

import { validateArrayType } from "Utils/Arrays";
import { createToast } from "Utils/toastFunc";

import { ErrorProps, SubCategories, Subcategory } from "./interface";

export const useSubCategories = (categoryId = 0): SubCategories => {
  const { data, isLoading, isError, error } = useQuery<
    Array<Subcategory>,
    ErrorProps
  >(
    ["subcategories", categoryId],
    () => getProductsSubCategories(categoryId),

    {
      enabled: !!categoryId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: (errorRes: ErrorProps) => createToast("error", errorRes.message),
    }
  );

  return {
    subcategoriesList: validateArrayType(data) ? data : undefined,
    loadingSubcategories: isLoading,
    subcategoriesError: isError ? error.message : null,
  };
};
