import styled from "styled-components";

export const ContainerRegisterForm = styled.section`
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  margin-right: 150px;
  padding-left: 130px;

  .field-holder,
  select-field-holder {
    width: 100%;
    .error-message {
      bottom: initial;
      &.error-without-label {
        bottom: -15px;
      }
    }
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const FlexItemsInput = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  justify-content: space-between;
  gap: 1rem;

  margin: 1rem 0;
  margin-bottom: 1.5rem;
`;

export const ContainerFields = styled.div`
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerButton = styled.div`
  margin-top: 2rem;
  text-align: right;
`;

export const TitleReview = styled.h5`
  font-size: 26px;
  color: #572580;
  margin: 0%;
`;

export const SubTitleReview = styled.h5`
  font-size: 13px;
  color: #572580;
  margin-top: 40px;
`;

export const Divisor = styled.div`
  margin-bottom: 30px;
`;
