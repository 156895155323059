import { useState } from "react";
import { useQuery } from "react-query";

import { getBudgets } from "Requests/Budget";

import { handleRequestErrors } from "Utils/Errors";
import { removeFalsyValuesFromFilters } from "Utils/RequestFilters";

import { Filters } from "./interface";

export const useGetBudgets = () => {
  const [filters, setFilters] = useState<Filters>({
    page_size: 20,
    page: 1,
  } as Filters);

  const query = useQuery(
    ["private-budget", filters],
    () =>
      getBudgets({
        search: { ...removeFalsyValuesFromFilters(filters) },
        type: "privado",
      }),
    {
      refetchOnWindowFocus: false,
      onError: (errorRes) =>
        handleRequestErrors({
          reqErrors: errorRes,
          errorMessage: "Não foi possível buscar a listagem de orçamentos.",
        }),
    }
  );

  const setBudgetsFilters = (searchFilter: Filters) => {
    const filtersParams = {
      ...filters,
      ...searchFilter,
      page_size: 20,
      page: searchFilter.page ?? 1,
    };
    setFilters(filtersParams);
  };

  return {
    ...query,
    setBudgetsFilters,
    pagination: query.data?.pagination,
  };
};
