import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";

import moment from "moment";

import { GetOrdersBudgets } from "Requests/Budget";
import {
  GetPersonDashboard,
  getPersonalCreditsData,
  getPersonalDashboardData,
} from "Requests/Person";

import { selectPacient } from "Actions/ServiceForm";

import { Store } from "Reducers/interface";

import { formatDateBR } from "Utils/Date";
import { formatBRLCurrency } from "Utils/FormatCurrency";
import { FormatDate } from "Utils/FormatterDate";
import { MaskCPF, MaskCelphone, MaskLandline } from "Utils/Masks";
import validatePermissions from "Utils/validatePermissions";

import LowFrequency from "Assets/Images/baixa.png";
import CreditsWithoutAccountImage from "Assets/Images/credit-without-account.svg";
import Inactive from "Assets/Images/inativo.png";
import CreditsEmptyState from "Assets/Images/labi-creditos-empty-state.png";
import LoadingImage from "Assets/Images/loading-purple.gif";
import Recurrent from "Assets/Images/recurrent.png";

import { DashboardDataProps, RecentOrdersProps, TabProps } from "../interface";

const chartOptions = {
  colors: ["#AB92BF", "#815CA0", "#572580", "#fff45b"],
  legend: {
    show: false,
  },
  labels: ["Exames", "Testes", "Check-ups", "Vacinas"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: true,

      donut: {
        labels: {
          show: true,

          name: {
            show: false,
          },

          total: {
            show: true,

            showAlways: true,
          },
        },
      },
    },
  },
};

function DashboardTab({ person }: TabProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const user_permissions = useSelector(
    (state: Store) => state.User.permissions
  );

  const creditErrorMessage = useRef("");
  const [loading, setLoading] = useState(false);
  const [creditsData, setCreditsData] = useState<any>();
  const [classification, setClassification] = useState("");
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0]);
  const [dashData, setDashData] = useState<DashboardDataProps>();
  const [recurrenceIcon, setRecurrenceIcon] = useState(LowFrequency);
  const [recentOrders, setRecentOrders] = useState<RecentOrdersProps[]>([]);

  async function getOrderBudgets() {
    try {
      const resultsObject = await GetOrdersBudgets(person.id);

      setRecentOrders(resultsObject.data);
    } catch (error) {
      console.log({ error });
    }
  }

  async function getDashData() {
    try {
      const { data } = await getPersonalDashboardData(person.id);
      setDashData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCreditsData() {
    try {
      const data = await getPersonalCreditsData(person.id);

      if (data.message) {
        if (data.message.includes("Usuário não possui conta no site Labi")) {
          return (creditErrorMessage.current =
            "Este cliente ainda não possui conta no site do Labi.");
        }
        if (
          data.message.includes(
            "Não existe movimentação Labi Créditos para esse usuário"
          )
        ) {
          return (creditErrorMessage.current =
            "Este cliente ainda não utilizou o programa Labi Créditos!");
        }
      }

      setCreditsData(data[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const getDocument = () => {
    const cpf = person.documents.find(
      (item) => item.type.toUpperCase() === "CPF"
    );
    const passport = person.documents.find(
      (item) =>
        item.type.toUpperCase() === "PASSPORT" ||
        item.type.toUpperCase() === "PASSAPORTE"
    );

    return {
      cpf,
      passport,
    };
  };

  function handleCreateBudget() {
    dispatch(
      selectPacient({
        id: person.id,
        isPerson: true,
        birthdate: person?.birthdate,
        cpf: getDocument()?.cpf ?? "",
        first_name: person?.full_name,
        ddd: person?.phones[0]?.ddd ?? "",
        phone: person?.phones[0]?.phone ?? "",
        contact: person?.emails[0]?.email ?? "",
        passport: getDocument().passport ?? null,
      })
    );

    history.push("/ficha-de-atendimento/person/selecionar-produtos/undefined");
  }

  function getCreditValue(value: string) {
    if (!value) return null;
    if (value === "None") return null;

    return value.replace("-", "");
  }

  async function handleFetchPersonalInfo() {
    try {
      setLoading(true);
      const promiseList = [getDashData(), fetchCreditsData()];
      await Promise.allSettled(promiseList);
      if (validatePermissions("Consult Budgets", user_permissions))
        await getOrderBudgets();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const getDashboard = async () => {
    try {
      const { data } = await GetPersonDashboard(person.id);
      const icon =
        data.classification === "Recorrente"
          ? Recurrent
          : data.classification === "Inativo"
          ? Inactive
          : LowFrequency;

      setClassification(data.classification);
      setRecurrenceIcon(icon);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboard();
    handleFetchPersonalInfo();
  }, []);

  useEffect(() => {
    const series = [
      dashData?.exams,
      dashData?.tests,
      dashData?.check_ups,
      dashData?.vaccines,
    ];

    setChartSeries(series as number[]);
  }, [dashData]);

  if (loading) {
    return (
      <span className="loading-persons">
        <img src={LoadingImage} alt="" />
      </span>
    );
  }

  return (
    <div className="token-area">
      <div className="token-person-dashboard">
        <div className="person-dashboard-header">
          <div className="activity-label-dashboard">
            <img src={recurrenceIcon} alt="Recorrente" />

            <span>{classification?.toUpperCase() ?? "SEM CLASSIFICAÇÃO"}</span>
          </div>

          <div className="person-dashboard-header-buttons">
            {validatePermissions("Update Persons", user_permissions) && (
              <Link
                to={`/pessoa/${params.id}`}
                className="button _action _white"
              >
                Atualizar dados
              </Link>
            )}

            {validatePermissions("Update Persons", user_permissions) && (
              <button onClick={handleCreateBudget} className="button _action">
                Criar orçamento
              </button>
            )}
          </div>
        </div>

        <div className="dashboard-main-content">
          <div className="dashboard-person-data">
            <span className="dashboard-sub-title">DADOS CADASTRAIS</span>

            <div className="general-data-div">
              <div>
                <div className="wrapper-info">
                  <p>
                    <strong>Nome: </strong>
                    {person.full_name}
                  </p>

                  {person.social_name && (
                    <p>
                      <strong>Nome social: </strong>
                      {person.social_name}
                    </p>
                  )}

                  <p>
                    <strong>Gênero: </strong>
                    {person.gender
                      ? person.gender.gender
                      : "Gênero não informado"}
                  </p>

                  {person.birthdate && (
                    <p>
                      <strong>Data de nascimento:</strong>
                      {moment(person.birthdate, "DD/MM/YYYY").isValid()
                        ? person.birthdate
                        : FormatDate(person?.birthdate)}
                    </p>
                  )}
                  <p>
                    <strong>Recém nascido:</strong>
                    {person.unborn ? "Sim" : "Não"}
                  </p>
                </div>
              </div>

              <div>
                {person?.phones &&
                  person?.phones.length > 0 &&
                  person?.phones.map((phone, i) => (
                    <p key={i}>
                      <strong>Telefone: </strong>({phone.ddd}){" "}
                      {JSON.stringify(phone.phone).length === 8
                        ? MaskLandline(phone.phone)
                        : MaskCelphone(phone.phone)}
                    </p>
                  ))}

                {person?.emails &&
                  person?.emails.length > 0 &&
                  person?.emails.map((email) => (
                    <p key={email.id}>
                      <strong>Email: </strong>
                      {email.email}
                    </p>
                  ))}

                {person?.documents &&
                  person?.documents.length > 0 &&
                  person?.documents.map((_document) => (
                    <p key={_document.id}>
                      <strong>Documento: </strong>
                      {_document.type === "cpf"
                        ? MaskCPF(_document.number)
                        : _document.number}
                    </p>
                  ))}

                <p>
                  <strong>Nome da mãe:</strong>
                  {person.mother_name ? person.mother_name : "Não informado"}
                </p>
              </div>
            </div>
          </div>

          <div className="dashboard-labi-credits">
            <span className="dashboard-sub-title">LABI CRÉDITOS</span>

            {creditsData ? (
              <div className="dashboard-credits-container">
                <div className="dashboard-credits-infos">
                  <div>
                    <span>CARTEIRA ATUAL</span>
                    <p>
                      R${" "}
                      {formatBRLCurrency(
                        getCreditValue(creditsData?.amount) ?? 0
                      )}
                    </p>
                  </div>
                  <div>
                    <span>CRÉDITOS ACUMULADOS</span>
                    <p>
                      R${" "}
                      {formatBRLCurrency(
                        getCreditValue(creditsData?.accumulated) ?? 0
                      )}
                    </p>
                  </div>
                  <div>
                    <span>CRÉDITOS GASTOS</span>
                    <p>
                      R${" "}
                      {formatBRLCurrency(
                        getCreditValue(creditsData?.spending) ?? 0
                      )}
                    </p>
                  </div>
                  <div>
                    <span>NÚMERO DE INDICAÇÕES</span>
                    <p>{creditsData?.indications ?? 0}</p>
                  </div>
                </div>
                <div className="dashboard-credits-togo">
                  <div>
                    <span>CRÉDITOS A LIBERAR</span>
                    <p>
                      {creditsData?.to_available?.amount
                        ? `R$ ${formatBRLCurrency(
                            creditsData?.to_available?.amount
                          )} em ${moment(
                            new Date(creditsData?.to_available?.valid_at)
                          ).format("DD/MM/YYYY")}`
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <span>CRÉDITOS A VENCER</span>
                    <p>
                      {creditsData?.to_expire?.amount
                        ? `R$ ${formatBRLCurrency(
                            creditsData?.to_expire?.amount
                          )} em ${moment(
                            new Date(creditsData?.to_expire?.expire_at)
                          ).format("DD/MM/YYYY")}`
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <span>PEDIDOS COM LABI CRÉDITOS</span>
                    <p>
                      {creditsData?.to_final_order?.amount
                        ? `R$ ${formatBRLCurrency(
                            getCreditValue(creditsData?.to_final_order?.amount)
                          )} em ${moment(
                            new Date(creditsData?.to_final_order?.expire_at)
                          ).format("DD/MM/YYYY")}`
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="empty">
                <img
                  src={
                    creditErrorMessage.current ===
                    "Este cliente ainda não possui conta no site do Labi."
                      ? CreditsWithoutAccountImage
                      : CreditsEmptyState
                  }
                  alt="Labi Creditos"
                />

                <span>
                  {creditErrorMessage.current ??
                    "Este cliente ainda não utilizou o programa Labi Créditos!"}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="dashboard-main-content">
          <div className="dashboard-interaction-history">
            <span className="dashboard-sub-title">HISTÓRICO DE INTERAÇÕES</span>

            <table className="">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Data</th>
                  <th>ID</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {recentOrders.length > 0 &&
                  !loading &&
                  recentOrders.map((order) => (
                    <tr key={order.id}>
                      <td>{order.type === "Order" ? "Pedido" : "Orçamento"}</td>
                      <td>{formatDateBR(order.date)}</td>
                      <td>{order.id}</td>
                      <td>R$ {order.totalDisplay}</td>
                      <td>{order.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="dashboard-general-history">
            <span className="dashboard-sub-title">HISTÓRICO GERAL</span>

            <div className="dashboard-general-history-legends">
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="donut"
                width={250}
              />

              <div className="chart-legends">
                <div>
                  <p>TOTAL SERVIÇOS REALIZADOS</p>
                  <span>{dashData?.total || 0}</span>
                </div>

                <div>
                  <div>
                    <p>EXAMES</p>
                    <span>{dashData?.exams || 0}</span>
                  </div>

                  <div>
                    <p>TESTES</p>
                    <span>{dashData?.tests || 0}</span>
                  </div>

                  <div>
                    <p>CHECK-UPS</p>
                    <span>{dashData?.check_ups || 0}</span>
                  </div>

                  <div>
                    <p>VACINAS</p>
                    <span>{dashData?.vaccines || 0}</span>
                  </div>
                </div>

                <div className="chart-legends-tickets">
                  <div>
                    <p>TICKET MÉDIO</p>
                    <span>R$ {dashData?.average_spent || "0,00"}</span>
                  </div>

                  <div>
                    <p>TOTAL GASTO ATÉ HOJE</p>
                    <span>R$ {dashData?.total_spent || "0,00"}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-customer-since">
              <span>CLIENTE LABI DESDE: {dashData?.client_since}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTab;
