import React from 'react'

export  function TitleCalendars({title, total_schedules}) {
    return (
        <header className="schedule-header-title">
            <h3>{title}</h3>
            <span>{total_schedules} agendamentos</span>
        </header>
    )
}
