import styled from "styled-components";

import PageWrapper from "Components/Pages/Wrapper";

export const FormContainer = styled.form`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 22px;
  row-gap: 20px;
  column-gap: 16px;

  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    select {
      width: 150px;
    }
  }

  select,
  input {
    border-radius: 8px;
    color: #b1b1b1;
    font-size: 13px;
    padding: 5px 16px;
  }

  .select-field-holder .arrow-icon {
    top: 8px;
    font-size: 15px;
    font-weight: 700;
  }

  .buttons-container {
    display: flex;
    gap: 16px;
    margin-right: auto;
  }

  .order-by {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      white-space: nowrap;
      color: rgb(177, 177, 177);
      font-size: 13px;
    }

    .field-holder {
      width: 100%;
    }
  }
`;

export const PageContainer = styled(PageWrapper)`
  &.entry-app-content {
    padding-left: 60px;

    .content-holder {
      padding: 24px 20px;
    }

    .page-content {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;

      .table-content {
        border-radius: 15px 15px 0px 0px;

        thead th:first-child,
        thead th:last-child {
          border-radius: 0;
        }
      }
    }
  }

  @media (min-width: 1260px) {
    &.entry-app-content {
      padding-top: 0px;
      position: relative;
      padding-right: 0;
      padding-left: 230px;
    }
  }
`;
