import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Content = styled(Box)`
  & * {
    color: var(--color-purple);
    font-family: var(--font-display);
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
  }

  strong {
    font-weight: 600;
  }
`;
