import React from "react";

import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";

import { getCalendars } from "../../Requests/Schedules";

const types = [
  { id: "name", name: "Nome" },
  { id: "email", name: "Email" },
  { id: "cpf", name: "CPF" },
  { id: "id", name: "Id do Agendamento" },
];

export default function SelectTypeSchedule({
  setMenuSelect,
  state,
  setState,
  setButtonPrevOrNext,
  setButtonClick,
  setIsSearch,
  setCalendarSelect,
  calendarSelect,
}) {
  const [calendars, setCalendars] = React.useState([]);
  const [typeSearch, setTypeSearch] = React.useState({
    id: "name",
    name: "Nome",
  });
  const [value, setValue] = React.useState("");
  const handleClickMenu = ({ target }) => setState(target.id, true);

  function handlePrevOrNext({ target }) {
    setButtonPrevOrNext((prev) => (target.id === "next" ? prev + 1 : prev - 1));
    setButtonClick((prev) => (target.id === "next" ? prev + 1 : prev - 1));
  }

  function handleChangeInput({ target }) {
    setValue(target.value);
  }

  function handleClearFilters() {
    setValue("");
    setMenuSelect("days");
    setTypeSearch({
      id: "name",
      name: "Nome",
    });
    setIsSearch({
      search: false,
      result: "",
      type: "days",
    });
    setCalendarSelect({
      id: 4167028,
      name: "Exames (SP)",
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (value) {
      setIsSearch((prev) => ({
        ...prev,
        result: value,
        search: true,
        type: typeSearch.id,
      }));

      setMenuSelect("search");
    }
  }

  async function GetCalendarFetch() {
    try {
      const data = await getCalendars();
      return setCalendars(data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    GetCalendarFetch();
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <div className="select-schedule">
        <div className="select-flex">
          <div className="select-button" onClick={handleClickMenu}>
            <button
              className={`button ${state === "days" ? "_purple" : "_white"}`}
              id="days"
            >
              Hoje
            </button>
            <button
              className={`button ${state === "week" ? "_purple" : "_white"}`}
              id="week"
            >
              Semana
            </button>
            <button
              className={`button ${state === "month" ? "_purple" : "_white"}`}
              id="month"
            >
              Mês
            </button>
          </div>

          <div className="select-prev-next" onClick={handlePrevOrNext}>
            <div className="prev">
              <button className="button _white" id="prev">
                {" "}
                {"<"}{" "}
              </button>
            </div>

            <div className="next">
              <button className="button _white" id="next">
                {" "}
                {">"}{" "}
              </button>
            </div>
          </div>

          <div className="select-search-types">
            <SelectSearch
              id="calendars_id"
              type="text"
              value={calendarSelect}
              error=""
              isMulti
              isMoreSelect={false}
              options={calendars}
              loading={!calendars.length}
              readOnly={!calendars.length}
              valueKey="name"
              labelKey="name"
              placeholder="Vacinas (SP)"
              onChange={(value) => setCalendarSelect(value)}
            />
          </div>

          <div>
            <button className="button _white"> Criar novo </button>
          </div>
        </div>
      </div>

      <div className="select-search-submit">
        <div className="select-search-submit-types">
          <SelectSearch
            id="calendars_id"
            type="text"
            value={typeSearch}
            error=""
            isMulti
            isMoreSelect={false}
            options={types}
            loading={!types.length}
            readOnly={!types.length}
            valueKey="name"
            labelKey="name"
            placeholder="Digite o tipo de busca"
            onChange={(value) => setTypeSearch(value)}
          />
        </div>
        <Input
          placeholder="Buscar por nome, CPF, e e-mail ou ID do pedido"
          onChange={handleChangeInput}
          value={value}
        />
        <button className="button" type="submit">
          {" "}
          Buscar{" "}
        </button>
        <div>
          <button
            type="button"
            className="button _transparentButton _alt"
            onClick={handleClearFilters}
          >
            Limpar filtros
          </button>
        </div>
      </div>
    </form>
  );
}
