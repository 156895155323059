import React, { useState } from "react";
import Modal from "react-modal";

import PropTypes from "prop-types";

import { Loading } from "Utils/Loading";

import UploadAreaAnnex from "Components/Form/UploadAnnex";
import { CampFile } from "Components/Form/UploadFiles/styles";

import IconPDF from "../../../Assets/Images/icon-pdf.svg";
import trashIcon from "../../../Assets/Images/trashTransparent.svg";
import { Medical } from "./styles";

Modal.setAppElement("#root");

function MedicalRecordModal({
  modalVisible,
  modalDismiss,
  documents,
  removeDocument,
  callbackFinish,
  noEdit,
  budget,
}) {
  const [loading, setLoading] = useState(false);

  function closeModal() {
    modalDismiss();
  }

  async function openFiles(files) {
    setLoading(true);
    await callbackFinish(files);
    setLoading(false);
  }

  function baseFormData() {
    setLoading(true);
    const formData = new FormData();
    return formData;
  }

  function extractContent(inputString) {
    const pattern = /\/([^/]+)\/([^./]+)\./;
    const regex = new RegExp(pattern);
    const match = regex.exec(inputString);
    if (match && match[2]) {
      return match[2];
    }

    return "";
  }

  function convertDocumentsInfo(documents) {
    if (!documents) return [];

    const documentsList = documents?.map((document) => {
      const image = new Image();
      image.src = document.base64;
      const docFormat = document.file_path.slice(-3);

      return {
        id: extractContent(document.file_path),
        file_path: document.file_path,
        filename: document.name,
        preview: `data:image/${docFormat};base64, ${document.base64}`,
        isImage: !document.file_path.endsWith(".pdf"),
      };
    });
    return documentsList;
  }

  const deleteDocument = async (file_path) => {
    try {
      setLoading(true);
      await removeDocument(file_path);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        bodyOpenClassName="modal-content-open"
        className="modal-content modal-content-cart"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
      >
        <div className="modal-wrapper-header-annex ">
          <h5>Pedido médico</h5>

          <p>Insira aqui todas as páginas do pedido médico.</p>
        </div>
        <Medical className="modal-wrapper-body">
          {loading ? (
            <Loading classCss="loading-centered" />
          ) : (
            <div className="list-content">
              {convertDocumentsInfo(documents)?.map((document) => {
                return (
                  <CampFile key={document.id} className="active">
                    <div className="space-file-name">
                      {document.isImage ? (
                        <img src={document.preview} alt="imagem arquivo" />
                      ) : (
                        <img src={IconPDF} alt="PDF icon" />
                      )}
                      <span>{document.filename}</span>
                    </div>
                    {!noEdit && (
                      <button
                        className="button-trash"
                        onClick={() => deleteDocument(document.file_path)}
                        style={{
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <img src={trashIcon} alt="remover" />
                      </button>
                    )}
                  </CampFile>
                );
              })}

              {!noEdit && (
                <div>
                  <UploadAreaAnnex
                    uploadFile={openFiles}
                    formDataFileString="documents[]"
                    fileAccept=".jpeg, .png, .pdf"
                    baseFormData={baseFormData}
                    importPath={`/budgets/documents/upload/${budget?.id}`}
                    multi
                    children={
                      <button
                        className="button transparent-btn"
                        onClick={closeModal}
                      >
                        VOLTAR
                      </button>
                    }
                  />
                </div>
              )}
            </div>
          )}
        </Medical>
      </Modal>
    </div>
  );
}

MedicalRecordModal.propTypes = {
  documents: PropTypes.array, // eslint-disable-line
  callbackFinish: PropTypes.func,
};

MedicalRecordModal.defaultProps = {
  documents: [],
};

export default MedicalRecordModal;
