import React from "react";

import PanelContent from "Components/Panels/PanelContent";
import SwitchInput from "Components/Switch/Input";

export function Attributes({ objectData, setObjectData, loading }) {
  const {
    parking,
    accessibility,
    test_covid,
    next_subway,
    open_unit,
    apply_vaccines,
    coming_soon,
    toxic_exam,
    collection_point
  } = objectData;
  return (
    <>
      <PanelContent title="Atributos" loading={loading}>
        <div className="row">
          <div className="xs-12">
            <SwitchInput
              label="Próximo ao metrô ou trem"
              id="next_subway"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  next_subway: {
                    value: !next_subway.value,
                  },
                }))
              }
              value={next_subway.value}
              textOn="Ativo"
              textOff="Inativo"
            />
          </div>
          <div className="xs-12">
            <SwitchInput
              label="status Unidade"
              id="open_unit"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  open_unit: {
                    value: !open_unit.value,
                  },
                }))
              }
              value={open_unit.value}
              textOn="Aberto"
              textOff="Fechada"
            />
          </div>
          <div className="xs-12">
            <SwitchInput
              label="Testes de Antígeno COVID-19"
              id="test_covid"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  test_covid: {
                    value: !test_covid.value,
                  },
                }))
              }
              value={test_covid.value}
              textOn="Ativo"
              textOff="Inativo"
            />
          </div>
          <div className="xs-12">
            <SwitchInput
              label="Acessibilidade para cadeirantes"
              id="accessibility"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  accessibility: {
                    value: !accessibility.value,
                  },
                }))
              }
              value={accessibility.value}
              textOn="Ativo"
              textOff="Inativo"
            />
          </div>
          <div className="xs-12">
            <SwitchInput
              label="Estacionamento da Unidade"
              id="parking"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  parking: {
                    value: !parking.value,
                  },
                }))
              }
              value={parking.value}
              textOn="Ativo"
              textOff="Inativo"
            />
            <SwitchInput
              label="Aplica vacinas"
              id="vaccines"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  apply_vaccines: {
                    value: !apply_vaccines?.value,
                  },
                }))
              }
              value={apply_vaccines?.value}
              textOn="Ativo"
              textOff="Inativo"
            />
            <SwitchInput
              label="Abre em breve"
              id="coming_soon"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  coming_soon: {
                    value: !coming_soon?.value,
                  },
                }))
              }
              value={coming_soon?.value}
              textOn="Ativo"
              textOff="Inativo"
            />
            <SwitchInput
              label="Exame Toxicológico"
              id="toxic_exam"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  toxic_exam: {
                    value: !toxic_exam?.value,
                  },
                }))
              }
              value={toxic_exam?.value}
              textOn="Ativo"
              textOff="Inativo"
            />
            <SwitchInput
              label="Posto de Coleta"
              id="collection_point"
              onChange={() =>
                setObjectData((prev) => ({
                  ...prev,
                  collection_point: {
                    value: !collection_point?.value,
                  },
                }))
              }
              value={collection_point?.value}
              textOn="Ativo"
              textOff="Inativo"
            />
          </div>
        </div>
      </PanelContent>
    </>
  );
}
