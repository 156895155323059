import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;

  .package-container {
    display: flex;
    flex-direction: column;
  }
`;
