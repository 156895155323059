import styled from "styled-components";

interface DefaultStyles {
  defaultStyles: any;
}

export const Container = styled.div<DefaultStyles>`
  grid-column-start: 1;
  grid-column-end: 6;
  height: 92px;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  align-items: center;

  .image-dots {
    width: 14px;
    justify-self: center;
  }

  .image-logo {
    width: 59px;
    height: 51px;
  }

  .health-name {
    font-family: var(--font-text);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: var(--color-bold-gray);
  }

  .edit-button {
    width: 100px;
    border-radius: 30px;
  }

  .view-button {
    border: 1px solid #572580;
    border-radius: 30px;
    background: white;
    color: #572580;
    width: 75%;
    min-width: 111px;
    margin-right: 5px;

    padding: 5px 0;
  }

  &:nth-child(even) {
    background: #f5f5f5;
  }

  &:last-child {
    border-radius: 0px 0px 20px 20px;
  }

  &.drag-color {
    background: #d5c8df;
    border: 3px solid #ab92bf;
    border-radius: 10px;
  }
`;
