import styled from "styled-components";

import PageWrapper from "Components/Pages/Wrapper";

export const PageContainer = styled(PageWrapper)`
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
`;

export const PageContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .page-heading {
    flex-wrap: nowrap;
  }

  & > .separator {
    width: 100%;
    border-bottom: 1px solid #cccccc;
    margin: 30px 0;
  }

  .budget-detailed,
  .page-heading {
    padding: 0 40px;
  }
`;
