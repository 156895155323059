import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { format } from "date-fns";
import { GeneralDataType } from "Pages/Orders/Token/interface";

import {
  GetAppointmentGender,
  GetDetails,
  GetPaymentData,
} from "Requests/Orders";

import { formatDateToBR } from "Utils/Date";
import { MaskCPF } from "Utils/Masks";
import { createToast } from "Utils/toastFunc";
import { createQueryString } from "Utils/WEB";

import { ContentPagesWrapper as ContentPagesWrapperBase } from "layouts/ContentPagesWrapper/index";

import { Accordion } from "Components/Accordion/index";
import { DetailsInfo } from "Components/DetailsInfo/index";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { Attendance } from "./Attendance";
import { getBreadcrumbs } from "./breadcrumb";
import { ParamsProps } from "./interface";
import {
  AttendancesContainer,
  BackButton,
  Centering,
  ContainerRevision,
  ContentArticle,
  FlexDetailsItems,
  GridPaymentItems,
  LoadingContainer,
} from "./styles";

const ContentPagesWrapper = ContentPagesWrapperBase as any;

export function ViewOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<GeneralDataType | null>(null);
  const [appointmentGender, setAppointmentGender] = useState<any>({});
  const [paymentData, setPaymentData] = useState<any>([]);

  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    geral: true,
    payment: true,
    items: true,
  });

  const history = useHistory();
  const { personId, orderId } = useParams<ParamsProps>();

  function handleOpen(key: string) {
    setOpen((prevState) => ({
      ...prevState,
      [key]: Object.keys(open).includes(key) ? !prevState[key] : false,
    }));
  }

  function handleGoBack() {
    history.goBack();
  }

  async function handlePaymentData(id: string | number) {
    try {
      const paymentResponse = await GetPaymentData(id);

      const { payment_transaction_date, number, street } =
        paymentResponse.data[0];
      const date = payment_transaction_date.split(" ");

      const paymentInfo = {
        ...paymentResponse.data[0],
        payment_transaction_date: `${formatDateToBR(date[0])} / ${date[1]}`,
        address: `${street},${number} `,
      };

      if (paymentResponse.success) {
        setPaymentData(paymentInfo);
      }
    } catch (error) {
      createToast("error", "Não foi possível buscar os dados de pagamento");
    }
  }

  async function handleAppointmentGender(data: any, person_id: string) {
    try {
      const web = data?.order_source.toLowerCase()
      const appointmentGender = await GetAppointmentGender(
        person_id,
        web === "web" ? data.id_source : data.id,
        web === "web" ? 2 : 1
      );

      const date = new Date(appointmentGender.data.appointment_at);

      if (appointmentGender.success) {
        setAppointmentGender({
          unit_name: data?.unit_name,
          appointment_at_date: format(date, "dd/MM/yyyy"),
          appointment_at_hour: format(date, "HH:mm:ss"),
          ...appointmentGender.data,
          ...([8, 9].includes(appointmentGender.data?.procedure) && {
            wait_room_link: `${
              process.env.REACT_APP_URL_CLINIC_SITE
            }/pre-atendimento?${createQueryString({
              id_scheduling: appointmentGender.data?.id_appointment,
              appointment_at: appointmentGender.data?.appointment_at,
              name: data?.pacient[0]?.name,
            })}`,
          }),
        });
      }
    } catch (error) {
      createToast("error", "Não foi possível os detalhes do agendamento");
    }
  }

  function getPatientId(data?: any) {
    const selectedData = data;

    if (Array.isArray(selectedData?.pacient)) {
      const patient = selectedData.pacient[0];

      return patient.id ?? patient.patient_id;
    }

    return selectedData?.pacient?.id;
  }

  async function getOrder() {
    setIsLoading(true);

    try {
      const { data, success } = await GetDetails(orderId);
      await handleAppointmentGender(data, getPatientId(data));
      await handlePaymentData(data.id);

      setIsLoading(false);

      if (!success) {
        createToast(
          "warn",
          "Não foi possível buscar seu agendamento. Tente novamente mais tarde."
        );
        history.push(`/ficha-cliente/${personId}`);
      }

      setOrder(data);
    } catch (error) {
      setIsLoading(false);
      createToast(
        "warn",
        "Não foi possível buscar seu agendamento. Tente novamente mais tarde."
      );
      history.push(`/ficha-cliente/${personId}`);
    }
  }

  useEffect(() => {
    getOrder();
  }, []);

  if (isLoading) {
    return (
      <ContentPagesWrapper
        titleHeader="Ficha do Cliente"
        listBreadcrumb={getBreadcrumbs(personId, orderId)}
        ContentWrapperHeader={() => (
          <BackButton onClick={handleGoBack}>Voltar</BackButton>
        )}
      >
        <Centering>
          <LoadingContainer>
            <p>Carregando dados do pedido</p>
            <img src={LoadingImage} alt="" />
          </LoadingContainer>
        </Centering>
      </ContentPagesWrapper>
    );
  }
  return (
    <ContentPagesWrapper
      titleHeader="Ficha do Cliente"
      listBreadcrumb={getBreadcrumbs(personId, orderId)}
      ContentWrapperHeader={() => (
        <BackButton onClick={handleGoBack}>Voltar</BackButton>
      )}
    >
      <ContainerRevision>
        <ContentArticle open={open?.geral}>
          <Accordion
            title="Dados Gerais"
            open={open?.geral}
            handleOpen={() => handleOpen("geral")}
            notCenter
          >
            <DetailsInfo
              label="Nº do pedido:"
              content={order?.id || "Não informado"}
            />
            <DetailsInfo
              label="Nº do pedido de origem:"
              content={order?.id_source || "Não informado"}
            />
            <DetailsInfo
              label="Data do pedido:"
              content={order?.date || "Não informado"}
            />
            <DetailsInfo
              label="Data de integração Motion:"
              content={order?.motion_integration_date || "Não informado"}
            />
            <DetailsInfo
              label="Status:"
              content={order?.status || "Não informado"}
            />
            <DetailsInfo
              label="Origem:"
              content={order?.order_source || "Não informado"}
            />
          </Accordion>
        </ContentArticle>

        <ContentArticle open={open?.payment}>
          <Accordion
            title="Dados de Pagamento"
            open={open?.payment}
            handleOpen={() => handleOpen("payment")}
            notCenter
          >
            <GridPaymentItems className="payment-grid">
              <DetailsInfo
                label="Data de pagamento:"
                content={
                  paymentData?.payment_transaction_date || "Não informado"
                }
              />

              <DetailsInfo
                label="Número de parcelas:"
                content={paymentData?.installments_number || "Não informado"}
              />

              <DetailsInfo
                label="Tipo de pagamento:"
                content={paymentData?.form_payment || "Não informado"}
              />

              <DetailsInfo
                label="Forma de pagamento:"
                content={paymentData?.form_payment || "Não informado"}
              />
              <DetailsInfo
                label="NSU:"
                content={paymentData?.nsu_number || "Não informado"}
              />
              <DetailsInfo
                label="Bandeira do cartão:"
                content={paymentData?.credit_card_banner || "Não informado"}
              />
              <DetailsInfo
                label="Cupom de desconto:"
                content={paymentData?.coupon_code || "Não informado"}
              />

              <DetailsInfo
                label="Nome"
                content={paymentData?.name || "Não informado"}
              />

              <DetailsInfo
                label="Documento:"
                content={MaskCPF(paymentData?.cpf) || "Não informado"}
              />

              <DetailsInfo
                label="Endereço:"
                content={paymentData?.address || "Não informado"}
              />
            </GridPaymentItems>
          </Accordion>
        </ContentArticle>

        <ContentArticle open={open?.items} twoColumns>
          <Accordion
            title="Itens do Pedido"
            open={open?.items}
            handleOpen={() => handleOpen("items")}
            notCenter
          >
            {!!order?.cart?.length &&
              order?.cart?.length > 0 &&
              order?.cart.map((item, index) => (
                <FlexDetailsItems key={index}>
                  <DetailsInfo label="Nome:" content={item.product_name} />
                  <DetailsInfo label="Quantidade:" content={item.amount} />
                </FlexDetailsItems>
              ))}
          </Accordion>
        </ContentArticle>
      </ContainerRevision>

      <AttendancesContainer>
        {order?.attendance?.home && (
          <ContentArticle open={open?.homeAttendance ?? true}>
            <Accordion
              title="Atendimento 1 - Domiciliar"
              open={open?.homeAttendance ?? true}
              handleOpen={() => handleOpen("homeAttendance")}
              notCenter
            >
              <Attendance
                patients={order.attendance.home.patients}
                home={order.attendance.home}
                payment={order.payer?.origin}
                scheduledAttendance={order.home_collect_date ? order?.home_collect_date.replaceAll(
                  "/",
                  "-"
                ): ""}
              />
            </Accordion>
          </ContentArticle>
        )}

        {order?.attendance.unit && (
          <ContentArticle open={open?.unitAttendance ?? true} fitContent>
            <Accordion
              title={`Atendimento ${order?.attendance.home ? 2 : 1} - Unidade`}
              open={open?.unitAttendance ?? true}
              handleOpen={() => handleOpen("unitAttendance")}
              notCenter
            >
              <Attendance
                patients={order?.attendance.unit.patients ?? []}
                attendance_link={appointmentGender.wait_room_link}
              />
            </Accordion>
          </ContentArticle>
        )}
      </AttendancesContainer>
    </ContentPagesWrapper>
  );
}
