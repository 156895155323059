import styled from "styled-components";

export const ContainerRegisterForm = styled.section`
  width: 100%;
  margin: 0 auto;
  margin-right: 400px;
  margin-top: 50px;
  .field-holder,
  select-field-holder {
    width: 100%;
    .error-message {
      bottom: initial;
      &.error-without-label {
        bottom: -15px;
      }
    }
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const FlexItemsInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
  justify-content: space-between;
  gap: 1rem;

  margin: 1rem 0;
  margin-bottom: 1.5rem;
`;

export const ContainerFields = styled.div`
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerButton = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`;

export const ContainerResponsibleForm = styled.div`
  .register-form-sub-title {
    margin-bottom: 13px;
  }
`;
export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;

  span {
    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #815ca0;
  }

  .content-switch {
    width: 60px;
    margin-left: 8px;
  }
`;
