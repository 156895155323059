import React from "react";

import ShowLoading from "Pages/RegistrationCleaning/components/ShowLoading";
import {
  ListTableInterface,
  DataPerson,
} from "Pages/RegistrationCleaning/interface";
import { ButtonSeeHistory } from "Pages/RegistrationCleaning/styles";

import { MaskBirthdate } from "Utils/Masks";

import Checkbox from "Components/Form/Checkbox";



export const ListTable = ({
  data,
  loading,
  handleCheckBoxSelected,
  personsSelect,
  setModalResource,
  modalResource,
}: ListTableInterface) => {
  const handleHistory = (item: DataPerson) => {
    setModalResource({
      ...modalResource,
      title: `Cliente: ${item.full_name}`,
      isVisible: true,
      modal: "history",
      handleDismiss: () =>
        setModalResource({ ...modalResource, isVisible: false }),
      historyData: item,
    });
  };

  return (
    <table className="table-content small-separate details">
      <thead>
        <tr>
          <th className="checkbox" />
          <th>Nome completo</th>
          <th>Nome social</th>
          <th>Data de nascimento</th>
          <th>Telefone</th>
          <th>Email</th>
          <th>
            pedidos/
            <br />
            orçamentos
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <ShowLoading />
        ) : !data?.length ? (
          <tr>
            <td colSpan={9} className="text-center text-purple">
              Oops! Parece que nada foi encontrado...
            </td>
          </tr>
        ) : (
          data.map((item) => (
            <tr key={item.id}>
              <td className="checkbox">
                <Checkbox
                  id={String(item.id)}
                  small
                  onChange={(_, value) => handleCheckBoxSelected(item, value)}
                  value={personsSelect.some(
                    (_item: any) => _item.id === item.id
                  )}
                />
              </td>
              <td>{item?.full_name} </td>
              <td>
                {item?.social_name ? item?.social_name : "Nenhum encontrado"}{" "}
              </td>
              <td>{MaskBirthdate(item?.birthdate)} </td>
              <td>
                {item?.default_phone?.ddd
                  ? `(${item?.default_phone?.ddd}) ${item?.default_phone?.phone}`
                  : "Nenhum encontrado"}{" "}
              </td>
              <td>{item?.default_email?.email} </td>
              <td>
                {Number(item?.budgets_count) +
                  Number(item?.orders_count) +
                  Number(item?.reports_count)}{" "}
              </td>
              <td>
                <ButtonSeeHistory onClick={() => handleHistory(item)}>
                  Ver histórico
                </ButtonSeeHistory>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};
