export const defaultCovenant = {
  covenant: "Convênio",
  covenant_id: 0,
  covenant_plan_ids: [],
};

export const defaultStatus = {
  id: 0,
  status: "Status",
};

export const defaultUnits = {
  id: 0,
  name: "Unidade",
};

export const defaultDatesOpts = {
  id: "",
  name: "Selecione",
};
