import API from "Services/Api";

export const SearchPersonsToInclude = async (cpf) => {
  try {
    const response = await API.get(`person/filter/${cpf}`);

    return response.data.data;
  } catch (error) {
    if (error.response.data) return [];
  }
};

export const GetCoupons = async (page = 1) => {
  try {
    const response = await API.get(`/coupons?page=${page}`);

    const responseFormatted = {
      meta: response.data.meta,
      data: response.data.data,
    };

    return responseFormatted;
  } catch (error) {
    console.log(error);

    if (error.response.data) {
      return {
        meta: {},
        data: [],
      };
    }
  }
};

export const SearchCoupons = async (search) => {
  try {
    const response = await API.get(`/coupons/filter${search}`);

    let responseFormatted;

    if (Array.isArray(response.data) && response.data.length === 0) {
      responseFormatted = {
        meta: {
          pagination: {},
        },
        data: [],
      };
    } else {
      responseFormatted = {
        meta: response.data.meta,
        data: response.data.data,
      };
    }

    return responseFormatted;
  } catch (error) {
    if (error.response.data) {
      return {
        meta: {
          pagination: {},
        },
        data: [],
      };
    }
  }
};

export const GetCoupon = async (id) => {
  try {
    const response = await API.get(`/coupons/${id}`);

    return {
      data: response.data.data,
      status: response.status,
    };
  } catch (error) {
    console.log(error);

    if (error.response.data) return error.response.data;

    throw error;
  }
};

export const CreateCoupon = async (data) => {
  try {
    const response = await API.post("/coupons/manager/data", data);

    if (response.status === 200) {
      return {
        status: response.status,
        success: true,
      };
    }
  } catch (err) {
    console.log(err);

    return {
      status: err.response.status,
      success: false,
      errors: err.response.data,
    };
  }
};

export const UpdateCoupon = async (id, data) => {
  try {
    const response = await API.put(`/coupons/${id}`, data);

    if (response.status === 200) {
      return {
        status: response.status,
        success: true,
      };
    }
  } catch (err) {
    console.log(err);

    return {
      status: err.response.status,
      success: false,
      errors: err.response.data,
    };
  }
};

export const DeleteCoupon = async (id) => {
  try {
    const response = await API.delete(`/coupons/${id}`);

    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);

    return false;
  }
};

export const GetCombo = async () => {
  try {
    const response = await API.get(`coupons/resource/combo`);

    return response.data;
  } catch (error) {
    console.log(error);

    if (error.response.data) {
      return {
        product_sub_category: [],
        product_category: [],
      };
    }
  }
};
