import styled from "styled-components";

export const Container = styled.div`
  .clear-cart p {
    text-decoration: underline;
    margin-bottom: 0;
  }

  .actions-cart-holder {
    button {
      font-family: Saira, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 16px;
      padding: 13px 17px;
      position: relative;
      width: 100%;
      border-radius: 8px;
      color: #666;
      background: #fff;
      border: 1px solid #f5f5f5;
      box-shadow: 0 0 0 1px #82889429, 0 4px 6px -2px #82889452;
      justify-content: flex-start;

      & img {
        margin-left: 13px;
      }

      &:hover {
        background: white;
        color: inherit;
        border: 1px solid transparent;
      }

      &.clear-cart {
        border: none;
        box-shadow: none;
        padding: 0;
      }

      &.active {
        background: #f1fff7;
        border: 1px solid #44b878;
      }

      span {
        text-transform: lowercase;
        margin-left: 30px;
        &.coupon {
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
`;
