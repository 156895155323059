export const YupValidateCpf = (cpf) => {
  cpf = cpf.replace(/\D/g, "");

  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let result = true;

  [9, 10].forEach(function (j) {
    let soma = 0;
    let r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });

  return result;
};

export const validatorCPF = (strCPF) => {
  let Soma;
  let Resto;

  Soma = 0;

  if (strCPF === "00000000000") {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  Soma = 0;

  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(10, 11))) {
    return false;
  }

  return true;
};

export const validatorEmail = (email) => {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export function validatorDate(date) {
  if (date == "00/00/00") return "data inválida";

  if (
    !date.match(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/([12][0-9]{3})$/g)
  ) {
    return "data inválida";
  }
  return "";
}
