import Success from "Assets/Images/successIcon.svg";
import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";
import { useHistory } from "react-router";
import { useRescheduleAppointment } from "Stores/reschedule-store";
import { Box } from "styles/globalComponents";
import { useShallow } from "zustand/react/shallow";


interface SuccessModalProps {
  isOpen: boolean;
  closeModal: () => void;
}


export const SuccessRescheduleModal = ({
  isOpen,
  closeModal,
}: SuccessModalProps) => {

  const history = useHistory()

  const [rescheduleAddress] = useRescheduleAppointment(useShallow((state) => [
    state.rescheduleAddress,
  ]));

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      renderHeading={() => (
        <h2>
          Reagendamento confirmado
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          Agendamento confirmado para o dia <strong>{rescheduleAddress.date}</strong> entre <strong>{rescheduleAddress.hour}</strong> no endereço <strong>{rescheduleAddress.fullAddress}</strong>.
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">


          <Button width="full" outlined onClick={() => history.push('/pedidos/')} minHeight="3.7em" fontWeight="600">
            {" "}
            Voltar para Pedidos
          </Button>

          <Button width="full" outlined onClick={() => window.location.reload()} minHeight="3.7em" fontWeight="600">
            {" "}
            Ver Detalhes do Pedido
          </Button>
        </Box>
      )}
    />
  );

}