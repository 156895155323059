import { useEffect, useState } from "react";

import { GetComboInProducts } from "Requests/Products";

import { createToast } from "../../../Utils/toastFunc";

export const useHealthInsurance = () => {
  const [channel, setChannel] = useState([]);

  async function fetchChannels() {
    try {
      const { channels } = await GetComboInProducts();

      setChannel(channels);
    } catch (err) {
      createToast("error", "Ocorreu um erro ao buscar os canais!");
    }
  }

  useEffect(() => {
    fetchChannels();
  }, []);

  return {
    channel,
    loadingChannel: false,
  };
};
