import React, { useRef, useState } from "react";

import UploadIcon from "Assets/Images/upload-icon.svg";

const Dropzone = (props) => {
  const [highlight, setHighlight] = useState(false);

  const fileInputRef = useRef();

  function fileListToArray(list) {
    const array = [];

    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }

    return array;
  }

  function openFileDialog() {
    if (props.disabled) return;

    fileInputRef.current.click();
  }

  function onFilesAdded({ target: { files } }) {
    if (props.disabled) return;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);

      props.onFilesAdded(array);
    }
  }

  function onDragOver(event) {
    event.preventDefault();

    if (props.disabled) return;

    setHighlight(true);
  }

  function onDragLeave() {
    setHighlight(false);
  }

  function onDrop(event) {
    event.preventDefault();

    if (props.disabled) return;

    const {files} = event.dataTransfer;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);

      props.onFilesAdded(array);
    }

    setHighlight(false);
  }

  return (
    <div
      className={`
                dropzoneInsurance-area 
                ${props.cssName}
                ${props.disabled ? "--disabled" : ""}
                ${highlight ? "--highlight" : ""}
                ${props.invalid ? "--invalid" : ""}
            `}
      onClick={openFileDialog}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <img alt="upload" className="icon" src={UploadIcon} />

      <input
        ref={fileInputRef}
        className="input-file"
        type="file"
        multiple={props.multi}
        onChange={onFilesAdded}
        accept={props.fileAccept}
      />

      <span>{props.elementDescr}</span>
    </div>
  );
};

Dropzone.defaultProps = {
  multi: false,
};

export default Dropzone;
