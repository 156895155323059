import React from "react";

import arrowAccordion from "Assets/Images/arrowAccordion.svg";

import {
  ContainerAccordion,
  HeaderAccordion,
  TitleAccordion,
  ContentInformationAccordion,
} from "./style";

export const Accordion = ({
  headerContent = "",
  title,
  children,
  open = false,
  handleOpen,
  hasBorder = false,
  notCenter = false,
}) => {
  return (
    <ContainerAccordion hasBorder={hasBorder}>
      <HeaderAccordion notCenter={notCenter} open={open} onClick={handleOpen}>
        <TitleAccordion>{title}</TitleAccordion>

        {headerContent}
        <img src={arrowAccordion} alt="seta" />
      </HeaderAccordion>

      {open && (
        <ContentInformationAccordion>
          <div>{children}</div>
        </ContentInformationAccordion>
      )}
    </ContainerAccordion>
  );
};
