import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import { Loading } from "Utils/Loading";
import { TextEllipsis } from "Utils/Uploads";
import validatePermissions from "Utils/validatePermissions";

import Checkbox from "Components/Form/Checkbox";
import Input from "Components/Form/Input";
import InputDateRange from "Components/Form/InputDateRange";
import SelectSearch from "Components/Form/SelectSearch";
import Pagination from "Components/Nav/Pagination";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import SuccessIcon from "Assets/Images/acceptIcon.svg";
import AttentionIcon from "Assets/Images/atention.svg";

import SelectUnits from "../../Components/Form/SelectUnits";
import {
  getOrdersHomeTelemedicine,
  getStatusPayment,
  getUnitsUser,
  integrationMotionOrder,
} from "../../Requests/HomeOrder";
import { MaskCPF } from "../../Utils/Masks";
import { createToast } from "../../Utils/toastFunc";
import { ModalDetailsErrors } from "./Modals/ModalDetailsErrors";
import { Header, NavWrapper, PaginationWrapper } from "./styles";

const types = [
  { id: "", name: "Tipo de busca" },
  { id: "name", name: "Nome" },
  { id: "email", name: "Email" },
  { id: "cpf", name: "CPF" },
  { id: "id_source", name: "Id de Origem" },
  { id: "id", name: "Id do Pedido" },
];

const typesAction = [
  { id: "all", name: "Vincular unidade selecionada" },
  { id: "motion", name: "Enviar ao motion" },
  {
    id: "all_filter_motion",
    name: "Enviar todos com base no filtro ao motion",
  },
  { id: "export_filter", name: "Exportar planilha com base no filtro" },
  { id: "export_select", name: "Exportar planilha com base nos selecionados" },
];

const initialDates = {
  dates: {
    startDate: null,
    endDate: null,
  },
};

const ordersType = [
  { type: false, name: "Pedido Simples" },
  { type: true, name: "Pedido Composto" },
];

export default function ExportHomeOrdersToMotion() {
  const user_permissions = useSelector((state) => state.User.permissions);
  const [typeSearch, setTypeSearch] = useState();
  const [value, setValue] = useState("");
  const [statusSearch, setStatusSearch] = useState();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [typesStatus, setTypesStatus] = useState([]);
  const [unit, setUnit] = useState();
  const [orderType, setOrderType] = useState();
  const [unitError, setUnitError] = useState("");

  const [errors, setErrors] = useState([]); // domiciliar array de erros
  const [isError, setIsError] = useState(false);

  const [ordersSelect, setOrdersSelect] = useState([]);

  const [dates, setDates] = useState(initialDates);

  const [modalVisible, setModalVisible] = useState(false);
  const [isExportModalVisible, setIsModalExportVisible] = useState(false);

  const [isSuccessfulExported, setIsSuccessfulExported] = useState(false);

  function handleExportModal() {
    setIsModalExportVisible(!isExportModalVisible);
  }

  function handleDatesChange(value) {
    setDates({
      dates: value,
    });
  }

  function handleCheckBoxSelected(id, value) {
    if (id === "check-all") {
      const currentOrders = data.map((item) => item.order_id);
      if (!value)
        return setOrdersSelect((prev) =>
          prev.filter((item) => !currentOrders.includes(item))
        );
      return setOrdersSelect((prev) => [...prev, ...currentOrders]);
    }

    if (!value)
      return setOrdersSelect((prev) => prev.filter((item) => item !== id));

    setOrdersSelect((prev) => [...prev, id]);
  }

  async function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    await fetchData({
      page: e.currentPage,
      ...(value ? { [typeSearch?.id]: value } : {}),
      status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
      compound_order: orderType ? orderType.type : null,
    });

    scroller.scrollTo("ScrollableDomiciliarOrders", {
      duration: 500,
      delay: 50,
      smooth: true,
      containerId: "ScrollDomiciliar",
      offset: -350,
    });
  }

  async function fetchData(rest) {
    try {
      setLoading(true);

      const date = {
        start: dates.dates.startDate
          ? dates.dates.startDate.format("YYYY-MM-DD")
          : null,
        end: dates.dates.endDate
          ? dates.dates.endDate.format("YYYY-MM-DD")
          : null,
      };
      const res = await getOrdersHomeTelemedicine({ ...rest, ...date });
      res.status === 200 && setData(res.data.data);
      if (res.data.meta && res.status === 200) {
        setPagination(res.data.meta.pagination);
      }
      res.status === 500 && createToast("error", "Erro interno de servidor.");

      return res;
    } catch (error) {
      createToast("error", "Ocorreu um erro ao solicitar os dados do pedido!");
    } finally {
      setLoading(false);
    }
  }

  async function fetchUnits() {
    try {
      const res = await getUnitsUser();
      setUnitsOptions(res);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar as unidades!");
    }
  }

  async function fetchStatus() {
    try {
      const res = await getStatusPayment();
      const formatter = res.data.map((item) => ({
        id: item.id,
        name: item.status,
      }));
      setTypesStatus(formatter);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar os status!");
    }
  }

  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
  }, []);

  function _showLoading() {
    return (
      <tr>
        <td colSpan="9" className="text-center">
          <span className="loading-persons">
            <Loading />
          </span>
        </td>
      </tr>
    );
  }

  async function reloadPage(res = { status: 200 }) {
    res.status === 200 &&
      (await fetchData({
        page: pagination.current_page,
        ...(value ? { [typeSearch?.id]: value } : {}),
        status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
        compound_order: orderType ? orderType.type : null,
      }));
  }

  async function handleSubmit() {
    if (unit == null || unit == undefined || unit == "Unidades") {
      setUnitError("Campo obrigatório");
      return;
    }

    try {
      setLoading(true);
      setErrors([]);
      setIsError(false);

      const res = await integrationMotionOrder({
        orders_id: ordersSelect,
        unit_id: unit,
      });

      if (res.error?.status === 500) {
        const errorMessage = res.error.data.message
          ? `Motivo do erro: ${res.error.data.message}`
          : "";
        setIsModalExportVisible(false);
        createToast(
          "error",
          `Pedido(s) não enviado(s)!, Consulte os resultados. ${errorMessage}`
        );
      } else {
        if (ordersSelect?.length > 20) {
          createToast(
            "warning",
            "Os pedidos serão processados em background, consulte seu email para os resultados."
          );
          setIsSuccessfulExported(true);
          setOrdersSelect([]);
          return;
        }
        const { data } = res;

        const errors = data
          .map((item) => {
            if (item.errors) return item.errors[0];
            return null;
          })
          .filter(Boolean);

        const success = data
          .map((item) => {
            if (item.success) return item.success[0];
            return null;
          })
          .filter(Boolean);

        if (success?.length > 0) {
          setIsSuccessfulExported(true);
          setOrdersSelect([]);
          createToast("success", "Pedidos enviados, consulte os resultados.");
        } else {
          setErrors(errors);
          setIsModalExportVisible(false);
          setModalVisible(true);
          setIsError(true);
          createToast("error", "Pedidos não enviados, consulte os resultados");
        }

        if (res.status === 406) {
          setErrors(res.data);
          setIsError(true);
          setIsModalExportVisible(false);
          setModalVisible(true);
          createToast("error", res.data[0]);
        }

        setOrdersSelect([]);
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function filterOrders() {
    try {
      setOrdersSelect([]);
      setPagination({});
      fetchData({
        page: pagination.current_page,
        ...(value ? { [typeSearch?.id]: value } : {}),
        status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
        compound_order: orderType ? orderType.type : null,
      });
    } catch (error) {
      console.log(error);
      createToast("error", "Ocorreu um erro ao realizar o filtro.");
    }
  }

  const clearFields = () => {
    setValue("");
    setErrors([]);
    setIsError(false);
    setStatusSearch(null);
    setTypeSearch(null);
    setOrderType(null);
    setDates(initialDates);
  };

  return (
    <PageWrapper className="entry-app-content order-home-list">
      <ModalDetailsErrors
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        errors={errors}
      />
      <div className="content-holder">
        <Header>
          <h1>Integração Telemedicina</h1>
          <Breadcrumb
            list={[
              {
                path: "/pedidos-domiciliar",
                label: "DOMICILIAR",
                current: false,
              },
              {
                path: "#",
                label: "INTEGRAÇÃO TELEMEDICINA",
                current: true,
              },
            ]}
          />
        </Header>

        <NavWrapper>
          <nav className="orders-tabs">
            <Link to="/pedidos-domiciliar">EXPORTAR</Link>
            <Link to="/pedidos/importar">IMPORTAR</Link>
            <Link to="/pedidos-domiciliar/motion">DOMICILIAR</Link>
            <Link to="#" className="current">
              TELECONSULTA
            </Link>
          </nav>
          <div className="order-home-filter">
            <header className="filters-order">
              <div>
                <InputDateRange
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  startDateId="start_date"
                  endDateId="end_date"
                  onDatesChange={(value) => handleDatesChange(value)}
                  openInModal={false}
                  dates={dates.dates}
                  blockPastChanges={false}
                  withClear
                />
              </div>
              <div className="sm-2 pl-0">
                <SelectSearch
                  id="status_id"
                  type="text"
                  value={statusSearch}
                  error=""
                  isMulti
                  isMoreSelect
                  options={typesStatus}
                  loading={!typesStatus.length}
                  readOnly={!typesStatus.length}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Status"
                  onChange={(value) => setStatusSearch(value)}
                />
              </div>
              <div className="sm-2 pl-0">
                <SelectSearch
                  id="compound_order"
                  type="text"
                  value={orderType}
                  error=""
                  isMulti
                  isMoreSelect={false}
                  options={ordersType}
                  loading={!ordersType.length}
                  readOnly={!ordersType.length}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Tipo de Pedido"
                  onChange={(value) => setOrderType(value)}
                />
              </div>
              <div className="sm-2 pl-0">
                <SelectSearch
                  id="calendars_id"
                  type="text"
                  value={typeSearch}
                  error=""
                  isMulti
                  isMoreSelect={false}
                  options={types}
                  loading={!types.length}
                  readOnly={!types.length}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Tipo de busca"
                  onChange={(value) => {
                    if (value.id === "") setValue("");
                    setTypeSearch(value);
                  }}
                />
              </div>
              <Input
                readOnly={!typeSearch}
                mask={SelectSearch.id === "cpf" ? "999.999.999-99" : ""}
                placeholder="Buscar por nome, CPF, e e-mail ou ID do pedido"
                value={value}
                onChange={({ target }) => setValue(target.value)}
                className="textInput"
              />
              <div className="full-width">
                <button className="button mr-10" onClick={filterOrders}>
                  {" "}
                  Buscar{" "}
                </button>
                <button className="button _white mr-10" onClick={clearFields}>
                  {" "}
                  Limpar{" "}
                </button>
              </div>
            </header>
            <div
              className="page-content page-content-list"
              id="ScrollDomiciliar"
            >
              <Element name="ScrollableDomiciliarOrders" />
              <table className="table-content small-separate details">
                <thead>
                  <tr>
                    <th className="checkbox">
                      <Checkbox
                        id="check-all"
                        small
                        readOnly={loading}
                        onChange={(id, value) =>
                          handleCheckBoxSelected(id, value)
                        }
                        value={
                          !loading &&
                          !!data.length &&
                          data.every((item) =>
                            ordersSelect.includes(item.order_id)
                          )
                        }
                      />
                    </th>
                    <th>Data do Pedido</th>
                    <th>Id Pedido</th>
                    <th>Id de Origem</th>
                    <th>Unidade</th>
                    <th>Canal</th>
                    <th>Paciente</th>
                    <th>CPF</th>
                    <th>Email</th>
                    <th>Status Intregação</th>
                    <th>Status Pagamento</th>
                    <th>Pedido Composto</th>
                    <th>Valor final</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    _showLoading()
                  ) : !data.length ? (
                    <tr>
                      <td colSpan="9" className="text-center text-purple">
                        Oops! Parece que nada foi encontrado...
                      </td>
                    </tr>
                  ) : (
                    data.map((item) => (
                      <tr key={item.order_id}>
                        <td className="checkbox">
                          <Checkbox
                            id={item.order_id}
                            small
                            onChange={(id, value) =>
                              handleCheckBoxSelected(id, value)
                            }
                            value={ordersSelect.some(
                              (_item) => _item === item.order_id
                            )}
                          />
                        </td>
                        <td>{item.date ?? "Nenhuma data encontrada"} </td>
                        <td>{item.order_id} </td>
                        <td>{item.id_source} </td>
                        <td>
                          {item.unit_name
                            ? item.unit_name
                            : "Unidade não encontrada"}{" "}
                        </td>
                        <td>{item.order_source} </td>
                        <td>{TextEllipsis(item.pacient_name, 25)} </td>
                        <td>
                          {item.pacient_cpf ? MaskCPF(item.pacient_cpf) : ""}{" "}
                        </td>
                        <td>
                          {item.pacient_email
                            ? item.pacient_email
                            : "Email não encontrado"}{" "}
                        </td>
                        <td>{item.motion_integration_status}</td>
                        <td>{item.status}</td>
                        <td>{item.compound_order ? "Sim" : "Não"}</td>
                        <td>
                          {" "}
                          {new Intl.NumberFormat("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.total)}
                        </td>
                        <td>
                          <Link
                            to={`/ficha-de-pedido/${item.order_id}`}
                            className="button _white _action _table"
                            type="button"
                          >
                            <span className="details-text">Visualizar</span>
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </NavWrapper>
      </div>

      <PaginationWrapper>
        {pagination.total_pages && (
          <>
            <Pagination
              totalRecords={pagination.total_pages}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e) => handlePagination(e)}
            />
          </>
        )}
        <button
          className="button"
          onClick={
            validatePermissions("Send Orders to Motion", user_permissions) &&
            handleExportModal
          }
          disabled={
            ordersSelect.length === 0 ||
            !validatePermissions("Send Orders to Motion", user_permissions)
          }
        >
          {" "}
          {loading ? <Loading sizeImg /> : "ENVIAR SELEÇÃO PARA O MOTION"}
        </button>
      </PaginationWrapper>
      <Modal
        isOpen={isExportModalVisible}
        onRequestClose={isSuccessfulExported ? null : handleExportModal}
        bodyOpenClassName="modal-content-open"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        className="upload-modal"
        closeTimeoutMS={500}
      >
        <div>
          {isSuccessfulExported ? (
            <div>
              <img src={SuccessIcon} alt="Sucesso" />
              <h1>Integração realizada com sucesso!</h1>
              <button
                className="button"
                onClick={() => {
                  reloadPage();
                  setIsSuccessfulExported(false);
                  handleExportModal();
                }}
              >
                FECHAR
              </button>
            </div>
          ) : (
            <>
              <img src={AttentionIcon} alt="Atenção" />
              <h1>Selecione abaixo a unidade de envio desejada</h1>
              <div className="unitsList">
                <SelectUnits
                  placeholder="Unidades"
                  error={unitError}
                  onChange={(event) => {
                    const value = JSON.parse(event.target.value);
                    setUnit(value.id);
                  }}
                  options={unitsOptions.length > 0 ? unitsOptions : []}
                  readOnly={unitsOptions.length <= 0}
                />
              </div>
              <button
                className="button"
                onClick={
                  validatePermissions(
                    "Send Orders to Motion",
                    user_permissions
                  ) && handleSubmit
                }
                disabled={
                  !validatePermissions(
                    "Send Orders to Motion",
                    user_permissions
                  )
                }
              >
                {loading ? <Loading sizeImg /> : "ENVIAR PARA O MOTION"}
              </button>
            </>
          )}
        </div>
      </Modal>
    </PageWrapper>
  );
}
