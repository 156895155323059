import React, { useEffect, useState } from "react";

import moment from "moment";

import { GetOrdersPlans } from "Requests/Orders";

import { useOrdersCombos } from "Hooks/useOrdersCombo";

import { Button } from "Components/Button";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";
import Table from "Components/Table";

import { Filter } from "./components/Filters";
import { ModalDetails } from "./components/ModalDatails";

const ListOrders = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState<any>({});
  const [filters, setFilters] = useState({});
  const [orderId, setOrderId] = useState(undefined);

  const { ordersCombo } = useOrdersCombos();
  const { units } = ordersCombo ?? {};

  const handleUnitname = (id: number) => {
    if (id) {
      const unitName = units?.find((item) => item.id === id);
      return unitName?.name;
    }
    return "-";
  };

  const columns = [
    {
      title: (
        <>
          Data
          <br /> pré-pedido
        </>
      ),
      key: "dataPre",
      render: ({ created_at }: any) => moment(created_at).format("DD-MM-YYYY"),
    },
    {
      title: (
        <>
          Data
          <br /> agendamento
        </>
      ),
      key: "dataAg",
      render: ({ home_collect_date }: any) => {
        const date = moment(home_collect_date, "DD/MM/YYYY")
          .locale("pt-br")
          .format("DD-MM-YYYY");

        return home_collect_date && date;
      },
    },
    {
      title: (
        <>
          ID pedido
          <br /> na origem
        </>
      ),
      key: "origin_order_id",
    },
    {
      title: "Paciente",
      key: "pacient",
      render: ({ pacient }: any) => pacient.name,
    },
    {
      title: "E-mail",
      key: "email",
      render: ({ pacient }: any) => pacient.email,
    },
    {
      title: "Tipo de coleta",
      key: "home_collected",
      render: ({ home_collected }: any) =>
        home_collected ? "Domiciliar" : "Unidade",
    },
    {
      title: "Unidade",
      key: "home_collected",
      render: ({ home_collected, unit_id }: any) =>
        home_collected ? "-" : handleUnitname(unit_id),
    },
    {
      title: "Status",
      key: "status",
    },
    {
      title: "Convênio",
      key: "convenio",
      render: ({ order_covenant }: any) =>
        order_covenant?.covenant_plan?.covenant?.covenant_name,
    },
    {
      title: "",
      key: "action",
      className: "table-action-order",
      render: ({ id }: any) => (
        <Button
          borderRadius="111"
          onClick={() => {
            setOrderId(id);
            setModalVisible(true);
          }}
          transparentHover
          outlined
        >
          visualizar
        </Button>
      ),
    },
  ];

  async function fetchData(filters: any, page: number) {
    try {
      setLoading(true);
      const { data, meta }: any = await GetOrdersPlans(page, { ...filters });
      setOrders(data);
      if (meta) setPagination(meta.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handlePagination(e: any) {
    if (pagination.current_page === e.currentPage) return;
    fetchData(filters, e.currentPage);
  }

  useEffect(() => {
    fetchData("", 1);
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading order-home-list">
          <div className="wrapper-header-content">
            <PageHeader title="Todos os pedidos">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Pedidos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Convênio",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
        </div>
        <div className="page-content min-h">
          <Filter
            fetchData={fetchData}
            setFilters={setFilters}
            filters={filters}
          />
          <Table loading={loading} data={orders} columns={columns} />
        </div>
      </div>
      <div className="pagination-wrapper">
        {!loading && (
          <Pagination
            totalRecords={pagination?.total_pages}
            currentPageSelected={pagination?.current_page}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e) => handlePagination(e)}
          />
        )}
      </div>

      {modalVisible && (
        <ModalDetails
          modalVisible={modalVisible}
          onRequestClose={setModalVisible}
          orderId={orderId}
        />
      )}
    </PageWrapper>
  );
};

export default ListOrders;
