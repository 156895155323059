import React from "react";

import { DependentContinueInterface } from "Pages/RegistrationCleaning/interface";
import { TextModal } from "Pages/RegistrationCleaning/styles";

export const DependentContinue = ({
  handleModal,
}: DependentContinueInterface) => {
  return (
    <>
      <TextModal fontWeight={400}>
        O cadastro do dependente foi atualizado com sucesso.
      </TextModal>
      <div
        className="flex-buttons-modal-information"
        style={{ marginTop: 30, flexDirection: "column", gap: 32 }}
      >
        <div className="container-button-information">
          <button className="button _action" onClick={() => handleModal()}>
            avançar pra revisão
          </button>
        </div>
      </div>
    </>
  );
};
