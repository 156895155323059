import styled from "styled-components";

export const ListProductsContainer = styled.div`
  padding: 10px 20px;
  overflow: auto;

  .product-not-found {
    margin-top: 100px;
  }
`;
