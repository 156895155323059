import styled from "styled-components";

export const Container = styled.div`
  &.entry-app-content {
    padding-left: 60px;

    .content-holder {
      padding: 24px 20px;

      .wrapper-header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .buttons-container {
          display: flex;
          gap: 10px;
        }
      }

      main {
        margin-top: 23px;

        .error {
          margin-top: 150px;
          font-family: var(--font-display);
          font-style: normal;
          font-weight: normal;
          font-size: 1.25rem;
          line-height: 1.75rem;
          color: var(--color-purple);
          text-align: center;
        }

        .error-message {
          font-size: 10px;
          line-height: 14px;
          text-align: right;
          color: #c23f3b;
        }

        .error-position {
          position: absolute;
          bottom: 0;
          right: 10px;
        }
      }
    }
  }

  @media (min-width: 1260px) {
    &.entry-app-content {
      padding-top: 0px;
      position: relative;
      padding-right: 0;
      padding-left: 230px;
    }
  }
`;
