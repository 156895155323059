import { useState } from "react";

import { ModalViewProduct } from "Pages/Product/Modals/ViewProduct";

import { Product } from "Hooks/useProducts/interface";

import { ProductsResultSearch } from "../ProductsResultSearch";

interface ProductsListResultProps {
  productsList: Product[];
}

export const ProductsListResult = ({
  productsList,
}: ProductsListResultProps) => {
  const [modalViewInfoProduct, setModalViewInfoProduct] = useState(false);
  const [productDetailId, setProductDetailId] = useState<null | number>(null);

  const openProductDetails = (id: number) => {
    setProductDetailId(id);
    setModalViewInfoProduct(true);
  };

  return (
    <>
      <ProductsResultSearch
        openProductDetails={openProductDetails}
        productList={productsList}
        addDetailButton
      />

      <ModalViewProduct
        openModal={modalViewInfoProduct}
        closeModal={() => setModalViewInfoProduct(false)}
        idSelected={productDetailId}
      />
    </>
  );
};
