export const searchString = function (message, str) {
  return !(message.indexOf(str) < 0);
};

export function TextEllipsis(text, letterLimits = 9999, ellipsis = "...") {
  if (!text) return "";

  if (text.length <= letterLimits) return text;

  return text.slice(0, letterLimits).concat(ellipsis);
}

export const removeHTMlTags = (textTag) => {
  if (!textTag) return;
  return textTag.replace(/<[^>]*>/g, "");
};

export const isWhitespaceOnly = (value) => {
  if (value && value.trim() === "") {
    return false;
  }
  return true;
};
