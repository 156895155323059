import React, { Component } from 'react'

class NotFound extends Component {

    render() {

        return (
            <div>404 - Not Found</div>
        )
    }
}

export default NotFound