import { useState } from "react";
import { useQuery } from "react-query";

import { getProductsCategories } from "Requests/Categories";

import { validateArrayType } from "Utils/Arrays";
import { createToast } from "Utils/toastFunc";

import { CategoriesReturn, Category, ErrorProps } from "./interface";

export const useCategories = (categoryId?: number): CategoriesReturn => {
  const [categorySelected, setCategorySelected] = useState<Category>({
    category: "",
    id: Number(categoryId) || 0,
  });

  const { data, isLoading, isError, error } = useQuery<
    Array<Category>,
    ErrorProps
  >({
    queryKey: ["categories"],
    queryFn: getProductsCategories,
    refetchOnWindowFocus: false,
    onError: (error) => createToast("error", error.message),
  });

  const setNewCategory = (newCategory: Category) => {
    setCategorySelected(newCategory);
  };

  return {
    categorySelected,
    categories: validateArrayType(data) ? data : undefined,
    loadingCategories: isLoading,
    categoriesError: isError ? error.message : null,
    setNewCategory,
  };
};
