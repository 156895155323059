import { useQuery } from "react-query";

import { budgetCombos } from "Requests/Budget";
import { BudgetCombosPromise } from "Requests/Budget/interface";

export const useBudgetCombos = () => {
  return useQuery<BudgetCombosPromise, string>(
    ["budget-combos"],
    budgetCombos,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
