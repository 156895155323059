import { useEffect } from "react";

import { Field } from "formik";
import { Box } from "styles/globalComponents";

import { useCovenants } from "Hooks/useCovenants";

import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";

import { CovenantFormProps } from "./interface";

export const CovenantInputFields = ({
  errors,
  touched,
  values,
  onChange,
  requiredCredential,
  blockSelection = false,
}: CovenantFormProps) => {
  const {
    covenantList = [],
    getCovenantId,
    plansList = [],
    loading: loadingCovenant,
  } = useCovenants();

  const covenantOpts = covenantList?.map((covenant) => ({
    id: covenant.id,
    value: covenant.id,
    name: covenant.covenant,
  }));

  const covenantPlansOpts = plansList?.map((plan) => ({
    id: plan.id,
    value: plan.id,
    plan: plan.covenant_plans_name,
  }));

  useEffect(() => {
    if (values.covenant) {
      getCovenantId(values.covenant);
    }
  }, []);

  return (
    <Box className="form-container-inputs">
      <div className="form-item">
        <Field
          name="covenant"
          id="covenant"
          label="CONVÊNIO*"
          options={covenantOpts}
          isMoreSelect={false}
          value={values.covenant}
          readOnly={loadingCovenant || blockSelection}
          valueKey="id"
          labelKey="name"
          loading={loadingCovenant}
          component={SelectSearch}
          placeholder="Digite para ver opções"
          onChange={({ target }: any) => {
            const { value } = target;
            getCovenantId(value);
            onChange("covenant", value);
          }}
          error={touched.covenant && errors.covenant && errors.covenant}
        />
      </div>

      <div className="form-item">
        <Field
          name="plan"
          id="plan"
          label="PLANO*"
          options={covenantPlansOpts}
          readOnly={loadingCovenant || blockSelection}
          loading={loadingCovenant}
          value={values.plan}
          valueKey="id"
          isMoreSelect={false}
          labelKey="plan"
          component={SelectSearch}
          placeholder="Selecione o plano"
          onChange={({ target }: any) => {
            const { value } = target;
            onChange("plan", value);
          }}
          error={touched.plan && errors.plan && errors.plan}
        />
      </div>

      <div className="form-item">
        <Field
          name="plan_card_number"
          type="string"
          id="plan_card_number"
          label={requiredCredential ? "CARTEIRINHA*" : "CARTEIRINHA"}
          value={values.plan_card_number}
          optionName="list"
          onChange={(e: any) => {
            const value = e.target.value.replace(/\D/g, "");
            onChange("plan_card_number", value);
          }}
          component={Input}
          placeholder="Insira o número da carteirinha"
          error={
            touched.plan_card_number &&
            errors.plan_card_number &&
            errors.plan_card_number
          }
        />
      </div>
    </Box>
  );
};
