import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router";

import { saveCovenantsOrder, searchCovenantsInfo } from "Requests/Covenant";
import {
  getInsurance,
  getInsuranceId,
  deleteInsurance,
  getImageCovenant,
} from "Requests/RequestInsurance";

import { validateArrayType } from "Utils/Arrays";
import { createToast } from "Utils/toastFunc";

import { HealthInsuranceCompanyInfo } from "Components/Cards/Covenant";
import Input from "Components/Form/Input";
import { Loader } from "Components/Loader";
import { ModalViewInsurance } from "Components/Modals/ModalViewInsurance/ModalViewInsurance";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import ArrowImg from "Assets/Images/arrow.svg";

import { CovenantGrid, LoaderContainer, NoResults } from "./styles";

export const HealthInsurance = () => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState(null);
  const [searchCovenant, setSearchCovenant] = useState("");
  const [data, setData] = useState([]);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const filterOptionsRef = useRef(null);
  const history = useHistory();

  function redirectToHealthInsuranceCreate() {
    return history.push("/convenios/novo");
  }

  async function fetchInsurance() {
    try {
      setLoading(true);
      const response = await getInsurance();
      setData(response.data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteInsurance(id, covenantName) {
    try {
      setLoading(true);
      await deleteInsurance(id);
      await fetchInsurance();
      createToast("success", `Convênio ${covenantName} deletado com sucesso`);
    } catch (error) {
      createToast("error", "Não foi possível deletar este convênio");
    } finally {
      setLoading(false);
    }
  }

  const redirectCovenantEdit = (id) => {
    history.push(`convenios/editar/${id}`);
  };

  function openFilterOptions(e) {
    e.stopPropagation();
    const { parentElement } = e.target;
    const isOpenFilter = parentElement.querySelector("div.filter-options");
    if (isOpenFilter) {
      parentElement
        .querySelector("div.filter-options")
        .classList.toggle("close");
    }
  }

  const orderBy = (array, type) => {
    const typeName = type.target.dataset.order;
    const arrayOrdenado = [...array];

    switch (typeName) {
      case "A-Z":
        arrayOrdenado.sort((a, b) => a.covenant.localeCompare(b.covenant));
        break;
      case "Z-A":
        arrayOrdenado.sort((a, b) => b.covenant.localeCompare(a.covenant));
        break;
      case "new":
        arrayOrdenado.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        break;
      case "old":
        arrayOrdenado.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        break;
      default:
        return arrayOrdenado;
    }

    return setData(arrayOrdenado);
  };

  async function saveCovenantOrderList(covenantList) {
    try {
      await saveCovenantsOrder(covenantList);
    } catch (error) {
      console.log({ error });
      createToast("error", error.message);
    }
  }

  function handleDragDrop(result) {
    if (!result.destination) return;
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const orderedItemsList = items.map((item, index) => ({
      ...item,
      order: index,
    }));
    saveCovenantOrderList(orderedItemsList);

    setData(items);
  }

  async function getCovenantInfo(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await searchCovenantsInfo(searchCovenant);
      setData(response.data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  }

  function handleChangeCovent(e) {
    const inputValue = e.target.value;

    if (inputValue) return setSearchCovenant(e.target.value);

    fetchInsurance();
  }

  const viewCovenantInfo = async (id) => {
    try {
      setLoadingOrder(true);
      setModalVisible(true);
      const response = await getInsuranceId(id);

      await Promise.allSettled(
        response?.covenant_images.map(async (item, index) => {
          if (item.img) {
            item.name = `Anexo ${index + 1}`;
            const dataImgItem = await getImageCovenant(
              item.img.replace("https://colabi-public.s3.amazonaws.com/", "")
            );

            item.type = dataImgItem.mimeType;
            item.img = dataImgItem.fileUrl;
          }
          return item;
        })
      );

      setInsuranceInfo(response);
    } catch (error) {
      return createToast("error", "Não foi possível");
    } finally {
      setLoadingOrder(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setInsuranceInfo({});
  };

  useEffect(() => {
    fetchInsurance();
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      {modalVisible && (
        <ModalViewInsurance
          modalVisible={modalVisible}
          modalDismiss={() => closeModal()}
          covenantInfo={insuranceInfo}
          loadingOrder={loadingOrder}
        />
      )}

      <div className="content-holder">
        <div className="page-heading no-shadow">
          <div className="wrapper-header-content">
            <PageHeader title="Cadastrar convênios">
              <Breadcrumb
                list={[
                  {
                    path: "/convenios",
                    label: "Convênios",
                    current: false,
                  },
                ]}
              />
            </PageHeader>

            <div className="actions-holder">
              <button
                type="button"
                onClick={redirectToHealthInsuranceCreate}
                className="button _action createbtn"
              >
                Criar
              </button>
            </div>
          </div>

          <form className="convenant_head" onSubmit={getCovenantInfo}>
            <div className="convenant_search__input">
              <Input
                id="search"
                type="search"
                placeholder="Busque pelo nome"
                onChange={(e) => handleChangeCovent(e)}
              />
              <button>BUSCAR</button>
            </div>
            <div className="convenant_order__button">
              <button
                onClick={(e) => openFilterOptions(e)}
                ref={filterOptionsRef}
              >
                <img src={ArrowImg} alt="arrow up" />
                <img src={ArrowImg} alt="arrow down" />
                Ordenar
              </button>

              <div className="filter-options close">
                <li>
                  <span
                    role="button"
                    tabIndex={0}
                    data-order="A-Z"
                    onClick={(e) => orderBy(data, e)}
                    onKeyDown={(e) => orderBy(data, e)}
                  >
                    Alfabética (A-Z)
                  </span>
                </li>
                <li>
                  <span
                    role="button"
                    tabIndex={-1}
                    data-order="Z-A"
                    onClick={(e) => orderBy(data, e)}
                    onKeyDown={(e) => orderBy(data, e)}
                  >
                    Alfabética (Z-A)
                  </span>
                </li>
                <li>
                  <span
                    role="button"
                    tabIndex={-2}
                    data-order="new"
                    onClick={(e) => orderBy(data, e)}
                    onKeyDown={(e) => orderBy(data, e)}
                  >
                    Mais recentes
                  </span>
                </li>
                <li>
                  <span
                    role="button"
                    tabIndex={-3}
                    data-order="old"
                    onClick={(e) => orderBy(data, e)}
                    onKeyDown={(e) => orderBy(data, e)}
                  >
                    Mais antigos
                  </span>
                </li>
              </div>
            </div>
          </form>
        </div>

        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}

        {!loading && !validateArrayType(data) && (
          <NoResults>Oops! Parece que nada foi encontrado...</NoResults>
        )}

        {!loading && validateArrayType(data) && (
          <CovenantGrid className="page-content-list">
            <div className="container-grid small-separate details">
              <div className="head-content">
                <span />
                <span>Logo</span>
                <span>Convênio</span>
                <span />
              </div>
              {data?.map((item, index) => (
                <DragDropContext onDragEnd={handleDragDrop} key={item.id}>
                  <Droppable droppableId="covenants">
                    {(provided) => (
                      <div
                        className="body-content"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Draggable
                          key={item.id}
                          draggableId={String(item.id)}
                          index={index}
                        >
                          {(providedItem, snapshot) => (
                            <HealthInsuranceCompanyInfo
                              id={item.id}
                              imageLogo={item.image}
                              snapshot={snapshot}
                              healthName={item.covenant}
                              provided={providedItem}
                              editHealthInfo={redirectCovenantEdit}
                              deleteHealthInfo={handleDeleteInsurance}
                              viewCovenantInfo={viewCovenantInfo}
                            />
                          )}
                        </Draggable>

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ))}
            </div>
          </CovenantGrid>
        )}
      </div>
    </PageWrapper>
  );
};
