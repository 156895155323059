import styled from "styled-components";

export const InputContainer = styled.div`
  .input-html-wrapper {
    &:placeholder {
      color: red;
    }

    .input-html-editor {
      padding: 12px;
      font-size: 14px;

      .public-DraftStyleDefault-block {
        margin: 0;
      }
    }

    .public-DraftEditorPlaceholder-root {
      color: rgba(153, 153, 153, 0.5);

      font-family: var(--font-text);
    }
  }
`;
