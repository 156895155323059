import React, { useState, useEffect } from "react";
import { useParams , Link, useHistory } from "react-router-dom";

import { getQuiz } from "Requests/Quiz";

import { createToast } from "Utils/toastFunc";

import { ContentPagesWrapper } from "layouts/ContentPagesWrapper/index";

import { Button } from "Components/Button/index";
import { CardSymptom } from "Components/Cards/CardSymptom/index";
import { ModalInformation } from "Components/Modals/ModalInformation/index";

import AcceptIcon from "Assets/Images/acceptIcon.svg";
import AtentionIcon from "Assets/Images/atention.svg";

import listBreadcrumb from "./listBreadcrumb.json";
import {
  ContainerReception,
  TitlePageSymptoms,
  FlexCards,
  TitlerReception,
  ReceptionButtons,
  BoxReception,
} from "./style";

export const Reception = () => {
  const [activeCard, setActiveCard] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [messagesCard, setMessagesCard] = useState();
  const isEnabled = activeCard[0]?.id;
  const { id, person_type } = useParams();

  const history = useHistory();

  useEffect(() => {
    (async function () {
      try {
        const quiz = await getQuiz();
        setMessagesCard(quiz.data.questions[0].answers);
      } catch {
        createToast(
          "error",
          "Algo de errado aconteceu :/, tente novamente mais tarde."
        );
        history.push(`/orcamento/pre-acolhimento/remover-paciente/${id}`);
      }
    })();
  }, []);

  const findActiveId = (id) => {
    return activeCard.find((item) => item.id === id);
  };

  const handleActiveCard = ({ message, id, impediment }) => {
    const findId = findActiveId(id);

    if (findId) {
      const newActiveCard = activeCard.filter((item) => item.id !== findId?.id);
      return setActiveCard(newActiveCard);
    }

    if (!impediment && activeCard.find((item) => item.impediment === true)) {
      return;
    } if (
      impediment &&
      activeCard.find((item) => item.impediment === false)
    ) {
      return;
    } 
      setActiveCard((prev) => [...prev, { message, id, impediment }]);
    
  };

  const handleToggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleSubmit = () => {
    const hasDifferentPit = activeCard?.filter(
      (item) => item?.impediment === true
    );
    if (!hasDifferentPit?.length) return handleToggleModal();

    history.push(`/pre-acolhimento/remover-paciente/${id}`);
  };

  const handleBackButton = () => {
    history.push("/orcamentos");
  };

  return (
    <ContentPagesWrapper
      titleHeader="Pré-Acolhimento"
      resizePaddignBottom
      listBreadcrumb={listBreadcrumb}
      ContentWrapperHeader={() => (
        <Link to="/orcamentos" className="button _white _transparentButton">
          Todos os Orçamentos
        </Link>
      )}
      ContentPageHeader={() => <TitlePageSymptoms>Sintomas</TitlePageSymptoms>}
    >
      {person_type === "web" ? (
        <ModalInformation
          modalVisible={modalVisible}
          modalDismiss={handleToggleModal}
          title="Pré-Acolhimento finalizado!"
          icon={AcceptIcon}
          message="Pronto, agora você deve seguir para a revisão do pedido. "
          messageButton="PROSSEGUIR PARA A REVISÃO"
          onClick={() => {
            history.push(`/pedido/revisar/${id}`);
          }}
        />
      ) : person_type === "person" ? (
        <ModalInformation
          modalVisible={modalVisible}
          modalDismiss={handleToggleModal}
          title="Pré-Acolhimento finalizado!"
          icon={AcceptIcon}
          message="Pronto, agora você deve seguir para o agendamento. "
          messageButton="PROSSEGUIR PARA O AGENDAMENTO"
          onClick={() => {
            history.push(`/pedido/agendar/${id}`);
          }}
        />
      ) : (
        <ModalInformation
          modalVisible={modalVisible}
          modalDismiss={handleToggleModal}
          title="Atenção!"
          icon={AtentionIcon}
          message="Para realizar o pagamento, você precisa finalizar o cadastro. Deseja continuar? "
          messageButton="FINALIZAR CADASTRO"
          onClick={() => {
            history.push(`/ficha-de-atendimento/finalizar-cadastro/${id}`);
          }}
        />
      )}

      <BoxReception>
        <ContainerReception>
          <div>
            <TitlerReception>
              O paciente <strong>possui algum desses sintomas?</strong>
            </TitlerReception>
            <FlexCards>
              {messagesCard?.map(({ answer, id, impediment }) => (
                <CardSymptom
                  message={answer}
                  active={findActiveId(id)}
                  onClick={() => handleActiveCard({ answer, id, impediment })}
                />
              ))}
            </FlexCards>
          </div>
        </ContainerReception>

        <ReceptionButtons>
          <Button classButton="_white radiusButton" onClick={handleBackButton}>
            Voltar
          </Button>
          <Button
            classButton="radiusButton"
            onClick={handleSubmit}
            disabled={!isEnabled}
          >
            Avançar
          </Button>
        </ReceptionButtons>
      </BoxReception>
    </ContentPagesWrapper>
  );
};
