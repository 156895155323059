import React from "react";

import { MaskCEP } from "Utils/Masks";

import Input from "Components/Form/Input";
import PanelContent from "Components/Panels/PanelContent";

export function Parkings({
  parkingInitial,
  parkings,
  setParkings,
  loading,
  parkingsActive,
  setParkingsActive,
}) {
  function handleFormCreateParking() {
    setParkings([...parkings, parkingInitial]);
  }

  function handleActiveNearestParkings() {
    setParkingsActive(true);
  }

  function handleChange(name, value, index) {
    const _name = name.slice(0, -1);

    const parking = [...parkings];

    parking[index] = {
      ...parking[index],
      [_name]: {
        ...parking[index][_name],
        value,
        error: "",
      },
    };
    setParkings([...parking]);
  }

  function handleDelete(callback, callbackActivePanel, array, index) {
    const filtered = array.filter((e, i) => i === index);
    const arrayCallback = array.filter((e, i) => i !== index);
    const newFind = { ...filtered[0], deleted: true };

    const results = [...arrayCallback, newFind];
    const isDeleted = results.every((parking) => parking.deleted);

    if (isDeleted) {
      callbackActivePanel(false);
      callback([parkingInitial]);
      return;
    }

    callback(results);
  }

  return (
    <PanelContent title="Estacionamentos Próximo" loading={loading}>
      {!parkingsActive && (
        <div
          className="xs-12"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            className="button _white _icon-add"
            style={{ marginBottom: "1rem" }}
            onClick={handleActiveNearestParkings}
          >
            <span className="represent-icon-holder">+</span>
            Adicionar novo
          </button>
        </div>
      )}

      {parkingsActive &&
        parkings.map(
          (_, i) =>
            !parkings[i].deleted && (
              <section
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderBottom: "1px dashed #E5E5E5",
                  marginBottom: "2rem",
                }}
              >
                <div className="sm-12 md-6">
                  <Input
                    label="Nome Estacionamento"
                    type="text"
                    id={`parking${i}`}
                    maxLength={245}
                    value={parkings[i].parking.value}
                    error={parkings[i].parking.error}
                    onChange={({ target }) =>
                      handleChange(target.name, target.value, i)
                    }
                  />
                </div>
                <div className="sm-12 md-6">
                  <Input
                    label="Cep"
                    type="text"
                    id={`cep${i}`}
                    placeholder={MaskCEP("00000000")}
                    maxLength="9"
                    value={MaskCEP(parkings[i].cep.value)}
                    error={parkings[i].cep.error}
                    onChange={({ target }) =>
                      handleChange(target.name, target.value, i)
                    }
                  />
                </div>

                <div className="sm-12 md-6">
                  <Input
                    label="Distância (Metros)"
                    type="text"
                    id={`distance${i}`}
                    maxLength={5}
                    placeholder="Digite à distância em metros"
                    value={parkings[i].distance.value}
                    error={parkings[i].distance.error}
                    onChange={({ target }) =>
                      handleChange(target.name, target.value, i)
                    }
                  />
                </div>

                <div className="sm-12 md-6">
                  <Input
                    label="Numero"
                    type="text"
                    id={`number${i}`}
                    maxLength={5}
                    value={parkings[i].number.value}
                    error={parkings[i].number.error}
                    onChange={({ target }) =>
                      handleChange(target.name, target.value, i)
                    }
                  />
                </div>

                <div className="xs-12">
                  <Input
                    label="Rua"
                    type="text"
                    id={`street${i}`}
                    maxLength={255}
                    value={parkings[i].street.value}
                    error={parkings[i].street.error}
                    onChange={({ target }) =>
                      handleChange(target.name, target.value, i)
                    }
                  />
                </div>

                <div className="xs-12 parking-itens-flex">
                  <div className="parking-grid-buttons">
                    <button
                      type="button"
                      className="button _white _icon-add"
                      onClick={handleFormCreateParking}
                    >
                      <span className="represent-icon-holder">+</span>
                      Adicionar
                    </button>

                    <button
                      type="button"
                      onClick={() =>
                        handleDelete(
                          setParkings,
                          setParkingsActive,
                          parkings,
                          i
                        )
                      }
                      className="link link-danger"
                    >
                      Excluir
                    </button>
                  </div>
                </div>
              </section>
            )
        )}
    </PanelContent>
  );
}
/*
 
*/
