import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { GetCoupons, SearchCoupons, DeleteCoupon } from "Requests/Coupons";

import validatePermissions from "Utils/validatePermissions";

import ConfirmAlert from "Components/Alert/ConfirmAlert";
import DefaultProvider from "Components/DefaultProvider";
import Input from "Components/Form/Input";
import Select from "Components/Form/Select";
import { Pagination } from "Components/Pagination";

import LoadingImage from "Assets/Images/loading-purple.gif";



const ListCoupons = () => {
  const user_permissions = useSelector((state) => state.User.permissions);

  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [pagination, setPagination] = useState({});
  const [lastPagePagination, setLastPagePagination] = useState(1);
  const [filterForm, setFilterForm] = useState({
    status: {
      value: "",
    },
    search: {
      value: "",
    },
  });

  function handleChange({ target: { value, name } }) {
    if (name === "search") {
      if (value === "") {
        setPagination({});

        fetchCoupons();
      }
    }

    setFilterForm({
      ...filterForm,
      [name]: {
        ...filterForm[name],
        value,
      },
    });
  }

  async function fetchCoupons() {
    setLoading(true);

    const couponsResponse = await GetCoupons();

    setCoupons(couponsResponse.data);

    if (couponsResponse.meta) setPagination(couponsResponse.meta.pagination);

    setLoading(false);
  }

  async function fetchSearchCoupons(search, status) {
    setLoading(true);

    const couponsResponse = await SearchCoupons(
      `?search=${search}&status=${status}`
    );

    setCoupons(couponsResponse.data);

    if (couponsResponse.meta) setPagination(couponsResponse.meta.pagination);

    setLoading(false);
  }

  async function fetchPagination(e) {
    setLoading(true);

    const search = filterForm.search.value;
    const status = filterForm.status.value;

    let couponsResponse;

    if (search !== "" || status !== "") {
      couponsResponse = await SearchCoupons(
        `?search=${search}&status=${status}&page=${e}`
      );
    } else {
      couponsResponse = await GetCoupons(e);
    }

    setCoupons(couponsResponse.data);
    setPagination(couponsResponse.meta.pagination);

    setLoading(false);
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="5" className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }

  function _tableList() {
    return !coupons.length ? (
      <tr>
        <td colSpan="5" className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {coupons.map((coupon) => (
          <tr key={coupon.id}>
            <td>{coupon.coupon_code}</td>
            <td>{coupon.name}</td>
            <td>{coupon.coupon_description}</td>
            <td>{coupon.status ? "Ativo" : "Inativo"}</td>
            <td className="text-right table-action">
              {validatePermissions("Update Coupons", user_permissions) && (
                <Link
                  to={`/cupons/${coupon.id}`}
                  className="button _blue _action"
                  type="button"
                >
                  <span className="details-text">Editar</span>
                </Link>
              )}
              {validatePermissions("Update Coupons", user_permissions) && (
                <button
                  disabled={coupon.inactive}
                  className="button _yellow _action"
                  type="button"
                  onClick={() => {
                    ConfirmAlert(
                      coupon,
                      _inactivationCouponLayout,
                      handleInactivation
                    );
                  }}
                >
                  <span className="details-text">
                    {coupon.status ? "Inativar" : "Ativar"}
                  </span>
                </button>
              )}
            </td>
          </tr>
        ))}
      </>
    );
  }

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && _showLoading()}
          {!loading && _tableList()}
        </tbody>
      </table>
    );
  }

  function _inactivationCouponLayout(
    { id, coupon_code, name, coupon_description },
    onClose,
    onSubmit
  ) {
    return (
      <div className="custom-ui">
        <h5 className="h5 text-gray text-light">
          Você tem certeza que deseja inativar esse cupom?
        </h5>
        <div className="informations-ui">
          <strong>Código: </strong>
          <p>{coupon_code}</p>

          <strong>Nome: </strong>
          <p>{name}</p>

          <strong>Descrição: </strong>
          <p>{coupon_description}</p>
        </div>
        <div className="wrapper-buttons">
          <button className="link link-danger" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="button _action"
            onClick={() => {
              onSubmit(id);
              onClose();
            }}
          >
            Sim
          </button>
        </div>
      </div>
    );
  }

  function handlePagination(e) {
    if (lastPagePagination === e.currentPage) return;

    fetchPagination(e.currentPage);

    setLastPagePagination(e.currentPage);
  }

  function handleInactivation(id) {
    fetchCouponInactivation(id);
  }

  async function fetchCouponInactivation(id) {
    const inactivationCouponResponse = await DeleteCoupon(id);

    if (inactivationCouponResponse) {
      setFilterForm({
        ...filterForm,
        status: {
          value: "",
        },
        search: {
          value: "",
        },
      });

      fetchCoupons();
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (filterForm.search.value === "" && filterForm.status.value === "") {
      fetchCoupons();

      return;
    }

    const search = filterForm.search.value;
    const status = filterForm.status.value;

    fetchSearchCoupons(search, status);
  }

  useEffect(() => {
    fetchCoupons();
  }, []);

  const breadcrumb = [
    {
      path: "/dashboard",
      label: "Dashboard",
      current: false,
    },
    {
      path: "#",
      label: "Todos os cupons",
      current: true,
    },
  ];

  const rightHeaderComponent = (
    <div className="actions-holder">
      {validatePermissions("Create Coupons", user_permissions) && (
        <Link to="/cupons/0" className="button _white _action">
          Adicionar Novo
        </Link>
      )}
    </div>
  );

  return (
    <DefaultProvider
      pageHeaderTitle="Todos os cupons"
      breadcrumb={breadcrumb}
      rightHeaderComponent={rightHeaderComponent}
      haveComponentOnHeader
    >
      <form onSubmit={handleSubmit} className="form-heading">
        <div className="row">
          <div className="sm-12 md-4 lg-4">
            <div className="search-input">
              <Input
                id="search"
                type="search"
                placeholder="Buscar por nome, código ou descrição"
                value={filterForm.search.value}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="sm-12 md-4 lg-4">
            <div className="search-input">
              <Select
                id="status"
                type="text"
                value={filterForm.status.value}
                options={[
                  {
                    id: 1,
                    name: "Ativo",
                  },
                  {
                    id: 0,
                    name: "Inativo",
                  },
                ]}
                placeholder="Status"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="sm-12 md-3">
            <div className="search-button">
              <button type="submit" className="button _action">
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="page-content page-content-list">{_renderTable()}</div>
      <div className="pagination-wrapper">
        {!loading && (
          <>
            <Pagination
              totalRecords={pagination?.total_pages}
              currentPageSelected={pagination?.current_page}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e) => handlePagination(e)}
            />
          </>
        )}
      </div>
    </DefaultProvider>
  );
};

export default ListCoupons;
