import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Container = styled(Box)`
  background: white;
  z-index: 201;
  position: absolute;
  top: 48px;
  max-height: 984px;
  height: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  &.quick-list {
    padding: 15px 12px;
  }

  p {
    font-size: 20px;
    color: var(--color-purple);
    font-family: var(--font-display);
    margin-left: 20px;
    margin-bottom: 27px;
  }

  table {
    display: revert;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f1f1f1;
        }

        td {
          padding: 8px 5px;
        }
      }
    }
  }
`;
