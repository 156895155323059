import { createToast } from "Utils/toastFunc";

import { ErrorException } from "./interface";

export const searchForErrorSatus = (error: any, status: number) => {
  if (!error.response) return;

  const { data } = error?.response;

  if (data.errors) {
    const { errors } = data;
    const errorCode = String(errors[0].code);
    const errorStatus = String(status).slice(0, 1);

    if (errorCode.includes(errorStatus)) {
      return true;
    }
  }
};

export const handleRequestErrors = ({
  reqErrors,
  errorMessage,
  throwError = false,
  showToast = true,
}: ErrorException) => {
  if ([500, 502, 504].includes(reqErrors.response.status)) {
    if (throwError) {
      throw new Error(errorMessage);
    }

    const error = `${errorMessage} Ocorreu um erro no servidor.`;

    createToast("error", error);
    return;
  }

  if (reqErrors?.response?.data?.errors && showToast) {
    const { errors } = reqErrors.response.data;

    errors.forEach((error: any) => createToast("error", error.message));
  }
};
