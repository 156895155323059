import React from "react";
import InputMask from "react-input-mask";

const Input = ({
  id,
  label,
  value,
  type,
  error,
  required,
  purple,
  multiline,
  onChange,
  onFocus,
  placeholder,
  mask,
  maskChar,
  autocomplete = "off",
  small,
  readOnly,
  maxLength,
  min,
  description,
  onKeyPress,
  renderErrorComponent = undefined,
  onBlur,
}) => {
  return (
    <div
      className={`
            field-holder 
            ${error || renderErrorComponent ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
        >
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      {multiline ? (
        <textarea
          rows="30"
          cols="6"
          className={`
          input textarea 
          ${error ? "field-holder-invalid" : ""}
          ${small ? "field-holder-small" : ""}
      `}
          name={id}
          id={id}
          placeholder={placeholder}
          value={value}
          required={required && "required"}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autocomplete}
          readOnly={readOnly}
        />
      ) : (
        <InputMask
          onKeyPress={onKeyPress}
          maskChar={maskChar || null}
          mask={mask}
          type={type}
          className="input _opacity-gray"
          name={id}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          required={required ? "required" : ""}
          placeholder={placeholder}
          autoComplete={autocomplete}
          readOnly={readOnly}
          maxLength={maxLength}
          min={min}
        />
      )}
      {renderErrorComponent ? renderErrorComponent() : null}

      {error !== "" && (
        <span
          className={`error-message ${
            !label && !error ? "error-without-label" : ""
          } `}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
