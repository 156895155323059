import styled from "styled-components";

export const Container = styled.div`
  input {
    border: none;
    padding: 10px 0;
    padding-left: 10px;

    &:focus-visible {
      outline-color: ghostwhite;
    }
  }

  & .tag {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;

    input {
      background: none;
    }
  }

  input::placeholder,
  textarea::placeholder {
    font-family: "Open Sans";
  }

  .checklist {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }

  .avaliable-channels {
    position: relative;
    z-index: 2;
  }
`;

export const CampFile = styled.div`
  margin-right: 22px;
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 96px;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-bottom: 36px;

  img {
    max-width: 109px;
    max-height: 96px;
  }

  &.addAnexo {
    margin-right: 0 !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 168px;
  }

  @media screen and (max-width: 768px) {
    width: 45%;
  }

  @media screen and (max-width: 495px) {
    width: 100%;
  }
`;

export const PositionAnnex = styled.div`
  position: relative;
  margin-bottom: 93px;

  .positionButton {
    position: absolute;
    right: 6px;
    margin-top: 15px;
  }
`;
