import { useRegions } from "Hooks/useRegions";

import { UnitsForm } from "./interface";

export const useSelectUnits = () => {
  const { allUnitsIds, regions } = useRegions();

  const selectAllUnits = (currValue: UnitsForm) => {
    const currentUnitsSelected = [...currValue.include];
    const hasToDeselectAllUnits = currentUnitsSelected.includes(0);

    if (hasToDeselectAllUnits) {
      return { include: [], exclude: [] };
    }

    return hasToDeselectAllUnits
      ? { include: [], exclude: [] }
      : { include: [0, ...allUnitsIds], exclude: [] };
  };

  const removeIdFromArray = (idList: number[], unitId: number) => {
    const childIndex = idList.indexOf(unitId);
    if (childIndex !== -1) {
      idList.splice(childIndex, 1);
    }
  };

  const selectRegion = (regionId: number, currValue: UnitsForm) => {
    const idsIncluded = new Set(currValue.include ?? []);
    const idsExcluded = new Set(currValue.exclude ?? []);

    const isRegionSelected = idsIncluded.has(regionId);
    const regionSelectedInfo = regions?.find((unit) => unit.id === regionId);
    const isAllUnitsCheckboxSelected = idsIncluded.has(0);

    if (isRegionSelected && regionSelectedInfo && isAllUnitsCheckboxSelected) {
      idsIncluded.delete(regionSelectedInfo?.id);
      idsExcluded.add(regionSelectedInfo?.id);
      regionSelectedInfo.units?.forEach((unit) => {
        idsExcluded.add(unit.id);
        idsIncluded.delete(unit.id);
      });
    }

    if (isRegionSelected && regionSelectedInfo && !isAllUnitsCheckboxSelected) {
      idsIncluded.delete(regionId);
      regionSelectedInfo?.units?.forEach((un) => {
        idsIncluded.delete(un.id);
        idsExcluded.delete(un.id);
      });
    }

    if (!isRegionSelected) {
      idsIncluded.add(regionId);
      regionSelectedInfo?.units?.forEach((unit) => {
        idsIncluded.add(unit.id);
      });
      idsExcluded.delete(regionId);
      regionSelectedInfo?.units?.forEach((unit) => {
        idsExcluded.delete(unit.id);
      });
    }

    return {
      include: Array.from(idsIncluded),
      exclude: Array.from(idsExcluded),
    };
  };

  const getParentRegion = (idUnit: number) => {
    const parentRegion = regions?.find((region) =>
      region?.units?.find((unit) => unit.id === idUnit)
    );
    return { ...parentRegion };
  };

  const selectUnit = (id: number, currValue: UnitsForm) => {
    const idsIncluded = [...currValue.include];
    const idsExcluded = [...currValue.exclude];

    const isUnitIncluded = idsIncluded.includes(id);
    const isUnitExcluded = idsExcluded.includes(id);
    const regionId = getParentRegion(id).id;

    if (isUnitExcluded) {
      removeIdFromArray(idsExcluded, id);
    }

    if (!isUnitIncluded) {
      idsIncluded.push(id);
    }

    if (isUnitIncluded && regionId) {
      const parentIdSelected = idsIncluded.includes(regionId);
      removeIdFromArray(idsIncluded, id);

      if (parentIdSelected) {
        idsExcluded.push(id);
      }
    }

    return {
      include: [...new Set(idsIncluded)],
      exclude: [...new Set(idsExcluded)],
    };
  };

  return {
    selectUnit,
    selectRegion,
    selectAllUnits,
  };
};
