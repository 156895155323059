import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Box } from "styles/globalComponents";

import { validateZipCode } from "Requests/Budget";
import { getInsuranceId, getUnitsInsurance } from "Requests/RequestInsurance";

import { setBudgetInfo } from "Actions/ServiceForm";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { Store } from "Reducers/interface";

import { handleRequestErrors } from "Utils/Errors";
import { Loading } from "Utils/Loading";

import { typeOfBudget } from "mocks";

import { Button } from "Components/Button";
import { CardTypeOfService } from "Components/Cards/CardTypeOfService";
import { BudgetInfoParams } from "Components/Cards/CardTypeOfService/interface";
import Input from "Components/Form/Input";
import { Heading } from "Components/ServiceForm/Heading";

import CartDetails from "../components/Cart/CartDetails";
import { PageBody, PageContainer, PageContent } from "../styles";
import {
  ColumnContainer,
  ColumnItem,
  Container,
  ContainerInput,
  SelectTitle,
  SubTitleUnit,
  TitleUnit,
} from "./styles";

const ServiceAttendanceType = () => {
  const [loading, setLoading] = useState({
    loadingZipCode: false,
    loadingPage: false,
  });
  const [comboUnits, setComboUnits] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);

  const { budget } = useSelector((state: Store) => state.service_form);
  const dispatch = useDispatch();
  const history = useHistory();

  const { covenantInfo } = budget ?? {};

  const {
    selectedCovenant,
    zipCode: zipcode,
    serviceSelected,
    setServiceSelected,
    setZipCode: setZipcode,
  } = useBudgetContext();

  const setTypeCollect = ({ typeCollect }: BudgetInfoParams) => {
    setZipCode("");
    setDisabledButton(typeCollect === "home");
    setServiceSelected(typeCollect as string);

    if (typeCollect === "unity") {
      dispatch(setBudgetInfo({ collect_type: typeCollect, zipCode: null }));
    }
    dispatch(setBudgetInfo({ collect_type: typeCollect }));
  };

  const getCovenant = async () => {
    try {
      const covenantData = selectedCovenant || covenantInfo;

      if (covenantData) {
        setLoading({ ...loading, loadingPage: true });
        const { covenant, covenant_plan } = covenantData;

        const responseInsurance = await getInsuranceId(covenant.id);
        typeOfBudget[1].disabled = !responseInsurance?.home_collect_permission;
        typeOfBudget[1].free_visit = !responseInsurance.free_visit;

        const responseUnitsInsurance = await getUnitsInsurance(
          covenant_plan.id
        );
        if (responseUnitsInsurance.length > 0) {
          setComboUnits(responseUnitsInsurance);
          typeOfBudget[0].disabled = false;
        }
      }
    } catch (error) {
      handleRequestErrors({
        errorMessage:
          "Não foi possível obter a lista de unidades atendidades.  ",
        reqErrors: error,
      });
    } finally {
      setLoading({ ...loading, loadingPage: false });
    }
  };

  const startBudgetCreation = () => {
    history.push(
      "/ficha-de-atendimento/person/selecionar-produtos?btype=covenant",
      { state: { from: "from-select-attendance-page" } }
    );
  };

  const handleZipCode = async (value: string) => {
    if (value.length === 9) {
      setLoading({ ...loading, loadingZipCode: true });
      const isValid = await validateZipCode(value);
      setDisabledButton(!isValid);
      setZipcode(value.replace("-", ""));
      dispatch(setBudgetInfo({ zipCode: value }));
    }

    setZipCode(value);
    setLoading({ ...loading, loadingZipCode: false });
  };

  const recoverUserData = () => {
    if (budget && budget.zipCode) {
      setDisabledButton(false);
    }
  };

  useEffect(() => {
    getCovenant();
    recoverUserData();
  }, []);

  return (
    <PageContainer className="entry-app-content entry-budget">
      <PageContent className="content-holder page-budget">
        <Heading
          list={[
            {
              path: "/orcamentos",
              label: "Orçamento",
              current: false,
            },
            {
              path: "#",
              label: "Tipo de atendimento",
              current: true,
            },
          ]}
          title="Orçamentos"
        />

        <PageBody className="cart-order-budget">
          <Container className="card-search-information">
            {loading.loadingPage ? (
              <Loading />
            ) : (
              <>
                <Box className="budget-type">
                  <SelectTitle>Selecione o tipo de atendimento</SelectTitle>

                  <CardTypeOfService
                    data={typeOfBudget}
                    onClick={setTypeCollect}
                    serviceSelected={serviceSelected}
                  />
                </Box>

                {serviceSelected === "home" && (
                  <Box mt="60">
                    <SelectTitle>Informe seu endereço</SelectTitle>
                    {loading.loadingZipCode ? (
                      <Loading />
                    ) : (
                      <ContainerInput>
                        {/* @ts-ignore */}
                        <Input
                          label="CEP"
                          maskChar={null}
                          mask="99999-999"
                          id="cep"
                          type="text"
                          placeholder="00000-000"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleZipCode(e.target.value)
                          }
                          value={zipCode || zipcode}
                        />
                      </ContainerInput>
                    )}
                  </Box>
                )}

                {serviceSelected === "unity" && (
                  <Box mt="60">
                    <SelectTitle>
                      Unidades que atendem o plano selecionado
                    </SelectTitle>
                    <ColumnContainer>
                      {comboUnits.map((item: any) => (
                        <ColumnItem key={item.id}>
                          <TitleUnit>{item?.region_name}</TitleUnit>
                          {item.units.map((subItem: any) => (
                            <SubTitleUnit key={subItem.unit_name}>
                              <span>{subItem.unit_name}: </span>
                              {subItem.address_formatted}
                            </SubTitleUnit>
                          ))}
                        </ColumnItem>
                      ))}
                    </ColumnContainer>
                  </Box>
                )}
                <Box mt="40">
                  <Button
                    onClick={startBudgetCreation}
                    disabled={disabledButton}
                  >
                    Criar orçamento
                  </Button>
                </Box>
              </>
            )}
          </Container>
          <CartDetails />
        </PageBody>
      </PageContent>
    </PageContainer>
  );
};

export default ServiceAttendanceType;
