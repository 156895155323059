import React, { useState, useEffect, ChangeEvent } from "react";

import { getProductLogs } from "Requests/ProductLogs";

import { useProductCombos } from "Hooks/useProductCombos";

import { Button } from "Components/Button";
import DefaultProvider from "Components/DefaultProvider/index";
import InputDateRange from "Components/Form/InputDateRange";
import Select from "Components/Form/Select";
import { ModalLogDetails } from "Components/Modals/ModalLogDetails";
import { Pagination } from "Components/Pagination";

import LoadingImage from "Assets/Images/loading-purple.gif";

import {
  PaginationInterface,
  FilterInterface,
  DatesInterface,
  LogInterface,
} from "./interface";

const ProductLogs = () => {
  const { combos } = useProductCombos({ includeCombo: ["log_types"] });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState({} as LogInterface);
  const [items, setItems] = useState([] as LogInterface[]);
  const [pagination, setPagination] = useState({} as PaginationInterface);
  const [filterParams, setFilterParams] = useState(null);
  const [filterForm, setFilterForm] = useState({
    action: "",
    start_at: "",
    end_at: "",
    dates: {
      startDate: null,
      endDate: null,
    },
  } as FilterInterface);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!filterParams) return;
    fetchData();
  }, [filterParams]);

  async function fetchData(page = false) {
    setLoading(true);
    !page && setPagination({});
    try {
      const params = {
        page: page || pagination.current_page,
        ...(filterForm.dates.startDate && {
          start_at: filterForm.dates.startDate.format("YYYY-MM-DD"),
        }),
        ...(filterForm.dates.endDate && {
          end_at: filterForm.dates.endDate.format("YYYY-MM-DD"),
        }),
        ...(filterForm.action && { action: filterForm.action }),
      };
      const _items = await getProductLogs({
        ...params,
      });
      setItems(_items?.data);
      setPagination(_items?.pagination || {});
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  function handleChange({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement>) {
    setFilterForm({
      ...filterForm,
      [name]: value,
    });
  }
  function handleDatesChange(value: DatesInterface) {
    setFilterForm({
      ...filterForm,
      dates: value,
    });
  }
  function handlePagination(e: any) {
    if (pagination.current_page === e.currentPage) return;
    fetchData(e.currentPage);
  }
  function _showLoading() {
    return (
      <tr>
        <td colSpan={7} className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }
  function _tableList() {
    return !items?.length ? (
      <tr>
        <td colSpan={7} className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {items?.map((item) => {
          const separete = item.created_at.toString().split(" ");
          const getTime = separete[0].split("-");
          const getHour = separete[1].split(":");
          const format = `${getTime[2]}/${getTime[1]}/${getTime[0]} - ${getHour[0]}:${getHour[1]}`;
          return (
            <tr key={item.id}>
              <td>{item?.user?.name}</td>
              <td>{item.action}</td>
              <td>{item.module}</td>
              <td>{format}</td>
              <td>
                {/* @ts-ignore */}
                <Button
                  onClick={() => {
                    setSelectedLog(item);
                    setIsModalOpen(true);
                  }}
                >
                  Detalhes
                </Button>
              </td>
            </tr>
          );
        })}
      </>
    );
  }
  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>Usuário</th>
            <th>Ação</th>
            <th>Modulo</th>
            <th>Data</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {loading && _showLoading()}
          {!loading && _tableList()}
        </tbody>
      </table>
    );
  }
  function handleSubmitFilter(event: any) {
    event.preventDefault();
    const _filterParams = {
      ...filterForm,
      period_start: filterForm.dates.startDate
        ? filterForm.dates.startDate.format("YYYY-MM-DD")
        : "",
      period_end: filterForm.dates.endDate
        ? filterForm.dates.endDate.format("YYYY-MM-DD")
        : filterForm.dates.startDate
        ? filterForm.dates.startDate.format("YYYY-MM-DD")
        : "",
    };
    setFilterParams(_filterParams as any);
  }
  const breadcrumb = [
    {
      path: "/dashboard",
      label: "Dashboard",
      current: false,
    },
    {
      path: "#",
      label: "Produtos",
      current: true,
    },
  ];
  return (
    <DefaultProvider
      pageHeaderTitle="LOGS Produtos"
      breadcrumb={breadcrumb}
      haveComponentOnHeader
    >
      <ModalLogDetails
        isOpen={isModalOpen}
        onClickClose={() => setIsModalOpen(false)}
        onClick={() => {
          setIsModalOpen(false);
          setSelectedLog({} as LogInterface);
        }}
        log={selectedLog}
      />
      <div className="page-content page-content-list">
        <form
          id="form-filter"
          onSubmit={handleSubmitFilter}
          className="form-heading"
        >
          <div className="row">
            <div className="sm-12 md-4 lg-4">
              <div className="search-input">
                <Select
                  id="action"
                  value={filterForm.action}
                  placeholder="Ação"
                  onChange={handleChange}
                  options={combos?.log_types || []}
                  label=""
                  error=""
                  required={false}
                  purple={false}
                  small={false}
                  onFocus={() => {}}
                  optionName="action"
                  readOnly={false}
                />
              </div>
            </div>
            <div className="sm-12 md-4 lg-4">
              <div className="search-input">
                <InputDateRange
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  startDateId="start_at"
                  endDateId="end_at"
                  onDatesChange={(value: any) => handleDatesChange(value)}
                  openInModal={false}
                  dates={filterForm.dates}
                  blockPastChanges={false}
                  withClear
                  label=""
                  error=""
                  small={false}
                  purple={false}
                />
              </div>
            </div>
            <div className="sm-12 md-4 lg-4">
              <div className="search-button">
                <button type="submit" className="button _action">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
        <br />
        {_renderTable()}
      </div>
      <div className="pagination-wrapper">
        {!loading && (
          <Pagination
            totalRecords={pagination?.total_pages}
            currentPageSelected={pagination?.current_page}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e) => handlePagination(e)}
          />
        )}
      </div>
    </DefaultProvider>
  );
};
export default ProductLogs;
