import React from "react";
import Modal from "react-modal";

export function ModalDetailsErrors({
  modalVisible,
  modalDismiss,
  errors
}) {

  function getMessage(error)
  {
    if(typeof error === 'string') return error;

    if(error.message) return error.message;

    if(error.error) return error.error;

    if(Array.isArray(error.errors)) return error.errors[0].message;
  }

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-schedule"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="modal-details-schedule">
        <div className="content-modal-schedule">
          <div className="header-details-modal">
            <p className="title-modal-details decoration">Listagem de Envios</p>
          </div>
          <div className="modal-details">
            <div className="page-content page-content-list">
              <table className="table-content small-separate details no-border-table">
                <thead>
                  <tr>
                    <th className="error-status">Status</th>
                    <th className="error-message">Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {errors.map((error, i) => (
                    <tr key={i}>
                      <td>{typeof error !== 'string' && error.status ? error.status : 'Erro'}</td>
                      <td>{getMessage(error)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}