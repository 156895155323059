import styled from "styled-components";

interface CommonLayout {
  gap?: string;
  justify?: string;
}

interface Flex extends CommonLayout {
  display?: "flex";
  direction?: string;
  wrap?: string;
  align?: string;
  cgap?: string;
  rgap?: string;
}

interface Grid extends CommonLayout {
  display?: "grid";
  align?: string;
  cgap?: string;
  rgap?: string;
}

interface RegularProps {
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  margin?: string;
}

type BoxProps = RegularProps & (Flex | Grid);

export const Box = styled.div.attrs((props: BoxProps) => ({
  mt: props.mt === "auto" ? "auto" : `${props.mt}px`,
  ml: props.ml === "auto" ? "auto" : `${props.ml}px`,
  mr: props.mr === "auto" ? "auto" : `${props.mr}px`,
  mb: props.mb === "auto" ? "auto" : `${props.mb}px`,
  pb: props.pb === "auto" ? "auto" : `${props.pb}px`,
  pt: props.pt === "auto" ? "auto" : `${props.pt}px`,
  pl: props.pl === "auto" ? "auto" : `${props.pl}px`,
  pr: props.pr === "auto" ? "auto" : `${props.pr}px`,
  cgap: props.cgap ? `${props.cgap}px` : "20px",
  rgap: props.rgap ? `${props.rgap}px` : "20px",
  align: props.align || "center",
}))<BoxProps>`
  ${(props) => props.mt && `margin-top:${props.mt}`};
  ${(props) => props.ml && `margin-left:${props.ml}`};
  ${(props) => props.mr && `margin-right:${props.mr}`};
  ${(props) => props.mb && `margin-bottom:${props.mb}`};
  ${(props) => props.pt && `padding-top:${props.pt}`};
  ${(props) => props.pl && `padding-left:${props.pl}`};
  ${(props) => props.pr && `padding-right:${props.pr}`};
  ${(props) => props.pb && `padding-bottom:${props.pb}`};
  ${(props) => props.margin && `margin:${props.margin}px`};
  ${(props) => props.display && `display:${props.display}`};
  ${(props) => props.display === "flex" && `flex-direction:${props.direction}`};
  ${(props) => props.display === "flex" && `flex-wrap:${props.wrap}`};
  ${(props) => props.rgap && `row-gap:${props.rgap}`};
  ${(props) => props.cgap && `column-gap:${props.cgap}`};

  ${(props) => props.display && `justify-content:${props.justify}`};
  ${(props) => props.display && `align-items:${props.align}`};

  ${(props) => props.gap && `gap:${props.gap}px`};
  box-sizing: border-box;
  width: 100%;
`;
