import React, { useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router";

import { Button } from "Components/Button";
import PageHeader from "Components/Pages/Header";

function DeleteBudgetCart({
  modalVisible,
  modalDismiss,
  deleteBudget,
  budget_id,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleConfirmDelete = async () => {
    setLoading(true);
    await deleteBudget();
    setLoading(false);
    modalDismiss();
    history.push("/ficha-de-atendimento/paciente");
  };

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="modal-wrapper-header">
        <PageHeader
          title={`Você deseja excluir o orçamento Nº ${budget_id} ?`}
        />
      </div>
      <div className="description-list modal-close-badge-flex">
        <div className="cart-flex-buttons">
          <Button
            loading={loading}
            className="button"
            onClick={handleConfirmDelete}
            borderRadius="8"
          >
            Confirmar
          </Button>

          <button className="button closeButton" onClick={modalDismiss}>
            Voltar
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteBudgetCart;
