import React from "react";
import { Editor } from "react-draft-wysiwyg";

import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import PanelContent from "Components/Panels/PanelContent";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function AdditionalInfo({
  editors,
  loading,
  setEditors,
  setObjectData,
}) {
  const {
    editorReference,
    editorDescription,
    editorOpenHours,
    editorUnmetProducts,
    editorFacilities,
    editorParkingDesk,
  } = editors;

  React.useEffect(() => {
    setObjectData((prev) => ({
      ...prev,
      reference: {
        ...prev.reference,
        value: draftToHtml(convertToRaw(editorReference.getCurrentContent())),
      },
      description: {
        ...prev.description,
        value: draftToHtml(convertToRaw(editorDescription.getCurrentContent())),
      },
      opening_hours: {
        ...prev.opening_hours,
        value: draftToHtml(convertToRaw(editorOpenHours.getCurrentContent())),
      },
      unmet_products: {
        ...prev.unmet_products,
        value: draftToHtml(
          convertToRaw(editorUnmetProducts.getCurrentContent())
        ),
      },
      facilities: {
        ...prev.facilities,
        value: draftToHtml(convertToRaw(editorFacilities.getCurrentContent())),
      },
      parking_desk: {
        ...prev.parking_desk,
        value: draftToHtml(convertToRaw(editorParkingDesk.getCurrentContent())),
      },
    }));
  }, [
    editorDescription,
    editorReference,
    editorOpenHours,
    editorUnmetProducts,
    editorFacilities,
    editorParkingDesk,
  ]);

  function handleEditorsChange(name, value) {
    setEditors((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  return (
    <div className="row">
      <div className="lg-6">
        <PanelContent title="Pontos de Referência" loading={loading}>
          <div
            className="md-12 lg-13"
            style={{ marginBottom: "5rem", marginTop: "1.5rem" }}
          >
            <label className="text-gray text-semibold">Referência</label>
            <Editor
              editorState={editorReference}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) =>
                handleEditorsChange("editorReference", value)
              }
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],

                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </PanelContent>
      </div>

      <div className="lg-6">
        <PanelContent title="Descrição" loading={loading}>
          <div
            className="md-12 lg-13"
            style={{ marginBottom: "5rem", marginTop: "1.5rem" }}
          >
            <label className="text-gray text-semibold">Descrição</label>
            <Editor
              editorState={editorDescription}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) =>
                handleEditorsChange("editorDescription", value)
              }
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </PanelContent>
      </div>

      <div className="lg-6">
        <PanelContent title="Horário de funcionamento" loading={loading}>
          <div
            className="md-12 lg-13"
            style={{ marginBottom: "5rem", marginTop: "1.5rem" }}
          >
            <label className="text-gray text-semibold">
              horário de funcionamento
            </label>
            <Editor
              editorState={editorOpenHours}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) =>
                handleEditorsChange("editorOpenHours", value)
              }
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </PanelContent>
      </div>

      <div className="lg-6">
        <PanelContent title="o que esta unidade não oferece" loading={loading}>
          <div
            className="md-12 lg-13"
            style={{ marginBottom: "5rem", marginTop: "1.5rem" }}
          >
            <label className="text-gray text-semibold">
              o que esta unidade não oferece
            </label>
            <Editor
              editorState={editorUnmetProducts}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) =>
                handleEditorsChange("editorUnmetProducts", value)
              }
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </PanelContent>
      </div>

      <div className="lg-6">
        <PanelContent title="Facilidades" loading={loading}>
          <div
            className="md-12 lg-13"
            style={{ marginBottom: "5rem", marginTop: "1.5rem" }}
          >
            <label className="text-gray text-semibold">Facilidades</label>
            <Editor
              editorState={editorFacilities}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) =>
                handleEditorsChange("editorFacilities", value)
              }
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </PanelContent>
      </div>

      <div className="lg-6">
        <PanelContent title="Descrição do Estacionamento" loading={loading}>
          <div
            className="md-12 lg-13"
            style={{ marginBottom: "5rem", marginTop: "1.5rem" }}
          >
            <label className="text-gray text-semibold">Descrição</label>
            <Editor
              editorState={editorParkingDesk}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) =>
                handleEditorsChange("editorParkingDesk", value)
              }
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "link",
                  "embedded",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </PanelContent>
      </div>
    </div>
  );
}
