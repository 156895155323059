import { useEffect, useState } from "react";
import * as Yup from "yup";
import { OrderHeading } from "Components/ServiceForm/OrderHeading";
import { PageBody, PageContainer, PageContent } from "Pages/ServiceForm/styles";
import { useHistory } from "react-router";
import { useCombos } from "Hooks/useCombos";
import { ItemType, PatientOrderType, useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { CartOrderDetails } from "Pages/ServiceForm/components/Cart/CartOrderDetails/CartOrderDeatails/index";
import { cancelAppointment } from "Requests/CancelAppointment";
import { toast } from "react-toastify";
import { createDependent, getDependents, updateDependent } from "Requests/Dependents";
import { FieldArray, Form, Formik } from "formik";
import { unmaskCPF } from "Utils/Masks";
import { ReactComponent as PlusIcon } from "Assets/Images/plus-icon.svg";
import { isCPF } from "Services/schema/validations";
import LoadingPurple from "Assets/Images/tinyficated/loading-purple.gif";
import { fetchProductRules } from "Requests/ProductRules";
import { Button } from "Components/Button";
import { scroller } from "react-scroll";
import { PersonData } from "Requests/UpdatePerson/types";
import { updatePerson } from "Requests/UpdatePerson";
import { DependentData } from "Requests/Dependents/types";
import { useShallow } from "zustand/react/shallow";
import moment from "moment";
import { parse, isToday, isValid } from "date-fns";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { ArraysType, PatientDependentType, FormType, ObjectsType, VaccineCardFile, CartType, CreateOrderType } from "./components/types/index";
import { Container, TitleCheckoutCollect } from "../style";
import { ScheduleTimer } from "../ScheduleTimer";
import { PatientCard } from "./components/PatientCard";
import { DependentType } from "./components/DependentSelect/types";
import { Card } from "./components/PatientCard/styles";
import { ProductsCards } from "./components/ProductsCard";
import { SelectedPatientProducts, SelectedProducts } from "./components/CardSelection/types";
import { CustomFieldType, ProductRuleType, RuleType } from "./components/PatientCard/types";
import { formatPayer, fromToFormFields, verifyIfHasVaccineLinked, verifyPatientAge } from "./components/PatientCard/functions";
import { toBase64 } from "./functions";
import { CancelAppointmentModal } from "../CancelAppointment";

export const CreateOrderIdentification = () => {

  const history = useHistory();

  const { order, setOrder } = useBudgetOrderLocalStorage();

  const { combos } = useCombos();


  const [setCurrentScreen, setEnabledProceedIdentification, isViewBudget] = useBudgetOrder(useShallow((state) => [
    state.setCurrentScreen,
    state.setEnabledProceedIdentification,
    state.isViewBudget
  ]));

  const [arrays, setArrays] = useState<ArraysType>({
    dependents: [],
    patients: [],
  });

  const [booleans, setBooleans] = useState({
    loading: false,
    showAddPatient: false,
    cancelAppointmentModal: false,
  });

  const [objects, setObjects] = useState<ObjectsType>({
    products_rule: null
  })

  useEffect(() => {
    fetchDependents();
    fetchProductsRules();
    setCurrentScreen('identification')
    setEnabledProceedIdentification(false);
  }, []);

  useEffect(() => {
    if (order.budget_id === 0) return history.push("/pedidos");
  }, [order]);

  const setProductWhenSinglePatient = () => {
    if (order.patients.length === 1) {
      const formattedProducts: SelectedProducts[] = [];
      order.items.map((i) => {
        formattedProducts.push({
          id: i.product_id,
          name: i.product_name,
          category: i.category
        })
      })
      const patientWithProducts: SelectedPatientProducts[] = [
        {
          patient_id: order.patients[0].index,
          products: formattedProducts
        }
      ]
      return patientWithProducts;
    }
    return []
  }

  const schemaValidation = Yup.object({
    patients: Yup.array().of(
      Yup.object({
        id: Yup.number(),
        name: Yup.string().required("Campo obrigatório"),
        email: Yup.string().required("Campo obrigatório").email("Insira um e-mail válido"),
        birthdate: Yup.string()
          .transform((value, originalValue) => {
            if (!originalValue) return value;

            const parsedDate = parse(originalValue, 'dd/MM/yyyy', new Date());

            return isValid(parsedDate) ? originalValue : null;
          })
          .typeError('Insira uma data válida no formato DD/MM/YYYY')
          .required('Campo obrigatório')
          .test('valid-date-format', 'Data inválida', function (value) {
            const parsedDate = value ? parse(value, 'dd/MM/yyyy', new Date()) : null;
            return isValid(parsedDate);
          })
          .test('not-today', 'A data não pode ser igual à de hoje', function (value) {
            const parsedDate = value ? parse(value, 'dd/MM/yyyy', new Date()) : new Date();
            return !isToday(parsedDate);
          }),
        document: Yup.object().shape({
          type: Yup.string().when('unborn', {
            is: (unborn: boolean) => unborn === false,
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string().notRequired()
          }),
          number: Yup.string().when(['type', 'unborn'], {
            is: (type: string, unborn: boolean) => type === 'cpf' && unborn === false,
            then: Yup.string().required("Documento é obrigatório").test('validateCpf', 'CPF inválido', isCPF),
            otherwise: Yup.string().notRequired()
          }),
          country_id: Yup.number().when(['type', 'unborn'], {
            is: (type: string, unborn: boolean) => type === 'passport' && unborn === false,
            then: Yup.number().min(1, "Selecione o Campo"),
            otherwise: Yup.number().notRequired(),
          }),
        }),
        gender_id: Yup.number().min(1, "Selecione o Campo"),
        formattedPhone: Yup.string().min(12, 'Complete Todos os Campos').required('Campo obrigatório'),
        social_name: Yup.string().when('gender_id', {
          is: (gender_id: number) => gender_id === 2 || gender_id === 4,
          then: Yup.string().required('Campo obrigatório'),
          otherwise: Yup.string().notRequired()
        }),
        weight: Yup.string(),
        symptoms: Yup.string(),
        mother_name: Yup.string(),
        medicament: Yup.string(),
        last_menstruation: Yup.string(),
        height: Yup.string()
      }),
    ),
  });


  const fetchDependents = async () => {
    try {
      setBooleans((booleans) => ({
        ...booleans,
        loading: true
      }))
      const dependents: PatientDependentType[] = await getDependents(order.payer_patient.id);
      mountSelectData(dependents);

      // mountPatientData(dependents);
      setBooleans((booleans) => ({
        ...booleans,
        loading: false
      }))
    } catch (err) {
      console.error(err);
      toast.error("Erro ao buscar dependentes.");
    }
  };

  const fetchProductsRules = async () => {
    try {
      const products_ids: number[] = order.items.map((i) => i.product_id);
      const productsRules: ProductRuleType = await fetchProductRules(products_ids?.join(","))
      setObjects((objects) => ({
        ...objects,
        products_rule: productsRules
      }));
    } catch (err) {
      console.error(err);
      toast.error('Erro ao buscar regras de produto')
    }
  }

  const handleCancelSchedule = async () => {

    try {
      const idSchedule = order.formattedAttendances.find((i) => i.id_scheduling != 0 && i.type === "home_collect")?.id_scheduling;
      if (idSchedule) {
        await cancelAppointment(idSchedule);

        setOrder({
          budget_id: 0,
          appointment_type_id: "",
          isTaxInserted: false,
          payer_patient: {
            id: 0,
            birthdate: '',
            document: {
              id: 0,
              number: '',
              type: '',
              country_id: 0
            },
            email: '',
            gender_id: 0,
            mother_name: '',
            name: '',
            phone: {
              id: 0,
              ddd: 0,
              ddi: 0,
              phone: 0
            },
            unborn: false,
            social_name: ''
          },
          lead: {
            id: 0,
            ddd: null,
            phone: '',
            email: null,
            name: '',
          },
          attendances: [],
          itemsQuantity: 0,
          totalPrice: '0,00',
          budget_type: 1,
          coupon_percentage_discount: 0,
          homeCollectValue: 0,
          isCovenant: false,
          discounts: 0,
          subtotal: 0,
          deliveryTime: '',
          items: [],
          product_categories: [],
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 26,
            default: true,
            reference_point: '',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: '',
          home_collected: false,
          home_collect_value: '',
          orderData: {
            channel_id: 4,
            order_type: 1,
            appointment_type_id: "",
            commercial_category: 63,
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              cupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        })
      }

    } catch (err) {
      console.error(err);
      toast.error("Erro ao cancelar o agendamento.");
    }
  };

  const mountSelectData = (dependents: PatientDependentType[]) => {
    const formattedDependents: DependentType[] = [];
    const patients: PatientOrderType[] = [];
    const payer = formatPayer(order.payer_patient)

    formattedDependents.push({
      id: order.payer_patient.id,
      full_name: order.payer_patient.name,
      isPayer: true,
    });
    dependents.map((i) => formattedDependents.push({
      id: i.id,
      full_name: i.full_name,
      isPayer: false,
    }));

    patients.push(payer);

    setArrays({
      ...arrays,
      patients: order.patients.length > 0 ? order.patients : patients,
      dependents: formattedDependents,
    });

    setOrder({
      ...order,
      patients: order.patients.length > 0 ? order.patients : patients,
      dependents,
    });
  };

  const addPatient = (push: <X = any>(obj: X) => void, patients: PatientOrderType[]) => {

    const newPatient: PatientOrderType = {
      id: 0,
      index: patients.length, // next index
      birthdate: "",
      document: {
        id: 0,
        type: "",
        number: "",
        country_id: 0,
      },
      formattedPhone: "",
      email: "",
      gender_id: 0,
      isPayer: false,
      isRequestor: false,
      mother_name: "",
      name: "",
      phone: {
        id: 0,
        ddd: 0,
        ddi: 55,
        phone: 0,
      },
      social_name: "",
      unborn: false,
      family_relationship_id: 0,
      responsible_id: 0,
      symptoms: "",
      diseases: "",
      height: "",
      last_menstruation: "",
      medicament: "",
      weight: "",
    };
    push(newPatient);


    const updatedPatients = patients;

    updatedPatients.push(newPatient);

    setOrder({
      ...order,
      patients: updatedPatients
    })

  };

  const removePatient = (remove: <X = any>(index: number) => X | undefined, index: number, patients: PatientOrderType[]) => {

    const patientId = patients[index].index;

    let clonedPatientsWithproducts = [...order.patientWithProducts];
    let clonedPatients = [...patients]

    clonedPatientsWithproducts = clonedPatientsWithproducts.filter((a) => a.patient_id !== patientId)
    clonedPatients = clonedPatients.filter((_, indexPatient) => index !== indexPatient)

    setOrder({
      ...order,
      patientWithProducts: clonedPatientsWithproducts,
      patients: clonedPatients
    });


    remove(index);
  }

  const verifyShowAddButton = (patients: PatientOrderType[]) => {
    let result = true;
    if (patients.length === order.items.length) result = false

    // Caso length de items seja 1, só que a quantidade seja mais que 1, por exemplo. 
    if (order.items.length === 1) {
      result = false
      let sumAmount = 0;
      order.items.map((i) => sumAmount += i.amount);
      if (patients.length === sumAmount) {
        result = false;
      } else {
        result = true
      }

    }

    if (order.patientWithProducts.length > 0) {
      const hasAllProductsLinked = areAllProductsLinked(order.patientWithProducts, order.items);

      result = !hasAllProductsLinked
    }

    if (patients.length === 6) {
      return false
    }

    return result
  }

  const areAllProductsLinked = (patientsWithProducts: SelectedPatientProducts[], items: ItemType[]): boolean => {
    // Extrair todos os IDs de produtos dos pacientes
    const patientProductIds = new Set(
      patientsWithProducts.flatMap(patient => patient.products.map(product => product.id))
    );

    // Verificar se todos os IDs de produtos dos items estão no conjunto de produtos dos pacientes
    return items.every(item => patientProductIds.has(item.product_id));
  };



  const showProductsCards = (patients: PatientOrderType[]) => {
    let result = false;

    /* 
    Caso Indice de Pacientes sseja maior que 1, 
    onde ele já verifica se pode adicionar paciente ou não, com base no length
    de array de items 
    */
    if (patients.length > 1) result = true;


    // Caso length de items seja 1, só que a quantidade seja mais que 1, por exemplo. 
    if (patients.length > 1 && order.items.length == 1) {
      let sumAmount = 0;
      order.items.map((i) => sumAmount += i.amount);
      result = false
    }



    return result
  }

  const hasAllProductsLinked = (patient_indexes: number[]) => {
    return patient_indexes.every(id =>
      order.patientWithProducts.some(patientProduct => patientProduct.patient_id === id)
    );
  }


  const processSingleOrMultiplePatients = async (
    values: FormType,
  ) => {
    if (values.patients.length === 1) {
      await processSinglePatient(values.patients[0]);
    } else {
      await processMultiplePatients(values);
    }
  };

  const processSinglePatient = async (patient: PatientOrderType) => {
    if (patient.isPayer) {
      await convertAndUpdatePerson(patient);
    } else if (patient.id === 0) {
      const convertedDependent = await convertDependentToCreate(patient);
      const createdDependent = await createConvertedDependent(convertedDependent);
      setOrder({
        ...order,
        patients: [
          { ...order.patients[0], id: createdDependent.id },
        ]
      })
    } else {
      const convertedDependent = await convertDependentToUpdate(patient);
      await updateConvertedDependent(convertedDependent, patient.id);
    }
  };

  const processMultiplePatients = async (
    values: FormType,
  ) => {

    values.patients.map(async (patient) => {
      if (patient.id === 0) {
        const convertedDependent = await convertDependentToCreate(patient);
        const createdDependent = await createConvertedDependent(convertedDependent);

        patient.id = createdDependent.id
      } else if (!patient.isPayer) {
        const convertedDependent = await convertDependentToUpdate(patient);
        await updateConvertedDependent(convertedDependent, patient.id);
      } else if (patient.isPayer) {
        await convertAndUpdatePerson(patient);
      }
    })

    setOrder({
      ...order,
      patients: values.patients,
    })
  };

  const setProductsToPatientsWhenSingleProduct = (patients: PatientOrderType[]) => {
    const patientWithProducts = [...order.patientWithProducts];
    for (const item of order.items) {
      let amountRemaining = item.amount;

      for (const patient of patients) {
        if (amountRemaining === 0) break;

        patientWithProducts.push({
          patient_id: patient.index,
          products: [
            {
              id: item.product_id,
              name: item.product_name,
              category: item.category,
            }
          ]
        });
        amountRemaining--;
      }
    }

    return patientWithProducts;
  }

  const convertAndUpdatePerson = async (patient: PatientOrderType) => {
    try {
      const convertedPatient: PersonData = {
        full_name: patient.name,
        social_name: patient.social_name,
        unborn: patient.unborn,
        gender_id: patient.gender_id,
        birthdate: moment(patient.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        mother_name: patient.mother_name,
        channel_id: 4,
        documents: patient.isPayer ? undefined : [{
          id: patient.document.id,
          type: patient.document.type,
          number: unmaskCPF(patient.document.number),
          country_id: patient.document.type === 'cpf' ? undefined : patient.document.country_id
        }],
        phones: [{
          id: patient.phone.id,
          ddi: 55,
          ddd: Number(patient.formattedPhone.split('+55')[1].substring(0, 2)),
          phone: Number(patient.formattedPhone.split('+55')[1].substring(2, 11)),
          default: true,
          channel_id: 4
        }],
        emails: patient.isPayer ? patient.email != order.payer_patient.email ? [{ id: 0, email: patient.email, default: true, channel_id: 4 }] : [] : [],
        addresses: order.lastAddress.city == "" ? [] : [{
          id: undefined,
          zip_code: order.lastAddress.zip_code,
          uf: order.lastAddress.uf ?? "",
          city: order.lastAddress.city,
          neighborhood: order.lastAddress.neighborhood,
          street: order.lastAddress.street,
          number: order.lastAddress.number,
          complement: order.lastAddress.complement ?? "",
          reference_point: order.lastAddress.reference_point ?? "",
          type_id: 1,
          default: true,
          channel_id: 4,
          country_id: 26
        }]
      }

      const updatedPerson = await updatePerson(patient.id, convertedPatient);
      toast.success('Pagador atualizado com sucesso!')
      return updatedPerson

    } catch (err) {
      console.error(err)
      toast.error('Problemas ao atualizar paciente')
    }

  }

  const convertDependentToCreate = async (patient: PatientOrderType) => {
    const convertedDependent: DependentData = {
      full_name: patient.name,
      social_name: patient.social_name,
      birthdate: moment(patient.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      mother_name: patient.mother_name,
      channel_id: 4,
      family_relationship_id: patient.family_relationship_id === 0 ? null : patient.family_relationship_id,
      partner_id: "1",
      unborn: patient.unborn,
      documents: !patient.unborn ? [
        {
          type: patient.document.type,
          country_id: patient.document.type === 'passport' ? patient.document.country_id : undefined,
          number: patient.document.type === 'cpf' ? unmaskCPF(patient.document.number) : patient.document.number
        }
      ] : undefined,
      phones: [{
        ddi: 55,
        ddd: Number(patient.formattedPhone.split('+55')[1].substring(0, 2)),
        phone: Number(patient.formattedPhone.split('+55')[1].substring(2, 11)),
        default: true,
        channel_id: 4
      }],
      emails: [
        {
          email: patient.email,
          default: true,
          channel_id: 4
        }
      ],
      gender_id: patient.gender_id,
      addresses: [
        {
          channel_id: 4,
          city: order.lastAddress.city,
          complement: order.lastAddress.complement,
          country_id: 26,
          default: true,
          neighborhood: order.lastAddress.neighborhood,
          number: order.lastAddress.number,
          reference_point: order.lastAddress.reference_point,
          street: order.lastAddress.street,
          type_id: 1,
          uf: order.lastAddress.uf ?? '',
          zip_code: order.lastAddress.zip_code
        }
      ],
      is_ghost: patient.unborn
    }

    return convertedDependent
  }

  const convertDependentToUpdate = async (patient: PatientOrderType) => {

    const convertedDependent: DependentData = {
      full_name: patient.name,
      social_name: patient.social_name,
      birthdate: moment(patient.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      mother_name: patient.mother_name,
      channel_id: 4,
      family_relationship_id: patient.family_relationship_id === 0 ? null : patient.family_relationship_id,
      partner_id: "1",
      unborn: patient.unborn,
      documents: !patient.unborn ? [
        {
          id: patient.document.id,
          type: patient.document.type,
          country_id: patient.document.type === 'passport' ? patient.document.country_id : undefined,
          number: patient.document.type === 'cpf' ? unmaskCPF(patient.document.number) : patient.document.number
        }
      ] : undefined,
      phones: [{
        id: patient.phone.id,
        ddi: 55,
        ddd: Number(patient.formattedPhone.split('+55')[1].substring(0, 2)),
        phone: Number(patient.formattedPhone.split('+55')[1].substring(2, 11)),
        default: true,
        channel_id: 4
      }],
      emails: [
        {
          email: patient.email,
          default: true,
          channel_id: 4
        }
      ],
      gender_id: patient.gender_id,
      addresses: order.lastAddress.city == "" ? [] : [{
        channel_id: 4,
        city: order.lastAddress.city,
        complement: '',
        country_id: 26,
        default: true,
        neighborhood: order.lastAddress.neighborhood,
        number: order.lastAddress.number,
        reference_point: '',
        street: order.lastAddress.street,
        type_id: 1,
        uf: order.lastAddress.uf ?? '',
        zip_code: order.lastAddress.zip_code
      }],
      is_ghost: patient.unborn
    }
    return convertedDependent
  }

  const createConvertedDependent = async (dependent: DependentData) => {
    try {
      const createdDependent = await createDependent(dependent, order.payer_patient.id);
      toast.success('Dependente criado com sucesso!')
      return createdDependent
    } catch (err) {
      toast.error("Erro ao criar dependente!")
    }
  }

  const updateConvertedDependent = async (dependent: DependentData, dependent_id: number) => {
    try {
      await updateDependent(dependent, order.payer_patient.id, dependent_id);
      toast.success('Dependente atualizado com sucesso!')
    } catch (err) {
      console.error(err);
      toast.error('Erro atualizar dependente!')
    }
  }

  const formatDataToCreateOrder = async (patients: PatientOrderType[]) => {
    const vaccine_cards = await mountVaccineCards(patients);
    const cart = await mountCart(patients)

    const home_collect_date = findHomeCollectDate()

    const totalValue = Number(replaceStringNumber(order.totalPrice)).toFixed(2)

    const formattedCreateOrder: CreateOrderType = {
      commercial_category: 39,
      channel_id: 4,
      commercial_document_number: String(order.budget_id),
      payer_id: order.payer_patient.id,
      order_type: order.budget_type,
      appointment_type_id: order.appointment_type_id === "" ? null : order.appointment_type_id,
      order: {
        budget_id: order.budget_id,
        subtotal: String(order.subtotal),
        discounts: String(order.discounts.toFixed(2)),
        total: totalValue,
        cupon_code: order.cupon_code,
        home_collected: order.formattedAttendances.some((a) => a.type === 'home_collect'),
        home_collect_value: order.homeCollectValue === 0 || order.homeCollectValue === '0,00' ? '0.00' : replaceStringNumber(order.homeCollectValue),
        home_collect_date,
        vaccine_cards,
        cart,
        home_collect_schedule: order.formattedAttendances.some((a) => a.type === 'home_collect') ? {
          id_home_collected: order.formattedAttendances.find((a) => a.type === 'home_collect')?.id_scheduling ?? 0,
          home_collect_date: home_collect_date ?? "",
          zip_code: order.lastAddress.zip_code,
          country: "Brasil",
          uf: order.lastAddress.uf ?? "",
          city: order.lastAddress.city,
          neighborhood: order.lastAddress.neighborhood,
          street: order.lastAddress.street,
          number: order.lastAddress.number,
          complement: order.lastAddress.complement,
          reference_point: order.lastAddress.reference_point,
          address_type_id: 1,
        } : null
      }
    }

    return formattedCreateOrder
  }

  const findHomeCollectDate = () => {
    const findHomeAttendance = order.formattedAttendances.find((a) => a.type === 'home_collect');

    if (findHomeAttendance) return moment(findHomeAttendance?.home_collect_date).format("YYYY-MM-DDThh:MM:ss");
    return null
  }

  const mountVaccineCards = async (patients: PatientOrderType[]) => {
    const vaccine_cards: VaccineCardFile[] = [];
    /* eslint-disable no-await-in-loop */
    for (const i of patients) {
      if (i.file) {
        const base64File = await toBase64(i.file)
        vaccine_cards.push({
          person_id: i.id,
          filename: i.file?.name ?? "",
          mimetype: i.file?.type ?? "",
          file: base64File ?? ""
        });
      }
    }
    /* eslint-enable no-await-in-loop */
    return vaccine_cards
  }

  const mountCart = (patients: PatientOrderType[]) => {
    const cartItems: CartType[] = [];
    let data: SelectedPatientProducts[] = [];

    const hasOneItem = order.items.length === 1;

    const patientsWithProductsOneItem = setProductsToPatientsWhenSingleProduct(patients);

    const patientWithProducts = hasOneItem ? patientsWithProductsOneItem : order.patientWithProducts

    for (const item of order.items) {
      for (const patient of patients) {
        // Define os dados para um único paciente, se houver apenas um
        if (patients.length === 1) {
          data = setProductWhenSinglePatient();
        }

        // Busca os produtos vinculados ao paciente atual
        const patientData = patients.length === 1
          ? data.find((p) => p.patient_id === patient.index)
          : patientWithProducts.find((p) => p.patient_id === patient.index);

        if (patientData) {
          // Verifica se o item do pedido está vinculado ao paciente
          const isProductLinkedToPatient = patientData.products.some(
            (linkedProduct) => linkedProduct.id === item.product_id
          );

          if (isProductLinkedToPatient) {
            // Busca atendimentos específicos, se aplicável
            const foundHomeAttendance = order.formattedAttendances.find((att) => att.type === 'home_collect' && att.products_ids.includes(item.product_id));
            const foundUnityAttendance = order.formattedAttendances.find((att) => att.type === 'unity_collect' && att.products_ids.includes(item.product_id));

            // Adiciona o item ao cartItems
            cartItems.push({
              person_id: patient.id,
              last_menstruation_period: patient.last_menstruation ? moment(patient.last_menstruation, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
              symptoms: patient.symptoms ?? "Sem sintomas",
              height: patient.height ? patient.height.replace(',', '.') : '',
              weight: patient.weight ? patient.weight.replace(',', '.') : '',
              diseases: patient.diseases ?? 'Sem Doenças',
              medicament: patient.medicament,
              product_code: item.product_code,
              amount: 1,
              item_unit_price: String(item.item_unit_price),
              total_discount_value: String(item.total_discount_value.toFixed(2)),
              total_item_value: String(item.total_item_value.toFixed(2)),
              coupon_valid: item.coupon_valid,
              sales_price: item.sales_price,
              id_home_collected: foundHomeAttendance ? foundHomeAttendance.id_scheduling : null,
              id_calendar: foundHomeAttendance ? Number(foundHomeAttendance.calendar_id) : null,
              responsible_unit: foundUnityAttendance ? foundUnityAttendance.unity_id : null,
              appointment_type_id: order.appointment_type_id,
            });
          }
        }
      }
    }

    return cartItems
  }

  const submitForm = async (values: FormType, setSubmitting: (isSubmitting: boolean) => void, setFieldError: (field: string, message: string | undefined) => void) => {
    setSubmitting(true)
    const patients_indexes: number[] = [];
    let hasVaccineLinked = false;
    let isYoungerThanFive = false;

    const handlePassportError = (i: PatientOrderType, index: number) => {
      if (i.document.number === '' && i.document.type === "passport" && !i.unborn) {
        setFieldError(`patients[${index}].document.number`, 'Campo obrigatório');
        scroller.scrollTo(`patients[${index}].document.number`, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        return true;
      }
      if (i.document.number === '' && i.document.type === 'cpf' && !i.unborn) {
        setFieldError(`patients[${index}].document.number`, 'Campo obrigatório');
        scroller.scrollTo(`patients[${index}].document.number`, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        return true;
      }
      return false;
    };

    const handleVaccineAndAge = (i: PatientOrderType, index: number) => {
      if (hasVaccineLinked) {
        isYoungerThanFive = verifyPatientAge(i);
        if (isYoungerThanFive) {
          scroller.scrollTo(`patients[${index}].file`, {
            duration: 500,
            delay: 0,
            smooth: 'easeInOutQuart',
          });
          return true;
        }
      }
      return false;
    };

    let stopFlow = false;

    for (const [index, patient] of values.patients.entries()) {

      stopFlow = verifyErrorCustomValues(values.patients, setFieldError);

      if (stopFlow) return

      patients_indexes.push(patient.index);

      if (handlePassportError(patient, index)) return;

      if (values.patients.length === 1) {
        const data = setProductWhenSinglePatient();
        hasVaccineLinked = verifyIfHasVaccineLinked(data, values.patients, patient.index);
      } else {
        hasVaccineLinked = verifyIfHasVaccineLinked(order.patientWithProducts, values.patients, patient.index);
      }

      if (handleVaccineAndAge(patient, index)) return;
    }

    if (stopFlow) return

    if (isYoungerThanFive && hasVaccineLinked) return;

    const hasProductsLinked = hasAllProductsLinked(patients_indexes);
    const hasOneProduct = order.items.length === 1;

    if (!hasProductsLinked && !hasOneProduct && values.patients.length > 1) {
      toast.error('Vincule os produtos nos pacientes faltantes.');
      return;
    }

    await processSingleOrMultiplePatients(values);


    const formattedCreateOrder = await formatDataToCreateOrder(values.patients)

    setOrder({
      ...order,
      orderData: formattedCreateOrder,
      patients: values.patients
    });
    history.push('/pedidos/criar-pedido/pagamento')
    setSubmitting(false)
  }

  const verifyErrorCustomValues = (patients: PatientOrderType[], setFieldError: (field: string, message: string | undefined) => void) => {

    let hasError = false;
    if (objects.products_rule) {
      const custom_fields: CustomFieldType[] = []
      let exists = false;

      const { showFields, ...restValues } = objects.products_rule

      for (const field in restValues) {
        custom_fields.push({
          product_id: Number(field),
          field: restValues[field]
        })
      }

      patients.map((patient, index) => {
        custom_fields.forEach((customField) => {
          if (patients.length === 1) {
            const data = setProductWhenSinglePatient();
            exists = data.some((patientProduct) =>
              patientProduct.products.some((product) => (product.id === customField.product_id) && patient.index === patientProduct.patient_id)
            );
          } else {
            exists = order.patientWithProducts.some((patientProduct) =>
              patientProduct.products.some((product) => (product.id === customField.product_id) && patient.index === patientProduct.patient_id)
            );
          }

          if (exists) {
            for (const field in customField.field) {
              const key = field as keyof RuleType;
              const formattedKey: string = fromToFormFields(key);
              if (customField.field[key]) {
                if (patient[formattedKey] === '') {
                  if (formattedKey === 'weight' || formattedKey === 'height') {
                    scroller.scrollTo(`patients[${index}].${formattedKey}`, {
                      duration: 500,
                      delay: 0,
                      smooth: 'easeInOutQuart',
                    });
                    hasError = true;
                    setFieldError(`patients[${index}].${formattedKey}`, 'Campo obrigatório');
                  }
                }
              }
            }
          }
        })
      })
    }
    return hasError
  }

  const handleCloseCancelModal = () => {
    setBooleans({
      ...booleans,
      cancelAppointmentModal: false
    });
    const { budget_id } = order

    handleCancelSchedule();

    if (isViewBudget) {
      history.push(`/orcamento/${budget_id}`);
    } else {
      history.push(`/ficha-de-atendimento/person/selecionar-produtos/${budget_id}?btype=private`);
    }
  }

  const handleOpenCancelModal = () => {
    setBooleans({ ...booleans, cancelAppointmentModal: true })
  }


  return (
    <PageContainer className="entry-app-content entry-budget">
      {order.budget_id != 0 ? <>
        <PageContent className="content-holder page-budget">
          <OrderHeading
            list={[
              {
                path: "/orcamentos",
                label: "Orçamento",
                current: false,
              },
              {
                path: "#",
                label: "Converter",
                current: false,
              },
              {
                path: "#",
                label: "Seleção de Paciente",
                current: true,
              },
            ]}
            title="Converter em Pedido"
            payerPatient={order.payer_patient}
          />
          <PageBody className="cart-order-budget">
            <Container className="card-search-information gray-background">
              <TitleCheckoutCollect>Informe os dados do paciente</TitleCheckoutCollect>
              {order.session_end ?
                <ScheduleTimer
                  expired={() => handleOpenCancelModal()}
                  content="Para garantir que sua reserva seja confirmada, por favor finalize a identificação e o pagamento do seu pedido dentro do prazo ao lado. Depois desse período sua reserva será liberada para venda novamente."
                />
                : <></>}

              {booleans.loading ?
                <div style={{ width: '100%' }}>
                  <img
                    className="loading loading-small loading-align-center"
                    src={LoadingPurple}
                    alt="Labi Saúde - Carregando"
                    loading="lazy"
                    style={{ marginTop: '3em', width: '8em', height: '8em' }}
                  />
                </div> : <Formik
                  initialValues={{
                    patients: arrays.patients,
                  }}
                  onSubmit={(values: FormType, { setSubmitting, setFieldError }) => submitForm(values, setSubmitting, setFieldError)}
                  enableReinitialize
                  validationSchema={schemaValidation}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ values, errors, handleChange, isSubmitting }) => (
                    <Form autoComplete="off">
                      <FieldArray name="patients">
                        {({ push, remove }) => (
                          <div style={{ width: "100%" }}>
                            {values.patients.map((i, index) => {
                              return (
                                <PatientCard
                                  payer_patient={order.payer_patient}
                                  key={index}
                                  index={index}
                                  patient={i}
                                  dependents={arrays.dependents}
                                  fullDependents={order.dependents}
                                  loadingDependents={booleans.loading}
                                  handleChange={handleChange}
                                  genders={combos.genders}
                                  countries={combos.countries}
                                  family_relationships={combos.family_relationships}
                                  errors={errors}
                                  hasVaccineInCart={order.items.some((a) => a.category === 'Vacinas')}
                                  patients={values.patients}
                                  items={order.items}
                                  patientWithProducts={order.patientWithProducts}
                                  removePatients={(index) => removePatient(remove, index, values.patients)}
                                  products_rule={objects.products_rule}
                                />
                              );
                            })}
                            {verifyShowAddButton(values.patients) ?
                              <div style={{ width: "100%", marginTop: 10, cursor: 'pointer' }} onClick={() => addPatient(push, values.patients)}>
                                <Card>
                                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <h3 className="addPatientTitle">ADICIONAR MAIS UM PACIENTE</h3>
                                    <div style={{ margin: 0, cursor: "pointer", marginTop: '8px', marginRight: '10px' }}>
                                      <PlusIcon />
                                    </div>
                                  </div>
                                </Card>
                              </div>
                              : <></>}
                          </div>
                        )}
                      </FieldArray>

                      {showProductsCards(values.patients) ?
                        <ProductsCards
                          patients={values.patients}
                          patientWithProducts={order.patientWithProducts}
                          items={order.items}
                        />
                        : <></>}


                      <div style={{
                        bottom: 0,
                        width: '100%',
                        backdropFilter: 'saturate(180%) blur(5px)', // Aplica desfoque e saturação
                        WebkitBackdropFilter: 'saturate(180%) blur(20px)', // Compatibilidade com Safari
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1em 0',
                        borderRadius: '1em'
                      }}>
                        <div style={{ justifyContent: 'left', marginLeft: '-3em', width: '10em' }}>

                          <Button textButton width="full" minHeight="3.7em" fontWeight="600" onClick={() => history.push('/pedidos/criar-pedido/coleta')}>
                            voltar
                          </Button>

                        </div>

                        <div style={{ width: '10em' }}>
                          <Button width="full" minHeight="3.7em" fontWeight="600" type="submit" loading={isSubmitting}>
                            Avançar
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              }
            </Container>
            <CartOrderDetails />
          </PageBody>
        </PageContent>
      </> : <></>}

      {booleans.cancelAppointmentModal ?
        <CancelAppointmentModal
          isOpen={booleans.cancelAppointmentModal}
          handleClose={() => handleCloseCancelModal()}
        />
        : <></>}
    </PageContainer>
  );
};