import React from "react";

import { Loading } from "Utils/Loading";

import { getSearchAppointments } from "../../Requests/Schedules";
import { getNameWeekSearch } from "../../Utils/Schedules";
import { ModalDetailsAppointments } from "./Modals/ModalDetailsAppointments";

export function SearchBoxSchedule({
  consult,
  IsSearch,
  calendarSelect,
  count,
  setTotalSechedules,
}) {
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState({});
  const [modalVisible, setModalVisible] = React.useState(false);
  const [data, setData] = React.useState({});

  const fetchSearchButton = React.useCallback(async () => {
    try {
      setLoading(true);
      const res = await getSearchAppointments({
        [IsSearch.type]: IsSearch.result,
        count,
        calendarId: calendarSelect.id,
      });
      setTotalSechedules(res.length);
      setResults(res);
    } catch (error) {
      setResults([]);
    } finally {
      setLoading(false);
    }
  }, [IsSearch, calendarSelect, count]);

  React.useEffect(() => {
    fetchSearchButton();
  }, [fetchSearchButton]);

  return (
    <>
      <ModalDetailsAppointments
        modalDismiss={() => setModalVisible(false)}
        modalVisible={modalVisible}
        data={data}
      />
      {!loading ? (
        results.length ? (
          results.map((result) => (
            <div
              className="schedule-search-container"
              onClick={() => {
                setModalVisible(true);
                setData(result);
              }}
            >
              <div className="search-date">
                <p>{getNameWeekSearch([...result.datetime.split("/")])}</p>
                <strong>{result.datetime.split("/")[0]}</strong>
              </div>

              <div className="schedule-search-flex-column">
                <p>Nº do Pedido</p>
                <strong>{result.id}</strong>
              </div>

              <div className="schedule-search-flex-column">
                <p>Data:</p>
                <strong>{result.datetime}</strong>
              </div>

              <div className="schedule-search-flex-column">
                <p>Horário:</p>
                <strong>{result.time}</strong>
              </div>

              <div className="schedule-search-flex-column customer">
                <p>Paciente:</p>
                <strong>{`${result.firstName} ${result.lastName}`}</strong>
              </div>

              <div className="schedule-search-flex-column">
                <button>Ver detalhes</button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-saira text-purple text-center text-size text-normal">
            Nenhum agendamento disponível...
          </p>
        )
      ) : (
        <div className="schedule-center">
          <Loading />
        </div>
      )}
    </>
  );
}
