import API from "Services/Api";


export const WalletLabiCredits = async (page = 1,params) => {
    try {
        const { data } = await API.get(`/labicredit/movements?action=extract&page=${page}&page_size=10`, { params })
        return data;
    } catch (error) {
        console.log(error)
    }
}

export const getUsersLabi = async (page= 1, params) => {
    try {
        const { data } = await API.get(`/labicredit/users?action=promoters&page=${page}&page_size=10`, { params })
        return data;
    } catch (error) {
        console.log(error)
    }
}