import { formatPhoneNumberIntl, Value } from "react-phone-number-input";

import {
  FillClientForm,
  PersonRequest,
  UpdateRequestPerson,
} from "Dtos/IPerson";

import { formatDateBR, formatDateToBackend } from "Utils/Date";

import { FormRegisterValues } from "./interface";

export const arrangeClientInfosToSetForm = (clientData: FillClientForm) => {
  const {
    full_name,
    social_name,
    birthdate,
    emails,
    documents,
    phones,
    gender,
    email,
    phone,
    ddd,
  } = clientData;

  const { id: idGender } = gender ?? {};
  const [fstPhone] = phones ?? [];

  let clientPhone = "";

  let country = "";
  let cpf = "";
  let passport = "";

  if (fstPhone) {
    const { ddd: clientDDD, ddi = "55", phone: phoneClient } = fstPhone;
    const joinedPhone = `+${ddi}${clientDDD}${phoneClient}`;
    clientPhone = fstPhone ? joinedPhone : "";
  }

  if (phone) {
    clientPhone = `+55${ddd}${phone}`;
  }

  const passportInfo = documents?.filter(
    (document) => document.type === "passport"
  );

  const cpfInfo = documents?.filter((document) => document.type === "cpf");

  if (passportInfo && passportInfo.length && passportInfo[0].country) {
    country = String(passportInfo[0].country?.id);
    passport = String(passportInfo[0].number);
  }

  if (cpfInfo && cpfInfo.length && cpfInfo[0].number) {
    cpf = String(cpfInfo[0].number);
  }

  const document =
    documents && documents?.length >= 1 ? String(documents[0].number) : "";
  const doc_type = documents && documents.length >= 1 ? documents[0].type : "";
  let emailPatient = emails?.length ? emails[0].email : "";

  if (email) emailPatient = email;

  return {
    name: full_name,
    social_name: social_name || "",
    gender: idGender ? String(idGender) : "",
    country,
    birthdate: formatDateBR(birthdate) || "",
    doc_type,
    document,
    passport,
    cpf,
    email: emailPatient,
    phone: clientPhone,
    covenant: null,
    plan: null,
    plan_card_number: null,
  };
};

export const prepareDataToSendRequest = (values: FormRegisterValues) => {
  const {
    birthdate,
    country,
    covenant,
    doc_type,
    document,
    email,
    gender,
    name,
    phone,
    plan,
    plan_card_number,
    social_name,
  } = values;

  const internationalPhone = formatPhoneNumberIntl(phone as Value);
  const regex = /\+(\d+)\s+(\d+)\s+(\d+)\s+(\d+)/;
  const phonesPiece = regex.exec(internationalPhone);

  const personObject = {
    is_ghost: false,
    full_name: name,
    social_name: social_name || undefined,
    channel_id: 2,
    birthdate: formatDateToBackend(birthdate),
    gender_id: Number(gender) || undefined,
    documents: [
      {
        number: document.replace(/\D/g, ""),

        ...(doc_type === "passport" && {
          country_id: Number(country),
        }),
        ...(doc_type === "passport" && {
          number: document,
        }),
        type: doc_type,
      },
    ],
    emails: [
      {
        channel_id: 2,
        default: true,
        email: email.toLowerCase(),
      },
    ],
    phones: [
      {
        default: true,
        channel_id: 2,
        ddi: phonesPiece ? Number(phonesPiece[1]) : "",
        ddd: phonesPiece ? Number(phonesPiece[2]) : "",
        phone: phonesPiece ? Number(phonesPiece.slice(3).join("")) : "",
      },
    ],
  };

  const covenant_credentials = {
    covenant_id: covenant,
    covenant_plan_id: plan,
    credential_number: plan_card_number || null,
  };

  return {
    personObject,
    covenant_credentials,
  };
};

const personWithFullInformation = (
  personObject: UpdateRequestPerson,
  personInfo: PersonRequest
) => {
  const { phones, emails, documents } = personInfo ?? {};
  const {
    documents: personDocs,
    phones: personPhones,
    emails: personEmail,
  } = personObject;

  const [fstDoc] = personDocs;
  const [fstPhone] = personPhones;
  const [fstEmail] = personEmail;
  let newArrDocuments = [...(documents ?? [])];

  const pnhs = phones?.map((phone, index) => {
    if (index === 0)
      return {
        channel_id: 2,
        ddd: fstPhone.ddd,
        ddi: fstPhone.ddi,
        default: fstPhone.default,
        phone: fstPhone.phone,
        id: phone.id,
      };

    return phone;
  });

  const emls = emails?.map((email, index) => {
    if (index === 0)
      return {
        channel_id: 2,
        default: fstEmail.default,
        email: fstEmail.email,
        id: email.id,
      };

    return email;
  });

  const docSelectedIndex = documents?.findIndex(
    (doc) => doc.type === personObject.documents[0].type
  );

  if (docSelectedIndex && docSelectedIndex >= 0) {
    newArrDocuments[docSelectedIndex].number = fstDoc.number;
  }

  if (documents && documents?.length <= 0) {
    newArrDocuments = personDocs.map((doc) => {
      const isPassport = doc.type === "passport";

      const docPerson = {
        number: doc.number,
        type: doc.type,
        ...(isPassport && { country_id: doc.country_id }),
      };

      return docPerson;
    });
  }

  newArrDocuments = newArrDocuments.map((doc) => {
    if (doc.type === "cpf")
      return {
        id: doc.id,
        type: doc.type,
        number: doc.number,
      };

    return {
      country_id: doc.country?.id,
      id: doc.id,
      number: doc.number,
      type: doc.type,
    };
  });

  return {
    newArrDocuments,
    emls,
    pnhs,
  };
};

export const transformSomePersonInfo = (
  personObject: UpdateRequestPerson,
  personInfo: PersonRequest | undefined
) => {
  const { id: uniqueId, emails, documents } = personInfo ?? {};
  const {
    documents: personDocs,
    phones: personPhones,
    emails: personEmail,
  } = personObject;

  const isPersonWithFullInformation =
    emails && emails[0]?.email && documents && documents[0]?.number;

  if (isPersonWithFullInformation && personInfo) {
    const { pnhs, emls, newArrDocuments } = personWithFullInformation(
      personObject,
      personInfo
    );

    const replacePersonInfo = {
      ...personObject,
      is_ghost: undefined,
      phones: pnhs,
      emails: emls,
      documents: newArrDocuments,
    };

    return { uniqueId, replacePersonInfo };
  }

  const replacePersonInfo = {
    ...personObject,
    is_ghost: undefined,
    phones: personPhones,
    emails: personEmail,
    documents: personDocs,
  };

  return { uniqueId, replacePersonInfo };
};
