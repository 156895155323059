import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const SearchInput = styled.div`
  grid-row: 1/2;
  margin: 0;

  & > div {
    padding: 0;
    margin: 0;
  }
`;

export const SearchFilters = styled.div`
  margin: 0;
  row-gap: 20px;
  align-items: center;
  grid-row: 2/3;
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;

  .filter-by {
    color: #b1b1b1;
    font-family: var(--font-display);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
  }

  .w-select {
    width: 170px;
  }

  .w-auto {
    width: auto;
  }

  &.row > div:first-child {
    padding-left: 0px;
  }

  .react-select__control {
    padding-left: 8px;
  }
`;

export const OrderInput = styled(Box)`
  width: auto;
`;

export const FiltersContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-rows: 100% 100%;
  gap: 0;
  row-gap: 20px;

  .container-btn-actions {
    width: max-content;

    .action-btn {
      padding: 8px 24px;
      font-weight: 500;
    }
  }
`;
