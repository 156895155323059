import React from "react";
import Modal from "react-modal";

import { dateFormatter } from "../../../Utils/Date";

export function ModalUsersSearch({
  modalVisible,
  modalDismiss,
  username,
  data,
}) {
  function switchKey(key) {
    switch (key) {
      case 1:
        return "Credito";
      case 2:
        return "Debito";
      case 3:
        return "Estorno"; // credito
      case 4: // bloquear se possuir o 4
        return "Expirado";
      case 6:
        return "Estorno Credito"; // debito
      default:
        return "Não foi possível localizar";
    }
  }

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-schedule modal-labi-credits"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      {Object.keys(data).length > 0 && (
        <div className="modal-details-schedule modal-details-labi-credits">
          <div className="content-modal-schedule">
            <div className="header-details-modal m-2">
              <p className="title-modal-details decoration">{username}</p>

              <div className="schedule-details-information">
                <p>
                  {dateFormatter(data.created_at)[0]} -{" "}
                  {data.expire_at !== "None"
                    ? dateFormatter(data.expire_at)[0]
                    : "Não expiravel"}
                </p>
                <a>Pedido # {data.order_id}</a>
              </div>
            </div>

            <div className="modal-details">
              <p className="subtitle-modal-details m-2">
                Informações do pedido N° {data.order_id}:
              </p>

              <div className="data-holder-order token-area">
                <div className="list-item">
                  <dt className="item-title">Nome:</dt>
                  <dd className="item-data">{username}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Tipo de transação:</dt>
                  <dd className="item-data">{switchKey(data.operation_id)}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Pedido:</dt>
                  <dd className="item-data"># {data.order_id}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Valor:</dt>
                  <dd className="item-data">
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(data.amount)}
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
