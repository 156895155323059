import { useSelector } from "react-redux";

import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { Store } from "Reducers/interface";

import {
  handleTextService,
  separateAttendments,
  separateCovenantAttendment,
} from "Utils/Attendments";
import { formatBRLCurrency } from "Utils/FormatCurrency";

import { AttendmentCollect } from "../CartDetails/interface";

export const CartSummary = () => {
  const { budget, zipCode } = useBudgetContext();
  const { order } = useSelector((store: Store) => store.service_form);
  const { paymentDetails } = order;
  const { is_covenant } = budget ?? {};

  const numberOfItemsCart = budget?.cart.length ? budget.cart.length : 0;
  const totalOrderValue = formatBRLCurrency(budget?.total);

  let attendments: AttendmentCollect[] = [];

  if (is_covenant && zipCode) {
    attendments = separateCovenantAttendment(
      budget?.cart,
      budget?.budget_type,
      "unity_collect"
    );
  }

  if (is_covenant && !zipCode) {
    attendments = separateCovenantAttendment(
      budget?.cart,
      budget?.budget_type,
      "home_collect"
    );
  }

  if (!is_covenant) {
    attendments = separateAttendments(budget?.cart, budget?.budget_type);
  }

  return (
    <Box>
      <h5 className="title-search-products-budget">
        Resumo:{" "}
        <span>
          {numberOfItemsCart} {numberOfItemsCart > 1 ? "itens" : "item"}
        </span>{" "}
        no orçamento
      </h5>
      <p
        dangerouslySetInnerHTML={{
          __html: handleTextService(
            numberOfItemsCart,
            totalOrderValue,
            attendments.length,
            is_covenant || false
          ),
        }}
      />
    </Box>
  );
};
