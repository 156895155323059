import { toast } from 'react-toastify';


export function createToast(style, message) {
    toast.dismiss();

    setTimeout(() => {
        toast[style](message, {
          autoClose: false,
        });
    }, 400);
}