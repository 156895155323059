import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import moment from "moment";

import "react-loading-skeleton/dist/skeleton.css";
import { getClinicalScheduleTimesReturn } from "Requests/ClinicalScheduleTimesReturn";

import { getDayAndMonth, getDayInWeek } from "Utils/Day";
import { searchForErrorSatus } from "Utils/Errors";
import { FakePromise } from "Utils/Promise";
import { createToast } from "Utils/toastFunc";

import { APISchedulesProps, ClinicalSchedulesWeeksProps } from "./interface";
import {
  Container,
  Day,
  Days,
  ScheduleDay,
  ScheduleFooter,
  WeekContainer,
} from "./styles";

export function ClinicalSchedulesWeek({
  code,
  setId,
  numberOfDays = 6,
  nextWeekText = "Semana seguinte",
  previousWeekText = "Semana anterior",
  procedure,
  return_released,
  selectedSchedule,
}: ClinicalSchedulesWeeksProps) {
  const firstDay = useRef(
    (() => {
      const today = moment();

      if (today.isoWeekday() === 7) {
        return today.add(1, "day");
      }

      return today;
    })()
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [availableTimes, setAvailableTimes] = useState<APISchedulesProps>(
    Array(numberOfDays)
      .fill(".")
      .map((_, index) => ({
        date: moment(firstDay.current).add(index, "days").format("YYYY-MM-DD"),
        hours: [],
        isEmpty: true,
      }))
  );

  const times = useRef(
    (() => {
      let hour = 7;
      let isHalf = true;

      if (code === "tele-medicine") {
        let hours = 7;
        let minutes = "45";

        return Array(32)
          .fill("-")
          .map((_, i) => {
            if (minutes === "45") hours++;

            const quarterMap: Record<string, string> = {
              "00": "15",
              "15": "30",
              "30": "45",
              "45": "00",
            };

            minutes = quarterMap[minutes];

            return `${hours.toString().padStart(2, "0")}:${minutes}`;
          });
      }

      return Array(16)
        .fill("-")
        .map((_, i) => {
          if (isHalf) hour++;
          isHalf = !isHalf;

          return `${hour.toString().padStart(2, "0")}:${isHalf ? "30" : "00"}`;
        });
    })()
  );

  function formatDayWithTime(date: string | Date | moment.Moment) {
    const formattedDate = moment(
      typeof date === "string" ? backendDateToDate(date) : date
    ).format("YYYY-MM-DD");

    return times.current.map((time) => `${formattedDate} ${time}:00`);
  }

  function backendDateToDate(date: string) {
    return moment(date, "YYYY-MM-DD").toDate();
  }

  function isToday(date: Date | string | moment.Moment) {
    const formattedDate = moment(
      typeof date === "string" ? backendDateToDate(date) : date
    );

    return moment().isSame(formattedDate, "day");
  }

  function nextDays() {
    let numberOfSundays = 0;

    for (let i = 0; i < numberOfDays; i++) {
      const firstDate = moment(availableTimes[0].date, "YYYY-MM-DD");

      const currentDate = firstDate.add(i + 1, "days");

      if (currentDate.isoWeekday() === 7) {
        numberOfSundays++;
      }
    }

    const firstDate = moment(availableTimes[0].date, "YYYY-MM-DD");

    if (numberOfSundays) {
      firstDay.current = moment(firstDate).add(
        numberOfSundays + numberOfDays,
        "days"
      );
    } else {
      firstDay.current = moment(firstDate).add(numberOfDays, "days");
    }

    fetchTimes();
  }

  function prevDays() {
    let numberOfSundays = 0;

    for (let i = 0; i < numberOfDays; i++) {
      const firstDate = moment(availableTimes[0].date, "YYYY-MM-DD");

      const currentDate = firstDate.subtract(i + 1, "days");

      if (currentDate.isoWeekday() === 7) {
        numberOfSundays++;
      }
    }

    const firstDate = moment(availableTimes[0].date, "YYYY-MM-DD");

    if (numberOfSundays) {
      firstDay.current = moment(firstDate).subtract(
        numberOfSundays + numberOfDays,
        "days"
      );
    } else {
      firstDay.current = moment(firstDate).subtract(numberOfDays, "days");
    }

    fetchTimes();
  }

  function formatTimes(times: APISchedulesProps) {
    if (!times) {
      createToast("error", "Não foi possível carregar os horários.");
      return null;
    }

    const availableDates = [];
    let increment = 0;

    while (availableDates.length < numberOfDays) {
      const momentDate = moment(firstDay.current).add(increment, "days");

      if (momentDate.isoWeekday() === 7) {
        increment++;
        continue;
      }

      const formattedDate = momentDate.format("YYYY-MM-DD");

      const findDate = times.find((time) => time.date === formattedDate);

      availableDates.push(
        findDate
          ? { ...findDate, isEmpty: false }
          : { date: formattedDate, hours: [], isEmpty: true }
      );

      increment++;
    }

    return availableDates;
  }

  const daysCount = () => {
    let day = numberOfDays;

    if (
      moment(firstDay.current).day() === 6 ||
      moment(firstDay.current).add(1, "days").day() === 6 ||
      moment(firstDay.current).add(2, "days").day() === 6
    ) {
      day = numberOfDays + 1;
    }

    return moment(firstDay.current).add(day, "days").format("YYYY[-]MM[-]DD");
  };

  const statusCodeRange500 = async () => {
    createToast("error", "Não foi possível buscar as datas disponíveis.");
    await FakePromise(3000);

    if (window.location.pathname.includes("/pedido/agendar")) {
      window.location.reload();
    }
  };

  async function fetchTimes() {
    if (!code) return;

    try {
      setIsLoading(true);
      const response = await getClinicalScheduleTimesReturn(
        code,
        moment(firstDay.current).format("YYYY[-]MM[-]DD"),
        daysCount(),
        procedure,
        return_released
      );

      const data = response?.data as APISchedulesProps;

      const formattedData = formatTimes(data);
      if (formattedData) setAvailableTimes(formattedData);
    } catch (error: any) {
      const errorFounded = searchForErrorSatus(error, 500);

      if (!error.response || errorFounded) {
        statusCodeRange500();
        return;
      }

      createToast("error", "Erro ao carregar os agendamentos");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTimes();
  }, [code]);

  useEffect(() => {
    if (selectedSchedule) {
      selectedSchedule(selectedTime);
    }
    availableTimes.forEach((time) => {
      time.hours.forEach((hour) => {
        if (hour.appointment_at === selectedTime) {
          setId(hour.appointment_at);
        }
      });
    });
  }, [selectedTime]);

  return (
    <Container>
      <WeekContainer>
        {availableTimes.map((time, index) => (
          <React.Fragment key={index}>
            {time.isEmpty ? (
              <ScheduleDay today={isToday(time.date) && !isLoading}>
                <header>
                  {isLoading ? (
                    <Skeleton height={96} style={{ marginBottom: "1rem" }} />
                  ) : (
                    <>
                      <p>
                        {(isLoading && <Skeleton height={28} />) ||
                          getDayInWeek(backendDateToDate(time.date))}
                      </p>
                      <p>
                        {(isLoading && <Skeleton height={28} />) ||
                          getDayAndMonth(backendDateToDate(time.date))}
                      </p>
                    </>
                  )}
                </header>
                {isLoading ? (
                  <Skeleton height={396} />
                ) : (
                  <Days>
                    {times.current.map((hour) => (
                      <Day available={false} disabled key={hour}>
                        {hour}
                      </Day>
                    ))}
                  </Days>
                )}
              </ScheduleDay>
            ) : (
              <ScheduleDay today={isToday(time.date) && !isLoading}>
                <header>
                  {isLoading ? (
                    <Skeleton height={96} style={{ marginBottom: "1rem" }} />
                  ) : (
                    <>
                      <p>
                        {(isLoading && <Skeleton height={28} />) ||
                          getDayInWeek(backendDateToDate(time.date))}
                      </p>
                      <p>
                        {(isLoading && <Skeleton height={28} />) ||
                          getDayAndMonth(backendDateToDate(time.date))}
                      </p>
                    </>
                  )}
                </header>

                {isLoading ? (
                  <Skeleton height={396} />
                ) : (
                  <Days>
                    {formatDayWithTime(time.date).map((formattedHour) => (
                      <Day
                        key={formattedHour}
                        available={time.hours.some(
                          (t) => t.appointment_at === formattedHour
                        )}
                        selected={formattedHour === selectedTime}
                        onClick={
                          time.hours.some(
                            (t) => t.appointment_at === formattedHour
                          )
                            ? () => setSelectedTime(formattedHour)
                            : undefined
                        }
                      >
                        {formattedHour.substring(11, 16)}
                      </Day>
                    ))}
                  </Days>
                )}
              </ScheduleDay>
            )}
          </React.Fragment>
        ))}
      </WeekContainer>

      {isLoading ? (
        <Skeleton width={520} height={50} />
      ) : (
        <ScheduleFooter>
          <div>
            <button
              disabled={!code || firstDay.current.diff(moment(), "days") <= 0}
              onClick={() => code && prevDays()}
            >
              {"<"}
            </button>
            <p>{previousWeekText}</p>
          </div>
          <span>{getDayAndMonth(firstDay.current, 0, true)}</span>
          <div>
            <p>{nextWeekText}</p>
            <button disabled={!code} onClick={() => code && nextDays()}>
              {">"}
            </button>
          </div>
        </ScheduleFooter>
      )}
    </Container>
  );
}
