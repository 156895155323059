import React from "react";

import { TextModal, Olist } from "Pages/RegistrationCleaning/styles";

export const DuplicatedListModal = () => {
  return (
    <TextModal className="duplicated-list-text black" fontWeight={400}>
      <span>Para realizar o ajuste, basta seguir os passos abaixo:</span>
      <Olist>
        <li>Identificar o(s) cadastro(s) correto(s) do cliente.</li>
        <li>
          Realizar a limpeza do cadastro, desvinculando os nomes ao CPF do
          cliente.
        </li>
        <li>
          Conferir os dados do cliente, validando as informações de{" "}
          <b>identidade</b> e <b>contato</b>.
        </li>
      </Olist>
    </TextModal>
  );
};
