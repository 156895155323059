import React from "react";

import trashIcon from "Assets/Images/delete-covenent.png";
import uploadCovenant from "Assets/Images/upload-covenant.png";

import { CardUploadCovenantProps } from "./interface";
import { ContainerFile, ImgCardCovenant } from "./style";

export const CardUploadCovenant = ({
  initialImgCard,
  label,
  name,
  value,
  onChange,
  onDelete,
  accept,
}: CardUploadCovenantProps) => {
  const handleImgCard = () => {
    if (value) return URL.createObjectURL(value);
    return initialImgCard;
  };

  return (
    <ContainerFile active={value}>
      <img src={handleImgCard()} alt="img-card" />
      <span>{value?.name ? value?.name : label}</span>

      {value ? (
        <button
          type="button"
          style={{
            cursor: "pointer",
            border: "none",
            backgroundColor: "transparent",
          }}
          onClick={() => onDelete(name, value)}
        >
          <img src={trashIcon} alt="remover" />
        </button>
      ) : (
        <>
          <label htmlFor={name} style={{ cursor: "pointer" }}>
            <ImgCardCovenant src={uploadCovenant} alt="" />
          </label>
          <input
            accept={accept}
            type="file"
            id={name}
            onChange={(e: any) => onChange(e)}
            multiple
            style={{ display: "none" }}
          />
        </>
      )}
    </ContainerFile>
  );
};
