import { Box } from "styles/globalComponents";

import { CheckboxListProps } from "./interface";
import { Container } from "./style";

export const CheckboxList = ({ title, error, children }: CheckboxListProps) => {
  return (
    <Container className={`input-container  ${error ? "invalid" : ""}`}>
      <span className="checkbox-list-title"> {title} </span>

      {children}

      {error && (
        <Box mt="3" mr="8">
          <span className="checkbox-list-error"> {error} </span>
        </Box>
      )}
    </Container>
  );
};
