import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  max-width: 490px;
  height: 40px;
  background-color: rgba(171, 146, 191, 0.15);
  border-radius: 14px;

  margin-top: 0.6em;
  margin-bottom: 1em;

  img {
    margin: 0 19px;
    width: 16px;
    height: 12px;
  }

  span {
    color: #572580;
    font-weight: 400;
    font-family: "Saira", sans-serif;
    font-size: 13px;
  }
`;
