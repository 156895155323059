import { FormValuesProps } from "Pages/Product/ProductForm/interface";

import { CATEGORIES } from "Constants/Categories";

import { ListProduct } from "Hooks/useProduct/interface";
import { RegionUnits } from "Hooks/useRegions/interface";
import { UnitsForm } from "Hooks/useSelectUnits/interface";

import { validateArrayType } from "Utils/Arrays";
import { createToast } from "Utils/toastFunc";

type UnitsSelected =
  | {
      exclude: number[];
      include: number[];
    }
  | undefined;

interface ProductFields extends FormValuesProps {
  [index: string]: any;
}

const deleteExamsFields = [
  "subcategories",
  "dose_amount",
  "dose_interval",
  "application_id",
  "rule.attenuated_virus",
  "rule.commercial_code",
  "rule.only_central",
  "isPackage",
];

const deleteCheckFields = [
  "dose_amount",
  "dose_interval",
  "application_id",
  "rule.attenuated_virus",
  "rule.commercial_code",
  "isPackage",
];

const deleteTestsField = [
  "dose_amount",
  "dose_interval",
  "isPackage",
  "application_id",
  "rule.commercial_code",
];

const deleteVaccineFields = [
  "sample_id",
  "diets",
  "fast_id",
  "fast_minutes",
  "product_pre_collection",
  "methodology_id",
  "isPackage",
];

const deleteConsultFields = [
  "sample_id",
  "dose_amount",
  "dose_interval",
  "application_id",
  "diets",
  "fast_id",
  "fast_minutes",
  "stop_medication_id",
  "stop_medication_obs",
  "methodology_id",
  "isPackage",
  "rule.commercial_code",
];

const deleteImagesField = [
  "sample_id",
  "dose_amount",
  "dose_interval",
  "services_periods",
  "methodology_id",
  "isPackage",
  "rule.commercial_code",
];

const deleteInfusionFields = [
  "sample_id",
  "dose_amount",
  "dose_interval",
  "methodology_id",
  "isPackage",
  "rule.commercial_code",
];

function deleteObjProperties(object: any, dataToRetrieve: string) {
  let obj: any = {};
  dataToRetrieve.split(".").forEach((token: string) => {
    if (object[token]) {
      obj = object[token];
      return;
    }
    if (Object.hasOwn(obj, token)) delete obj[token];
  });
  return { ...object, obj };
}

const deleteFields = (values: ProductFields, excludeField: string[]) => {
  const fields = { ...values };
  excludeField.forEach((field) => {
    if (field.includes(".")) {
      return deleteObjProperties(fields, field);
    }
    return delete fields[field];
  });

  return fields;
};

export const deleteFieldOnCategory = (values: FormValuesProps) => {
  switch (values.category_id.id) {
    case 1:
      return deleteFields(values, deleteExamsFields);
    case 2:
      return deleteFields(values, deleteCheckFields);
    case 3:
      return deleteFields(values, deleteTestsField);
    case 4:
      return deleteFields(values, deleteVaccineFields);
    case 5:
      return deleteFields(values, deleteConsultFields);
    case 7:
      return deleteFields(values, deleteImagesField);
    default:
      return deleteFields(values, deleteInfusionFields);
  }
};

export const formatResultTime = (
  id: number | undefined,
  time: number | undefined
) => {
  if (id && time) {
    switch (id) {
      case 1:
        return time;

      case 2:
        return time * 60;

      case 3:
        return time * 60 * 24;

      default:
        return "";
    }
  }
  return 0;
};

const validateDuplicateId = (array: any, nameId: any) => {
  const ids = new Set();
  for (const item of array) {
    if (ids.has(item[nameId])) {
      return true; // ID duplicado encontrado
    }
    ids.add(item[nameId]);
  }

  return false; // Nenhum ID duplicado encontrado
};

export const formatCreateProduct = (values: FormValuesProps) => {
  const productFieldsValue = deleteFieldOnCategory(values);

  const {
    rule,
    category_id,
    fast_minutes,
    dose_interval,
    slug,
    units,
    kits,
    partners,
    services_periods,
    mt_code,
    product_pre_collection,
    stop_medication_id,
    fastItems,
  } = productFieldsValue;

  const servicesPeriods = services_periods?.filter(
    (servicec) => servicec.start_id && servicec.end_id
  );

  const response = validateDuplicateId(fastItems, "fast_id");
  if (response) {
    createToast(
      "error",
      "Você tem itens duplicados em jejum. Por favor, exclua o item duplicado ou faça a alteração"
    );
    return false;
  }

  if (fastItems.length > 1 && fastItems.some((item) => item.fast_id === 1)) {
    createToast("error", "A opção todos deve ser única no campo de jejum");
    return false;
  }

  const formattedObj = {
    ...productFieldsValue,
    mt_code: mt_code || undefined,
    category_id: category_id.id,
    rule: {
      ...rule,
      ...(category_id.id === Number(CATEGORIES.vaccine) && {
        commercial_code: rule.commercial_code || null,
      }),
      max_items_cart: rule.max_items_cart || null,
    },
    slug: slug.toLowerCase(),
    fast_minutes: fast_minutes ?? undefined,
    ...(category_id.id === Number(CATEGORIES.vaccine) && {
      dose_interval: dose_interval || 0,
    }),
    stop_medication_id: stop_medication_id !== 0 ? stop_medication_id : null,
    kits: kits?.length ? kits?.map((kit) => kit.id) : [],
    partners: partners?.map((partner) => ({
      price: partner.price,
      list_id: partner.partner_id,
      ...((partner.result_id || partner.term === 0) && {
        result_time: formatResultTime(
          Number(partner.result_id),
          Number(partner.term)
        ),
      }),
    })),

    services_periods: validateArrayType(servicesPeriods)
      ? services_periods?.filter(
          (periods) => periods.start_id && periods.end_id
        )
      : undefined,

    product_pre_collection:
      product_pre_collection &&
      product_pre_collection?.filter(
        (item) => item?.collection_id && item?.collection_id !== 0
      ),
    fastItems:
      fastItems.length > 0
        ? fastItems
            .map((item) => {
              return {
                ...item,
                fast_hours_min:
                  item.fast_hours_min === "" ? null : item.fast_hours_min,
                fast_hours_max:
                  item.fast_hours_max === "" ? null : item.fast_hours_max,
              };
            })
            .filter(
              (itemFilter) =>
                itemFilter.fast_id !== "" && itemFilter.fast_id !== 0
            )
        : [],
  };

  return formattedObj;
};

export const convertDeadlines = (minutes: number) => {
  switch (Boolean(minutes)) {
    case minutes >= 1440: {
      const time = minutes / 24 / 60;

      return {
        time,
        id: 3,
        label: time === 1 && time < 2 ? "Dia" : "Dias",
      };
    }

    case minutes >= 60: {
      const time = minutes / 60;

      return {
        time,
        id: 2,
        label: time === 1 && time < 2 ? "Hora" : "Horas",
      };
    }

    case minutes > 1:
      return {
        time: minutes,
        id: 1,
        label: "Minutos",
      };

    default:
      return {
        time: 0,
        id: 0,
        label: "",
      };
  }
};

export const createInitialValues = (product: ListProduct | undefined) => {
  const new_product_pre_collection = product?.product_pre_collection;
  if (product?.product_pre_collection.length === 1) {
    product?.product_pre_collection.map((item) => {
      if (item.collection_id === 1) {
        new_product_pre_collection?.push({ collection_id: 0 });
      } else new_product_pre_collection?.unshift({ collection_id: 0 });
    });
  }

  return {
    fastItems:
      product?.fasts && product?.fasts?.length > 0
        ? product?.fasts
        : [{ fast_id: "", fast_hours_min: "", fast_hours_max: "" }],
    product: product?.product ?? "",
    slug: product?.slug ?? "",
    sku: product?.sku ?? "",
    mt_code: product?.mt_code || "",
    isPackage: validateArrayType(product?.kits),

    category_id: {
      id: product?.category?.id || 0,
      category: product?.category?.category || "",
    },
    subcategories: validateArrayType(product?.subcategories)
      ? product?.subcategories?.map(
          (subcategory: { id: number }) => subcategory.id
        )
      : [],

    partners: product?.partners.length
      ? product.partners?.map((partner) => ({
          price: partner?.price || 0,
          partner_id: partner?.list.id,
          result_id: partner.result_time
            ? convertDeadlines(partner?.result_time).id
            : 0,
          term: partner.result_time
            ? convertDeadlines(partner?.result_time).time
            : 0,
        }))
      : [
          {
            price: 0,
            partner_id: 1,
            result_id: undefined,
            term: undefined,
          },
        ],

    status_id: product?.status?.id ?? 1,
    channels: product?.channels.length
      ? product?.channels.map((channel) => channel.id)
      : [],
    genders: product?.genders.length
      ? product?.genders.map((gender) => gender.id)
      : [],
    sample_id: product?.sample?.id ?? undefined,
    kits: validateArrayType(product?.kits) ? product?.kits : [],
    dose_amount: product?.dose_amount ?? 1,
    dose_interval: product?.dose_interval ? product.dose_interval : undefined,
    application_id: product?.application?.id ?? undefined,
    methodology_id: product?.methodology?.id ?? undefined,
    product_description: {
      synonym: product?.product_description?.synonym ?? "",
      partner_description:
        product?.product_description?.partner_description ?? "",
      partner_title: product?.product_description?.partner_title ?? "",

      description: product?.product_description?.description ?? "",
      why_do: product?.product_description?.why_do ?? "",
      preparation_method:
        product?.product_description?.preparation_method ?? "",
      how_to: product?.product_description?.how_to ?? "",
      results: product?.product_description?.results ?? "",
      reactions: product?.product_description?.reactions ?? "",
      composition: product?.product_description?.composition ?? "",
      orientation: product?.product_description?.orientation ?? "",
      restriction: product?.product_description?.restriction ?? "",
      presentation: product?.product_description?.presentation ?? "",
      protection: product?.product_description?.protection ?? "",
      indicated: product?.product_description?.indicated ?? "",
      not_indicated: product?.product_description?.not_indicated ?? "",
      dosage: product?.product_description?.dosage ?? "",
      application: product?.product_description?.application ?? "",
      availability_sus: product?.product_description?.availability_sus ?? "",
      public_differentials:
        product?.product_description?.public_differentials ?? [],
      private_differentials:
        product?.product_description?.private_differentials ?? [],
      sell_note: product?.product_description?.sell_note ?? "",
    },
    rule: {
      attenuated_virus: product?.rule?.attenuated_virus ?? false,
      commercial_code: product?.rule?.commercial_code || "",

      free_visit: product?.rule?.free_visit ?? false,
      isolated_sale: product?.rule?.isolated_sale ?? false,
      max_items_cart: product?.rule?.max_items_cart ?? undefined,
      only_central: product?.rule?.only_central ?? false,
      need_pre_scheduling: product?.rule?.need_pre_scheduling ?? false,
      save_budget: product?.rule?.save_budget ?? true,
      prescription: product?.rule?.prescription ?? false,
      need_height: product?.rule?.need_height ?? true,
      need_diseases: product?.rule?.need_diseases ?? false,
      need_medicament: product?.rule?.need_medicament ?? false,
      need_last_menstruation: product?.rule?.need_last_menstruation ?? false,
      need_mother_name: product?.rule?.need_mother_name ?? false,
      need_symptoms: product?.rule?.need_symptoms ?? false,
      need_weight: product?.rule?.need_weight ?? true,
    },
    services: product?.services.length
      ? product?.services.map((string) => string.id)
      : [],
    units: {
      include: product?.units?.include.map((idUnit) => idUnit) ?? [],
      exclude: product?.units?.exclude.map((idUnit) => idUnit) ?? [],
    },
    services_periods: [
      {
        day_id: 1,
        label: "Segunda-Feira",
        start_id: product?.services_periods?.[0]?.start_id ?? undefined,
        end_id: product?.services_periods?.[0]?.end_id ?? undefined,
      },
      {
        day_id: 2,
        label: "Terça-Feira",
        start_id: product?.services_periods?.[1]?.start_id ?? undefined,
        end_id: product?.services_periods?.[1]?.end_id ?? undefined,
      },
      {
        day_id: 3,
        label: "Quarta-Feira",
        start_id: product?.services_periods?.[2]?.start_id ?? undefined,
        end_id: product?.services_periods?.[2]?.end_id ?? undefined,
      },
      {
        day_id: 4,
        label: "Quinta-Feira",
        start_id: product?.services_periods?.[3]?.start_id ?? undefined,
        end_id: product?.services_periods?.[3]?.end_id ?? undefined,
      },
      {
        day_id: 5,
        label: "Sexta-Feira",
        start_id: product?.services_periods?.[4]?.start_id ?? undefined,
        end_id: product?.services_periods?.[4]?.end_id ?? undefined,
      },
      {
        day_id: 6,
        label: "Sábado",
        start_id: product?.services_periods?.[5]?.start_id ?? undefined,
        end_id: product?.services_periods?.[5]?.end_id ?? undefined,
      },
    ],
    diets: validateArrayType(product?.diets)
      ? product?.diets.map((diet) => diet.id)
      : [],
    product_pre_collection: (new_product_pre_collection || []).map(
      (item: any) => {
        return Object.fromEntries(
          Object.entries(item).filter(([key, value]) => value !== null)
        );
      }
    ),

    stop_medication_id: product?.stop_medication?.id || null,
    stop_medication_obs: product?.stop_medication_obs ?? "",
    diet_avoid_preservatives_obs: product?.diet_avoid_preservatives_obs ?? "",
    diet_avoid_foods_obs: product?.diet_avoid_foods_obs ?? "",
  };
};

export const getInitialUnitsValue = (
  units: UnitsSelected,
  regions: RegionUnits[] | undefined
) => {
  const include: Set<number> = new Set(units?.include ?? []);
  const exclude: Set<number> = new Set(units?.exclude ?? []);

  if (regions) {
    regions.forEach((region) => {
      if (exclude.has(region.id)) {
        region?.units?.forEach((un) => {
          if (include.has(un.id)) {
            include.add(un.id);
          }
        });

        return;
      }

      if (
        (include.has(0) && !exclude.has(region.id)) ||
        include.has(region.id)
      ) {
        include.add(region.id);
      }

      region?.units?.forEach((un) => {
        if (!exclude.has(un.id) && include.has(region.id)) {
          include.add(un.id);
        }
      });
    });
  }

  return {
    include: Array.from(include),
    exclude: Array.from(exclude),
  };
};

export const removeUnitsFromItsRegion = (
  units: UnitsForm,
  regions: RegionUnits[]
) => {
  const include = new Set(units.include);
  const exclude = new Set(units.exclude);

  regions.forEach((region) => {
    const isRegionIncluded = include.has(region.id);
    const isRegionExcluded = exclude.has(region.id);

    if (region.units) {
      region?.units?.forEach((un) => {
        if (isRegionIncluded) {
          include.delete(un.id);
        }

        if (isRegionExcluded) {
          exclude.delete(un.id);
        }
      });
    }
  });

  return { include: Array.from(include), exclude: Array.from(exclude) };
};

const removeRegionNotExcluded = (units: UnitsForm, regions: RegionUnits[]) => {
  const unitsremovedFromItsRegions = removeUnitsFromItsRegion(units, regions);

  const { exclude, include } = unitsremovedFromItsRegions;

  const excluded = new Set(exclude);
  const included = new Set(include);

  if (included.has(0)) {
    regions.forEach((region) => {
      if (excluded.has(region.id)) return;

      included.delete(region.id);
    });
  }

  return {
    included: Array.from(included),
    excluded: Array.from(excluded),
  };
};

export const formatUnitsRequest = (
  units: UnitsForm,
  regions: RegionUnits[] | undefined
) => {
  if (units && regions) {
    const removedIncludedRegions = removeRegionNotExcluded(units, regions);

    const { excluded, included } = removedIncludedRegions;

    return {
      include: included,
      exclude: excluded,
    };
  }
};
