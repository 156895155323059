import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal"
import { Box } from "styles/globalComponents";
import Warning from "Assets/Images/atention.svg";


interface BlockedScheduleModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const BlockedScheduleModal = ({
  isOpen,
  closeModal,
}: BlockedScheduleModalProps) => {


  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => closeModal()}
      modalIcon={Warning}
      // closeIcon={false}
      renderHeading={() => (
        <h2 style={{ textAlign: 'center' }}>
          {" "}
          Reagendamento bloqueado
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          <strong>Atenção:</strong> Não é possível realizar o reagendamento pois está muito próximo da data do atendimento. Para prosseguir com essa solicitação, entre em contato diretamente com a equipe de operação domiciliar.        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column" >
          <Button
            onClick={() => closeModal()}
            width="full"
            minHeight="3.7em"
            fontWeight="600"
          >
            Voltar para Pedido
          </Button>
        </Box >
      )}
    />
  )

}