import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Element } from "react-scroll";

import { Header, PaginationWrapper } from "Pages/HomeOrders/styles";
import { ListTable } from "Pages/RegistrationCleaning/components/ListTable";
import { ModalHandler } from "Pages/RegistrationCleaning/components/ModalHandler";
import {
  ComponentPaginationProps,
  DataPersonPagination,
  DataPerson,
  RouteParamsInterface,
  ModalInterface,
} from "Pages/RegistrationCleaning/interface";
import { TitleDocument } from "Pages/RegistrationCleaning/styles";


import { duplicateRegistration } from "Requests/Person";

import {
  selectPersonDuplicate,
  resetPersonDuplicatedFlow,
} from "Actions/DuplicatePerson";

import { Store } from "Reducers/interface";

import { MaskCPF } from "Utils/Masks";
import { createToast } from "Utils/toastFunc";

import Pagination from "Components/Nav/Pagination";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

export const MainSelection: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { PersonDuplicate, PersonDuplicatedFlow } = useSelector(
    (store: Store) => store
  );
  const [modalResource, setModalResource] = useState<ModalInterface>({
    isVisible: false,
    handleDismiss: () => {},
    title: "",
    modal: "default",
    handleModal: () => {},
    messageButton: "",
    messageButtonCancelModal: "",
    icon: "",
  });

  const [data, setData] = useState<DataPerson[]>();
  const [pagination, setPagination] = useState<DataPersonPagination>();
  const [loading, setLoading] = useState(false);
  const [personsSelect, setPersonsSelect] = useState<any>([]);
  const { document } = useParams<RouteParamsInterface>();
  async function handlePagination(e: ComponentPaginationProps) {
    if (pagination?.current_page === e.currentPage) return;
    await fetchData({ page: e.currentPage });
  }

  const handleSubmit = async () => {
    if (personsSelect.length === 1) {
      return handleWithoutUnifying();
    }

    return handleUnifyRegistration();
  };

  const handleWithoutUnifying = () => {
    setModalResource({
      ...modalResource,
      isVisible: true,
      modal: "continue_without_unifying",
      messageButton: "seguir sem unificar",
      messageButtonCancelModal: "voltar",
      handleModal: () => handleUnify(undefined, personsSelect[0], false),
      handleDismiss: () =>
        setModalResource({ ...modalResource, isVisible: false }),
    });
  };

  const handleUnifyRegistration = () => {
    setModalResource({
      ...modalResource,
      isVisible: true,
      title:
        personsSelect.length === 1
          ? "Pessoas Duplicadas"
          : "Tem certeza que deseja unificar estas pessoas?",
      modal: "unify_registration",
      handleModal: handleUnify,
      personsSelect,
      handleDismiss: () =>
        setModalResource({ ...modalResource, isVisible: false }),
    });
  };

  function handleCheckBoxSelected(person: DataPerson, value: boolean) {
    if (!value)
      return setPersonsSelect((prev: DataPerson[]) =>
        prev.filter((item: DataPerson) => item.id !== person.id)
      );
    setPersonsSelect((prev: DataPerson[]) => [...prev, person]);
  }

  async function fetchData({
    search = null,
    page = 1,
    id = null,
    exclude = [],
  }: any) {
    try {
      setLoading(true);

      const response = await duplicateRegistration(document, page, {
        search,
        id,
        exclude,
      });
      const {data} = response;
      return data;
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao solicitar as pessoas duplicadas!"
      );
    } finally {
      setLoading(false);
    }
  }

  const handleUnify = (
    modalPersonsSelected: DataPerson[] | undefined,
    main: DataPerson | undefined,
    popSuccess = false
  ) => {
    dispatch(
      selectPersonDuplicate({
        ...PersonDuplicate,
        main: main?.id ?? null,
        merge: modalPersonsSelected?.map((item: DataPerson) => item.id) ?? [],
        edit: {
          ...main,
        },
      })
    );
    if (popSuccess) {
      return setModalResource({
        ...modalResource,
        modal: "success_modal",
        isVisible: true,
        handleDismiss: () =>
          history.push(`/limpeza-cadastro/${document}/dependent`),
      });
    }
    history.push(`/limpeza-cadastro/${document}/dependent`);
  };

  useEffect(() => {
    dispatch(selectPersonDuplicate(undefined)); // reset

    const { origin } = PersonDuplicatedFlow;

    if (origin === "duplicated-list") {
      setModalResource({
        ...modalResource,
        isVisible: true,
        modal: "duplicated-list-modal",
        messageButton: "ajustar cadastro",
        messageButtonCancelModal: "cancelar",
        handleModal: () =>
          setModalResource({ ...modalResource, isVisible: false }),
        handleDismiss: () => {
          dispatch(resetPersonDuplicatedFlow());
          history.push("/pessoas-duplicadas");
          setModalResource({ ...modalResource, isVisible: false });
        },
      });
    } else if (origin === "send-motion") {
      setModalResource({
        ...modalResource,
        isVisible: true,
        modal: "send-motion-modal",
        messageButton: "ajustar cadastro",
        messageButtonCancelModal: "cancelar",
        handleModal: async () => {
          const { data }: any = await fetchData({
            id: PersonDuplicatedFlow.id,
          });

          setPersonsSelect(data);
          setModalResource({ ...modalResource, isVisible: false });
          initialize({ exclude: [PersonDuplicatedFlow.id] });
          return;
        },
        handleDismiss: () => {
          dispatch(resetPersonDuplicatedFlow());
          history.push("/pedidos");
          setModalResource({ ...modalResource, isVisible: false });
        },
      });
    }

    const initialize = async ({ exclude = [] }: any) => {
      const { data, current_page, last_page, total }: any = await fetchData({
        page: 1,
        exclude,
      });

      setData(data);

      setPagination({
        current_page,
        total_pages: last_page,
        total,
      });
    };

    initialize({});
  }, []);

  return (
    <PageWrapper className="entry-app-content order-home-list">
      <ModalHandler {...modalResource} />
      <div className="content-holder">
        <Header>
          <h1>Limpeza de Cadastro</h1>
          <Breadcrumb
            list={[
              {
                path: "/pessoas",
                label: "PESSOAS",
                current: false,
              },
              {
                path: "#",
                label: "LIMPEZA DE CADASTRO",
                current: true,
              },
            ]}
          />
        </Header>

        <div className="order-home-filter">
          <div className="page-content page-content-list" id="ScrollDomiciliar">
            <TitleDocument>
              {`Quem é o titular do CPF ${document && MaskCPF(document)}?`}
            </TitleDocument>

            <Element name="ScrollableDomiciliarOrders" />
            <ListTable
              data={data}
              loading={loading}
              personsSelect={personsSelect}
              handleCheckBoxSelected={handleCheckBoxSelected}
              modalResource={modalResource}
              setModalResource={setModalResource}
            />
          </div>
        </div>
      </div>

      <PaginationWrapper>
        <>
          {pagination?.total_pages && (
            <>
              <Pagination
                totalRecords={pagination.total_pages}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={(e: ComponentPaginationProps) =>
                  handlePagination(e)
                }
              />
            </>
          )}
          <button
            className="button"
            onClick={() => handleSubmit()}
            disabled={personsSelect.length === 0}
          >
            atribuir como cadastro correto
          </button>
        </>
      </PaginationWrapper>
    </PageWrapper>
  );
};
