import API from "Services/Api";

import { toOptions } from "Utils/ToOptions";

export async function getOrdersHome(params) {
  try {
    const res = await API.get(`/orders/domicile`, { params });

    return res;
  } catch (error) {
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}

// Gabi

export async function getOrdersHomeTelemedicine(params) {
  try {
    const res = await API.get(`/orders/telemedicine`, { params });

    return res;
  } catch (error) {
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}

export async function getUnitsUser() {
  try {
    const { data } = await API.get(`/order/unit`);
    return data;
  } catch (error) {
    console.log(error);
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}

export async function postMotionOrderHome(obj) {
  try {
    const res = await API.post(`/order/unit`, obj);
    return res;
  } catch (error) {
    console.log(error);
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}

export async function managerExportHomeOrder(obj) {
  try {
    const res = await API.post("orders/manager/export/home_collected", obj, {
      // headers: headers,
      responseType: obj.orders.length <= 10 ? "blob" : "json", // important
    });
    return res;
  } catch (error) {
    console.log(error);
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}

export async function integrationMotionOrder(obj) {
  try {
    const res = await API.post("orders/domicile/integration/motion", obj);

    return res;
  } catch (error) {
    throw new Error("Não foi possível integrar a unidade");
  }
}

export async function getStatusPayment() {
  try {
    const res = await API.get("order/status");

    return res;
  } catch (error) {
    console.log(error);
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}
export async function getCalendars() {
  try {
    const { data } = await API.get("/appointments/calendars");
    const calendarsOptions = toOptions(data, "id", "name");

    return calendarsOptions;
  } catch (error) {
    console.log(error);
    return {
      error: error.response,
      data: error.response.data,
    };
  }
}
