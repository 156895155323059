import { OrderType } from "Stores/budget-order"
import { Box } from "styles/globalComponents";
import FormatCurrency, { formatBRLCurrency } from "Utils/FormatCurrency";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { CartDetailValue } from "../../CartDetailValue";


export const CartOrderTotal = ({ order }: { order: OrderType }) => {

  const homeHollectValue = order.homeCollectValue && order.homeCollectValue
    ? `R$ ${FormatCurrency(order?.homeCollectValue)}` : "Gratuito";

  return (
    <Box className="total-cart-holder-new-layout" mt="15">

      {!order.isCovenant ? <>
        {!!order?.discounts && (
          <CartDetailValue
            label="Descontos"
            value={formatBRLCurrency(order?.discounts, true)}
          />
        )}
      </> : <></>}


      <>
        {!!order?.subtotal && (
          <CartDetailValue
            label="Subtotal"
            value={formatBRLCurrency(order.subtotal, true)}
          />
        )}

        {(order?.homeCollectValue != 0 && order?.homeCollectValue != `0,00`) && (
          <CartDetailValue
            label="Atendimento Domiciliar"
            value={homeHollectValue}
          />
        )}

        <CartDetailValue
          label="Total"
          value={formatBRLCurrency(replaceStringNumber(order.totalPrice), true)}
        />
      </>
    </Box>
  )

}