import React from "react";

import { TextEllipsis } from "../../../Utils/Uploads";
import { Container } from "./styles";

interface TypedSelectProps {
  error?: boolean;
  small?: boolean;
  label?: string;
  id?: string;
  value?: any;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  required?: boolean;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLSelectElement>;
  placeholder?: string;
  readOnly?: string;
  fullSize?: boolean;
  options: {
    key: string;
    value: string;
  }[];
}

export function TypedSelect({
  error,
  small,
  label,
  id,
  value,
  onChange,
  required,
  disabled,
  onFocus,
  placeholder,
  options,
  readOnly,
  fullSize,
}: TypedSelectProps) {
  return (
    <Container error={error} small={small} fullSize={fullSize || false}>
      {label && <label htmlFor={id ?? ""} />}

      <div className="select-field-holder">
        <select
          name={id ?? ""}
          id={id ?? ""}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          onFocus={onFocus}
          className="input select"
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option.key} value={option.key}>
              {TextEllipsis(option.value, 30)}
            </option>
          ))}
        </select>
        {!readOnly && <span className="arrow-icon">{">"}</span>}
      </div>
      {!!error && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </Container>
  );
}
