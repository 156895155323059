import API from "Services/Api";

type DocumentValidationType = {
  document: string;
  type: "cpf" | "passport";
  id?: number | null;
  ignore_duplicated?: boolean;
};

export async function documentValidation({
  document,
  type,
  id = null,
  ignore_duplicated = false,
}: DocumentValidationType) {
  try {
    const response = await API.post("/validation/person/document", {
      document,
      type,
      id,
      ignore_duplicated,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const uniqueDocument = async ({
  document,
  type,
  id = null,
  ignore_duplicated = false,
}: DocumentValidationType) => {
  try {
    await documentValidation({ document, type, id, ignore_duplicated });
    return true;
  } catch (error) {
    return false;
  }
};
