import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { GetBudgetByID } from "Requests/Budget";
import { GetInstallments } from "Requests/Installments";
import {
  GetPaymentTypes,
  CreateOrder,
  getOptionsBrandAndOperator,
} from "Requests/Order";

import Input from "Components/Form/Input";
import InputCurrency from "Components/Form/InputCurrency";
import Select from "Components/Form/Select";
import PageSteper from "Components/Nav/PageSteper";
import PageWrapper from "Components/Pages/Wrapper";
import CartItem from "Components/ServiceForm/CartItem";

import LoadingImage from "Assets/Images/loading-purple.gif";

import CalculateCartWithDiscount from "../../Utils/CalculateCartWithDiscount";

const ServiceFormPayment = (props) => {
  const serviceFormState = useSelector((state) => state.service_form);
  const patientSelected = serviceFormState.pacient;
  const { budget_id } = useParams();
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [budget, setBudget] = useState({});
  const [loadingCart, setLoadingCart] = useState(true);
  const [stepActive, setStepActive] = useState(0);
  const [valueReceived, setValueReceived] = useState("");

  const [valueChange, setValueChange] = useState({
    value: 0,
    error: "",
  });

  const [cardData, setCardData] = useState({
    nsu_transaction: {
      value: "",
      error: "",
    },
    credit_card_banner: {
      value: "",
      error: "",
    },
    installment: {
      value: 1,
    },
  });

  const [card_banners, setCardBanners] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [submitOrder, setSubmitOrder] = useState(false);

  function handleChangeCardValue({ target: { value, id } }) {
    setCardData({
      ...cardData,
      [id]: {
        ...cardData[id],
        value,
      },
    });
  }

  function handleSelectPaymentMethod(step) {
    setStepActive(step);
  }

  function resetStepPayment() {
    setStepActive(0);

    setCardData({
      nsu_transaction: {
        value: "",
        error: "",
      },
      credit_card_banner: {
        value: "",
        error: "",
      },
      installment: {
        value: 1,
      },
    });
  }

  function handleValueReceived({ target: { value } }) {
    const budgetTotal =
      parseFloat(budget.total) - (parseFloat(budget.total) / 100) * 10;
    const changeValue = parseFloat(value) - budgetTotal;

    const errorChangeValue = "";
    let changeValueObject = {
      error: errorChangeValue,
      value: changeValue,
    };

    if (changeValue < 0) {
      changeValueObject = {
        error: "O valor recebido precisa ser maior que o total do orçamento",
        value: 0,
      };
    }

    setValueChange({
      ...changeValueObject,
    });
    setSubmitOrder(changeValue >= 0);
    setValueReceived(value);
  }

  async function fetchCart(budget_id) {
    const responseCart = await GetBudgetByID(budget_id);

    if (!responseCart.success) {
      createToast("error", "Orçamento não disponível no momento.");

      props.history.push("/ficha-de-atendimento/orcamentos");

      return;
    }

    setBudget(responseCart.info);
    setLoadingCart(false);
  }

  async function createOrder(e) {
    setLoadingCart(true);

    e.preventDefault();

    const postData = {
      budget_id: budget.id,
      payment_type: stepActive,
      installments_number: cardData.installment.value,
      nsu_number: cardData.nsu_transaction.value,
      credit_card_banner: cardData.credit_card_banner.value,
      card_operator: 5,
    };

    const responsePayment = await CreateOrder(postData);

    if (!responsePayment.success) {
      createToast(
        "error",
        "Oops! Tivemos um problema para processar o pagamento."
      );

      setLoadingCart(false);

      return;
    }

    const redirectState = {
      payment_url: stepActive === 4,
      orderCreated: true,
      orderId: responsePayment.data.id || responsePayment.data.id_source,
    };

    props.history.push(
      `/ficha-de-atendimento/resumo/${budget_id}`,
      redirectState
    );

    createToast("success", "Pagamento efetuado com sucesso!");

    setLoadingCart(false);
  }

  function showMessageCartIsAnotherPacient() {
    createToast(
      "error",
      "Oops! O carrinho que você está tentando acessar está indisponível"
    );
  }

  function showMessagePacientEmpty() {
    createToast("error", "Oops! Nenhum paciente foi selecionado.");
  }

  function createToast(style, message) {
    toast.dismiss();

    setTimeout(() => {
      toast[style](message);
    }, 400);
  }

  function applyDiscount() {
    if (stepActive === 3 && cardData.installment.value !== 1) return false;
    if (stepActive === 4 && cardData.installment.value !== 1) return false;
    return true;
  }

  async function fetchData() {
    async function fetchPaymentTypes() {
      const responsePayment = await GetPaymentTypes();

      setPaymentTypes(responsePayment);
    }

    async function fetchOptionsBrandAndOperator() {
      const response = await getOptionsBrandAndOperator();

      setCardBanners(response);
    }

    await Promise.all([fetchPaymentTypes(), fetchOptionsBrandAndOperator()]);
  }

  useEffect(() => {
    if (!patientSelected.id) {
      showMessagePacientEmpty();

      props.history.push("/ficha-de-atendimento/paciente");
    }

    if (budget_id && !patientSelected.id) {
      showMessageCartIsAnotherPacient();

      props.history.push("/ficha-de-atendimento/orcamentos");
    }

    if (budget_id > 0) {
      fetchCart(budget_id);
    } else {
      setLoadingCart(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    async function handleLoadInstallments() {
      const installmentsOptions = await GetInstallments(budget.total);

      setInstallments(installmentsOptions);
    }

    if (stepActive === 3 || stepActive === 4) {
      handleLoadInstallments();
    }
  }, [stepActive, budget]);

  return (
    <PageWrapper className="entry-app-content">
      <PageSteper patientSelected={patientSelected} />

      <section className="service-form-page service-form-cart">
        <div className="container">
          <div className="row">
            <div className="sm-12 md-7 lg-8 search-products-holder">
              <h5 className="title-service-form-page text-left text-light text-display text-hgray text-separate-text">
                {budget_id > 0
                  ? `Efetuar pagamento`
                  : "Orçamento não encontrado..."}
              </h5>

              <div className="payment-step-holder">
                {stepActive === 0 && (
                  <>
                    <div className="step-header">
                      <span className="step-title">
                        Escolha o método de pagamento:{" "}
                      </span>
                    </div>
                    <div className="payment-method-holder">
                      {paymentTypes.length > 0 &&
                        paymentTypes.map((payment) => (
                          <div
                            className="payment-method"
                            onClick={() =>
                              handleSelectPaymentMethod(payment.id)
                            }
                          >
                            <div className="box">
                              <i
                                className={
                                  parseInt(payment.id) === 1
                                    ? "icon-money"
                                    : "icon-credit-card"
                                }
                              />
                              {payment.type}
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}

                {stepActive === 1 && (
                  <>
                    <div className="step-header">
                      <span className="step-title">
                        Informe o valor recebido no pagamento:{" "}
                      </span>

                      <span
                        className="link link-purple"
                        onClick={resetStepPayment}
                      >
                        Voltar
                      </span>
                    </div>
                    <div className="payment-method-money">
                      <div className="row">
                        <div className="sm-4">
                          <InputCurrency
                            label="Total do orçamento"
                            id="budget_total"
                            value={
                              CalculateCartWithDiscount(10, budget.totalDisplay)
                                .calculate
                            }
                            readOnly
                            currencyPrefix="R$"
                            description="Valor total do orçamento"
                          />
                        </div>
                        <div className="sm-3">
                          <InputCurrency
                            label="Valor recebido"
                            id="valueReceived"
                            value={valueReceived}
                            onChange={handleValueReceived}
                            currencyPrefix="R$"
                            description="Informe o valor recebido para calcular o troco"
                          />
                        </div>
                        <div className="sm-5">
                          <InputCurrency
                            label="Troco"
                            id="valueChange"
                            value={valueChange.value}
                            error={valueChange.error}
                            currencyPrefix="R$"
                            readOnly
                            description="Valor de troco a ser devolvido ao paciente"
                          />
                        </div>
                      </div>
                    </div>

                    {submitOrder && (
                      <div className="text-right">
                        <button
                          onClick={createOrder}
                          className="button _large _purple"
                        >
                          Finalizar pedido
                        </button>
                      </div>
                    )}
                  </>
                )}

                {stepActive === 2 && (
                  <>
                    <div className="step-header">
                      <span className="step-title">
                        Informe o NSU da transação:{" "}
                      </span>

                      <span
                        className="link link-purple"
                        onClick={resetStepPayment}
                      >
                        Voltar
                      </span>
                    </div>
                    <div className="payment-method-card">
                      <div className="row">
                        <div className="sm-8 text-separate-text">
                          <Input
                            label="NSU da transação"
                            id="nsu_transaction"
                            type="text"
                            value={cardData.nsu_transaction.value}
                            error={cardData.nsu_transaction.error}
                            placeholder="Informe o NSU da transação"
                            onChange={handleChangeCardValue}
                            description="NSU do comprovante de compra (encontrado na primeira via da transação)"
                          />
                        </div>
                        <div className="sm-8 text-separate-text">
                          <Select
                            label="Bandeira do cartão"
                            id="credit_card_banner"
                            value={cardData.credit_card_banner.value}
                            error={cardData.credit_card_banner.error}
                            placeholder="-"
                            onChange={handleChangeCardValue}
                            options={card_banners}
                          />
                        </div>
                      </div>
                    </div>

                    {!!cardData.nsu_transaction.value &&
                      !!cardData.credit_card_banner.value && (
                        <div className="text-right">
                          <button
                            onClick={createOrder}
                            className="button _large _purple"
                          >
                            Finalizar pedido
                          </button>
                        </div>
                      )}
                  </>
                )}

                {stepActive === 3 && (
                  <>
                    <div className="step-header">
                      <span className="step-title">
                        Informe o NSU do cartão e selecione o parcelamento:{" "}
                      </span>

                      <span
                        className="link link-purple"
                        onClick={resetStepPayment}
                      >
                        Voltar
                      </span>
                    </div>
                    <div className="payment-method-card">
                      <div className="row">
                        <div className="sm-8 text-separate-text">
                          <Input
                            label="NSU da transação"
                            id="nsu_transaction"
                            type="text"
                            value={cardData.nsu_transaction.value}
                            error={cardData.nsu_transaction.error}
                            placeholder="Informe o NSU da transação"
                            onChange={handleChangeCardValue}
                            description="NSU do comprovante de compra (encontrado na primeira via da transação)"
                          />
                        </div>
                        <div className="sm-8 text-separate-text">
                          <Select
                            label="Bandeira do cartão"
                            id="credit_card_banner"
                            value={cardData.credit_card_banner.value}
                            error={cardData.credit_card_banner.error}
                            placeholder="-"
                            onChange={handleChangeCardValue}
                            options={card_banners}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="sm-8">
                          <span className="half-small-separate step-title text-purple text-display text-block">
                            Em quantas vezes?
                          </span>

                          <div className="installments-list">
                            {installments.map((item) => (
                              <div
                                className={`installment-item ${
                                  item.installments ===
                                  cardData.installment.value
                                    ? "selected"
                                    : ""
                                }`}
                                key={item.installments}
                                onClick={() => {
                                  setCardData({
                                    ...cardData,
                                    installment: {
                                      value: item.installments,
                                    },
                                  });
                                }}
                              >
                                <div className="info">
                                  <span className="text-gray text-bold">
                                    {item.installments}x
                                  </span>
                                  <span className="value text-gray">
                                    R$ {item.valuePerInstallmentDisplay}
                                  </span>
                                  <span className="text-purple text-semibold text-opacity-50">
                                    {item.percent_discount
                                      ? `${item.percent_discount}% de desconto`
                                      : "Sem desconto"}
                                  </span>
                                </div>

                                <span className="text-lgray text-opacity-75">
                                  R$ {item.totalValueDisplay}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    {cardData.installment.value > 0 &&
                      !!cardData.nsu_transaction.value &&
                      !!cardData.credit_card_banner.value && (
                        <div className="text-right">
                          <button
                            onClick={createOrder}
                            className="button _large _purple"
                          >
                            Finalizar pedido
                          </button>
                        </div>
                      )}
                  </>
                )}

                {stepActive === 4 && (
                  <>
                    <div className="step-header">
                      <span className="step-title">
                        Selecione o parcelamento:{" "}
                      </span>

                      <span
                        className="link link-purple"
                        onClick={resetStepPayment}
                      >
                        Voltar
                      </span>
                    </div>
                    <div className="payment-method-card">
                      <div className="row">
                        <div className="sm-8">
                          <div className="installments-list">
                            {installments.map((item) => (
                              <div
                                className={`installment-item ${
                                  item.installments ===
                                  cardData.installment.value
                                    ? "selected"
                                    : ""
                                }`}
                                key={item.installments}
                                onClick={() => {
                                  setCardData({
                                    ...cardData,
                                    installment: {
                                      value: item.installments,
                                    },
                                  });
                                }}
                              >
                                <div className="info">
                                  <span className="text-gray text-bold">
                                    {item.installments}x
                                  </span>
                                  <span className="value text-gray">
                                    R$ {item.valuePerInstallmentDisplay}
                                  </span>
                                  <span className="text-purple text-semibold text-opacity-50">
                                    {item.percent_discount
                                      ? `${item.percent_discount}% de desconto`
                                      : "Sem desconto"}
                                  </span>
                                </div>

                                <span className="text-lgray text-opacity-75">
                                  R$ {item.totalValueDisplay}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    {cardData.installment.value > 0 && (
                      <div className="text-right">
                        <button
                          onClick={createOrder}
                          className="button _large _purple"
                        >
                          Finalizar pedido
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="sm-12 md-5 lg-4 cart-side-holder">
              <div className="cart-side">
                {loadingCart ? (
                  <div className="loading loading-holder">
                    <img src={LoadingImage} alt="" />
                  </div>
                ) : budget.cart && budget.cart.length > 0 ? (
                  <>
                    <h5 className="title-cart text-left text-light text-display text-purple text-separate-text">
                      Resumo do orçamento ({budget.cart.length}{" "}
                      {budget.cart.length > 1 ? "itens" : "item"})
                    </h5>

                    <div className="cart-itens-holder">
                      {budget.cart.map((item) => (
                        <CartItem
                          key={item.id}
                          isProduct
                          product={item}
                          readOnly
                        />
                      ))}
                      {budget.home_collected && (
                        <CartItem
                          label="Coleta Domiciliar"
                          value={`R$ ${budget.homeCollectValueDisplay}`}
                        />
                      )}
                    </div>
                    <div className="total-cart-holder">
                      <CartItem
                        label="Subtotal"
                        value={`R$ ${budget.subtotalDisplay}`}
                      />
                      {budget.coupon_code && (
                        <CartItem label="Cupom" value={budget.coupon_code} />
                      )}
                      <CartItem
                        label="Descontos"
                        value={`- R$ ${budget.discountsDisplay}`}
                      />
                      {applyDiscount() && (
                        <CartItem
                          label="Desconto à vista"
                          value={`- R$ ${
                            CalculateCartWithDiscount(10, budget.totalDisplay)
                              .price
                          }`}
                        />
                      )}
                      <CartItem
                        label="Total"
                        value={`R$ ${
                          applyDiscount()
                            ? CalculateCartWithDiscount(10, budget.totalDisplay)
                                .calculate
                            : budget.totalDisplay
                        }`}
                      />
                    </div>
                  </>
                ) : (
                  <h5 className="title-cart text-left text-light text-display text-danger text-separate-text">
                    Este orçamento está vazio...
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default ServiceFormPayment;
