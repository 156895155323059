import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .covenant,
  .plan {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    font-family: var(--font-display);
  }

  .covenant-list {
    width: 100%;
    grid-column: 1/3;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .covenant-list-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`;
