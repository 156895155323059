import { SearchProductProps } from "./interface";
import { Container, InputWrapper, Overlay } from "./style";

export function OverlayInputText({
  searchValue,
  name,
  type = "search",
  placeholder,
  onChange,
  children,
  setOverlayOpen,
  isOverlayOpen,
}: SearchProductProps) {
  return (
    <Container display="flex" direction="column">
      {isOverlayOpen && <Overlay onClick={() => setOverlayOpen(false)} />}

      <InputWrapper
        className={`input-wrapper ${isOverlayOpen ? "open" : ""} ${
          searchValue ? "rounded-border" : ""
        }`}
      >
        <input
          id={name}
          name={name}
          type={type}
          autoComplete="off"
          placeholder={placeholder}
          value={searchValue}
          onChange={(event) => onChange(event.target.value)}
          onClick={() => setOverlayOpen(true)}
        />
        {isOverlayOpen && children}
      </InputWrapper>
    </Container>
  );
}
