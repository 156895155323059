import styled from "styled-components";

export const ContainerProductList = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    display: inline-table;

    tr,
    th,
    td {
      font-size: 13px;
      text-align: left;
    }

    thead {
      tr {
        & th:last-child {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #fff;
        }

        td {
          padding: 8px 5px;
        }
      }
    }
  }
`;
