import React from "react";

import { ContainerSymptom } from "./style";

export const CardSymptom = ({ message, active, onClick }) => {
  return (
    <ContainerSymptom active={active} onClick={onClick}>
      <p>{message}</p>
    </ContainerSymptom>
  );
};
