import API from "Services/Api";

import { createToast } from "Utils/toastFunc";

export const createUnits = async (objData) => {
  try {
    const { data, status } = await API.post(`/unit`, objData);
    return { data, status };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editUnit = async (objData, id) => {
  try {
    const { status } = await API.post(`/unit/${id}`, objData);
    return { status };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUnits = async () => {
  try {
    const res = await API.get(`/unit`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllUnits = async (type, user_units) => {
  try {
    const res = await API.get("/unit", {
      params: {
        user_units: !!user_units,
        ...(type && { type }),
        ...(!type && { paginate: false }),
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImgUnity = async (objImg, id) => {
  try {
    const res = await API.post(`/unit/upload/image/${id}`, objImg);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteImage = async (id) => {
  try {
    const res = await API.delete(`/unit/remove/image/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const showUnit = async (unit) => {
  try {
    const { data, statusText } = await API.get(`/unit/${unit}`);
    return { data, statusText };
  } catch (error) {
    console.log(error);
  }
};

export const getRegions = async () => {
  try {
    const { data } = await API.get(`/unit/region`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getRegion = async (id) => {
  try {
    const { data } = await API.get(`/unit/region/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteRegion = async (id) => {
  try {
    const res = await API.delete(`/unit/region/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createRegion = async (obj) => {
  try {
    const { data } = await API.post(`/unit/region`, obj, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateRegion = async (id, obj) => {
  try {
    const { data } = await API.post(`/unit/region/${id}`, obj, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getDivisions = async (id, obj) => {
  try {
    const { data } = await API.get(`/unit/division`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCity = async (city) => {
  try {
    const { data } = await API.get(`/cities/${city}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getStates = async () => {
  try {
    const { data } = await API.get("/unit/state");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUnit = async (id) => {
  try {
    const { data, status } = await API.delete(`/unit/${id}`);
    return { data, status };
  } catch (error) {
    const { message } = error.response.data;

    createToast("error", message);
  }
};

export const PageUnits = async (page, filters, sort) => {
  try {
    const request = filters
      ? `/unit?page=${page}&${filters}&${sort}`
      : `/unit?page=${page}&${sort}`;
    const res = await API.get(request);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createAlert = async (obj) => {
  try {
    const res = await API.post(`/unit/warning`, obj);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAlerts = async () => {
  try {
    const res = await API.get(`/unit/warning`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAlert = async (id) => {
  try {
    const res = await API.get(`/unit/warning/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAlert = async (id) => {
  try {
    const res = await API.delete(`/unit/warning/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editAlert = async (id, obj) => {
  try {
    const res = await API.put(`/unit/warning/${id}`, obj);
    return res;
  } catch (error) {
    console.log(error);
  }
};
