import "react-dates/initialize";
import React, { useState } from "react";
// eslint-disable-next-line import-helpers/order-imports
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import { AiOutlineCloseCircle } from "react-icons/ai";

import moment from "moment";
import ptBr from "moment/locale/pt-br";
import PropTypes from "prop-types";

moment.locale("pt-br", ptBr);

const InputDate = ({
  id,
  onDateChange,
  value,
  error,
  small,
  purple,
  label,
  labelInline,
  openInModal,
  blockPastChanges,
  withClear,
  placeholder,
}) => {
  const [focused, setFocused] = useState(false);

  const clearDate = () => {
    onDateChange(null);
  };

  return (
    <div
      className={`
            field-holder
            field-holder-date
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
            ${labelInline ? "field-holder-label-inline" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
        >
          {label}
        </label>
      )}
      <SingleDatePicker
        placeholder={placeholder}
        date={value}
        onDateChange={(date) => onDateChange(date)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
        displayFormat="DD/MM/YYYY"
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        withPortal={openInModal}
        isOutsideRange={
          blockPastChanges
            ? (day) =>
                isInclusivelyBeforeDay(day, moment().subtract("1", "days"))
            : () => false
        }
        showClearDate={withClear}
      />

      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
      {!!value && (
        <div className="clearDate" onClick={clearDate}>
          <AiOutlineCloseCircle color="gray" />
        </div>
      )}
    </div>
  );
};

InputDate.defaultProps = {
  id: "date", // eslint-disable-line
  placeholder: "Data",
  small: false,
  purple: false,
  label: "",
  openInModal: true,
  blockPastChanges: true,
  withClear: false,
  labelInline: false,
};

InputDate.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onDateChange: PropTypes.object.isRequired, // eslint-disable-line
  small: PropTypes.bool,
  purple: PropTypes.bool,
  label: PropTypes.string,
  labelInline: PropTypes.bool,
  openInModal: PropTypes.bool,
  blockPastChanges: PropTypes.bool,
  withClear: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default InputDate;
