import React from 'react';

import { PageWrapperProps } from './interface';

const PageWrapper = ({ loading, className, children }: PageWrapperProps) => {
    
    return (
        <main className={ `
            app-content ${ className || '' } ${ loading ? 'app-content-loading' : '' }
        ` }>
            { children }
        </main>
    );
};

export default PageWrapper;