import React from "react";

import {
  Field,
  useFormikContext,
  ErrorMessage as ErrorMessageFormik,
} from "formik";
import { SubListCheckbox } from "Pages/Product/Components/SubListCheckbox";
import { Box } from "styles/globalComponents";

import { useProductCombos } from "Hooks/useProductCombos";
import { useRegions } from "Hooks/useRegions";
import { useSelectUnits } from "Hooks/useSelectUnits";

import { hasPathInArray } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";
import { CheckboxList } from "Components/CheckboxList";
import { ErrorMessage } from "Components/ErrorMessage";
import SwitchInputControlled from "Components/Form/Controlled/SwitchInputControlled";
import { CustomCheckbox } from "Components/Form/CustomCheckbox";
import Select from "Components/Form/Select";
import { LoadingText } from "Components/LoadingText";

import { FormValuesProps, HandleChangeFields } from "../../interface";
import { AttendanceDays, CheckboxSublist, Container } from "./styles";

export const RestrictionsForm = ({
  handleCheckbox,
  setSelectField,
}: HandleChangeFields) => {
  const { values, errors, setFieldValue, touched, handleBlur } =
    useFormikContext<FormValuesProps>();
  const { combos } = useProductCombos({ includeCombo: ["*"] });
  const { regions, loadingRegions, regionsError } = useRegions();

  const { selectAllUnits, selectRegion, selectUnit } = useSelectUnits();

  const { category_id } = values;
  const categoryId = String(category_id.id);

  const heighestUnitblock =
    regions
      ?.map((unit) => unit?.units?.length)
      .filter(Boolean)
      .sort((a, b) => a - b)
      .at(-1) ?? 0;

  const handleSelectAllUnits = () => {
    const units = selectAllUnits(values.units);
    setFieldValue("units", units);
  };
  const handleSelectRegion = (regionId: number) => {
    const units = selectRegion(regionId, values.units);
    setFieldValue("units", units);
  };
  const handleSelectUnit = (unitId: number) => {
    const units = selectUnit(unitId, values.units);
    setFieldValue("units", units);
  };

  return (
    <AccordionCollapse title="Restrições">
      <Container>
        <CheckboxList
          error={touched.services && errors.services ? errors.services : ""}
          title="Tipo de Atendimento*"
        >
          <div className="checkbox-list">
            {combos?.services?.map((service) => (
              <Field
                component={CustomCheckbox}
                type="checkbox"
                name="services"
                value={service.id}
                label={service.attendance}
                key={service.id}
                checked={values.services.includes(service.id)}
                onChange={handleCheckbox}
              />
            ))}
          </div>
        </CheckboxList>

        {loadingRegions && !regionsError && (
          <LoadingText text="Carregando lista de unidades" />
        )}

        {loadingRegions && !regionsError && (
          <ErrorMessage text="Ocorreu um erro ao buscar a lista de unidades" />
        )}

        {!loadingRegions &&
          !regionsError &&
          hasPathInArray([1, 2, 3, 4, 5, 8], categoryId) && (
            <CheckboxList
              error={
                touched.units?.include && errors?.units?.include
                  ? errors.units.include
                  : ""
              }
              title="Unidades*"
            >
              <Box mt="15">
                <Field
                  component={CustomCheckbox}
                  type="checkbox"
                  label="Todas unidades"
                  name="units"
                  onChange={handleSelectAllUnits}
                  checked={values.units.include.includes(0)}
                />
                <CheckboxSublist
                  className="checkbox-units"
                  height={heighestUnitblock * 31}
                >
                  {regions?.map((region) => (
                    <SubListCheckbox
                      key={region.id}
                      region={region}
                      handleSelectAllRegionUnits={handleSelectRegion}
                      handleSelectUnits={handleSelectUnit}
                    />
                  ))}
                </CheckboxSublist>
              </Box>
            </CheckboxList>
          )}

        {hasPathInArray([1, 2, 3, 4, 5, 8], categoryId) && (
          <div
            className={`input-container column w-100 ${
              touched.services_periods && errors?.services_periods && "invalid"
            }`}
          >
            <span className="text-gray label">Dia de atendimento</span>
            <AttendanceDays>
              {values.services_periods?.map((service, index) => (
                <div className="attendance-days" key={service.label}>
                  <span className="label">{service.label}</span>
                  <div className="input-container">
                    <Field
                      name={`services_periods.${index}.start_id`}
                      id={`services_periods.${index}.start_id`}
                      options={combos?.resut_times}
                      optionName="hour"
                      label="Horário de início"
                      value={values.services_periods?.[index].start_id}
                      onChange={setSelectField}
                      onBlur={handleBlur}
                      component={Select}
                      placeholder="Selecione aqui"
                    />

                    <Field
                      name={`services_periods.${index}.end_id`}
                      id={`services_periods.${index}.end_id`}
                      options={combos?.resut_times}
                      value={values.services_periods?.[index].end_id}
                      onChange={setSelectField}
                      onBlur={handleBlur}
                      optionName="hour"
                      label="Horário de fim"
                      component={Select}
                      placeholder="Selecione aqui"
                    />
                  </div>
                </div>
              ))}
            </AttendanceDays>
            <ErrorMessageFormik
              name="services_periods"
              component="span"
              className="input-error"
            />
          </div>
        )}

        <div className="toggles-container ">
          {hasPathInArray([1, 2, 3, 8], categoryId) && (
            <Field
              name="rule.need_pre_scheduling"
              id="rule.need_pre_scheduling"
              value={values.rule.need_pre_scheduling}
              label="Agendamento prévio p/ unidade"
              component={SwitchInputControlled}
              textOn="Obrigatório"
              textOff="Não obrigatório"
            />
          )}

          {hasPathInArray([1, 2, 3, 4, 7, 8], categoryId) && (
            <Field
              name="rule.prescription"
              value={values.rule.prescription}
              id="rule.prescription"
              label="Necessita pedido médico"
              component={SwitchInputControlled}
              textOn="Sim"
              textOff="Não"
            />
          )}

          <Field
            name="rule.save_budget"
            value={values.rule.save_budget}
            id="rule.save_budget"
            label="Salvar orçamento"
            component={SwitchInputControlled}
            textOn="Sim"
            textOff="Não"
          />
        </div>
      </Container>
    </AccordionCollapse>
  );
};
