import { MENU_TOGGLE } from "Actions/ActionsTypes";

const defaultState = {
	opened: false
};

export default function Menu(state = defaultState, action) {
	switch (action.type) {
		case MENU_TOGGLE:
			return {
				opened: !state.opened
			};

		default:
			return state;
	}
}
