import { validateArrayType } from "Utils/Arrays";

export const alreadyExistsPlan = (plan: string, plansSelected: string[]) => {
  if (validateArrayType(plansSelected)) {
    return plansSelected.some((planSelected) =>
      planSelected.toLowerCase().includes(plan.toLowerCase())
    );
  }
};

export const convertToFormData = (values: any) => {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    if (key === "file") {
      /* @ts-ignore */
      return formData.append(`${key}`, value[0]);
    }

    if (key === "units") {
      Object.entries(values.units).forEach(([k, v]) => {
        values.units[k].forEach((unit: any, index: number) => {
          formData.append(`units[${k}][${index}]`, unit);
        });
      });

      return;
    }

    if (Array.isArray(values[key])) {
      values[key].forEach((value: any) => {
        formData.append(`${key}[]`, value);
      });
      return;
    }

    if (typeof values[key] === "object") {
      formData.append(key, JSON.stringify(values[key]));
      return;
    }

    formData.append(key, values[key]);
  });

  return formData;
};

export const formatInitialValuesonEdit = (covenantInfo: any) => {
  const files: any = [];
  covenantInfo.covenant_images.map((item: any) => {
    item.img[0].id = item.id;
    files.push(item.img[0]);
    return item;
  });

  return {
    id: covenantInfo?.id,
    access: covenantInfo?.access || "",
    covenant_channels: covenantInfo?.channels || [],
    contact: covenantInfo?.contact || "",
    covenant: covenantInfo?.covenant || "",
    covenant_checklist:
      covenantInfo?.covenant_checklists?.map(
        (checkItem: any) => checkItem.checklist_item
      ) || [],
    covenant_plans:
      covenantInfo?.covenant_plans.map(
        (plan: any) => plan.covenant_plans_name
      ) || [],
    covenant_plans_list: covenantInfo?.covenant_plans,
    description: covenantInfo?.description || "",
    external_link: covenantInfo?.external_link || "",
    units: covenantInfo?.units || { includes: [], exclude: [] },
    docs: files || [],
    file: covenantInfo?.image || null,
    free_visit: covenantInfo?.free_visit,
    home_collect_permission: covenantInfo?.home_collect_permission,
  };
};
