import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { withRouter, Link } from "react-router-dom";

import { getPersonByID } from "Requests/Person";

import {
  MaskCPF,
  MaskDate,
  MaskTelephone,
  MaskCelphone,
  MaskCEP,
} from "Utils/Masks";

import PageHeader from "Components/Pages/Header";
import PanelContent from "Components/Panels/PanelContent";

Modal.setAppElement("#root");

function DetailsPersonModal({
  modalVisible,
  modalDismiss,
  personID,
  setPersonID,
  loading,
}) {
  const [person, setPerson] = useState({});

  const [visibleMainTab, setVisibleMainTab] = useState({
    owner: {
      status: true,
      className: "active",
    },
    associated: {
      status: false,
      className: "",
    },
  });

  function closeModal() {
    modalDismiss();

    setTimeout(() => {
      setPerson({});
    }, 500);
  }

  function handleVisibleTab(e) {
    const _id = e.currentTarget.id;
    let _tabs = {};

    Object.keys(visibleMainTab).forEach((tabName) => {
      if (tabName !== _id) {
        _tabs = {
          ..._tabs,
          [tabName]: {
            ..._tabs[tabName],
            status: false,
            className: "",
          },
        };
      } else {
        _tabs = {
          ..._tabs,
          [tabName]: {
            ..._tabs[tabName],
            status: true,
            className: "active",
          },
        };
      }
    });

    setVisibleMainTab((prevState) => ({
      ...prevState,
      ..._tabs,
    }));
  }

  function resetVisibleTab() {
    setVisibleMainTab((prevState) => ({
      ...prevState,
      owner: {
        status: true,
        className: "active",
      },
      associated: {
        status: false,
        className: "",
      },
    }));
  }

  const infoPerson = [
    {
      title: "Nome",
      data: (person.data && person.data.first_name) || "Não informado",
    },
    {
      title: "Nome Social",
      data: (person.data && person.data.social_name) || "Não informado",
    },
    {
      title: "Data de Nascimento",
      data: (person.data && MaskDate(person.data.birthdate)) || "Não informado",
    },
    {
      title: "CPF",
      data:
        (person.data && person.data.cpf && MaskCPF(person.data.cpf)) ||
        "Não informado",
    },
    {
      title: "Sexo",
      data: (person.data && person.data.gender.gender) || "Não informado",
    },
    {
      title: "RG",
      data: (person.data && person.data.rg) || "Não informado",
    },
    {
      title: "Org. Emissor",
      data: (person.data && person.data.rg_issue_body) || "Não informado",
    },
    {
      title: "Altura",
      data:
        (person.data &&
          parseInt(person.data.height) > 0 &&
          `${person.data.height}cm`) ||
        "Não informado",
    },
    {
      title: "Peso",
      data:
        (person.data &&
          parseInt(person.data.weight) > 0 &&
          `${person.data.weight}kg`) ||
        "Não informado",
    },
    {
      title: "Passaporte",
      data: (person.data && person.data.passport) || "Não informado",
    },
    {
      title: "País Emissor",
      data:
        (person.data &&
          person.data.passport_issuing_country &&
          person.data.passport_issuing_country.country_name) ||
        "Não informado",
    },
    {
      title: "Nacionalidade",
      data:
        (person.data && person.data.nationality.nationality) || "Não informado",
    },
  ];

  function renderPersonAddress(addresses) {
    return (
      <PanelContent title="Informações de Endereço">
        {addresses ? (
          <>
            {addresses.map((address) => (
              <dl
                key={address.street + address.number}
                className="multiple-list"
              >
                <div className="list-item">
                  <dt className="item-title">Tipo:</dt>
                  <dd className="item-data">{address.address_type}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Rua:</dt>
                  <dd className="item-data">{address.street}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Nº:</dt>
                  <dd className="item-data">{address.number}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Complemento:</dt>
                  <dd className="item-data">{address.complement}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Bairro:</dt>
                  <dd className="item-data">{address.neighborhood}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Cidade:</dt>
                  <dd className="item-data">{address.city}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Estado:</dt>
                  <dd className="item-data">{address.uf}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">CEP:</dt>
                  <dd className="item-data">{MaskCEP(address.zip_code)}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">País:</dt>
                  <dd className="item-data">{address.country}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Ponto de Referencia:</dt>
                  <dd className="item-data">{address.reference_point}</dd>
                </div>
              </dl>
            ))}
          </>
        ) : (
          <p className="text-center text-bold text-danger">
            Dados não informados
          </p>
        )}
      </PanelContent>
    );
  }

  function renderPersonPhone(phones) {
    return (
      <PanelContent title="Informações de Telefone">
        {phones ? (
          <>
            {phones.map((phone) => (
              <dl key={phone.phone} className="multiple-list">
                <div className="list-item">
                  <dt className="item-title">Tipo:</dt>
                  <dd className="item-data">{phone.phone_type}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">DDI:</dt>
                  <dd className="item-data">{phone.ddi}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">DDD:</dt>
                  <dd className="item-data">{phone.ddd}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Número:</dt>
                  <dd className="item-data">
                    {phone.phone_type === "Celular"
                      ? MaskCelphone(phone.phone)
                      : MaskTelephone(phone.phone)}
                  </dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Ramal:</dt>
                  <dd className="item-data">{phone.branch}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Observação:</dt>
                  <dd className="item-data">{phone.obs}</dd>
                </div>
              </dl>
            ))}
          </>
        ) : (
          <p className="text-center text-bold text-danger">
            Dados não informados
          </p>
        )}
      </PanelContent>
    );
  }

  function renderPersonContact(contacts) {
    return (
      <PanelContent title="Informações de contato">
        {contacts ? (
          <>
            {contacts.map((contact) => (
              <dl key={contact.contact} className="multiple-list">
                <div className="list-item">
                  <dt className="item-title">Tipo:</dt>
                  <dd className="item-data">{contact.contacts_type}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Contato:</dt>
                  <dd className="item-data">{contact.contact}</dd>
                </div>
              </dl>
            ))}
          </>
        ) : (
          <p className="text-center text-bold text-danger">
            Dados não informados
          </p>
        )}
      </PanelContent>
    );
  }

  function renderPersonMedicine(medicines, other_medicines) {
    return (
      <PanelContent title="Medicamentos de uso contínuo">
        {medicines && other_medicines ? (
          <>
            {medicines.map((medicine, index) => (
              <dl key={medicine.drug_name} className="multiple-list">
                <div className="list-item">
                  <dt className="item-title">Medicamento:</dt>
                  <dd className="item-data">{medicine.drug_name}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Outros: </dt>
                  <dd className="item-data">{other_medicines[index].drug}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Dosagem diária:</dt>
                  <dd className="item-data">{medicine.daily_dosage}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">Data de início:</dt>
                  <dd className="item-data">{medicine.used_since}</dd>
                </div>
              </dl>
            ))}
          </>
        ) : (
          <p className="text-center text-bold text-danger">
            Dados não informados
          </p>
        )}
      </PanelContent>
    );
  }

  function renderPersonDisease(diseases) {
    return (
      <PanelContent title="Informações de doenças">
        {diseases ? (
          <>
            {diseases.map((disease) => (
              <dl key={disease.disease} className="multiple-list">
                <div className="list-item">
                  <dt className="item-title">Doença:</dt>
                  <dd className="item-data">{disease.disease}</dd>
                </div>
                <div className="list-item">
                  <dt className="item-title">CID:</dt>
                  <dd className="item-data">{disease.cid}</dd>
                </div>
              </dl>
            ))}
          </>
        ) : (
          <p className="text-center text-bold text-danger">
            Dados não informados
          </p>
        )}
      </PanelContent>
    );
  }

  useEffect(() => {
    if (personID !== null) {
      async function fetchPersonById(id) {
        const personObject = await getPersonByID(id);

        setPerson(personObject);
      }

      fetchPersonById(personID);
    }

    resetVisibleTab();
  }, [modalVisible, personID]);

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        bodyOpenClassName="modal-content-open"
        className="modal-content modal-fixed-header"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
      >
        {person.data && (
          <div className="modal-wrapper-header">
            <PageHeader
              title={`Visualizando ${
                person.data &&
                (person.data.social_name || person.data.first_name)
              }`}
            >
              <div className="actions-holder">
                <Link to={`/pessoa/${personID}`}>
                  <button type="submit" className="button _white _action">
                    Editar{" "}
                    {person.data &&
                      (person.data.social_name || person.data.first_name)}
                  </button>
                </Link>
              </div>
            </PageHeader>
          </div>
        )}
        {!person.data ? (
          <span className="loading-persons">
            <img src={loading} alt="" />
          </span>
        ) : (
          <div className="description-list">
            <div className="modal-header-navigation">
              <div className="main-tabs">
                <div
                  id="owner"
                  onClick={handleVisibleTab}
                  className={`${visibleMainTab.owner.className} ${
                    visibleMainTab.associated.status && "remove-margin-bottom"
                  } navigation-items left-tab`}
                >
                  Dados do paciente
                </div>

                {(person.data.person_parents.length > 0 ||
                  person.data.person_dependents.length > 0) && (
                  <div
                    id="associated"
                    onClick={handleVisibleTab}
                    className={`${visibleMainTab.associated.className} ${
                      visibleMainTab.associated.status && "remove-margin-bottom"
                    } navigation-items right-tab`}
                  >
                    {person.data.person_parents.length > 0
                      ? "Lista de responsáveis"
                      : "Lista de dependentes"}
                  </div>
                )}
              </div>

              {(person.data.person_parents.length > 0 ||
                person.data.person_dependents.length > 0) && (
                <div className="sub-tabs">
                  {visibleMainTab.associated.status &&
                  person.data.person_parents.length > 0
                    ? visibleMainTab.associated.status &&
                      person.data.person_parents.map((each) => {
                        const { id, first_name } = each;

                        return (
                          <div className="subtab-holder" key={id}>
                            <div
                              id={id}
                              className="tabs-items"
                              onClick={() => {
                                const obj = {
                                  data: person.data && each.responsible,
                                };

                                setPersonID(id);
                                setPerson(obj);
                                resetVisibleTab();
                              }}
                            >
                              {first_name}
                            </div>
                          </div>
                        );
                      })
                    : visibleMainTab.associated.status &&
                      person.data.person_dependents.map((each) => {
                        const { id, first_name } = each;

                        return (
                          <div className="subtab-holder" key={id}>
                            <div
                              id={id}
                              className="tabs-items"
                              onClick={() => {
                                const obj = {
                                  data: person.data && each.dependent,
                                };

                                setPersonID(id);
                                setPerson(obj);
                                resetVisibleTab();
                              }}
                            >
                              {first_name}
                            </div>
                          </div>
                        );
                      })}
                </div>
              )}
            </div>

            <PanelContent title="Informações Básicas">
              {infoPerson.map((eachInfo, key) => (
                <div className="list-item" key={eachInfo.title + key}>
                  <dt className="item-title">{eachInfo.title}:</dt>
                  <dd className="item-data">{eachInfo.data}</dd>
                </div>
              ))}
            </PanelContent>

            {person.data.person_address &&
              person.data.person_address.length > 0 &&
              renderPersonAddress(person.data.person_address)}
            {person.data.person_phones &&
              person.data.person_phones.length > 0 &&
              renderPersonPhone(person.data.person_phones)}
            {person.data.person_contacts &&
              person.data.person_contacts.length > 0 &&
              renderPersonContact(person.data.person_contacts)}
            {person.data.person_drugs &&
              person.data.person_drugs.length > 0 &&
              person.data.person_drugs_othersrender &&
              person.data.person_drugs_othersrender.length > 0 &&
              renderPersonMedicine(
                person.data.person_drugs,
                person.data.person_drugs_others
              )}
            {person.data.person_diseases &&
              person.data.person_diseases.length > 0 &&
              renderPersonDisease(person.data.person_diseases)}
          </div>
        )}
      </Modal>
    </div>
  );
}

export default withRouter(DetailsPersonModal);