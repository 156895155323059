import API from "Services/Api";

export const getProductsCategories = async () => {
  try {
    const categories = await API.get(`products/categories`);

    return categories.data;
  } catch (error) {
    throw new Error(
      "Ocorreu um erro na requisição para obter a listagem de categorias. Atualize a página e tente novamente"
    );
  }
};

export const getProductsSubCategories = async (categoryId: number) => {
  try {
    const subcategories = await API.get(
      `products/categories/${categoryId}/subcategories`
    );

    return subcategories.data;
  } catch (error) {
    throw new Error(
      "Ocorreu um erro na requisição para obter a listagem de subcategorias."
    );
  }
};
