import React from "react";

import { CartDetailValueProps } from "./interface";
import * as S from "./styles";

export const CartDetailValue = ({
  label,
  value,
  lineThrough,
}: CartDetailValueProps) => {
  return (
    <S.Container>
      <p className={`label ${lineThrough ? "lineThrough" : ""}`}>{label}</p>
      <p className={`price ${lineThrough ? "lineThrough" : ""}`}>{value}</p>
    </S.Container>
  );
};
