import React from "react";

import { ErrorProps } from "./interface";
import * as S from "./style";

export const ErrorMessage = ({ text }: ErrorProps) => {
  return (
    <S.ErrorContainer>
      <p>{text}</p>
    </S.ErrorContainer>
  );
};
