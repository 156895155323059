import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { Box } from "styles/globalComponents";
import { Button } from "Components/Button";
import { useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { useShallow } from "zustand/react/shallow";
import { useHistory } from "react-router";
import { AttendanceOrderItem } from "../AttendanceItem/item";
import { CartOrderTotal } from "../CartOrderTotal";


export const CartOrderDetails = () => {

  const { order, setOrder } = useBudgetOrderLocalStorage();

  const formattedDeliveryLabel = order.deliveryTime.split(".0");

  const history = useHistory();

  const [booleans, setBooleans] = useState({
    disabledNextButton: true
  })

  const [currentScreen, setCurrentScreen, enabledProceedCollect, enabledProceedIdentification, setCurrentComponent, isViewBudget] = useBudgetOrder(useShallow((state) => [
    state.currentScreen,
    state.setCurrentScreen,
    state.enabledProceedCollect,
    state.enabledProceedIdentification,
    state.setCurrentComponent,
    state.isViewBudget,
  ]));

  useEffect(() => {
    if (currentScreen === 'collect') {
      setBooleans({
        ...booleans,
        disabledNextButton: !enabledProceedCollect
      })
    }
  }, [enabledProceedCollect, currentScreen]);

  useEffect(() => {
    if (currentScreen === 'identification') {
      setBooleans({
        ...booleans,
        disabledNextButton: !enabledProceedIdentification
      })
    }
  }, [enabledProceedIdentification, currentScreen])

  const handleCancelOrder = () => {

    if (isViewBudget) {
      history.push(`/orcamento/${order.budget_id}`);
    } else {
      history.push(`/ficha-de-atendimento/person/selecionar-produtos/${order.budget_id}?btype=private`);
    }

    setOrder({
      budget_id: 0,
      appointment_type_id: "",
      payer_patient: {
        id: 0,
        birthdate: '',
        document: {
          id: 0,
          number: '',
          type: '',
          country_id: 0
        },
        email: '',
        gender_id: 0,
        mother_name: '',
        name: '',
        phone: {
          id: 0,
          ddd: 0,
          ddi: 0,
          phone: 0
        },
        unborn: false,
        social_name: ''
      },
      lead: {
        id: 0,
        ddd: null,
        phone: '',
        email: null,
        name: '',
      },
      attendances: [],
      itemsQuantity: 0,
      totalPrice: '0,00',
      budget_type: 1,
      home_collected: false,
      coupon_percentage_discount: 0,
      homeCollectValue: 0,
      isCovenant: false,
      discounts: 0,
      subtotal: 0,
      deliveryTime: '',
      items: [],
      product_categories: [],
      formattedAttendances: [],
      session_start: "",
      session_end: "",
      lastAddress: {
        street: "",
        number: "",
        uf: "",
        neighborhood: "",
        city: "",
        channel_id: 4,
        complement: '',
        country_id: 1,
        default: true,
        reference_point: '0',
        type_id: 1,
        zip_code: ''
      },
      attendanceLabel: [],
      dependents: [],
      patients: [],
      patientWithProducts: [],
      cupon_code: '',
      home_collect_value: '',
      isTaxInserted: false,
      orderData: {
        channel_id: 4,
        order_type: 1,
        commercial_category: 63,
        appointment_type_id: "",
        commercial_document_number: '',
        payer_id: 0,
        order: {
          budget_id: 0,
          subtotal: '',
          discounts: '',
          total: 0,
          cupon_code: null,
          home_collected: false,
          home_collect_value: '',
          home_collect_date: '',
          vaccine_cards: [],
          cart: [],
          home_collect_schedule: {
            id_home_collected: 0,
            home_collect_date: "",
            zip_code: "",
            country: "Brasil",
            uf: "",
            city: "",
            neighborhood: "",
            street: "",
            number: "",
            complement: "",
            reference_point: "",
            address_type_id: 1,
          }
        }
      }
    });
    setCurrentScreen('collect')
    setCurrentComponent('defaultCollect');
  }

  return (
    <div className="cart-information-resume">
      <div className="cart-details-container">
        <div className="cart-details-container-content">

          {/* Cabeçalho */}
          <h4 style={{ color: '#572580' }}>Resumo do Pedido</h4>
          <p style={{
            minWidth: '28vw',
            width: '28vw',
            marginTop: '-1.5em', textAlign: 'justify'
          }}>
            Você está orçando <strong>{order.itemsQuantity} {order.itemsQuantity > 1 ? 'itens' : 'item'}</strong> divididos em <strong>{order.attendances.length} {order.attendances.length > 1 ? 'atendimentos' : 'atendimento'} </strong>
            no valor total de <strong>R$ {order.totalPrice}</strong>
          </p>

          {/* Atendimentos */}
          {order.attendances.map((i) => (
            <Fragment key={i.id}>
              <AttendanceOrderItem attendance={i} />
            </Fragment>
          ))}

          <CartOrderTotal order={order} />

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5em', marginBottom: '0.5em' }}>
            <div
              style={{
                minWidth: '1.7vw',
                minHeight: '1.7vw',
                width: '1.7vw',
                height: '1.7vw',
                backgroundColor: '#EBEBEB',
                borderRadius: '50%',
              }}
            />
            <p style={{ margin: 0, fontWeight: 500, marginLeft: '0.7em', fontSize: '1em' }}>
              O tempo máximo para o(s) resultado(s){" "}
              {formattedDeliveryLabel ? `${formattedDeliveryLabel[0]}${formattedDeliveryLabel[1] ? formattedDeliveryLabel[1] : ''}` : ""}
            </p>
          </div>

          <div className="actions-cart-budget" style={{ marginTop: '3em' }}>
            <Box display="flex" direction="column">
              <Button width="full" onClick={() => handleCancelOrder()} outlined>
                Cancelar Pedido
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}