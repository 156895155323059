import Axios from "axios";
import { validateArrayType } from "Utils/Arrays";
import { getToken } from "Services/Authentication";
import { CreateScheduleType, FetchDatesHomeCollectType, FetchHoursHomeCollectType } from "./types";

export const fetchDatesHomeCollect = async ({
  person_id,
  polygon,
  sku,
  state
}: FetchDatesHomeCollectType) => {
  try {
    const API = Axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_WP}/wp-json/api`,
      headers: {
        "Content-Type": "application/json"
      },
    });


    const url = `/schedule/dates?state=${state}&sku=${encodeURIComponent(
      sku
    )}&reference_date=&user_id=${person_id}&polygon=${polygon}`;

    const response = await API.get(url);

    if (!response.data)
      throw "Ocorreu um erro inesperado ao buscar datas de agendamento disponíveis para esse produto.";

    if (
      response.data &&
      response.data.includes("Não há datas disponíveis no momento")
    )
      throw "Ops! Não foi possível encontrar datas disponíveis para agendamento do atendimento domiciliar!";

    if (!validateArrayType(response.data))
      throw "Ops! Não foi possível encontrar datas disponíveis para agendamento do atendimento domiciliar!";


    return response.data
  } catch (err) {
    throw err;
  }

}

export const fetchHoursHomeCollect = async ({
  state,
  date,
  polygon,
  sku
}: FetchHoursHomeCollectType) => {
  try {
    const API = Axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_WP}/wp-json/api`,
      headers: {
        "Content-Type": "application/json"
      },
    });

    const url = `/schedule/time?state=${state}&sku=${encodeURIComponent(
      sku
    )}&date=${date}&polygon=${polygon}`;

    const response = await API.get(url);

    if (!response.data)
      throw "Ocorreu um erro inesperado ao buscar horários disponíveis para essa data.";

    if (response.data.includes("Sem Horário") || !response.data.length)
      throw "Ops! Não foi possível encontrar horários disponíveis para essa data.";

    return response.data;
  } catch (err) {
    throw "Ops! Ocorreu um erro inesperado ao buscar horários disponíveis para essa data.";
  }
}

export const createScheduleHomeCollect = async (schedule: CreateScheduleType) => {
  try {
    const ColabiApi = Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    const origin = process.env.REACT_APP_URL_COLABI

    const url = '/appointments/store';
    const headers = {
      origin,
      referer: origin,
      Authorization: `Bearer ${getToken()}`
    }
    const response = await ColabiApi.post(url, {
      ...schedule,
    }, { headers });

    return response.data
  } catch (err) {
    throw "Ops! Ocorreu um erro inesperado ao realizar o agendamento.";
  }
}