import API from "Services/Api";

import { handleRequestErrors } from "Utils/Errors";

import { FormatPreRegister } from "./Formatters/Person";

export const SearchPersons = async (search) => {
  const response = await API.get(`/persons/lead?search=${search}`);

  const persons = response?.data?.persons?.map((person) => {
    return {
      ...person,
      isPerson: true,
    };
  });

  const leads = response?.data?.leads?.map((lead) => {
    return {
      ...lead,
      isPerson: false,
    };
  });

  return {
    status: response?.status,
    data: { persons, leads },
  };
};

export const PreRegisterPerson = async (data) => {
  const preRegisterDataFormatted = FormatPreRegister(data);

  const preRegisterResponse = await API.post(
    "persons/lead",
    preRegisterDataFormatted
  );

  return {
    success: true,
    data: preRegisterResponse.data,
  };
};

export const getPersonByID = async (id) => {
  try {
    const response = await API.get(`/persons/${id}`);

    return {
      ...response.data,
    };
  } catch (error) {
    handleRequestErrors({
      reqErrors: error,
      errorMessage:
        "Não foi possível obter os dados do paciente. Ocorreu um erro no servidor",
      throwError: true,
    });

    if (error?.response?.data?.errors) {
      const errorMessage = error?.response?.data?.errors[0]?.message;
      throw new Error(errorMessage);
    }

    throw new Error("Não foi possível obter os dados do paciente");
  }
};

export const getPersons = async () => {
  try {
    const response = await API.get("/persons");

    return response.data;
  } catch (error) {
    if (error.response.data) return error.response.data;

    throw error;
  }
};

export const createPerson = async (objData) => {
  const response = await API.post("/persons", objData);
  return response.data;
};

export const createCovenantCredentials = async (idPerson, objData) => {
  const response = await API.post(`/persons/${idPerson}/covenant`, objData);
  return response.data;
};

export const updateCovenantCredentials = async (
  idPerson,
  objData,
  credential_id
) => {
  const response = await API.put(
    `/persons/${idPerson}/covenant/${credential_id}`,
    objData
  );
  return response.data;
};

export const updatePerson = async (objData, idPerson) => {
  const response = await API.put(`/persons/${idPerson}`, objData);
  return response.data;
};

export const getPersonalDashboardData = async (person_id) => {
  try {
    const response = await API.get(`/orders/services/performed/${person_id}`);

    return response.data;
  } catch (error) {
    if (error.response.data) return error.response.data;
    throw error;
  }
};

export const getPersonalCreditsData = async (person_id) => {
  try {
    const response = await API.get(`/labicredit/movements/${person_id}`);

    return response.data;
  } catch (error) {
    if (error.response.data) return error.response.data;
    return error;
  }
};

export const duplicateRegistration = async (document, page = 1, params) => {
  try {
    const response = await API.get(`/persons/duplicated/${document}`, {
      params: {
        page,
        ...params,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const allDuplicatedList = async (page = 1, params) => {
  try {
    const response = await API.get(`persons/duplicated`, {
      params: {
        page,
        ...params,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const Combos = async ({ combos }) => {
  try {
    const response = await API.get(`persons/resources/combos`, {
      params: { combos },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePersonDuplicate = async (idPerson, objData) => {
  try {
    const response = await API.put(`/persons/duplicated/${idPerson}`, objData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePersonLead = async (idPerson, idLead) => {
  try {
    const response = await API.delete(`persons/${idPerson}/lead/${idLead}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const CreatePersonResponsible = async (data, responsible_id) => {
  try {
    const res = await API.post(`/persons/${responsible_id}/dependents`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdatePersonResponsible = async (
  objData,
  idPerson,
  responsible_id
) => {
  try {
    const response = await API.put(
      `/persons/${responsible_id}/dependents/${idPerson}`,
      objData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetPersonResponsible = async (idPerson) => {
  try {
    const response = await API.get(`persons/${idPerson}/responsibles`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetPersonDashboard = async (id) => {
  try {
    const response = await API.get(`persons/dashboard/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadCredentialInfo = async (person_id, credential_id) => {
  try {
    await API.post(`/persons/${person_id}/covenant/${credential_id}/upload`);
  } catch (error) {
    console.log(error);
  }
};
export const CreateCredencialPerson = async (data, idPerson) => {
  try {
    const res = await API.post(`/persons/${idPerson}/covenant`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdateCredencialPerson = async (data, idPerson, idCredencial) => {
  try {
    const res = await API.put(
      `/persons/${idPerson}/covenant/${idCredencial}`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DeleteCredencialPerson = async (idPerson, idCredencial) => {
  try {
    const res = await API.delete(
      `/persons/${idPerson}/covenant/${idCredencial}`
    );
    if (res?.status === 200) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const CreateImgCovenant = async (data, idPerson, idCredencial) => {
  try {
    const res = await API.post(
      `/persons/${idPerson}/covenant/${idCredencial}/upload`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const DeleteImgCovenant = async (idPerson, idCredencial, typeImg) => {
  try {
    const res = await API.delete(
      `/persons/${idPerson}/covenant/${idCredencial}/remove?image_to_remove=${typeImg}`
    );
    if (res?.status === 200) return true;
    return false;
  } catch (error) {
    return false;
  }
};
