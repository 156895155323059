import React from "react";

import { Field, Formik } from "formik";
import { Box } from "styles/globalComponents";

import { useOrdersCombos } from "Hooks/useOrdersCombo";

import { createObjParams } from "Utils/searchFilters";
import Slugify from "Utils/Slugify";

import { Button } from "Components/Button";
import SelectControlled from "Components/Form/Controlled/SelectControlled";
import Input from "Components/Form/Input";
import InputDateRange from "Components/Form/InputDateRange";
import Select from "Components/Form/Select";

import Data from "../../data.json";
import { HandleDateChange, SetField } from "./interface";
import { StyledForm } from "./styles";

export function Filter({ fetchData, setFilters, filters }: any) {
  const { initialValues, types, order_by } = Data;

  const { loadingOrdersCombos, ordersCombo, ordersCombosError } =
    useOrdersCombos();

  const { covenants, status, units, date_types, service_type } = ordersCombo ?? {};

  const handleSubmitSearch = (values: any) => {
    const filtersRequest = formatFilters(values);
    fetchData({ ...filtersRequest }, 1);
    setFilters(filtersRequest);
  };

  const formatFilters = (values: any) => {
  
    const covenantIds = covenants?.find(
      (name) => name.covenant === values.covenant_plan_id
    );

    let covenantPlanIds: number[] = [];
    if (covenantIds) {
      covenantPlanIds = covenantIds.covenant_plan_ids || [];
    }

    const filtersParam = {
      ...values,
      search: "",
      type: "",
      ...(values.type && {
        [values.type]:
          values.type === "email" ? values.search : Slugify(values.search),
      }),
      start: values.start?.format("DD/MM/YYYY") || "",
      end: values.end?.format("DD/MM/YYYY") || "",
      covenant_plan_id: covenantPlanIds,
    };
    return createObjParams(filtersParam);
  };

  const handleReset = (formik: any) => {
    formik.resetForm({ values: initialValues });
    fetchData(initialValues, 1);
    setFilters(initialValues);
  };

  function handleDatesChange(value: any, formik: any) {
    if (value.startDate) formik.setFieldValue("start", value.startDate);
    if (value.endDate) formik.setFieldValue("end", value.endDate);
  }

  const handleChangeAlphabeticOrder = (
    event: React.ChangeEvent<HTMLInputElement>,
    setField: SetField
  ) => {
    const selectedOrder = event.target.value;
    setField("order_by", selectedOrder);

    const newFilters = {
      ...filters,
      order_by: selectedOrder,
    };

    setFilters(newFilters);

    fetchData({ ...newFilters }, 1);
  };

  const handleDateType = ({
    event,
    handleChange,
    setField,
  }: HandleDateChange) => {
    const val = event.target.value;

    if (val === "") {
      setField("start", null);
      setField("end", null);
    }

    handleChange(event);
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize
      validateOnChange={false}
      onSubmit={handleSubmitSearch}
    >
      {(formik) => {
        const { handleChange, setFieldValue } = formik;

        return (
          <StyledForm className="mb-1">
            <div className="row">
              <div className="lg-2 mt-1">
                <Field
                  placegolder="Filtrar datas"
                  name="date_type"
                  id="date_type"
                  optionName="name"
                  value={formik.values.date_type}
                  options={date_types}
                  component={Select}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleDateType({
                      event: e,
                      setField: setFieldValue,
                      handleChange,
                    })
                  }
                />
              </div>
              <div className="lg-4 mt-1">
                <InputDateRange
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  startDateId="start"
                  endDateId="end"
                  disabled={formik.values.date_type === ""}
                  onDatesChange={(value: any) =>
                    handleDatesChange(value, formik)
                  }
                  openInModal={false}
                  dates={{
                    startDate: formik.values.start,
                    endDate: formik.values.end,
                  }}
                  blockPastChanges={false}
                  withClear={false}
                  error={undefined}
                  small={undefined}
                  purple={undefined}
                  label={undefined}
                />
              </div>
              <div className="lg-2 mt-1">
                <Field
                  placegolder="Status"
                  name="status_id"
                  loadin
                  options={status}
                  readonly={loadingOrdersCombos}
                  optionName="status"
                  component={SelectControlled}
                />
              </div>
              <div className="lg-2 mt-1">
                <Field
                  placegolder="Unidade"
                  name="unit_id"
                  readonly={loadingOrdersCombos}
                  options={units}
                  optionName="name"
                  component={SelectControlled}
                />
              </div>
              <div className="lg-2 mt-1">
                <Field
                  placegolder="Convênio"
                  name="covenant_plan_id"
                  optionName="covenant"
                  options={covenants}
                  readonly={loadingOrdersCombos}
                  component={SelectControlled}
                />
              </div>
              <div className="lg-2 mt-1">
                <Field
                  placegolder="Tipo de atendimento"
                  name="service_type"
                  optionName="service_type"
                  options={service_type}
                  readonly={loadingOrdersCombos}
                  component={SelectControlled}
                />
              </div>
              <div className="lg-2 mt-1">
                <Field
                  placegolder="Tipo de busca"
                  name="type"
                  id="type"
                  options={types}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("search", "");
                    handleChange(e);
                  }}
                  component={Select}
                />
              </div>
              <div className="lg-4 mt-1">
                <Field
                  placeholder="Digite aqui a informação"
                  name="search"
                  id="search"
                  type="text"
                  value={formik.values.search}
                  readOnly={formik.values.type === ""}
                  component={Input}
                  onChange={handleChange}
                />
              </div>
              <Box
                className="buttons-container"
                display="flex"
                mt="16"
                gap="10"
              >
                <Button
                  type="button"
                  onClick={() => handleReset(formik)}
                  outlined
                  borderRadius="111"
                >
                  Limpar
                </Button>
                <Button type="submit" borderRadius="111">
                  Buscar
                </Button>
              </Box>
              <Box mt="16" display="flex" ml="auto">
                <Field
                  component={Select}
                  onChange={(e: any) =>
                    handleChangeAlphabeticOrder(e, setFieldValue)
                  }
                  id="order_by"
                  value={formik.values.order_by}
                  options={order_by}
                  valueKey="id"
                  labelKey="name"
                  placeholder="ordenar por"
                />
              </Box>
            </div>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
