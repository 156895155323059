import Select from "react-select";
import { SelectCountriesProps } from "./types";

export const SelectCountries = ({
  options,
  id,
  name,
  error,
  value,
  handleChange,
  placeholder,
  disabled,
  label
}: SelectCountriesProps) => {

  const getValue = (value: number) => {
    return options.filter((o) => value === o.id);
  }

  return (
    <div className={`field-holder ${error ? "field-holder-invalid" : ""}`}>
      <label
        htmlFor={id}
        className='text-gray text-semibold _purple'
      >
        {label}
      </label>
      <Select
        menuPosition="fixed"
        classNamePrefix="react-select"
        className="react-select input _opacity-gray"
        id={id}
        value={getValue(value)}
        onChange={(selectedOption) => {
          const event: any = {
            target: {
              name,
              value: selectedOption ? selectedOption.id : 0,
            },
          };
          handleChange(event);
        }}
        options={options}
        placeholder={placeholder}
        isSearchable
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        getOptionValue={(option) => option.name}
        getOptionLabel={(option) => option.name}
        name={name}
        isDisabled={disabled}
      />
      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  )
}