import { login, logout } from "Services/Authentication";

import { handleSuccessLogin, handleLogout } from "./ActionsCreators";


export const handleLogin = data => {
	return dispatch => {
		login(data.token);

		dispatch(handleSuccessLogin(data));
	};
};

export const handleSuccessLogout = _ => {
	return async dispatch => {
		logout();

		dispatch(handleLogout());
	};
};
