import { useSelector } from "react-redux";

import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { Store } from "Reducers/interface";

import FormatCurrency, { formatBRLCurrency } from "Utils/FormatCurrency";

import { CartDetailValue } from "../CartDetailValue";

export const TotalCartDetails = () => {
  const { budget } = useBudgetContext();

  const { is_covenant, total } = budget ?? {};

  const { order } = useSelector((store: Store) => store.service_form);

  const { paymentDetails } = order;

  const totalOrderValue = budget?.total;

  const budgetValueBelow0 = total !== 0;

  const homeCollectFee =
    budget?.home_collected && budget?.home_collect_value
      ? `R$ ${FormatCurrency(budget?.home_collect_value)}`
      : "Gratuito";

  return (
    <Box className="total-cart-holder-new-layout" mt="15">
      {budget?.home_collected && !is_covenant && (
        <CartDetailValue label="Coleta Domiciliar" value={homeCollectFee} />
      )}

      {budget?.home_collected && is_covenant && (
        <CartDetailValue
          label="Atendimento Domiciliar"
          value={homeCollectFee}
        />
      )}

      {!is_covenant && (
        <>
          {!!budget?.discounts && (
            <CartDetailValue
              label="Descontos"
              value={formatBRLCurrency(budget?.discounts, true)}
            />
          )}
          {!!paymentDetails?.addition && (
            <CartDetailValue
              label="Taxa de parcelamento"
              value={formatBRLCurrency(paymentDetails?.addition, true)}
            />
          )}
        </>
      )}

      {budgetValueBelow0 && (
        <>
          {!!budget?.subtotal && (
            <CartDetailValue
              label="Subtotal"
              value={formatBRLCurrency(budget?.subtotal, true)}
              lineThrough={!!paymentDetails?.addition}
            />
          )}

          <CartDetailValue
            label="Total"
            value={formatBRLCurrency(totalOrderValue, true)}
          />
        </>
      )}
    </Box>
  );
};
