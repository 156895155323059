import styled from "styled-components";

export const Container = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  h4 {
    color: var(--color-purple);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
  }

  p {
    color: var(--color-gray-text);
    text-align: center;
    font-family: var(--font-text);
    font-size: 16px;
    font-weight: 400;
  }

  .buttons-container {
    display: flex;
    gap: 24px;

    button {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
