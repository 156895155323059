import React, { useEffect, useState } from "react";

import { Field } from "formik";

import { formikComboTypes } from "Utils/LocalTypes";

import InputControlled from "Components/Form/Controlled/Input";
import SelectControlled from "Components/Form/Controlled/SelectControlled";

export const ReviewForm = ({
  type_document,
  prefix,
  readOnly = false,
}: any) => {
  const [typeDocument, setTypeDocument] = useState<
    "cpf" | "passport" | undefined
  >(type_document);
  const [prefixState, setPrefixState] = useState<string>(prefix);
  useEffect(() => {
    setTypeDocument(type_document);
    setPrefixState(prefix);
  }, [type_document, prefix]);



  return (
    <>
      <div className="column">
        <h5>dados de identidade</h5>
        <Field
          label="nome completo"
          placeholder=""
          name={prefixState ? `${prefixState}[full_name]` : "full_name"}
          type="text"
          component={InputControlled}
          className="input _opacity-gray"
          readOnly={readOnly}
        />
        <Field
          label="nome social"
          placeholder=""
          name={prefixState ? `${prefixState}[social_name]` : "social_name"}
          type="text"
          component={InputControlled}
          className="input _opacity-gray"
          readOnly={readOnly}
        />
        <Field
          label="data de nascimento"
          placeholder=""
          name={prefixState ? `${prefixState}[birthdate]` : "birthdate"}
          type="date"
          component={InputControlled}
          className="input _opacity-gray"
          readOnly={readOnly}
        />

        <Field
          name={prefixState ? `${prefixState}[gender_id]` : "gender_id"}
          label="gênero"
          options={formikComboTypes.genders.data}
          component={SelectControlled}
          readOnly={readOnly}
        />

        <Field
          label="documento"
          name={prefixState ? `${prefixState}[type_document]` : "type_document"}
          placeholder="Selecione o tipo de documento"
          options={[
            { name: "CPF", id: "cpf" },
            { name: "Passaporte", id: "passport" },
          ]}
          component={SelectControlled}
          readOnly={readOnly || prefixState === "edit"}
        />
        {typeDocument === "cpf" ? (
          <Field
            label="cpf"
            placeholder="Insira o documento"
            mask="999.999.999-99"
            name={prefixState ? `${prefixState}[cpf]` : "cpf"}
            type="text"
            component={InputControlled}
            className="input _opacity-gray"
            readOnly={readOnly || prefixState === "edit"}
          />
        ) : (
          typeDocument === "passport" && (
            <>
              <Field
                label="passaporte"
                placeholder="Insira o documento"
                name={prefixState ? `${prefixState}[passport]` : "passport"}
                type="text"
                component={InputControlled}
                className="input _opacity-gray"
                readOnly={readOnly || prefixState === "edit"}
              />
              <Field
                label="País emissor"
                placeholder="No caso de passaporte, selecione o país"
                name={
                  prefixState
                    ? `${prefixState}[passport_issuing_country]`
                    : "passport_issuing_country"
                }
                optionName="name"
                options={formikComboTypes.countrys.data}
                component={SelectControlled}
                readOnly={readOnly || prefixState === "edit"}
              />
            </>
          )
        )}
      </div>
      <div className="column">
        <h5>Dados de contato</h5>
        <Field
          label="e-mail"
          placeholder=""
          name={prefixState ? `${prefixState}[email]` : "email"}
          type="mail"
          component={InputControlled}
          className="input _opacity-gray"
          readOnly={readOnly}
        />

        <Field
          label="telefone"
          placeholder=""
          name={prefixState ? `${prefixState}[phone]` : "phone"}
          mask="+99 (99) 9999-99999"
          type="text"
          component={InputControlled}
          className="input _opacity-gray"
          readOnly={readOnly}
        />
      </div>
    </>
  );
};
