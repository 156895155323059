import { BudgetProps, CartItem } from "Dtos/IBudget";
import { AttendanceType, CollectType } from "Stores/budget-order";

export const mountAttendances = (items: CartItem[]) => {
  const attendanceCounter = { value: 0 };

  const homeAttendances: AttendanceType[] = [];
  const unityAttendances: AttendanceType[] = [];
  const bothAttendances: AttendanceType[] = [];

  items.forEach((item: CartItem, index: number) => {
    switch (item.collect_type) {
      case "home_collect":
        addAttendance(homeAttendances, "home_collect", index, item, attendanceCounter);
        break;
      case "unity_collect":
        addAttendance(unityAttendances, "unity_collect", index, item, attendanceCounter);
        break;
      case "both":
        addAttendance(bothAttendances, "both", index, item, attendanceCounter);
        break;
      default:
        break;
    }
  });

  const attendances: AttendanceType[] = [
    ...unityAttendances,
    ...bothAttendances,
    ...homeAttendances,
  ];

  attendances.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));

  return attendances;
};

const addAttendance = (
  attendanceList: AttendanceType[],
  type: CollectType,
  index: number,
  item: CartItem,
  attendanceCounter: { value: number } // Agora é um objeto
) => {
  if (attendanceList.length > 0) {
    attendanceList[0].items.push({
      budget_id: item.budget_id,
      product_id: item.product_id,
      amount: item.amount,
      product_name: item.product_name,
      product_code: item.product_code,
      item_unit_price: item.item_unit_price,
      checked: false,
    });
  } else {
    attendanceList.push({
      id: index + 1,
      title: `Atendimento ${attendanceCounter.value + 1}`,
      type,
      region_id: 0,
      unity_id: 0,
      calendar_id: 0,
      schedule_id: 0,
      items: [
        {
          budget_id: item.budget_id,
          product_id: item.product_id,
          amount: item.amount,
          product_name: item.product_name,
          product_code: item.product_code,
          item_unit_price: item.item_unit_price,
          checked: false,
        },
      ],
    });
    attendanceCounter.value++; // Incrementando o valor do contador
  }
};


export const mountCartItems = (items: CartItem[], budget: BudgetProps) => {
  const formattedItems: CartItem[] = [];

  items.map((i) => {
    formattedItems.push({ ...i, category: budget.product_categories.find((a) => a.product_id === i.product_id)?.category_name ?? "" })
  })

  return formattedItems
}