import { USER_DUPLICATE } from "Actions/ActionsTypes";

const defaultState = {
  main: null,
  dependents: [],
  merge: [],
  remove: [],
  edit: {},
};

export default function PersonDuplicate(state = defaultState, action: any) {
  switch (action.type) {
    case USER_DUPLICATE:
      return {
        ...(action.payload || defaultState),
      };

    default:
      return state;
  }
}
