import { insertAt } from "Utils/Arrays";
import { FormatDateWithHours } from "Utils/FormatterDate";

function formatterExamList(exams, exams2) {
  const childrenElements = exams.map((exam, key) => {
    const children = exams2
      .filter((exam2) => exam.id === exam2.level1_id)
      .map((exam2) => ({
        ...exam2,
        promised_date:
          exam.promised_date && exam.promised_date !== "-"
            ? exam.promised_date.replaceAll("-", "/")
            : "Não informado",
      }));

    if (children.length > 0) {
      insertAt(exams, key + 1, children);
    }

    return {
      ...exam,
      promised_date:
        exam.promised_date && exam.promised_date !== "-"
          ? exam.promised_date.replaceAll("-", "/")
          : "Não informado",
    };
  });

  return childrenElements;
}

export const FormatterList = (apiData) => {
  return apiData.map((item) => ({
    ...item,
    displayDate: FormatDateWithHours(item.open_date),
  }));
};

export const FormatterDetail = (apiData) => {
  const returnedData = apiData;

  formatterExamList(returnedData.exams_level1, returnedData.exams_level2);

  return returnedData;
};

export const FormatterComboList = (objectData) => {
  let filters = {};

  Object.keys(objectData).forEach((comboKey) => {
    filters = {
      ...filters,
      [comboKey]: objectData[comboKey].map((key) => ({
        id: key,
        name: key,
      })),
    };
  });

  return filters;
};
