export const unmaskCPF = (cpf) => {
  return cpf.replace(/[./-\s]/g, "");
};

export const unmaskRG = (rg) => {
  return rg.replace(/[./-\s]/g, "");
};

export const MaskCPF = (cpf) => {
  if (!cpf) return "";
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return cpf;
};

export const MaskCEP = (cep) => {
  if (cep) {
    cep = cep.replace(/\D/g, "");
    cep = cep.replace(/(\d{5})(\d)/, "$1-$2");
  }

  return cep;
};

export const unmaskCEP = (cep) => {
  if (!cep) return;
  cep = String(cep);
  return cep?.replace(/[/-\s]/g, "");
};

export const MaskRG = (rg) => {
  rg = rg.replace(/\D/g, "");
  rg = rg.replace(/(\d{2})(\d)/, "$1.$2");
  rg = rg.replace(/(\d{3})(\d)/, "$1.$2");
  rg = rg.replace(/(\d{3})(\d)/, "$1-$2");

  return rg;
};

export const MaskDate = (date) => {
  date = date.replace(/\D/g, "");
  date = date.replace(/(\d{2})(\d)/, "$1/$2");
  date = date.replace(/(\d{2})(\d)/, "$1/$2");

  return date;
};

export const MaskTelephone = (telephone) => {
  telephone = String(telephone);

  telephone = telephone.replace(/\D/g, "");
  telephone = telephone.replace(/(\d{4})(\d)/, "$1-$2");

  return telephone;
};

export const unmaskPhone = (phone) => {
  if (!phone) return;
  phone = String(phone);
  phone = phone.replace(/[/-\s]/g, "");
  return phone;
};

export const MaskCelphone = (celphone) => {
  celphone = String(celphone);

  celphone = celphone.replace(/\D/g, "");
  celphone = celphone.replace(/(\d{5})(\d)/, "$1-$2");

  return celphone;
};

export const MaskLandline = (celphone) => {
  celphone = String(celphone);
  celphone = celphone.replace(/\D/g, "");
  celphone = celphone.replace(/(\d{4})(\d)/, "$1-$2");

  return celphone;
};

export const MaskCelphoneDDD = (celphone) => {
  celphone = String(celphone);

  const ddd = celphone.match(/^\s*\(\s*\d{2}\s*\)\s*/);
  const phone = celphone.replace(/^\s*\(\s*\d{2}\s*\)\s*/, "");

  const _phone = phone.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2");

  return `${ddd} ${_phone}`;
};

export const MaskHeightAndWeight = (value, unit) => {
  if (value) {
    value = value.replace(".", ",");
    value += ` ${unit}`;

    return value;
  }
  return null;
};

export const MaskBirthdate = (value) => {
  if (value) {
    value = value.split("-").reverse().join("/");
    return value;
  }
  return null;
};

export const unmaskAndMaskCPF = (cpf) => {
  cpf = unmaskCPF(cpf);
  cpf = MaskCPF(cpf);

  return cpf;
};

export function maskPhoneNumber(phoneNumber) {
  const regex = /^(\d{4})(\d{4,5})$/;

  const phone = String(phoneNumber);
  return phone.replace(regex, (match, group1, group2) => {
    if (group1.length === 4) {
      return `${group1}-${group2}`;
    }

    return `${group1}-${group2.slice(0, 4)}-${group2.slice(4)}`;
  });
}
