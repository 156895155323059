import { localStore } from "./store.config";

export const useStoreHandler = () => {
  const storage = localStore();

  const storeFilters = (filters: Object, storageName: string) => {
    const stringifiedFilter = JSON.stringify(filters);

    storage.setItem(`@${storageName}`, stringifiedFilter);
  };

  const getStoredFilters = (storageName: string) => {
    const parsedFilters = storage.getItem(`@${storageName}`);

    if (!parsedFilters) return "";

    return JSON.parse(parsedFilters);
  };

  const clearStore = (storageName: string) =>
    storage.removeItem(`@${storageName}`);

  return {
    storeFilters,
    getStoredFilters,
    clearStore,
  };
};
