import {
  PERSON_DUPLICATED_FLOW,
  PERSON_DUPLICATED_FLOW_RESET,
} from "Actions/ActionsTypes";

const defaultState = {
  document: "",
  id: null,
  order_id: null,
  origin: "",
};

export default function PersonDuplicatedFlow(
  state = defaultState,
  action: any
) {
  switch (action.type) {
    case PERSON_DUPLICATED_FLOW:
      return {
        ...state,
        ...action.payload,
      };
    case PERSON_DUPLICATED_FLOW_RESET:
      return defaultState;

    default:
      return state;
  }
}
