import styled from "styled-components";

export const ContainerSendMotion = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    margin-left: 10px;
    border-radius: 111px;
    padding: 8px 20px !important;
    font-size: 13px;
  }
  label {
    margin: 0 10px 0 0;
  }
`;

export const Hr = styled.hr`
  background-color: var(--color-semi-gray) !important;
`;

export const HeaderModal = styled.h3`
  color: var(--color-purple);
`;

export const ContainerInformation = styled.div``;

export const DetailsInfoImage = styled.dl`
  font-family: var(--font-text);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color-bold-gray);
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 15px 20px;
  border-radius: 24px;
  background: #fff;
  min-width: 412px;
  box-shadow: 0px 4px 6px -2px rgba(130, 136, 148, 0.32),
    0px 0px 0px 1px rgba(130, 136, 148, 0.16);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  &:hover {
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  div {
    display: flex;
    align-items: center;
    img {
      max-width: 60px;
      object-fit: contain;
      border-radius: 10px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 190px;
      margin-left: 10px;
      font-size: 13px;
      color: var(--color-lgray-text);
      font-weight: 400;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: grid !important;
  button {
    margin-right: 0 !important;
    border-radius: 111px;
    line-height: 6px;
    margin-bottom: 5px;
    padding: 8px 12px !important;
    font-size: 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    margin-left: 10px;
    border-radius: 111px;
    padding: 8px 12px !important;
  }
`;

export const Carousel = styled.div`
  position: relative;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 100px;
    max-width: 490px;
  }
  button {
    border-radius: 50px;
    background-color: #572580;
    color: white;
    cursor: pointer;
    border: none;
    padding: 12px 14px;
    font-size: 12px;
    width: 50px;
    height: 50px;
    text-align: center;
  }
`;

export const ButtonsCarousel = styled.div`
  position: absolute;
  left: calc(50% - 85px);
  top: calc(100% - 80px);
  button {
    margin-left: 24px;
  }
`;

export const DownloadButton = styled.button`
  position: absolute;
  top: calc(100% - 80px);
  left: 100%;
  img {
    width: 13px;
    height: 13px;
    margin: 0;
    margin-top: 2px;
  }
`;

export const ContainerDocumet = styled.div`
  display: flex;

  align-items: center;

  justify-content: center;
  margin-top: 16px;
  padding: 15px 20px;
  border-radius: 24px;
  background: rgb(255, 255, 255);
  min-width: 250px;
  box-shadow: rgba(130, 136, 148, 0.32) 0px 4px 6px -2px,
    rgba(130, 136, 148, 0.16) 0px 0px 0px 1px;
`;
