export const FormatterUnits = (
  objData,
  parkingsData,
  transports,
  isActiveParking,
  isCreate,
  objectDataImg
) => {
  const { insurances, channels, category_ids: categories } = objData;
  const covenant_ids = insurances.value?.map((item) => item.id) || [];
  const channels_id = channels.value?.map((item) => item.id) || [];
  const category_ids = categories?.value?.map((item) => item.id) || [];

  let obj = {};
  let parkingsArray = [];

  if (!isActiveParking) parkingsData = [];
  if (transports[0].transport === "") transports = [];

  function filteredDeleted(array) {
    const filterCreateUpdateMethod = array.filter(
      (item) => item.isCreatedInMethodUpdate
    );
    const filteredDeleted = filterCreateUpdateMethod.filter(
      (item) => !item.deleted
    );

    const filteredNotCreatedUpdateMethod = array.filter(
      (item) => !item.isCreatedInMethodUpdate
    );
    const newArray = [...filteredDeleted, ...filteredNotCreatedUpdateMethod];

    return newArray;
  }

  parkingsData.forEach((obj) => {
    let parkings = {};

    Object.keys(obj).forEach((parking) => {
      if (_verifyIsParking(parking)) {
        parkings = {
          ...parkings,
          addresses: {
            ...parkings.addresses,
            [parking]: obj[parking].value,
            city_id: objData.city_id.value,
          },
        };
        return;
      }

      if (parking === "deleted") {
        parkings = {
          ...parkings,
          deleted: obj[parking],
        };
        return;
      }

      parkings = {
        ...parkings,
        [parking]: obj[parking].value,
        isCreatedInMethodUpdate: obj.isCreatedInMethodUpdate,
      };
    });

    parkingsArray = [...parkingsArray, parkings];
  });

  parkingsArray = filteredDeleted(parkingsArray);
  const transportsArray = filteredDeleted(transports);

  Object.keys(objData).forEach((name) => {
    if (_verifyIsAddress(name)) {
      const _obj = objData.address_id ? { address_id: objData.address_id } : {};
      if (name === "cep") {
        obj = {
          ...obj,
          addresses: {
            cep: objData.cep.value,
            ...obj.addresses,
          },
        };
        return;
      }

      if (transports) {
        obj = {
          ...obj,
          transports: JSON.stringify(transportsArray),
        };
      }

      if (parkingsArray) {
        obj = {
          ...obj,
          parkings: JSON.stringify(parkingsArray),
        };
      }

      obj = {
        ...obj,
        addresses: {
          ...obj.addresses,
          [name]: objData[name].value,
          city_id: objData.city_id.value,
          ..._obj,
        },
      };
      return;
    }
    if (name === "insurances") {
      obj = {
        ...obj,
        covenant_ids,
      };
      return;
    }

    if (name === "region_id") {
      const value = objData[name].value.id;
      obj = {
        ...obj,
        region_id: value,
      };
      return;
    }

    if (name === "slug") {
      const { value } = objData[name];
      obj = {
        ...obj,
        slug: value.toLowerCase(),
      };
      return;
    }

    if (name === "division_id") {
      const value = objData[name].value.id;
      obj = {
        ...obj,
        division_id: value,
      };
      return;
    }

    if (name === "type") {
      const { value } = objData[name];
      obj = {
        ...obj,
        type: value,
      };
      return;
    }

    if (name === "channels") {
      obj = {
        ...obj,
        channels_ids: channels_id,
      };
      return;
    }

    if (name === "category_ids") {
      obj = {
        ...obj,
        category_ids,
      };
      return;
    }

    if (name === "reference") {
      if (objData[name].value) {
        const { value } = objData[name];
        obj = {
          ...obj,
          reference: value.replace(/"/g, "'"),
        };
      }
      return;
    }

    // Create type like object
    if (name === "type") {
      if (objData[name].value) {
        const { value } = objData[name];
        obj = {
          ...obj,
          type: value,
        };
      }
      return;
    }

    if (name === "description") {
      if (objData[name].value) {
        const { value } = objData[name];
        obj = {
          ...obj,
          description: value.replace(/"/g, "'"),
        };
      }
      return;
    }

    if (name === "opening_hours") {
      if (objData[name].value) {
        const { value } = objData[name];
        obj = {
          ...obj,
          opening_hours: value.replace(/"/g, "'"),
        };
      }
      return;
    }

    if (name === "unmet_products") {
      if (objData[name].value) {
        const { value } = objData[name];
        obj = {
          ...obj,
          unmet_products: value.replace(/"/g, "'"),
        };
      }
      return;
    }

    if (name === "covenant") return;
    if (name === "region") return;
    if (name === "city_id") return;

    obj = {
      ...obj,
      [name]: objData[name].value,
    };
  });

  return obj;
};

export const FormatterImgUnitMap = (proFilesImageMap) => {
  const formData = new FormData();

  proFilesImageMap.forEach((file) => {
    formData.append("image_map[]", file);
  });

  return formData;
};

export const FormatterImgUnit = (profiles, proFilesImageMap) => {
  const formData = new FormData();

  profiles.forEach((file) => {
    formData.append("images[]", file);
  });

  proFilesImageMap.forEach((file) => {
    formData.append("image_map[]", file);
  });

  return formData;
};

export const UnFormattedUnits = (
  bodyData,
  parkingsInital,
  transportInitial,
  setParkingsActive,
  setTransportActive
) => {
  let obj = {};
  let parkingsData = {};
  let transportsData = {};
  let parkingsArray = [];
  Object.keys(bodyData).forEach((name) => {
    if (name === "registered_by" || name === "warnings" || name === "holidays")
      return;
    if (name === "images" || name === "image_map") {
      obj = {
        ...obj,
      };
      return;
    }

    if (name === "addresses") {
      Object.keys(bodyData[name]).forEach((_name) => {
        if (_name === "default") return;

        if (_name === "cities") {
          obj = {
            ...obj,
            city_id: {
              value: bodyData.addresses[_name].id,
              error: "",
              cities: [bodyData.addresses[_name]],
            },
          };
          return;
        }

        if (_name === "id") {
          obj = {
            ...obj,
            address_id: bodyData.addresses[_name],
          };
          return;
        }
        obj = {
          ...obj,
          [_name]: {
            value: bodyData.addresses[_name],
            error: "",
          },
        };
      });

      return;
    }

    if (name === "parkings") {
      if (bodyData[name].length === 0) {
        parkingsArray = [parkingsInital];
        return;
      }
      setParkingsActive(true);
      bodyData[name].forEach((obj) => {
        Object.keys(obj).forEach((item) => {
          if (item === "opening_time") return;
          if (item === "addresses") {
            Object.keys(obj[item]).forEach((obj_address) => {
              if (
                obj_address === "cities" ||
                obj_address === "default" ||
                obj_address === "id"
              )
                return;
              if (_verifyIsParking(obj_address)) {
                parkingsData = {
                  ...parkingsData,
                  [obj_address]: {
                    value: obj[item][obj_address],
                    error: "",
                  },
                };
                return;
              }
              parkingsData = {
                ...parkingsData,
                [item]: { value: obj[item], error: "" },
              };
            });
            return;
          }
          parkingsData = {
            ...parkingsData,
            [item]: { value: obj[item], error: "" },
            isCreatedInMethodUpdate: false,
          };
        });
        parkingsArray = [...parkingsArray, parkingsData];
      });
      return;
    }

    if (name === "transports") {
      if (bodyData[name].length === 0) {
        transportsData = [transportInitial];
        return;
      }
      setTransportActive(true);
      transportsData = bodyData[name];
      return;
    }

    if (name === "covenants") {
      const newArray = bodyData[name].map((item) => ({
        id: item.id,
        covenant: item.covenant,
      }));
      obj = {
        ...obj,
        insurances: {
          value: newArray,
          error: "",
        },
      };
      return;
    }

    if (name === "region") {
      obj = {
        ...obj,
        region_id: {
          value: {
            id: bodyData[name]?.id,
            region: bodyData[name]?.region,
          },
          error: "",
        },
      };
      return;
    }

    if (name === "divisions") {
      obj = {
        ...obj,
        division_id: {
          value: bodyData[name],
          error: "",
        },
      };
    }

    if (name === "categories") {
      obj = {
        ...obj,
        category_ids: {
          value: bodyData[name],
          error: "",
        },
      };
    }

    obj = {
      ...obj,
      [name]: {
        value: bodyData[name] ?? "",
        error: "",
      },
    };

    return;
  });
  return { obj, parkingsData: parkingsArray, transportsData };
};

function _verifyIsAddress(name) {
  const addressField = [
    "neighborhood",
    "street",
    "number",
    "complement",
    "cep",
    "latitude",
    "longitude",
  ];
  return addressField.some((field) => field === name);
}

function _verifyIsParking(name) {
  const addressField = [
    "neighborhood",
    "street",
    "number",
    "complement",
    "cep",
    "latitude",
    "longitude",
  ];
  return addressField.some((field) => field === name);
}
