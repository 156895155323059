export const getPeriodData = (
  selectedPeriod,
  daysSeries,
  weeksSeries,
  monthsSeries,
  key
) => {
  if (selectedPeriod === "days") {
    return daysSeries[key];
  }
  if (selectedPeriod === "weeks") {
    return weeksSeries[key];
  }
  return monthsSeries[key];
};
export const getTax = (data) => {
  if (!data) return "";
  return data.includes("-") ? `↓ ${  data}` : `↑ ${  data}`;
};
