import React from "react";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { LoadingProps } from "./interface";

export function Loading({ classCss, sizeImg }: LoadingProps) {
  return (
    <span
      className={`loading-persons ${classCss || ""} ${
        sizeImg ? "sizeImg" : ""
      }`}
    >
      <img src={LoadingImage} alt="" />
    </span>
  );
}
