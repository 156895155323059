import API from "Services/Api";

import { ProductLogsInterface } from "./interface";

export async function getProductLogs({
  action,
  start_at,
  end_at,
  page = 1,
}: ProductLogsInterface) {
  try {
    const response = await API.get("/products/logs", {
      params: {
        action,
        start_at,
        end_at,
        page,
      },
    });
    const data = {
      data: response.data.data,
      pagination: response.data.meta.pagination,
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
