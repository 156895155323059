import styled from "styled-components";

export const TitlePageSymptoms = styled.h3`
  margin: 0;
  padding: 0;

  margin-top: 0.5rem;

  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;

  letter-spacing: 0.5px;

  color: var(--color-purple);
`;

export const TitlerReception = styled.p`
  p,
  strong {
    marign: 0;
    padding: 0;
  }

  strong {
    font-weight: 600;
  }

  margin-bottom: 2.5rem;

  text-align: center;

  font-family: var(--font-text);
  font-style: normal;
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 2.5rem;

  text-align: center;

  color: #999999;
`;

export const ContainerReception = styled.div`
  padding: 1.75rem 0.5rem;
  background-color: #f5f5f5;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxReception = styled.div`
  padding: 0.5rem 1rem;
`;

export const FlexCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ReceptionButtons = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
