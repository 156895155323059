import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  GetBudgetByID,
  SendBudgetEmail,
  DeleteDocumentsBudget,
  GetDocumentsBudget,
} from "Requests/Budget";

import { CopyToClipboard } from "Utils/Copy";
import { MaskCPF, MaskCelphone } from "Utils/Masks";
import { validatorEmail } from "Utils/Validator";

import PageWrapper from "Components/Pages/Wrapper";
import CartItem from "Components/ServiceForm/CartItem";

import LoadingImage from "Assets/Images/loading-purple.gif";

import InputButton from "../../Components/Buttons/InputButton";
import MedicalRecord from "../../Components/ServiceForm/MedicalRecord";
import { GetPaymentData } from "../../Requests/Orders";
import { getToken } from "../../Services/Authentication";

const ServiceFormResume = (props) => {
  const refPaymentLink = useRef(null);

  const [loading, setLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);
  const [budget, setBudget] = useState({});
  const [pacientEmail, setPacientEmail] = useState("");
  const [paymentData, setPaymentData] = useState({});
  const [documents, setDocuments] = useState([]);

  const stateLocation = props.location.state;

  async function fetchBudget() {
    const responseBudget = await GetBudgetByID(props.match.params.budget_id);

    if (
      stateLocation &&
      stateLocation.orderCreated &&
      stateLocation.payment_url
    ) {
      const paymentResponse = await GetPaymentData(stateLocation.orderId);
      if (paymentResponse.success) {
        setPaymentData(paymentResponse.data);
      }
    }

    if (!responseBudget.success) {
      props.history.push("/ficha-de-atendimento/orcamentos");

      return;
    }

    if (!responseBudget.info.pacient && responseBudget.info.lead) {
      responseBudget.info.pacient = responseBudget.info.lead;
    }

    setBudget(responseBudget.info);

    setLoading(false);
  }

  async function handlePdfDownload(e) {
    e.preventDefault();

    const isClinic = budget.budget_type === 5;

    const url = `${process.env.REACT_APP_API_URL}/budgets/pdf${
      isClinic ? "/clinic/" : "/"
    }${budget.id}?token=${getToken()}`;

    window.open(url, "_blank");
  }

  function createToast(style, message) {
    toast.dismiss();

    setTimeout(() => {
      toast[style](message);
    }, 400);
  }

  async function sentBudgetViaEmail(e) {
    e.preventDefault();

    if (pacientEmail === "" && !validatorEmail(pacientEmail)) {
      createToast(
        "error",
        "O email é obrigatório e precisa ser um email válido"
      );

      return;
    }

    setLoadingAction(true);

    const responseEmail = await SendBudgetEmail(budget.id, pacientEmail);

    if (responseEmail.success) {
      createToast("success", "Orçamento enviado com sucesso!");
    } else {
      createToast("error", "Oops! Não foi possível enviar o email");
    }

    setPacientEmail("");
    setLoadingAction(false);
  }

  async function fetchDocuments(budget_id) {
    const responseDocuments = await GetDocumentsBudget(budget_id);

    if (responseDocuments.success) {
      setDocuments(responseDocuments.data);
    }
  }

  async function removeDocument(document_id) {
    const documentResponse = await DeleteDocumentsBudget(document_id);

    if (documentResponse.success) {
      setDocuments(documents.filter((document) => document.id !== document_id));

      createToast("success", "Ficha médica removida com sucesso.");
    } else {
      createToast("error", "Não foi possível remover a ficha médica.");
    }
  }

  function copyToClipboard(value) {
    CopyToClipboard({
      message: "Copiado!",
      text: value,
    });
  }

  useEffect(() => {
    if (!props.match.params.budget_id) {
      props.history.push("/ficha-de-atendimento/orcamentos");
    } else {
      fetchBudget();
    }
  }, []);
  return (
    <PageWrapper className="entry-app-content">
      {loading ? (
        <div className="loading loading-holder">
          <img src={LoadingImage} alt="" />
        </div>
      ) : (
        <section className="service-form-page service-form-resume">
          <div className="container">
            <div className="row">
              <div className="sm-12">
                <div className="order-resume-item order-resume-pacient small-separate">
                  <h4 className="title text-left text-light text-display text-purple small-separate">
                    Detalhes do{" "}
                    {stateLocation && stateLocation.orderCreated
                      ? "pedido"
                      : "orçamento"}{" "}
                    de Nº{" "}
                    {stateLocation && stateLocation.orderCreated
                      ? stateLocation.orderId
                      : budget.id}
                  </h4>

                  <p className="text-lgray text-left text-light text-lgray separate-base">
                    Abaixo segue informações referentes ao{" "}
                    {stateLocation && stateLocation.orderCreated
                      ? "pedido gerado"
                      : "orçamento salvo"}
                    .
                  </p>

                  <div className="order-resume-item order-resume-info">
                    <div className="row">
                      <div className="sm-12">
                        <span className="text-block text-purple uppercase">
                          Data do orçamento:
                        </span>
                        <span className="text-lgray text-opacity-75">
                          {budget.date}
                        </span>
                      </div>
                      {paymentData.payment_link && (
                        <div className="sm-12">
                          <span className="text-block text-purple uppercase">
                            Link para pagamento:
                          </span>
                          <span
                            ref={refPaymentLink}
                            className="text-lgray text-opacity-75"
                          >
                            {paymentData.payment_link}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="order-resume-item order-resume-pacient small-separate">
                  <h4 className="title text-left text-light text-display text-purple">
                    Dados do paciente
                  </h4>

                  {budget.id && (
                    <MedicalRecord
                      documents={documents}
                      fetchDocuments={() => fetchDocuments(budget.id)}
                      budget={budget}
                      initialFetchDocuments
                      noEdit
                    />
                  )}

                  <ul className="data-list">
                    {budget.pacient.name && (
                      <li className="data-list-item">
                        <span className="text-purple">Nome: </span>{" "}
                        {budget.pacient.name}
                      </li>
                    )}
                    {budget.pacient.cpf && (
                      <li className="data-list-item">
                        <span className="text-purple">CPF: </span>{" "}
                        {MaskCPF(budget.pacient.cpf)}
                      </li>
                    )}
                    {budget.pacient.email && (
                      <li className="data-list-item">
                        <span className="text-purple">Email: </span>{" "}
                        {budget.pacient.email}
                      </li>
                    )}
                    {budget.pacient.phone && (
                      <li className="data-list-item">
                        <span className="text-purple">Telefone: </span>{" "}
                        {MaskCelphone(budget.pacient.phone)}
                      </li>
                    )}
                  </ul>
                </div>

                <div className="order-resume-item order-resume-products small-separate">
                  <h4 className="title text-left text-light text-display text-purple">
                    Produtos neste orçamento
                  </h4>

                  <div className="data-resume-item">
                    {budget.cart.map((item) => (
                      <CartItem
                        key={item.id}
                        isProduct
                        product={item}
                        readOnly
                      />
                    ))}
                    {budget.home_collected && (
                      <CartItem
                        label="Coleta Domiciliar"
                        value={`R$ ${budget.homeCollectValueDisplay}`}
                      />
                    )}
                  </div>
                  <div className="data-resume-values">
                    <div className="cart-item resume">
                      <p className="label">Subtotal</p>
                      <p className="price">R$ {budget.subtotalDisplay}</p>
                    </div>
                    {budget.coupon_code && (
                      <div className="cart-item resume">
                        <p className="label">Cupom aplicado</p>
                        <p className="coupon-code">{budget.coupon_code}</p>
                      </div>
                    )}
                    <div className="cart-item resume">
                      <p className="label">Descontos</p>
                      <p className="price">R$ {budget.discountsDisplay}</p>
                    </div>
                    <div className="cart-item resume">
                      <p className="label">Total</p>
                      <p className="price">R$ {budget.totalDisplay}</p>
                    </div>
                  </div>
                </div>

                <div className="order-resume-item order-resume-products text-center small-separate">
                  {paymentData.payment_link && (
                    <button
                      onClick={() => copyToClipboard(paymentData.payment_link)}
                      type="button"
                      className="button _block _xs _blue small-separate"
                    >
                      Copiar link de pagamento
                    </button>
                  )}
                  {budget.id &&
                    budget.status === 1 &&
                    stateLocation &&
                    stateLocation.orderCreated && (
                      <Link
                        to={`/ficha-de-pedido/${stateLocation.orderId}`}
                        className="button _block _xs small-separate"
                      >
                        Visualizar pedido
                      </Link>
                    )}
                  {budget.id && budget.status !== 1 && (
                    <>
                      <Link
                        to={`/ficha-de-atendimento/pagamento/${budget.id}`}
                        className="button _block _xs small-separate"
                      >
                        Prosseguir para o pagamento
                      </Link>

                      <button
                        onClick={() => copyToClipboard(budget.site_link)}
                        type="button"
                        disabled
                        className="button _block _xs _blue small-separate"
                      >
                        Link para pagamento no site
                      </button>

                      <button
                        onClick={handlePdfDownload}
                        type="button"
                        className="button _block _xs _yellow small-separate"
                      >
                        Baixar como PDF
                      </button>

                      <div className="coupon-cart-holder text-center">
                        <InputButton
                          placeholder="Email do paciente"
                          value={pacientEmail}
                          onChange={setPacientEmail}
                          label="Enviar email"
                          submit={sentBudgetViaEmail}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </PageWrapper>
  );
};

export default ServiceFormResume;
