import React, { useState } from "react";

import { Box } from "styles/globalComponents";

import { STATUS } from "Constants/Combos";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { formatBRLCurrency } from "Utils/FormatCurrency";

import { Button } from "Components/Button";
import { Loader } from "Components/Loader";

import { ProductCardProps } from "./interface";
import { Container } from "./styles";

export const CardProduct = ({
  product,
  addToCart,
  removeToCart,
  children,
}: ProductCardProps) => {
  const [loadingTask, setLoadingTask] = useState(false);

  const { budget, loadingCart } = useBudgetContext();

  const { default_partner, product_description, is_covered } = product ?? {};

  const isProductAlredyInCart = budget?.cart?.some(
    (itemCart) => itemCart.product_id === product.id
  );

  const { synonym } = product_description ?? {};

  const { category, status } = product;

  const taskRunning = async () => {
    setLoadingTask(true);

    if (isProductAlredyInCart) {
      await removeToCart();
    }

    if (!isProductAlredyInCart) {
      await addToCart();
    }

    setLoadingTask(false);
  };

  const isVaccine = category.category === "Vacinas";
  const unavaliable = status.id === Number(STATUS.unavaliable);
  const inactive = status.id === Number(STATUS.inactive);

  const warnMe = unavaliable && isVaccine;

  const buttonContent = () => {
    if (inactive) return "Em espera";
    if (warnMe) return "Avise-me";
    if (unavaliable) return "Indisponível";

    return (
      <Box display="flex" gap="8" className="img-btn" justify="center">
        {isProductAlredyInCart ? "Remover" : "Adicionar"}{" "}
        <div
          className={isProductAlredyInCart ? "cart-bag-remove" : "cart-bag"}
        />
      </Box>
    );
  };

  const coveredPrice = default_partner?.price
    ? `Não Coberto - ${formatBRLCurrency(
        default_partner.price,
        true
      )} em até 12x`
    : "R$ 0,00";

  const isCovenantPrice = is_covered ? "Coberto" : coveredPrice;

  const productPrice = default_partner?.price
    ? formatBRLCurrency(default_partner.price, true)
    : "R$ 0,00";

  const priceItem = Object.hasOwn(product, "is_covered")
    ? isCovenantPrice
    : productPrice;

  return (
    <Container>
      <td>{product.sku}</td>
      <td>{product.product}</td>
      <td>{synonym?.substring(0, 40)}...</td>
      <td className="price">{priceItem}</td>
      <td>
        <Box display="flex" gap="15" justify="center">
          {children}

          <Button
            onClick={taskRunning}
            disabled={loadingTask || loadingCart}
            classButton={`${
              isProductAlredyInCart ? "text-opacity-50" : ""
            } button-cart`}
          >
            {loadingTask ? <Loader size={20} /> : buttonContent()}
          </Button>
        </Box>
      </td>
    </Container>
  );
};
