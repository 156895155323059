import React from "react";

import { DependentContinueInterface } from "Pages/RegistrationCleaning/interface";
import { TextModal } from "Pages/RegistrationCleaning/styles";

export const ValidationModal = ({
  handleModal,
}: DependentContinueInterface) => {
  return (
    <>
      <TextModal fontWeight={400}>
        Para finalizar o ajuste, valide as informações de <b>identidade</b> e de{" "}
        <b>contato</b> junto ao cliente e caso haja alguma informação
        divergente, <b>atualize-a</b>.
      </TextModal>
      <div
        className="flex-buttons-modal-information"
        style={{ marginTop: 30, flexDirection: "column", gap: 32 }}
      >
        <div className="container-button-information">
          <button className="button _action" onClick={() => handleModal()}>
            continuar para validação
          </button>
        </div>
      </div>
    </>
  );
};
