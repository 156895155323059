import { Lead, Patient } from "Dtos/IBudget";

export const formatClient = (patient: Patient | null, lead: Lead | null) => {
  if (patient) {
    return {
      birthdate: patient.birthdate,
      documents: [
        {
          number: patient.document.number,
          type: patient.document.type,
        },
      ],
      emails: [
        {
          default: true,
          email: patient.email,
        },
      ],
      full_name: patient.name,
      id: patient.id,
      mother_name: null,
      phones: [
        {
          ddd: patient.phone.ddd,
          ddi: patient.phone.ddi,
          default: true,
          phone: patient.phone.phone,
        },
      ],
      social_name: patient.social_name,
      isPerson: true,
    };
  }

  return {
    cpf: null,
    ddd: lead?.ddd ?? "",
    email: lead?.email ?? null,
    id: lead?.id,
    name: lead?.name ?? null,
    phone: lead?.phone ?? null,
    isPerson: false,
    full_name: lead?.name,
    isLead: true,
    phones: [
      {
        ddd: lead?.ddd ?? null,
        phone: lead?.phone ?? null,
      },
    ],
    emails: [
      {
        email: lead?.email ?? "",
      },
    ],
  };
};
