import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Content = styled(Box)`
  & * {
    color: var(--color-hgray-text);
    text-align: center;
    font-family: var(--font-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin: 0;
  }

  strong {
    font-weight: 700;
  }
`;
