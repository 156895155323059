const address = [
  {
    default: true,
  },
];

const phones = [
  {
    default: true,
  },
];

const emails = {
  default: true,
};

export const CpfData = {
  id: undefined,
  person_id: undefined,
  country_id: undefined,
  number: "",
  type: "cpf",
};

export const PassaportData = {
  id: undefined,
  person_id: undefined,
  country_id: undefined,
  number: "",
  type: "passport",
};

export const InitialValuePatient = {
  id: 0,
  full_name: "",
  social_name: "",
  unborn: false,
  gender_id: undefined,
  birthdate: "",
  mother_name: null,
  phones,
  documents: [CpfData, PassaportData],
  emails: [emails],
  gender: {
    id: 0,
    gender: "",
    sub_gender: "",
  },
  addresses: address,
  parents: [],
  covenants_credentials: [],
};
