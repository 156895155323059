import { Form } from "formik";
import styled from "styled-components";

export const Container = styled(Form)`
  &.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-items {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin: 0 0 8px;
    }
  }

  .form-item {
    width: 100%;
    margin: 0 0 32px;
  }

  .upload-item {
    position: relative;

    &.upload-error {
      & > div {
        background-color: rgba(224, 159, 157, 0.25);
        box-shadow: 0px 0px 0px 1px #c23f3b;
      }
    }

    .error {
      position: absolute;
      right: 8px;
    }
  }
`;

export const DropZoneContainer = styled.div`
  position: relative;

  .drop-zone {
    margin-right: 22px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 4px 6px -2px rgba(130, 136, 148, 0.32);
    border-radius: 24px;
    padding: 15px 20px;

    .space-file-name {
      text-transform: uppercase;
      padding-left: 23px;
    }

    .button-trash {
      margin-right: 34px;
    }

    .upload-wrapper {
      width: auto;
    }

    img {
      max-width: 74px;
      max-height: 96px;
    }

    &.addAnexo {
      margin-right: 0 !important;

      p {
        margin: 0;
      }
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 168px;
    }

    &.error {
      border-color: #c23f3b;
      background-color: rgba(224, 159, 157, 0.25);
      box-shadow: 0px 0px 0px 1px #c23f3b;
    }
  }

  .error-message {
    position: absolute;
    right: 8px;
  }
`;
