import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

import API from "Services/Api";

import UploadArea from "Components/Form/UploadArea";
import DetailsOrdersErrorsModal from "Components/Modals/DetailsOrdersErrorsModal";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";
import UploadIcon from "Assets/Images/upload.png";

import { Header, NavWrapper, PaginationWrapper } from "./styles";

const ImportOrders = () => {
  const [modalErrorCode, setModalErrorCode] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imported_orders, setImportedOrders] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageActive, setPageActive] = useState(0);
  const [lastPagePagination, setLastPagePagination] = useState(1);

  useEffect(() => {
    fetchImportedOrders();
  }, []);

  function handleUploadModal() {
    setIsUploadModalVisible(!isUploadModalVisible);
  }

  async function fetchImportedOrders(page = 1) {
    setLoading(true);

    try {
      const response = await API.get(`/import/spreadsheet?page=${page}`);

      const { data } = response.data;

      const pagination = {
        current_page: response.data.current_page,
        per_page: response.data.per_page,
        total_pages: response.data.last_page,
      };

      setPagination(pagination || {});
      setPageActive(pagination.current_page || page);
      setImportedOrders(data);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  async function sendFileFinished(response) {
    const { data } = response;

    if (data && data.total_errors > 0) {
      showErrors(data.id, data.code_name);
    }

    fetchImportedOrders();
  }

  function handlePagination(e) {
    if (lastPagePagination === e.currentPage) return;

    fetchImportedOrders(e.currentPage);
    setLastPagePagination(e.currentPage);
  }

  async function showErrors(id, code) {
    setLoadingModal(true);
    setModalErrorCode(code);
    setModalVisible(true);

    try {
      const response = await API.get(`/import/spreadsheet/errors/${id}`);

      const { data } = response.data;
      setDataModal(data);
    } catch (error) {
      console.log(error);
    }

    setLoadingModal(false);
  }

  function Loading() {
    return (
      <span className="loading-persons">
        <img src={LoadingImage} alt="" />
      </span>
    );
  }

  return (
    <PageWrapper className="entry-app-content">
      <Modal
        isOpen={isUploadModalVisible}
        onRequestClose={handleUploadModal}
        bodyOpenClassName="modal-content-open"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        className="upload-modal"
        closeTimeoutMS={500}
      >
        <div>
          <img src={UploadIcon} alt="Upload" />
          <h1>Fazer upload</h1>
          <UploadArea
            formDataFileString="order_spreadsheet"
            importPath="/import/orders"
            allowExtensions={["xlsx", "xls", "csv"]}
            finished={sendFileFinished}
          />
        </div>
      </Modal>
      <DetailsOrdersErrorsModal
        modalErrorCode={modalErrorCode}
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        loading={loadingModal}
        data={dataModal}
      />
      <div className="content-holder">
        {/* <div className="page-heading">
					<div className="wrapper-header-content">
						<PageHeader title="Importar pedidos">
							<Breadcrumb
								list={[
									{
										path: "/pedidos",
										label: "Pedidos",
										current: false
									},
									{
										path: "#",
										label: "Importar pedidos",
										current: true
									}
								]}
							/>
						</PageHeader>
					</div>
				</div> */}
        <Header>
          <h1>Importar Pedidos</h1>
          <Breadcrumb
            list={[
              {
                path: "/pedidos-domiciliar",
                label: "DOMICILIAR",
                current: false,
              },
              {
                path: "#",
                label: "IMPORTAR PEDIDOS",
                current: true,
              },
            ]}
          />
        </Header>

        <NavWrapper>
          <nav>
            <Link to="/pedidos-domiciliar">EXPORTAR</Link>
            <Link to="#" className="current">
              IMPORTAR
            </Link>
          </nav>
          <div>
            <div className="page-content page-content-list">
              <table className="table-content small-separate details">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Data</th>
                    <th>Nº Registros</th>
                    <th>Nº Registros com falha</th>
                  </tr>
                </thead>
                <tbody>
                  {imported_orders.length > 0 && !loading ? (
                    imported_orders.map((item) => (
                      <tr key={item.id}>
                        <td>{item.code_name}</td>
                        <td>{item.import_date}</td>
                        <td>{item.total_items}</td>
                        <td
                          className={
                            item.total_errors > 0
                              ? "text-purple text-click"
                              : ""
                          }
                          onClick={() => {
                            if (item.total_errors <= 0) return;
                            showErrors(item.id, item.code_name);
                          }}
                        >
                          {item.total_errors > 0
                            ? `${item.total_errors} - ver mais`
                            : item.total_errors}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center text-purple">
                        {loading ? (
                          <Loading />
                        ) : (
                          "Oops! Parece que nada foi encontrado..."
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </NavWrapper>
      </div>
      <PaginationWrapper>
        {!loading && (
          <>
            <Pagination
              totalRecords={pagination?.total_pages}
              currentPageSelected={pagination?.current_page}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e) => handlePagination(e)}
            />
          </>
        )}

        <button className="button" onClick={handleUploadModal}>
          Importar pedidos
        </button>
      </PaginationWrapper>
    </PageWrapper>
  );
};

export default ImportOrders;
