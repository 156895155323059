import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { AppHolder } from "styles/appHolder";

import Routes from "./Routes";

export default function App() {
  return (
    <AppHolder className="app-holder">
      <Routes />
      {/* @ts-ignore */}
      <ToastContainer position="bottom-center" />
    </AppHolder>
  );
}
