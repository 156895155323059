import styled, { css } from "styled-components";

interface ContentArticleProps {
  open?: boolean;
  fitContent?: boolean;
  twoColumns?: boolean;
}

export const FlexDetailsItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dd {
    text-align: center;
  }
`;

export const GridPaymentItems = styled.div`
  &.payment-grid {
    margin-top: -7px;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 60px);
  grid-auto-flow: column;
  column-gap: 8px;

  dl {
    margin-top: 16px;
    margin-bottom: 0;
    max-width: 175px;
    dd {
      word-break: break-word;
    }
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: #572580;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

export const Centering = styled.div`
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
`;

export const ContainerRevision = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.1rem;
  padding: 1rem 1.5rem;

  padding-bottom: 0;
`;

export const ContainerUnity = styled.div`
  padding: 0 1.5rem;
`;

export const BorderSeparate = styled.div`
  margin: 1.125rem 1.5rem;
  border-bottom: 1px solid #ebebeb;
`;

export const ContentArticle = styled.article<ContentArticleProps>`
  flex: 1;
  ${(props) =>
    props?.open &&
    css`
      section:first-child {
        height: 100%;
      }
    `}

  ${(props) =>
    props?.twoColumns &&
    css`
      flex: 2;
    `}

  @media screen and (max-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1321px) {
    dl {
      dt {
        font-size: 14px;
      }
    }
  }
`;

export const TitleUnity = styled.p`
  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;

  letter-spacing: 0.5px;

  color: var(--color-purple);
`;

export const ContainerUnityForm = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div:first-child {
    flex: 0 0 60%;
  }

  margin-bottom: 1.125rem;
`;

export const TitleFinalValue = styled.p`
  &,
  strong {
    margin: 0;
    padding: 0;
    font-family: var(--font-display);
  }

  font-style: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #999999;

  strong,
  span {
    color: var(--color-purple);
    font-weight: 600;
  }

  strong {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const ContainerButtonRevision = styled.div`
  margin-top: 1rem;
  text-align: right;
  button {
    padding: 8px 36px !important;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
  text-align: center;

  p {
    color: var(--color-purple);
    font-size: 20px;
  }

  img {
    width: 4rem;
  }
`;

export const AttendancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem;
  border-radius: 16px;
  flex-wrap: wrap;
`;
