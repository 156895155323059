import * as React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { BudgetProvider } from "Context/Budget";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "Store";
import { GlobalStyle } from "styles/global";

import App from "./App";

import "Assets/Scss/main.scss";

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      {/* @ts-ignore */}
      <PersistGate persistor={persistor}>
        <GlobalStyle />
        <BrowserRouter>
          <BudgetProvider>
            <App />
          </BudgetProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);
