export const replaceStringNumber = (value?: number | null | string) => {
  if(value === undefined) return 0

  if (typeof value === "string") {
    return parseFloat(value.replace(/\./g, "").replace(",", "."));
  }

  if (typeof value === "number") {
    const newValue = value
      ?.toFixed(2)
      .toString()
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2")
      .replace(/(?=(\d{3})+(\D))\B/g, ".");

    if (value < 0) {
      return "-" + newValue;
    }

    return newValue;
  }

  return "0,00";
};