import { Modal } from "Components/Modals/Modal";
import Warning from "Assets/Images/atention.svg";
import { Box } from "styles/globalComponents";
import { Button } from "Components/Button";
import { cancelOrder } from "Requests/OrderCancel";
import { useHistory } from "react-router";
import { useState } from "react";

interface OrderCancelModalProps {
  isOpen: boolean;
  closeModal: () => void;
  orderId: number;
}

export const OrderCancelModal = ({
  isOpen,
  closeModal,
  orderId
}: OrderCancelModalProps) => {
  const history = useHistory();

  const [loadingCancel, setLoadingCancel] = useState(false)

  const handleCancelOrder = async () => {
    try {
      setLoadingCancel(true);
      await cancelOrder(orderId);
      setLoadingCancel(false);
      history.push('/pedidos')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Warning}
      renderHeading={() => (
        <h2 style={{ textAlign: 'center' }}>
          Cancelar pedido
        </h2>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">

          <p style={{
            marginBottom: '-1.5em',
            color: 'var(--color-hgray-text)',
            textAlign: 'center',
            fontFamily: 'var(--font-text)',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '160%',
            margin: '0px',
          }}>
            <strong>Atenção:</strong> Ao cancelar o pedido o agendamento vinculado a este pedido será excluído também e o valor será automaticamente estornado ao cliente.
          </p>

          <Button width="full" minHeight="3.7em" fontWeight="600" onClick={() => handleCancelOrder()} loading={loadingCancel}>
            Prosseguir com o Cancelamento
          </Button>

          <Button width="full" outlined minHeight="3.7em" fontWeight="600" onClick={() => closeModal()}>
            Voltar para Pedido
          </Button>
        </Box >
      )}
    />
  )
}