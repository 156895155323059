import "react-dates/initialize";
import React, { useState } from "react";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import ptBr from "moment/locale/pt-br";

moment.locale("pt-br", ptBr);

const InputDateRange = ({
  startDateId,
  endDateId,
  dates,
  onDatesChange,
  error,
  small,
  purple,
  label,
  openInModal,
  blockPastChanges,
  withClear,
  disabled = false,
  startDatePlaceholderText,
  endDatePlaceholderText,
}) => {
  const [focused, setFocused] = useState(null);

  const { startDate, endDate } = dates;

  return (
    <div
      className={`
            field-holder 
            field-holder-date
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={startDateId}
          className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
        >
          {label}
        </label>
      )}
      <DateRangePicker
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={(dates) => onDatesChange(dates)}
        focusedInput={focused}
        onFocusChange={(focusedInput) => setFocused(focusedInput)}
        withPortal={openInModal}
        numberOfMonths={1}
        disabled={disabled}
        displayFormat="DD/MM/YYYY"
        hideKeyboardShortcutsPanel
        isOutsideRange={
          blockPastChanges
            ? (day) =>
                isInclusivelyBeforeDay(day, moment().subtract("1", "days"))
            : () => false
        }
        showClearDates={withClear}
        minimumNights={0}
      />
      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default InputDateRange;
