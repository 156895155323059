import { CheckboxProps } from "./interface";

const Checkbox = ({
	id,
	label,
	descriptionText,
	value,
	error,
	required,
	purple,
	onChange,
	small,
	block,
	readOnly
}: CheckboxProps) => {
	return (
		<div
			className={`
		field-holder
		checkbox-field 
		field-checkbox-holder
		${error ? "field-holder-invalid" : ""}
		${small ? "field-holder-small" : ""}
		${block ? "field-holder-block" : ""}
        `}
		>
			{label && (
				<label className={`text-gray text-semibold ${purple ? "_purple" : ""}`}>
					{label}
				</label>
			)}
			<label className="checkbox-holder">
				<input
					type="checkbox"
					className="input _checkbox"
					name={id}
					id={id}
					onChange={input => {
						onChange(id, !value);
					}}
					value={value}
					checked={value}
					required={required}
					disabled={readOnly}
				/>
				<span className="box-check" />
				{descriptionText}
			</label>
			{error !== "" && (
				<span
					className={`error-message ${!label ? "error-without-label" : ""}`}
				>
					{error}
				</span>
			)}
		</div>
	);
};

export default Checkbox;
