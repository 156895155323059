import React, { ChangeEvent } from "react";

interface InputCepProps {
  id: string;
  label: string;
  value: string;
  mask: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  readOnly?: boolean
}


export const InputCep = ({
  id,
  label,
  value,
  mask,
  placeholder,
  onChange,
  error,
  readOnly
}: InputCepProps) => {

  return (
    <div
      className={`
          field-holder 
          ${error ? "field-holder-invalid" : ""}
      `}
    >
      {label && (
        <label
          htmlFor={id}
          className='text-gray text-semibold'
        >
          {label}
        </label>
      )}
      <input
        id={id}
        name={id}
        type="text"
        className="input _opacity-gray"
        onChange={(e) => onChange(e)}
        value={value}
        placeholder={placeholder}
        maxLength={9}
        readOnly={readOnly}
      />


    </div>
  )
}