import styled from "styled-components";

export const SelectContainer = styled.div`
  .select-holder {
    display: flex;
    gap: 32px;

    .input-holder {
      flex: 1;
    }
  }

  .products-package-items {
    margin-top: 16px;
  }
`;
