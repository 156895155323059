import { Modal } from "Components/Modals/Modal";
import { Box } from "styles/globalComponents";

import { useEffect, useState } from "react";
import { useSchedule } from "Hooks/useSchedule";
import { toast } from "react-toastify";
import { Button } from "Components/Button";
import { useShallow } from "zustand/react/shallow";
import { useRescheduleAppointment } from "Stores/reschedule-store";
import { rescheduleOrder } from "Requests/RescheduleOrder";
import moment from "moment";
import { getCollectRangeCep } from "Requests/CollectRangeCep";
import { FormValuesAdressType } from "Pages/Orders/CreateOrder/components/Collect/types";
import { fetchDatesHomeCollect, fetchHoursHomeCollect } from "Requests/HomeCollect";
import { ArraysType, BooleansType, ObjectsType } from "./types";
import { CollectAddress, PatientType, PayerOrderType } from "../interface";
import { AppointmentScheduleType, CalendarDateType, CalendarHourType } from "./components/SlideDates/types";
import { CollectCalendarReschedule } from "./components/CollectCalendar";
import { mountSkusProducts } from "./functions";


interface RescheduleCalendarModalProps {
  isOpen: boolean;
  closeModal: (openAnotherModal: boolean) => void;
  address?: CollectAddress;
  patients?: PatientType[];
  payer: PayerOrderType;
  schedule_id: string;
  handleBackModal: () => void;
  handleOpenBlockedSchedule: () => void;
  order_id: number;
  appointmentTypeId: number;
}


export const RescheduleCalendarModal = ({
  isOpen,
  closeModal,
  address,
  patients,
  schedule_id,
  handleBackModal,
  handleOpenBlockedSchedule,
  payer,
  order_id,
  appointmentTypeId
}: RescheduleCalendarModalProps) => {

  const { getZipcodeStateConverted } = useSchedule()

  const [rescheduleAddress, setRescheduleAddress] = useRescheduleAppointment(useShallow((state) => [
    state.rescheduleAddress,
    state.setRescheduleAddress,
  ]));

  const [arrays, setArrays] = useState<ArraysType>({
    dates: [],
    hours: []
  });

  const [booleans, setBooleans] = useState<BooleansType>({
    loadingDates: false,
    loadingHours: false,
    loadingReschedule: false
  });

  const [objects, setObjects] = useState<ObjectsType>({
    appointment: {
      appointment_id: '',
      calendar_id: '',
      query_hour: '',
      query_date: '',
      type: 'date',
      weekday: '',
      date: '',
      hour: ""
    },
    fetchedAddress: {
      street: "",
      number: "",
      state: "",
      neighborhood: "",
      city: "",
      complement: '',
      reference_point: '',
      zip_code: '',
      kml: {
        layer: '',
        map: '',
        polygon: ''
      }
    }
  });


  const fetchDates = async () => {

    try {
      if (address) {
        setBooleans((booleans) => ({
          ...booleans,
          loadingDates: true,
        }))

        const fetchedData = await getCollectRangeCep({ zip_code: address.zip_code }).then((fetchedData: any) => fetchedData ? fetchedData.data.data : null)

        const mountedAddress: FormValuesAdressType = {
          city: address.city,
          complement: address.complement,
          neighborhood: address.neighborhood,
          number: address.number,
          state: address.state,
          street: address.address,
          reference_point: "N/A",
          zip_code: address.zip_code,
          kml: fetchedData ? fetchedData.kml : {
            layer: '',
            map: '',
            polygon: ''
          }
        }

        setObjects((prevState) => ({
          ...prevState,
          fetchedAddress: mountedAddress,
        }))

        const state = getZipcodeStateConverted({ address: { ...mountedAddress } });
        const formattedSkus = mountSkusProducts(patients ?? [])

        const response: CalendarDateType = await fetchDatesHomeCollect({
          person_id: payer.id,
          polygon: mountedAddress.kml.polygon,
          sku: formattedSkus,
          state
        });

        setArrays((arrays) => ({
          ...arrays,
          dates: response
        }))
        setBooleans((booleans) => ({
          ...booleans,
          loadingDates: false,
        }));
      }
    } catch (err) {
      toast.error('Falha ao consultar Datas para Agendamento.')
    }
  }

  useEffect(() => {
    if (address) {
      fetchDates()
    }
  }, [])


  const handleSelectCalendar = async (appointment: AppointmentScheduleType) => {

    try {
      setObjects((objects) => ({
        ...objects,
        appointment
      }));
      setBooleans((booleans) => ({
        ...booleans,
        loadingHours: true
      }))

      const state = getZipcodeStateConverted({ address: { ...objects.fetchedAddress } });
      const formattedSkus = mountSkusProducts(patients ?? [])

      const response: CalendarHourType = await fetchHoursHomeCollect({
        polygon: objects.fetchedAddress.kml.polygon,
        sku: formattedSkus,
        date: appointment.query_date,
        state
      });

      setBooleans((booleans) => ({
        ...booleans,
        loadingHours: false
      }))

      setArrays((arrays) => ({
        ...arrays,
        hours: response
      }))
    } catch (err) {
      toast.error('Falha ao consultar Horários para Agendamento.')
    }
  }

  const handleSelectHour = async (appointment: AppointmentScheduleType) => {
    setObjects((objects) => ({
      ...objects,
      appointment: {
        ...objects.appointment,
        appointment_id: appointment.appointment_id,
        calendar_id: appointment.calendar_id,
        query_hour: appointment.query_hour,
        hour: appointment.hour
      }
    }))
  }

  const handleReschedule = async () => {
    try {

      setBooleans((prevState) => ({
        ...prevState,
        loadingReschedule: true
      }));


      const fullName = payer.name;
      const [firstName, ...lastNameParts] = fullName.split(" ");
      const lastName = lastNameParts.join(" ");


      // const scheduleAddress: UpdateScheduleType = {
      //   firstName,
      //   lastName,
      //   phone: payer.phone,
      //   email: payer.email,
      //   protocol: String(order_id),
      //   order_voucher: String(order_id),
      //   cep: address?.zip_code ?? "",
      //   street: address?.address ?? "",
      //   number: address?.number ?? "",
      //   complement: address?.complement ?? "",
      //   neighborhood: address?.neighborhood ?? "",
      //   city: address?.city ?? "",
      //   state: address?.state ?? "",
      //   appointmentType: {
      //     id: appointmentTypeId
      //   }
      // }

      await rescheduleOrder(schedule_id, objects.appointment.query_hour);
      // await updateSchedule(scheduleAddress, schedule_id)

      if (address) {
        setRescheduleAddress({
          ...rescheduleAddress,
          date: moment(objects.appointment.query_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          hour: `${objects.appointment.hour} às ${moment(objects.appointment.hour, "HH:mm").add(1, 'hour').format("HH:mm")}`,
          fullAddress: `${address.address}, ${address.number} - ${address.neighborhood} - ${address.city} - ${address.state}`
        })
      }

      setBooleans((prevState) => ({
        ...prevState,
        loadingReschedule: false
      }));

      closeModal(true);
    } catch (err) {
      handleOpenBlockedSchedule();
      setBooleans((prevState) => ({
        ...prevState,
        loadingReschedule: false
      }));
    }
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => closeModal(false)}
      modalIcon=''
      closeIcon={false}
      renderHeading={() => (
        <h2 style={{ textAlign: 'center' }}>
          {" "}
          Reagendar atendimento
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          Selecione a data e horário desejados para o atendimento domiciliar no <strong>CEP: {address?.zip_code}</strong>:
        </p>
      )}
      renderActions={() => (
        <Box>
          <div style={{ maxWidth: '800px', marginBottom: '1em' }}>
            <CollectCalendarReschedule
              dates={arrays.dates}
              hours={arrays.hours}
              loadingDate={booleans.loadingDates}
              loadingHours={booleans.loadingHours}
              appointment={objects.appointment}
              handleSelectDate={handleSelectCalendar}
              handleSelectHour={handleSelectHour}
            />
          </div>


          <Box display="flex" direction="column" >
            <Button
              onClick={() => handleReschedule()}
              width="full"
              loading={booleans.loadingReschedule}
              disabled={!!(objects.appointment.appointment_id === '' || objects.appointment.hour === '')}
              minHeight="3.7em"
              fontWeight="600"
            >
              CONFIRMAR AGENDAMENTO
            </Button>
            <Button width="full" outlined onClick={() => handleBackModal()} minHeight="3.7em" fontWeight="600">
              {" "}
              Voltar
            </Button>
          </Box>
        </Box >
      )}
    />
  )
}