import React from "react";

import { getIn } from "formik";

const CheckboxControlled = ({
  name,
  label,
  descriptionText,
  value,
  required,
  purple,
  onChange,
  small,
  block,
  readOnly,
  field,
  form,
}) => {
  const error = getIn(form.errors, field.name);
  return (
    <div
      className={`
		field-holder
		checkbox-field 
		field-checkbox-holder
		${error ? "field-holder-invalid" : ""}
		${small ? "field-holder-small" : ""}
		${block ? "field-holder-block" : ""}
        `}
    >
      {label && (
        <label className={`text-gray text-semibold ${purple ? "_purple" : ""}`}>
          {label}
        </label>
      )}
      <label className="checkbox-holder">
        <input
          type="checkbox"
          className="input _checkbox"
          name={name}
          id={name}
          checked={field?.value}
          required={required ? "required" : ""}
          disabled={readOnly}
          {...field}
          onChange={(event) => {
            if (onChange) {
              return onChange(event);
            }
            return field.onChange(event);
          }}
        />
        <span className="box-check" />
        {descriptionText}
      </label>
      {error && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default CheckboxControlled;
