export const INITIAL_VALUES = {
  covenant: "",
  external_link: "",
  description: "",
  covenant_channels: [],
  file: null,
  docs: [],
  covenant_checklist: [],
  units: {
    include: [0],
    exclude: [],
  },
  covenant_plans: [],
  access: "",
  contact: "",
  free_visit: false,
  home_collect_permission: false,
};
