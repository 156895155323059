import {
  SELECT_PACIENT,
  SELECT_ORDER,
  SELECT_PLOTS,
  REMOVE_PLOTS,
  SELECT_COVENANT_ID,
  CLEAR_COVENANT,
  BUDGET,
  RESET_BUDGET,
} from "Actions/ActionsTypes";

const defaultState = {
  pacient: {},
  order: {},
  covenantInfo: null,
  budget: {},
};

export default function ServiceForm(state = defaultState, action) {
  switch (action.type) {
    case SELECT_PACIENT:
      return {
        ...state,
        pacient: action.payload,
      };

    case SELECT_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case SELECT_PLOTS:
      return {
        ...state,
        order: {
          paymentDetails: action.payload,
        },
      };

    case SELECT_COVENANT_ID:
      return {
        ...state,
        covenantInfo: action.payload,
      };

    case CLEAR_COVENANT:
      return {
        ...state,
        covenantInfo: null,
      };

    case BUDGET:
      return {
        ...state,
        budget: {
          ...state.budget,
          ...action.payload,
        },
      };

    case RESET_BUDGET:
      return {
        ...state,
        budget: null,
      };

    case REMOVE_PLOTS:
      return {
        ...state,
        order: {
          paymentDetails: action.payload,
        },
      };

    default:
      return state;
  }
}
