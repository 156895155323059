import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    color: var(--color-purple);
    margin: 0;
  }

  p {
    margin-bottom: 0;
    padding: 32px 0;
    font-family: var(--font-text);
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #979797;
  }

  button {
    padding: 9px 24px;
    border-radius: 30px;
    min-width: 150px;

    img {
      width: 30px !important;
    }
  }
`;
