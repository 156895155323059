import React from "react";

import { TextModal, Olist } from "Pages/RegistrationCleaning/styles";

export const SendMotionModal = () => {
  return (
    <TextModal className="black" fontWeight={400}>
      <span>
        O cliente solicitante, possui mais de uma pessoa cadastrada em seu CPF.
        Para prosseguir com o atendimento deste cliente você precisa realizar o
        ajuste de cadastro.
      </span>
      <Olist className="with-bg">
        <li>Identificar o(s) cadastro(s) correto(s) do cliente.</li>
        <li>
          Realizar a limpeza do cadastro, desvinculando os nomes ao CPF do
          cliente.
        </li>
        <li>
          Conferir os dados do cliente, validando as informações de{" "}
          <b>identidade</b> e <b>contato</b>.
        </li>
      </Olist>
    </TextModal>
  );
};
