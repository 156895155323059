import { ReactNode } from "react";

import { Box } from "styles/globalComponents";

interface ModalIconProps {
  children: ReactNode;
}

export const ModalIcon = ({ children }: ModalIconProps) => {
  return (
    <Box display="flex" justify="center" mb="32">
      {children}
    </Box>
  );
};
