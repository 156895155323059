import React, { useState } from "react";

import { useCategories } from "Hooks/useCategories";
import { useProducts } from "Hooks/useProducts";
import { useSubCategories } from "Hooks/useSubCategories";

import { validateArrayType } from "Utils/Arrays";
import Slugify from "Utils/Slugify";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { ErrorMessage } from "Components/ErrorMessage";
import Input from "Components/Form/Input";
import Select from "Components/Form/Select";
import { LoadingText } from "Components/LoadingText";
import PageHeader from "Components/Pages/Header";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import ProductListIcon from "Assets/Images/list-products.svg";

import { ListProductsItems } from "./Components/ListProducts";
import { ORDER_BY, STATUS_PRODUCT } from "./helperData";
import { ModalViewProduct } from "./Modals/ViewProduct";
import * as S from "./style";

const ListProducts = () => {
  const [openProductIndoModal, setOpenProductInfoModal] = useState(false);
  const [selectIdProduct, setSelectIdProduct] = useState(null);

  const [searchFilters, setSearchFilters] = useState({
    search: "",
    categories: "",
    subcategories: "",
    status_id: "",
    order_type: "",
  });

  const {
    productsList,
    productsError,
    loadingProducts,
    productsPagination,
    pageSelected,
    fetchNewPage,
    setProductFilters,
  } = useProducts({ enableReq: true, searchBudget: false });

  const { setNewCategory, categories, loadingCategories, categorySelected } =
    useCategories();

  const { subcategoriesList, loadingSubcategories } = useSubCategories(
    categorySelected.id
  );

  const {
    categories: categoriesFilter,
    status_id,
    search,
    order_type,
    subcategories,
  } = searchFilters;

  function handleClearFilters() {
    setSearchFilters({
      search: "",
      categories: "",
      subcategories: "",
      status_id: "",
      order_type: "",
    });
    setProductFilters({});
    fetchNewPage(1);
  }

  function handlePagination(e) {
    if (pageSelected === e.currentPage) return;

    fetchNewPage(e.currentPage);
  }

  const setUrlQueries = (filtersParams) => {
    setProductFilters(filtersParams);
  };

  const createFiltersObj = (objReplace = {}) => {
    return {
      categories: Slugify(categoriesFilter),
      status_id,
      search,
      order_type,
      subcategories,
      ...objReplace,
    };
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!search && !categoriesFilter && !status_id) {
      createToast("error", "Selecione no mínimo um filtro para sua busca.");
      return;
    }

    const filtersParams = createFiltersObj({ order_type: "" });
    setSearchFilters((prevState) => ({ ...prevState, order_type: "" }));
    setUrlQueries(filtersParams);
    fetchNewPage(1);
  }

  function openModal() {
    setOpenProductInfoModal(true);
  }

  function closeModal() {
    setOpenProductInfoModal(false);
  }

  const handleChangeAlphabeticOrder = (value) => {
    setSearchFilters((prevState) => ({ ...prevState, order_type: value }));

    const filtersParams = createFiltersObj({ order_type: value });

    setUrlQueries(filtersParams);
  };

  function handleSelectProduct(productId) {
    setOpenProductInfoModal(true);
    setSelectIdProduct(productId);
  }

  return (
    <S.PageContainer className="entry-app-content">
      <div className="content-holder">
        <div>
          <div className="wrapper-header-content">
            <PageHeader title="Produtos" icon={ProductListIcon}>
              <Breadcrumb
                list={[
                  {
                    path: "/produtos",
                    label: "Produtos",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            {loadingProducts && (
              <LoadingText text="Carregando lista de produtos" />
            )}

            {!loadingProducts && productsError && (
              <ErrorMessage text={productsError} />
            )}

            {!loadingProducts && !productsError && (
              <>
                <S.FormContainer onSubmit={handleSubmit}>
                  <div className="filters">
                    <div className="input-container search">
                      <Input
                        id="search"
                        type="search"
                        value={searchFilters.search}
                        placeholder="Buscar por nome ou código"
                        onChange={(e) =>
                          setSearchFilters({
                            ...searchFilters,
                            search: e.target.value,
                          })
                        }
                      />
                    </div>
                    {categories && !loadingCategories && (
                      <div className="input-container category">
                        <Select
                          id="categories"
                          options={categories}
                          valueKey="category"
                          labelKey="category"
                          optionName="category"
                          value={searchFilters.categories}
                          placeholder="Categorias"
                          onChange={(e) => {
                            setSearchFilters({
                              ...searchFilters,
                              categories: e.target.value,
                              subcategories: "",
                            });
                            setNewCategory({
                              category: "",
                              id: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}

                    {searchFilters.categories &&
                      validateArrayType(subcategoriesList) &&
                      !loadingSubcategories && (
                        <div className="input-container subcategory">
                          <Select
                            id="sub-categories"
                            type="text"
                            options={subcategoriesList}
                            valueKey="id"
                            labelKey="name"
                            optionName="subcategory"
                            value={searchFilters.subcategories}
                            placeholder="Subcategoria"
                            onChange={(e) => {
                              setSearchFilters({
                                ...searchFilters,
                                subcategories: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}

                    <div className="input-container status">
                      <Select
                        id="status"
                        type="text"
                        options={STATUS_PRODUCT}
                        valueKey="status"
                        labelKey="status"
                        value={searchFilters.status_id}
                        placeholder="Status"
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            status_id: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="buttons-container">
                    <Button
                      type="button"
                      className=" _transparentButton _alt"
                      onClick={handleClearFilters}
                      outlined
                      borderRadius="111"
                    >
                      Limpar
                    </Button>
                    <Button
                      type="submit"
                      borderRadius="111"
                      onClick={handleSubmit}
                    >
                      Filtrar
                    </Button>
                  </div>

                  <div className="order-by">
                    <span>Ordenar por:</span>
                    <Select
                      id="order_type"
                      options={ORDER_BY}
                      valueKey="name"
                      labelKey="name"
                      value={searchFilters.order_type}
                      placeholder="Selecione"
                      onChange={(e) =>
                        handleChangeAlphabeticOrder(e.target.value)
                      }
                    />
                  </div>
                </S.FormContainer>

                {!productsList && (
                  <div className="text-center text-purple product-not-found">
                    Oops! Parece que nada foi encontrado...
                  </div>
                )}

                {productsList && (
                  <>
                    <ListProductsItems
                      productsList={productsList}
                      selectIdItem={handleSelectProduct}
                    />

                    {productsPagination?.total_pages && (
                      <Pagination
                        totalRecords={productsPagination?.total_pages}
                        currentPageSelected={pageSelected}
                        pageLimit={1}
                        pageNeighbours={1}
                        onPageChanged={(e) => handlePagination(e)}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ModalViewProduct
        openModal={openProductIndoModal}
        setOpenModal={openModal}
        closeModal={closeModal}
        idSelected={selectIdProduct}
      />
    </S.PageContainer>
  );
};

export default ListProducts;
