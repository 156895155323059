import React, { useState, useRef } from "react";

import Dropzone from "./Dropzone";
import ProgressBar from "./ProgressBar";

const UploadInsurance = (props) => {
  const [uploading] = useState(false);
  const [successUpload] = useState(false);
  const [uploadProgress] = useState({});

  const uploadProgressRef = useRef(uploadProgress);

  function _renderValidateFiles(file) {
    let message;

    switch (uploadProgressRef.current[file.name].state) {
      case "upload_done":
        message = (
          <span className="text-success">
            Arquivo importado com sucesso. Aguarde enquanto processamos o
            arquivo...
          </span>
        );
        break;
      case "done":
        message = (
          <span className="text-success">Arquivo processado com sucesso!</span>
        );
        break;
      case "upload_error":
        message = (
          <span className="text-danger">
            Oops, essa não! Parece que tivemos um problema para importar o seu
            arquivo...
          </span>
        );
        break;
      case "error":
        message = (
          <span className="text-danger">
            Oops, essa não! Parece que tivemos um problema para processar o seu
            arquivo...
          </span>
        );
        break;
      default:
        message = (
          <span className="text-gray">
            Aguarde enquanto importamos o seu arquivo...
          </span>
        );
        break;
    }

    return <div className="upload-feedback">{message}</div>;
  }

  function _renderProgress(file) {
    const uploadProgressBar = uploadProgressRef.current[file.name];

    if (uploading || successUpload) {
      return (
        <div
          className={`
                    progress-wrapper
                    ${
                      uploadProgressBar && uploadProgressBar.state === "done"
                        ? "--success"
                        : ""
                    }
                `}
        >
          <ProgressBar
            progress={uploadProgressBar ? uploadProgressBar.percentage : 0}
          />
        </div>
      );
    }
  }
  return (
    <div
      className="uploadInsurance-area fixWidth"
      style={{ marginBottom: "2rem" }}
    >
      <div className="content">
        <Dropzone
          cssName={`fixWidth ${props.error && "invalid"}`}
          fileAccept={props.fileAccept}
          multi={props.multi}
          onFilesAdded={props.sendFormData}
          disabled={uploading || successUpload}
          elementDescr={
            <div className="upload-area-info">
              Arraste ou clique aqui para selecionar a logo
            </div>
          }
        />
      </div>

      <div className="file-info">
        {props.files &&
          props.files?.map((file) => {
            return (
              <div className="file-upload" key={file.name}>
                <div className="info">
                  <p>
                    <strong>Nome do arquivo:</strong>
                    {file.name}
                  </p>
                  <p>
                    <strong>Formato:</strong>
                    {file.type}
                  </p>
                </div>
                {_renderProgress(file)}
                {uploadProgressRef.current[file.name] &&
                  _renderValidateFiles(file)}
              </div>
            );
          })}
      </div>
      <div className="actions">{/* _renderActions() */}</div>
    </div>
  );
};

UploadInsurance.defaultProps = {
  multi: false,
  fileAccept: ".xls, .xlsx, .csv",
};

export default UploadInsurance;
