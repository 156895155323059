import { Form, Formik } from "formik";
import { Box } from "styles/globalComponents";

import { useCovenants } from "Hooks/useCovenants";

import { Button } from "Components/Button";

import { Container } from "../CompleteRegister/style";
import { CovenantInputFields } from "../CovenantInputFields";
import { CovenantForm as CovenantFormPropps } from "../CovenantInputFields/interface";
import { covenantSchema } from "../schemas";
import { CovenantFormProps } from "./interface";

export const CovenantForm = ({
  closeModal,
  covenantSelected,
  onSubmit,
}: CovenantFormProps) => {
  const { loading } = useCovenants();

  const { covenant, covenant_plan, credential_number } = covenantSelected ?? {};

  const initialValues = {
    covenant: covenant?.id || null,
    plan: covenant_plan?.id || null,
    plan_card_number: credential_number || null,
  };

  const submit = async (values: CovenantFormPropps) => {
    try {
      await onSubmit(values);
    } catch (error) {
      throw new Error("Não foi possível executar esta ação no momento!");
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submit(values).then().catch()}
        validationSchema={covenantSchema}
      >
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          const covenantErrors = {
            plan: errors.plan,
            covenant: errors.covenant,
            plan_card_number: errors.plan_card_number,
          };

          const covenantTouched = {
            plan: touched.plan,
            covenant: touched.covenant,
            plan_card_number: touched.plan_card_number,
          };

          const covenantValues = {
            plan: values.plan,
            covenant: values.covenant,
            plan_card_number: values.plan_card_number,
          };

          return (
            <Form className="form-container">
              <div className="form-items">
                <CovenantInputFields
                  errors={covenantErrors}
                  touched={covenantTouched}
                  values={covenantValues}
                  onChange={setFieldValue}
                />
              </div>
              <Box display="flex" direction="column" mt="16">
                <Button
                  width="full"
                  minHeight="48"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || loading}
                >
                  SALVAR CONVẼNIO
                </Button>

                <Button
                  width="full"
                  minHeight="48"
                  outlined
                  onClick={closeModal}
                  loading={isSubmitting}
                  disabled={isSubmitting || loading}
                >
                  CANCELAR
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
