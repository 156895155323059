import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const SelectSearch = ({
  id,
  label,
  value,
  error,
  required = false,
  purple = false,
  onChange,
  placeholder,
  options,
  small = false,
  readOnly,
  loading,
  isMulti,
  isMoreSelect = true,
  valueKey,
  labelKey,
  isCreatable = false,
  description = "",
}) => {
  function getValue(opts, val) {
    return opts.filter((o) => val === o.value);
  }

  const createOption = (label) => ({
    [valueKey]: label.toLowerCase(),
    [labelKey]: label,
  });

  const addItemOnSelect = (selectedOption) => {
    !isMulti
      ? onChange({
          target: {
            name: id,
            value: selectedOption.value,
          },
        })
      : onChange(selectedOption);
  };

  const handleKeyDown = (event) => {
    const inputValue = event.target.value;

    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab": {
        const optionCreated = createOption(inputValue);

        addItemOnSelect(value.concat([optionCreated]));

        event.preventDefault();
        break;
      }
      default:
        return;
    }
  };

  return (
    <div
      className={`
            field-holder 
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple && "_purple"}`}
        >
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      {isCreatable ? (
        <CreatableSelect
          classNamePrefix="react-select"
          className="react-select input _opacity-gray"
          id={id}
          value={!isMulti ? getValue(options, value) : value}
          onChange={(selectedOption) => addItemOnSelect(selectedOption)}
          onKeyDown={handleKeyDown}
          isMulti={isMulti}
          required={required && "required"}
          isDisabled={readOnly}
          isLoading={loading}
          options={options}
          placeholder={placeholder}
          isSearchable
          loadingMessage="Carregando opções..."
          noOptionsMessage={() => "Nenhuma opção encontrada"}
          formatCreateLabel={(inputValue) => `Criar ${inputValue}...`}
          getOptionValue={(option) => option[valueKey]}
          getOptionLabel={(option) => option[labelKey]}
        />
      ) : (
        <Select
          menuPosition="fixed"
          classNamePrefix="react-select"
          className="react-select input _opacity-gray"
          id={id}
          value={!isMulti ? getValue(options, value) : value}
          onChange={(selectedOption) => {
            !isMulti
              ? onChange({
                  target: {
                    name: selectedOption[labelKey],
                    value: selectedOption[valueKey],
                  },
                })
              : onChange(selectedOption);
          }}
          isMulti={isMoreSelect}
          required={required && "required"}
          isDisabled={readOnly}
          isLoading={loading}
          options={options}
          placeholder={placeholder}
          isSearchable
          loadingMessage="Carregando opções..."
          noOptionsMessage={() => "Nenhuma opção encontrada"}
          getOptionValue={(option) => option[valueKey]}
          getOptionLabel={(option) => option[labelKey]}
        />
      )}
      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default SelectSearch;
