import {
  DocumentsProps,
  PhonesPersonProps,
  EmailsProps,
} from "Pages/ServiceForm/SelectPacient/interface";

import { unmaskCEP, unmaskCPF, unmaskPhone } from "Utils/Masks";

import { AddressType } from "./interface";

export const FormatEmail = (email: EmailsProps[] = []) => {
  const emails = email?.map((item, i: number) => {
    if (!item.email) return null;

    delete item?.person_id;
    delete item?.registered_by;
    delete item?.responsible_id;

    return {
      ...item,
      channel_id: item.channel_id || 2,
    };
  });

  return emails.filter((e) => e !== null);
};

export const FormatAddress = (addresses: AddressType[] = []) => {
  const newAddress = addresses?.map((item) => {
    if (!item.zip_code) return null;
    if (!Object.values(item).length) return null;

    delete item.address_type;
    delete item.country;
    delete item.created_at;
    delete item.updated_at;
    delete item.person_id;
    delete item.reference_point;
    delete item.registered_by;
    delete item.type;
    delete item.responsible_id;

    if (!item.complement) delete item.complement;
    return {
      ...item,
      zip_code: unmaskCEP(item.zip_code) || null,
      type_id: Number(item.type_id) || 0,
      country_id: Number(item.country_id) || 0,
      default: item.default || false,
      channel_id: item.channel_id || 2,
    };
  });

  const formatedAddress = newAddress.filter(Boolean) as AddressType[];

  const modifyAddress = setFirstAddressDefault(formatedAddress);

  return modifyAddress;
};

const setFirstAddressDefault = (addresses: AddressType[]) => {
  const hasDefaultAddress = addresses.some(
    (address) => address.default === true
  );

  if (!hasDefaultAddress) {
    const modifyFirstAddress = addresses.map((address, index) => {
      if (index === 0) {
        return { ...address, default: true };
      }
      return address;
    });

    return modifyFirstAddress;
  }

  return addresses;
};

export const FormatPhones = (phones: PhonesPersonProps[] = []) => {
  const _phone: PhonesPersonProps[] = [];

  // Remover a const e deixar apenas o forEach
  const _phones = phones?.forEach((item) => {
    if (!item.phone) return null;

    delete item.person_id;
    delete item.registered_by;

    const newDDD = String(item.ddd).replace("(", "").replace(")", "");

    _phone.push({
      phone: Number(unmaskPhone(item.phone)),
      ddd: Number(newDDD),
      ddi: Number(unmaskPhone(item.ddi)),
      channel_id: item.channel_id || 2,
      default: item.default,
      id: item.id ? item.id : undefined,
    });
  });

  return _phone.filter((e) => e !== null);
};

export const FormatDocuments = (documents: DocumentsProps[] = []) => {
  const docs: DocumentsProps[] = [];

  documents.forEach((item) => {
    if (item.number) {
      delete item.person_id;
      delete item.registered_by;
      delete item.country;
      if (item.type === "cpf") delete item.country_id;
      if (item.country_id) item.country_id = Number(item.country_id);
      docs.push({ ...item, number: unmaskCPF(item.number) });
    }
  });

  return docs;
};

export function hasAsyncErrors(errors: Error[]) {
  return errors.map((error) => {
    const [field, message] = error.message.split(":");
    return {
      field,
      message: message.trim(),
    };
  });
}
