import { useState } from "react";
import { useQuery } from "react-query";

import { getProducts } from "Requests/Products";

import { validateArrayType } from "Utils/Arrays";
import { createToast } from "Utils/toastFunc";

import {
  ErrorProps,
  ProductsData,
  ProductsProps,
  UseProductsReturn,
} from "./interface";

export const useProducts = ({
  searchBudget = true,
  enableReq,
  initialFilters,
}: ProductsProps): UseProductsReturn => {
  const [pageSelected, setPageSelected] = useState(1);
  const [filters, setFilters] = useState<Object>(initialFilters || {});
  const { data, isLoading, isError, error } = useQuery<
    ProductsData,
    ErrorProps
  >(
    ["products", filters, pageSelected],
    () =>
      getProducts({
        page: pageSelected,
        filters,
        searchBudget,
      }),
    {
      enabled: enableReq || Object.keys(filters).length > 0,
      refetchOnWindowFocus: false,
      onError: (errorRes: ErrorProps) => createToast("error", errorRes.message),
    }
  );

  const setNewPage = (page: number) => {
    setPageSelected(page);
  };

  const setProductFilters = (searchFilter: Object) => {
    setFilters({ ...searchFilter });
  };

  return {
    productsList: validateArrayType(data?.data) ? data?.data : undefined,
    loadingProducts: isLoading,
    productsPagination: data?.pagination,
    productsError: isError ? error.message : null,
    pageSelected,
    fetchNewPage: setNewPage,
    setProductFilters,
  };
};
