import LoadingPurple from "Assets/Images/tinyficated/loading-purple.gif";
import { CollectCallendarRescheduleProps } from "./types";
import { RescheduleSliderDates } from "../SlideDates";
import { StyledText } from "./styles";
import { RescheduleSliderHour } from "../SliderHours";



export const CollectCalendarReschedule = ({
  dates,
  hours,
  appointment,
  loadingDate,
  loadingHours,
  handleSelectDate,
  handleSelectHour,
}: CollectCallendarRescheduleProps) => {

  return (
    <>
      <StyledText>Selecione a Nova Data:</StyledText>

      {loadingDate ?
        <div className="wrapper-loading">
          <img
            className="loading loading-small loading-align-center"
            src={LoadingPurple}
            alt="Labi Saúde - Carregando"
            loading="lazy"
          />
        </div>
        : <RescheduleSliderDates
          handleSelect={handleSelectDate}
          appointment={appointment}
          dates={dates}
          loadingDate={loadingDate}
          type="date"
          query="query"
        />}

      {loadingHours ?
        <div className="wrapper-loading">
          <img
            className="loading loading-small loading-align-center"
            src={LoadingPurple}
            alt="Labi Saúde - Carregando"
            loading="lazy"
          />
        </div> :
        <>
        </>}

      {appointment.date && !loadingHours && hours.length > 0 ? <>
        <StyledText>SELECIONE O NOVO HORÁRIO:</StyledText>
        <RescheduleSliderHour
          handleSelect={handleSelectHour}
          appointment={appointment}
          hours={hours}
          loadingHour={loadingHours}
          type="hour"
          query="query"
        /></> : <></>}

    </>
  )

}