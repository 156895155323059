import React from "react";

import { formatBRLCurrency } from "Utils/FormatCurrency";

import { Button } from "Components/Button";

import { ProductItem } from "./interface";
import * as S from "./styles";

export const ListItem = ({ product, selectId }: ProductItem) => {
  return (
    <S.Container key={product.id}>
      <td>{product.product}</td>
      <td>{product.sku}</td>

      <td>{product.category.category}</td>
      <td>{product.subcategories[0]?.subcategory || "-"} </td>

      <td>
        {product.default_partner?.price
          ? `R$ ${formatBRLCurrency(product.default_partner.price)}`
          : "R$ 0,00"}
      </td>

      <td>{product.status.status}</td>
      <td>
        <Button
          borderRadius="111"
          onClick={() => selectId(product.id)}
          transparentHover
        >
          visualizar
        </Button>
      </td>
    </S.Container>
  );
};
