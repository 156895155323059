import React from "react";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { LoadingText } from "Components/LoadingText";

import { AttendmentsCartList } from "../AttendmenList";
import { CartActions } from "../CartActions";
import { CartSummary } from "../CartSummary";
import { TotalCartDetails } from "../CartTotalDetails";
import { CartCovenant } from "./CartCovenant";
import { CartDetailsProps } from "./interface";

export default function CartDetails({ children }: CartDetailsProps) {
  const { budget, loadingBudget } = useBudgetContext();

  return (
    <div className="cart-information-resume">
      {!!budget?.cart.length && !loadingBudget && (
        <div className="cart-details-container">
          <div className="cart-details-container-content">
            <CartSummary />

            <AttendmentsCartList />

            {budget.is_covenant && <CartCovenant />}

            <TotalCartDetails />

            <CartActions />

            {children}
          </div>
        </div>
      )}

      {(!budget || (budget && budget?.cart?.length < 1)) && !loadingBudget && (
        <h5 className="title-cart text-left purpletext text-separate-text">
          Resumo: <b>orçamento vazio</b>
        </h5>
      )}

      {loadingBudget && !budget && <LoadingText text="Carregando orçamento" />}

      {loadingBudget && budget && (
        <LoadingText text="Recalculando orçamento, aguarde !" />
      )}
    </div>
  );
}
