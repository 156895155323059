import React from "react";
import { CgClose } from "react-icons/cg";

import { Field, FieldArray, useFormikContext } from "formik";
import { Box } from "styles/globalComponents";

import { SUBCATEGORIES } from "Constants/Categories";

import { useProductCombos } from "Hooks/useProductCombos";

import { hasPathInArray } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";
import { Button } from "Components/Button";
import { CheckboxList } from "Components/CheckboxList";
import SwitchInputControlled from "Components/Form/Controlled/SwitchInputControlled";
import { CustomCheckbox } from "Components/Form/CustomCheckbox";
import Input from "Components/Form/Input";
import InputCurrency from "Components/Form/InputCurrency";
import Select from "Components/Form/Select";

import { FormValuesProps, HandleChangeFields, Partners } from "../../interface";
import { deadlines } from "./helperData";
import { Container } from "./styles";

export const IdentificationForm = ({
  handleCheckbox,
  setSelectField,
}: HandleChangeFields) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldError,
    setFieldTouched,
  } = useFormikContext<FormValuesProps>();

  const { category_id } = values;

  const categoryId = String(category_id.id);

  const { combos } = useProductCombos({ includeCombo: ["*"] });

  const selectedPartners = values.partners.map((partner) => partner.partner_id);

  const partnersAvaliable = combos?.partners.filter(
    (partner) => !selectedPartners.includes(partner.id)
  );

  const getPartnerName = (partnerId: number) => {
    return combos?.partners.find((partner) => partner.id === partnerId)?.list;
  };

  const partnersOptions = (partner: Partners) => {
    if (partnersAvaliable) {
      return [
        ...partnersAvaliable,
        {
          id: partner.partner_id,
          list: getPartnerName(partner.partner_id),
        },
      ].filter((partnerAv) => partnerAv.id);
    }
  };

  const blockDeletePartner = (partnerIndex: number) => {
    setFieldError(`partners.${partnerIndex}.partner_id`, "Campo obrigatório");
    setFieldTouched(`partners.${partnerIndex}.partner_id`, true, false);
  };

  const error: any = errors.partners;

  return (
    <AccordionCollapse title="Identificação">
      <Container>
        <div className="input-container w-100 ">
          <Field
            name="product"
            id="product"
            label="Nome do produto*"
            component={Input}
            onBlur={handleBlur}
            value={values.product}
            onChange={handleChange}
            error={touched.product && errors.product && errors.product}
            placeholder="Digite o nome do produto"
          />
        </div>
        <div className="input-container w-100 ">
          <Field
            id="slug"
            name="slug"
            label="Slug*"
            component={Input}
            onBlur={handleBlur}
            value={values.slug}
            onChange={handleChange}
            error={touched.slug && errors.slug && errors.slug}
            placeholder="Digite o slug"
          />
        </div>
        <div className="input-container w-100">
          <Field
            name="product_description.synonym"
            id="product_description.synonym"
            value={values.product_description.synonym}
            onChange={handleChange}
            label="Sinonímia"
            type="text"
            component={Input}
            error={errors.product_description?.synonym}
            placeholder="Digite a sinonímia do produto"
          />
        </div>

        <div className="input-container w-50 ">
          <Field
            name="sku"
            id="sku"
            value={values.sku}
            label="Código Site/Colabi (SKU)*"
            error={touched.sku && errors.sku && errors.sku}
            onChange={handleChange}
            onBlur={handleBlur}
            component={Input}
            placeholder="Digite o sku do produto"
          />
        </div>
        <div className="input-container w-50 ">
          <Field
            name="mt_code"
            id="mt_code"
            type="number"
            value={values.mt_code}
            label="CÓDIGO MOTION"
            error={touched.mt_code && errors.mt_code && errors.mt_code}
            onChange={handleChange}
            onBlur={handleBlur}
            component={Input}
            placeholder="Digite o código motion do produto"
          />
        </div>

        {values?.subcategories?.includes(
          Number(SUBCATEGORIES.vaccine_club)
        ) && (
          <div className="input-container w-100 ">
            <Field
              name="rule.commercial_code"
              id="rule.commercial_code"
              type="number"
              value={values.rule.commercial_code}
              label="Cliente comercial motion"
              error={
                touched.rule?.commercial_code &&
                errors.rule?.commercial_code &&
                errors.rule?.commercial_code
              }
              onChange={handleChange}
              onBlur={handleBlur}
              component={Input}
              placeholder="Digite o código comercial"
            />
          </div>
        )}

        <FieldArray
          name="partners"
          render={({ push, remove }) => {
            return (
              <>
                {values.partners.length >= 1 &&
                  values.partners.map((partner, index) => (
                    <div
                      className="w-100 partner-container "
                      key={`parceiro-${partner.partner_id || `id${index}`}`}
                    >
                      <div className="w-50 deadline-info align-items-end">
                        <Field
                          name={`partners.${index}.partner_id`}
                          id={`partners.${index}.partner_id`}
                          label="Preço*"
                          options={partnersOptions(partner)}
                          value={partner.partner_id}
                          optionName="list"
                          onChange={setSelectField}
                          onBlur={handleBlur}
                          component={Select}
                          placeholder="Selecione o parceiro"
                          error={
                            touched.partners?.[index]?.partner_id &&
                            error?.[index]?.partner_id &&
                            error?.[index]?.partner_id
                          }
                        />

                        <Field
                          name={`partners.${index}.price`}
                          id={`partners.${index}.price`}
                          onChange={handleChange}
                          value={partner.price}
                          label=""
                          component={InputCurrency}
                          onBlur={handleBlur}
                          placeholder="R$ 0,00"
                          error={
                            touched?.partners?.[index]?.price &&
                            error?.[index]?.price &&
                            error?.[index]?.price
                          }
                        />
                      </div>

                      {hasPathInArray([1, 2, 3, 6, 7], categoryId) && (
                        <div className="w-50 flex">
                          <div className="w-100">
                            <div
                              className="deadline-info align-items-end w-100 gap-20"
                              style={{ flex: 1 }}
                            >
                              <Field
                                name={`partners.${index}.term`}
                                id={`partners.${index}.term`}
                                value={partner.term ?? ""}
                                label="Prazo"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={Input}
                                placeholder="Digite o prazo do produto"
                                error={
                                  touched?.partners?.[index]?.term &&
                                  error?.[index]?.term &&
                                  error?.[index]?.term
                                }
                              />
                              <Field
                                name={`partners.${index}.result_id`}
                                id={`partners.${index}.result_id`}
                                onChange={setSelectField}
                                onBlur={handleBlur}
                                options={deadlines}
                                label=""
                                value={partner.result_id}
                                optionName="label"
                                component={Select}
                                placeholder="Selecione o prazo do produto"
                                error={
                                  touched?.partners?.[index]?.result_id &&
                                  error?.[index]?.result_id &&
                                  error?.[index]?.result_id
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {values.partners.length > 1 && (
                        <Button textButton onClick={() => remove(index)}>
                          <CgClose color="#999999" />
                        </Button>
                      )}
                    </div>
                  ))}

                <div className="input-container w-100">
                  <Button
                    textButton
                    type="button"
                    onClick={() => {
                      const lastPartnerAdded = values.partners.at(-1);

                      const index = values.partners.findIndex(
                        (partner) => !partner.partner_id
                      );

                      if (index !== -1) {
                        blockDeletePartner(index);
                      }

                      if (lastPartnerAdded?.partner_id) {
                        push({
                          price: 0,
                          result_id: undefined,
                          partner_id: undefined,
                          term: undefined,
                        });
                      }
                    }}
                  >
                    {" "}
                    + Adicionar novo preço de parceiro
                  </Button>
                </div>
              </>
            );
          }}
        />

        <CheckboxList
          error={touched.channels && errors.channels ? errors.channels : ""}
          title="Canais*"
        >
          <Box className="checkbox-list" mt="8">
            {combos?.channels.map((channel) => (
              <Field
                key={channel.id}
                type="checkbox"
                name="channels"
                component={CustomCheckbox}
                parse={Number(channel.id)}
                value={channel.id}
                label={channel.name}
                checked={values.channels.includes(channel.id)}
                onChange={handleCheckbox}
                onBlur={handleBlur}
              />
            ))}
          </Box>
        </CheckboxList>

        <CheckboxList
          title="Gêneros*"
          error={touched.genders && errors.genders ? errors.genders : ""}
        >
          <div className="checkbox-list">
            {combos?.genders.map((gender) => (
              <Field
                key={gender.id}
                type="checkbox"
                name="genders"
                onBlur={handleBlur}
                component={CustomCheckbox}
                value={gender.id}
                label={gender.sub_gender}
                checked={values.genders.includes(gender.id)}
                onChange={handleCheckbox}
              />
            ))}
          </div>
        </CheckboxList>

        <div className="sales-note-toggles w-100">
          <div className="sales-note w-50">
            <Field
              name="rule.max_items_cart"
              id="rule.max_items_cart"
              label="Quantidade de venda"
              value={values.rule.max_items_cart || ""}
              type="number"
              onChange={handleChange}
              component={Input}
              placeholder="Digite a quantidade de venda do produto"
            />
          </div>

          <div className="toggles  w-50">
            <Field
              name="rule.isolated_sale"
              id="rule.isolated_sale"
              label="venda isolada"
              textOn="Sim"
              textOff="Não"
              component={SwitchInputControlled}
            />

            {hasPathInArray([7, 8], categoryId) && (
              <Field
                name="rule.only_central"
                id="rule.only_central"
                label="Venda apenas via central"
                textOn="Sim"
                textOff="Não"
                component={SwitchInputControlled}
              />
            )}

            {!hasPathInArray([5, 7], categoryId) && (
              <Field
                name="rule.free_visit"
                id="rule.free_visit"
                textOn="Sim"
                textOff="Não"
                label="taxa de domiciliar gratuita"
                component={SwitchInputControlled}
              />
            )}
          </div>
        </div>
      </Container>
    </AccordionCollapse>
  );
};
