/* eslint-disable import/no-cycle */
import { SelectedPatientProducts } from 'Pages/Orders/CreateOrder/components/Patient/components/CardSelection/types';
import { CreateOrderType, PatientDependentType } from 'Pages/Orders/CreateOrder/components/Patient/components/types/index';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'

export type OrderType = {
  budget_id: number;
  budget_type: number;
  payer_patient: PatientType;
  appointment_type_id: string;
  lead: LeadType;
  attendances: AttendanceType[];
  itemsQuantity: number;
  totalPrice: string;
  homeCollectValue: string | number;
  isCovenant: boolean;
  discounts: number;
  subtotal: number;
  deliveryTime: string;
  items: ItemType[];
  product_categories: ProductCategoryType[],
  formattedAttendances: FormattedAttendanceType[],
  attendanceLabel: AttendanceLabel[],
  session_start: string;
  session_end: string;
  lastAddress: LastAddressType;
  dependents: PatientDependentType[];
  patients: PatientOrderType[];
  patientWithProducts: SelectedPatientProducts[];
  cupon_code: string | null;
  home_collected: boolean;
  home_collect_value: string;
  coupon_percentage_discount: number | null;
  orderData: CreateOrderType;
  isTaxInserted: boolean;
}

export type LastAddressType = {
  zip_code: string;
  uf?: string;
  country_id?: number;
  street: string;
  number: string;
  complement: string;
  reference_point: string;
  default?: boolean;
  channel_id?: number;
  city: string;
  neighborhood: string;
  type_id?: number;
}

export type FormattedAttendanceType = {
  ids_attendance: number[];
  type: CollectType;
  products_ids: number[];
  region_id: number;
  unity_id: number;
  id_scheduling: number;
  home_collect_date: string;
  calendar_id: string;
  unity_name: string;
  home_collect_hour: string;
}

type ProductCategoryType = {
  category_id: number;
  category_name: string;
  product_id: number;
  sub_categories: string[]
}

export type ItemType = {
  amount: number;
  budget_id: number;
  collect_type: CollectType;
  coupon_valid: boolean;
  id: number;
  item_unit_price: number;
  last_menstruation_period: string | null;
  product_code: string;
  product_id: number;
  product_name: string;
  sales_price: boolean;
  symptoms: string | null;
  total_discount_value: number;
  total_item_value: number;
  voucher: string | null;
  totalDisplay?: string;
  discountsDisplay?: string;
  category: string;
};

export type PatientType = {
  id: number;
  birthdate: string;
  document: DocumentType;
  email: string;
  gender_id: number;
  mother_name: string;
  name: string;
  phone: PhoneType;
  unborn: boolean
  social_name: string;
}

export type PatientOrderType = {
  id: number;
  index: number;
  birthdate: string;
  document: DocumentType;
  formattedPhone: string;
  email: string;
  gender_id: number;
  name: string;
  phone: PhoneType;
  unborn: boolean
  social_name: string;
  file?: File
  isPayer: boolean;
  isRequestor: boolean;
  family_relationship_id: number
  responsible_id: number;
  diseases: string;
  height: string;
  last_menstruation: string;
  medicament: string;
  mother_name: string;
  symptoms: string;
  weight: string;
  [key: string]: any;
}

type FamilyRelationshipType = {
  id: number;
  label: string
}

type LeadType = {
  id: number;
  name: string;
  ddd: number | null;
  email: string | null;
  phone: string
}

export type DocumentType = {
  id: number;
  number: string;
  type: TypeDocumentType;
  country_id: number;
}

type TypeDocumentType = '' | 'cpf' | 'passport';

type PhoneType = {
  id: number;
  ddd: number;
  ddi: number;
  phone: number;
}

type CurrentModalType = '' | 'incompleteRegister' | 'completeRegister' | 'successRegister'

export type CollectType = 'both' | 'unity_collect' | 'home_collect';

export type CurrentScreenType = 'collect' | 'identification' | 'payment'

export type CurrentComponent = 'defaultCollect' | 'homeCollect'

export type AttendanceType = {
  id: number;
  title: string;
  type: CollectType;
  items: AttendanceItemType[];
  region_id: number;
  unity_id: number;
  calendar_id: number;
  schedule_id: number;
}

export type AttendanceItemType = {
  budget_id: number;
  product_id: number;
  amount: number;
  product_name: string;
  product_code: string;
  item_unit_price?: number;
  checked: boolean;
}

export type AttendanceLabel = {
  label: string;
  unity: string;
  collect_address: string;
  ids_attendance: number[]
}

type BudgetOrderStore = {
  order: OrderType
  currentModal: CurrentModalType
  setCurrentModal: (value: CurrentModalType) => void
  setOrder: (value: OrderType) => void
  currentScreen: CurrentScreenType;
  setCurrentScreen: (value: CurrentScreenType) => void;
  currentComponent: CurrentComponent
  setCurrentComponent: (value: CurrentComponent) => void;
  enabledProceedCollect: boolean
  setEnabledProceedCollect: (value: boolean) => void;
  isFillingAddress: boolean
  setIsFillingAddress: (value: boolean) => void;
  enabledProceedIdentification: boolean
  setEnabledProceedIdentification: (value: boolean) => void;
  isViewBudget: boolean;
  setIsViewBudget: (value: boolean) => void;
  // isCreatingBudget: boolean;
  // setIsCreatingBudget: (value: boolean) => void;
}

type BudgetOrderLocalStore = {
  order: OrderType
  setOrder: (value: OrderType) => void
}

export const useBudgetOrder = create<BudgetOrderStore>((set) => ({
  currentModal: '',
  setCurrentModal: (value) => set({ currentModal: value }),
  currentScreen: 'collect',
  setCurrentScreen: (value) => set({ currentScreen: value }),
  currentComponent: 'defaultCollect',
  setCurrentComponent: (value) => set({ currentComponent: value }),
  enabledProceedCollect: false,
  setEnabledProceedCollect: (value) => set({ enabledProceedCollect: value }),
  enabledProceedIdentification: false,
  setEnabledProceedIdentification: (value) => set({ enabledProceedIdentification: value }),
  isFillingAddress: false,
  setIsFillingAddress: (value) => set({ isFillingAddress: value }),
  order: {
    budget_id: 0,
    appointment_type_id: "",
    payer_patient: {
      id: 0,
      birthdate: '',
      document: {
        id: 0,
        number: '',
        type: '',
        country_id: 26
      },
      email: '',
      gender_id: 0,
      mother_name: '',
      name: '',
      phone: {
        id: 0,
        ddd: 0,
        ddi: 0,
        phone: 0
      },
      unborn: false,
      social_name: ''
    },
    lead: {
      id: 0,
      ddd: null,
      phone: '',
      email: null,
      name: '',
    },
    attendances: [],
    itemsQuantity: 0,
    totalPrice: '0,00',
    budget_type: 1,
    coupon_percentage_discount: 0,
    homeCollectValue: 0,
    isCovenant: false,
    discounts: 0,
    subtotal: 0,
    deliveryTime: '',
    items: [],
    product_categories: [],
    formattedAttendances: [],
    session_start: "",
    session_end: "",
    lastAddress: {
      street: "",
      number: "",
      uf: "",
      neighborhood: "",
      city: "",
      channel_id: 4,
      complement: '',
      country_id: 26,
      default: true,
      reference_point: '0',
      type_id: 1,
      zip_code: ''
    },
    attendanceLabel: [],
    dependents: [],
    patients: [],
    patientWithProducts: [],
    cupon_code: '',
    home_collected: false,
    home_collect_value: '',
    orderData: {
      channel_id: 4,
      order_type: 1,
      appointment_type_id: "",
      commercial_category: 63,
      commercial_document_number: '',
      payer_id: 0,
      order: {
        budget_id: 0,
        subtotal: '',
        discounts: '',
        total: 0,
        cupon_code: null,
        home_collected: false,
        home_collect_value: '',
        home_collect_date: '',
        vaccine_cards: [],
        cart: [],
        home_collect_schedule: {
          id_home_collected: 0,
          home_collect_date: "",
          zip_code: "",
          country: "Brasil",
          uf: "",
          city: "",
          neighborhood: "",
          street: "",
          number: "",
          complement: "",
          reference_point: "",
          address_type_id: 1,
        }
      }
    },
    isTaxInserted: false,
  },
  setOrder: (value) => set({ order: value }),
  isViewBudget: false,
  setIsViewBudget: (value) => set({ isViewBudget: value }),
}))


export const useBudgetOrderLocalStorage = create<BudgetOrderLocalStore>()(
  persist(
    (set, get) => ({
      order: {
        budget_id: 0,
        appointment_type_id: "",
        payer_patient: {
          id: 0,
          birthdate: '',
          document: {
            id: 0,
            number: '',
            type: '',
            country_id: 0
          },
          email: '',
          gender_id: 0,
          mother_name: '',
          name: '',
          phone: {
            id: 0,
            ddd: 0,
            ddi: 0,
            phone: 0
          },
          unborn: false,
          social_name: ''
        },
        lead: {
          id: 0,
          ddd: null,
          phone: '',
          email: null,
          name: '',
        },
        attendances: [],
        itemsQuantity: 0,
        totalPrice: '0,00',
        budget_type: 1,
        coupon_percentage_discount: 0,
        homeCollectValue: 0,
        isCovenant: false,
        discounts: 0,
        subtotal: 0,
        deliveryTime: '',
        items: [],
        product_categories: [],
        formattedAttendances: [],
        session_start: "",
        session_end: "",
        lastAddress: {
          street: "",
          number: "",
          uf: "",
          neighborhood: "",
          city: "",
          channel_id: 4,
          complement: '',
          country_id: 26,
          default: true,
          reference_point: '',
          type_id: 1,
          zip_code: ''
        },
        attendanceLabel: [],
        dependents: [],
        patients: [],
        patientWithProducts: [],
        cupon_code: '',
        home_collected: false,
        home_collect_value: '',
        isTaxInserted: false,
        orderData: {
          channel_id: 4,
          order_type: 1,
          appointment_type_id: "",
          commercial_category: 63,
          commercial_document_number: '',
          payer_id: 0,
          order: {
            budget_id: 0,
            subtotal: '',
            discounts: '',
            total: 0,
            cupon_code: null,
            home_collected: false,
            home_collect_value: '',
            home_collect_date: '',
            vaccine_cards: [],
            cart: [],
            home_collect_schedule: {
              id_home_collected: 0,
              home_collect_date: "",
              zip_code: "",
              country: "Brasil",
              uf: "",
              city: "",
              neighborhood: "",
              street: "",
              number: "",
              complement: "",
              reference_point: "",
              address_type_id: 1,
            }
          }
        }
      },
      setOrder: (value: OrderType) => {
        set({ order: value });
      },
    }),
    {
      name: 'budget-order-storage',
      storage: createJSONStorage(() => localStorage), // use localStorage here
      onRehydrateStorage: () => (state) => {
        console.log('Rehydrated state', state); // Log the state when rehydrated
      },
    }
  )
);