import React from "react";

import { Button } from "Components/Button/index";

const InputButton = ({
  placeholder,
  onChange,
  value,
  label,
  submit,
  loading = false,
}) => {
  return (
    <div className="field-validate-coupon email-validate ">
      <input
        type="text"
        className="input _sm _circle _border-purple _transparent _lgray no-border"
        name="coupon_code"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="separator" />
      <Button
        onClick={submit}
        loading={loading}
        textButton
        classButton="_xsm no-border transparent-btn"
      >
        {label}
      </Button>
    </div>
  );
};

export default InputButton;
