import { useState, useEffect } from "react";
import { CardSelectionProps } from "./types";
import { ButtonSelectProduct, PatientItem } from "./style";




export const CardSelection = ({
  index,
  count,
  patients,
  onClick,
  selectedProducts,
}: CardSelectionProps) => {

  const [productsName, setProductsName] = useState("");

  useEffect(() => {
    const productsName: string[] = [] ;
    let concatedString = ""

    selectedProducts.map(i => {
      if (i.patient_id === patients[index].index) {
        i.products.map((a) => productsName.push(a.name))
      }
    });
    concatedString =
      productsName.length > 2
        ? productsName?.join(", ")
        : productsName?.join(" e ");

    setProductsName(concatedString);
  }, [selectedProducts]);

  return (
    <PatientItem>
      <strong>
        {patients[index]?.social_name ||
          patients[index]?.name ||
          `Paciente ${count}`}
      </strong>
      <ButtonSelectProduct
        type="button"
        onClick={() => onClick(index)}
      >
        {productsName || `Selecione os produto a receber`}
      </ButtonSelectProduct>
    </PatientItem>
  )

}