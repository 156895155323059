import axios from "axios";
// import { UnityType } from "Pages/Orders/CreateOrder/components/Collect/types";

export const getUnitiesWithSkus = async (skus: string, region_id: number) => {
  try {
    const allParams = {
      region: region_id,
      skus
    }

    const res = await axios ({
      method: 'GET',
      url: `${process.env.REACT_APP_MS_API_URL}/products/units`,
      params: {
        ...allParams
      }
    })

    if (res.status >= 200 && res.status < 300) {
      return res.data;
    }
  } catch (error) {
    return new Error("Não foi possível buscar as unidades.");
  }
}