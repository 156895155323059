import React from "react";
import Modal from "react-modal";

import PageHeader from "Components/Pages/Header";
import PanelContent from "Components/Panels/PanelContent";

import LoadingImage from "Assets/Images/loading-purple.gif";


function DetailsOrdersErrorsModal({
  modalVisible,
  modalDismiss,
  modalErrorCode,
  loading,
  data,
}) {
  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      {loading || !data ? (
        <span className="loading-persons">
          <img src={LoadingImage} alt="" />
        </span>
      ) : (
        <>
          <div className="modal-wrapper-header">
            <PageHeader
              title={`Detalhes de erros da planilha ${  modalErrorCode}`}
            />
          </div>
          <div className="description-list">
            <PanelContent>
              {data.map((item, index) => (
                <dl key={item.cpf + index} className="multiple-list">
                  {!!item.cpf && (
                    <div className="list-item">
                      <dt className="item-title">CPF:</dt>
                      <dd className="item-data">{item.cpf}</dd>
                    </div>
                  )}
                  {!!item.pacient_name && (
                    <div className="list-item">
                      <dt className="item-title">Nome:</dt>
                      <dd className="item-data">{item.pacient_name}</dd>
                    </div>
                  )}
                  {!!item.reason && (
                    <div className="list-item">
                      <dt className="item-title">Erro:</dt>
                      <dd className="item-data">{item.reason}</dd>
                    </div>
                  )}
                </dl>
              ))}
            </PanelContent>
          </div>
        </>
      )}
    </Modal>
  );
}

export default DetailsOrdersErrorsModal;
