import { getNameWeekSearch } from "../../Utils/Schedules";

export function formatterSchedule(res) {
  const { data } = res;
  const _data = data[Object.keys(data)[1]] || data[Object.keys(data)];
  let length = 0;
  const length_total = [];
  let total_schedules = 0;
  let acc = {};
  for (const i in _data) {
    total_schedules += _data[i].length;
    if (!acc[i]) {
      length = _data[i].length > length ? _data[i].length : length;
      acc = { ...acc, [i]: _data[i] };
      continue;
    }

    const schedules = acc[i].concat(_data[i]);
    length = schedules.length > length ? schedules.length : length;
    acc = { ...acc, [i]: schedules };
  }

  for (let i = 0; i < length; i++) {
    length_total.push({});
  }

  return {
    reducer: acc,
    length_total,
    total_schedules,
  };
}

export function formatterScheduleWeek(res, hours) {
  const { data } = res;
  let weeks = [];
  let total = 0;
  const array_total = [];
  let total_schedules = 0;

  Object.keys(data).forEach((key) => {
    const _split = key.split("-");

    const getNameWek = getNameWeekSearch([_split[2], _split[1], _split[0]]);
    weeks = [
      ...weeks,
      {
        title: getNameWek,
        day: _split[2],
        month: _split[1],
        year: _split[0],
        data: data[key],
      },
    ];
  });
  const reducer = weeks.reduce((acc, value) => {
    const getKeys = Object.keys(value.data);

    const { title, month, day, year } = value;
    const hoursDifferenceOne = hours.filter((element) => {
      if (getKeys.indexOf(element) === -1) return element;
    });

    const hoursDifferenceTwo = getKeys.filter((element) => {
      if (hours.indexOf(element) === -1) return element;
    });

    const equal = hoursDifferenceOne.concat(hoursDifferenceTwo);

    let hours_data = {};

    for (const i in equal) {
      if (value.data[equal[i]]) {
        hours_data = { ...hours_data, [equal[i]]: value.data[equal[i]] };
      } else {
        hours_data = { ...hours_data, [equal[i]]: [null] };
      }
    }
    getKeys.forEach((hour) => {
      if (value.data[hour])
        total =
          value.data[hour].length > total ? value.data[hour].length : total;
      if (value.data[hour]) total_schedules += value.data[hour].length;
    });

    acc = [
      ...acc,
      {
        title,
        month,
        day,
        year,
        data: {
          ...value.data,
          ...hours_data,
        },
      },
    ];
    return acc;
  }, []);

  for (let i = 0; i < total; i++) {
    array_total.push({});
  }

  return { reducer, array_total, total_schedules };
}

export function formatterSearch(res) {
  const { data } = res;
  const _data = Object.keys(data).map((key) => data[key]);

  const reducer = _data.reduce((acc, value) => {
    const date = value[Object.keys(value)[0]];
    acc = [...acc, ...date];
    return acc;
  }, []);

  return reducer;
}

export function formatterObjectDataSubmit(objectData) {
  let obj = {};
  Object.keys(objectData).forEach((name) => {
    if (objectData[name] === "") return;
    if (
      name === "calendar" ||
      name === "calendarID" ||
      name === "datetime" ||
      name === "time" ||
      name === "endTime"
    )
      return;

    obj = {
      ...obj,
      [name]: objectData[name],
    };
  });

  return obj;
}
