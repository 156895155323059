/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import API from "Services/Api";
import {
  isCPF,
  uniqueDocument,
  validateFullname,
} from "Services/schema/validations";

import {
  Combos,
  CreateCredencialPerson,
  CreateImgCovenant,
  CreatePersonResponsible,
  GetPersonResponsible,
  UpdateCredencialPerson,
  createPerson,
  updatePerson,
} from "Requests/Person";
import { getInsurance, getInsuranceId } from "Requests/RequestInsurance";

import { useDebounce } from "Hooks/useDebounce";

import { getFile } from "Utils/Base64";
import { MaskCPF, MaskCEP } from "Utils/Masks";
import Slugify from "Utils/Slugify";
import { Convenants } from "Utils/Units/Interface";

import { Button } from "Components/Button";
import Checkbox from "Components/Form/Checkbox";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Autocomplete from "Components/Person/AutocompleteInput";
import Breadcrumb from "Components/Shared/Breadcrumb";

import {
  DocumentsProps,
  EmailsProps,
} from "../ServiceForm/SelectPacient/interface";
import Patient from "./components/Patient";
import { CpfData, InitialValuePatient, PassaportData } from "./datas";
import {
  AddressType,
  ComboCovenant,
  CombosProps,
  CovenantPatient,
  MessageFeedback,
  Person as PersonProps,
  RequestPerson,
} from "./interface";
import { schema } from "./schema";
import {
  FormatAddress,
  FormatDocuments,
  FormatEmail,
  FormatPhones,
  hasAsyncErrors,
} from "./utils";

const initialStateMessages = {
  success: "",
  wait: "",
  error: "",
};

function Person({ match, location: { state } }: any) {
  const [objectData, setObjectData] = useState<PersonProps>(
    InitialValuePatient as any
  );
  const [messages, setMessages] = useState(initialStateMessages);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [comboTypes, setComboTypes] = useState<CombosProps>();
  const [filteredSuggestions, setFilteredSuggestions] = useState<PersonProps[]>(
    []
  );
  const [responsibleSearch, setResponsibleSearch] = useState("");
  const [activeSuggestion, setActiveSuggestion] = useState(false);
  const [notShowSugesstionInEdit, setNotShowSugesstionInEdit] = useState(false);
  const [responsiblePeople, setResponsiblePeople] = useState<PersonProps>();
  const [needResponsibles, setNeedResponsibles] = useState(false);
  const [responsibleId, setResponsibleId] = useState<number>();
  const [isTrans, setIsTrans] = useState<boolean>(false);

  const history = useHistory();
  const formRef = useRef<any>(null);
  const isEdit = match.params.id > 0;

  const removeResponsible = () => {
    setResponsiblePeople(undefined);
    setResponsibleId(undefined);
    setResponsibleId(undefined);
    setNeedResponsibles(false);
    setResponsibleSearch("");
  };

  const _renderMessages = ({ success, wait, error }: MessageFeedback) => {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  };

  const handleChangeCheckbox = (name: string, value: boolean) => {
    if (!value) return removeResponsible();
    setNeedResponsibles(value);
  };

  const handleSelectResponsible = ({ currentTarget: { id } }: any) => {
    const findSelect = filteredSuggestions.find(
      (item) => item?.id === Number(id)
    );

    if (!findSelect) return;

    setNotShowSugesstionInEdit(true);
    setResponsiblePeople(findSelect);
    setActiveSuggestion(false);
    setResponsibleId(findSelect.id);
    setResponsibleSearch(findSelect?.full_name);
  };

  const handleSearchResponsible = ({ target: { value } }: any) => {
    setResponsibleSearch(value);
  };

  const fetchComboTypes = async () => {
    const covenants: ComboCovenant[] = [{ id: "", name: "Selecione" }];
    const response = await Combos({
      combos: [
        "genders",
        "countries",
        "address_types",
        "health_plan_operators",
      ],
    });

    const { data } = await getInsurance();
    data.map((item: Convenants) => {
      covenants.push({ name: item.covenant, id: item.id });
    });

    setComboTypes({ ...response, covenants });
  };

  const formatDocument = (documents: DocumentsProps[]) => {
    if (documents.length) {
      const filterCpf = documents.filter((item) => item.type === "cpf");
      let filterPassaport = documents.filter(
        (item) => item.type === "passport"
      );

      if (!filterCpf.length) {
        filterCpf.push(CpfData);
      }

      if (!filterPassaport.length) {
        filterPassaport.push(PassaportData);
      } else {
        filterPassaport = filterPassaport.map((item) => {
          return {
            ...item,
            country_id: item.country?.id,
          };
        });
      }

      return filterCpf.concat(filterPassaport);
    }
  };

  const formatGetAddress = (address: AddressType[]) => {
    if (address.length) {
      address = address.map((item) => {
        return {
          ...item,
          country_id: item.country?.id || 0,
          // @ts-ignore
          type_id: item.type?.id || 0,
          zip_code: MaskCEP(item.zip_code),
        };
      });
      return updateDefaultFlag(address);
    }
  };

  function updateDefaultFlag(arr: any[]) {
    let foundDefault = false;

    const updatedArray = arr.map((obj) => {
      if (obj.default && !foundDefault) {
        foundDefault = true;
        return obj;
      }

      return {
        ...obj,
        default: false,
      };
    });

    return updatedArray;
  }

  const getPlans = async (id: number) => {
    const response = await getInsuranceId(id);
    return [
      { id: "", covenant_plans_name: "Selecione" },
      ...response.covenant_plans,
    ];
  };

  const fetchPersonByID = async () => {
    let _message = {};

    setMessages((prevState) => ({
      ...prevState,
      wait: "Carregando informações do paciente!",
    }));

    try {
      const { data } = await API.get(`/persons/${match.params.id}`);
      const newData = {
        ...data,
        social_name: data.social_name ?? InitialValuePatient.social_name,
        addresses: !data.addresses.length
          ? InitialValuePatient.addresses
          : formatGetAddress(data.addresses),
        documents: !data.documents.length
          ? [CpfData, PassaportData]
          : formatDocument(data.documents),
        phones: !data.phones.length
          ? InitialValuePatient.phones
          : updateDefaultFlag(data.phones),
        emails: !data.emails.length
          ? InitialValuePatient.emails
          : updateDefaultFlag(data.emails),
        birthdate: formatBirthdate(data.birthdate),
        gender_id: data?.gender?.id,
        covenants_credentials: await Promise.all(
          data.covenants_credentials.map(async (item: any) => {
            item.covenant_id = item.covenant?.id?.toString();
            item.covenant_plan_id = item.covenant_plan?.id?.toString();
            item.comboPlans = await getPlans(item.covenant?.id);
            item.frontCard = getFile(
              item.front_credential_img_base64,
              item.front_credential_img_name
            );
            item.backCard = getFile(
              item.back_credential_img_base64,
              item.back_credential_img_name
            );
            return item;
          })
        ),
      };

      setObjectData(newData);

      const responsible = await GetPersonResponsible(data.id);

      if (responsible.data.length) {
        setResponsibleId(responsible.data[0].id);
        setResponsiblePeople(responsible.data[0]);
        setNeedResponsibles(true);
      }

      _message = {
        success: "Carregado com sucesso.",
        wait: "",
        error: "",
      };
    } catch (error) {
      _message = {
        success: "",
        wait: "",
        error:
          "Ocorreu um erro durante o carregamento das informações do paciente.",
      };

      setMessages((prevState) => ({
        ...prevState,
        ..._message,
      }));
    } finally {
      setTimeout(() => {
        setMessages(initialStateMessages);
      }, 2000);
    }
  };

  const handleCredentials = async (
    covenants_credentials: CovenantPatient[],
    id: number
  ) => {
    await Promise.all(
      covenants_credentials.map(async (item) => {
        let idCredential = item.id;
        if (!item?.id) {
          const { data }: any = await CreateCredencialPerson(
            {
              covenant_id: item.covenant_id,
              covenant_plan_id: item.covenant_plan_id,
              credential_number: item.credential_number,
            },
            id
          );
          idCredential = data?.id;
        } else {
          await UpdateCredencialPerson(
            {
              covenant_id: item.covenant_id,
              covenant_plan_id: item.covenant_plan_id,
              credential_number: item.credential_number,
            },
            id,
            item.id
          );
        }

        if (item?.frontCard?.new || item?.backCard?.new) {
          const formData = new FormData();

          formData.append(
            "front_image",
            item?.frontCard?.new ? item?.frontCard : null
          );

          formData.append(
            "back_image",
            item?.backCard?.new ? item?.backCard : null
          );

          await CreateImgCovenant(formData, id, idCredential);
        }
      })
    );
  };

  const postPerson = async (
    formattedData: RequestPerson,
    responsible_id?: number,
    covenants_credentials?: CovenantPatient[]
  ) => {
    try {
      let response: any;
      if (responsible_id) {
        response = await CreatePersonResponsible(formattedData, responsible_id);
      } else {
        response = await createPerson(formattedData);
      }

      if (covenants_credentials && response.id)
        await handleCredentials(covenants_credentials, response?.id);

      setMessages({
        error: "",
        wait: "",
        success: "Paciente cadastrado com sucesso!",
      });

      if (state?.redirect_to_payment_id) {
        return history.push(
          `/ficha-de-atendimento/pagamento/${state.redirect_to_payment_id}`
        );
      }

      setTimeout(() => {
        history.goBack();
      }, 2000);
    } catch (error) {
      setMessages({
        error: `Oops! Parece que tivemos um problema ao criar o paciente`,
        wait: "",
        success: "",
      });
    }
  };

  const updatePersons = async (
    formattedData: RequestPerson,
    covenants_credentials: CovenantPatient[]
  ) => {
    try {
      await updatePerson(formattedData, objectData?.id);

      if (covenants_credentials && objectData?.id)
        await handleCredentials(covenants_credentials, objectData?.id);

      setMessages({
        error: "",
        wait: "",
        success: "Paciente atualizado com sucesso.",
      });

      if (state?.redirect_to_payment_id) {
        return history.push(
          `/ficha-de-atendimento/pagamento/${state.redirect_to_payment_id}`
        );
      }

      history.push("/pessoas");
    } catch (error) {
      setMessages({
        success: "",
        wait: "",
        error: `Oops! Parece que tivemos um problema ao editar o paciente, cheque o formulário abaixo`,
      });

      throw error;
    }
  };

  const baseSchema = Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        type: Yup.string(),
        number: Yup.string().when("type", {
          is: (type: string) => type === "cpf",
          then: (schema) =>
            schema
              .test("validateCpf", "CPF inválido", function () {
                // eslint-disable-next-line react/no-this-in-sfc
                const { number } = this.parent;
                if (!number) return true;
                return isCPF(number);
              })
              .test("uniqueDocument", "CPF já foi cadastrado", function () {
                // eslint-disable-next-line react/no-this-in-sfc
                const { id, type, number } = this.parent;

                if (!number) return true;
                return uniqueDocument({
                  id,
                  type,
                  document: number,
                  ignore_duplicated: false,
                });
              }),
        }),
        country_id: Yup.string()
          .nullable()
          .when(["number", "type"], {
            is: (number: string, type: string) => {
              if (type !== "cpf" && number) {
                return true;
              }
            },
            then: (schema) => schema.required("Campo obrigatório"),
          }),
      })
    ),
    social_name: Yup.string().when([], {
      is: () => isTrans,
      then: Yup.string()
        .test("social_name", "Nome e Sobrenome", validateFullname)
        .required("Obrigatório"),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().when([], {
            is: () => isEdit && objectData.emails.some((email) => email.email),
            then: Yup.string().required("Campo obrigatório"),
          }),
        })
      )
      .test("uniqueEmails", "Os emails não podem ser iguais", (emails: any) => {
        const emailValues = emails.map(
          (emailObj: EmailsProps) => emailObj.email
        );

        const uniqueEmailValues = [...new Set(emailValues)];
        return emailValues.length === uniqueEmailValues.length;
      }),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          ddi: Yup.string()
            .nullable()
            .when([], {
              is: () => isEdit && objectData.phones.some((phone) => phone.ddi),
              then: Yup.string().required("Campo obrigatório"),
            }),
          ddd: Yup.string()
            .nullable()
            .when("ddi", {
              is: (ddi: string) => !!ddi,
              then: (schema) => schema.required("Campo obrigatório"),
            })
            .when([], {
              is: () => isEdit && objectData.phones.some((phone) => phone.ddd),
              then: Yup.string().required("Campo obrigatório"),
            }),
          phone: Yup.string()
            .nullable()
            .when("ddd", {
              is: (ddi: string) => !!ddi,
              then: (schema) => schema.required("Campo obrigatório"),
            })
            .when([], {
              is: () =>
                isEdit && objectData.phones.some((phone) => phone.phone),
              then: Yup.string().required("Campo obrigatório"),
            }),
        })
      )
      .test(
        "uniquePhones",
        "Os telefones não podem ser iguais",
        (phones: any) => {
          const phoneValues = phones.map((phoneObj: any) =>
            phoneObj.phone?.replace("-", "")
          );

          const uniquePhoneValues = [...new Set(phoneValues)];
          return phoneValues.length === uniquePhoneValues.length;
        }
      ),
  });

  const personSchema = schema.concat(baseSchema);

  const needResponsible = (date: string) => {
    const age = getAge(date);
    const needPeopleResponsible = age < 18;
    return needPeopleResponsible;
  };

  const formatBirthdate = (birthdate: string) => {
    const newBirthdate = birthdate?.split("-")?.reverse()?.join("/");
    if (newBirthdate) return newBirthdate;
    return undefined;
  };

  const handleSubmit = async (data: any, form: any) => {
    const needPeopleResponsible = needResponsible(data.birthdate);
    if (needPeopleResponsible && !responsibleId) {
      setMessages((prevState) => ({
        ...prevState,
        error: "Nehuma pessoa responsável foi selecionada!",
      }));
      setNeedResponsibles(true);
      return;
    }

    setMessages((prevState) => ({
      ...prevState,
      error: "",
      wait: "Validando as informações...",
    }));

    const needDocument = data.documents.every((item: any) => !item.number);
    if (!needPeopleResponsible && needDocument) {
      setMessages((prevState) => ({
        ...prevState,
        error: "Documento obrigatório!",
        wait: "",
      }));
      return;
    }

    const newData = {
      full_name: data.full_name,
      ...(data.social_name && { social_name: data.social_name }),
      birthdate: data.birthdate.split("/").reverse().join("-"),
      mother_name: data.mother_name || "",
      channel_id: 2,
      documents: FormatDocuments(data.documents).length
        ? FormatDocuments(data.documents)
        : undefined,
      phones: FormatPhones(data.phones).length
        ? FormatPhones(data.phones)
        : undefined,
      emails: FormatEmail(data.emails).length
        ? FormatEmail(data.emails)
        : undefined,
      gender_id: Number(data.gender_id),
      addresses: FormatAddress(data.addresses).length
        ? FormatAddress(data.addresses)
        : undefined,
      unborn: data.unborn,
      remove_phones: data.remove_phones || undefined,
      remove_emails: data.remove_emails || undefined,
      remove_addresses: data.remove_addresses || undefined,
    };

    try {
      if (isEdit) {
        await updatePersons(
          newData as RequestPerson,
          data.covenants_credentials
        );

        return;
      }

      await postPerson(
        newData as RequestPerson,
        responsibleId,
        data.covenants_credentials
      );
    } catch (error) {
      // @ts-ignore
      const errors = hasAsyncErrors(error.errors);
      errors.forEach(
        ({ field, message }: { field: string; message: string }) => {
          form.setFieldError(field, message);
        }
      );
    }
  };

  function getAge(dateString: string) {
    if (!dateString) return 0;
    dateString = dateString.split("/").reverse().join("-");

    const today = new Date();
    const birthDate = new Date(dateString);

    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      new Date(today.getFullYear(), today.getMonth(), today.getDate()) <
      new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
    )
      age--;

    return age;
  }

  const getResponsible = useDebounce(
    async () => {
      if (notShowSugesstionInEdit) return setNotShowSugesstionInEdit(false);
      setFilteredSuggestions([]);
      setActiveSuggestion(false);

      try {
        setLoadingSearch(true);
        const { data } = await API.get(
          `/persons?search=${Slugify(responsibleSearch)}`
        );

        const newData: PersonProps[] = [];
        if (data.data.length) {
          data.data.map((item: PersonProps) => {
            if (Number(item.id) === Number(match.params.id)) return;

            newData.push({
              ...item,
              birthdate: formatBirthdate(item.birthdate) as string,
            });
          });
        }

        setFilteredSuggestions(newData);
        setActiveSuggestion(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSearch(false);
      }
    },
    1000,
    [responsibleSearch]
  );

  useEffect(() => {
    fetchComboTypes();

    if (match.params.id && match.params.id > 0) {
      fetchPersonByID();
    }
  }, []);

  useEffect(() => {
    if (responsibleSearch) getResponsible();
  }, [getResponsible]);

  return (
    <PageWrapper className="entry-app-content">
      <form className="content-holder content-holder-no-filter remove-padding">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader
              title={match.params.id > 0 ? "Editar Pessoa" : "Criar Nova"}
            >
              <Breadcrumb
                list={[
                  {
                    path: "/pessoas",
                    label: "Pessoas",
                    current: false,
                  },
                  {
                    path: "/pessoas",
                    label: "Todas as pessoas",
                    current: false,
                  },
                  {
                    path: "#",
                    label: match.params.id > 0 ? "Editar Pessoa" : "Criar Nova",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
            <div className="actions-holder">
              <button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                className=" link link-danger"
              >
                Voltar
              </button>
              <button
                onClick={() => formRef?.current?.submitForm()}
                type="button"
                className="button _action"
              >
                {match.params.id > 0
                  ? state?.redirect_to_payment_id
                    ? "Salvar e continuar p/ pagamento"
                    : "Editar"
                  : "Cadastrar"}
              </button>
            </div>
          </div>
          {match.params.id < 1 && (
            <div className="container-checkbox-responsible">
              <Checkbox
                id="need_responsible"
                label=" "
                descriptionText="O paciente necessita de um responsável?"
                value={needResponsibles}
                // error={need_responsible.error}
                onChange={handleChangeCheckbox}
              />
            </div>
          )}
          {needResponsibles && (
            <div className="flex-container-responsible">
              <div className="flex-items generate-margin-1">
                {match.params.id < 1 && (
                  <div className="select-search-submit persons-search">
                    <div className="select-search-submit-types">
                      {/* @ts-ignore */}
                      <Autocomplete
                        label="Buscar responsável"
                        id="search_responsible"
                        activeSuggestion={activeSuggestion}
                        showSuggestions={activeSuggestion}
                        filteredSuggestions={filteredSuggestions}
                        onChange={handleSearchResponsible}
                        onClick={handleSelectResponsible}
                        value={responsibleSearch}
                        error=""
                        withCreate
                        noSuggestionMessage="Nenhum responsável foi encontrado."
                        placeholder="Nome, sobrenome, CPF ou Passaporte"
                      />
                    </div>
                    <div className="action-buttons-search">
                      <Button
                        loading={loadingSearch}
                        type="button"
                        classButton="button _white"
                        onClick={removeResponsible}
                        disabled={loadingSearch}
                      >
                        {" "}
                        Limpar{" "}
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {responsiblePeople ? (
                <div className="responsible-information">
                  <strong className="title-responsible">Responsável:</strong>
                  <p> {responsiblePeople?.full_name} </p>
                  <p> {formatBirthdate(responsiblePeople?.birthdate || "")}</p>
                  <p>
                    <strong>Documento </strong>
                    {responsiblePeople.documents[0].number
                      ? responsiblePeople.documents[0].type === "cpf" ||
                        responsiblePeople.documents[0].type === "CPF"
                        ? MaskCPF(responsiblePeople?.documents[0].number)
                        : responsiblePeople?.documents[0].number
                      : ""}
                  </p>
                </div>
              ) : null}
            </div>
          )}
          {_renderMessages(messages)}
        </div>
        <div className="page-content">
          <div className="row">
            <div className="xs-12">
              <Formik
                initialValues={objectData}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={personSchema}
                innerRef={formRef}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ values, setFieldValue, errors, setFieldError }) => {
                  const gender: Number = Number(values?.gender_id);

                  const isTrans = gender === 2 || gender === 4 || gender === 5;

                  setIsTrans(isTrans);

                  if (
                    errors.phones === "Telefone duplicado na lista de telefones"
                  ) {
                    const index = values.phones.length - 1;
                    setFieldError(`phones[${index}].phone`, errors.phones);
                  }

                  if (
                    errors.emails === "E-mail duplicado na lista de e-mails"
                  ) {
                    const index = values.emails.length - 1;

                    setFieldError(`emails[${index}].email`, errors.emails);
                  }

                  return (
                    <Form>
                      <Patient
                        comboTypes={comboTypes}
                        objectData={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </form>
    </PageWrapper>
  );
}

export default Person;
