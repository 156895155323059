import styled from "styled-components";

export const Card = styled.div`
  max-width: 100%;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0 1px #82889429,0 4px 6px -2px #82889452;
  padding: 10px;
  margin-bottom: 10px;

  .patient-title {
    text-transform: uppercase;
    color: #572580;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 0.3em;
  }

  .delete-button {
    text-transform: uppercase;
    color: #572580;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 0.3em;
    cursor: pointer;
  }

  .addPatientTitle{
    color: rgba(87, 37, 128, 1);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
    margin-top: 0;
    margin-left: 10px;
  }

  padding: 10
`