import API from "Services/Api";
import ApiAcuity from "Services/ApiAcuity";

import { FormatterData, FormatterDetailsOrder } from "./Formatters/Orders";

export const GetOrders = async (page = 1) => {
  try {
    const response = await API.get(`/orders?page=${page}`);

    const responseFormatted = {
      meta: response.data.meta,
      data: response.data.data,
    };

    return responseFormatted;
  } catch (error) {
    console.log(error);

    if (error.response.data) {
      return {
        meta: {},
        data: [],
      };
    }
  }
};

export const GetOrdersPlans = async (page, params) => {
  try {
    const { data } = await API.get(`/orders/covenant?page=${page}`, {
      params,
    });
    const responseFormatted = {
      meta: data.meta,
      data: data.data,
    };

    return responseFormatted;
  } catch (error) {
    return {
      meta: {},
      data: [],
    };
  }
};

export const GetDetails = async (id) => {
  try {
    const response = await API.get(`/orders/${id}`);

    return {
      success: true,
      data: FormatterDetailsOrder(response.data.data),
    };
  } catch (error) {
    throw new Error("Houve um erro ao buscar as informações do pedido");
  }
};

export const GetPaymentData = async (id) => {
  try {
    const response = await API.get(`/orders/payment/${id}`);

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      data: {},
    };
  }
};

export const editCovenantOrder = async (reqBody) => {
  try {
    const response = await API.put("/orders/covenant/update", { ...reqBody });
    console.log(response);
    if (response.status === 200)
      return {
        success: true,
        data: response.data,
      };

    return {
      success: false,
      data: {},
    };
  } catch (error) {
    return {
      success: false,
      data: {},
    };
  }
};

export const sendToMotion = async (data) => {
  try {
    const res = await API.post(`orders/manager/integration/motion/unit`, data);
    return {
      status: true,
      data: res.data,
    };
  } catch (error) {
    throw {
      status: false,
      message: error.response.data.message,
    };
  }
};

export const sendClinicToMotion = async (data) => {
  try {
    const res = await API.post(`orders/motion/clinic`, data);
    return {
      status: true,
      data: res.data,
    };
  } catch (error) {
    throw {
      status: false,
      message: error.response.data.message,
    };
  }
};

export const sendSubOrderToMotion = async (data) => {
  try {
    const res = await API.post(`orders/suborders/integration/motion`, data);
    return {
      status: true,
      data: res.data,
    };
  } catch (error) {
    throw {
      status: false,
      message: error.response.data.message,
    };
  }
};

export const GetHomeCollect = async (id) => {
  try {
    const response = await API.get(`/orders/home_collect/${id}`);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      data: {},
    };
  }
};

export const GetPatients = async (id) => {
  try {
    const response = await API.get(`/orders/patients/${id}`);

    return {
      success: true,
      data: response.data.patients,
    };
  } catch (error) {
    return {
      success: false,
      data: [],
    };
  }
};

export const GetDependentOrders = async (user_id, page = 1) => {
  try {
    const response = await API.get(
      `orders/dependents_orders/list/${user_id}?page=${page}`
    );

    let responseFormatted;

    if (Array.isArray(response.data) && response.data.length === 0) {
      responseFormatted = {
        meta: {
          pagination: {},
        },
        data: [],
      };
    } else {
      responseFormatted = {
        meta: response.data.meta,
        data: FormatterData(response.data.data),
      };
    }

    return responseFormatted;
  } catch (error) {
    console.log(error);

    if (error.response.data) {
      return {
        meta: {},
        data: [],
      };
    }
  }
};

export const SearchOrders = async (search) => {
  try {
    const response = await API.get(`/orders${search}`);

    let responseFormatted;

    if (Array.isArray(response.data) && response.data.length === 0) {
      responseFormatted = {
        meta: {
          pagination: {},
        },
        data: [],
      };
    } else {
      responseFormatted = {
        meta: response.data.meta,
        data: FormatterData(response.data.data),
      };
    }

    return responseFormatted;
  } catch (error) {
    if (error.response.data) {
      return {
        meta: {
          pagination: {},
        },
        data: [],
      };
    }
  }
};

export const GetAppointmentGender = async (
  person_id,
  order_id,
  channel_id = 2
) => {
  try {
    const response = await ApiAcuity.get(
      `/appointments/history/${person_id}/order/${order_id}?channel_id=${channel_id}`
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      data: [],
    };
  }
};

export const OrderDuplicate = async (order_id, data) => {
  try {
    const response = await API.post(`/orders/return/${order_id}`, data);

    return {
      success: true,
      data: response.data.order,
    };
  } catch (error) {
    return {
      success: false,
      data: [],
    };
  }
};

export const CreateReturnOrder = async (order_id) => {
  try {
    const response = await API.post("/orders/consult/return", {
      parent_id: order_id,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const DestroyOrder = async (id) => {
  try {
    await API.delete(`/orders/destroy/${id}`);
  } catch (error) {
    console.log(error);
  }
};
