import { AttendanceItemType } from "Stores/budget-order"

export const CartOrderItem = ({ product }: { product: AttendanceItemType }) => {

  const handleUnitPrice = () => {
    if (product.item_unit_price) {
      return `R$ ${(product.amount * product.item_unit_price)
        ?.toFixed(2)
        ?.replace(".", ",")}`;
    }

    return "Coberto";
  };

  return (
    <>
      <div className="cart-item-order">
        <p className="label">
          {product.product_code ? `${product.product_code} | ${product.product_name}` : product.product_name}
        </p>
        <p className="value">{product.amount}</p>
        <p className="price" style={{ fontWeight: '14px'}}>{handleUnitPrice()}</p>
      </div>
    </>
  )

}