import {
  handleUserDuplicate,
  handlePersonDuplicatedFlow,
  handlePersonDuplicatedFlowReset,
} from "./ActionsCreators";

export const selectPersonDuplicate = (data: any) => {
  return (dispatch: any) => {
    dispatch(handleUserDuplicate(data));
  };
};

export const selectPersonDuplicatedFlow = (data: any) => {
  return (dispatch: any) => {
    dispatch(handlePersonDuplicatedFlow(data));
  };
};

export const resetPersonDuplicatedFlow = () => {
  return (dispatch: any) => {
    dispatch(handlePersonDuplicatedFlowReset());
  };
};
