import React from "react";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";
import { Container } from "./styles";

export const ProductAnamnesis = ({ product }: ProductProps) => {
  const { rule } = product ?? {};
  const {
    need_diseases,
    need_height,
    need_last_menstruation,
    need_weight,
    need_mother_name,
    need_symptoms,
  } = rule ?? {};

  return (
    <AccordionCollapse title="Anamnese do Paciente" cssClass="anamnesis">
      <Container>
        <div className="column-1">
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Altura
            </span>
            <div className="column-1">
              <S.RowContent role="row" className="row-content">
                {need_height ? "Sim" : "Não"}
              </S.RowContent>
            </div>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Doenças Crônicas
            </span>
            <div className="column-1">
              <S.RowContent role="row" className="row-content">
                {need_diseases ? "Sim" : "Não"}
              </S.RowContent>
            </div>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Sintomas
            </span>
            <div className="column-1">
              <S.RowContent role="row" className="row-content">
                {need_symptoms ? "Sim" : "Não"}
              </S.RowContent>
            </div>
          </div>
        </div>

        <div className="column-2">
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Peso
            </span>
            <div className="column-1">
              <S.RowContent role="row" className="row-content">
                {need_weight ? "Sim" : "Não"}
              </S.RowContent>
            </div>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Data da última menstruação
            </span>
            <div className="column-1">
              <S.RowContent role="row" className="row-content">
                {need_last_menstruation ? "Sim" : "Não"}
              </S.RowContent>
            </div>
          </div>
        </div>

        <div className="column-3">
          <span role="heading" className="heading" aria-level={5}>
            Nome da mãe
          </span>
          <div className="column-1">
            <S.RowContent role="row" className="row-content">
              {need_mother_name ? "Sim" : "Não"}
            </S.RowContent>
          </div>
        </div>
      </Container>
    </AccordionCollapse>
  );
};
