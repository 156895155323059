import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { GetReportsDetail } from "Requests/Reports";

import validatePermissions from "Utils/validatePermissions";

import PageHeader from "Components/Pages/Header";


Modal.setAppElement("#root");

function DetailsResultsModal({
  modalVisible,
  modalDismiss,
  resultSelected,
  loading,
  resendEmail,
  sendingEmail,
}) {
  const user_permissions = useSelector((state) => state.User.permissions);

  const [report, setReport] = useState({});

  function closeModal() {
    modalDismiss();

    setTimeout(() => {
      setReport({});
    }, 500);
  }

  function statusClass(status) {
    switch (status) {
      case "Cancelado":
        return "danger";

      case "Liberado":
        return "success";

      case "Remarcado":
        return "warning";

      default:
        return "normal";
    }
  }

  useEffect(() => {
    if (resultSelected !== null) {
      async function fetchReport(code) {
        const reportObject = await GetReportsDetail(code);

        if (reportObject.status >= 200 && reportObject.status < 300) {
          setReport(reportObject.data);
        }
      }

      fetchReport(resultSelected);
    }
  }, [modalVisible, resultSelected]);

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        bodyOpenClassName="modal-content-open"
        className="modal-content modal-fixed-header"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
      >
        {report && (
          <div className="modal-wrapper-header">
            <PageHeader title="Detalhes do resultado" />
          </div>
        )}
        {!report.requisiton_code ? (
          <span className="loading-persons loading-centered">
            <img src={loading} alt="" />
          </span>
        ) : (
          <div className="content-results-details">
            <div className="results-infos text-separate-content text-center">
              <h4 className="pacient h5 text-light text-purple">
                Paciente: {report.pacient_name}
              </h4>
              <p className="unity text-gray">
                Unidade: {report.responsible_unit_name}
              </p>

              <p className="h5 text-light text-purple">
                Requisição: #{report.requisiton_code}
              </p>
            </div>
            <div className="results-list">
              <table cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    <th width="62%">Exames</th>
                    <th className="text-center" width="18%">
                      Prazo
                    </th>
                    <th className="text-center" width="20%">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {report.exams_level1.map((exam, key) => {
                    return (
                      <tr key={exam.id || key}>
                        <td className={!exam.id ? "subitem" : ""}>
                          {exam.name}(#{exam.code})
                        </td>
                        <td className="text-center">{exam.promised_date}</td>
                        <td className="text-center">
                          <span className={`badge ${statusClass(exam.status)}`}>
                            {exam.status}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {validatePermissions("Send Mail Reports", user_permissions) && (
              <div className="results-actions">
                <button
                  className={`button _large ${sendingEmail ? "_loading" : ""}`}
                  onClick={() => resendEmail(report.requisiton_code)}
                >
                  Enviar por e-mail
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}

export default DetailsResultsModal;
