import React from 'react';

const PublicLayout = (props) => (
    <div className="public-layout">
        { props.header }

        { props.children }

        { props.footer }
    </div>
);

export default PublicLayout;
