import React from "react";

import { Box } from "styles/globalComponents";

import { arrayOfServices, budgetServiceList } from "mocks";

import { Button } from "Components/Button";
import { CardTypeOfService } from "Components/Cards/CardTypeOfService";
import { Heading } from "Components/ServiceForm/Heading";
import { ModalsClientCovenant } from "Components/ServiceForm/ModalsClientCovenant";

import CartDetails from "../components/Cart/CartDetails";
import { PageBody, PageContainer, PageContent } from "../styles";
import { Container, SelectTitle } from "./styles";
import { useBudgetType } from "./useBudgetType";

const ServiceFormSelectBudget = () => {
  const {
    modalClient,
    personData,
    budgetType,
    isLoading,
    isRefetching,
    categoriesSelected,
    setBudgetService,
    startBudgetCreation,
    handleServiceClick,
    resetModalClient,
  } = useBudgetType();

  return (
    <PageContainer className="entry-app-content entry-budget">
      <PageContent className="content-holder page-budget">
        <Heading
          list={[
            {
              path: "/orcamentos",
              label: "Orçamento",
              current: false,
            },
            {
              path: "#",
              label: "Criar novo",
              current: true,
            },
          ]}
          title="Orçamentos"
        />

        <PageBody className="cart-order-budget">
          <Container className="card-search-information ">
            {modalClient.open && (
              <ModalsClientCovenant
                initialModalsState={modalClient}
                resetModalClient={resetModalClient}
                personInfo={personData}
              />
            )}
            <Box className="budget-type">
              <SelectTitle>Selecione o tipo de Orçamento</SelectTitle>

              <CardTypeOfService
                data={budgetServiceList}
                onClick={setBudgetService}
                serviceSelected={budgetType || undefined}
              />
            </Box>

            {budgetType && (
              <Box className="categories-budget" mt="60">
                <SelectTitle>Selecione o tipo de Serviço</SelectTitle>

                <CardTypeOfService
                  data={arrayOfServices}
                  onClick={handleServiceClick}
                  serviceSelected={categoriesSelected}
                />
              </Box>
            )}

            <Box mt="40">
              <Button
                onClick={startBudgetCreation}
                disabled={!categoriesSelected}
                loading={isLoading || isRefetching}
              >
                Criar orçamento
              </Button>
            </Box>
          </Container>

          <CartDetails />
        </PageBody>
      </PageContent>
    </PageContainer>
  );
};

export default ServiceFormSelectBudget;
