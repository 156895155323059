import React, { Suspense } from "react";

const AuthLayout = (props) => {
  return (
    <div className="auth-layout">
      <div className={props.classNames}>
        {props.header}

        <Suspense fallback={<div>Loading...</div>}>{props.children}</Suspense>

        {props.sidebar || false}

        {props.footer}
      </div>
    </div>
  );
};

export default AuthLayout;
