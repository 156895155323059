import styled, { css } from "styled-components";

export const ContainerFile = styled.div<{ onSuccess?: boolean; hasError?: boolean }>`
  padding: 20px;
  margin-right: 22px;
  width: 90%;
  max-width: 490px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-bottom: 36px;
  position: relative;

  ${({ onSuccess }) =>
    onSuccess &&
    css`
      border: 1px solid var(--color-green);
      background-color: rgba(101, 212, 103, 0.25);
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid var(--color-danger);
      background-color: rgba(224, 159, 157, 0.25);
    `}

  img {
    max-width: 70px;
    max-height: 96px;
    margin-right: 10px;
  }

  &.addAnexo {
    margin-right: 0 !important;
  }

  span {
    font-size: 10px;
    line-height: 14px;
    color: var(--color-danger);
    position: absolute;
    bottom: -15px;
    right: 8px;
  }

  button {
    img {
      width: 25px !important;
    }
    	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  }

  @media screen and (max-width: 495px) {
    width: 100%;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
`;
