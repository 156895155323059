import axios from "axios";

import { getCognitoToken } from "Requests/Cognito";

import { getCognitoAuthToken, startCognitoSesssion } from "./CognitoAuth";

const ApiAcuity = axios.create({
  baseURL: process.env.REACT_APP_API_ACUITY,
});

ApiAcuity.interceptors.request.use(
  async (config) => {
    const cognitoToken = getCognitoAuthToken();

    if (!cognitoToken) {
      try {
        const cognitoToken = await getCognitoToken();
        startCognitoSesssion(cognitoToken);
        const cognitoAuthToken = getCognitoAuthToken();

        if (config.headers)
          config.headers.Authorization = `Bearer ${cognitoAuthToken}`;

        return config;
      } catch (error) {
        console.log(error);
      }
    }

    if (config.headers) config.headers.Authorization = `Bearer ${cognitoToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiAcuity;
