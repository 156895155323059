import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";


import { getRndsList, getOptionsFiltersRnds, sendRnds } from "Requests/Rnds";

import validatePermissions from "Utils/validatePermissions";

import { initialStateMessages } from "mocks";

import ConfirmAlert from "Components/Alert/ConfirmAlert";
import DefaultProvider from "Components/DefaultProvider/index";
import Checkbox from "Components/Form/Checkbox";
import Input from "Components/Form/Input";
import InputDateRange from "Components/Form/InputDateRange";
import Select from "Components/Form/Select";
import { Pagination } from "Components/Pagination";

import LoadingImage from "Assets/Images/loading-purple.gif";





import ModalRNDS from "../../Components/Modals/ModalRNDS";

const ListRNDS = () => {
  const typingTimeout = useRef();
  const user_permissions = useSelector((state) => state.User.permissions);

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState("");
  const [errorRNDS, setErrorRNDS] = useState("");
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filterParams, setFilterParams] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [messages, setMessages] = useState(initialStateMessages);
  const [actions, setActions] = useState({
    select_action: "",
  });
  const [optionsFilters, setOptionsFilters] = useState({
    exams_code: [],
    status_codes: [],
    states_codes: [],
  });
  const [filterForm, setFilterForm] = useState({
    exam_code: "",
    status: "",
    state: "",
    requisition_code: "",
    dates: {
      startDate: null,
      endDate: null,
    },
  });

  useEffect(() => {
    fetchOptionsFilters();
    fetchData();
  }, []);

  useEffect(() => {
    if (!filterParams) return;

    fetchData();
  }, [filterParams]);

  useEffect(() => {
    if (
      Object.keys(messages).some(
        (item) => item !== "wait" && messages[item].length
      )
    ) {
      setTimeout(() => {
        setMessages && setMessages(initialStateMessages);
      }, 3000);
    }
  }, [messages]);

  async function fetchOptionsFilters() {
    await getOptionsFiltersRnds()
      .then((res) => setOptionsFilters(res))
      .catch((error) =>
        handleMessageChange(
          "Ocorreu um erro ao buscar as opções de filtros!",
          "error"
        )
      );
  }

  async function fetchData(page, per_page) {
    setLoading(true);
    !page && setPagination({});
    setCheckedItems([]);

    try {
      const params = filterParams || {};

      const _per_page = per_page || pagination.per_page;

      const _items = await getRndsList(params, page, _per_page);
      setItems(_items.data);
      setPagination(_items.pagination);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  function handleChange({ target: { value, name } }) {
    setFilterForm({
      ...filterForm,
      [name]: value,
    });
  }

  function handleActionChange({ target: { value, name } }) {
    setActions({
      ...actions,
      [name]: value,
    });
  }

  function handleDatesChange(value) {
    setFilterForm({
      ...filterForm,
      dates: value,
    });
  }

  function handleMessageChange(value, name) {
    setMessages({
      ...messages,
      [name]: value,
    });
  }

  function handleCheckedItems(id, value) {
    if (id === "check-all") {
      if (!value) return setCheckedItems([]);

      return setCheckedItems(
        items.map(
          (item) => `${item.product_motion_id}|${item.requisition_code}`
        )
      );
    }

    const _checkedItems = [...checkedItems];

    if (!value)
      return setCheckedItems(_checkedItems.filter((item) => item !== id));

    _checkedItems.push(id);
    setCheckedItems(_checkedItems);
  }

  function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    fetchData(e.currentPage);
  }

  function handleOpenModal(item) {
    setIsModalOpen(true);

    setText({
      first_name: item.first_name,
      requisition_code: item.requisition_code,
      product_code: item.product_code,
    });
    setErrorRNDS(item.error_msg);
  }

  function handleChangePerPage(value) {
    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      fetchData(null, value);
    }, 1000);

    setPagination({
      ...pagination,
      per_page: value,
    });
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="7" className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }

  function _tableList() {
    return !items.length ? (
      <tr>
        <td colSpan="7" className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {items.map((item) => {
          const separete = item.created_at.toString().split(" ");
          const getTime = separete[0].split("-");

          const getHour = separete[1].split(":");
          const format = `${getTime[2]}/${getTime[1]}/${getTime[0]} - ${getHour[0]}:${getHour[1]}`;

          return (
            <tr key={item.product_motion_id + item.requisition_code}>
              {validatePermissions(
                "Send Notification RNDS",
                user_permissions
              ) && (
                <td className="checkbox">
                  <Checkbox
                    id={`${item.product_motion_id}|${item.requisition_code}`}
                    onChange={(id, value) => handleCheckedItems(id, value)}
                    value={checkedItems.some(
                      (_item) =>
                        _item ===
                        `${item.product_motion_id}|${item.requisition_code}`
                    )}
                    small
                  />
                </td>
              )}
              <td>{item?.full_name}</td>
              <td>{item.product_motion_id}</td>
              <td>{item.product_code}</td>
              <td>{item.requisition_code}</td>
              <td>{item.unit_state}</td>
              <td>{format}</td>
              <td>
                {["Erro ao Integrar com RNDS", "Dados Ausentes"].includes(
                  item.integration_status
                ) ? (
                  <a className="a-rnds" onClick={() => handleOpenModal(item)}>
                    {item.integration_status}
                  </a>
                ) : (
                  item.integration_status
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  }

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            {validatePermissions(
              "Send Notification RNDS",
              user_permissions
            ) && (
              <th className="checkbox">
                <Checkbox
                  id="check-all"
                  readOnly={loading}
                  onChange={(id, value) => handleCheckedItems(id, value)}
                  value={
                    !loading &&
                    !!items.length &&
                    items.length === checkedItems.length
                  }
                  small
                />
              </th>
            )}
            <th>Nome (Paciente)</th>
            <th>Código do Exame</th>
            <th>Exame</th>
            <th>Código de Requisição</th>
            <th>Estado</th>
            <th>Data</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {loading && _showLoading()}
          {!loading && _tableList()}
        </tbody>
      </table>
    );
  }

  function applyActionConfirm(data, onClose, onSubmit) {
    return (
      <div className="custom-ui">
        <h5 className="h5 text-gray text-light">
          Você tem certeza que deseja executar essa ação?
        </h5>
        <div className="informations-ui">
          <strong>{data.action}</strong>
          <p>{data.message}</p>
          <p>{`As informações serão enviadas para Rede Nacional de Dados em Saúde (RNDS) que por sua vez processará os dados informados.
					Para identificar se o envio foi um sucesso retorne em alguns minutos e verifique na tabela.`}</p>
        </div>
        <div className="wrapper-buttons">
          <button className="link link-danger" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="button _action"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            Sim
          </button>
        </div>
      </div>
    );
  }

  function _renderMessages({ success, wait, error }) {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  }

  async function handleSubmit() {
    const { select_action } = actions;

    handleMessageChange("Enviando informações...", "wait");

    const data = {
      ...(checkedItems.length && select_action === "1"
        ? {
            requisitions: checkedItems.map((item) => ({
              requisition: item.split("|")[1],
            })),
          }
        : {}),
      ...(filterParams && select_action === "2"
        ? { filters: filterParams }
        : {}),
    };

    await sendRnds(data)
      .then((res) => {
        handleMessageChange("Enviados com sucesso!", "success");
        fetchData(pagination.current_page);
      })
      .catch((error) =>
        handleMessageChange(
          "Ocorreu um erro na tentativa de enviar os itens!",
          "error"
        )
      );
  }

  function handleSubmitFilter(event) {
    event.preventDefault();

    const _filterParams = {
      ...filterForm,
      period_start: filterForm.dates.startDate
        ? filterForm.dates.startDate.format("YYYY-MM-DD")
        : "",
      period_end: filterForm.dates.endDate
        ? filterForm.dates.endDate.format("YYYY-MM-DD")
        : filterForm.dates.startDate
        ? filterForm.dates.startDate.format("YYYY-MM-DD")
        : "",
    };

    delete _filterParams.dates;

    setFilterParams(_filterParams);
  }

  function handleSubmitActions(event) {
    event.preventDefault();

    const { select_action } = actions;

    if (!select_action)
      return handleMessageChange("Nenhuma ação selecionada!", "error");
    if (select_action === "1" && !checkedItems.length)
      return handleMessageChange("Não há itens selecionados!", "error");
    if (
      select_action === "2" &&
      (!filterParams ||
        (filterParams &&
          !Object.keys(filterParams).some((item) => filterParams[item])))
    )
      return handleMessageChange("Não há filtros aplicado!", "error");

    const dataAction1 = {
      action: "Reenviar todos selecionados!",
      message: `Essa ação reenviará ${
        checkedItems.length > 1
          ? `todos os ${  checkedItems.length  } itens selecionados`
          : "o item selecionado"
      } na tabela abaixo.`,
    };

    const dataAction2 = {
      action: "Reenviar todos com base no filtro!",
      message:
        "Essa ação reenviará todos os itens com base no filtro aplicado.",
    };

    ConfirmAlert(
      select_action === "1" ? dataAction1 : dataAction2,
      applyActionConfirm,
      handleSubmit
    );
  }

  const breadcrumb = [
    {
      path: "/dashboard",
      label: "Dashboard",
      current: false,
    },
    {
      path: "#",
      label: "Lista RNDS",
      current: true,
    },
  ];

  return (
    <>
      <ModalRNDS
        modalVisible={isModalOpen}
        modalDismiss={setIsModalOpen}
        text={text}
        errorRNDS={errorRNDS}
      />
      <DefaultProvider
        pageHeaderTitle="Lista RNDS"
        breadcrumb={breadcrumb}
        haveComponentOnHeader
      >
        <>
          <form
            id="form-filter"
            onSubmit={handleSubmitFilter}
            className="form-heading"
          >
            <div className="row">
              <div className="row sm-12 md-6 lg-12">
                <div className="sm-12 md-4 lg-4">
                  <div className="search-input">
                    <Select
                      id="exam_code"
                      value={filterForm.exam_code}
                      placeholder="Código do exame"
                      onChange={handleChange}
                      options={optionsFilters.exams_code}
                    />
                  </div>
                </div>
                <div className="sm-12 md-3 lg-4">
                  <div className="search-input">
                    <Select
                      id="status"
                      value={filterForm.status}
                      placeholder="Status"
                      onChange={handleChange}
                      options={optionsFilters.status_codes}
                    />
                  </div>
                </div>
                <div className="sm-12 md-3 lg-4">
                  <div className="search-input">
                    <Select
                      id="state"
                      value={filterForm.state}
                      placeholder="Estado"
                      onChange={handleChange}
                      options={optionsFilters.states_codes}
                    />
                  </div>
                </div>
              </div>

              <div className="row sm-12 md-6 lg-12">
                <div className="sm-12 md-3 lg-4">
                  <div className="search-input">
                    <Input
                      id="requisition_code"
                      type="search"
                      value={filterForm.requisition_code}
                      placeholder="Código de Requisição"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="sm-12 md-3 lg-6">
                  <div className="search-input">
                    <InputDateRange
                      startDatePlaceholderText="Data inicial"
                      endDatePlaceholderText="Data final"
                      startDateId="start_date"
                      endDateId="end_date"
                      onDatesChange={(value) => handleDatesChange(value)}
                      openInModal={false}
                      dates={filterForm.dates}
                      blockPastChanges={false}
                      withClear
                    />
                  </div>
                </div>
                <div className="sm-12 md-2 lg-2">
                  <div className="search-button">
                    <button type="submit" className="button _action">
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {validatePermissions("Send Notification RNDS", user_permissions) && (
            <form
              id="form-actions"
              onSubmit={handleSubmitActions}
              className="form-heading"
            >
              <div className="row">
                <div className="sm-12 md-4 lg-4">
                  <div className="search-input">
                    <Select
                      id="select_action"
                      value={actions.select_action}
                      placeholder="Ações em massa"
                      onChange={handleActionChange}
                      options={[
                        { id: "1", name: "Reenviar todos selecionados" },
                        { id: "2", name: "Reenviar todos com base no filtro" },
                      ]}
                    />
                  </div>
                </div>
                <div className="sm-12 md-2 lg-2">
                  <div className="search-button">
                    <button type="submit" className="button _action">
                      Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          {_renderMessages(messages)}
        </>
        <div className="page-content page-content-list">{_renderTable()}</div>
        <div className="pagination-wrapper">
          {!loading && (
            <>
              <Pagination
                totalRecords={pagination?.total_pages}
                currentPageSelected={pagination?.current_page}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={(e) => handlePagination(e)}
              />
            </>
          )}
        </div>
      </DefaultProvider>
    </>
  );
};

export default ListRNDS;
