import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { GetOrders, SearchOrders } from "Requests/Orders";

import { MaskCPF } from "Utils/Masks";
import validatePermissions from "Utils/validatePermissions";

import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";
import Table from "Components/Table";

import { SubOrdersModal } from "../../Components/Modals/SubOrdersModal";
import { createToast } from "../../Utils/toastFunc";

const types = [
  { id: "", name: "Tipo de busca" },
  { id: "name", name: "Nome" },
  { id: "email", name: "Email" },
  { id: "cpf", name: "CPF" },
  { id: "id_source", name: "Id de Origem" },
];

const ListOrders = () => {
  const user_permissions = useSelector((state) => state.User.permissions);
  const [typeSearch, setTypeSearch] = useState();
  const [value, setValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({});
  const [lastPagePagination, setLastPagePagination] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subOrders, setSubOrders] = useState([]);

  const columns = [
    {
      title: "Data/Hora",
      key: "date",
    },
    {
      title: (
        <>
          ID pedido
          <br /> na origem
        </>
      ),
      key: "origin_order_id",
    },
    {
      title: "Canal",
      key: "channel",
    },
    {
      title: "Pagador",
      key: "pacient",
      render: ({ pacient }) => pacient.name,
    },
    {
      title: "CPF",
      key: "cpf",
      render: ({ pacient }) =>
        pacient.type === "cpf" ? MaskCPF(pacient.number) : "Não informado",
    },
    {
      title: "E-mail",
      key: "email",
      render: ({ pacient }) => pacient.email,
    },
    {
      title: "Tipo de coleta",
      key: "home_collected",
      render: ({ home_collected }) =>
        home_collected ? "Domiciliar" : "Unidade",
    },
    {
      title: "Status",
      key: "status",
    },
    {
      title: "Integração",
      key: "integration",
      className: "table-action-order",
      render: (order) => handleIntegration(order),
    },
    {
      title: "Valor final",
      key: "paid_value",
      render: ({ paid_value }) => `R$ ${paid_value}`,
    },
    {
      title: "",
      key: "action",
      className: "table-action-order",
      render: ({ order_id }) => (
        <Link
          to={`/ficha-de-pedido/${order_id}`}
          className="button _white _action"
          type="button"
        >
          <span className="details-text">Visualizar</span>
        </Link>
      ),
    },
  ];

  function handleIntegration(order) {
    const {
      sub_orders,
      unit_id,
      motion_integration_status,
      order_type,
      order_id,
      home_collected,
    } = order;

    if (sub_orders.length > 0)
      return (
        <button
          type="button"
          onClick={() => {
            setSubOrders({ sub_orders, unit_id, home_collected });
            setIsModalOpen(true);
          }}
          className="button _blue _action"
        >
          Sub-Pedidos
        </button>
      );


    if (motion_integration_status === "Aguardando Envio"){
      const disabledButton = !validatePermissions("Send Orders to Motion", user_permissions);

      return (
        <Link
          type="button"
          className="button _blue _action"
          to={
            disabledButton 
              ? "#" 
              : validatePermissions("Send Orders to Motion", user_permissions) && order_type === 5
                ? `/pedido/revisar/${order_id}`
                : `/pedidos-envio/${order_id}`
          }
          disabled={
            disabledButton
          }
        >
          Enviar
        </Link>
      );
    }
     

    if (motion_integration_status !== "Aguardando Envio")
      return order.motion_integration_status;

    return "Envio indisponível";
  }

  async function fetchData() {
    setPagination({});
    setLoading(true);
    try {
      const ordersResponse = await GetOrders();

      setOrders(ordersResponse.data);

      if (ordersResponse.meta) setPagination(ordersResponse.meta.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchSearchData() {
    setPagination({});
    setLoading(true);

    const ordersResponse = await SearchOrders(`?${typeSearch?.id}=${value}`);

    setOrders(ordersResponse.data);

    if (ordersResponse.meta) setPagination(ordersResponse.meta.pagination);

    setLoading(false);
  }

  async function fetchPagination(e) {
    setLoading(true);

    let ordersResponse;

    if (typeSearch?.id !== "" && value) {
      ordersResponse = await SearchOrders(
        `?${typeSearch?.id}=${value}&page=${e}`
      );
    } else {
      ordersResponse = await GetOrders(e);
    }

    setOrders(ordersResponse.data);
    setPagination(ordersResponse.meta.pagination);
    setLoading(false);
  }

  function handlePagination(e) {
    if (lastPagePagination === e.currentPage) return;

    fetchPagination(e.currentPage);
    setLastPagePagination(e.currentPage);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!typeSearch && !value)
      return createToast("error", "Selecione um tipo de busca");

    if (typeSearch?.id !== "" && !value)
      return createToast(
        "error",
        "Você selecionou um tipo de busca, mas não informou o valor"
      );

    if (typeSearch?.id === "" || !value) {
      fetchData();

      return;
    }

    fetchSearchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageWrapper className="entry-app-content ">
      <div className="content-holder ">
        <div className="page-heading order-home-list">
          <div className="wrapper-header-content">
            <PageHeader title="Todos os pedidos">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Pedidos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Todos os pedidos",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
          <div className="order-home-filter">
            <div className="filters-order">
              <form className="select-search-submit" onSubmit={handleSubmit}>
                <div className="select-search-submit-types">
                  <SelectSearch
                    id="calendars_id"
                    type="text"
                    value={typeSearch}
                    error=""
                    isMulti
                    isMoreSelect={false}
                    options={types}
                    loading={!types.length}
                    readOnly={!types.length}
                    valueKey="name"
                    labelKey="name"
                    placeholder="Tipo de busca"
                    onChange={(value) => {
                      if (value.id === "") setValue("");
                      setTypeSearch(value);
                    }}
                  />
                </div>

                <Input
                  readOnly={!typeSearch}
                  placeholder="Buscar por nome, CPF, e e-mail ou ID do pedido"
                  value={value}
                  onChange={({ target }) => setValue(target.value)}
                />
                <button className="button" onClick={handleSubmit} type="submit">
                  {" "}
                  Buscar{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Table loading={loading} data={orders} columns={columns} />
      </div>
      <div className="pagination-wrapper">
        {!loading && (
          <>
            <Pagination
              totalRecords={pagination?.total_pages}
              currentPageSelected={pagination?.current_page}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e) => handlePagination(e)}
            />
          </>
        )}
      </div>
      <SubOrdersModal
        modalVisible={isModalOpen}
        modalDismiss={() => setIsModalOpen(false)}
        handleClick={() => {}}
        title="Sub-Pedidos"
        userPermissions={user_permissions}
        subOrders={subOrders}
      />
    </PageWrapper>
  );
};

export default ListOrders;
