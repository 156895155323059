import FormatCurrency from "Utils/FormatCurrency";
import { MaskCEP } from "Utils/Masks";

export const FormatterData = (apiData) => {
  return apiData.map((itemData) => ({
    ...itemData,
    totalDisplay: `R$ ${FormatCurrency(itemData.total)}`,
  }));
};

export const FormatterDetailsOrder = (apiData) => {
  return {
    ...apiData,
    total: FormatCurrency(apiData.total),
    subtotal: FormatCurrency(apiData.subtotal),
    discounts: FormatCurrency(apiData.discounts),
    home_collect_value: FormatCurrency(apiData.home_collect_value),
    payment_discount: FormatCurrency(apiData.payment_discount),
    cart: apiData.cart.map((item) => ({
      ...item,
      unitValue: FormatCurrency(item.item_unit_price),
      totalDisplay: FormatCurrency(item.total_item_value),
      name: item.product_name,
    })),
  };
};

export const FormmatterHomeCollect = (apiData) => {
  if (Object.keys(apiData).length < 2) {
    return {};
  }
  return {
    ...apiData,
    value: FormatCurrency(apiData.value),
    zip_code: MaskCEP(apiData.zip_code),
  };
};
