import Axios from "axios";

import API from "Services/Api";

export const getProductsCombos = async (params: string) => {
  try {
    const combos = await Axios.get(
      `${process.env.REACT_APP_MS_API_URL}/products/resources${
        params ? `?${params}` : ""
      }`
    );

    return combos.data;
  } catch (error) {
    throw new Error(
      "Não foi possível buscar os dados necessários para preenchimento do formulário"
    );
  }
};

export const getOrdersCombos = async () => {
  try {
    const combos = await API.get(`/orders/covenant/resource/combos`);

    return combos.data;
  } catch (error) {
    throw new Error("Não foi possível buscar a listagem de combos");
  }
};
