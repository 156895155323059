import API from "Services/Api";

export async function getLogSalesForce(
  params,
  page = 1,
  module = "Product",
  per_page = 10
) {
  try {
    const response = await API.get(
      `/log/salesforce?page=${page}&module=${module}&pagination=${per_page}`,
      { params }
    );

    return {
      data: response.data.data,
      pagination: {
        current_page: response.data.current_page,
        per_page: response.data.per_page,
        total_pages: response.data.last_page,
      },
    };
  } catch (error) {
    console.log(error);
  }
}
export async function getModules() {
  try {
    const { data } = await API.get(`/log/salesforce/modules`);
    const array = data.map((item) => {
      return { id: item.module, name: item.module };
    });
    return array;
  } catch (error) {
    console.log(error);
  }
}
