import { useEffect } from "react";

import { useFormikContext } from "formik";

const transformObjectToDotNotation = (
  obj: any,
  prefix = "",
  result: string[] = []
) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (!value) return;

    const nextKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object") {
      transformObjectToDotNotation(value, nextKey, result);
    } else {
      result.push(nextKey);
    }
  });

  return result;
};

export const getFieldErrorNames = (formikErrors: unknown) => {
  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  const submittedWithErrors = submitCount >= 1 && errors;

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);

    if (fieldErrorNames.length <= 0) return;

    const [element] = document.getElementsByName(fieldErrorNames[0]);

    if (!element) return;

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [submitCount, submittedWithErrors]);

  return null;
};
