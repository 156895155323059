import AmplimedApi from "Services/AmplimedApi";
import API from "Services/Api";
import ApiAcuity from "Services/ApiAcuity";

export async function getClinicalScheduleTimes(
  amplimedCode: string,
  week: string,
  day: string,
  procedure: number
) {
  if (!amplimedCode) {
    return;
  }

  try {
    const response = await ApiAcuity.get(
      `/appointments?professional_code=${amplimedCode}&procedure=${procedure}&channel_id=2&starts_at=${week}&ends_at=${day}`
    );

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}

export async function getClinicalScheduleTimesReturn(
  amplimedCode: string,
  week: string,
  day: string,
  count = 0,
  procedure: number | string
): Promise<any | undefined> {
  if (!amplimedCode) {
    return;
  }

  try {
    const response = await ApiAcuity.get(
      `appointments?professional_code=${amplimedCode}&procedure=${procedure}&channel_id=2&starts_at=${week}&ends_at=${day}`
    );

    return response;
  } catch (error) {
    console.log(error);

    if (count < 3) {
      return getClinicalScheduleTimesReturn(
        amplimedCode,
        week,
        day,
        count + 1,
        procedure
      );
    }

    throw error;
  }
}

export async function getClinicalScheduleTimesFinal(
  amplimedCode: string,
  week: string,
  day: string,
  count = 0
): Promise<any | undefined> {
  if (!amplimedCode) {
    return;
  }

  try {
    const response = await AmplimedApi.get(
      `/appointments?channel=2&procedure=6&professional_code=${amplimedCode}&starts_at=${week}&ends_at=${day}`
    );

    return response;
  } catch (error) {
    console.log(error);

    if (count < 3) {
      return getClinicalScheduleTimesFinal(amplimedCode, week, day, count + 1);
    }

    throw error;
  }
}

export async function ScheduleATime(data: any) {
  if (!data) {
    return;
  }

  try {
    const response = await ApiAcuity.post(`/appointments`, data);

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}

export async function CancelSchedule(
  appointment_id: string,
  motive: string,
  user_logged: any,
  person_id: string,
  order_id: string
) {
  if (!appointment_id || !motive) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await ApiAcuity.post(`/appointments/cancel`, {
      appointment_id,
      motive,
      channel_id: 2,
      person_id,
      order_id,
      user_id: user_logged.id,
    });

    return response;
  } catch (error) {
    console.log(error);

    throw error;
  }
}

export async function Reschedule(data: any, appointment: string) {
  if (!data) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await ApiAcuity.put(`/appointments/${appointment}`, {
      ...data,
    });

    return response;
  } catch (error) {
    throw error;
  }
}

export async function RescheduleReturn(data: any) {
  if (!data) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await ApiAcuity.post(`/appointments`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function saveAppointment(data: any) {
  if (!data) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await API.post(`/orders/appointment`, data);

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}
