import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { Box } from "styles/globalComponents";

import { GetBudgetByID } from "Requests/Budget";

import { Button } from "Components/Button";
import PageHeader from "Components/Pages/Header";
import Breadcrumb from "Components/Shared/Breadcrumb";
import { DetailedBudgetItem } from "Components/ViewBudgetDetails";

import { BudgetItemProps } from "./interface";
import { PageContainer, PageContent } from "./styles";

export const ViewBudget = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data } = useQuery<BudgetItemProps, string>(
    ["budget-item", id],
    () => GetBudgetByID(String(id)),
    {
      refetchOnWindowFocus: false,
    }
  );

  const covenantType = data?.info.is_covenant ? "Convênios" : "Particulares";

  return (
    <PageContainer className="entry-app-content entry-budget">
      <PageContent>
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title={`Orçamentos ${covenantType}`}>
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Orçamentos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: `${covenantType}`,
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>

          <Button textButton onClick={() => history.goBack()}>
            VOLTAR
          </Button>
        </div>
        <div className="separator" />
        <Box className="budget-detailed">
          <DetailedBudgetItem />
        </Box>
      </PageContent>
    </PageContainer>
  );
};
