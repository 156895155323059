import styled from "styled-components";

export const ModalContent = styled.div`
  p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #333;
  }

  div{
    background: #F5F5F5;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    text-align: start;
    margin-bottom: 32px;

    span{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #333;

      margin-bottom: 20px;
    }
  }

`