import API from "../Services/Api";

export const getUsersList = async (page = 1, params) => {
  try {
    const response = await API.get(`/admin/user/listusers?page=${page}`, {
      params,
    });

    const newPagination = {
      count: response.data.to,
      current_page: response.data.current_page,
      links: {
        next: response.data.next_page_url,
        previous: response.data.prev_page_url,
      },
      per_page: response.data.per_page,
      total: response.data.total,
      total_pages: response.data.last_page,
    };

    const _data = {
      data: response.data.data,
      pagination: newPagination,
    };

    return _data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const clearFilters = async () => {
  try {
    const response = await API.get("/admin/user/listusers?page=1");

    const _data = {
      data: response.data.data,
      pagination: {
        current_page: response.data.current_page,
        per_page: response.data.per_page,
        total_pages: response.data.last_page,
      },
    };

    return _data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await API.delete(`/admin/user/delete/${id}`);

    return true;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const activateUser = async (id) => {
  try {
    await API.get(`/admin/user/restore/${id}`);

    return true;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const getOptionsRoles = async () => {
  try {
    const response = await API.get("/admin/user/listroles");

    const data = response.data.roles.map((i) => ({ id: i, name: i }));

    return data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const getUser = async (id) => {
  try {
    const response = await API.get(`/admin/user/edit/${id}`);

    const { data } = response;
    data.role = data.roles.length ? data.roles[0].name : "";
    data.status = !data.deleted_at;

    return response.data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const createUser = async (data) => {
  try {
    await API.post("/admin/user/register", data);

    return true;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const updateUser = async (data) => {
  try {
    await API.put("/admin/user/update", data);

    return true;
  } catch (error) {
    console.log(error);

    throw error;
  }
};
