import API from "Services/Api";

export const GetPaymentTypes = async () => {
  try {
    const response = await API.get("/orders/payment_types");

    return response.data;
  } catch (error) {
    if (error.response.data) return [];
  }
};

export const CreateOrder = async (data) => {
  try {
    const response = await API.post("/orders/manager/data", data);

    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error.response.data,
    };
  }
};

export const getOptionsBrandAndOperator = async () => {
  try {
    const response = await API.get("/orders/card/operators");
    const data = response.data.card_banners.map((i) => ({
      id: i.id,
      name: i.card_banner,
    }));

    return data;
  } catch (error) {
    console.log(error);

    throw "";
  }
};
