import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { TextEllipsis, CapitalizeFirstLetter } from "Utils/Uploads";
import validatePermissions from "Utils/validatePermissions";

import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

import {
  getUnits,
  getRegions,
  getStates,
  deleteUnit,
  PageUnits,
} from "../../Requests/Units";
import { createToast } from "../../Utils/toastFunc";

export function Units() {
  const user_permissions = useSelector((state) => state.User.permissions);
  const history = useHistory();

  const [units, setUnits] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [pageAtual, setPageAtual] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [listStates, setListStates] = useState([]);
  const [listRegions, setListRegions] = useState([]);
  const [searchFilters, setSearchFilters] = useState({});
  const [sort, setSort] = useState({ orderBy: "name", orderAsc: true });
  const [queryStringFilters, setQueryStringFilters] = useState(null);
  const listTypes = [
    {
      id: "clinica",
      name: "Clínica",
    },
    {
      id: "exames",
      name: "Exames",
    },
    {
      id: "domiciliar",
      name: "Domiciliar",
    },
  ];

  const listStatus = [
    {
      id: 1,
      name: "Ativa",
    },
    {
      id: 2,
      name: "Inativa",
    },
  ];

  const clearSearch = () => {
    const sorting = `orderBy=${sort.orderBy}&orderAsc=${sort.orderAsc}`;
    setSearchFilters({});
    setQueryStringFilters(null);
    setPagination([]);
    fetchUnitsFilters(null, null, sorting);
  };

  function Loading() {
    return (
      <span className="loading-persons">
        <img src={LoadingImage} alt="" />
      </span>
    );
  }

  async function handleSorting(fieldName) {
    const sortByAsc = sort?.orderBy === fieldName ? !sort.orderAsc : true;
    setSort({
      orderBy: fieldName,
      orderAsc: sortByAsc,
    });

    setPagination([]);
  }

  async function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    const page = e.currentPage;

    setLoading(true);
    try {
      const sorting = `orderBy=${sort.orderBy}&orderAsc=${sort.orderAsc}`;
      const res = await PageUnits(page, queryStringFilters, sorting);
      setUnits(res.data.data);
      setPagination(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const sorting = `orderBy=${sort.orderBy}&orderAsc=${sort.orderAsc}`;

    if (searchFilters && Object.keys(searchFilters).length > 0) {
      const filters = Object.keys(searchFilters)
        .map((key) => {
          const value = searchFilters[key];
          if (value !== null && typeof value === "object") {
            // Get values from fields different of UF
            if (key !== "uf" && value.id) {
              return `${key}=${value.id}`;
            }

            if (key === "uf") {
              return `${key}=${value.state}`;
            }

            if (value.value) {
              return `${key}=${value.value}`;
            }
          } else {
            return `${key}=${value}`;
          }
        })
        .join("&");

      setPagination([]);
      setQueryStringFilters(filters);
      fetchUnitsFilters(null, filters, sorting);
    }
  }

  async function fetchAllUnits() {
    setLoading(true);
    const res = await getUnits();
    setUnits(res.data.data);
    setPagination(res.data);
    setLoading(false);
  }

  async function fetchUnitsFilters(page, filters, sort) {
    setLoading(true);
    const res = await PageUnits(page ?? 1, filters, sort);
    setUnits(res.data.data);
    setPagination(res.data);
    setLoading(false);
  }

  async function fetchRegions() {
    const response = await getRegions();
    setListRegions(response);
  }

  async function fetchStates() {
    const response = await getStates();
    const filterStates = response.filter(
      (state) => state.id === 33 || state.id === 35 || state.id === 41
    );
    setListStates(filterStates);
  }

  async function DeleteUnit(id) {
    try {
      setLoading(true);
      const { status } = await deleteUnit(id);
      if (status === 200) {
        createToast("success", `Unidade  N°${id} alterada com sucesso!`);
        setInactive(true);
        await fetchAllUnits();
      }
    } catch (error) {
     console.log(error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const sorting = `orderBy=${sort.orderBy}&orderAsc=${sort.orderAsc}`;
    fetchUnitsFilters(null, queryStringFilters, sorting);
  }, [sort]);

  useEffect(() => {
    fetchRegions();
    fetchStates();
  }, []);

  const formatAddressToString = (address) => {
    const { street, neighborhood, cities, cep, number } = address;
    return `${street}, ${number}${neighborhood ? ` - ${neighborhood}` : ""}, ${
      cities.city
    } - ${cities.states.state}, ${cep}`;
  };

  return (
    <PageWrapper className="entry-app-content order-home-list">
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Todas as unidades">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Unidades",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Todas unidades",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            <div className="actions-holder">
              {validatePermissions("Create Units", user_permissions) && (
                <Link to="/unidades/0" className="button _white _action">
                  Adicionar Unidade
                </Link>
              )}
            </div>
          </div>
          <div className="order-home-filter full-width">
            <form className="filters-order" onSubmit={handleSubmit}>
              <div className="sm-3">
                <Input
                  id="unit_name"
                  name="unit_name"
                  type="search"
                  placeholder="Busque pelo nome da unidade"
                  value={searchFilters?.name || ""}
                  onChange={({ target }) =>
                    setSearchFilters({
                      ...searchFilters,
                      name: target.value,
                    })
                  }
                />
              </div>
              <div className="sm-2">
                <SelectSearch
                  id="regions"
                  type="text"
                  value={searchFilters?.region_id || ""}
                  isMulti
                  isMoreSelect={false}
                  options={listRegions}
                  loading={!listRegions.length}
                  readOnly={!listRegions.length}
                  valueKey="id"
                  labelKey="region"
                  placeholder="Regiões"
                  onChange={(value) => {
                    setSearchFilters({
                      ...searchFilters,
                      region_id: value,
                    });
                  }}
                />
              </div>
              <div className="sm-2">
                <SelectSearch
                  id="states"
                  type="text"
                  value={searchFilters?.uf || ""}
                  isMulti
                  isMoreSelect={false}
                  options={listStates}
                  loading={!listStates.length}
                  readOnly={!listStates.length}
                  valueKey="id"
                  labelKey="state"
                  placeholder="Estado"
                  onChange={(value) => {
                    setSearchFilters({
                      ...searchFilters,
                      uf: value,
                    });
                  }}
                />
              </div>
              <div className="sm-2">
                <SelectSearch
                  id="status"
                  type="text"
                  value={searchFilters?.status || ""}
                  isMulti
                  isMoreSelect={false}
                  options={listStatus}
                  loading={!listStatus.length}
                  readOnly={!listStatus.length}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Status"
                  onChange={(value) => {
                    setSearchFilters({
                      ...searchFilters,
                      status: value,
                    });
                  }}
                />
              </div>
              <div className="sm-2">
                <SelectSearch
                  id="type"
                  type="text"
                  value={searchFilters?.type || ""}
                  isMulti
                  isMoreSelect={false}
                  options={listTypes}
                  loading={!listTypes.length}
                  readOnly={!listTypes.length}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Tipo de Unidade"
                  onChange={(value) => {
                    setSearchFilters({
                      ...searchFilters,
                      type: value,
                    });
                  }}
                />
              </div>
              <div className="full-width ml-10">
                <button
                  type="submit"
                  className="button mr-10"
                  onClick={handleSubmit}
                >
                  Buscar
                </button>
                <button
                  id="clean"
                  className="button _white mr-10"
                  onClick={clearSearch}
                >
                  Limpar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="page-content page-content-list">
          <table className="table-content small-separate details">
            <thead>
              <tr>
                <th>
                  Nome da Unidade
                  <button
                    type="button"
                    className="sort-buttons"
                    onClick={() => handleSorting("name")}
                  >
                    ↓↑
                  </button>
                </th>
                <th>
                  Região
                  <button
                    type="button"
                    className="sort-buttons"
                    onClick={() => handleSorting("region_id")}
                  >
                    ↓↑
                  </button>
                </th>
                <th>Endereço</th>
                <th>
                  Cidade
                  <button
                    type="button"
                    className="sort-buttons"
                    onClick={() => handleSorting("city")}
                  >
                    ↓↑
                  </button>
                </th>
                <th>Status</th>
                <th>
                  Tipo da Unidade
                  <button
                    type="button"
                    className="sort-buttons"
                    onClick={() => handleSorting("type")}
                  >
                    ↓↑
                  </button>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {units.length > 0 && !loading ? (
                units.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.regions?.region}</td>
                    <td>
                      {TextEllipsis(formatAddressToString(item.addresses), 70)}{" "}
                    </td>
                    <td>{item.addresses.cities.city}</td>
                    <td>{item.deleted_at === null ? "Ativa" : "Inativa"}</td>
                    <td>{CapitalizeFirstLetter(item.type)}</td>
                    <td className="text-right table-action regionTable">
                      <div className="flex-buttons">
                        <button
                          type="button"
                          className="button _blue _action"
                          onClick={() =>
                            history.push(
                              `unidades/${item.slug ? item.slug : item.id}`
                            )
                          }
                        >
                          Editar
                        </button>
                        <button
                          type="button"
                          className={`${
                            item.deleted_at !== null && "_buttonDisabled"
                          } button _yellow _action`}
                          onClick={() => DeleteUnit(item.id)}
                        >
                          <span className="details-text">
                            {item.deleted_at === null ? "Desativar" : "Ativar"}
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center text-purple">
                    {loading ? (
                      <Loading />
                    ) : (
                      "Oops! Parece que nada foi encontrado..."
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination-wrapper">
        {!loading && (
          <Pagination
            totalRecords={pagination?.last_page}
            currentPageSelected={pagination?.current_page}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e) => handlePagination(e)}
          />
        )}
      </div>
    </PageWrapper>
  );
}
