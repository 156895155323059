import * as Yup from "yup";

import { isCPF, uniqueDocument } from "Services/schema/validations";

export const schema = Yup.object().shape({
  edit: Yup.object().shape({
    full_name: Yup.string().required("Campo obrigatório"),
    birthdate: Yup.date().required("Campo obrigatório"),
    gender_id: Yup.number().required("Campo obrigatório"),
    email: Yup.string().email("Email inválido").required("Campo obrigatório"),
    phone: Yup.string().required("Campo obrigatório"),
    cpf: Yup.string()
      .when("type_document", {
        is: "cpf",
        then: Yup.string()
          .required("Obrigatório")
          .test("validateCpf", "CPF inválido", isCPF),
      })
      .test("uniqueDocument", "CPF já foi cadastrado", function () {
        const { id, type_document, cpf } = this.parent;
        return uniqueDocument({
          id,
          type: type_document,
          document: cpf,
          ignore_duplicated: true,
        });
      }),
    passport: Yup.string().when("type_document", {
      is: "passport",
      then: Yup.string()
        .required("Obrigatório")
        .test("uniqueDocument", "Passaporte já foi cadastrado", function () {
          const { id, type_document, passport } = this.parent;
          return uniqueDocument({
            id,
            type: type_document,
            document: passport,
          });
        }),
    }),
    type_document: Yup.string().required("Obrigatório"),
    passport_issuing_country: Yup.string()
      .nullable(true)
      .when("type_document", {
        is: "passport",
        then: Yup.string().required("Obrigatório"),
      }),
  }),
  dependents: Yup.array().of(
    Yup.object().shape({
      full_name: Yup.string().required("Campo obrigatório"),
      birthdate: Yup.date().required("Campo obrigatório"),
      gender_id: Yup.number().required("Campo obrigatório"),
      email: Yup.string().email("Email inválido"),
      phone: Yup.string(),
      cpf: Yup.string()
        .nullable(true)

        .when("type_document", {
          is: "cpf",
          then: Yup.string()
            .nullable(false)
            .required("Obrigatório")
            .test("validateCpf", "CPF inválido", isCPF)
            .test("uniqueDocument", "CPF já foi cadastrado", function () {
              const { id, type_document, cpf } = this.parent;
              return uniqueDocument({ id, type: type_document, document: cpf });
            }),
        }),
      passport: Yup.string()
        .nullable(true)
        .when("type_document", {
          is: "passport",
          then: Yup.string()
            .required("Obrigatório")
            .nullable(false)
            .test(
              "uniqueDocument",
              "Passaporte já foi cadastrado",
              function () {
                const { id, type_document, passport } = this.parent;
                return uniqueDocument({
                  id,
                  type: type_document,
                  document: passport,
                });
              }
            ),
        }),
      passport_issuing_country: Yup.string()
        .nullable(true)
        .when("type_document", {
          is: "passport",
          then: Yup.string().required("Obrigatório"),
        }),
    })
  ),
});
