import React from "react";

const SwitchInput = ({
  id,
  label,
  onChange,
  value,
  purple,
  textOn,
  cssName,
  textOff,
  reverse,
  small,
  description,
  iconOn,
  iconOff,
  disabled,
}) => {
  return (
    <div
      className={`
            field-holder 
            ${cssName}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          className={`text-gray text-semibold ${purple ? "_purple" : ""} `}
        >
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help"></i>
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      <button
        disabled={disabled}
        onClick={() => onChange && onChange(!value, id)}
        className={`
                    switch-holder 
                    ${value ? "_active" : ""}
                    ${reverse ? "switch-ltf" : ""}
                    ${iconOn ? "with-icons" : ""}
                `}
        type="button"
      >
        <span className="switch-item">
          <small>{value ? iconOn : iconOff}</small>
        </span>
        <span className="switch-text">{value ? textOn : textOff}</span>
      </button>
    </div>
  );
};

export default SwitchInput;
