export const typesOptions = [
  { id: "", name: "Tipo de busca" },
  { id: "name", name: "Nome" },
  { id: "email", name: "Email" },
  { id: "cpf", name: "CPF" },
  { id: "id_source", name: "Id de Origem" },
  { id: "id", name: "Id do Pedido" },
];

export const itensPageOptions = [
  { id: "10", name: "10" },
  { id: "50", name: "50" },
  { id: "100", name: "100" },
];

export const initialDates = {
  dates: {
    startDate: null,
    endDate: null,
  },
};

export const ordersTypeOptions = [
  { type: false, name: "Pedido Simples" },
  { type: true, name: "Pedido Composto" },
];
