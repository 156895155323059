import React from 'react';

const ProgressBar = (props) => {

    return (
        <div className="progress-bar">
            <div
                className="progress"
                style={{ width: `${props.progress  }%` }}
            />
        </div>
    );
}

export default ProgressBar;