import React from "react";

import { Container } from "./styles";

interface LoaderProps {
  size?: number;
  color?: string;
  hoverColor?: string;
  deactivateHover?: boolean;
  borderWidth?: number;
  className?: string;
}

export function Loader({
  color = "#572580",
  size = 30,
  hoverColor = "#fff",
  deactivateHover = false,
  borderWidth = 3,
  className,
}: LoaderProps) {
  return (
    <Container
      color={color}
      size={size}
      hoverColor={hoverColor}
      hasHover={!deactivateHover}
      borderWidth={borderWidth}
      className={className}
    />
  );
}
