import React, { useState } from "react";
import { useHistory } from "react-router";

import { createProduct } from "Requests/Products";

import { useCategories } from "Hooks/useCategories";
import { Category } from "Hooks/useCategories/interface";
import { useProductCombos } from "Hooks/useProductCombos";
import { useRegions } from "Hooks/useRegions";

import { validateArrayType } from "Utils/Arrays";
import { FakePromise } from "Utils/Promise";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { ErrorMessage } from "Components/ErrorMessage";
import { LoadingText } from "Components/LoadingText";
import PageHeader from "Components/Pages/Header";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { useModal } from "../Modals/useModal";
import { ModalAction } from "../Modals/Warnings";
import { ProductForm } from "../ProductForm";
import { FormValuesProps } from "../ProductForm/interface";
import { formatCreateProduct, formatUnitsRequest } from "../ProductForm/utils";
import { INITIAL_VALUES } from "./form";
import { SelectProductCategory } from "./ProductsCategories";
import * as S from "./styles";

export const CreateNewProduct = () => {
  const [step, setStep] = useState("select_category");
  const [loadingCreate, setLoadingCreate] = useState(false);

  const history = useHistory();
  const { allUnitsIds, regions } = useRegions();

  const {
    categorySelected,
    categories,
    categoriesError,
    loadingCategories,
    setNewCategory,
  } = useCategories();

  const { combos, combosError } = useProductCombos({ includeCombo: ["*"] });

  const { modalOptions, modalNonValueProduct, openConfirmModal } = useModal();

  const initialValues = {
    ...INITIAL_VALUES,
    category_id: {
      id: categorySelected.id,
      category: categorySelected.category,
    },
    units: {
      ...INITIAL_VALUES.units,
      include: [0, ...allUnitsIds],
    },
    genders: combos?.genders.map((gender) => gender.id) ?? [],
    channels: combos?.channels.map((channel) => channel.id) ?? [],
  };

  const selectCategory = (category: Category) => {
    setNewCategory(category);
    setStep("create_product");
  };

  const handleSubmit = async (values: FormValuesProps) => {
    try {
      const formattedValues = formatCreateProduct(values);

      if (!formattedValues) return;

      const createObjValues = {
        ...formattedValues,
        units: {
          ...formatUnitsRequest(values.units, regions),
        },
      };

      const hasProductWithZeroValue =
        createObjValues.partners &&
        createObjValues.partners.some((partner) => partner.price === 0);

      setLoadingCreate(true);

      if (hasProductWithZeroValue) await modalNonValueProduct();

      await createProduct(createObjValues);
      setLoadingCreate(false);

      createToast("success", "Produto criado com sucesso");
      await FakePromise(2000);
      history.push("/produtos");
    } catch (error: any) {
      const errors = error?.response?.data ?? {};
      const errorsWP = error?.response?.data.errors ?? {};

      if (errorsWP && validateArrayType(errorsWP)) {
        errorsWP.forEach((err: { message: string }) => {
          createToast("error", err.message);
        });
      }

      if (errors && validateArrayType(errors)) {
        errors.forEach((err: { message: string }) => {
          createToast("error", err.message);
        });
      }
    } finally {
      setLoadingCreate(false);
    }
  };

  if (categoriesError) {
    return (
      <S.Container className="entry-app-content">
        <div className="content-holder">
          <main>
            <p className="error">{categoriesError}</p>
          </main>
        </div>
      </S.Container>
    );
  }

  if (loadingCategories) {
    return (
      <S.Container className="entry-app-content">
        <div className="content-holder">
          <main>
            <LoadingText text="Carregando lista de categorias" />
          </main>
        </div>
      </S.Container>
    );
  }

  if (combosError) {
    return (
      <S.Container className="entry-app-content">
        <div className="content-holder">
          <main>
            <ErrorMessage text={combosError} />
          </main>
        </div>
      </S.Container>
    );
  }

  return (
    <S.Container className="entry-app-content">
      <div className="content-holder">
        <div>
          <div className="wrapper-header-content">
            <PageHeader
              title={
                step === "select_category"
                  ? "Tipo de produto"
                  : `Criar novo produto`
              }
            >
              <Breadcrumb
                list={[
                  {
                    path: "/produtos",
                    label: "Produtos",
                    current: false,
                  },
                  {
                    path: "/produtos",
                    label: "Criar novo produto",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
            <div className="buttons-container">
              {step === "create_product" && (
                <>
                  <Button
                    outlined
                    borderRadius="111"
                    onClick={() => setStep("select_category")}
                    disabled={loadingCreate}
                  >
                    Cancelar
                  </Button>
                  <Button
                    form="form-submit"
                    type="submit"
                    borderRadius="111"
                    disabled={loadingCreate}
                  >
                    Criar
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>

        {openConfirmModal && (
          <ModalAction
            modalEditOptions={modalOptions}
            modalOpened={openConfirmModal}
          />
        )}

        <main>
          {loadingCreate && (
            <LoadingText flex text="Criando produto. Aguarde!" imgSize={15} />
          )}
          {step === "select_category" ? (
            <SelectProductCategory
              selectCategory={selectCategory}
              categoriesList={categories}
            />
          ) : (
            <ProductForm
              handleSubmit={handleSubmit}
              initialValues={initialValues}
            />
          )}
        </main>
      </div>
    </S.Container>
  );
};
