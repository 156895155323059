import React from "react";
import { useHistory } from "react-router";

import { Box } from "styles/globalComponents";

import {
  createInsurance,
  uploadImageCovenant,
} from "Requests/RequestInsurance";

import { useRegions } from "Hooks/useRegions";

import { createToast } from "Utils/toastFunc";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { HealthInsuranceForm } from "../HealthInsuranceForm";
import { INITIAL_VALUES } from "./initialValues";
import { Container } from "./style";

export const CreateHealthInsurance = () => {
  const history = useHistory();
  const { allUnitsIds } = useRegions();

  function stopHealthInsuranceCreation() {
    return history.push("/convenios");
  }

  const handleSubmit = async (formValues: any, formDocs: any) => {
    try {
      delete formValues.docs;
      delete formValues.file;
      delete formValues.covenant_plans_list;

      const response = await createInsurance(formValues);
      if (response.status === 200) {
        await uploadImageCovenant(response?.data?.id, formDocs);
        createToast(
          "success",
          `Convênio ${formValues?.covenant} cadastrado com sucesso.`
        );

        history.push("/convenios");
      }
    } catch (error: any) {
      const errMessage = error?.response?.data?.errors[0]?.message;

      if (errMessage) return createToast("error", errMessage);

      createToast("error", "Não foi possível cadastrar o convênio");
    }
  };

  const initialValues = {
    ...INITIAL_VALUES,
    units: {
      ...INITIAL_VALUES.units,
      include: [...INITIAL_VALUES.units.include, ...allUnitsIds],
    },
  };

  return (
    <PageWrapper className="entry-app-content">
      <Container className="content-holder holderPanelActive">
        <div className="page-heading no-shadow">
          <div className="wrapper-header-content">
            <PageHeader title="Cadastrar convênios">
              <Breadcrumb
                list={[
                  {
                    path: "/convenios",
                    label: "Convênios",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Cadastrar Convênio",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            <Box display="flex" align="center" justify="flex-end">
              <button
                type="button"
                className="link link-danger"
                onClick={stopHealthInsuranceCreation}
              >
                Voltar
              </button>

              <button
                type="submit"
                className="button radiusButtonBorder positionButton"
                form="submit"
              >
                Cadastrar Convênio
              </button>
            </Box>
          </div>
        </div>
        <HealthInsuranceForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </Container>
    </PageWrapper>
  );
};
