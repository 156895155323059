export function mimeType(file_name) {
  const split_name = file_name.split(".");
  const type = split_name[split_name.length - 1];

  switch (type) {
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    case "xls":
      return "application/vnd.ms-excel";
    case "jpg":
      return "image/jpeg";

    case "jpeg":
      return "image/pjpeg";

    case "png":
      return "image/png";

    default:
      return "text/csv";
  }
}

export function blobToFile(theBlob, fileName) {
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

export function TextEllipsis(text, letterLimits = 9999, ellipsis = "...") {
  if (!text) return "";

  if (text.length <= letterLimits) return text;

  return text.toString().slice(0, letterLimits).concat(ellipsis);
}

export function CapitalizeFirstLetter(text) {
  if (typeof text !== "string") return "";
  if (text !== "undefined") return text.charAt(0).toUpperCase() + text.slice(1);
}
