import styled from "styled-components";

export const CampFile = styled.div`
  margin-right: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-bottom: 36px;

  .space-file-name {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 23px;

    .img-add-page {
      padding: 0 25px;
    }
  }

  .button-trash {
    margin-right: 34px;
  }

  img {
    max-width: 74px;
    max-height: 96px;
  }

  &.addAnexo {
    margin-right: 0 !important;

    p {
      margin: 0;
    }
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 168px;
  }

  @media screen and (max-width: 768px) {
    width: 45%;
  }

  @media screen and (max-width: 495px) {
    width: 100%;
  }
`;
