import { confirmAlert } from "react-confirm-alert";

import { ConfirmAlertChildProps, ConfirmAlertDataProps, ConfirmAlertSubmitProps } from "./interface";

const ConfirmAlert = (
  data: ConfirmAlertDataProps,
  child: ConfirmAlertChildProps["child"],
  onSubmit: ConfirmAlertSubmitProps["onSubmit"]
): void => {
  return confirmAlert({
    customUI: ({ onClose }) => child(data, onClose, onSubmit),
    closeOnEscape: true,
    closeOnClickOutside: true,
  });
};

export default ConfirmAlert;
