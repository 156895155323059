import React from "react";

import { CATEGORIES } from "Constants/Categories";

import { useQueryParams } from "Hooks/useQueryParams";

import TooltipInformationSmart from "Components/TooltipInformation";

import { ServiceList, TypeOfServicesProps } from "./interface";
import {
  Button,
  Container,
  ContainerAditionalInformation,
  ContainerButton,
  ContainerItemText,
  ContainerText,
  InfoText,
} from "./styles";

export const CardTypeOfService = ({
  data,
  serviceSelected,
  onClick,
}: TypeOfServicesProps) => {
  const params = useQueryParams();
  const btype = params.get("btype");

  const isCovenantBudget = btype === "covenant";
  const isCategoriesList = data.some((service) => service.categories_included);

  let services = data;

  if (isCovenantBudget && isCategoriesList) {
    const imageCategoryRemoved = data.filter(
      (service) => !service.categories_included?.includes(CATEGORIES.image)
    );

    services = imageCategoryRemoved;
  }

  const totalItems = data?.length;
  const checkAsSelected = (item: ServiceList) => {
    if (typeof serviceSelected === "number") {
      return (
        serviceSelected && item?.categories_included?.includes(serviceSelected)
      );
    }

    return serviceSelected && serviceSelected === item?.service;
  };

  const clickFn = (serviceInfo: ServiceList) => {
    const { categories_included, service, text } = serviceInfo;

    if (service) {
      onClick({
        budgetService: service,
        categories: [0],
        title: "",
        typeCollect: service,
      });

      return;
    }

    if (categories_included && categories_included.length >= 1) {
      onClick({
        categories: categories_included,
        title: text,
        budgetService: "",
        typeCollect: "",
      });
    }
  };

  return (
    <Container>
      {services.map((item, index) => (
        <ContainerButton key={item.id} last={index + 1 === totalItems}>
          <Button
            last={index + 1 === totalItems}
            first={index === 0}
            onClick={() => clickFn(item)}
            className={checkAsSelected(item) ? "checked" : ""}
            disabled={item.disabled}
          >
            <ContainerText>
              <ContainerItemText>
                <img src={item.image} alt="Imagem" />
                <p>{item.text}</p>
              </ContainerItemText>
            </ContainerText>
          </Button>
          <ContainerAditionalInformation>
            {item?.disabled && (
              <InfoText>
                <span>Indisponível</span>
                <TooltipInformationSmart message={item.message_disabled} />
              </InfoText>
            )}
            {!item.disabled && item.free_visit && (
              <InfoText>
                <span>R$ 49,00</span>
                <TooltipInformationSmart message="O convênio selecionado não cobre o valor domiciliar" />
              </InfoText>
            )}
          </ContainerAditionalInformation>
        </ContainerButton>
      ))}
    </Container>
  );
};
