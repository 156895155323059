import React from 'react'

export function BoxMonth({ appointment, calendarSelect }) {
    const { total, time, endTime } = appointment;
    const type = typeof appointment === 'object';

    return (
        <div className="schedule-box-month">
            <strong id={type ? appointment.id : appointment}>{type ? appointment.id : appointment}</strong>

            {type ? (
                <div className="info-box-month">
                    <p>{time} - {endTime}</p>
                    <p>{total} agendamentos</p>
                    <p>{calendarSelect.name}</p>
                </div>
            ): (
                <div style={{width: '234px'}} />
            )}
        </div>
    )
}
