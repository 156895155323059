import Check from "Assets/Images/cards/checkUnits.svg";

import { Container } from "./styles";
import { CardAttendanceProps } from "./types";


export const CardAttendance = ({ title, collect, unity }: CardAttendanceProps) => {

  return (
    <Container>
      <img src={Check} alt="Unidade" />
      {collect ? <span>{`${title} - ${collect}`}</span> : <span>{`${title} - ${unity}`}</span>}
    </Container>
  );
}