import React, { useState } from "react";

import arrowAccordion from "Assets/Images/arrowAccordion.svg";

import { AccordionCollapseProps } from "./interface";
import {
  ContainerAccordion,
  HeaderAccordion,
  TitleAccordion,
  ContentAccordion,
} from "./style";

export const AccordionCollapse = ({
  headerContent = "",
  title,
  children,
  bordered = true,
  centered = false,
  padding = "",
  cssClass = "",
}: AccordionCollapseProps) => {
  const [open, setOpen] = useState(true);

  return (
    <ContainerAccordion
      bordered={bordered}
      padding={padding}
      className={cssClass}
    >
      <HeaderAccordion
        centered={centered}
        open={open}
        onClick={() => setOpen(!open)}
      >
        <TitleAccordion>{title}</TitleAccordion>

        {headerContent}
        <img src={arrowAccordion} alt="arrow-collapse" />
      </HeaderAccordion>

      {open && <ContentAccordion>{children}</ContentAccordion>}
    </ContainerAccordion>
  );
};
