import * as Yup from "yup";

import { uniqueDocument, isCPF } from "Services/schema/validations";

export const schema = Yup.object().shape({
  full_name: Yup.string().required("Campo obrigatório"),
  birthdate: Yup.string().required("Campo obrigatório"),
  gender_id: Yup.number().required("Campo obrigatório"),
  social_name: Yup.string().nullable(),
  documents: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      number: Yup.string().when("type", {
        is: (type: string) => type === "cpf",
        then: (schema) =>
          schema
            .test("validateCpf", "CPF inválido", function () {
              const { number } = this.parent;

              if (!number) return true;
              return isCPF(number);
            })
            .test("uniqueDocument", "CPF já foi cadastrado", function () {
              const { id, type, number } = this.parent;

              if (!number) return true;
              return uniqueDocument({
                id,
                type,
                document: number,
                ignore_duplicated: true,
              });
            }),
      }),
      country_id: Yup.string()
        .nullable()
        .when(["number", "type"], {
          is: (number: string, type: string) => {
            if (type !== "cpf" && number) {
              return true;
            }
          },
          then: (schema) => schema.required("Campo obrigatório"),
        }),
    })
  ),
  phones: Yup.array().of(
    Yup.object()
      .shape({
        ddi: Yup.string().nullable(),
        ddd: Yup.string()
          .nullable()
          .when("ddi", {
            is: (ddi: string) => !!ddi,
            then: (schema) => schema.required("Campo obrigatório"),
          }),
        phone: Yup.string()
          .nullable()
          .when("ddd", {
            is: (ddi: string) => !!ddi,
            then: (schema) => schema.required("Campo obrigatório"),
          }),
      })
      .required()
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email("Email invalido"),
    })
  ),
  addresses: Yup.array().of(
    Yup.object().shape({
      zip_code: Yup.string()
        .nullable()
        .min(9, "CEP inválido")
        .test("required", "Campo obrigatório", function (value) {
          const { street, number, neighborhood, uf, country_id, city } =
            this.parent;

          const hasInputFilled = [
            street,
            number,
            neighborhood,
            uf,
            country_id,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }
          return true;
        }),
      type_id: Yup.string()
        .nullable()
        .test("required", "Campo obrigatório", function (value) {
          const {
            zip_code,
            street,
            number,
            neighborhood,
            uf,
            country_id,
            city,
          } = this.parent;

          const hasInputFilled = [
            zip_code,
            street,
            number,
            neighborhood,
            uf,
            country_id,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }
          return true;
        }),
      street: Yup.string()
        .nullable()
        .test("required", "Campo obrigatório", function (value) {
          const { zip_code, number, neighborhood, uf, country_id, city } =
            this.parent;

          const hasInputFilled = [
            zip_code,
            number,
            neighborhood,
            uf,
            country_id,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }
          return true;
        }),
      number: Yup.string()
        .nullable()
        .test("required", "Campo obrigatório", function (value) {
          const { zip_code, street, neighborhood, uf, country_id, city } =
            this.parent;

          const hasInputFilled = [
            zip_code,
            street,
            neighborhood,
            uf,
            country_id,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }
          return true;
        }),
      complement: Yup.string().nullable(),
      neighborhood: Yup.string().test(
        "required",
        "Campo obrigatório",
        function (value) {
          const { zip_code, street, number, uf, country_id, city } =
            this.parent;

          const hasInputFilled = [
            zip_code,
            street,
            number,
            uf,
            country_id,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }
          return true;
        }
      ),
      uf: Yup.string()
        .nullable()
        .test("required", "Campo obrigatório", function (value) {
          const { zip_code, street, number, neighborhood, country_id, city } =
            this.parent;

          const hasInputFilled = [
            zip_code,
            street,
            number,
            neighborhood,
            country_id,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }
          return true;
        }),
      country_id: Yup.string()
        .nullable()
        .test("required", "Campo obrigatório", function (value) {
          const { zip_code, street, number, neighborhood, uf, city } =
            this.parent;

          const hasInputFilled = [
            zip_code,
            street,
            number,
            neighborhood,
            uf,
            city,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!Number(value);
          }
          return true;
        }),
      city: Yup.string()
        .nullable()
        .test("required", "Campo obrigatório", function (value) {
          const { zip_code, street, number, neighborhood, uf, country_id } =
            this.parent;

          const hasInputFilled = [
            zip_code,
            street,
            number,
            neighborhood,
            uf,
            country_id,
          ].some((field) => !!field);

          if (hasInputFilled) {
            return !!value;
          }

          return true;
        }),
    })
  ),
  covenants_credentials: Yup.array().of(
    Yup.object().shape({
      covenant_id: Yup.number().required("Campo obrigatório"),
      covenant_plan_id: Yup.number().required("Campo obrigatório"),
    })
  ),
});
