export const STATUS_PRODUCT = [
  {
    id: 0,
    name: "Indisponível",
  },
  {
    id: 1,
    name: "Disponível",
  },
  {
    id: 2,
    name: "Inativo",
  },
];

export const ORDER_BY = [
  {
    id: "asc",
    name: "A-Z",
  },
  {
    id: "desc",
    name: "Z-A",
  },
];
