
import { Fragment } from "react/jsx-runtime";
import { AttendanceType } from "Stores/budget-order";

import ClincisIcon from "Assets/Images/clinics-icon.png";
import HomeIcon from "Assets/Images/home-icon.png";
import { Box } from "styles/globalComponents";
import { getTitle } from "Pages/ServiceForm/helper";
import { Container } from "./styles"
import { CartOrderItem } from "../CartItem";


export const AttendanceOrderItem = ({ attendance }: { attendance: AttendanceType }) => {

  const title = getTitle(attendance.type);

  return (
    <>
      <Container>
        <section className="budget-collect-type">
          <article>
            {attendance.type === "home_collect" && (
              <div>
                <img src={HomeIcon} alt="" />
              </div>
            )}
            {attendance.type === "unity_collect" && (
              <div>
                <img src={ClincisIcon} alt="" />
              </div>
            )}

            {attendance.type === "both" && (
              <Box display="flex">
                <img src={HomeIcon} alt="" />
                <img src={ClincisIcon} alt="" style={{ marginLeft: "-1.5em" }} />
              </Box>
            )}
          </article>
          <Box display="flex" direction="column" gap="0" align="flex-start">
            <p className="cart-home-or-unit-title">
              <strong>{attendance.title.toUpperCase()}</strong> <br />
              {title.toUpperCase()}
            </p>
          </Box>
        </section>
        <div className="cart-itens-holder new-layout-cart-itens-holder">
          {attendance.items.map((i) => (
            <Fragment key={i.product_id}>
              <CartOrderItem product={i} />
            </Fragment>
          ))}

        </div>
      </Container>
    </>
  )

}