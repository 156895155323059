import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { GeneralInformationModal } from "Components/Modals/GeneralInformationModal";
import MedicalRecordModal from "Components/Modals/MedicalRecordModal";

import PencilIcon from "Assets/Images/pencil.png";

const MedicalRecord = ({
  documents,
  generalInformation,
  addInformation,
  removeDocument,
  fetchDocuments,
  initialFetchDocuments,
  setGeneralInformation,
  noEdit,
  isToCart,
  budget,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalInfVisible, setModalInfVisible] = useState(false);

  const { is_covenant } = budget ?? {};

  function openModal() {
    setModalVisible(true);
  }

  function clearHTML(value) {
    return !!value
      ?.trim()
      ?.replace(/(<([^>]+)>)/gi, "")
      ?.replace(/&nbsp;/g, "");
  }

  function openModalInfo() {
    setModalInfVisible(true);
  }

  useEffect(() => {
    initialFetchDocuments && fetchDocuments();
  }, []);

  return (
    <>
      <MedicalRecordModal
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        documents={documents}
        removeDocument={removeDocument}
        callbackFinish={fetchDocuments}
        budget={budget}
        noEdit={noEdit}
      />
      <GeneralInformationModal
        generalInformation={generalInformation}
        noEdit={noEdit}
        modalVisible={modalInfVisible}
        modalDismiss={() => setModalInfVisible(false)}
        addInformation={addInformation}
        onChange={(value) => setGeneralInformation(value)}
        budget={budget}
      />
      {isToCart ? (
        <div>
          {!is_covenant && (
            <button
              className={clearHTML(budget.general_information) ? "active" : ""}
              onClick={openModalInfo}
            >
              Informações gerais{" "}
              {clearHTML(budget.general_information) && (
                <img src={PencilIcon} width={16} />
              )}
            </button>
          )}

          <button
            className={documents?.length > 0 ? "active" : ""}
            onClick={openModal}
          >
            Anexar pedido medico{" "}
            <span>
              {documents?.length > 0 &&
                `${documents?.length} arquivo${
                  documents?.length > 1 ? "s" : ""
                }`}
            </span>
            {documents?.length > 0 && <img src={PencilIcon} width={16} />}
          </button>
        </div>
      ) : (
        <div className="medical-record">
          <p>Lista de fichas médicas</p>

          <button
            onClick={openModal}
            type="button"
            className="button _white _xsmall"
          >
            Visualizar
          </button>
        </div>
      )}
    </>
  );
};

MedicalRecord.propTypes = {
  documents: PropTypes.array, // eslint-disable-line
  removeDocument: PropTypes.func,
};

MedicalRecord.defaultProps = {
  documents: [],
};

export default MedicalRecord;
