import React from "react";

import { useDebounce } from "Hooks/useDebounce";

import { Loading } from "Utils/Loading";

import Input from "Components/Form/Input";

import { getHoursAvaliableSchedule } from "../../../Requests/Schedules";
import MiniMonthCalendar from "../MiniMonthCalendar";
import SelectHoursBox from "../SelectHoursBox";

export default function ModalChange({ data, setDate }) {
  const [result, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const [dateSelect, setDateSelect] = React.useState("");

  const getHours = useDebounce(
    async () => {
      try {
        setLoading(true);
        const res = await getHoursAvaliableSchedule({
          date: dateSelect,
          calendarID: data.calendarID,
          appointmentTypeID: data.appointmentType.id,
        });
        setResult(res);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    1000,
    [dateSelect]
  );

  React.useEffect(() => {
    dateSelect && getHours();
  }, [getHours]);

  return (
    <div className="modal-change-schedule">
      <div className="content-modal-schedule">
        <p className="title-change-modal-schedule">Remarcar agendamento</p>

        <p className="description-change-modal-schedule">
          Remarcar o agendamento de <strong>Nome Sobrenome</strong> de sexta, 28
          de maio de 2021 às 07:30 - 8:30 para:
        </p>

        <div className="details-info">
          <div className="margin">
            <Input label="Agenda:" value="Exames ( SP )" />
          </div>

          <div className="calendar-change margin">
            <p className="modal-change-title">Data:</p>
            <MiniMonthCalendar setDate={setDateSelect} />
          </div>

          <div className="hour-change margin">
            <p className="modal-change-title">Horario:</p>
            {!loading ? (
              result.length ? (
                <div className="hour-container-change margin">
                  <SelectHoursBox data={result} setDate={setDate} />
                </div>
              ) : (
                <p className="no-hours-modal">Sem horários disponíveis...</p>
              )
            ) : (
              <div className="schedule-center loading-margin-bottom">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
