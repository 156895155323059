import React from "react";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

const DefaultProvider = (props) => {
  const {
    breadcrumb,
    pageHeaderTitle,
    rightHeaderComponent,
    children,
    haveComponentOnHeader,
    paginationWrapper,
  } = props;

  let header;
  let content;

  if (haveComponentOnHeader) {
    header = children[0];
    content = children.slice(1);
  } else {
    content = children;
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder page-budget">
        <div className="page-heading">
          <div className="wrapper-header-content m-bottom-1 order-budget">
            <PageHeader title={pageHeaderTitle}>
              <Breadcrumb list={breadcrumb} />
              {header || false}
            </PageHeader>

            <div className="budget-header-user">
              {rightHeaderComponent || false}
            </div>
          </div>
        </div>

        {content}
      </div>
      {paginationWrapper && (
        <div className="pagination-wrapper">{paginationWrapper}</div>
      )}
    </PageWrapper>
  );
};

export default DefaultProvider;
