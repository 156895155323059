import React from "react";

import { DependentContinueOrMoreInterface } from "Pages/RegistrationCleaning/interface";
import {
  TextModal,
} from "Pages/RegistrationCleaning/styles";

export const DependentContinueOrMore = ({
  handleModal,
  handleDismiss,
}: DependentContinueOrMoreInterface) => {
  return (
    <>
      <TextModal fontWeight={400}>
        O cadastro do dependente foi atualizado com sucesso.
        <br />
        <br />
        Ainda existem pessoas que estavam vinculadas ao cadastro duplicado
        original. Gostaria de adicionar alguma dessas pessoas como dependente?
      </TextModal>
      <div
        className="flex-buttons-modal-information"
        style={{ marginTop: 30, flexDirection: "column", gap: 32 }}
      >
        <div className="container-button-information">
          <button className="button _action" onClick={() => handleModal()}>
            não, avançar pra revisão
          </button>
        </div>
        <div className="container-button-information">
          <button
            className="button _white _transparentBorderButton"
            onClick={() => handleDismiss()}
          >
            sim, adicionar outro dependente
          </button>
        </div>
      </div>
    </>
  );
};
