import React from "react";

import { getHistoric } from "Requests/Schedules";

import { Loading } from "Utils/Loading";
import { MaskCEP } from "Utils/Masks";
import { getNameWeekSearch, getScheduleInfo } from "Utils/Schedules";

import Input from "Components/Form/Input";

import SchedulesPacient from "../SchedulesPacient";

export default function ModalDetails({ historyPacient, readOnly, data }) {
  const [history, setHistory] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const _split = data.datetime.split("/");
  const labelWeek = getNameWeekSearch([..._split], true);

  const month =
    _split[1] < 10 ? Number(_split[1].split("0")[1]) : Number(_split[1]);
  const { months } = getScheduleInfo();

  const GetCalendarAndHistoryFetch = React.useCallback(async () => {
    try {
      setLoading(true);
      const history = await getHistoric(data.email);
      setHistory(history);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    GetCalendarAndHistoryFetch();
  }, [GetCalendarAndHistoryFetch]);

  const verifyFields = (
    verify,
    obj,
    message = "Nenhuma infomação encontrada"
  ) => {
    return verify.includes(obj) ? message : obj;
  };
  return (
    <div className="content-modal-schedule">
      <div className="header-details-modal">
        <p className="title-modal-details decoration">
          {data.firstName} {data.lastName}
        </p>

        <div className="schedule-details-information">
          <p>
            {labelWeek}, {_split[0]} de {months[month - 1]} de {_split[2]}
          </p>
          <p>
            {data.time} - {data.endTime}
          </p>
          <a>Pedido # {data.id}</a>
        </div>
      </div>

      <div className="modal-details">
        <p className="subtitle-modal-details">Detalhes</p>

        <div className="details-info margin">
          <div className="margin">
            <Input label="Agenda:" readOnly={readOnly} value={data.calendar} />
          </div>

          <div className="margin">
            <Input
              label="Tipo de Evento"
              readOnly={readOnly}
              value={data.type}
            />
          </div>

          <div>
            <Input
              label="Notas"
              readOnly={readOnly}
              value={data.notes ? data.notes : "Sem notas sobre o agendamento"}
            />
          </div>

          <div className="modal-details-flex-inputs margin">
            <div>
              <Input
                mask="(99) 9 9999-9999"
                label="Telefone"
                readOnly={readOnly}
                value={data.phone}
              />
            </div>

            <div>
              <Input label="Email" readOnly={readOnly} value={data.email} />
            </div>
          </div>

          {data.protocol && (
            <div className="margin">
              <Input
                label="Protocólo Central"
                readOnly={readOnly}
                value={data.protocol}
              />
            </div>
          )}

          {data.home_collect_voucher && (
            <div className="margin">
              <Input
                label="Voucher taxa de coleta domiciliar"
                readOnly={readOnly}
                value={data.home_collect_voucher}
              />
            </div>
          )}

          <div className="margin">
            <Input
              label="Voucher pedido no site"
              readOnly={readOnly}
              value={
                data.order_voucher
                  ? data.order_voucher
                  : "Nenhum voucher do pedido foi encontrado"
              }
            />
          </div>
        </div>
      </div>

      <div className="modal-details-address">
        <p className="subtitle-modal-details">Endereço</p>

        <div className="details-info">
          <div>
            <Input label="CEP" readOnly={readOnly} value={MaskCEP(data.cep)} />
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input label="Numero" readOnly={readOnly} value={data.number} />
            </div>
            <div>
              <Input
                label="Logradouro"
                readOnly={readOnly}
                value={data.street}
              />
            </div>
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input
                label="Complemento"
                readOnly={readOnly}
                value={data.complement}
              />
            </div>

            <div>
              <Input
                label="Bairro"
                readOnly={readOnly}
                value={data.neighborhood}
              />
            </div>
          </div>

          <div>
            <Input label="Cidade" readOnly={readOnly} value={data.city} />
          </div>

          <div>
            <Input label="Estado" readOnly={readOnly} value={data.state} />
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input
                mask="(99) 9 9999-9999"
                label="Telefone"
                readOnly={readOnly}
                value={data.phone}
              />
            </div>

            <div>
              <Input label="Email" readOnly={readOnly} value={data.email} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-details-data-pacient">
        <p className="subtitle-modal-details">Dados do Paciente</p>

        <div className="details-info">
          <div>
            <Input
              label="Nome completo"
              readOnly={readOnly}
              value={`${data.firstName} ${data.lastName}`}
            />
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input
                mask="(99) 9 9999-9999"
                label="Telefone"
                readOnly={readOnly}
                value={data.phone}
              />
            </div>

            <div>
              <Input label="Email" readOnly={readOnly} value={data.email} />
            </div>
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input
                label="Documento"
                readOnly={readOnly}
                value={data.document}
              />
            </div>

            <div>
              <Input
                label="Data de nascimento"
                readOnly={readOnly}
                value={data.birthdate}
              />
            </div>
          </div>

          <div>
            <Input
              label="Nome da mãe"
              readOnly={readOnly}
              value={data.mother_name}
            />
          </div>

          <div>
            <Input
              label="Sintomas"
              readOnly={readOnly}
              value={verifyFields(
                ["-", "n/a"],
                data.symptoms,
                "Nenhum sintoma apresentado"
              )}
            />
          </div>

          {!data.covid_test && (
            <>
              <div className="modal-details-flex-inputs">
                <div>
                  <Input
                    label="Peso"
                    readOnly={readOnly}
                    value={verifyFields(
                      ["-", "n/a"],
                      data.weight,
                      "Sem registro"
                    )}
                  />
                </div>

                <div>
                  <Input
                    label="Altura"
                    readOnly={readOnly}
                    value={verifyFields(
                      ["-", "n/a"],
                      data.height,
                      "Sem registro"
                    )}
                  />
                </div>
              </div>

              <div>
                <Input
                  label="Historico de doenças"
                  readOnly={readOnly}
                  value={verifyFields(
                    ["-", "n/a"],
                    data.disease_history,
                    "Nenhum historico encontrado"
                  )}
                />
              </div>

              <div>
                <Input
                  label="Drogas"
                  readOnly={readOnly}
                  value={verifyFields(["-", "n/a"], data.drugs)}
                />
              </div>

              <div>
                <Input
                  label="Ultima menstruação"
                  readOnly={readOnly}
                  value={verifyFields(["-", "n/a"], data.last_menstruation)}
                />
              </div>

              <div className="modal-details-flex-inputs">
                <div>
                  <Input
                    label="Plano de saúde"
                    readOnly={readOnly}
                    value={data.health_insurance === "yes" ? "Sim" : "Não"}
                  />
                </div>

                <div>
                  <Input
                    label="Cartão do plano de saúde"
                    readOnly={readOnly}
                    value={verifyFields(
                      ["-", "n/a"],
                      data.health_insurance_card
                    )}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {historyPacient && history && (
        <div className="modal-details-data-pacient">
          <p className="subtitle-modal-details">Historico</p>

          {!loading ? (
            Object.keys(history).length && (
              <>
                <SchedulesPacient
                  type="Agendamentos Passados"
                  data={history.prev}
                />
                <SchedulesPacient
                  type="Agendamentos Futuros"
                  data={history.next}
                />
              </>
            )
          ) : (
            <div className="schedule-center loading-margin-bottom">
              <Loading />
            </div>
          )}
        </div>
      )}

      {data.other_patients?.length > 0 && (
        <div className="modal-details-data-pacient">
          <p className="subtitle-modal-details">Outros pacientes:</p>

          {data.other_patients.map((patient) => (
            <div className="details-info">
              <div>
                <Input
                  label="Nome completo"
                  readOnly={readOnly}
                  value={patient.name}
                />
              </div>

              <div className="modal-details-flex-inputs">
                <div>
                  <Input
                    label="Documento"
                    readOnly={readOnly}
                    value={patient.document}
                  />
                </div>

                <div>
                  <Input
                    label="Data de nascimento"
                    readOnly={readOnly}
                    value={patient.birthdate}
                  />
                </div>
              </div>
              <div>
                <Input
                  label="Exame"
                  readOnly={readOnly}
                  value={patient.exams}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
