import { useQuery } from "react-query";

import { getComboRegionsInsurance } from "Requests/RequestInsurance";

import { validateArrayType } from "Utils/Arrays";

import { ErrorProps, RegionUnits, RegionsUnits } from "./interface";

export const useRegions = (): RegionsUnits => {
  const { data, isError, error, isLoading } = useQuery<
    RegionUnits[],
    ErrorProps
  >("units", getComboRegionsInsurance, {
    refetchOnWindowFocus: false,
  });

  const unitsAvaliables: number[] = [];

  data?.forEach((unit) => {
    unitsAvaliables.push(unit.id);
    unit?.units?.forEach((un) => unitsAvaliables.push(un.id));
  });

  return {
    regions: validateArrayType(data) ? data : undefined,
    loadingRegions: isLoading,
    regionsError: isError ? error.message : null,
    allUnitsIds: unitsAvaliables,
  };
};
