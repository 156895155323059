import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.1rem;
  border-radius: 30px;
  color: #999;
  padding: 2px 10px 2px 20px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
    0 1px 2px -1px rgba(130, 136, 148, 0.48);

  background: #ffffff;
  border-radius: 161px;
  border: 1px solid #d8d8d8;

  input {
    border-radius: 8px;
    background-color: #fff;
    display: block;
    font-size: 14px;
    color: #666;
    text-align: left;
    outline: none;
    width: 100%;
    border: 1px solid #fff;
    transition: all 0.3s ease-in-out;
  }

  button {
    font-size: 14px;
    font-weight: 500;
  }

  & div.separator {
    height: 30px;
    width: 1px;
    background: #d8d8d8;
    margin-right: 10px;
    margin-left: 10px;
  }
`;
