import { useHistory, useParams } from "react-router";

import { Box } from "styles/globalComponents";

import { getToken } from "Services/Authentication";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";

import TermsIcon from "Assets/Images/terms.svg";

interface TermModalProps {
  isOpen: boolean;
  closeModal: (modal: "term") => void;
  openModal?: (modal: "form") => void;
}

export const TermModal = ({
  closeModal,
  isOpen,
  openModal,
}: TermModalProps) => {
  const { budget } = useBudgetContext();

  const history = useHistory();

  const { person_type } = useParams<{ person_type: string }>();

  const handleSubmit = (budgetId?: number) => {
    if (budgetId) {
      if (
        person_type === "person" ||
        history.location.pathname.includes("finalizar-cadastro")
      ) {
        return history.push(`/pedido/agendar/${budgetId}`);
      }
    }

    createToast("error", " ID do orçamento não foi informado");
  };

  const submitTermModal = () => {
    handleSubmit(budget?.id);
    closeModal("term");

    if (openModal) openModal("form");
  };

  async function handleDownloadTerm() {
    try {
      window.open(
        `${process.env.REACT_APP_API_URL}/budgets/consent/${
          budget?.id
        }?token=${getToken()}`,
        "Termo de consentimento"
      );
    } catch {
      createToast("error", "Erro ao baixar termo de consentimento");
    }
  }
  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => closeModal("term")}
      modalIcon={TermsIcon}
      renderHeading={() => <h2>Termo de uso do serviço</h2>}
      renderDescription={() => (
        <p>
          Para prosseguir para o pagamento, é necessário que o cliente assine o
          'termo de uso do serviço'. Imprima o termo para o cliente assinar e
          prosseguir o processo.
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">
          {" "}
          <Button minHeight="48" width="full" onClick={submitTermModal}>
            Continuar
          </Button>
          <Button
            minHeight="48"
            outlined
            width="full"
            onClick={handleDownloadTerm}
          >
            IMPRIMIR TERMO
          </Button>
        </Box>
      )}
    />
  );
};
