import API from "Services/Api";

export const getQuiz = async () => {
  try {
    const response = await API.get("/quiz/7");

    return response;
  } catch (error) {
    return [];
  }
};
