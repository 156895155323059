export const INITIAL_VALUES = {
  product: "",
  slug: "",
  sku: "",
  mt_code: "",
  category_id: { id: 0, category: "" },
  subcategories: [],
  partners: [
    {
      price: 0,
      partner_id: 1,
      result_id: undefined,
      term: "",
    },
  ],
  status_id: 1,
  isPackage: false,
  channels: [],
  genders: [],
  sample_id: undefined,
  kits: [],
  dose_amount: 1,
  dose_interval: "",
  application_id: undefined,
  methodology_id: undefined,
  product_description: {
    synonym: "",
    description: "",
    partner_description: "",
    partner_title: "",
    why_do: "",
    preparation_method: "",
    how_to: "",
    results: "",
    reactions: "",
    composition: "",
    orientation: "",
    restriction: "",
    presentation: "",
    protection: "",
    indicated: "",
    not_indicated: "",
    dosage: "",
    application: "",
    availability_sus: "",
    public_differentials: [],
    private_differentials: [],
    sell_note: "",
  },
  rule: {
    attenuated_virus: false,
    commercial_code: "",
    free_visit: false,
    isolated_sale: false,
    max_items_cart: null,
    only_central: false,
    need_pre_scheduling: false,
    save_budget: true,
    prescription: false,
    need_height: true,
    need_diseases: false,
    need_medicament: false,
    need_last_menstruation: false,
    need_mother_name: false,
    need_symptoms: false,
    need_weight: true,
  },
  services: [],
  units: {
    include: [0],
    exclude: [],
  },
  services_periods: [
    {
      day_id: 1,
      label: "Segunda-Feira",
      start_id: undefined,
      end_id: undefined,
    },
    { day_id: 2, label: "Terça-Feira", start_id: undefined, end_id: undefined },
    {
      day_id: 3,
      label: "Quarta-Feira",
      start_id: undefined,
      end_id: undefined,
    },
    {
      day_id: 4,
      label: "Quinta-Feira",
      start_id: undefined,
      end_id: undefined,
    },
    { day_id: 5, label: "Sexta-Feira", start_id: undefined, end_id: undefined },
    { day_id: 6, label: "Sábado", start_id: undefined, end_id: undefined },
  ],
  diets: [],
  product_pre_collection: [],
  fastItems: [{ fast_id: "", fast_hours_min: "", fast_hours_max: "" }],
  stop_medication_id: 0,
  stop_medication_obs: "",
};
