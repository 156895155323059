import { useRef } from "react";
import { Button } from "Components/Button";
import { PaymentLinkButtonProps } from "./types";
import { Container } from "./styles";



export const PaymentLinkButton = ({ value, sendCopy, minHeight, placeholder, label }: PaymentLinkButtonProps) => {

  const ref = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <input
        ref={ref}
        type="text"
        name="coupon_code"
        placeholder={placeholder}
        defaultValue={value}
      />
      <div className="separator" />
      <Button
        onClick={() => sendCopy(value)}
        textButton
        padding="0"
        minHeight={minHeight}
      >
        {label}
      </Button>
    </Container>
  );


}