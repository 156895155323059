import Select from "react-select";
import { DependentSelectProps, DependentType } from "./types";


export const DependentSelect = ({
  id,
  options,
  value,
  loading,
  onChange,
  placeholder,
  isDisabled,
}: DependentSelectProps) => {

  const getValue = (options: DependentType[], value: string) => {
    return options.filter((o) => value === o.full_name);
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'rgba(242, 239, 245, 1)',  // Cor de fundo do select
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'rgba(242, 239, 245, 1)',  // Cor de fundo do menu
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgba(242, 239, 245, 1)' : 'rgba(242, 239, 245, 1)',  // Cor ao focar em uma opção
      color: 'rgba(102, 102, 102, 1)',  // Cor do texto
    }),
  };


  return (
    <div className='field-holder'>
      <Select
        menuPosition="fixed"
        classNamePrefix="react-select"
        className="react-select input _opacity-gray"
        id={id}
        value={getValue(options, value.full_name)}
        onChange={(selectedOption) => {
          selectedOption ? onChange(selectedOption) : null
        }}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        getOptionValue={(option) => option.full_name}
        getOptionLabel={(option) => option.full_name}
        styles={customStyles}
        isDisabled={isDisabled}
      />
    </div>
  )


}