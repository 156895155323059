import React from "react";

import MoveDots from "Assets/Images/move-dots.svg";

import { HealthInsuranceInfoProps } from "./interface";
import { Container } from "./style";

export const HealthInsuranceCompanyInfo = ({
  deleteHealthInfo,
  editHealthInfo,
  viewCovenantInfo,
  healthName,
  id,
  imageLogo,
  provided,
  snapshot,
}: HealthInsuranceInfoProps) => {
  return (
    <Container
      className={snapshot.isDragging ? "drag-color" : ""}
      id={id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      defaultStyles={provided.draggableProps.style}
    >
      <img src={MoveDots} className="image-dots" alt="mover elemento" />{" "}
      <img src={imageLogo} className="image-logo" alt={healthName} />
      <span className="health-name">{healthName}</span>
      <button
        type="button"
        className="view-button button"
        onClick={() => viewCovenantInfo(id)}
      >
        Visualizar
      </button>
      <button
        type="button"
        className="edit-button button"
        onClick={() => editHealthInfo(id)}
      >
        Editar
      </button>
      <button
        type="button"
        className="remove-button link link-danger"
        onClick={() => deleteHealthInfo(id, healthName)}
      >
        Excluir
      </button>
    </Container>
  );
};
