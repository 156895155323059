export const TOKEN_KEY = "@CognitoToken";

export const setCognitoSession = (token) => {
  return (document.cookie = `${TOKEN_KEY}=${token}; max-age=3600; path="/"`);
};

export const isCognitoAuthenticated = () => {
  const user = getCognitoAuthToken();

  if (!user) return false;
};

export const getCognitoAuthToken = () => {
  const name = `${TOKEN_KEY}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      if (c.indexOf("undefined") > 0) return "";
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const startCognitoSesssion = (token) => {
  setCognitoSession(token);
};

export const cognitoEndSession = () => {
  return (document.cookie = `${TOKEN_KEY}="";max-age=3600;path="/`);
};
