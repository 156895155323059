import styled from "styled-components";

export const Container = styled.div`
  padding: 43px 0 0;
  border-top: 1px solid #ebebeb;

  .w-50 {
    width: calc(50% - 14px);
  }

  .w-100 {
    width: 100%;
  }

  .gap-20 {
    gap: 20px;
  }

  .align-end {
    align-self: flex-end;
  }

  .align-start {
    align-items: flex-start;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .column {
    flex-direction: column;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 22px;

    .form-column-1,
    .form-column-2 {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }

    input,
    input::placeholder,
    select,
    textarea {
      color: var(--color-lgray-text);
      font-family: var(--font-text);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    textarea {
      height: 90px;
    }

    label,
    span.label {
      margin: 0;
      font-family: var(--font-display);
    }

    span.label {
      font-size: 14px;
      font-weight: 600;
      display: block;
      line-height: 22px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .input-container {
      display: flex;
      margin-bottom: 16px;
      position: relative;

      &.invalid {
        border-color: #c23f3b;
        background-color: rgba(224, 159, 157, 0.25);
      }

      .input-error {
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        color: #c23f3b;
        position: absolute;
        right: 8px;
        bottom: 0;
      }

      .field-holder {
        width: 100%;
      }
    }
  }

  @media (min-width: 1140px) {
    .form {
      grid-template-columns: 1fr minmax(300px, 400px);
    }
  }
`;
