import React from "react";
import { useHistory } from "react-router";

import { Box } from "styles/globalComponents";

import { useGetBudgetsCovenant } from "Hooks/useGetBudgetsCovenant";

import { LoadingText } from "Components/LoadingText";
import PageHeader from "Components/Pages/Header";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { Filter } from "./components/Filters";
import { ParamsFilter } from "./components/Filters/interface";
import { ListBudgets } from "./components/ListBudgets";
import { PageContainer, PageContent } from "./styles";

export const CovenantsBudgets = () => {
  const history = useHistory();

  const {
    data,
    setBudgetsFilters,
    isLoading: loading,
    isError,
    pagination,
  } = useGetBudgetsCovenant();

  function handlePagination(e: { currentPage: number }) {
    if (pagination?.current_page === e.currentPage) return;
    setBudgetsFilters({ page: e.currentPage });
  }

  const handleGetBudgetDetail = (budget_id: number) => {
    history.push(`/orcamento/${budget_id}`);
  };

  const searchBudgets = (filters: ParamsFilter) => {
    setBudgetsFilters(filters);
  };

  if (isError) {
    return (
      <PageContainer className="entry-app-content ">
        <PageContent className="content-holder ">
          <Box mt="100">
            <h5 className="text-center text-purple">
              Não foi possível obter a listagem de orçamentos de convênios !
              Atualize a página.
            </h5>
          </Box>
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer className="entry-app-content ">
      <PageContent className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Orçamentos Convênios">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Orçamentos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Convênios",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
        </div>

        <div className="page-content">
          <Filter searchBudgets={searchBudgets} />

          {loading ? (
            <Box mt="100">
              <LoadingText text="Carregando lista de orçamentos de convênios. Aguarde!" />
            </Box>
          ) : null}

          {data?.data.length && !loading ? (
            <>
              <ListBudgets
                data={data.data}
                handleGetBudgetDetail={handleGetBudgetDetail}
              />

              <Pagination
                key={pagination?.current_page}
                totalRecords={pagination?.total_pages}
                currentPageSelected={pagination?.current_page}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={(e) => handlePagination(e)}
              />
            </>
          ) : null}

          {!data?.data.length && !loading ? (
            <Box mt="60">
              <h3 className="text-center text-purple">
                Nenhum orçamento encontrado
              </h3>
            </Box>
          ) : null}
        </div>
      </PageContent>
    </PageContainer>
  );
};
