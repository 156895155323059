import { ExcludeCombo, IncludeCombo } from "./interfaces";

export const combosOptions = [
  "categories",
  "subcategories",
  "status",
  "applications",
  "fasts",
  "methodologies",
  "stop_medications",
  "samples",
  "genders",
  "channels",
  "services",
  "diets",
  "partners",
  "resut_days",
  "resut_times",
  "log_types",
];

export const getParamsFilter = (
  includeCombo: IncludeCombo = ["*"],
  excludeCombo: ExcludeCombo = undefined
) => {
  const comboParams: string[] = [];

  if (includeCombo.includes("*")) {
    combosOptions.forEach((combo) => {
      !excludeCombo?.includes(combo) && comboParams.push(`combos=${combo}`);
    });
  }

  combosOptions.forEach((combo) => {
    includeCombo.includes(combo) && comboParams.push(`combos=${combo}`);
  });

  return comboParams.join().replaceAll(",", "&");
};
