import React, { useRef, useState } from "react";
import AsyncSelect from "react-select/async";

import { getProducts } from "Requests/Products";

import { validateObjectType } from "Utils/Objects";

import { Button } from "Components/Button";
import { ListPackageItem } from "Components/ListPackageItem";

import {
  ProductsCategories,
  ProductsResultsProps,
  SelectTypeTextProps,
} from "./interface";
import { SelectContainer } from "./styles";

export const SelectListItems = ({
  placeholder,
  label,
  field,
  cssClass = "",
  productsCategories,
  mapValues,
  error,
  name,
  deleteItem,
  addToPackage,
}: SelectTypeTextProps) => {
  const [selectedOption, setSelectedOption] =
    useState<ProductsResultsProps | null>(null);

  const [search, setSearch] = useState<string>("");
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleAddProductToPackage = () => {
    if (selectedOption) {
      addToPackage(selectedOption);
      setSelectedOption(null);
    }
  };

  const keyPres = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter" && validateObjectType(selectedOption)) {
      handleAddProductToPackage();
      e.preventDefault();
    }

    if (e.key === "Enter" && !search) {
      e.preventDefault();
    }
  };

  const fetchProducts = async (
    inputValue: string,
    type: ProductsCategories
  ) => {
    const filters = {
      search: inputValue,
      category: type.categories.join(","),
      subcategories: type.subcategories ? type.subcategories.join(",") : "",
    };

    const listResponse = await getProducts({
      page: 1,
      filters,
      searchBudget: true,
    });

    const resultsFounded = listResponse.data.filter((item: any) => {
      return (
        item.product.toLowerCase().includes(inputValue) ||
        item.sku.toLowerCase().includes(inputValue)
      );
    });

    return resultsFounded;
  };

  const fetchInfo = async (inputValue: string, type: ProductsCategories) => {
    try {
      const productsList = await fetchProducts(inputValue.toLowerCase(), type);
      return productsList;
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = async (inputValue: string, type: ProductsCategories) => {
    return new Promise<any>((resolve) => {
      if (timeout.current) clearInterval(timeout.current);
      timeout.current = setTimeout(async () => {
        const productsResult = await fetchInfo(inputValue, type);
        resolve(productsResult);
      }, 2000);
    });
  };

  return (
    <SelectContainer className={`exams-list-included ${cssClass}`}>
      <div
        className={`
                field-holder 
                ${error ? " field-holder-invalid" : ""}
            `}
      >
        {label && (
          <label htmlFor={field?.name} className="text-gray text-semibold">
            {label}
          </label>
        )}

        <div className="select-holder">
          <div className="input-holder">
            <AsyncSelect
              classNamePrefix="react-select"
              className="react-select input _opacity-gray"
              value={selectedOption}
              loadOptions={(value: string) =>
                loadOptions(value, productsCategories)
              }
              onKeyDown={(e) => keyPres(e)}
              isClearable
              name={name}
              onInputChange={(value) => setSearch(value)}
              placeholder={placeholder}
              loadingMessage={() => "Carregando produtos..."}
              noOptionsMessage={() => "Nenhum produto encontrado"}
              onChange={(value) => setSelectedOption(value)}
              getOptionValue={(option: ProductsResultsProps) => option?.sku}
              getOptionLabel={(option: ProductsResultsProps) =>
                `${option.sku} - ${option.product}`
              }
            />
          </div>

          <Button borderRadius="111" onClick={handleAddProductToPackage}>
            Adicionar
          </Button>
        </div>
      </div>
      <div className="products-package-items">
        {mapValues?.length >= 1
          ? mapValues.map((item) => (
              <ListPackageItem
                key={item.id}
                showDotsIcon={false}
                productName={`${item.sku} - ${item.product}`}
                deleteProduct={() => deleteItem(item)}
              />
            ))
          : null}
      </div>

      {error !== "" && <span className="input-error">{error}</span>}
    </SelectContainer>
  );
};
