import axios from "axios";

import { toast } from "react-toastify";
import { CollectRangeCepAPI, CollectRangeCepParams } from "./interface";

const BASE_URL = process.env.REACT_APP_MS_API_URL;

export async function getCollectRangeCep({
  zip_code,
}: CollectRangeCepParams) {

  try {
    const data = await axios.get<CollectRangeCepAPI>(
      `${BASE_URL}/site/home-collection/availability/zipcode`,
      {
        params: {
          zip_code,
          origin: process.env.REACT_APP_URL_COLABI,
        },
      }
    )

    return { data }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;

      // Tratamento de erro 404
      if (status === 404) {
        toast.error(data.message);
      }

      // Tratamento de erro 500
      if (status === 500) {
        toast.error(
          "Ocorreu um erro ao verificar se o seu CEP está dentro da área de cobertura."
        );
      }
    }
  }

}
