import { ChangeEvent } from "react";

import { BudgetMotion } from "Dtos/IMotion";
import { Formik } from "formik";
import { Box } from "styles/globalComponents";

import { useCovenants } from "Hooks/useCovenants";

import { Button } from "Components/Button";
import { CardUploadCovenant } from "Components/Cards/CardUploadCovenant";
import { ScrollToFieldError } from "Components/ScrollFiedError";
import { CovenantInputFields } from "Components/ServiceForm/ModalsClientCovenant/CovenantInputFields";
import { covenantMotionSchema } from "Components/ServiceForm/ModalsClientCovenant/schemas";

import BackCard from "Assets/Images/back-card.svg";
import FrontCard from "Assets/Images/document-anexo.svg";
import MedicalOrder from "Assets/Images/medical-order.svg";

import { Container } from "./styles";

interface ModalFormProps {
  closeModal: () => void;
  onSubmit: (values: BudgetMotion) => Promise<void>;
  initialValues: BudgetMotion;
}

export const ModalForm = ({
  closeModal,
  onSubmit,
  initialValues,
}: ModalFormProps) => {
  const { loading } = useCovenants();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={covenantMotionSchema}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting }) => {
        const covenantErrors = {
          plan: errors.plan,
          covenant: errors.covenant,
          plan_card_number: errors.plan_card_number,
        };

        const covenantTouched = {
          plan: touched.plan,
          covenant: touched.covenant,
          plan_card_number: touched.plan_card_number,
        };

        const covenantValues = {
          plan: values.plan,
          covenant: values.covenant,
          plan_card_number: values.plan_card_number,
        };

        const addFile = (
          field: string,
          e: ChangeEvent<HTMLInputElement> | null
        ) => {
          const file = e?.target.files;

          if (file) return setFieldValue(field, file[0]);
          setFieldValue(field, null);
        };

        const hasFrontCredentialError = touched.front_card && errors.front_card;
        const hasBackCredentialError = touched.back_card && errors.back_card;
        const hasMedicalOrderError =
          touched.medical_order && errors.medical_order;

        return (
          <Container className="form-container">
            <ScrollToFieldError />
            <div className="form-items">
              <CovenantInputFields
                errors={covenantErrors}
                touched={covenantTouched}
                values={covenantValues}
                onChange={setFieldValue}
                requiredCredential
                blockSelection
              />
            </div>
            <Box mt="20">
              <Box
                className={`upload-item ${
                  hasFrontCredentialError ? "upload-error" : ""
                }`}
              >
                <CardUploadCovenant
                  name="front_card"
                  accept=".jpeg, .png, .pdf, .jpg"
                  value={values.front_card}
                  label="FRENTE DA CARTEIRINHA"
                  initialImgCard={FrontCard}
                  onChange={(e) => addFile("front_card", e)}
                  onDelete={() => addFile("front_card", null)}
                />
                {hasFrontCredentialError && (
                  <span className="error-message error">
                    {errors.front_card}
                  </span>
                )}
              </Box>

              <Box
                mt="30"
                className={`upload-item ${
                  hasBackCredentialError ? "upload-error" : ""
                }`}
              >
                <CardUploadCovenant
                  name="back_card"
                  accept=".jpeg, .png, .pdf, .jpg"
                  value={values.back_card}
                  label="VERSO DA CARTEIRINHA"
                  initialImgCard={BackCard}
                  onChange={(e) => addFile("back_card", e)}
                  onDelete={() => addFile("back_card", null)}
                />

                {hasBackCredentialError && (
                  <span className="error-message error">
                    {errors.back_card}
                  </span>
                )}
              </Box>

              <Box
                mt="30"
                className={`upload-item ${
                  hasMedicalOrderError ? "upload-error" : ""
                }`}
              >
                <CardUploadCovenant
                  name="medical_order"
                  accept=".jpeg, .png, .pdf, .jpg"
                  value={values.medical_order}
                  label="PEDIDO MÉDICO"
                  initialImgCard={MedicalOrder}
                  onChange={(e) => addFile("medical_order", e)}
                  onDelete={() => addFile("medical_order", null)}
                />
                {hasMedicalOrderError && (
                  <span className="error-message error">
                    {errors.medical_order}
                  </span>
                )}
              </Box>
            </Box>
            <Box display="flex" direction="column" mt="48">
              <Button
                width="full"
                minHeight="48"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || loading}
              >
                SALVAR CONVẼNIO
              </Button>

              <Button
                width="full"
                minHeight="48"
                outlined
                onClick={closeModal}
                loading={isSubmitting}
                disabled={isSubmitting || loading}
              >
                CANCELAR
              </Button>
            </Box>
          </Container>
        );
      }}
    </Formik>
  );
};
