import React from "react";
import Modal from "react-modal";

import PageHeader from "Components/Pages/Header";

Modal.setAppElement("#root");

function ModalRNDS({
	text,
	modalVisible,
	modalDismiss,
	errorRNDS,
	externalCss
}) {

	function closeModal() {
		modalDismiss();
	}

	return (
		<div>
			<Modal
				isOpen={modalVisible}
				bodyOpenClassName="modal-content-open"
				className={`modal-content ${externalCss ? "modal-salesforce" : "modal-rnds modal-content-cart"}`}
				overlayClassName={{
					base: "modal",
					afterOpen: "after-open",
					beforeClose: "before-close"
				}}
				closeTimeoutMS={500}
				onRequestClose={closeModal}
			>
				<div className="modal-wrapper-header h5 text-bold">
					<PageHeader>
						<div className="rnds-modal-inner-heading">
							<h2>{text.first_name}</h2>
							<h6>Código do produto: {text.product_code}</h6>
							<h6>Código da requisição: {text.requisition_code}</h6>
						</div>
					</PageHeader>
				</div>
				<div className="modal-wrapper-body">
					<div className="list-content text-content">
						<p>{errorRNDS}</p>
					</div>
				</div>
			</Modal>
		</div>
	);
}



export default ModalRNDS;
