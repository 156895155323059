import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { CategorySelect } from "Context/Budget/interface";
import { PersonRequest, PersonRequestError } from "Dtos/IPerson";

import { getPersonByID } from "Requests/Person";

import { setBudgetInfo } from "Actions/ServiceForm";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useQueryParams } from "Hooks/useQueryParams";

import { Store } from "Reducers/interface";

import { validateArrayType } from "Utils/Arrays";
import { createToast } from "Utils/toastFunc";

import { BudgetInfoParams } from "Components/Cards/CardTypeOfService/interface";

export const useBudgetType = () => {
  const [personId, setPersonId] = useState<null | number>(null);

  const [modalClient, setModalClient] = useState({
    incompleteRegister: false,
    updateRegisterForm: false,
    selectCovenant: false,
    open: false,
  });

  const {
    data: personData,
    isLoading,
    refetch,
    isRefetching,
    isError,
  } = useQuery<PersonRequest, PersonRequestError>(
    ["person", personId],
    () => getPersonByID(personId),
    {
      enabled: !!personId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        checkPersonInfo(data);
      },
      onError: (errorMessage) => {
        createToast("error", errorMessage.message);
      },
    }
  );

  const queryClient = useQueryClient();

  const {
    patientSelected,
    setBudgetType,
    setProductsCategoriesSelected,
    budgetType,
    productsCategoriesSelected,
  } = useBudgetContext();

  const { budget } = useSelector((store: Store) => store.service_form);
  const queryParams = useQueryParams();
  const budget_type = queryParams.get("btype");
  const dispatch = useDispatch();
  const history = useHistory();

  const resetModalClient = () => {
    setModalClient({
      incompleteRegister: false,
      updateRegisterForm: false,
      selectCovenant: false,
      open: false,
    });
  };

  const setBudgetService = ({ budgetService }: BudgetInfoParams) => {
    setBudgetType(budgetService as string);

    dispatch(setBudgetInfo({ type: budgetService }));

    const currentLocation = history.location.pathname;
    history.push(`${currentLocation}?btype=${budgetService}`);
  };

  const handleServiceClick = (categoriesInfo: CategorySelect) => {
    const { categories } = categoriesInfo;
    dispatch(setBudgetInfo({ categories }));

    setProductsCategoriesSelected(categories);
  };

  const checkPersonInfo = (personReturn: PersonRequest) => {
    const { covenants_credentials } = personReturn ?? {};

    if (validateArrayType(covenants_credentials)) {
      setModalClient({
        incompleteRegister: false,
        updateRegisterForm: false,
        selectCovenant: true,
        open: true,
      });

      return;
    }

    setModalClient({
      incompleteRegister: true,
      updateRegisterForm: false,
      selectCovenant: false,
      open: true,
    });
  };

  const covenantBudgetValidation = async () => {
    const { isLead, id } = patientSelected;

    if (isLead) {
      setModalClient({
        incompleteRegister: true,
        updateRegisterForm: false,
        selectCovenant: false,
        open: true,
      });

      return;
    }

    if (personData) {
      checkPersonInfo(personData);
      refetch();
      history.push(
        "/ficha-de-tendimento/person/tipo-de-atendimento?btype=covenant"
      );
      return;
    }

    setPersonId(id);
  };

  const startBudgetCreation = async () => {
    if (isError) {
      refetch();
      return;
    }

    if (budget && budget.type === "covenant" && budget.covenantInfo) {
      history.push(
        `/ficha-de-atendimento/person/tipo-de-atendimento?btype=covenant`
      );
      return;
    }

    const budgetListType = new Set(["covenant", "private"]);

    if (!budget_type || !budgetListType.has(budget_type)) {
      createToast("error", "Selecione o tipo de orçamento");
      return;
    }

    if (budget_type === "covenant") {
      covenantBudgetValidation();
      return;
    }

    const { isLead } = patientSelected;

    const personType = isLead ? "lead" : "person";

    history.push(
      `/ficha-de-atendimento/${personType}/selecionar-produtos?btype=${budgetType}`,
      { state: { from: "from-budget-type-page" } }
    );
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["person"],
    });
  }, []);

  return {
    modalClient,
    personData,
    budgetType,
    isLoading,
    isRefetching,
    categoriesSelected: productsCategoriesSelected
      ? productsCategoriesSelected[0]
      : undefined,
    setBudgetService,
    startBudgetCreation,
    handleServiceClick,
    resetModalClient,
  };
};
