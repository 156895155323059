import { useState, useEffect, useCallback } from "react";

import useInterval from "Hooks/useInterval";
import { useBudgetOrderLocalStorage } from "Stores/budget-order";
import { ScheduleTimerProps, TimeExpireType } from "./types";
import {
  ContainerScheduleTimer,
  TimerDisplay,
  TimerDescription,
} from "./style";

export const ScheduleTimer = ({ expired, content }: ScheduleTimerProps) => {

  const { order } = useBudgetOrderLocalStorage();

  const [timeExpire, setTimeExpire] = useState<TimeExpireType>({} as any);
  const [count, setCount] = useState(false);

  const insertZeroBefore = (number: number) => {
    if (!number) return "00";
    return number < 10 ? `0${number}` : number;
  }

  const concatenatedValue = `${timeExpire.minutes}:${insertZeroBefore(timeExpire.seconds)}`

  const getDateExpireIn = useCallback(() => {
    if (!order.session_end || concatenatedValue.includes('-')) {
      // Se não houver session_end, interrompe o cronômetro
      setCount(false);
      return;
    }

    const date_now = new Date().getTime();
    const t = Number(order.session_end) - date_now;

    const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((t % (1000 * 60)) / 1000);

    setTimeExpire({ minutes, seconds });
    setCount(true);

    // Verifica se o tempo já expirou ao inicializar
    if (minutes <= 0 && seconds <= 0) {
      expired && expired();
    }
  }, [order.session_end, expired]);

  useInterval(
    () => {
      updateTimer();
    },
    count ? 1000 : null
  );

  useEffect(() => {
    getDateExpireIn();
  }, [getDateExpireIn]);

  function updateTimer() {
    const { minutes, seconds } = timeExpire;

    if (minutes <= 0 && seconds <= 0) {
      setCount(false);
      expired && expired();
    } else if (seconds === 0) {
      setTimeExpire({ minutes: minutes - 1, seconds: 59 });
    } else {
      setTimeExpire({ ...timeExpire, seconds: seconds - 1 });
    }
  }

  // function updateTimer() {
  //   const { minutes, seconds } = timeExpire;
  
  //   if (minutes <= 0 && seconds <= 0) {
  //     setCount(false);
  //     expired && expired();
  //   } else {
  //     // Subtrai 10 minutos (600 segundos) a cada segundo
  //     const totalSeconds = minutes * 60 + seconds - 600;
      
  //     const updatedMinutes = Math.floor(totalSeconds / 60);
  //     const updatedSeconds = totalSeconds % 60;
  
  //     setTimeExpire({ minutes: updatedMinutes, seconds: updatedSeconds });
  //   }
  // }

  return (
    <ContainerScheduleTimer>
      <TimerDisplay>
        {concatenatedValue.includes("-") ? '00:00' : concatenatedValue}
      </TimerDisplay>
      <TimerDescription dangerouslySetInnerHTML={{ __html: content }} />
    </ContainerScheduleTimer>
  );
}
