import React from 'react'

import { getNameWeekSearch , getScheduleInfo } from 'Utils/Schedules';

import Input from 'Components/Form/Input';

export default function ModalCancel({data, sendEmail, setSendEmail, checkShow, setCheckShow, notes, setNotes}) {

    const _split = data.datetime.split('/');
    const labelWeek = getNameWeekSearch([..._split], true);
    const { months } = getScheduleInfo();

    const month = _split[1] < 10 ? Number(_split[1].split('0')[1]) : Number(_split[1]);
    return (
        <div className="content-modal-schedule modal-cancel-schedule">
            <div className="header-details-modal">
                <p className="title-modal-details"> Cancelar agendamento</p>
            </div>

            <div className="description-cancel-schedule">
                <p className="subtitle-description">Você tem certeza que deseja cancelar o agendamento abaixo?</p>

                <div>
                    <p> <strong>{data.firstName} {data.lastName}</strong> </p>
                    <p>{labelWeek}, {_split[0]} de {months[month - 1]} de {_split[2]}</p>
                    <p>{data.time} - {data.endTime}</p>
                    <p>{data.type}</p>
                </div>
            </div>

            <div className="checklist-modal-cancel">
                <div className={`container-modal-checklist ${ checkShow ? "check" : ""}`} onClick={() => setCheckShow(prev => !prev)}>
                    <div className="box-checklist-modal"> <div className="background" /> </div>
                    <p>Marcar como no-show</p>
                </div>

                <div className={`container-modal-checklist ${ sendEmail ? "send" : ""}`} onClick={() => setSendEmail(prev => !prev)}>
                <div className="box-checklist-modal"> <div className="background" /> </div>

                    <p>Enviar email de cancelamento ao cliente</p>
                </div>
            </div>

            <div className="details-info margin">
                    <div className="margin">
                        <Input label="Nota:"  placeholder="Incluir nota de cancelamento ( opcional )" value={notes} onChange={(({target}) => setNotes(target.value))}/>
                    </div>
            </div>
        </div>
    )
}
