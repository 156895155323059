import React, { useState } from "react";
import Modal from "react-modal";

import { Box } from "styles/globalComponents";

import { Loading } from "Utils/Loading";

import { AttachedFiles } from "./AttachedFiles";
import { AttachFiles } from "./AttachFiles";
import { Container } from "./styles";

interface CovenantCredialAttachProps {
  modalVisible: any;
  modalDismiss: any;
  documents: any;
  removeDocument: any;
  callbackFinish: any;
  budget: any;
}

export function CovenantCredialAttach({
  modalVisible,
  modalDismiss,
  documents,
  removeDocument,
  callbackFinish,
  budget,
}: CovenantCredialAttachProps) {
  const [loading, setLoading] = useState(false);

  function closeModal() {
    modalDismiss();
  }

  async function uploadFiles(files: any) {
    setLoading(true);
    await callbackFinish(files);
    setLoading(false);
  }

  function extractContent(inputString: any) {
    const pattern = /\/([^/]+)\/([^./]+)\./;
    const regex = new RegExp(pattern);
    const match = regex.exec(inputString);
    if (match && match[2]) {
      return match[2];
    }

    return "";
  }

  function convertDocumentsInfo(documents: any) {
    if (!documents) return [];

    const documentsList = documents?.map((document: any) => {
      const image = new Image();
      image.src = document.base64;
      const docFormat = document.file_path.slice(-3);

      return {
        id: extractContent(document.file_path),
        file_path: document.file_path,
        filename: document.name,
        preview: `data:image/${docFormat};base64, ${document.base64}`,
        isImage: !document.file_path.endsWith(".pdf"),
      };
    });
    return documentsList;
  }

  const deleteDocument = async (file_path: any) => {
    try {
      setLoading(true);
      await removeDocument(file_path);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        bodyOpenClassName="modal-content-open"
        className="modal-content modal-content-cart"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
      >
        <div className="modal-wrapper-header-annex ">
          <h5>Anexo carteirinha</h5>

          <p>Insira aqui frente e verso da carteirinha.</p>
        </div>
        <Container className="modal-wrapper-body">
          {loading ? (
            <Loading classCss="loading-centered" />
          ) : (
            <div className="list-content">
              {convertDocumentsInfo(documents)?.map((document: any) => (
                <AttachedFiles
                  deleteDocument={deleteDocument}
                  documents={document}
                />
              ))}

              <Box>
                <AttachFiles
                  budget={budget}
                  closeModal={closeModal}
                  openFiles={uploadFiles}
                />
              </Box>
            </div>
          )}
        </Container>
      </Modal>
    </div>
  );
}
