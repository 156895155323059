import { TooltipIconProps } from "./interface";


export const TooltipIcon = ({ iconColor, ...props }: TooltipIconProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={iconColor}
      role="img"
      aria-label="Tooltip"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.48535 0C4.06935 0 0.485352 3.584 0.485352 8C0.485352 12.416 4.06935 16 8.48535 16C12.9014 16 16.4854 12.416 16.4854 8C16.4854 3.584 12.9014 0 8.48535 0ZM7.68535 4V5.6H9.28535V4H7.68535ZM7.68535 7.2V12H9.28535V7.2H7.68535ZM2.08535 8C2.08535 11.528 4.95735 14.4 8.48535 14.4C12.0134 14.4 14.8854 11.528 14.8854 8C14.8854 4.472 12.0134 1.6 8.48535 1.6C4.95735 1.6 2.08535 4.472 2.08535 8Z"
      />
    </svg>
  );
};