import React from "react";

import moment from "moment";
import { PatientType } from "Pages/Orders/Token/interface";

import { CopyToClipboard } from "Utils/Copy";

import { DetailsInfo } from "Components/DetailsInfo";

import { IAppointmentGender } from "../../../../../Dtos/IAppointmentGender";
import { Patient, PatientsContainer } from "./styles";

interface ClinicalAttendanceProps {
  patients: PatientType[];
  appointmentGender: IAppointmentGender;
  unit_name: string;
  waitRoomLink?: string;
  isTelemedicine: boolean;
}

export function ClinicalAttendance({
  patients,
  appointmentGender,
  unit_name,
  waitRoomLink,
  isTelemedicine,
}: ClinicalAttendanceProps) {
  return (
    <PatientsContainer>
      {patients.map((patient, index) => (
        <Patient key={index}>
          <div>
            <DetailsInfo
              label={`Paciente ${index + 1}: `}
              content={patient.full_name || patient.name}
            />
            <DetailsInfo
              label="Data de Nascimento: "
              content={patient.birthdate}
            />
            {patient.cpf && <DetailsInfo label="CPF: " content={patient.cpf} />}
            {patient.passport && (
              <DetailsInfo label="Passaporte: " content={patient.passport} />
            )}
            {patient.telephone && (
              <DetailsInfo label="Telefone: " content={patient.telephone} />
            )}
            <DetailsInfo label="E-mail: " content={patient.email} />
            {!!patient.schedule_id && (
              <DetailsInfo label="ID: " content={`Nº ${patient.schedule_id}`} />
            )}
            {waitRoomLink && (
              <dl className="consult-unit">
                <dt>Link da Consulta</dt>

                <button
                  onClick={() =>
                    CopyToClipboard({
                      text: encodeURI(waitRoomLink),
                      message: "Copiado !",
                    })
                  }
                  type="button"
                  className="button _xsmall"
                >
                  Copiar link
                </button>
              </dl>
            )}
          </div>
        </Patient>
      ))}

      <Patient>
        <div>
          <DetailsInfo
            label="Data da consulta"
            content={moment(new Date(appointmentGender.appointment_at)).format(
              "DD/MM/YYYY"
            )}
          />
          <DetailsInfo
            label="Horário da consulta"
            content={moment(new Date(appointmentGender.appointment_at)).format(
              "HH:mm[h]"
            )}
          />
          <DetailsInfo
            label="Id do agendamento"
            content={appointmentGender.id_appointment}
          />
          <DetailsInfo
            label="Local de atendimento"
            content={isTelemedicine ? "Consulta por telemedicina" : unit_name}
          />
        </div>
      </Patient>
    </PatientsContainer>
  );
}
