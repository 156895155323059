import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Element } from "react-scroll";

import { Header, PaginationWrapper } from "Pages/HomeOrders/styles";
import { ListTable } from "Pages/RegistrationCleaning/components/ListTable";
import { ModalHandler } from "Pages/RegistrationCleaning/components/ModalHandler";
import {
  ComponentPaginationProps,
  DataPersonPagination,
  DataPerson,
  RouteParamsInterface,
  ModalInterface,
} from "Pages/RegistrationCleaning/interface";
import { TitleDocument } from "Pages/RegistrationCleaning/styles";


import { duplicateRegistration } from "Requests/Person";

import { selectPersonDuplicate } from "Actions/DuplicatePerson";

import { Store } from "Reducers/interface";

import { createToast } from "Utils/toastFunc";
import { verifyOfAge } from "Utils/VerifyOfAge";

import Pagination from "Components/Nav/Pagination";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

export const DependentsSelection: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { PersonDuplicate } = useSelector((store: Store) => store);
  const [modalResource, setModalResource] = useState<ModalInterface>({
    isVisible: false,
    handleDismiss: () => {},
    title: "",
    modal: "default",
    handleModal: () => {},
    messageButton: "",
    messageButtonCancelModal: "",
    icon: "",
  });

  const [data, setData] = useState<DataPerson[]>();
  const [pagination, setPagination] = useState<DataPersonPagination>();
  const [loading, setLoading] = useState(false);
  const [personsSelect, setPersonsSelect] = useState<DataPerson[]>([]);
  const { document } = useParams<RouteParamsInterface>();
  async function handlePagination(e: ComponentPaginationProps) {
    if (pagination?.current_page === e.currentPage) return;
    await fetchData(e.currentPage);
  }

  function handleCloseModal() {
    setModalResource({
      isVisible: false,
      handleDismiss: () => {},
      title: "",
      modal: "default",
      handleModal: () => {},
      messageButton: "",
      messageButtonCancelModal: "",
      icon: "",
    });
  }

  function handleCheckBoxSelected(person: DataPerson, value: boolean) {
    if (!value)
      return setPersonsSelect((prev: DataPerson[]) =>
        prev.filter((item: DataPerson) => item.id !== person.id)
      );

    setModalResource({
      ...modalResource,
      isVisible: true,
      modal: "dependent_alert",
      messageButton: "vincular como dependente",
      messageButtonCancelModal: "voltar",
      handleModal: () => {
        validateDependent(person);
      },
      personData: person,
      handleDismiss: () => handleCloseModal(),
    });
  }

  function validateDependent(dependent: DataPerson) {
    if (verifyOfAge(new Date(dependent.birthdate))) {
      // maior de idade
      return editPersonModal(dependent);
    }

    // menor de idade
    setModalResource({
      ...modalResource,
      isVisible: true,
      modal: "dependent_has_document",
      messageButton: "sim",
      messageButtonCancelModal: "não",
      handleModal: () => {
        // tem documento
        editPersonModal(dependent);
      },
      handleDismiss: () => {
        // não tem documento
        const newDependent = {
          ...dependent,
        };
        editPersonModal(newDependent, true);
      },
    });
  }

  function editPersonModal(person: DataPerson, withoutDocument = false) {
    setModalResource({
      ...modalResource,
      isVisible: true,
      withoutDocument,
      modal: "dependent_edit",
      handleSubmit: (dependent) => {
        setDependentSelected(dependent);
      },
      personData: person,
      handleDismiss: () => handleCloseModal(),
    });
  }

  const setDependentSelected = useCallback(
    (dependent: DataPerson) => {
      setPersonsSelect((prev: DataPerson[]) => [...prev, dependent]);
    },
    [personsSelect]
  );

  const handleDependentsStore = useCallback(() => {
    dispatch(
      selectPersonDuplicate({
        ...PersonDuplicate,
        dependents: personsSelect,
      })
    );
    handleFlow();
  }, [personsSelect]);

  function handleFlow() {
    history.push(`/limpeza-cadastro/${document}/review`);
  }

  async function fetchData(page?: any) {
    try {
      setLoading(true);
      const except = [
        PersonDuplicate?.main,
        ...PersonDuplicate?.merge,
        ...PersonDuplicate?.remove,
      ];

      const response = await duplicateRegistration(document, page, {
        exclude: except,
      });
      const {data} = response;
      setData(data.data);

      setPagination({
        current_page: data.current_page,
        total_pages: data.last_page,
        total: data.total,
      });

      if (!data.data.length) {
        handleFlow();
      }
      return data.data;
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao solicitar as pessoas duplicadas!"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!PersonDuplicate?.main) {
      return history.push(`/limpeza-cadastro/${document}/merge`);
    }

    dispatch(
      selectPersonDuplicate({ ...PersonDuplicate, dependents: [], remove: [] })
    );

    fetchData();
  }, []);

  useEffect(() => {
    if (personsSelect.length > 0) {
      if (pagination?.total === personsSelect.length) {
        setModalResource({
          ...modalResource,
          isVisible: true,
          modal: "dependent_continue",
          messageButton: "",
          messageButtonCancelModal: "",
          handleModal: () => {
            handleCloseModal();
            handleDependentsStore();
          },
        });
        return;
      }
      setModalResource({
        ...modalResource,
        isVisible: true,
        modal: "dependent_continue_or_more",
        messageButton: "",
        messageButtonCancelModal: "",
        handleModal: () => {
          handleCloseModal();
          handleDependentsStore();
        },
        handleDismiss: () => handleCloseModal(),
      });
    }
  }, [personsSelect]);

  return (
    <PageWrapper className="entry-app-content order-home-list">
      <ModalHandler {...modalResource} />
      <div className="content-holder">
        <Header>
          <h1>Limpeza de Cadastro</h1>
          <Breadcrumb
            list={[
              {
                path: "/pessoas",
                label: "PESSOAS",
                current: false,
              },
              {
                path: "#",
                label: "LIMPEZA DE CADASTRO",
                current: true,
              },
            ]}
          />
        </Header>

        <div className="order-home-filter">
          <div className="page-content page-content-list" id="ScrollDomiciliar">
            <TitleDocument>
              Deseja atribuir um dos cadastros restantes como dependente?
            </TitleDocument>

            <Element name="ScrollableDomiciliarOrders" />
            <ListTable
              data={data}
              loading={loading}
              personsSelect={personsSelect}
              handleCheckBoxSelected={handleCheckBoxSelected}
              modalResource={modalResource}
              setModalResource={setModalResource}
            />
          </div>
        </div>
      </div>

      <PaginationWrapper>
        <>
          {pagination?.total_pages && (
            <>
              <Pagination
                totalRecords={pagination.total_pages}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={(e: ComponentPaginationProps) =>
                  handlePagination(e)
                }
              />
            </>
          )}
          {personsSelect.length > 0 ? (
            <button className="button" onClick={() => handleDependentsStore()}>
              vincular como dependente
            </button>
          ) : (
            <button className="button" onClick={() => handleFlow()}>
              continuar sem vincular
            </button>
          )}
        </>
      </PaginationWrapper>
    </PageWrapper>
  );
};
