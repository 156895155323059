import styled from "styled-components";

export const SelectTitle = styled.h2`
  color: var(--color-purple);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
`;

export const Container = styled.div`
  &.gray-background {
    background: #f5f5f5;
  }

  &.card-search-information {
    padding-right: 20px;
    padding-left: 20px;
    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0;
  }
`;
