import React from "react";
import Modal from "react-modal";

import { Button } from "Components/Button";

import Attention from "Assets/Images/atention.svg";

import { ModalProps } from "./interface";
import { Container } from "./styles";

export const ModalAction = ({ modalEditOptions, modalOpened }: ModalProps) => {
  return (
    <Modal
      isOpen={modalOpened}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
    >
      <Container>
        <img src={Attention} alt="" />

        <h4>{modalEditOptions?.texts.head}</h4>
        <p>{modalEditOptions?.texts.additional}</p>
        <div className="buttons-container">
          <Button textButton onClick={modalEditOptions?.cancel}>
            {modalEditOptions?.texts.buttons.cancel}
          </Button>
          <Button borderRadius="111" onClick={modalEditOptions?.accept}>
            {modalEditOptions?.texts.buttons.accept}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
