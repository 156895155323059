import { documentValidation } from "Requests/ApiValidation";

export const isCPF = (cpf) => {
  if (!cpf) return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  let add = 0;
  let i = 0;
  let rev;
  for (i = 0; i < 9; i += 1) add += Number(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(9))) return false;
  add = 0;
  for (i = 0; i < 10; i += 1) add += Number(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(10))) return false;
  return true;
};

export const validatorDocument = (document = "") => {
  const includeOneValue = ["cpf", "passport"].includes(document);
  return includeOneValue;
};

export const validatorEmail = (Email = "") => {
  const email = Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  return !!email;
};

export const isName = (fullname) => {
  const [, lastname] = fullname.split(" ");

  if (!lastname) return false;

  return true;
};

export const formatterSchemaError = (path, type) => {
  if (type === "cellphone") return "Telefone inválido";
  if (type === "birthday") return "Data inválida";
  if (type === "cpf") return "CPF inválido";
  if (type === "email") return "Informe um email valido";
  if (type === "name") return "Informe um nome e sobrenome";
  if (path === "min") {
    if (type === "cep") return "informe um cep valido";
    if (type === "password") return "A senha deve conter 6 digitos";
  }

  if (path === "oneOf") return "As senhas precisam ser iguais.";

  return "Campo obrigatório";
};

export const uniqueDocument = async ({
  document,
  type,
  id = null,
  ignore_duplicated = false,
}) => {
  try {
    await documentValidation({ document, type, id, ignore_duplicated });
    return true;
  } catch (error) {
    return false;
  }
};

export function validateFullname(fullname = "") {
  const _arr = fullname
    .trim()
    .split(" ")
    .filter((n) => n);

  if (_arr.length < 2) return false;

  const _cloneArr = [..._arr];

  const _first = _cloneArr.shift();
  const _last = _cloneArr.join(" ");

  return true;
}
