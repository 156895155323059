import API from "Services/Api";

export const searchCovenantsInfo = async (covenant: any) => {
  try {
    const response = await API.get(`covenant/filter/${covenant}`);

    return response.data;
  } catch (error) {
    console.log({ error });
  }
};

export const saveCovenantsOrder = async (covenantList: any) => {
  try {
    await API.post("covenant/manager/order", {
      covenantList,
    });
  } catch (error) {
    throw new Error("Não foi possível salvar a ordenação de convênios");
  }
};
