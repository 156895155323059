import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { Container } from "./styles";

export const CartCovenant = () => {
  const { selectedCovenant } = useBudgetContext();

  const { covenant, covenant_plan } = selectedCovenant ?? {};
  const { covenant: covenantName } = covenant ?? {};
  const { covenant_plans_name } = covenant_plan ?? {};

  return (
    <Container>
      <Box display="flex" justify="space-between">
        <span>Convênio</span>
        <span>{covenantName}</span>
      </Box>
      <Box display="flex" justify="space-between" mt="12">
        <span>Plano</span>
        <span>{covenant_plans_name}</span>
      </Box>
    </Container>
  );
};
