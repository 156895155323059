import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    font-family: var(--font-display);
    font-size: 14px;
    line-height: 22px;
    max-width: 155px;

    &.lineThrough {
      text-decoration: line-through;
    }
  }

  .price {
    white-space: nowrap;
    font-size: 15px;
    color: #666666;
    font-family: "Saira", sans-serif;
    flex: 0 0 102px;
    text-align: right;
    max-width: 102px;

    &.lineThrough {
      text-decoration: line-through;
    }
  }
`;
