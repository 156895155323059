import React from "react";

import { Box } from "styles/globalComponents";

import { ModalProps } from "./interface";
import { ModalContainer } from "./styles";

export const ModalContent = ({
  isOpen,
  children,
  requestClose,
  closeOnOverlay,
  maxWidth,
  maxHeight,
  notRadius,
}: ModalProps) => {
  return (
    <ModalContainer
      notRadius={notRadius}
      isOpen={isOpen}
      bodyOpenClassName="modal-content-open"
      className="modal-content"
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={closeOnOverlay}
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      preventScroll
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    >
      <Box display="flex" direction="column" className="content" gap="0">
        {children}
      </Box>
    </ModalContainer>
  );
};
