interface Filters {
  [key: string]: any;
}

export const createFilterParams = (filters: Filters) => {
  const obj: Filters = {};

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      obj[key] = filters[key];
    }
  });

  return obj;
};

export const createObjParams = (filters: Object) => {
  let filtersList = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      filtersList = {
        ...filtersList,
        [key]: value,
      };
    }
  });

  return filtersList;
};

export const createObjParamsTeste = <T>(filtersParams: Object): T => {
  let initFilters: T = {} as T;

  Object.entries(filtersParams).forEach(([key, value]) => {
    if (value) {
      initFilters = {
        ...initFilters,
        [key]: value,
      };
    }
  });

  return initFilters;
};
