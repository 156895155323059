import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";

import {
  editInsurance,
  getImageCovenant,
  getInsuranceId,
  uploadImageCovenant,
} from "Requests/RequestInsurance";

import { useRegions } from "Hooks/useRegions";

import { createToast } from "Utils/toastFunc";
import { getInitialUnitsValue } from "Utils/Units/Units";

import { LoadingText } from "Components/LoadingText";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { HealthInsuranceForm } from "../HealthInsuranceForm";
import { formatInitialValuesonEdit } from "../HealthInsuranceForm/utils";
import { CovenantImages, CovenantInfo } from "./interface";
import { Container } from "./style";

export const EditHealthInsurance = () => {
  const [covenantInfo, setCovenantInfo] = useState<CovenantInfo | undefined>(
    undefined
  );

  const history = useHistory();
  const { id: covenantId } = useParams<{ id: string }>();
  const { regions } = useRegions();

  function stopHealthInsuranceCreation() {
    return history.push("/convenios");
  }

  const getCovenantInfo = async () => {
    try {
      const covenantResponse = await getInsuranceId(covenantId);

      const covenantLogo = await getImageCovenant(
        covenantResponse.image,
        true,
        `logo.png`
      );
      const covenantImages = await fetchCovenantImages(
        covenantResponse.covenant_images
      );

      covenantResponse.image = covenantLogo;
      covenantResponse.covenant_images = covenantImages;

      setCovenantInfo(covenantResponse);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCovenantImages = async (covenantImgs: CovenantImages[]) => {
    return await Promise.all(arrayOfImagesRequest(covenantImgs));
  };

  const arrayOfImagesRequest = (covenantImgs: CovenantImages[]) => {
    const conenantInfos = covenantImgs.map((item) => ({ ...item }));

    return conenantInfos.map(async (item: CovenantImages, index: number) => {
      if (item.img) {
        return {
          ...item,
          img: await getImageCovenant(
            item.img,
            true,
            `anexo-${index + 1}.${item.img.split(".").pop()}`
          ),
        };
      }
    });
  };

  const handleSubmit = async (formValues: any, formDocs: any) => {
    try {
      delete formValues.docs;
      delete formValues.file;
      delete formValues.covenant_plans_list;

      editInsurance(formValues, covenantId);
      await uploadImageCovenant(covenantId, formDocs);

      createToast(
        "success",
        `Convênio ${formValues?.covenant} atualizado com sucesso.`
      );
    } catch (error) {
      createToast("error", "Não foi possível atualizar os dados do convênio");
    } finally {
      history.push("/convenios");
    }
  };

  const initialValues = useMemo(() => {
    if (covenantInfo) {
      return {
        ...formatInitialValuesonEdit(covenantInfo),
        units: getInitialUnitsValue(covenantInfo?.units, regions),
      };
    }
  }, [covenantInfo]);

  useEffect(() => {
    getCovenantInfo();
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <Container className="content-holder holderPanelActive">
        <div className="page-heading no-shadow">
          <div className="wrapper-header-content">
            <PageHeader title="Cadastrar convênios">
              <Breadcrumb
                list={[
                  {
                    path: "/convenios",
                    label: "Convênios",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Cadastrar Convênio",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            <div className="actions-holder">
              <button
                type="submit"
                className="button radiusButtonBorder positionButton"
                form="submit"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>

        {initialValues ? (
          <HealthInsuranceForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        ) : (
          <LoadingText text="Buscando dados do convênio" />
        )}
      </Container>
    </PageWrapper>
  );
};
