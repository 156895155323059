import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;

  .flex-dose {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .align-center {
    justify-self: center;
  }
`;
