import styled from "styled-components";

export const UnitListContainer = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;

    tr,
    th,
    td {
      font-size: 13px;
      text-align: left;
    }

    thead {
      tr {
        & th:last-child {
          text-align: center;
        }

        th {
          padding: 8px 5px;
          font-family: var(--font-display);
          font-weight: 600;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #fff;
        }
        td {
          padding: 8px 5px;
        }
      }
    }
    thead,
    tbody {
      tr {
        th,
        td {
          padding-right: 15px;
        }

        th:first-child,
        td:first-child,
        th:nth-child(2),
        td:nth-child(2),
        th:last-child,
        td:last-child {
          width: 15%;
        }
        th:nth-child(3),
        td:nth-child(3) {
          width: 22%;
        }
        th:nth-child(4),
        td:nth-child(4) {
          width: 22%;
        }
      }
    }
  }
`;
