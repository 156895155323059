import React from "react";

import { HistoryModalInterface } from "Pages/RegistrationCleaning/interface";
import { TableHistory } from "Pages/RegistrationCleaning/styles";

import { dateFormatter } from "Utils/Date";

export const History = ({ historyData }: HistoryModalInterface) => {
  const formatedData = historyData?.operations.map((item) => {
    const labels = {
      order: "Pedido",
      report: "Laudo",
      budget: "Orçamento",
      default: "-",
    };
    const formatedDate = dateFormatter(item.created_at, true);
    return {
      operation: labels[item.operation ?? "default"],
      formatedDate: formatedDate[0],
    };
  });

  return (
    <div>
      <TableHistory>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Data da solicitação</th>
          </tr>
        </thead>

        <tbody>
          {formatedData?.map((item, key) => (
            <tr key={`history_${key}`}>
              <td>{item.operation}</td>
              <td>{item.formatedDate}</td>
            </tr>
          ))}
        </tbody>
      </TableHistory>
    </div>
  );
};
