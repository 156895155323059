import React from "react";
import Modal from "react-modal";

import { Button } from "Components/Button";

import { ModalSelectNewDateProps } from "./interface";
import { ModalContent } from "./style";

export const ModalSelectNewDate = ({
  isOpen,
  onClick,
  loading,
}: ModalSelectNewDateProps) => {
  return (
    <Modal
      isOpen={isOpen}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-content-information"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
    >
      <ModalContent>
        <h2>Atenção!</h2>
        <p>
          O horário escolhido não está mais disponível. Por favor, escolha uma
          novo horário para finalizar seu agendamento.
        </p>
        {/* @ts-ignore */}
        <Button onClick={onClick} loading={loading}>
          VER OPÇÕES DE HORÁRIO
        </Button>
      </ModalContent>
    </Modal>
  );
};
