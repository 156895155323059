import React from "react";
import Skeleton from "react-loading-skeleton";

import { set, getHours, format } from "date-fns";
import moment from "moment";

import { getAppointments } from "Requests/Schedules";

import { useDebounce } from "Hooks/useDebounce";

import { filterHoursWeek } from "../../../../Utils/Schedules";
import { CardHours } from "../../Cards/CardHours";
import { CardTableSchedule } from "../../Cards/CardTableSchedule";
import { ModalDetailsAppointments } from "../../Modals/ModalDetailsAppointments";

import "react-loading-skeleton/dist/skeleton.css";

export function WeekCalendar({
  hours,
  buttonClick,
  calendarSelect,
  setTotalSechedules,
  setWeekData,
}) {
  const [weeks, setWeeks] = React.useState([]);
  const [total_length, setTotalLength] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [lengthWeeks, setLenthWeeks] = React.useState(0);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [dataModal, setDataModal] = React.useState({});

  const fetchAppointments = useDebounce(
    async () => {
      try {
        setLoading(true);
        let hoursFormatter = [
          "07:30",
          "08:30",
          "09:30",
          "10:30",
          "11:30",
          "12:30",
          "13:30",
          "14:00",
          "15:00",
        ];
        if (
          calendarSelect.name.includes("RJ") ||
          calendarSelect.name.includes("Niteroi")
        ) {
          hoursFormatter = [
            "07:30",
            "08:30",
            "09:30",
            "10:30",
            "11:30",
            "12:30",
            "13:30",
            "14:30",
          ];
        }
        if (calendarSelect.name.includes("SP")) {
          hoursFormatter = [
            "07:30",
            "08:30",
            "09:30",
            "10:30",
            "11:30",
            "12:30",
            "13:30",
            "14:00",
            "15:00",
          ];
        }

        // horários de clínica
        if (calendarSelect.name.includes("Clínica")) {
          // horários de 8h as 15:45 com intervalos de 15 minutos

          const date = new Date(0);
          const hours = [];
          const initialHour = set(date, { minutes: 0, hours: 8, seconds: 0 });
          let currentHour = getHours(initialHour);

          while (currentHour < 16) {
            hours.push(format(initialHour, "HH:mm"));
            initialHour.setMinutes(initialHour.getMinutes() + 15);
            currentHour = getHours(initialHour);
          }

          hoursFormatter = hours;
        }

        const res = await getAppointments(
          { type: "week", count: buttonClick, calendarId: calendarSelect.id },
          hoursFormatter
        );

        if (res.reducer.length < 7 && calendarSelect.name.includes("Clínica")) {
          while (res.reducer.length < 7) {
            const lastReducer = res.reducer[res.reducer.length - 1];

            const data = {};

            Object.entries(lastReducer.data).forEach(([key, value]) => {
              data[key] = [null];
            });

            const currentDate = moment(
              new Date(lastReducer.year, lastReducer.month - 1, lastReducer.day)
            ).add("days", 1);

            res.reducer.push({
              day: currentDate.format("DD"),
              month: currentDate.format("MM"),
              year: currentDate.format("YYYY"),
              title: currentDate.format("ddd").toUpperCase(),
              data,
            });
          }
        }

        setWeeks(res.reducer);
        const dates = res.reducer[0];

        const month = Number(dates.month);
        const day = Number(dates.day);
        const year = Number(dates.year);

        setWeekData({ day, month, year });
        setLenthWeeks(res.reducer.length);

        setTotalLength(res.array_total);
        setTotalSechedules(res.total_schedules);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    1000,
    [buttonClick, calendarSelect]
  );

  React.useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  return (
    <>
      <ModalDetailsAppointments
        modalDismiss={() => setModalVisible(false)}
        modalVisible={modalVisible}
        data={dataModal}
      />
      <div
        className={`
            schedule-week 
            ${!loading && weeks.length ? "schedule-before" : ""} 
            ${loading ? "schedule-center" : ""} 
            ${!weeks.length ? "schedule-center" : ""} 
            ${total_length.length < 3 ? `i-${total_length.length}` : ""}
            ${total_length.length >= 3 ? `width-${lengthWeeks}` : ""}
        `}
      >
        <div className="schedule-week-flex">
          {!loading ? (
            weeks.length ? (
              <>
                <CardHours hours={hours} />

                {weeks?.map((week) => (
                  <CardTableSchedule
                    setDataModal={setDataModal}
                    setModalVisible={setModalVisible}
                    total_length={total_length}
                    week={week}
                    title={week.title}
                    day={week.day}
                    month={week.month}
                    data={filterHoursWeek(
                      week.data,
                      total_length,
                      calendarSelect.name.includes("Clínica")
                    )}
                  />
                ))}
              </>
            ) : (
              <div className="schedule-center">
                <p className="text-saira text-purple text-center text-size text-normal">
                  Nenhum agendamento disponível...
                </p>
              </div>
            )
          ) : (
            <div className="skeleton">
              <Skeleton height={47} width={1635} />
              <Skeleton height={90} width={1635} count={6} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
