import React from "react";

import { Loading } from "Utils/Loading";

import InputDateRange from "Components/Form/InputDateRange";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { WalletLabiCredits } from "../../Requests/LabiCredits";
import { dateFormatter } from "../../Utils/Date";
import { createToast } from "../../Utils/toastFunc";

const typesTransactions = [
  { id: "1", name: "Credito" },
  { id: "2", name: "Debito" },
  { id: "3", name: "Estorno" },
  { id: "4", name: "Expirado" },
  { id: "6", name: "Estorno Credito" },
  { id: "7", name: "Cancelamento" },
];

const initialDates = {
  dates: {
    startDate: null,
    endDate: null,
  },
};

export default function LabiCredits() {
  const [transaction, setTransaction] = React.useState();
  const [pagination, setPagination] = React.useState({});
  const [value, setValue] = React.useState("");
  const [movements, setMovements] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dates, setDates] = React.useState(initialDates);

  function handleDatesChange(value) {
    setDates({
      dates: value,
    });
  }

  function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;
    fetchData(e.currentPage);
  }

  async function ClearSearch() {
    setPagination({});
    try {
      setPagination({});
      setDates(initialDates);
      setTransaction(null);
      fetchData(1, true);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao limpar o filtro");
    }
  }

  async function fetchData(page, clear) {
    !page && setPagination({});

    try {
      setLoading(true);
      const date = {
        start: dates.dates.startDate
          ? dates.dates.startDate.format("YYYY-MM-DD")
          : null,
        end: dates.dates.endDate
          ? dates.dates.endDate.format("YYYY-MM-DD")
          : dates.dates.startDate
          ? dates.dates.startDate.format("YYYY-MM-DD")
          : null,
      };
      const [{ movements, pagination }] = await WalletLabiCredits(
        page,
        !clear
          ? { ...date, operation_id: transaction ? transaction.id : null }
          : null
      );

      setMovements(movements);
      setPagination(pagination[0]);
    } catch (error) {
      console.log(error);
      setMovements([]);
      setPagination({});
      createToast("error", "ocorreu um erro ao buscar as transações.");
    } finally {
      setLoading(false);
    }
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="7" className="text-center">
          <span className="loading-persons">
            <Loading />
          </span>
        </td>
      </tr>
    );
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  function switchType(key) {
    switch (key) {
      case 1:
        return "Recebido";
      case 2:
        return "Usado";
      case 3:
        return "Devolvido";
      case 4:
        return "Expirado";
      case 5:
        return "Esperando";
      case 6:
        return "Pré Crédito Reembolsado";
      case 7:
        return "Cancelado";
      default:
        return "Não foi possível localizar";
    }
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading inputs-solid">
          <div className="wrapper-header-content">
            <PageHeader title="Movimentações Labi">
              <Breadcrumb
                list={[
                  {
                    path: "/labi-creditos",
                    label: "Labi Créditos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Movimentações Labi Créditos",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
          <div>
            <div className="flex-items generate-margin-1">
              <div className="select-search-submit-types">
                <SelectSearch
                  id="calendars_id"
                  type="text"
                  value={transaction}
                  error=""
                  isMulti
                  isMoreSelect={false}
                  options={typesTransactions}
                  loading={!typesTransactions.length}
                  readOnly={!typesTransactions.length}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Tipo de Transação"
                  onChange={(value) => {
                    setTransaction(value);
                  }}
                />
              </div>
              <div>
                <InputDateRange
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  startDateId="start_date"
                  endDateId="end_date"
                  onDatesChange={(value) => handleDatesChange(value)}
                  openInModal={false}
                  dates={dates.dates}
                  blockPastChanges={false}
                  withClear
                />
              </div>
              <button
                className="button"
                onClick={async () => await fetchData()}
              >
                Filtrar
              </button>
              <button className="button _white" onClick={ClearSearch}>
                Limpar
              </button>
            </div>
          </div>
        </div>
        <div className="page-content page-content-list">
          <table className="table-content small-separate details">
            <thead>
              <tr>
                <th>Id</th>
                <th>Data</th>
                <th>Tipo de operação</th>
                <th>Valor</th>
                <th>Data de expiração</th>
                <th>Id do Pedido</th>
                <th>Carteira</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !movements.length ? (
                  <tr>
                    <td colSpan="7" className="text-center text-purple">
                      Oops! Parece que nada foi encontrado...
                    </td>
                  </tr>
                ) : (
                  movements.map((movement) => (
                    <tr key={movement.id_movement}>
                      <td>{movement.id_movement}</td>
                      <td>{dateFormatter(movement.created_at)[0]}</td>
                      <td>{switchType(movement.operation_id)}</td>
                      <td>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(movement.amount)}
                      </td>
                      <td>
                        {movement.valid_at === "None"
                          ? "Não expirável"
                          : dateFormatter(movement.valid_at)[0]}
                      </td>
                      <td>{movement.order_id}</td>
                      <td> {movement.wallet_id} </td>
                    </tr>
                  ))
                )
              ) : (
                _showLoading()
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="pagination-wrapper">
        {!loading && (
          <Pagination
            totalRecords={pagination?.total_pages}
            currentPageSelected={Number(pagination?.page)}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e) => handlePagination(e)}
          />
        )}
      </div>
    </PageWrapper>
  );
}
