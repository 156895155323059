import Axios from "axios";
import { CreateOrderType } from "Pages/Orders/CreateOrder/components/Patient/components/types";
import { getToken } from "Services/Authentication";




export const createBudgetOrder = async (order: CreateOrderType) => {

  const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getToken()}`
    },
  });

  try {
    const createdOrder = await API.post('/orders/colabi/data', {
      ...order
    })

    return createdOrder.data

  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao criar o pedido";
  }
}