import React from "react";

import { DependentAlertInterface } from "Pages/RegistrationCleaning/interface";
import {
  TextModal,
  ClientViewContainer,
} from "Pages/RegistrationCleaning/styles";

import AcceptIcon from "Assets/Images/acceptIconGreen.svg";
import RejectedIcon from "Assets/Images/rejectedIcon.svg";

export const DependentAlert = ({ personData }: DependentAlertInterface) => {
  return (
    <>
      <TextModal fontWeight={400}>
        {`Você tem certeza que deseja vincular ${personData?.full_name} como dependente? Ao vincular um dependente a uma pessoa, esse responsável terá acesso a:`}
      </TextModal>
      <ClientViewContainer>
        <span>acesso</span>
        <span>disponibilidade</span>

        <p>Acessar o laudo avulso (protocolo e senha)</p>
        <img src={AcceptIcon} alt="icone" />

        <p>Visualizar histórico de pedidos</p>
        <img src={AcceptIcon} alt="icone" />

        <p>Visualiza histórico de resultados no site</p>
        <img src={RejectedIcon} alt="icone" />
      </ClientViewContainer>
    </>
  );
};
