import { createToast } from "./toastFunc";

export const CopyToClipboard = ({ text = "", message = "texto copiado!" }) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = text;
    input.focus();
    input.select();
    const result = document.execCommand("copy");
    document.body.removeChild(input);

    if (result == "unsuccessful") {
      console.error("Failed to copy text.");
      return;
    }
  }

  createToast("success", message, 5000);
};
