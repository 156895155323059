import styled, { css, keyframes } from "styled-components";

interface LoaderProps {
  size: number;
  color: string;
  hoverColor: string;
  hasHover: boolean;
  borderWidth: number;
}

const animation = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<LoaderProps>`
  display: inline-block;
  width: ${(props) => props.size * 1.3}px;
  height: ${(props) => props.size * 1.3}px;

  &::after {
    content: " ";
    display: block;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 50%;
    margin: 0;
    line-height: 0;
    border: ${(props) => props.borderWidth}px solid ${(props) => props.color};
    border-color: ${(props) => props.color} transparent
      ${(props) => props.color} transparent;
    animation: ${animation} 1.2s linear infinite;
    transition: border-color 0.3s ease;
  }

  ${(props) =>
    props.hasHover &&
    css`
      &:hover::after {
        border: ${props.borderWidth}px solid ${props.hoverColor};
        border-color: ${props.hoverColor} transparent ${props.hoverColor}
          transparent;
      }
    `}
`;
