import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  GetDetails,
  GetHomeCollect,
  GetPaymentData,
  GetPatients,
  sendToMotion,
} from "Requests/Orders";
import { duplicateRegistration } from "Requests/Person";

import {
  selectPersonDuplicatedFlow,
  resetPersonDuplicatedFlow,
} from "Actions/DuplicatePerson";

import { EmptyValue } from "Utils/EmptyValue";
import FormatCurrency from "Utils/FormatCurrency";
import { MaskCPF, MaskCelphoneDDD } from "Utils/Masks";
import validatePermissions from "Utils/validatePermissions";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import PanelContent from "Components/Panels/PanelContent";
import CartItem from "Components/ServiceForm/CartItem";
import Breadcrumb from "Components/Shared/Breadcrumb";

import SelectUnits from "../../Components/Form/SelectUnits";
import { ModalSendMotionProduct } from "../../Components/Modals/ModalSendMotionProduct";
import { createToast } from "../../Utils/toastFunc";

export function SendMotion() {
  const dispatch = useDispatch();
  const [loadingDetails, setLoadingDetails] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [homeCollect, setHomeCollect] = React.useState({});
  const [loadingHomeCollect, setLoadingHomeCollect] = React.useState(false);
  const [paymentPersonData, setPaymentPersonData] = React.useState({});
  const [paymentData, setPaymentData] = React.useState([]);
  const [loadingPaymentData, setLoadingPaymentData] = React.useState(false);
  const [patients, setPatients] = React.useState([]);
  const [loadingPatients, setLoadingPatients] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [unit, setUnit] = useState([]);
  const [nameUnit, setNameUnit] = useState([]);
  const [options, setOptions] = useState([]);
  const [unitError, setUnitError] = useState("");
  const [IsModalOpen, setIsModalOpen] = useState(false);

  const user_permissions = useSelector((state) => state.User.permissions);

  const history = useHistory();
  const params = useParams();

  async function fetchDetails() {
    try {
      setLoadingDetails(true);
      const { data } = await GetDetails(params.id);
      if (data.motion_integration_status !== "Aguardando Envio") {
        createToast("error", "Esse produto não pode ser enviado ao Motion.");
        history.push("/pedidos");
      }
      setOptions(data.units);
      setDetails(data);
      setPaymentPersonData(data.payer);
    } catch (error) {
      console.log(error);
    }
    setLoadingDetails(false);
  }

  async function fetchHomeCollect() {
    try {
      setLoadingHomeCollect(true);
      const { data } = await GetHomeCollect(params.id);
      setHomeCollect(data);
    } catch (error) {
      console.log(error);
    }
    setLoadingHomeCollect(false);
  }

  async function fetchPaymentData() {
    try {
      setLoadingPaymentData(true);
      const { data } = await GetPaymentData(params.id);
      setPaymentData(data);
    } catch (error) {
      console.log(error);
    }
    setLoadingPaymentData(false);
  }

  async function fetchPatients() {
    try {
      setLoadingPatients(true);
      const { data } = await GetPatients(params.id);
      setPatients(data);
    } catch (error) {
      console.log(error);
    }
    setLoadingPatients(false);
  }

  function handleSubmit() {
    if (unit == null || unit == undefined || unit == "Unidades") {
      setUnitError("Campo obrigatório");
      return;
    }
    setIsModalOpen(true);
  }

  async function handleSubmitModal() {
    try {
      setLoading(true);
      const res = await sendToMotion({
        order_id: details.id,
        unit_integration_id: unit,
      });
      createToast("success", res.data.message);
      history.push("/pedidos");
    } catch (error) {
      console.log(error);
      createToast("error", error?.message);
      setIsModalOpen(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchDetails();
    fetchHomeCollect();
    fetchPaymentData();
    fetchPatients();
  }, []);

  useEffect(() => {
    const fetchDuplicated = async (document, page) => {
      const response = await duplicateRegistration(document, page);
      const { data } = response.data;

      if (data.length > 1) {
        dispatch(resetPersonDuplicatedFlow());
        dispatch(
          selectPersonDuplicatedFlow({
            id: paymentPersonData.id,
            origin: "send-motion",
            order_id: params.id,
          })
        );

        history.push(`/limpeza-cadastro/${document}/merge`);
      }
    };
    if (paymentPersonData.cpf) {
      fetchDuplicated(paymentPersonData.cpf, 1);
    }
  }, [paymentPersonData]);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder content-holder-no-filter remove-padding">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title={`Enviar Pedido Nº ${params.id}`}>
              <Breadcrumb
                list={[
                  {
                    path: "/",
                    label: "Dashboard",
                    current: false,
                  },
                  {
                    path: "/pedidos",
                    label: "Todos os pedidos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Enviar pedido",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
            <div className="actions-holder" style={{ flexWrap: "wrap" }}>
              <div
                className="teste  form-heading flexItems"
                style={{ flexWrap: "wrap" }}
              >
                <div className="flexItems" style={{ flexWrap: "wrap" }}>
                  <div className="sm-3">
                    <label
                      style={{ margin: "0px" }}
                      disabled={validatePermissions(
                        "Send Orders to Motion",
                        user_permissions
                      )}
                    >
                      Enviar ao motion
                    </label>
                  </div>
                  <div className="sm-6" style={{ marginBottom: "2px" }}>
                    <SelectUnits
                      placeholder="Unidades"
                      error={unitError}
                      onChange={(event) => {
                        const value = JSON.parse(event.target.value);
                        setUnitError("");
                        setUnit(value.id);
                        setNameUnit(value.name);
                      }}
                      options={options.length > 0 ? options : []}
                      readOnly={options.length <= 0}
                    />
                  </div>
                </div>
                <div className="flexItems" style={{ marginBottom: ".3rem" }}>
                  <button
                    type="button"
                    className="button _action"
                    onClick={
                      validatePermissions(
                        "Send Orders to Motion",
                        user_permissions
                      ) && handleSubmit
                    }
                    disabled={
                      options.length <= 0 ||
                      !validatePermissions(
                        "Send Orders to Motion",
                        user_permissions
                      )
                    }
                  >
                    Enviar
                  </button>

                  <button
                    onClick={() => {
                      history.goBack();
                    }}
                    type="button"
                    className="link link-danger"
                    style={{ padding: "11px 25px" }}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="sm-6">
              <PanelContent title="Dados Gerais" loading={loadingDetails}>
                <div className="data-holder-order token-area">
                  <div className="list-item">
                    <dt className="item-title">Pedido Colabi:</dt>
                    <dd className="item-data">
                      {details.id || "Não informado"}
                    </dd>
                  </div>
                  <div className="list-item">
                    <div className="row no-margin">
                      <dt className="item-title">Status:</dt>
                      <div className="tooltip-holder">
                        <span className="tooltip-icon icon-i">i</span>
                        <div className="tooltip-description large">
                          Pagamento pendente (ex: orçamento)
                          <br />
                          Pagamento expirado: necessário gerar novo link
                          <br />
                          Pago em dd/mm/aa hh:mm:ss
                          <br />
                          Falha no pagamento (ex: transação recusada pelo
                          cartão)
                          <br />
                          Reembolso total
                          <br />
                          Reembolso parcial (R$##,##)
                          <br />
                          Cancelado
                        </div>
                      </div>
                    </div>
                    <dd className="item-data">
                      {EmptyValue(details.status, "Não informado")}
                    </dd>
                  </div>
                  <div className="list-item">
                    <dt className="item-title">Nº pedido de origem:</dt>
                    <dd className="item-data">
                      {EmptyValue(details.origin_order_id, "Não informado")}
                    </dd>
                  </div>
                  <div className="list-item">
                    <dt className="item-title">Origem:</dt>
                    <dd className="item-data">
                      {EmptyValue(details.order_source, "Não informado")}
                    </dd>
                  </div>
                  <div className="list-item">
                    <dt className="item-title">Data pedido:</dt>
                    <dd className="item-data">
                      {EmptyValue(details.date, "Não informado")}
                    </dd>
                  </div>
                  <div className="list-item">
                    <dt className="item-title">Valor Final:</dt>
                    <dd className="item-data">R$ {details.paid_value}</dd>
                  </div>
                  <div className="list-item">
                    <dt className="item-title">Data integração:</dt>
                    <dd className="item-data">
                      {EmptyValue(
                        details.motion_integration_date,
                        "Não informado"
                      )}
                    </dd>
                  </div>
                </div>
              </PanelContent>
            </div>
            {paymentPersonData && (
              <div className="sm-6">
                <PanelContent title="Dados do Pagador" loading={loadingDetails}>
                  <div className="payer-data-holder-order token-area">
                    <div className="list-item">
                      <dt className="item-title">Nome:</dt>
                      <dd className="item-data">
                        {EmptyValue(paymentPersonData.name, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Email:</dt>
                      <dd className="item-data">
                        {EmptyValue(paymentPersonData.email, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">CPF:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          MaskCPF(paymentPersonData.cpf),
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Data de Nascimento:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          paymentPersonData.birthdate,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Telefone:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          MaskCelphoneDDD(paymentPersonData.phone),
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Origem:</dt>
                      <dd className="item-data">
                        {EmptyValue(paymentPersonData?.origin, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">ID:</dt>
                      <dd className="item-data">
                        {" "}
                        Nº {EmptyValue(paymentPersonData?.id, "Não informado")}
                      </dd>
                    </div>
                  </div>
                </PanelContent>
              </div>
            )}
            <div className="sm-12">
              <PanelContent
                title="Itens do pedido"
                loading={loadingDetails}
                expandable
              >
                <div className="cart-data-holder-order">
                  <div className="data-resume-heading">
                    <div>
                      <span>Nome</span>
                    </div>
                    <div>
                      <span>Quantidade</span>
                      <span>Valor unit.</span>
                      <span>Total</span>
                    </div>
                  </div>
                  <div className="data-resume-item">
                    {details.cart &&
                      details.cart.map((item) => (
                        <CartItem
                          entry
                          key={item.id}
                          isProduct
                          product={item}
                          readOnly
                        />
                      ))}
                  </div>
                  <div className="data-resume-values">
                    <div className="cart-item resume">
                      <p className="label">Subtotal</p>
                      <p className="price">R$ {details.subtotal}</p>
                    </div>
                    {details.coupon_code && (
                      <div className="cart-item resume">
                        <p className="label">Cupom aplicado</p>
                        <p className="coupon-code">{details.coupon_code}</p>
                      </div>
                    )}
                    {details.home_collected && (
                      <div className="cart-item resume">
                        <p className="label">Coleta Domiciliar</p>
                        <p className="price">R$ {details.home_collect_value}</p>
                      </div>
                    )}
                    <div className="cart-item resume">
                      <p className="label">Descontos</p>
                      <p className="price">R$ {details.discounts}</p>
                    </div>
                    <div className="cart-item resume">
                      <p className="label">Total</p>
                      <p className="price">R$ {details.total}</p>
                    </div>
                  </div>
                </div>
              </PanelContent>
            </div>
            {!loadingHomeCollect && Object.keys(homeCollect).length > 0 && (
              <div className="sm-12">
                <PanelContent
                  title="Coleta domiciliar"
                  loading={loadingHomeCollect}
                  expandable
                >
                  <div className="home-collect-holder-order token-area">
                    <div className="list-item">
                      <dt className="item-title">Data de coleta:</dt>
                      <dd className="item-data">
                        {EmptyValue(homeCollect?.collectDate, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">CEP:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          homeCollect.collectAddress?.zipcode,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Rua:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          homeCollect.collectAddress?.address,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Número:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          homeCollect.collectAddress?.number,
                          "Sem número"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Dia da semana:</dt>
                      <dd className="item-data">
                        {EmptyValue(homeCollect?.weekDay, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Bairro:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          homeCollect.collectAddress?.neighborhood,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Complemento:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          homeCollect.collectAddress?.complement,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Cidade/Estado:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          homeCollect.collectAddress?.city,
                          "Não informado"
                        )}
                        {`/${EmptyValue(
                          homeCollect.collectAddress?.state,
                          ""
                        )}`}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Taxa de Coleta:</dt>
                      <dd className="item-data">
                        R${" "}
                        {FormatCurrency(
                          EmptyValue(details?.home_collect_value, 0.0)
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Identificador/Agendamento:</dt>
                      <dd className="item-data">
                        {EmptyValue(homeCollect?.scheduleId, "Não informado")}
                      </dd>
                    </div>
                  </div>
                </PanelContent>
              </div>
            )}

            {paymentData?.map((payment, index) => (
              <div className="sm-12">
                <PanelContent
                  title={`Dados do pagamento ${index + 1}`}
                  loading={loadingPaymentData}
                  expandable
                >
                  <div className="payer-data-holder-order token-area">
                    <div className="list-item">
                      <dt className="item-title">Data de pagamento:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          payment.payment_transaction_date,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Forma de pagamento:</dt>
                      <dd className="item-data">
                        {EmptyValue(payment.form_payment, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Número de parcelas:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          payment.installments_number,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">NSU:</dt>
                      <dd className="item-data">
                        {EmptyValue(payment.nsu_number, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Link de pagamento:</dt>
                      <dd className="item-data">
                        {payment.payment_link ? (
                          <a
                            href={payment.payment_link}
                            className="link link-blue"
                          >
                            Acessar
                          </a>
                        ) : (
                          <span>Não informado</span>
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Bandeira do cartão:</dt>
                      <dd className="item-data">
                        {EmptyValue(
                          payment.credit_card_banner,
                          "Não informado"
                        )}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Tipo de pagamento:</dt>
                      <dd className="item-data">
                        {EmptyValue(payment.payment_type, "Não informado")}
                      </dd>
                    </div>
                    <div className="list-item">
                      <dt className="item-title">Cupom de desconto:</dt>
                      <dd className="item-data">
                        {EmptyValue(payment.coupon_code, "Não informado")}
                      </dd>
                    </div>
                  </div>
                </PanelContent>
              </div>
            ))}

            {patients.length > 0 &&
              patients.map((patient, index) => (
                <div className="sm-12">
                  <PanelContent
                    title={`Paciente ${index + 1}`}
                    loading={loadingPatients}
                    expandable
                  >
                    <div className="patients-data-holder-order token-area">
                      <div className="list-item">
                        <dt className="item-title">Nome:</dt>
                        <dd className="item-data">
                          {EmptyValue(patient.name, "Não informado")}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Documento:</dt>
                        <dd className="item-data">
                          {EmptyValue(
                            MaskCPF(patient.document),
                            "Não informado"
                          )}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Nascimento:</dt>
                        <dd className="item-data">
                          {EmptyValue(patient.birthdate, "Não informado")}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">E-mail:</dt>
                        <dd className="item-data">
                          {EmptyValue(patient.email, "Não informado")}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Telefone:</dt>
                        <dd className="item-data">
                          {EmptyValue(patient.phone, "Não informado")}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Idade:</dt>
                        <dd className="item-data">
                          {patient.age ? `${patient.age}` : "Não informada"}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Nome da mãe:</dt>
                        <dd className="item-data">
                          {EmptyValue(patient.mother_name, "Não informado")}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Peso:</dt>
                        <dd className="item-data">
                          {patient.weight
                            ? `${patient.weight} kgs`
                            : "Não informado"}
                        </dd>
                      </div>
                      <div className="list-item">
                        <dt className="item-title">Altura:</dt>
                        <dd className="item-data">
                          {EmptyValue(
                            patient.height
                              ? `${patient.height} m`
                              : "Não informado"
                          )}
                        </dd>
                      </div>
                      {patient.symptoms && (
                        <div className="list-item">
                          <dt className="item-title">Sintomas:</dt>
                          <dd className="item-data">{patient.symptoms}</dd>
                        </div>
                      )}
                      {patient.gender === "Feminino" && (
                        <div className="list-item">
                          <dt className="item-title">
                            Data da última menstruação:
                          </dt>
                          <dd className="item-data">
                            {EmptyValue(
                              patient.last_menstruation_period,
                              "Não informada"
                            )}
                          </dd>
                        </div>
                      )}
                      {patient.diseases && (
                        <div className="list-item">
                          <dt className="item-title">Doenças:</dt>
                          <dd className="item-data">{patient.diseases}</dd>
                        </div>
                      )}
                      {patient.drugs && (
                        <div className="list-item">
                          <dt className="item-title">Medicamentos:</dt>
                          <dd className="item-data">{patient.drugs}</dd>
                        </div>
                      )}
                      <div className="list-item">
                        <dt className="item-title">Gênero:</dt>
                        <dd className="item-data">
                          {EmptyValue(patient.gender, "Não informado")}
                        </dd>
                      </div>
                      {patient.exams && (
                        <div className="list-item">
                          <dt className="item-title">Exames:</dt>
                          <dd className="item-data">{patient.exams}</dd>
                        </div>
                      )}
                    </div>
                  </PanelContent>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ModalSendMotionProduct
        modalVisible={IsModalOpen}
        modalDismiss={() => setIsModalOpen(false)}
        order={params.id}
        handleSubmit={handleSubmitModal}
        loading={loading}
        unit={nameUnit}
      />
    </PageWrapper>
  );
}
