import React from "react";
import Modal from "react-modal";

import { ViewProduct } from "../../Components/ViewProduct";
import { ProductModalProps } from "./interface";

export const ModalViewProduct = ({
  openModal,
  closeModal,
  idSelected,
}: ProductModalProps) => {
  return (
    <Modal
      isOpen={openModal}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header modal-product"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={closeModal}
    >
      <ViewProduct closeModal={closeModal} idSelected={idSelected} />
    </Modal>
  );
};
