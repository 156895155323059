import { useQuery } from "react-query";

import { getProductsCombos } from "Requests/Combos";

import { validateObjectType } from "Utils/Objects";

import { CombosRequest, Combos, Error, CombosProps } from "./interfaces";
import { getParamsFilter } from "./utils";

export const useProductCombos = ({
  excludeCombo,
  includeCombo,
  searchBudget,
  enabled = true,
}: CombosProps): CombosRequest => {
  let combosToShow = getParamsFilter(includeCombo, excludeCombo);
  if (searchBudget) {
    combosToShow += "&search_budget=true";
  }

  const { data, error, isError, isLoading } = useQuery<Combos, Error>(
    ["combos", includeCombo, excludeCombo],
    () => getProductsCombos(combosToShow),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled,
    }
  );

  return {
    combos: validateObjectType(data) ? data : undefined,
    loadingCombos: isLoading,
    combosError: isError ? error.message : null,
  };
};
