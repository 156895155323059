import React, { ChangeEvent } from "react";

import { Formik, Form } from "formik";

import { SUBCATEGORIES } from "Constants/Categories";

import { hasPathInArray } from "Utils/Arrays";

import { CheckboxKeys, ProductsFormProps } from "./interface";
import { schema } from "./schema";
import { AnamneseForm } from "./SectionsForm/Anamnese";
import { CategoryForm } from "./SectionsForm/Category";
import { CompositionForm } from "./SectionsForm/Composition";
import { DescriptionForm } from "./SectionsForm/Description";
import { Featured } from "./SectionsForm/Featured";
import { IdentificationForm } from "./SectionsForm/Identification";
import { PrepareForm } from "./SectionsForm/Prepare";
import { RestrictionsForm } from "./SectionsForm/Restriction";
import { StatusForm } from "./SectionsForm/Status";
import { Container } from "./style";

export const ProductForm = ({
  handleSubmit,
  initialValues,
}: ProductsFormProps) => {
  const { category_id } = initialValues;
  const categoryId = String(category_id.id);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, setFieldValue, touched, setTouched, setFieldTouched }) => {
          const setSelectField = (e: ChangeEvent<HTMLInputElement>) => {
            const { name } = e.target;
            const value = Number(e.target.value);

            setFieldValue(name, value);
            setFieldTouched(name, true, false);
          };

          const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            const { name } = e.target;

            if (value === 4) setFieldValue("diet_avoid_preservatives_obs", "");
            if (value === 6) setFieldValue("diet_avoid_foods_obs", "");

            setTouched({ ...touched, [name]: true });
            const currentCheckboxValues = values[name as keyof CheckboxKeys];
            const currentValue = currentCheckboxValues?.filter(
              (checkValue) => checkValue !== value
            );
            const setField = [...new Set(currentCheckboxValues), Number(value)];

            if (currentCheckboxValues?.includes(value)) {
              return setFieldValue(name, currentValue);
            }

            return setFieldValue(name, setField);
          };

          const handleCheckboxCollection = (
            e: ChangeEvent<HTMLInputElement>
          ) => {
            const value = Number(e.target.value);
            const { name } = e.target;

            const currentCheckboxValues = values.product_pre_collection?.some(
              (checkValue) => checkValue?.collection_id === value
            );

            if (currentCheckboxValues) {
              setFieldValue(name, 0);
            } else {
              setFieldValue(name, value);
            }
          };

          return (
            <Form className="form" id="form-submit">
              <div className="form-column-1">
                <IdentificationForm
                  setSelectField={setSelectField}
                  handleCheckbox={handleCheckbox}
                />

                {values?.subcategories?.includes(
                  Number(SUBCATEGORIES.vaccine_club)
                ) && <Featured />}

                {hasPathInArray([1, 2, 3, 4, 6, 8], categoryId) && (
                  <CompositionForm setSelectField={setSelectField} />
                )}

                <DescriptionForm />

                <RestrictionsForm
                  setSelectField={setSelectField}
                  handleCheckbox={handleCheckbox}
                />

                <PrepareForm
                  setSelectField={setSelectField}
                  handleCheckbox={handleCheckbox}
                  handleCheckboxCollection={handleCheckboxCollection}
                />
              </div>

              <div className="form-column-2">
                <StatusForm setSelectField={setSelectField} />

                <CategoryForm />

                <AnamneseForm />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
