import React from "react";
import { useSelector } from "react-redux";

import { List } from "Pages/PersonDuplicated/List";
import { Preview } from "Pages/PersonDuplicated/Preview";

import { Store } from "Reducers/interface";

export const PersonDuplicated = () => {
  const { PersonDuplicatedFlow } = useSelector((store: Store) => store);

  return <>{!PersonDuplicatedFlow.document ? <List /> : <Preview />}</>;
};
