import { BudgetItemProps } from "Pages/Budgets/ViewDetails/interface";

import { formatDateBR } from "Utils/Date";

import { AccordionCollapse } from "Components/AccordionCollapse";
import { budgetTypes } from "Components/ViewBudgetDetails/budgetTypes";
import {
  BoxInfo,
  GridAccordionContent,
  TextContent,
  Title,
} from "Components/ViewBudgetDetails/style";

export const BudgetDetail = ({ data }: { data: BudgetItemProps }) => {
  const {
    id,
    channel,
    coupon_code,
    status,
    motion_integrated_at,
    budget_type,
    is_covenant,
    covenant_data,
  } = data?.info ?? {};

  const serviceType = budgetTypes.find((type) =>
    type.categories?.includes(budget_type)
  );

  return (
    <AccordionCollapse title="Dados do Orçamento" cssClass="lg-12">
      <GridAccordionContent>
        <BoxInfo>
          <Title>Id do Orçamento</Title>
          <TextContent>{id}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Origem</Title>
          <TextContent>{channel?.name ?? "Não informado"} </TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Tipo</Title>
          <TextContent>{serviceType?.type}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Integrado</Title>
          <TextContent>{motion_integrated_at ? "Sim" : "Não"}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Data do orçamento</Title>
          <TextContent>{formatDateBR(data.info.created_at)}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Status</Title>
          <TextContent>{status?.status}</TextContent>
        </BoxInfo>
        {is_covenant ? (
          <BoxInfo display="flex" direction="column" gap="0">
            <Title>Convênio</Title>
            <TextContent>
              {covenant_data?.covenant_name ?? "Não informado"}{" "}
            </TextContent>
          </BoxInfo>
        ) : (
          <BoxInfo display="flex" direction="column" gap="0">
            <Title>Cupom</Title>
            <TextContent>{coupon_code ?? "Não informado"} </TextContent>
          </BoxInfo>
        )}

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Data da integração</Title>
          <TextContent>
            {motion_integrated_at
              ? formatDateBR(motion_integrated_at)
              : "Não informado"}
          </TextContent>
        </BoxInfo>
      </GridAccordionContent>
    </AccordionCollapse>
  );
};
