import React from 'react'

export default function CardExtractAccount({amount, type, text, secondText, isPurple, setModalVisible, setOrderData, item, firstDate }) {
    const handleClick = () => {
        setModalVisible(true)
        setOrderData(item)
    }

    return (
        <div className={`container-card-labi-credits-extract ${type}`}>
            <div className="flex-card-extract">
                <p className="card-money-value">{amount}</p>
                <div>
                    <p>{text}</p>
                </div>
                <p className="card-p-formatter">{firstDate}</p>
                <p className={`card-p-formatter ${isPurple ? 'font-display' : ""} ${secondText ? 'color': ""}`} onClick={item && handleClick}>{secondText}</p>
            </div>
        </div>
    )
}
