import styled from "styled-components";

export const Container = styled.tr`
  td {
    font-size: 16px;
    line-height: 20px;
  }
  .price {
    font-weight: 700;
  }
  .rounded {
    border-radius: 30px;
    padding: 9px 24px;
  }
`;
