import React from "react";

import { hasPathInArray, validateArrayType } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";
import { Container } from "./styles";

export const ProductComposition = ({ product }: ProductProps) => {
  const {
    sample,
    application,
    rule,
    methodology,
    kits,
    category,
    dose_amount,
    dose_interval,
  } = product ?? {};

  return (
    <AccordionCollapse title="Composição" cssClass="composition">
      <Container>
        <div>
          {hasPathInArray([1, 2, 3], category?.id) && (
            <div className="composition-column-1">
              <span role="heading" className="heading" aria-level={5}>
                Tipo de amostra
              </span>
              <S.RowContent role="row" className="row-content">
                {sample?.sample || "Não informado"}
              </S.RowContent>
            </div>
          )}

          {hasPathInArray([8, 4], category?.id) && (
            <div className="composition-column-1">
              <span role="heading" className="heading" aria-level={5}>
                Aplicação
              </span>
              <S.RowContent role="row" className="row-content">
                {application?.application || "Não informado"}
              </S.RowContent>
            </div>
          )}
        </div>

        <div>
          {hasPathInArray([4], category?.id) && (
            <div className="composition-column-2 align-center">
              <span role="heading" className="heading" aria-level={5}>
                Vírus atenuado
              </span>
              <S.RowContent role="row" className="row-content">
                {rule?.attenuated_virus ? "Sim" : "Não"}
              </S.RowContent>
            </div>
          )}

          {hasPathInArray([1, 2, 3], category?.id) && (
            <div className="composition-column-1 align-center">
              <span role="heading" className="heading" aria-level={5}>
                Metodologia de análise
              </span>
              <S.RowContent role="row" className="row-content">
                {methodology?.methodology || "Não informado"}
              </S.RowContent>
            </div>
          )}
        </div>

        {hasPathInArray([4], category?.id) && (
          <div className="flex-dose">
            <div className="composition-column-2 align-center w-50">
              <span role="heading" className="heading" aria-level={5}>
                Quantidade de doses
              </span>
              <S.RowContent role="row" className="row-content">
                {dose_amount || "Não informado"}
              </S.RowContent>
            </div>

            <div className="composition-column-2 align-center w-50">
              <span role="heading" className="heading" aria-level={5}>
                Intervalo entre doses
              </span>
              <S.RowContent role="row" className="row-content">
                {dose_interval || "Não informado"}
              </S.RowContent>
            </div>
          </div>
        )}

        {hasPathInArray([2, 4, 3], category?.id) && validateArrayType(kits) && (
          <div>
            <div className="composition-column-2">
              <span role="heading" className="heading" aria-level={5}>
                Pacotes
              </span>
              {kits?.map((kit) => (
                <S.RowContent role="row" className="row-content" key={kit?.id}>
                  {kit.product}
                </S.RowContent>
              ))}
            </div>
          </div>
        )}
      </Container>
    </AccordionCollapse>
  );
};
