import styled from "styled-components";

export const ButtonDelete = styled.div`
  display: flex;
  align-items: center;

  &.margin {
      img {
        margin-top: 12px;
      }
    }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.33s ease-in-out;

    img {
      height: 30px;
    }

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const ContainerInfos = styled.div`
  padding: 27px 0;
  border-bottom: 1px solid #c3c3c380;
  position: relative;
  :first-child {
    padding: 0 0 27px 0;
  }
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  border: none;
  left: 97%;
`;

export const AddButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0;
`;

export const ContainerFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-top: 26px;
  padding: 15px 19px;

  img {
    max-width: 109px;
    max-height: 96px;
  }

  &.addAnexo {
    margin-right: 0 !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 168px;
    font-size: 13px;
    line-height: 21px;
    padding: 5px;
  }
`;

export const ImgCardCovenant = styled.img`
  width: 25px;
`;

export const LabelDocuments = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 16px;
`;
