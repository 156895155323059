import Modal from "react-modal";

import styled, { css } from "styled-components";

interface ModalProps {
  maxWidth: string;
  maxHeight: string;
  notRadius?: boolean;
}

export const ModalContainer = styled(Modal).attrs((props: ModalProps) => ({
  maxWidth: props.maxWidth ? `${props.maxWidth}px` : "unset",
  maxHeight: props.maxHeight ? `${props.maxHeight}px` : "unset",
  borderRadius: props.notRadius ? `0` : "24px",
}))<ModalProps>`
  min-width: initial;
  padding: 0px !important;
  width: 100%;

  ${(props) =>
    css`
      max-width: ${props.maxWidth};
    `};

  ${(props) =>
    css`
      max-height: ${props.maxHeight};
    `};

  .separator {
    width: 100%;
    height: 1px;
    border: 1px solid rgba(196, 196, 196, 0.3);
    box-sizing: border-box;
  }

  .content {
    max-height: inherit;
    padding: 35px 40px;
    box-sizing: border-box;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d5c8df;
      border-radius: 32px;
      border: 1px solid transparent;
      transition: all 300ms ease-in-out;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #d5c8df;
      }
    }
  }
`;
