import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Container = styled(Box)`
  &.units-content {
    background: #f1f1f1;
    padding-right: 20px;
    padding-left: 20px;

    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0;

    .heading-title {
      color: var(--color-purple);
      font-size: 24px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
    }
  }
`;
