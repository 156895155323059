import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { UseParamsProps } from "Pages/Orders/Token/interface";

import { getBudgets } from "Requests/Budget";

import { removeFalsyValuesFromFilters } from "Utils/RequestFilters";
import { createToast } from "Utils/toastFunc";

import { Filters } from "./interface";

export const useGetBudgetsPerson = () => {
  const params = useParams<UseParamsProps>();

  const [filters, setFilters] = useState<Filters>({
    page_size: 20,
    page: 1,
    person_id: params?.id,
  } as Filters);

  const query = useQuery(
    ["budgets", filters],
    () =>
      getBudgets({
        search: { ...removeFalsyValuesFromFilters(filters) },
        type: "",
      }),
    {
      refetchOnWindowFocus: false,
      onError: (errorRes: string) => createToast("error", errorRes),
    }
  );

  const setBudgetsFilters = (searchFilter: Filters) => {
    const filtersParams = {
      ...filters,
      ...searchFilter,
      page_size: 20,
      page: searchFilter.page ?? 1,
    };
    setFilters(filtersParams);
  };

  return {
    ...query,
    setBudgetsFilters,
    pagination: query.data?.pagination,
  };
};
