import styled from "styled-components";


export const CalendarContainer = styled.div`


   @media screen and (max-width: 1920px) {
    max-width: 1050px;
  }

  @media screen and (max-width: 1400px) {
    max-width: 1050px;
  }

  @media screen and (min-width: 2400px) {
    max-width: 100%;
  }

`