import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from "react-redux";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import validatePermissions from "Utils/validatePermissions";

import Checkbox from "Components/Form/Checkbox";
import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import PanelContent from "Components/Panels/PanelContent";
import Breadcrumb from "Components/Shared/Breadcrumb";





import {
  createAlert,
  getAllUnits,
  getAlerts,
  getAlert,
  deleteAlert,
  editAlert,
} from "../../../Requests/Units";
import { Loading } from "../../../Utils/Loading";
import { createToast } from "../../../Utils/toastFunc";
import { TextEllipsis } from "../../../Utils/Uploads";



import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function ListAlerts() {
  const [isPanelActive, setIsPanelActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [all_units, setAllUnits] = React.useState(true);
  const [warning, setWarning] = React.useState("");
  const [errorWarning, setErrorWarning] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [UnitsSelect, setUnitsSelect] = React.useState([]);

  const [listUnits, setListUnits] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [idCurrent, setIdCurrent] = React.useState(0);

  const [editor, setEditor] = React.useState(EditorState.createEmpty());
  async function fetchUnits() {
    try {
      setLoading(true);

      const { data } = await getAllUnits();
      const newArray = data.map((item) => ({ id: item.id, option: item.name }));
      setOptions(newArray);

      setLoading(false);
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  async function fetchAlerts() {
    try {
      setLoading(true);
      const { data } = await getAlerts();
      setListUnits(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  async function handleEdit(id) {
    try {
      resetEditMode();
      setLoading(true);

      setIsPanelActive(true);
      setEditMode(true);
      setIdCurrent(id);

      const { data } = await getAlert(id);
      const BlockDescription = htmlToDraft(data.description);
      const draftDescription = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          BlockDescription.contentBlocks,
          BlockDescription.entityMap
        )
      );
      setEditor(draftDescription);

      setWarning(data.warning);
      setAllUnits(data.all_units);

      if (data.units.length > 0) {
        const newArray = data.units.map((item) => ({
          id: item.id,
          option: item.name,
        }));
        setUnitsSelect(newArray);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  function verifyForm() {
    let valid = true;
    if (warning === "") {
      createToast("error", "Um ou mais campos não foram preenchidos");
      setErrorWarning("Campo obrigatório");
      valid = false;
    }
    if (!all_units && UnitsSelect.length <= 0) {
      createToast("error", "Selecione pelo menos uma unidade");
      valid = false;
    }
    return valid;
  }

  function resetEditMode(type = "create") {
    setIsPanelActive((prev) => !prev);
    setEditMode(false);
    setWarning("");
    type === "create" && setAllUnits(true);
    setUnitsSelect([]);
    setEditor(EditorState.createEmpty());
    setIdCurrent(0);
  }

  async function handleDelete(id) {
    try {
      setLoading(true);
      const res = await deleteAlert(id);
      if (res.status === 200) {
        createToast("success", "Alerta excluido com sucesso!");
        await fetchAlerts();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  async function handleSubmit() {
    try {
      if (!verifyForm()) return;

      const unit_ids = UnitsSelect.map((item) => item.id);

      setLoading(true);
      const description = draftToHtml(convertToRaw(editor.getCurrentContent()));
      const res = await createAlert({
        warning,
        description,
        unit_ids,
        all_units,
      });
      setLoading(false);

      if (res.status === 200) {
        createToast("success", "Alerta cadastrado com sucesso!");
        setIsPanelActive(false);
        await fetchAlerts();
      }
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  async function handleSubmitEdit() {
    try {
      if (!verifyForm()) return;
      const description = draftToHtml(convertToRaw(editor.getCurrentContent()));

      const unit_ids = UnitsSelect.map((item) => item.id);

      setLoading(true);
      const res = await editAlert(idCurrent, {
        description,
        warning,
        unit_ids,
        all_units,
      });
      setLoading(false);

      if (res.status === 200) {
        createToast("success", "Alerta cadastrado com sucesso!");
        setIsPanelActive(false);
        await fetchAlerts();
      }
    } catch (error) {
      console.log(error);
      createToast("error", "Um erro inesperado ocorreu!");
    }
  }

  React.useEffect(() => {
    fetchUnits();
    fetchAlerts();
  }, []);

  // se todas as unidades forem selecionadas limpa as que já estavam selecionadas
  React.useEffect(() => {
    all_units && setUnitsSelect([]);
  }, [all_units]);

  const user_permissions = useSelector((state) => state.User.permissions);
  const handleShowPanel = () => resetEditMode("create");
  const title = isPanelActive
    ? editMode
      ? "Editar alerta"
      : "Cadastrar alerta"
    : "Todos os Alertas";

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title={title}>
              <Breadcrumb
                list={[
                  {
                    path: isPanelActive ? "/unidades" : "/dashboard",
                    label: isPanelActive ? "unidades" : "Dashboard",
                    current: false,
                  },
                  {
                    path: isPanelActive ? "/alertas" : "/unidades",
                    label: isPanelActive ? "Todos Alertas" : "unidades",
                    current: false,
                  },
                  {
                    path: "#",
                    label: isPanelActive
                      ? editMode
                        ? "Editar alerta"
                        : "Criar alerta"
                      : "Todos alertas",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            <div className="actions-holder">
              {validatePermissions("Create Units", user_permissions) && (
                <>
                  <button
                    className={
                      isPanelActive
                        ? "link link-danger"
                        : "button _white _action"
                    }
                    onClick={handleShowPanel}
                  >
                    {isPanelActive ? "Voltar" : "Adicionar Alerta"}
                  </button>
                  {isPanelActive && (
                    <button
                      className="button _action"
                      onClick={editMode ? handleSubmitEdit : handleSubmit}
                    >
                      {editMode ? "Editar" : "Criar"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {isPanelActive && (
          <div className="page-content">
            <PanelContent title="Cadastrar novo Alerta" loading={loading}>
              <div className="row">
                <div className="md-4 lg-6">
                  <SelectSearch
                    label="Seleciona as Unidades"
                    id="options_ids"
                    type="text"
                    value={UnitsSelect}
                    isMulti
                    options={options}
                    loading={options?.length === 0}
                    readOnly={options?.length === 0 || all_units}
                    valueKey="option"
                    labelKey="option"
                    placeholder={
                      all_units
                        ? "Todas unidades selecionadas"
                        : "Selecione as Unidades"
                    }
                    onChange={(value) => setUnitsSelect(value)}
                  />
                </div>
                <div className="md-4 lg-6">
                  <Input
                    label="Nome do Alerta"
                    value={warning}
                    error={errorWarning}
                    id="warning"
                    onChange={({ target }) => {
                      setErrorWarning("");
                      setWarning(target.value);
                    }}
                  />
                </div>
              </div>
              <Checkbox
                id="newborn"
                label=" "
                descriptionText="Todas Unidades?"
                value={all_units}
                onChange={() => setAllUnits((prev) => !prev)}
              />

              <div className="lg-12" style={{ marginBottom: "1rem" }}>
                <label className="text-gray text-semibold">
                  Mensagem de alerta
                </label>
                <Editor
                  editorState={editor}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(value) => setEditor(value)}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "link",
                      "embedded",
                      "emoji",
                      "image",
                      "remove",
                      "history",
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                />
              </div>
            </PanelContent>
          </div>
        )}

        {!isPanelActive && (
          <div className="page-content page-content-list">
            <table className="table-content small-separate details">
              <thead>
                <tr>
                  <th>Alertas</th>
                  <th>Unidades</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {listUnits.length > 0 && !loading ? (
                  listUnits.map((item) => (
                    <tr key={item.id}>
                      <td>{TextEllipsis(item.warning, 70)}</td>
                      <td>{item.all_units ? "Todas" : item.units.length}</td>
                      <td className="text-right table-action">
                        <button
                          type="button"
                          className="button _blue _action"
                          onClick={() => handleEdit(item.id)}
                        >
                          Editar
                        </button>
                        <button
                          type="button"
                          className="button _yellow _action"
                          onClick={() => handleDelete(item.id)}
                        >
                          <span className="details-text">Excluir</span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center text-purple">
                      {loading ? (
                        <Loading />
                      ) : (
                        "Oops! Parece que nada foi encontrado..."
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </PageWrapper>
  );
}
