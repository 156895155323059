import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { BudgetProps } from "Dtos/IBudget";

import { CATEGORIES } from "Constants/Categories";
import { STATUS } from "Constants/Combos";

import { AddOrUpdateProductToCart, RemoveProductToCart } from "Requests/Budget";
import { FormatterToUpdateBudget } from "Requests/Formatters/Budget";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { Product } from "Hooks/useProducts/interface";
import { useQueryParams } from "Hooks/useQueryParams";

import { Store } from "Reducers/interface";

import { handleRequestErrors } from "Utils/Errors";
import { createToast } from "Utils/toastFunc";

import { OptionsPartner } from "../CartSearch/interface";

export const useCartAmount = () => {
  const [modal, setModal] = useState({
    unavailable: false,
    partnerSelect: false,
  });
  const [partnersOptions, setPartnersOptions] = useState<OptionsPartner[]>([]);
  const [productSelected, setProductSelected] = useState<Product | null>(null);

  const { person_type } = useParams<{ person_type: string }>();

  const queryString = useQueryParams();
  const budget_type = queryString.get("btype");

  const {
    budget,
    loadingBudget,
    selectedCovenant,
    zipCode,
    partnerSelected,
    setBudgetInfo,
    documents,
    toggleLoadingCartState,
    setModalMedicalAttach,
  } = useBudgetContext();

  const history = useHistory();

  const { pacient } = useSelector((state: Store) => state.service_form);
  const patientSelected = pacient;

  const closeModal = (modalClose: "unavailable" | "partnerSelect") => {
    setModal((prev) => ({ ...prev, [modalClose]: false }));
  };

  const getRegionId = (budgetCart: BudgetProps | null, product: Product) => {
    let regionId = null;

    const isImageCategory = product.category.id === CATEGORIES.image;

    if (isImageCategory) {
      if (!budgetCart) {
        regionId = product?.partners.find(
          (item) => Number(item.list.id) === Number(partnerSelected)
        );
      }

      if (budgetCart) {
        regionId = product?.partners?.find(
          (item) => Number(item?.list?.id) === Number(budgetCart?.partner_id)
        );
      }

      if (!regionId) {
        throw new Error(
          "Este produto não é atendido pelo parceiro selecionado!"
        );
      }
    }

    return regionId;
  };

  const formatPayloadBudgetCreation = (product: Product) => {
    const type = product?.category?.id;

    const regionId = getRegionId(budget, product);

    const objBudget = {
      budget_type: type,
      budget: budget ?? null,
      patient: {
        id: patientSelected.id,
        ...(patientSelected.emails && {
          emailId: patientSelected.emails[0] ? patientSelected.emails[0].id : 0,
          phoneId: patientSelected.phones[0] ? patientSelected.phones[0].id : 0,
        }),
      },
      ...(zipCode && { zip_code: zipCode }),
      ...(selectedCovenant && { credential_id: selectedCovenant.id }),
      person_type,
      product: {
        id: product.id,
        amount: 1,
        partner_id: partnerSelected ?? regionId?.list.id,
      },
    };

    return FormatterToUpdateBudget(objBudget);
  };

  const handlePartnerProduct = async (product: Product) => {
    const partnersList: OptionsPartner[] = [];

    const defaultPartner = {
      id: 0,
      partner_regions_id: 0,
      name: "",
    };

    product?.partners?.forEach((item) => {
      partnersList.push({
        id: item.list.id,
        partner_regions_id: item.list.partner.id,
        name: item.list.partner.partner,
      });
    });

    setPartnersOptions([defaultPartner, ...partnersList]);

    setModal((prev) => ({ ...prev, partnerSelect: true }));

    setProductSelected(product);
  };

  const handleAddProduct = async (product: Product) => {
    try {
      toggleLoadingCartState(true);
      if (product.status.id === STATUS.unavaliable) {
        setModal((prev) => ({ ...prev, unavailable: true }));
        return;
      }

      if (
        !budget_type &&
        budget_type !== "private" &&
        budget_type !== "covenant"
      ) {
        throw new Error(
          "Este tipo de orçamento não está disponível no momento. Só é possível criar orçamento particular ou por convênio médico!"
        );
      }

      const cart = budget?.cart;
      const type = product?.category?.id;

      if (!cart?.length && type === CATEGORIES.image && !partnerSelected) {
        handlePartnerProduct(product);

        return;
      }

      const postData = formatPayloadBudgetCreation(product);

      const responseAddCart: any = await AddOrUpdateProductToCart({
        budget_info: postData,
        budget_type,
      });

      if (responseAddCart?.info) {
        setBudgetInfo({ ...responseAddCart.info });
      }

      if (!budget) {
        const budgetId = responseAddCart.info.id;
        history.push(
          `/ficha-de-atendimento/${person_type}/selecionar-produtos/${budgetId}?btype=${budget_type}`
        );
      }
      setModal((prev) => ({ ...prev, partnerSelect: false }));

      createToast("success", "Produto adicionado com sucesso!");

      if (!cart?.length && type === 7 && !documents?.length) {
        setModalMedicalAttach(true);
      }
    } catch (errors) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível adicionar este item no orçamento.  ",
      });
    } finally {
      toggleLoadingCartState(false);
    }
  };

  const handleRemoveToCart = async (product: Product) => {
    try {
      if (
        !budget_type &&
        budget_type !== "private" &&
        budget_type !== "covenant"
      ) {
        throw new Error(
          "Não foi possível excluir este produto, pois o tipo de orçamento está diferente do realizado no momento."
        );
      }

      const type = product?.category?.id;

      const regionId = getRegionId(budget, product);

      const objBudget = {
        budget_type: type,
        patient: {
          id: patientSelected.id,
          ...(patientSelected.emails && {
            emailId: patientSelected.emails[0]
              ? patientSelected.emails[0].id
              : 0,
            phoneId: patientSelected.phones[0]
              ? patientSelected.phones[0].id
              : 0,
          }),
        },
        ...(selectedCovenant && { credential_id: selectedCovenant.id }),
        budget,
        product: {
          id: product.id,
          amount: 0,
        },
        person_type,
        regionId: regionId ?? null,
      };
      const postData = FormatterToUpdateBudget(objBudget);

      const responseRemoveCart: any = await RemoveProductToCart({
        productExclude: postData,
        budget_type,
      });

      if (responseRemoveCart?.success && responseRemoveCart?.info) {
        setBudgetInfo(responseRemoveCart.info);

        createToast("success", "Produto removido com sucesso!");
      }
    } catch (errors) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível remover este item do orçamento.  ",
      });
    }
  };

  return {
    handleRemoveToCart,
    handleAddProduct,
    closeModal,
    loadingBudget,
    budget,
    documents,
    modal,
    partnersOptions,
    productSelected,
  };
};
