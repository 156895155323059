import { useState, useRef, useCallback, useEffect } from "react";
// @ts-ignore
import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm';
import moment from "moment";
// @ts-ignore
import { AppointmentScheduleType, AppointmentType, CalendarHourType } from "../SlideDates/types";
import { StyledNextArrow, StyledPreviousArrow } from "../SlideDates/styles";

interface RescheduleSliderHourSliderHourProps {
  handleSelect: (appointment: AppointmentScheduleType) => void;
  appointment: AppointmentScheduleType;
  loadingHour: boolean;
  hours: CalendarHourType;
  type: AppointmentType;
  query?: string;
}


export const RescheduleSliderHour = ({
  handleSelect,
  appointment,
  loadingHour,
  hours,
  type,
  query
}: RescheduleSliderHourSliderHourProps) => {
  const [activeSlider, setActiveSlider] = useState(0);
  const glideRef = useRef<Glide | null>(null);
  const glideId = `${type}-slider`;

  const sliderGoTo = useCallback(
    (direction: string) => {
      if (glideRef.current) {
        glideRef.current.go(direction);
        setActiveSlider(glideRef.current.index);
      }
    },
    []
  );

  const addingHoursInTime = useCallback((time: string, duration: number | string | null) => {
    const addMinutes = typeof duration === 'number' && duration > 1 ? duration : 60;
    const horarioMoment = moment(time, "HH:mm");
    const horarioFinal = horarioMoment.add(addMinutes, "minutes");
    return horarioFinal.format("HH:mm");
  }, []);

  
  useEffect(() => {
    const elementExists = document.querySelector(`#${glideId}`);
    if (elementExists) {
      const glideInstance = new Glide(`#${glideId}`, {
        type: "slider",
        rewind: false,
        bound: true,
        startAt: 0,
        perView: 1,
      });

      glideInstance.mount({ Controls });
      glideInstance.on("move.after", () => {
        setActiveSlider(glideInstance.index);
      });

      glideRef.current = glideInstance;

      return () => {
        glideInstance.destroy();
      };
    }
  }, [glideId]);

  return (
    <section>
      <div className="container" style={{ paddingRight: 0}}>
        {/* <div className="row"> */}
          <div
            id={glideId}
            className={`glide slider-dates-sm ${loadingHour ? "-hidden" : ""}`}
          >
            {hours && (
              <div className="glide__arrows">
                <button
                  style={{ opacity: activeSlider === 0 ? "0.25" : "1" }}
                  onClick={() => sliderGoTo("<")}
                  className="glide__arrow glide__arrow--prev"
                >
                   <StyledPreviousArrow />
                </button>
              </div>
            )}

            <div data-glide-el="track" className="glide__track">
              <ul className="glide__slides">
                {hours.length > 0 ? hours.map((eachArray, index) => (
                  <div key={index} className="each-slide">
                    {eachArray.map((data: any, dataIndex) => (
                      <div
                        key={`${data.hour_query}-${dataIndex}`}
                        onClick={() =>
                          handleSelect({
                            query_date: data.query_date,
                            weekday: data.weekday,
                            appointment_id: data.appointmentID || "",
                            calendar_id: data.calendarID || "",
                            query_hour: data.hour_query || "",
                            date: data.date || "",
                            type: appointment.type,
                            hour: data.hour,
                          })
                        }
                        className={`card card-dates-sm ${appointment.query_hour === data.hour_query ? "_selected" : ""}`}
                      >
                        <div className="date">
                          {data[type]}
                          <br />ás  {" "}
                          {addingHoursInTime(
                            data.hour,
                            data?.duration
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )) : <></>}
              </ul>
            </div>
            {hours && (
              <div className="glide__arrows _right">
                <button
                  style={{ opacity: activeSlider + 1 === hours.length ? "0.25" : "1" }}
                  onClick={() => sliderGoTo(">")}
                  className="glide__arrow glide__arrow--next"
                >
                  <StyledNextArrow />
                </button>
              </div>
            )}
          </div>
          <div className="bullets slider-hour-home-collect">
            <div className="glide__bullets calendar-slider" style={{ marginLeft: '1em'}}>
              {hours?.map((_, index) => (
                <button
                  key={index}
                  className={`glide__bullet ${activeSlider === index ? "glide__bullet--active" : ""}`}
                  onClick={() => sliderGoTo(`=${index}`)}
                />
              ))}
            </div>
          </div>
        {/* </div> */}
      </div>
    </section>
  );
};
