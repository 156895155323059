import API from "Services/Api";

export const getOptionsFiltersRnds = async () => {
  try {
    const response = await API.get("/reports/rnds/data/combolist");

    const data = {};

    for (const item in response.data) {
      data[item] = response.data[item].map((i) => ({ id: i, name: i }));
    }

    return data;
  } catch (error) {
    console.log(error);

    throw "";
  }
};

export const getRndsList = async (params, page = 1, per_page = 10) => {
  try {
    const response = await API.get(
      `/reports/rnds/data/list?page=${page}&pagination=${per_page}`,
      { params }
    );

    const _data = {
      data: response.data.data,
      pagination: {
        current_page: response.data.current_page,
        per_page: response.data.per_page,
        total_pages: response.data.last_page,
      },
    };

    return _data;
  } catch (error) {
    console.log(error);

    throw "";
  }
};

export const sendRnds = async (data) => {
  try {
    const response = await API.post("/reports/rnds/data/send", data);

    return response.data;
  } catch (error) {
    console.log(error);

    throw "";
  }
};
