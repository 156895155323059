import React from "react";
import Modal from "react-modal";

import SelectTypeModal from "../SelectTypeModal";
import ModalCancel from "./ModalCancel";
import ModalChange from "./ModalChange";
import ModalDetails from "./ModalDetails";
import ModalEdit from "./ModalEdit";



export function ModalDetailsAppointments({
  modalVisible,
  modalDismiss,
  data
}) {
  const [modalOpen, setModalOpen] = React.useState('details');
  const [date, setDate] = React.useState("");

  const [checkShow, setCheckShow] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const [objectData, setObjectData] = React.useState({});

  React.useEffect(() => {
    setModalOpen('details')
  }, [modalVisible]);
 
  React.useEffect(() => {
    setObjectData(data)
  }, [data])

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-schedule"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="modal-details-schedule">
        {modalOpen === 'details' && (
          <ModalDetails readOnly historyPacient data={data} />
        )}

        {modalOpen === 'edit' && (
          <ModalEdit readOnly={false} historyPacient={false} data={data} setObjectData={setObjectData} objectData={objectData}/>
        )}

        {modalOpen === 'change' && (
          <ModalChange data={data} date={date} setDate={setDate} />
        )}

        {modalOpen === 'cancel' && (
          <ModalCancel 
            setSendEmail={setSendEmail} 
            setCheckShow={setCheckShow} 
            checkShow={checkShow} 
            sendEmail={sendEmail} 
            data={data}
            notes={notes}
            setNotes={setNotes}
          />
        )}


        <SelectTypeModal 
          data={data} 
          hour={date} 
          modalDismiss={modalDismiss} 
          modalOpen={modalOpen} 
          setModalOpen={setModalOpen} 
          checkShow={checkShow}
          sendEmail={sendEmail}
          notes={notes}
          setNotes={setNotes}
          objectEdit={objectData}
        />
      </div>
    </Modal>
  );
}


