export const EmittingOrgan = [
	{
		id: "SSP",
		name: "SSP"
	},
	{
		id: "Organismos Militares",
		name: "Organismos Militares"
	},
	{
		id: "Comandoda Aeronáutica (Ex Ministério da Aeronáutica)",
		name: "Comandoda Aeronáutica (Ex Ministério da Aeronáutica)"
	},
	{
		id: "Comando do Exército (Ex Ministério do Exército)",
		name: "Comando do Exército (Ex Ministério do Exército)"
	},
	{
		id: "Comando da Marinha (Ex Ministério da Marinha)",
		name: "Comando da Marinha(Ex Ministério da Marinha)"
	},
	{
		id: "Polícia Federal",
		name: "Polícia Federal"
	},
	{
		id: "Carteira de Identidade Classista",
		name: "Carteira de Identidade Classista"
	},
	{
		id: "Conselho Regional de Administração",
		name: "Conselho Regional de Administração"
	},
	{
		id: "Conselho Regional de Assist. Social",
		name: "Conselho Regional de Assist. Social"
	},
	{
		id: "Conselho Regional de Biblioteconomia",
		name: "Conselho Regional de Biblioteconomia"
	},
	{
		id: "Conselho Regional de Contabilidade",
		name: "Conselho Regional de Contabilidade"
	},
	{
		id: "Conselho Regional de Corretores Imóveis",
		name: "Conselho Regional de Corretores Imóveis"
	},
	{
		id: "Conselho Regional de Enfermagem",
		name: "Conselho Regional de Enfermagem"
	},
	{
		id: "Conselho Regional de Engenharia , Arquitetura e Agronomia",
		name: "Conselho Regional de Engenharia , Arquitetura e Agronomia"
	},
	{
		id: "Conselho Regional de Estatística",
		name: "Conselho Regional de Estatística"
	},
	{
		id: "Conselho Regional de Farmácia",
		name: "Conselho Regional de Farmácia"
	},
	{
		id: "Conselho Regional de Fisioterapia e Terapia Ocupacional",
		name: "Conselho Regional de Fisioterapia e Terapia Ocupacional"
	},
	{
		id: "Conselho Regional de Medicina",
		name: "Conselho Regional de Medicina"
	},
	{
		id: "Conselho Regional de Medicina Veterinária",
		name: "Conselho Regional de Medicina Veterinária"
	},
	{
		id: "Ordem dos Músicos do Brasil",
		name: "Ordem dos Músicos do Brasil"
	},
	{
		id: "Conselho Regional de Nutrição",
		name: "Conselho Regional de Nutrição"
	},
	{
		id: "Conselho Regional de Odontologia",
		name: "Conselho Regional de Odontologia"
	},
	{
		id: "Conselho Regional de Profissionais de Relações Públicas",
		name: "Conselho Regional de Profissionais de Relações Públicas"
	},
	{
		id: "Conselho Regional de Psicologia",
		name: "Conselho Regional de Psicologia"
	},
	{
		id: "Conselho Regional de Química",
		name: "Conselho Regional de Química"
	},
	{
		id: "Conselho Regional de Representantes Comerciais",
		name: "Conselho Regional de Representantes Comerciais"
	},
	{
		id: "Ordem dos Advogados do Brasil",
		name: "Ordem dos Advogados do Brasil"
	},
	{
		id: "Outros Emissores",
		name: "Outros Emissores"
	}
];

export const Uf = [
	{
		id: "SP",
		name: "SP"
	},
	{
		id: "AC",
		name: "AC"
	},
	{
		id: "AL",
		name: "AL"
	},
	{
		id: "AM",
		name: "AM"
	},
	{
		id: "AP",
		name: "AP"
	},
	{
		id: "BA",
		name: "BA"
	},
	{
		id: "CE",
		name: "CE"
	},
	{
		id: "DF",
		name: "DF"
	},
	{
		id: "ES",
		name: "ES"
	},
	{
		id: "GO",
		name: "GO"
	},
	{
		id: "MA",
		name: "MA"
	},
	{
		id: "MG",
		name: "MG"
	},
	{
		id: "MS",
		name: "MS"
	},
	{
		id: "MT",
		name: "MT"
	},
	{
		id: "PA",
		name: "PA"
	},
	{
		id: "PB",
		name: "PB"
	},
	{
		id: "PE",
		name: "PE"
	},
	{
		id: "PI",
		name: "PI"
	},
	{
		id: "PR",
		name: "PR"
	},
	{
		id: "RJ",
		name: "RJ"
	},
	{
		id: "RN",
		name: "RN"
	},
	{
		id: "RO",
		name: "RO"
	},
	{
		id: "RR",
		name: "RR"
	},
	{
		id: "RS",
		name: "RS"
	},
	{
		id: "SC",
		name: "SC"
	},
	{
		id: "SE",
		name: "SE"
	},
	{
		id: "TO",
		name: "TO"
	}
];

export const Initial_Combos_Types = {
	genders: {
		data: [
			{
				id: 1,
				gender: "Masculino",
				sub_gender: "Masculino"
			},
			{
				id: 2,
				gender: "Masculino",
				sub_gender: "Masculino Trans"
			},
			{
				id: 3,
				gender: "Feminino",
				sub_gender: "Feminino"
			},
			{
				id: 4,
				gender: "Feminino",
				sub_gender: "Feminino Trans"
			},
			{
				id: 5,
				gender: "Indefinido",
				sub_gender: "Indefinido"
			}
		]
	},
	nationality: {
		data: [
			{
				id: 1,
				nationality: "Afeg„o"
			},
			{
				id: 2,
				nationality: "Albanesa"
			},
			{
				id: 3,
				nationality: "Alem„"
			},
			{
				id: 4,
				nationality: "Algeriana"
			},
			{
				id: 5,
				nationality: "Americana"
			},
			{
				id: 6,
				nationality: "Angolana"
			},
			{
				id: 7,
				nationality: "Antiguana"
			},
			{
				id: 8,
				nationality: "Ap·trida"
			},
			{
				id: 9,
				nationality: "Argelina"
			},
			{
				id: 10,
				nationality: "Argentina"
			},
			{
				id: 11,
				nationality: "ArmÍnia"
			},
			{
				id: 12,
				nationality: "Australiana"
			},
			{
				id: 13,
				nationality: "Austro-h˙ngara"
			},
			{
				id: 14,
				nationality: "AustrÌaca"
			},
			{
				id: 15,
				nationality: "AÁoriana"
			},
			{
				id: 16,
				nationality: "Bahamense"
			},
			{
				id: 17,
				nationality: "Barbadense"
			},
			{
				id: 18,
				nationality: "Bechuano"
			},
			{
				id: 19,
				nationality: "Belga"
			},
			{
				id: 20,
				nationality: "Belizenha"
			},
			{
				id: 21,
				nationality: "Bohemia"
			},
			{
				id: 22,
				nationality: "Boliviana"
			},
			{
				id: 23,
				nationality: "Bosnia"
			},
			{
				id: 24,
				nationality: "Brasileira"
			},
			{
				id: 25,
				nationality: "Brit‚nico"
			},
			{
				id: 26,
				nationality: "B˙lgara"
			},
			{
				id: 27,
				nationality: "Camaronense"
			},
			{
				id: 28,
				nationality: "Canadense"
			},
			{
				id: 29,
				nationality: "Chilena"
			},
			{
				id: 30,
				nationality: "Chinesa"
			},
			{
				id: 31,
				nationality: "CingalÍs"
			},
			{
				id: 32,
				nationality: "Colombiana"
			},
			{
				id: 33,
				nationality: "Comorense"
			},
			{
				id: 34,
				nationality: "Coreana"
			},
			{
				id: 35,
				nationality: "Costarriquenha"
			},
			{
				id: 36,
				nationality: "Croata"
			},
			{
				id: 37,
				nationality: "Cubana"
			},
			{
				id: 38,
				nationality: "Dinamarquesa"
			},
			{
				id: 39,
				nationality: "Dominicana"
			},
			{
				id: 40,
				nationality: "EgÌpcia"
			},
			{
				id: 41,
				nationality: "Equatoriana"
			},
			{
				id: 42,
				nationality: "Escandinava"
			},
			{
				id: 43,
				nationality: "Escocesa"
			},
			{
				id: 44,
				nationality: "Eslava"
			},
			{
				id: 45,
				nationality: "Eslovaca"
			},
			{
				id: 46,
				nationality: "Eslovena"
			},
			{
				id: 47,
				nationality: "Espanhola"
			},
			{
				id: 48,
				nationality: "Finlandesa"
			},
			{
				id: 49,
				nationality: "Francesa"
			},
			{
				id: 50,
				nationality: "Galega"
			},
			{
				id: 51,
				nationality: "GanÍs"
			},
			{
				id: 52,
				nationality: "Granadina"
			},
			{
				id: 53,
				nationality: "Grega"
			},
			{
				id: 54,
				nationality: "Guatemalteca"
			},
			{
				id: 55,
				nationality: "Guianense"
			},
			{
				id: 56,
				nationality: "GuianÍs"
			},
			{
				id: 57,
				nationality: "Haitiana"
			},
			{
				id: 58,
				nationality: "Holandesa"
			},
			{
				id: 59,
				nationality: "Hondurenha"
			},
			{
				id: 60,
				nationality: "H˙ngara"
			},
			{
				id: 61,
				nationality: "Iemenita"
			},
			{
				id: 62,
				nationality: "Indiana"
			},
			{
				id: 63,
				nationality: "IndonÈsio"
			},
			{
				id: 64,
				nationality: "Inglesa"
			},
			{
				id: 65,
				nationality: "Iraquiano"
			},
			{
				id: 66,
				nationality: "Irlandesa"
			},
			{
				id: 67,
				nationality: "Israelense"
			},
			{
				id: 68,
				nationality: "Italiana"
			},
			{
				id: 69,
				nationality: "Jamaicana"
			},
			{
				id: 70,
				nationality: "Japonesa"
			},
			{
				id: 71,
				nationality: "Libanesa"
			},
			{
				id: 72,
				nationality: "Libia"
			},
			{
				id: 73,
				nationality: "Lituana"
			},
			{
				id: 74,
				nationality: "Luxemburguesa"
			},
			{
				id: 75,
				nationality: "Malaio"
			},
			{
				id: 76,
				nationality: "Marfinense"
			},
			{
				id: 77,
				nationality: "Marroquina"
			},
			{
				id: 78,
				nationality: "Mexicana"
			},
			{
				id: 79,
				nationality: "Montenegrina"
			},
			{
				id: 80,
				nationality: "MoÁambicana"
			},
			{
				id: 81,
				nationality: "NeozelandÍs"
			},
			{
				id: 82,
				nationality: "NepalÍs"
			},
			{
				id: 83,
				nationality: "Nicaraguense"
			},
			{
				id: 84,
				nationality: "Nigeriano"
			},
			{
				id: 85,
				nationality: "Norte-Americana"
			},
			{
				id: 86,
				nationality: "Norueguesa"
			},
			{
				id: 87,
				nationality: "Omanense"
			},
			{
				id: 88,
				nationality: "Otomana"
			},
			{
				id: 89,
				nationality: "Outras"
			},
			{
				id: 90,
				nationality: "Palestina"
			},
			{
				id: 91,
				nationality: "Panamenha"
			},
			{
				id: 92,
				nationality: "PaquistanÍs"
			},
			{
				id: 93,
				nationality: "Paraguaia"
			},
			{
				id: 94,
				nationality: "Persa"
			},
			{
				id: 95,
				nationality: "Peruana"
			},
			{
				id: 96,
				nationality: "Polaca"
			},
			{
				id: 97,
				nationality: "Polonesa"
			},
			{
				id: 98,
				nationality: "Portorriquenha"
			},
			{
				id: 99,
				nationality: "Portuguesa"
			},
			{
				id: 100,
				nationality: "Prussiana"
			},
			{
				id: 101,
				nationality: "Prussiano"
			},
			{
				id: 102,
				nationality: "Qararense"
			},
			{
				id: 103,
				nationality: "Queniano"
			},
			{
				id: 104,
				nationality: "Romena"
			},
			{
				id: 105,
				nationality: "RuandÍs"
			},
			{
				id: 106,
				nationality: "Russa"
			},
			{
				id: 107,
				nationality: "Salvadorenha"
			},
			{
				id: 108,
				nationality: "Santa-lucense"
			},
			{
				id: 109,
				nationality: "Saudita"
			},
			{
				id: 110,
				nationality: "SaxÙnia"
			},
			{
				id: 111,
				nationality: "Somali"
			},
			{
				id: 112,
				nationality: "Sueca"
			},
			{
				id: 113,
				nationality: "SuiÁa"
			},
			{
				id: 114,
				nationality: "Sul-Africana"
			},
			{
				id: 115,
				nationality: "SurinamÍs"
			},
			{
				id: 116,
				nationality: "S„o-cristovense"
			},
			{
				id: 117,
				nationality: "S„o-vicentino"
			},
			{
				id: 118,
				nationality: "SÈrvia"
			},
			{
				id: 119,
				nationality: "SÌria"
			},
			{
				id: 120,
				nationality: "TailandÍs"
			},
			{
				id: 121,
				nationality: "Tchecoslovaca"
			},
			{
				id: 122,
				nationality: "Timorense"
			},
			{
				id: 123,
				nationality: "Tirol"
			},
			{
				id: 124,
				nationality: "Trindadense"
			},
			{
				id: 125,
				nationality: "Turca"
			},
			{
				id: 126,
				nationality: "Ucraniana"
			},
			{
				id: 127,
				nationality: "Ugandense"
			},
			{
				id: 128,
				nationality: "Uruguaia"
			},
			{
				id: 129,
				nationality: "Venezuelana"
			},
			{
				id: 130,
				nationality: "Vietinamita"
			},
			{
				id: 131,
				nationality: "Zimbabuense"
			},
			{
				id: 132,
				nationality: "¡rabe"
			}
		]
	},
	countrys: {
		data: [
			{
				id: 1,
				name: "AFEGANISTAO"
			},
			{
				id: 2,
				name: "AFRICA DO SUL"
			},
			{
				id: 3,
				name: "ALEMANHA"
			},
			{
				id: 4,
				name: "ANDORRA"
			},
			{
				id: 5,
				name: "ANGOLA"
			},
			{
				id: 6,
				name: "ANGUILLA"
			},
			{
				id: 7,
				name: "ANT. REP. IUGOSLAVA MACEDONIA"
			},
			{
				id: 8,
				name: "ANTARCTIDA"
			},
			{
				id: 9,
				name: "ANTIGUA E BARBUDA"
			},
			{
				id: 10,
				name: "ANTILHAS HOLANDESAS"
			},
			{
				id: 11,
				name: "APATRIDA"
			},
			{
				id: 12,
				name: "ARABIA SAUDITA"
			},
			{
				id: 13,
				name: "ARGELIA"
			},
			{
				id: 14,
				name: "ARGENTINA"
			},
			{
				id: 15,
				name: "ARUBA"
			},
			{
				id: 16,
				country_name: "AUSTRALIA"
			},
			{
				id: 17,
				country_name: "AUSTRIA"
			},
			{
				id: 18,
				country_name: "BANGLADESH"
			},
			{
				id: 19,
				country_name: "BARBADOS"
			},
			{
				id: 20,
				country_name: "BELGICA"
			},
			{
				id: 21,
				country_name: "BELIZE"
			},
			{
				id: 22,
				country_name: "BENIN"
			},
			{
				id: 23,
				country_name: "BERMUDAS"
			},
			{
				id: 24,
				country_name: "BOLIVIA"
			},
			{
				id: 25,
				country_name: "BOTSUANA"
			},
			{
				id: 26,
				country_name: "BRASIL"
			},
			{
				id: 27,
				country_name: "BRUNEI"
			},
			{
				id: 28,
				country_name: "BURKINA FASO"
			},
			{
				id: 29,
				country_name: "BURUNDI"
			},
			{
				id: 30,
				country_name: "BUTAO"
			},
			{
				id: 31,
				country_name: "CAMAROES"
			},
			{
				id: 32,
				country_name: "CAMPUCHEA"
			},
			{
				id: 33,
				country_name: "CANADA"
			},
			{
				id: 34,
				country_name: "CATAR"
			},
			{
				id: 35,
				country_name: "CHADE"
			},
			{
				id: 36,
				country_name: "CHILE"
			},
			{
				id: 37,
				country_name: "CHIPRE"
			},
			{
				id: 38,
				country_name: "CINGAPURA"
			},
			{
				id: 39,
				country_name: "COLOMBIA"
			},
			{
				id: 40,
				country_name: "CONGO"
			},
			{
				id: 41,
				country_name: "COSTA DO MARFIM"
			},
			{
				id: 42,
				country_name: "COSTA RICA"
			},
			{
				id: 43,
				country_name: "CUBA"
			},
			{
				id: 44,
				country_name: "CURACAO"
			},
			{
				id: 45,
				country_name: "DIEGO E GARCIA"
			},
			{
				id: 46,
				country_name: "DINAMARCA"
			},
			{
				id: 47,
				country_name: "DJIBUTI"
			},
			{
				id: 48,
				country_name: "EGITO"
			},
			{
				id: 49,
				country_name: "EL SALVADOR"
			},
			{
				id: 50,
				country_name: "EMIRADOS ARABES UNIDOS"
			},
			{
				id: 51,
				country_name: "EQUADOR"
			},
			{
				id: 52,
				country_name: "ERITREIA"
			},
			{
				id: 53,
				country_name: "ESPANHA"
			},
			{
				id: 54,
				country_name: "EST. DA CIDADE DO VATICANO"
			},
			{
				id: 55,
				country_name: "ESTADOS UNIDOS"
			},
			{
				id: 56,
				country_name: "ETIOPIA"
			},
			{
				id: 57,
				country_name: "FALKLAND (ILHAS MALVINAS)"
			},
			{
				id: 58,
				country_name: "FEDERACAO DA RUSSIA"
			},
			{
				id: 59,
				country_name: "FIJI"
			},
			{
				id: 60,
				country_name: "FILIPINAS"
			},
			{
				id: 61,
				country_name: "FINLANDIA"
			},
			{
				id: 62,
				country_name: "FORMOSA (TAIWAN)"
			},
			{
				id: 63,
				country_name: "FRANCA"
			},
			{
				id: 64,
				country_name: "GABAO"
			},
			{
				id: 65,
				country_name: "GAMBIA"
			},
			{
				id: 66,
				country_name: "GANA"
			},
			{
				id: 67,
				country_name: "GIBRALTAR"
			},
			{
				id: 68,
				country_name: "GRANADA"
			},
			{
				id: 69,
				country_name: "GRECIA"
			},
			{
				id: 70,
				country_name: "GROENLANDIA"
			},
			{
				id: 71,
				country_name: "GUADALUPE"
			},
			{
				id: 72,
				country_name: "GUAM"
			},
			{
				id: 73,
				country_name: "GUATEMALA"
			},
			{
				id: 74,
				country_name: "GUERNSEY"
			},
			{
				id: 75,
				country_name: "GUIANA"
			},
			{
				id: 76,
				country_name: "GUIANA FRANCESA"
			},
			{
				id: 77,
				country_name: "GUINE"
			},
			{
				id: 78,
				country_name: "GUINE-BISSAU"
			},
			{
				id: 79,
				country_name: "GUINE-EQUATORIAL"
			},
			{
				id: 80,
				country_name: "HAITI"
			},
			{
				id: 81,
				country_name: "HONDURAS"
			},
			{
				id: 82,
				country_name: "HONG KONG"
			},
			{
				id: 83,
				country_name: "IEMEN"
			},
			{
				id: 84,
				country_name: "ILHA (NAVIDAD) CHRISTMAS"
			},
			{
				id: 85,
				country_name: "ILHA BOUVET"
			},
			{
				id: 86,
				country_name: "ILHA DA MADEIRA"
			},
			{
				id: 87,
				country_name: "ILHA DE MAN"
			},
			{
				id: 88,
				country_name: "ILHA DO CANAL JERSEY"
			},
			{
				id: 89,
				country_name: "ILHA DOMINICA"
			},
			{
				id: 90,
				country_name: "ILHA HEARD E ILHAS MCDONALD"
			},
			{
				id: 91,
				country_name: "ILHA NIUE"
			},
			{
				id: 92,
				country_name: "ILHA NORFOLK"
			},
			{
				id: 93,
				country_name: "ILHA PITCAIRN"
			},
			{
				id: 94,
				country_name: "ILHA REUNIAO"
			},
			{
				id: 95,
				country_name: "ILHA WAKE"
			},
			{
				id: 96,
				country_name: "ILHAS (BRITANICAS) VIRGENS"
			},
			{
				id: 97,
				country_name: "ILHAS (E.U.A.) VIRGENS"
			},
			{
				id: 98,
				country_name: "ILHAS ALANDA"
			},
			{
				id: 99,
				country_name: "ILHAS ASCENSAO"
			},
			{
				id: 100,
				country_name: "ILHAS BAHAMAS"
			},
			{
				id: 101,
				country_name: "ILHAS BAHREIN"
			},
			{
				id: 102,
				country_name: "ILHAS CANARIAS"
			},
			{
				id: 103,
				country_name: "ILHAS CAYMAN"
			},
			{
				id: 104,
				country_name: "ILHAS COCOS (KEELING)"
			},
			{
				id: 105,
				country_name: "ILHAS COMORES"
			},
			{
				id: 106,
				country_name: "ILHAS COOK"
			},
			{
				id: 107,
				country_name: "ILHAS DO PACIFICO"
			},
			{
				id: 108,
				country_name: "ILHAS FEROE"
			},
			{
				id: 109,
				country_name: "ILHAS GEORGIA DO SUL E SANDUICHE DO SUL"
			},
			{
				id: 110,
				country_name: "ILHAS JOHNSTON"
			},
			{
				id: 111,
				country_name: "ILHAS LEBUAN"
			},
			{
				id: 112,
				country_name: "ILHAS MARSHALL"
			},
			{
				id: 113,
				country_name: "ILHAS MENORES DISTANTES DOS ESTADOS UNIDOS"
			},
			{
				id: 114,
				country_name: "ILHAS MIDWAY"
			},
			{
				id: 115,
				country_name: "ILHAS MONTSERRAT"
			},
			{
				id: 116,
				country_name: "ILHAS RODRIGUES"
			},
			{
				id: 117,
				country_name: "ILHAS SALOMAO"
			},
			{
				id: 118,
				country_name: "ILHAS SAO CRISTOVAO E NEVES"
			},
			{
				id: 119,
				country_name: "ILHAS SAO TOME E PRINCIPE"
			},
			{
				id: 120,
				country_name: "ILHAS TOQUELAU"
			},
			{
				id: 121,
				country_name: "ILHAS TURCAS E CAICOS"
			},
			{
				id: 122,
				country_name: "ILHAS WALLIS E FUTUNA"
			},
			{
				id: 123,
				country_name: "INDIA"
			},
			{
				id: 124,
				country_name: "INDONESIA"
			},
			{
				id: 125,
				country_name: "IRAQUE"
			},
			{
				id: 126,
				country_name: "IRLANDA"
			},
			{
				id: 127,
				country_name: "ISLANDIA"
			},
			{
				id: 128,
				country_name: "ISRAEL"
			},
			{
				id: 129,
				country_name: "ITALIA"
			},
			{
				id: 130,
				country_name: "JAMAICA"
			},
			{
				id: 131,
				country_name: "JAPAO"
			},
			{
				id: 132,
				country_name: "JORDANIA"
			},
			{
				id: 133,
				country_name: "KIRIBATI"
			},
			{
				id: 134,
				country_name: "KOSOVO"
			},
			{
				id: 135,
				country_name: "KUWAIT"
			},
			{
				id: 136,
				country_name: "LESOTO"
			},
			{
				id: 137,
				country_name: "LIBANO"
			},
			{
				id: 138,
				country_name: "LIBERIA"
			},
			{
				id: 139,
				country_name: "LIBIA"
			},
			{
				id: 140,
				country_name: "LIECHTENSTEIN"
			},
			{
				id: 141,
				country_name: "LUXEMBURGO"
			},
			{
				id: 142,
				country_name: "MACAU"
			},
			{
				id: 143,
				country_name: "MADAGASCAR"
			},
			{
				id: 144,
				country_name: "MALASIA"
			},
			{
				id: 145,
				country_name: "MALAVI"
			},
			{
				id: 146,
				country_name: "MALDIVAS"
			},
			{
				id: 147,
				country_name: "MALI"
			},
			{
				id: 148,
				country_name: "MALTA"
			},
			{
				id: 149,
				country_name: "MARIANAS DO NORTE"
			},
			{
				id: 150,
				country_name: "MARROCOS"
			},
			{
				id: 151,
				country_name: "MARTINICA"
			},
			{
				id: 152,
				country_name: "MAURICIO"
			},
			{
				id: 153,
				country_name: "MAURITANIA"
			},
			{
				id: 154,
				country_name: "MAYOTTE"
			},
			{
				id: 155,
				country_name: "MEXICO"
			},
			{
				id: 156,
				country_name: "MIANMAR (BIRMANIA)"
			},
			{
				id: 157,
				country_name: "MICRONESIA"
			},
			{
				id: 158,
				country_name: "MOCAMBIQUE"
			},
			{
				id: 159,
				country_name: "MONACO"
			},
			{
				id: 160,
				country_name: "MONGOLIA"
			},
			{
				id: 161,
				country_name: "MONTENEGRO"
			},
			{
				id: 162,
				country_name: "NAMIBIA"
			},
			{
				id: 163,
				country_name: "NAURU"
			},
			{
				id: 164,
				country_name: "NEPAL"
			},
			{
				id: 165,
				country_name: "NICARAGUA"
			},
			{
				id: 166,
				country_name: "NIGER"
			},
			{
				id: 167,
				country_name: "NIGERIA"
			},
			{
				id: 168,
				country_name: "NORUEGA"
			},
			{
				id: 169,
				country_name: "NOVA CALEDONIA"
			},
			{
				id: 170,
				country_name: "NOVA ZELANDIA"
			},
			{
				id: 171,
				country_name: "OMA"
			},
			{
				id: 172,
				country_name: "OUTROS"
			},
			{
				id: 173,
				country_name: "PAISES BAIXOS (HOLANDA)"
			},
			{
				id: 174,
				country_name: "PALAU"
			},
			{
				id: 175,
				country_name: "PALESTINA"
			},
			{
				id: 176,
				country_name: "PANAMA"
			},
			{
				id: 177,
				country_name: "PAPUA NOVA GUINE"
			},
			{
				id: 178,
				country_name: "PAQUISTAO"
			},
			{
				id: 179,
				country_name: "PARAGUAI"
			},
			{
				id: 180,
				country_name: "PERU"
			},
			{
				id: 181,
				country_name: "POLINESIA FRANCESA"
			},
			{
				id: 182,
				country_name: "PORTO RICO"
			},
			{
				id: 183,
				country_name: "PORTUGAL"
			},
			{
				id: 184,
				country_name: "PROVISAO DE NAVIOS E AERONAVES"
			},
			{
				id: 185,
				country_name: "QUENIA"
			},
			{
				id: 186,
				country_name: "REINO UNIDO"
			},
			{
				id: 187,
				country_name: "REP. FED. DA IUGOSLAVIA"
			},
			{
				id: 188,
				country_name: "REP. POP. DEMOCR. DO LAOS"
			},
			{
				id: 189,
				country_name: "REP. POP. DEMOCRATICA COREIA (COREIA DO NORTE)"
			},
			{
				id: 190,
				country_name: "REP. UNIDA DA TANZANIA"
			},
			{
				id: 191,
				country_name: "REPUBLICA ARABE DA SIRIA"
			},
			{
				id: 192,
				country_name: "REPUBLICA CENTRO-AFRICANA"
			},
			{
				id: 193,
				country_name: "REPUBLICA DA ALBANIA"
			},
			{
				id: 194,
				country_name: "REPUBLICA DA ARMENIA"
			},
			{
				id: 195,
				country_name: "REPUBLICA DA BELARUS"
			},
			{
				id: 196,
				country_name: "REPUBLICA DA BOSNIA-HERZEGOVINA"
			},
			{
				id: 197,
				country_name: "REPUBLICA DA BULGARIA"
			},
			{
				id: 198,
				country_name: "REPUBLICA DA COREIA (COREIA DO SUL)"
			},
			{
				id: 199,
				country_name: "REPUBLICA DA CROACIA"
			},
			{
				id: 200,
				country_name: "REPUBLICA DA ESLOVENIA"
			},
			{
				id: 201,
				country_name: "REPUBLICA DA ESTONIA"
			},
			{
				id: 202,
				country_name: "REPUBLICA DA GEORGIA"
			},
			{
				id: 203,
				country_name: "REPUBLICA DA HUNGRIA"
			},
			{
				id: 204,
				country_name: "REPUBLICA DA LETONIA"
			},
			{
				id: 205,
				country_name: "REPUBLICA DA LITUANIA"
			},
			{
				id: 206,
				country_name: "REPUBLICA DA MOLDAVIA"
			},
			{
				id: 207,
				country_name: "REPUBLICA DA POLONIA"
			},
			{
				id: 208,
				country_name: "REPUBLICA DE CABO VERDE"
			},
			{
				id: 209,
				country_name: "REPUBLICA DEMOCRATICA DO CONGO"
			},
			{
				id: 210,
				country_name: "REPUBLICA DO AZERBAIJAO"
			},
			{
				id: 211,
				country_name: "REPUBLICA DO CAZAQUISTAO"
			},
			{
				id: 212,
				country_name: "REPUBLICA DO TADJIQUISTAO"
			},
			{
				id: 213,
				country_name: "REPUBLICA DO TURCOMENISTAO"
			},
			{
				id: 214,
				country_name: "REPUBLICA DO UZBEQUISTAO"
			},
			{
				id: 215,
				country_name: "REPUBLICA DOMINICANA"
			},
			{
				id: 216,
				country_name: "REPUBLICA ESLOVACA"
			},
			{
				id: 217,
				country_name: "REPUBLICA ISLAMICA DO IRA"
			},
			{
				id: 218,
				country_name: "REPUBLICA POPULAR CHINA"
			},
			{
				id: 219,
				country_name: "REPUBLICA QUIRGUIZ"
			},
			{
				id: 220,
				country_name: "REPUBLICA TCHECA"
			},
			{
				id: 221,
				country_name: "ROMENIA"
			},
			{
				id: 222,
				country_name: "RUANDA"
			},
			{
				id: 223,
				country_name: "SAARA OCIDENTAL"
			},
			{
				id: 224,
				country_name: "SAINT KITTS E NEVIS"
			},
			{
				id: 225,
				country_name: "SAINT MARTIN"
			},
			{
				id: 226,
				country_name: "SAINT PIERREE MIQUELON"
			},
			{
				id: 227,
				country_name: "SAMOA"
			},
			{
				id: 228,
				country_name: "SAMOA AMERICANA"
			},
			{
				id: 229,
				country_name: "SAN MARINO"
			},
			{
				id: 230,
				country_name: "SANTA HELENA"
			},
			{
				id: 231,
				country_name: "SANTA LUCIA"
			},
			{
				id: 232,
				country_name: "SAO VICENTE E GRANADINAS"
			},
			{
				id: 233,
				country_name: "SENEGAL"
			},
			{
				id: 234,
				country_name: "SERRA LEOA"
			},
			{
				id: 235,
				country_name: "SERVIA"
			},
			{
				id: 236,
				country_name: "SEYCHELLES"
			},
			{
				id: 237,
				country_name: "SOMALIA"
			},
			{
				id: 238,
				country_name: "SRI LANKA"
			},
			{
				id: 239,
				country_name: "SUAZILANDIA"
			},
			{
				id: 240,
				country_name: "SUDAO"
			},
			{
				id: 241,
				country_name: "SUDAO DO SUL"
			},
			{
				id: 242,
				country_name: "SUECIA"
			},
			{
				id: 243,
				country_name: "SUICA"
			},
			{
				id: 244,
				country_name: "SURINAME"
			},
			{
				id: 245,
				country_name: "TAILANDIA"
			},
			{
				id: 246,
				country_name: "TERRIT. EXT. AUSTRALIA"
			},
			{
				id: 247,
				country_name: "TERRITORIO BRIT. OCEANO INDICO"
			},
			{
				id: 248,
				country_name: "TIMOR LESTE"
			},
			{
				id: 249,
				country_name: "TOGO"
			},
			{
				id: 250,
				country_name: "TONGA"
			},
			{
				id: 251,
				country_name: "TRINIDAD E TOBAGO"
			},
			{
				id: 252,
				country_name: "TUNISIA"
			},
			{
				id: 253,
				country_name: "TURQUIA"
			},
			{
				id: 254,
				country_name: "TUVALU"
			},
			{
				id: 255,
				country_name: "UCRANIA"
			},
			{
				id: 256,
				country_name: "UGANDA"
			},
			{
				id: 257,
				country_name: "URUGUAI"
			},
			{
				id: 258,
				country_name: "VANUATU"
			},
			{
				id: 259,
				country_name: "VENEZUELA"
			},
			{
				id: 260,
				country_name: "VIETNA"
			},
			{
				id: 261,
				country_name: "ZAMBIA"
			},
			{
				id: 262,
				country_name: "ZANZIBAR"
			},
			{
				id: 263,
				country_name: "ZIMBABUE"
			},
			{
				id: 264,
				country_name: "ZONA DO CANAL DO PANAMA"
			}
		]
	},
	healthPlanOperators: {
		data: [
			{
				id: 1,
				operator_name: "SulAmÈrica"
			},
			{
				id: 2,
				operator_name: "Porto Seguro"
			},
			{
				id: 3,
				operator_name: "Ita˙"
			},
			{
				id: 4,
				operator_name: "Unimed"
			},
			{
				id: 5,
				operator_name: "CarePlus"
			},
			{
				id: 6,
				operator_name: "Leader"
			},
			{
				id: 7,
				operator_name: "InterclÌnicas"
			},
			{
				id: 8,
				operator_name: "Plena Sa˙de"
			}
		]
	},
	addressTypes: {
		data: [
			{
				id: 1,
				type: "Residencial"
			},
			{
				id: 2,
				type: "Comercial"
			}
		]
	},
	phonesTypes: {
		data: [
			{
				id: 1,
				type: "Fixo"
			},
			{
				id: 2,
				type: "Celular"
			},
		]
	},
	contactTypes: {
		data: [
			{
				id: 1,
				type: "E-mail"
			},
			{
				id: 2,
				type: "Messenger"
			},
			{
				id: 3,
				type: "Instagram"
			},
			{
				id: 4,
				type: "Facebook"
			},
			{
				id: 5,
				type: "Skype"
			}
		]
	}
};


export const formikComboTypes = {
	genders: {
		data: [
			{
				id: 1,
				name: "Masculino",
				sub_name: "Masculino"
			},
			{
				id: 2,
				name: "Masculino Trans",
				sub_name: "Masculino Trans"
			},
			{
				id: 3,
				name: "Feminino",
				sub_name: "Feminino"
			},
			{
				id: 4,
				name: "Feminino Trans",
				sub_name: "Feminino Trans"
			},
			{
				id: 5,
				name: "Indefinido",
				sub_name: "Indefinido"
			}
		]
	},
	nationality: {
		data: [
			{
				id: 1,
				nationality: "Afeg„o"
			},
			{
				id: 2,
				nationality: "Albanesa"
			},
			{
				id: 3,
				nationality: "Alem„"
			},
			{
				id: 4,
				nationality: "Algeriana"
			},
			{
				id: 5,
				nationality: "Americana"
			},
			{
				id: 6,
				nationality: "Angolana"
			},
			{
				id: 7,
				nationality: "Antiguana"
			},
			{
				id: 8,
				nationality: "Ap·trida"
			},
			{
				id: 9,
				nationality: "Argelina"
			},
			{
				id: 10,
				nationality: "Argentina"
			},
			{
				id: 11,
				nationality: "ArmÍnia"
			},
			{
				id: 12,
				nationality: "Australiana"
			},
			{
				id: 13,
				nationality: "Austro-h˙ngara"
			},
			{
				id: 14,
				nationality: "AustrÌaca"
			},
			{
				id: 15,
				nationality: "AÁoriana"
			},
			{
				id: 16,
				nationality: "Bahamense"
			},
			{
				id: 17,
				nationality: "Barbadense"
			},
			{
				id: 18,
				nationality: "Bechuano"
			},
			{
				id: 19,
				nationality: "Belga"
			},
			{
				id: 20,
				nationality: "Belizenha"
			},
			{
				id: 21,
				nationality: "Bohemia"
			},
			{
				id: 22,
				nationality: "Boliviana"
			},
			{
				id: 23,
				nationality: "Bosnia"
			},
			{
				id: 24,
				nationality: "Brasileira"
			},
			{
				id: 25,
				nationality: "Brit‚nico"
			},
			{
				id: 26,
				nationality: "B˙lgara"
			},
			{
				id: 27,
				nationality: "Camaronense"
			},
			{
				id: 28,
				nationality: "Canadense"
			},
			{
				id: 29,
				nationality: "Chilena"
			},
			{
				id: 30,
				nationality: "Chinesa"
			},
			{
				id: 31,
				nationality: "CingalÍs"
			},
			{
				id: 32,
				nationality: "Colombiana"
			},
			{
				id: 33,
				nationality: "Comorense"
			},
			{
				id: 34,
				nationality: "Coreana"
			},
			{
				id: 35,
				nationality: "Costarriquenha"
			},
			{
				id: 36,
				nationality: "Croata"
			},
			{
				id: 37,
				nationality: "Cubana"
			},
			{
				id: 38,
				nationality: "Dinamarquesa"
			},
			{
				id: 39,
				nationality: "Dominicana"
			},
			{
				id: 40,
				nationality: "EgÌpcia"
			},
			{
				id: 41,
				nationality: "Equatoriana"
			},
			{
				id: 42,
				nationality: "Escandinava"
			},
			{
				id: 43,
				nationality: "Escocesa"
			},
			{
				id: 44,
				nationality: "Eslava"
			},
			{
				id: 45,
				nationality: "Eslovaca"
			},
			{
				id: 46,
				nationality: "Eslovena"
			},
			{
				id: 47,
				nationality: "Espanhola"
			},
			{
				id: 48,
				nationality: "Finlandesa"
			},
			{
				id: 49,
				nationality: "Francesa"
			},
			{
				id: 50,
				nationality: "Galega"
			},
			{
				id: 51,
				nationality: "GanÍs"
			},
			{
				id: 52,
				nationality: "Granadina"
			},
			{
				id: 53,
				nationality: "Grega"
			},
			{
				id: 54,
				nationality: "Guatemalteca"
			},
			{
				id: 55,
				nationality: "Guianense"
			},
			{
				id: 56,
				nationality: "GuianÍs"
			},
			{
				id: 57,
				nationality: "Haitiana"
			},
			{
				id: 58,
				nationality: "Holandesa"
			},
			{
				id: 59,
				nationality: "Hondurenha"
			},
			{
				id: 60,
				nationality: "H˙ngara"
			},
			{
				id: 61,
				nationality: "Iemenita"
			},
			{
				id: 62,
				nationality: "Indiana"
			},
			{
				id: 63,
				nationality: "IndonÈsio"
			},
			{
				id: 64,
				nationality: "Inglesa"
			},
			{
				id: 65,
				nationality: "Iraquiano"
			},
			{
				id: 66,
				nationality: "Irlandesa"
			},
			{
				id: 67,
				nationality: "Israelense"
			},
			{
				id: 68,
				nationality: "Italiana"
			},
			{
				id: 69,
				nationality: "Jamaicana"
			},
			{
				id: 70,
				nationality: "Japonesa"
			},
			{
				id: 71,
				nationality: "Libanesa"
			},
			{
				id: 72,
				nationality: "Libia"
			},
			{
				id: 73,
				nationality: "Lituana"
			},
			{
				id: 74,
				nationality: "Luxemburguesa"
			},
			{
				id: 75,
				nationality: "Malaio"
			},
			{
				id: 76,
				nationality: "Marfinense"
			},
			{
				id: 77,
				nationality: "Marroquina"
			},
			{
				id: 78,
				nationality: "Mexicana"
			},
			{
				id: 79,
				nationality: "Montenegrina"
			},
			{
				id: 80,
				nationality: "MoÁambicana"
			},
			{
				id: 81,
				nationality: "NeozelandÍs"
			},
			{
				id: 82,
				nationality: "NepalÍs"
			},
			{
				id: 83,
				nationality: "Nicaraguense"
			},
			{
				id: 84,
				nationality: "Nigeriano"
			},
			{
				id: 85,
				nationality: "Norte-Americana"
			},
			{
				id: 86,
				nationality: "Norueguesa"
			},
			{
				id: 87,
				nationality: "Omanense"
			},
			{
				id: 88,
				nationality: "Otomana"
			},
			{
				id: 89,
				nationality: "Outras"
			},
			{
				id: 90,
				nationality: "Palestina"
			},
			{
				id: 91,
				nationality: "Panamenha"
			},
			{
				id: 92,
				nationality: "PaquistanÍs"
			},
			{
				id: 93,
				nationality: "Paraguaia"
			},
			{
				id: 94,
				nationality: "Persa"
			},
			{
				id: 95,
				nationality: "Peruana"
			},
			{
				id: 96,
				nationality: "Polaca"
			},
			{
				id: 97,
				nationality: "Polonesa"
			},
			{
				id: 98,
				nationality: "Portorriquenha"
			},
			{
				id: 99,
				nationality: "Portuguesa"
			},
			{
				id: 100,
				nationality: "Prussiana"
			},
			{
				id: 101,
				nationality: "Prussiano"
			},
			{
				id: 102,
				nationality: "Qararense"
			},
			{
				id: 103,
				nationality: "Queniano"
			},
			{
				id: 104,
				nationality: "Romena"
			},
			{
				id: 105,
				nationality: "RuandÍs"
			},
			{
				id: 106,
				nationality: "Russa"
			},
			{
				id: 107,
				nationality: "Salvadorenha"
			},
			{
				id: 108,
				nationality: "Santa-lucense"
			},
			{
				id: 109,
				nationality: "Saudita"
			},
			{
				id: 110,
				nationality: "SaxÙnia"
			},
			{
				id: 111,
				nationality: "Somali"
			},
			{
				id: 112,
				nationality: "Sueca"
			},
			{
				id: 113,
				nationality: "SuiÁa"
			},
			{
				id: 114,
				nationality: "Sul-Africana"
			},
			{
				id: 115,
				nationality: "SurinamÍs"
			},
			{
				id: 116,
				nationality: "S„o-cristovense"
			},
			{
				id: 117,
				nationality: "S„o-vicentino"
			},
			{
				id: 118,
				nationality: "SÈrvia"
			},
			{
				id: 119,
				nationality: "SÌria"
			},
			{
				id: 120,
				nationality: "TailandÍs"
			},
			{
				id: 121,
				nationality: "Tchecoslovaca"
			},
			{
				id: 122,
				nationality: "Timorense"
			},
			{
				id: 123,
				nationality: "Tirol"
			},
			{
				id: 124,
				nationality: "Trindadense"
			},
			{
				id: 125,
				nationality: "Turca"
			},
			{
				id: 126,
				nationality: "Ucraniana"
			},
			{
				id: 127,
				nationality: "Ugandense"
			},
			{
				id: 128,
				nationality: "Uruguaia"
			},
			{
				id: 129,
				nationality: "Venezuelana"
			},
			{
				id: 130,
				nationality: "Vietinamita"
			},
			{
				id: 131,
				nationality: "Zimbabuense"
			},
			{
				id: 132,
				nationality: "¡rabe"
			}
		]
	},
	countrys: {
		data: [
			{
				id: 1,
				name: "AFEGANISTAO"
			},
			{
				id: 2,
				name: "AFRICA DO SUL"
			},
			{
				id: 3,
				name: "ALEMANHA"
			},
			{
				id: 4,
				name: "ANDORRA"
			},
			{
				id: 5,
				name: "ANGOLA"
			},
			{
				id: 6,
				name: "ANGUILLA"
			},
			{
				id: 7,
				name: "ANT. REP. IUGOSLAVA MACEDONIA"
			},
			{
				id: 8,
				name: "ANTARCTIDA"
			},
			{
				id: 9,
				name: "ANTIGUA E BARBUDA"
			},
			{
				id: 10,
				name: "ANTILHAS HOLANDESAS"
			},
			{
				id: 11,
				name: "APATRIDA"
			},
			{
				id: 12,
				name: "ARABIA SAUDITA"
			},
			{
				id: 13,
				name: "ARGELIA"
			},
			{
				id: 14,
				name: "ARGENTINA"
			},
			{
				id: 15,
				name: "ARUBA"
			},
			{
				id: 16,
				name: "AUSTRALIA"
			},
			{
				id: 17,
				name: "AUSTRIA"
			},
			{
				id: 18,
				name: "BANGLADESH"
			},
			{
				id: 19,
				name: "BARBADOS"
			},
			{
				id: 20,
				name: "BELGICA"
			},
			{
				id: 21,
				name: "BELIZE"
			},
			{
				id: 22,
				name: "BENIN"
			},
			{
				id: 23,
				name: "BERMUDAS"
			},
			{
				id: 24,
				name: "BOLIVIA"
			},
			{
				id: 25,
				name: "BOTSUANA"
			},
			{
				id: 26,
				name: "BRASIL"
			},
			{
				id: 27,
				name: "BRUNEI"
			},
			{
				id: 28,
				name: "BURKINA FASO"
			},
			{
				id: 29,
				name: "BURUNDI"
			},
			{
				id: 30,
				name: "BUTAO"
			},
			{
				id: 31,
				name: "CAMAROES"
			},
			{
				id: 32,
				name: "CAMPUCHEA"
			},
			{
				id: 33,
				name: "CANADA"
			},
			{
				id: 34,
				name: "CATAR"
			},
			{
				id: 35,
				name: "CHADE"
			},
			{
				id: 36,
				name: "CHILE"
			},
			{
				id: 37,
				name: "CHIPRE"
			},
			{
				id: 38,
				name: "CINGAPURA"
			},
			{
				id: 39,
				name: "COLOMBIA"
			},
			{
				id: 40,
				name: "CONGO"
			},
			{
				id: 41,
				name: "COSTA DO MARFIM"
			},
			{
				id: 42,
				name: "COSTA RICA"
			},
			{
				id: 43,
				name: "CUBA"
			},
			{
				id: 44,
				name: "CURACAO"
			},
			{
				id: 45,
				name: "DIEGO E GARCIA"
			},
			{
				id: 46,
				name: "DINAMARCA"
			},
			{
				id: 47,
				name: "DJIBUTI"
			},
			{
				id: 48,
				name: "EGITO"
			},
			{
				id: 49,
				name: "EL SALVADOR"
			},
			{
				id: 50,
				name: "EMIRADOS ARABES UNIDOS"
			},
			{
				id: 51,
				name: "EQUADOR"
			},
			{
				id: 52,
				name: "ERITREIA"
			},
			{
				id: 53,
				name: "ESPANHA"
			},
			{
				id: 54,
				name: "EST. DA CIDADE DO VATICANO"
			},
			{
				id: 55,
				name: "ESTADOS UNIDOS"
			},
			{
				id: 56,
				name: "ETIOPIA"
			},
			{
				id: 57,
				name: "FALKLAND (ILHAS MALVINAS)"
			},
			{
				id: 58,
				name: "FEDERACAO DA RUSSIA"
			},
			{
				id: 59,
				name: "FIJI"
			},
			{
				id: 60,
				name: "FILIPINAS"
			},
			{
				id: 61,
				name: "FINLANDIA"
			},
			{
				id: 62,
				name: "FORMOSA (TAIWAN)"
			},
			{
				id: 63,
				name: "FRANCA"
			},
			{
				id: 64,
				name: "GABAO"
			},
			{
				id: 65,
				name: "GAMBIA"
			},
			{
				id: 66,
				name: "GANA"
			},
			{
				id: 67,
				name: "GIBRALTAR"
			},
			{
				id: 68,
				name: "GRANADA"
			},
			{
				id: 69,
				name: "GRECIA"
			},
			{
				id: 70,
				name: "GROENLANDIA"
			},
			{
				id: 71,
				name: "GUADALUPE"
			},
			{
				id: 72,
				name: "GUAM"
			},
			{
				id: 73,
				name: "GUATEMALA"
			},
			{
				id: 74,
				name: "GUERNSEY"
			},
			{
				id: 75,
				name: "GUIANA"
			},
			{
				id: 76,
				name: "GUIANA FRANCESA"
			},
			{
				id: 77,
				name: "GUINE"
			},
			{
				id: 78,
				name: "GUINE-BISSAU"
			},
			{
				id: 79,
				name: "GUINE-EQUATORIAL"
			},
			{
				id: 80,
				name: "HAITI"
			},
			{
				id: 81,
				name: "HONDURAS"
			},
			{
				id: 82,
				name: "HONG KONG"
			},
			{
				id: 83,
				name: "IEMEN"
			},
			{
				id: 84,
				name: "ILHA (NAVIDAD) CHRISTMAS"
			},
			{
				id: 85,
				name: "ILHA BOUVET"
			},
			{
				id: 86,
				name: "ILHA DA MADEIRA"
			},
			{
				id: 87,
				name: "ILHA DE MAN"
			},
			{
				id: 88,
				name: "ILHA DO CANAL JERSEY"
			},
			{
				id: 89,
				name: "ILHA DOMINICA"
			},
			{
				id: 90,
				name: "ILHA HEARD E ILHAS MCDONALD"
			},
			{
				id: 91,
				name: "ILHA NIUE"
			},
			{
				id: 92,
				name: "ILHA NORFOLK"
			},
			{
				id: 93,
				name: "ILHA PITCAIRN"
			},
			{
				id: 94,
				name: "ILHA REUNIAO"
			},
			{
				id: 95,
				name: "ILHA WAKE"
			},
			{
				id: 96,
				name: "ILHAS (BRITANICAS) VIRGENS"
			},
			{
				id: 97,
				name: "ILHAS (E.U.A.) VIRGENS"
			},
			{
				id: 98,
				name: "ILHAS ALANDA"
			},
			{
				id: 99,
				name: "ILHAS ASCENSAO"
			},
			{
				id: 100,
				name: "ILHAS BAHAMAS"
			},
			{
				id: 101,
				name: "ILHAS BAHREIN"
			},
			{
				id: 102,
				name: "ILHAS CANARIAS"
			},
			{
				id: 103,
				name: "ILHAS CAYMAN"
			},
			{
				id: 104,
				name: "ILHAS COCOS (KEELING)"
			},
			{
				id: 105,
				name: "ILHAS COMORES"
			},
			{
				id: 106,
				name: "ILHAS COOK"
			},
			{
				id: 107,
				name: "ILHAS DO PACIFICO"
			},
			{
				id: 108,
				name: "ILHAS FEROE"
			},
			{
				id: 109,
				name: "ILHAS GEORGIA DO SUL E SANDUICHE DO SUL"
			},
			{
				id: 110,
				name: "ILHAS JOHNSTON"
			},
			{
				id: 111,
				name: "ILHAS LEBUAN"
			},
			{
				id: 112,
				name: "ILHAS MARSHALL"
			},
			{
				id: 113,
				name: "ILHAS MENORES DISTANTES DOS ESTADOS UNIDOS"
			},
			{
				id: 114,
				name: "ILHAS MIDWAY"
			},
			{
				id: 115,
				name: "ILHAS MONTSERRAT"
			},
			{
				id: 116,
				name: "ILHAS RODRIGUES"
			},
			{
				id: 117,
				name: "ILHAS SALOMAO"
			},
			{
				id: 118,
				name: "ILHAS SAO CRISTOVAO E NEVES"
			},
			{
				id: 119,
				name: "ILHAS SAO TOME E PRINCIPE"
			},
			{
				id: 120,
				name: "ILHAS TOQUELAU"
			},
			{
				id: 121,
				name: "ILHAS TURCAS E CAICOS"
			},
			{
				id: 122,
				name: "ILHAS WALLIS E FUTUNA"
			},
			{
				id: 123,
				name: "INDIA"
			},
			{
				id: 124,
				name: "INDONESIA"
			},
			{
				id: 125,
				name: "IRAQUE"
			},
			{
				id: 126,
				name: "IRLANDA"
			},
			{
				id: 127,
				name: "ISLANDIA"
			},
			{
				id: 128,
				name: "ISRAEL"
			},
			{
				id: 129,
				name: "ITALIA"
			},
			{
				id: 130,
				name: "JAMAICA"
			},
			{
				id: 131,
				name: "JAPAO"
			},
			{
				id: 132,
				name: "JORDANIA"
			},
			{
				id: 133,
				name: "KIRIBATI"
			},
			{
				id: 134,
				name: "KOSOVO"
			},
			{
				id: 135,
				name: "KUWAIT"
			},
			{
				id: 136,
				name: "LESOTO"
			},
			{
				id: 137,
				name: "LIBANO"
			},
			{
				id: 138,
				name: "LIBERIA"
			},
			{
				id: 139,
				name: "LIBIA"
			},
			{
				id: 140,
				name: "LIECHTENSTEIN"
			},
			{
				id: 141,
				name: "LUXEMBURGO"
			},
			{
				id: 142,
				name: "MACAU"
			},
			{
				id: 143,
				name: "MADAGASCAR"
			},
			{
				id: 144,
				name: "MALASIA"
			},
			{
				id: 145,
				name: "MALAVI"
			},
			{
				id: 146,
				name: "MALDIVAS"
			},
			{
				id: 147,
				name: "MALI"
			},
			{
				id: 148,
				name: "MALTA"
			},
			{
				id: 149,
				name: "MARIANAS DO NORTE"
			},
			{
				id: 150,
				name: "MARROCOS"
			},
			{
				id: 151,
				name: "MARTINICA"
			},
			{
				id: 152,
				name: "MAURICIO"
			},
			{
				id: 153,
				name: "MAURITANIA"
			},
			{
				id: 154,
				name: "MAYOTTE"
			},
			{
				id: 155,
				name: "MEXICO"
			},
			{
				id: 156,
				name: "MIANMAR (BIRMANIA)"
			},
			{
				id: 157,
				name: "MICRONESIA"
			},
			{
				id: 158,
				name: "MOCAMBIQUE"
			},
			{
				id: 159,
				name: "MONACO"
			},
			{
				id: 160,
				name: "MONGOLIA"
			},
			{
				id: 161,
				name: "MONTENEGRO"
			},
			{
				id: 162,
				name: "NAMIBIA"
			},
			{
				id: 163,
				name: "NAURU"
			},
			{
				id: 164,
				name: "NEPAL"
			},
			{
				id: 165,
				name: "NICARAGUA"
			},
			{
				id: 166,
				name: "NIGER"
			},
			{
				id: 167,
				name: "NIGERIA"
			},
			{
				id: 168,
				name: "NORUEGA"
			},
			{
				id: 169,
				name: "NOVA CALEDONIA"
			},
			{
				id: 170,
				name: "NOVA ZELANDIA"
			},
			{
				id: 171,
				name: "OMA"
			},
			{
				id: 172,
				name: "OUTROS"
			},
			{
				id: 173,
				name: "PAISES BAIXOS (HOLANDA)"
			},
			{
				id: 174,
				name: "PALAU"
			},
			{
				id: 175,
				name: "PALESTINA"
			},
			{
				id: 176,
				name: "PANAMA"
			},
			{
				id: 177,
				name: "PAPUA NOVA GUINE"
			},
			{
				id: 178,
				name: "PAQUISTAO"
			},
			{
				id: 179,
				name: "PARAGUAI"
			},
			{
				id: 180,
				name: "PERU"
			},
			{
				id: 181,
				name: "POLINESIA FRANCESA"
			},
			{
				id: 182,
				name: "PORTO RICO"
			},
			{
				id: 183,
				name: "PORTUGAL"
			},
			{
				id: 184,
				name: "PROVISAO DE NAVIOS E AERONAVES"
			},
			{
				id: 185,
				name: "QUENIA"
			},
			{
				id: 186,
				name: "REINO UNIDO"
			},
			{
				id: 187,
				name: "REP. FED. DA IUGOSLAVIA"
			},
			{
				id: 188,
				name: "REP. POP. DEMOCR. DO LAOS"
			},
			{
				id: 189,
				name: "REP. POP. DEMOCRATICA COREIA (COREIA DO NORTE)"
			},
			{
				id: 190,
				name: "REP. UNIDA DA TANZANIA"
			},
			{
				id: 191,
				name: "REPUBLICA ARABE DA SIRIA"
			},
			{
				id: 192,
				name: "REPUBLICA CENTRO-AFRICANA"
			},
			{
				id: 193,
				name: "REPUBLICA DA ALBANIA"
			},
			{
				id: 194,
				name: "REPUBLICA DA ARMENIA"
			},
			{
				id: 195,
				name: "REPUBLICA DA BELARUS"
			},
			{
				id: 196,
				name: "REPUBLICA DA BOSNIA-HERZEGOVINA"
			},
			{
				id: 197,
				name: "REPUBLICA DA BULGARIA"
			},
			{
				id: 198,
				name: "REPUBLICA DA COREIA (COREIA DO SUL)"
			},
			{
				id: 199,
				name: "REPUBLICA DA CROACIA"
			},
			{
				id: 200,
				name: "REPUBLICA DA ESLOVENIA"
			},
			{
				id: 201,
				name: "REPUBLICA DA ESTONIA"
			},
			{
				id: 202,
				name: "REPUBLICA DA GEORGIA"
			},
			{
				id: 203,
				name: "REPUBLICA DA HUNGRIA"
			},
			{
				id: 204,
				name: "REPUBLICA DA LETONIA"
			},
			{
				id: 205,
				name: "REPUBLICA DA LITUANIA"
			},
			{
				id: 206,
				name: "REPUBLICA DA MOLDAVIA"
			},
			{
				id: 207,
				name: "REPUBLICA DA POLONIA"
			},
			{
				id: 208,
				name: "REPUBLICA DE CABO VERDE"
			},
			{
				id: 209,
				name: "REPUBLICA DEMOCRATICA DO CONGO"
			},
			{
				id: 210,
				name: "REPUBLICA DO AZERBAIJAO"
			},
			{
				id: 211,
				name: "REPUBLICA DO CAZAQUISTAO"
			},
			{
				id: 212,
				name: "REPUBLICA DO TADJIQUISTAO"
			},
			{
				id: 213,
				name: "REPUBLICA DO TURCOMENISTAO"
			},
			{
				id: 214,
				name: "REPUBLICA DO UZBEQUISTAO"
			},
			{
				id: 215,
				name: "REPUBLICA DOMINICANA"
			},
			{
				id: 216,
				name: "REPUBLICA ESLOVACA"
			},
			{
				id: 217,
				name: "REPUBLICA ISLAMICA DO IRA"
			},
			{
				id: 218,
				name: "REPUBLICA POPULAR CHINA"
			},
			{
				id: 219,
				name: "REPUBLICA QUIRGUIZ"
			},
			{
				id: 220,
				name: "REPUBLICA TCHECA"
			},
			{
				id: 221,
				name: "ROMENIA"
			},
			{
				id: 222,
				name: "RUANDA"
			},
			{
				id: 223,
				name: "SAARA OCIDENTAL"
			},
			{
				id: 224,
				name: "SAINT KITTS E NEVIS"
			},
			{
				id: 225,
				name: "SAINT MARTIN"
			},
			{
				id: 226,
				name: "SAINT PIERREE MIQUELON"
			},
			{
				id: 227,
				name: "SAMOA"
			},
			{
				id: 228,
				name: "SAMOA AMERICANA"
			},
			{
				id: 229,
				name: "SAN MARINO"
			},
			{
				id: 230,
				name: "SANTA HELENA"
			},
			{
				id: 231,
				name: "SANTA LUCIA"
			},
			{
				id: 232,
				name: "SAO VICENTE E GRANADINAS"
			},
			{
				id: 233,
				name: "SENEGAL"
			},
			{
				id: 234,
				name: "SERRA LEOA"
			},
			{
				id: 235,
				name: "SERVIA"
			},
			{
				id: 236,
				name: "SEYCHELLES"
			},
			{
				id: 237,
				name: "SOMALIA"
			},
			{
				id: 238,
				name: "SRI LANKA"
			},
			{
				id: 239,
				name: "SUAZILANDIA"
			},
			{
				id: 240,
				name: "SUDAO"
			},
			{
				id: 241,
				name: "SUDAO DO SUL"
			},
			{
				id: 242,
				name: "SUECIA"
			},
			{
				id: 243,
				name: "SUICA"
			},
			{
				id: 244,
				name: "SURINAME"
			},
			{
				id: 245,
				name: "TAILANDIA"
			},
			{
				id: 246,
				name: "TERRIT. EXT. AUSTRALIA"
			},
			{
				id: 247,
				name: "TERRITORIO BRIT. OCEANO INDICO"
			},
			{
				id: 248,
				name: "TIMOR LESTE"
			},
			{
				id: 249,
				name: "TOGO"
			},
			{
				id: 250,
				name: "TONGA"
			},
			{
				id: 251,
				name: "TRINIDAD E TOBAGO"
			},
			{
				id: 252,
				name: "TUNISIA"
			},
			{
				id: 253,
				name: "TURQUIA"
			},
			{
				id: 254,
				name: "TUVALU"
			},
			{
				id: 255,
				name: "UCRANIA"
			},
			{
				id: 256,
				name: "UGANDA"
			},
			{
				id: 257,
				name: "URUGUAI"
			},
			{
				id: 258,
				name: "VANUATU"
			},
			{
				id: 259,
				name: "VENEZUELA"
			},
			{
				id: 260,
				name: "VIETNA"
			},
			{
				id: 261,
				name: "ZAMBIA"
			},
			{
				id: 262,
				name: "ZANZIBAR"
			},
			{
				id: 263,
				name: "ZIMBABUE"
			},
			{
				id: 264,
				name: "ZONA DO CANAL DO PANAMA"
			}
		]
	},
	healthPlanOperators: {
		data: [
			{
				id: 1,
				operator_name: "SulAmÈrica"
			},
			{
				id: 2,
				operator_name: "Porto Seguro"
			},
			{
				id: 3,
				operator_name: "Ita˙"
			},
			{
				id: 4,
				operator_name: "Unimed"
			},
			{
				id: 5,
				operator_name: "CarePlus"
			},
			{
				id: 6,
				operator_name: "Leader"
			},
			{
				id: 7,
				operator_name: "InterclÌnicas"
			},
			{
				id: 8,
				operator_name: "Plena Sa˙de"
			}
		]
	},
	addressTypes: {
		data: [
			{
				id: 1,
				type: "Residencial"
			},
			{
				id: 2,
				type: "Comercial"
			}
		]
	},
	phonesTypes: {
		data: [
			{
				id: 1,
				type: "Fixo"
			},
			{
				id: 2,
				type: "Celular"
			},
		]
	},
	contactTypes: {
		data: [
			{
				id: 1,
				type: "E-mail"
			},
			{
				id: 2,
				type: "Messenger"
			},
			{
				id: 3,
				type: "Instagram"
			},
			{
				id: 4,
				type: "Facebook"
			},
			{
				id: 5,
				type: "Skype"
			}
		]
	}
};