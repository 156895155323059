import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Field, Form, FormikProvider, useFormik } from "formik";

import { handleSelectPlot } from "Actions/ActionsCreators";

import { validateArrayType } from "Utils/Arrays";
import { validateObjectType } from "Utils/Objects";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button/index";
import InputControlled from "Components/Form/Controlled/Input";
import InputCurrencyControlled from "Components/Form/Controlled/InputCurrencyControlled";
import SelectControlled from "Components/Form/Controlled/SelectControlled";

import FreeAttendancImg from "Assets/Images/orderFree.svg";

import { InstallmentsSelect } from "./InstallmentsSelect";
import {
  ContainerButton,
  ContainerPaymentForm,
  FreeAttendanceContainer,
  PaymentFormResizable,
  SelectedPaymentsContainer,
} from "./style";

const INITIAL_VALUES = {
  credit_nsu: "",
  credit_flag: "",
  credit_operator: "",
  credit_paid: "",
  pix_nsu: "",
  pix_paid: "",
};

export const PaymentFormsForm = ({
  budget_id,
  price,
  onSubmit,
  formRef,
  isLoading = false,
}) => {
  const dispatch = useDispatch();
  const [selectedPaymentForms, setSelectedPaymentForms] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState({
    number_installments: 1,
  });
  const totalPrice = selectedInstallment?.total || price;

  const paymentForms = [
    {
      name: "Débito",
      id: 2,
    },
    {
      name: "Crédito",
      id: 3,
    },
    {
      name: "Pix",
      id: 5,
    },
    {
      name: "Dinheiro",
      id: 1,
    },
  ];

  const creditFlags = [
    { id: 1, name: "Visa" },
    { id: 2, name: "Mastercard" },
    { id: 3, name: "American Express" },
    { id: 4, name: "HIPERCARD" },
    { id: 5, name: "DINNERS" },
    { id: 6, name: "HIPER" },
    { id: 7, name: "Cartão de Débito Autorizador TEM" },
    { id: 8, name: "MEU TEM" },
    { id: 9, name: "Sorocred" },
    { id: 10, name: "Sicredi" },
    { id: 11, name: "Cup" },
    { id: 12, name: "Cabal" },
    { id: 13, name: "Credz" },
    { id: 14, name: "Credsystem" },
    { id: 15, name: "Banescard" },
    { id: 16, name: "Mercado Pago" },
    { id: 17, name: "Elo" },
  ];

  const cardOperators = [
    { id: 1, name: "Cielo" },
    { id: 2, name: "Getnet" },
    { id: 3, name: "Mercado Pago" },
    { id: 4, name: "PagSeguro" },
    { id: 5, name: "Rede" },
    { id: 6, name: "Ticket" },
  ];

  function handleSavePlotSelected(plot) {
    setSelectedInstallment(plot);
    dispatch(handleSelectPlot(plot));
  }

  function validateCamp(value) {
    let error;
    if (!value) {
      error = "Obrigatório";
    }
    if (value === undefined) {
      error = "Obrigatório";
    }
    if (value === 0) {
      error = "Obrigatório";
    }

    return error;
  }

  function setPaidDetails(values) {
    if (validateObjectType(values)) {
      const paidValues = {
        credit_nsu: values.nsu_code || "",
        credit_flag: values.credit_flag || "",
        credit_operator: values.credit_operator || "",
        credit_paid: values.credit_paid || "",

        pix_nsu: values.nsu_code || "",
        pix_paid: values.pix_paid || "",

        value_paid: values.value_paid || "",
        nsu_code: values.nsu_code || "",
      };

      return paidValues;
    }

    throw "Verifique se todos os dados de pagamento foram preenchidos corretamente";
  }

  function checkPaidValueExceptCreditCard(paidValue, price, payment_type) {
    if (payment_type)
      if (paidValue > price) {
        createToast("error", "O valor pago foi maior que o valor do orçamento");
        return;
      }
    if (paidValue < price) {
      createToast("error", "O valor pago foi menor que o valor do orçamento");
      return;
    }
  }

  function checkPaidValueCreditCardOly(
    selectedInstallment,
    paidValue,
    price,
    payment_type
  ) {
    if (payment_type === 3) {
      if (!selectedInstallment) {
        createToast(
          "error",
          "Selecione o número de parcelas do pagamento no crédito"
        );
      }

      if (paidValue < price) {
        createToast("error", "O valor pago foi menor que o valor do orçamento");
        return;
      }
    }
  }

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validateOnBlur: false,
    validateOnChange: false,
    innerRef: formRef,
    onSubmit: (values, { validateForm, setSubmitting }) => {
      validateForm(values)
        .then(() => {
          const paidValues = setPaidDetails(values);
          const paidValue = paidValues.value_paid;
          const payment_type = selectedPaymentForms[0].id;

          if (validateArrayType(selectedPaymentForms) && totalPrice === 0) {
            const formsWithId = [
              {
                payment_type: 1,
                installments_number: 1,
                paid_price: paidValue,
              },
            ];
            const orderData = {
              budget_id,
              order_type: 5,
              payments: formsWithId,
            };
            return onSubmit(orderData);
          }

          if (payment_type !== 3)
            checkPaidValueExceptCreditCard(paidValue, price, payment_type);

          if (payment_type === 3)
            checkPaidValueCreditCardOly(
              selectedInstallment,
              paidValue,
              price,
              payment_type
            );

          const isNotCard = payment_type === 1 || payment_type === 5;

          const formsWithId = [
            {
              payment_type,
              installments_number: selectedInstallment.number_installments || 1,
              nsu_number: paidValues.nsu_code,
              credit_card_banner: isNotCard
                ? null
                : Number(paidValues.credit_flag),
              card_operator: isNotCard
                ? null
                : Number(paidValues.credit_operator),
              ...(selectedInstallment.addition && {
                addition: selectedInstallment.addition,
              }),
              paid_price: paidValue,
            },
          ];

          const orderData = {
            budget_id,
            order_type: 5,
            payments: formsWithId,
          };

          onSubmit(orderData);
        })
        .catch((error) => {
          setSubmitting(false);
        });
    },
  });

  const { values, isSubmitting, setSubmitting } = formik;

  useEffect(() => {
    if (!isLoading) {
      setSubmitting(false);
    }
  }, [isLoading]);

  return (
    <ContainerPaymentForm>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {selectedPaymentForms.length === 0
            ? paymentForms.map((form) => (
                <PaymentFormResizable>
                  <header
                    onClick={() => {
                      setSelectedPaymentForms([{ ...form }]);
                    }}
                  >
                    <span>{form.name}</span>
                  </header>
                </PaymentFormResizable>
              ))
            : selectedPaymentForms.map((form) => (
                <>
                  <SelectedPaymentsContainer>
                    <PaymentFormResizable open>
                      <header>
                        <span>{form.name}</span>
                        <button
                          onClick={() =>
                            setSelectedPaymentForms(
                              selectedPaymentForms.filter(
                                (pos) => pos.id !== form.id
                              )
                            )
                          }
                        >
                          REMOVER
                        </button>
                      </header>

                      <div>
                        <Field
                          name="nsu_code"
                          type="text"
                          label="NSU da transação"
                          placeholder="NSU da transação"
                          component={InputControlled}
                          className="input _opacity-gray"
                          validate={validateCamp}
                        />
                      </div>
                      {(form.id === 3 || form.id === 2) && (
                        <div>
                          <Field
                            name="credit_flag"
                            type="text"
                            label="Bandeira do cartão"
                            placeholder="Selecione uma bandeira"
                            options={creditFlags}
                            component={SelectControlled}
                            validate={validateCamp}
                          />
                          <Field
                            name="credit_operator"
                            type="text"
                            label="Operadora"
                            placeholder="Selecione uma operadora"
                            options={cardOperators}
                            component={SelectControlled}
                            validate={validateCamp}
                          />
                          <Field
                            name="value_paid"
                            type="text"
                            label="Valor pago"
                            placeholder="Valor pago"
                            value={(values.value_paid = totalPrice)}
                            component={InputCurrencyControlled}
                            validate={validateCamp}
                          />
                          {form.id === 3 && (
                            <>
                              <h6>Em quantas vezes?</h6>
                              <InstallmentsSelect
                                price={price}
                                selectedInstallment={selectedInstallment}
                                handleSavePlotSelected={handleSavePlotSelected}
                              />
                            </>
                          )}
                        </div>
                      )}
                      {form.id === 5 && (
                        <div>
                          <Field
                            name="value_paid"
                            type="text"
                            label="Valor pago"
                            value={(values.value_paid = price)}
                            placeholder="Valor pago"
                            component={InputCurrencyControlled}
                            validate={validateCamp}
                          />
                        </div>
                      )}
                      {form.id === 1 && (
                        <div>
                          <Field
                            name="value_paid"
                            type="text"
                            label="Valor pago"
                            placeholder="Valor pago"
                            value={(values.value_paid = price)}
                            component={InputCurrencyControlled}
                            validate={validateCamp}
                          />
                        </div>
                      )}
                    </PaymentFormResizable>
                  </SelectedPaymentsContainer>
                </>
              ))}

          {price == 0 && (
            <FreeAttendanceContainer>
              <h2>
                Este atendimento será gratuito. Para concluir o pedido clique em{" "}
                <strong>finalizar pedido.</strong>
              </h2>
              <img src={FreeAttendancImg} alt="teste" />
            </FreeAttendanceContainer>
          )}

          <ContainerButton>
            <Button
              type="submit"
              classButton="_action radiusButton"
              loading={isSubmitting}
              disabled={isSubmitting}
              padding="13px 36px"
            >
              FINALIZAR PEDIDO
            </Button>
          </ContainerButton>
        </form>
      </FormikProvider>
    </ContainerPaymentForm>
  );
};
