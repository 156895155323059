import API from "Services/Api";
import ApiAcuity from "Services/ApiAcuity";

export async function getClinicalScheduleTimesReturn(
  amplimedCode: string,
  week: string,
  day: string,
  procedure: number | string,
  return_released?: boolean
) {
  if (!amplimedCode && amplimedCode !== "tele-medicine") {
    return;
  }

  try {
    if (return_released) {
      procedure = procedure == 5 ? 6 : procedure == 9 ? 8 : procedure;
    }

    let path = `/appointments?procedure=${procedure}&channel_id=2&starts_at=${week}&ends_at=${day}`;

    if (!!amplimedCode && amplimedCode !== "tele-medicine") {
      path = `${path}&professional_code=${amplimedCode}`;
    }

    const response = await ApiAcuity.get(path);

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}

export async function ScheduleATime(data: any) {
  if (!data) {
    return;
  }

  try {
    const response = await API.post(`/amplimed`, data);

    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function CancelSchedule(appointment_id: string, motive: string) {
  if (!appointment_id || !motive) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await API.post(`/amplimed/cancel`, {
      idAgendamento: appointment_id,
      motivoCancelamento: motive,
    });

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}

export async function Reschedule(data: any, appointment: string) {
  if (!data) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await API.put(`/amplimed/${appointment}`, data);

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}

export async function saveAppointment(data: any) {
  if (!data) {
    throw new Error("Campos obrigatórios");
  }

  try {
    const response = await API.post(`/orders/appointment`, data);

    return response;
  } catch (error: any) {
    console.log(error);

    throw error;
  }
}
