const formatterPhone = (phone = "") => {
  if (!phone) return {};

  const dddNumber = phone?.substring(0, 3)?.replace("(", "");

  const phoneNumber = phone?.substring(4, phone.length)?.replaceAll(" ", "");
  const removeSeparetor = phoneNumber?.replaceAll("-", "");
  const phoneNumberFormatted = removeSeparetor;

  return {
    ddd: Number(dddNumber),
    phone: Number(phoneNumberFormatted),
  };
};

const formatterDataReceive = (data) => {
  const { first_name, telphone, email } = data;
  const { ddd, phone } = formatterPhone(telphone);

  return {
    name: first_name,
    ...(email ? { email } : {}),
    ...(phone ? { phone } : {}),
    ...(ddd ? { ddd } : {}),
  };
};

export const FormatPreRegister = (formData) => {
  const dataForm = formatterDataReceive(formData);
  return dataForm;
};
