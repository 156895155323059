import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Form, Field, FormikProvider, useFormik } from "formik";

import { createPerson } from "Requests/Person";

import { formikComboTypes } from "Utils/LocalTypes";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button/index";
import InputControlled from "Components/Form/Controlled/Input";
import SelectControlled from "Components/Form/Controlled/SelectControlled";
import { ModalInformation } from "Components/Modals/ModalInformation/index";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import TermsIcon from "Assets/Images/attention.png";


import { schema } from "./schema";
import {
  ContainerRegisterForm,
  ContainerButton,
  ContainerFields,
  TitleReview,
  SubTitleReview,
  Divisor,
} from "./style";

export const RegistrationReview = ({ leadId, isRegisteredProp }) => {
  const [registeredPerson, setRegisteredPerson] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [cpfInvalid, setCpfInvalid] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  // const birthDate = lead.birthdate.split("-").reverse().join("/");

  const MOCK = [
    {
      name: "Gabi Teste",
      email: "gabi.teste@teste.teste",
      phone: "11 9 85356753",
      gender: 1,
      birthday: "2000-02-14",
      type_document: "cpf",
      document: "47241739809",
      country: "",
    },
    {
      name: "Front Teste",
      email: "bruno.teste@teste.teste",
      phone: "11 9 85356752",
      gender: 1,
      birthday: "2000-02-25",
      type_document: "cpf",
      document: "47241739809",
      country: "",
    },
  ];

  const form = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    birthday: "",
    type_document: "",
    document: "",
    country: "",
    dependencies: MOCK,
  };

  const handleSubmit = () => {
    handleToggleModal();
  };

  const handleChangeDependent = (name, index, value) => {};

  const handleToggleModal = () => {
    setCpfInvalid(!cpfInvalid);
  };

  async function onSubmit(values) {
    const isCPF = values?.type_document === "cpf";

    const personObject = {
      lead_id: leadId,
      first_name: values.name,
      cpf: isCPF ? values.document.replaceAll(".", "") : null,
      passport_issuing_country: !isCPF ? values.country : 0,
      passport: !isCPF ? values.document : null,
      gender: values.gender,
      birthdate: values.birthday.replaceAll("/-", "-"),
      contacts: [
        {
          contact_type_id: "1",
          contact: values.email,
          default: true,
        },
      ],
      phones: [
        {
          id: "",
          default: true,
          phone_type_id: "2",
          ddi: "55",
          ddd: values.phone.substring(1, 3),
          phone: values.phone.substring(5).replace("-", ""),
          branch: "",
          obs: "celular",
        },
      ],
    };

    try {
      setIsLoading(true);
      const person = await createPerson(personObject);
      setIsLoading(false);

      if (person.errors?.cpf[0]) {
        createToast("error", "  CPF já cadastrado");
        return;
      }
      setIsRegistered(true);
      isRegisteredProp();
      setTermsModalVisible(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      if (error?.errors?.cpf[0]) {
        setCpfInvalid(true);
      }
    }
  }

  const formik = useFormik({
    initialValues: form,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { validateForm }) => {
      validateForm(values).then(() => {
        onSubmit(values);
      });
    },
  });

  const { values } = formik;

  return (
    <>
      <PageWrapper className="entry-app-content">
        <div className="content-holder">
          <div className="page-heading">
            <div className="wrapper-header-content">
              <PageHeader title="Validação das informações">
                <Breadcrumb
                  list={[
                    {
                      path: "/orcamentos",
                      label: "Orçamentos",
                      current: false,
                    },
                    {
                      path: "#",
                      label: "Validação das informações",
                      current: true,
                    },
                  ]}
                />
              </PageHeader>
            </div>

            <FormikProvider value={formik}>
              <ContainerRegisterForm>
                <Form>
                  <TitleReview>Informações do cliente</TitleReview>
                  <ContainerFields />

                  <div className="row">
                    <div className=" md-5">
                      <SubTitleReview>DADOS DE IDENTIDADE</SubTitleReview>

                      <Field
                        name="name"
                        type="text"
                        label="Nome Completo"
                        placeholder="Nome e Sobrenome"
                        component={InputControlled}
                        className="input _opacity-gray"
                      />
                      <Divisor />

                      <Field
                        placeholder="DD/MM/AAAA"
                        name="birthday"
                        mask="99/99/9999"
                        type="tel"
                        label="Data de Nascimento"
                        component={InputControlled}
                        className="input _opacity-gray"
                      />
                      <Divisor />

                      <Field
                        placeholder="Selecione o gênero"
                        name="gender"
                        label="Gênero:"
                        options={formikComboTypes.genders.data}
                        component={SelectControlled}
                      />
                      <Divisor />

                      <article>
                        <Field
                          label="documento"
                          name="type_document"
                          placeholder="Selecione o tipo do documento"
                          options={[
                            { name: "CPF", id: "cpf" },
                            { name: "Passaporte", id: "passport" },
                          ]}
                          component={SelectControlled}
                        />
                        <Divisor />

                        {values?.type_document === "cpf" && (
                          <Field
                            placeholder="Insira o número do documento"
                            mask={
                              values?.type_document === "cpf"
                                ? "999.999.999-99"
                                : ""
                            }
                            name="document"
                            type="text"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                        )}
                      </article>
                      <Divisor />

                      {values?.type_document === "passport" && (
                        <Field
                          label="País emissor"
                          placeholder="No caso de passaporte, selecione o país"
                          name="country"
                          options={formikComboTypes.countrys.data}
                          component={SelectControlled}
                        />
                      )}
                      <Divisor />
                    </div>
                    <div className=" md-2" />

                    <div className=" md-5">
                      <SubTitleReview>DADOS DE IDENTIDADE</SubTitleReview>

                      <Field
                        placeholder="email@gmail.com"
                        name="email"
                        type="email"
                        label="Email"
                        component={InputControlled}
                        className="input _opacity-gray"
                      />
                      <Divisor />

                      <Field
                        placeholder="(99) 99999-9999"
                        name="phone"
                        mask="(99) 99999-9999"
                        type="tel"
                        label="Telefone"
                        component={InputControlled}
                        className="input _opacity-gray"
                      />
                      <Divisor />
                    </div>
                  </div>

                  <Divisor />

                  {values.dependencies.map((item, index) => (
                    <div key={index}>
                      <TitleReview>
                        Informações do dependente {index + 1}
                      </TitleReview>
                      <ContainerFields />

                      <div className="row">
                        <div className=" md-5">
                          <SubTitleReview>DADOS DE IDENTIDADE</SubTitleReview>

                          <Field
                            type="text"
                            name={`dependencies[${index}].name`}
                            label="Nome Completo"
                            value="teste"
                            placeholder="Nome e Sobrenome"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                          <Divisor />

                          <Field
                            placeholder="DD/MM/AAAA"
                            name={`dependencies[${index}].birthday`}
                            mask="99/99/9999"
                            type="tel"
                            label="Data de Nascimento"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                          <Divisor />

                          <Field
                            placeholder="Selecione o gênero"
                            name={`dependencies[${index}].gender`}
                            label="Gênero:"
                            options={formikComboTypes.genders.data}
                            component={SelectControlled}
                          />
                          <Divisor />

                          <article>
                            <Field
                              label="documento"
                              name={`dependencies[${index}].type_document`}
                              placeholder="Selecione o tipo do documento"
                              options={[
                                { name: "CPF", id: "cpf" },
                                { name: "Passaporte", id: "passport" },
                              ]}
                              component={SelectControlled}
                            />
                            <Divisor />

                            {item?.type_document === "cpf" && (
                              <Field
                                placeholder="Insira o número do documento"
                                mask={
                                  item?.type_document === "cpf"
                                    ? "999.999.999-99"
                                    : ""
                                }
                                name={`dependencies[${index}].document`}
                                type="text"
                                onChange={({ target: { value } }) =>
                                  handleChangeDependent(
                                    "document",
                                    index,
                                    value
                                  )
                                }
                                component={InputControlled}
                                className="input _opacity-gray"
                              />
                            )}
                          </article>
                          <Divisor />

                          {item?.type_document === "passport" && (
                            <Field
                              label="País emissor"
                              placeholder="No caso de passaporte, selecione o país"
                              name={`country${index}`}
                              options={formikComboTypes.countrys.data}
                              onChange={({ target: { value } }) =>
                                handleChangeDependent("country", index, value)
                              }
                              component={SelectControlled}
                            />
                          )}
                          <Divisor />
                        </div>
                        <div className=" md-2" />

                        <div className=" md-5">
                          <SubTitleReview>DADOS DE IDENTIDADE</SubTitleReview>

                          <Field
                            placeholder="email@gmail.com"
                            name={`dependencies[${index}].email`}
                            type="email"
                            label="Email"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                          <Divisor />

                          <Field
                            placeholder="(99) 99999-9999"
                            name={`dependencies[${index}].phone`}
                            mask="(99) 99999-9999"
                            type="tel"
                            label="Telefone"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                          <Divisor />
                        </div>
                      </div>

                      <Divisor />
                    </div>
                  ))}

                  <ContainerButton>
                    <Button
                      loading={isLoading}
                      type="submit"
                      classButton="_action radiusButton"
                    >
                      salvar alterações
                    </Button>
                  </ContainerButton>
                </Form>
              </ContainerRegisterForm>
            </FormikProvider>
          </div>
        </div>
      </PageWrapper>

      <ModalInformation
        modalVisible={cpfInvalid}
        modalDismiss={handleSubmit}
        title="Informações atualizadas"
        icon={TermsIcon}
        messageButton="CONTINUAR ATENDIMENTO"
        onClick={() => {
          history.push(`/ficha-de-atendimento/paciente`);
        }}
      >
        <p>
          O cadastro foi ajustado e as informações foram atualizadas com
          sucesso. Agora você pode prosseguir com o atendimento normalmente!
        </p>
      </ModalInformation>
    </>
  );
};
