import React from "react";

import { Box } from "styles/globalComponents";

import { useGetBudgets } from "Hooks/useGetBudgets";

import { LoadingText } from "Components/LoadingText";
import PageHeader from "Components/Pages/Header";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { Filter } from "./components/Filters";
import { ParamsFilter } from "./components/Filters/interface";
import { ListBudgets } from "./components/ListBudgets";
import { PageContainer, PageContent } from "./styles";

export const PrivateBudgets = () => {
  const {
    data,
    setBudgetsFilters,
    isLoading: loading,
    isError,
    pagination,
  } = useGetBudgets();

  function handlePagination(e: { currentPage: number }) {
    if (pagination?.current_page === e.currentPage) return;
    setBudgetsFilters({ page: e.currentPage });
  }

  const searchBudgets = (filters: ParamsFilter) => {
    setBudgetsFilters(filters);
  };

  if (isError) {
    return (
      <PageContainer className="entry-app-content ">
        <PageContent className="content-holder ">
          <Box mt="100">
            <h5 className="text-center text-purple">
              Não foi possível obter a listagem de orçamentos particulares !
              Atualize a página.
            </h5>
          </Box>
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer className="entry-app-content ">
      <PageContent className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Orçamentos Particulares">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Orçamentos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Particular",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
        </div>

        <div className="page-content">
          <Filter searchBudgets={searchBudgets} />

          {loading ? (
            <Box mt="100">
              <LoadingText text="Carregando lista de orçamentos particulares. Aguarde !" />
            </Box>
          ) : null}

          {data?.data.length && !loading ? (
            <>
              <ListBudgets data={data.data} />

              <Pagination
                key={pagination?.current_page}
                totalRecords={pagination?.total_pages}
                currentPageSelected={pagination?.current_page}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={(e) => handlePagination(e)}
              />
            </>
          ) : null}

          {!data?.data.length && !loading ? (
            <Box mt="60">
              <h3 className="text-center text-purple">
                Nenhum orçamento encontrado
              </h3>
            </Box>
          ) : null}
        </div>
      </PageContent>
    </PageContainer>
  );
};
