import * as Yup from "yup";

import { isCPF, uniqueDocument } from "Services/schema/validations";

export const schema = Yup.object().shape({
  label_id: Yup.number().required("Obrigatório"),
  cpf: Yup.string().when("type_document", {
    is: "cpf",
    then: Yup.string()
      .required("Obrigatório")
      .test("validateCpf", "CPF inválido", isCPF)
      .test("uniqueDocument", "CPF já foi cadastrado", function () {
        const { id, type_document, cpf } = this.parent;
        return uniqueDocument({ id, type: type_document, document: cpf });
      }),
  }),
  passport: Yup.string().when("type_document", {
    is: "passport",
    then: Yup.string()
      .required("Obrigatório")
      .test("uniqueDocument", "Passaporte já foi cadastrado", function () {
        const { id, type_document, passport } = this.parent;
        return uniqueDocument({ id, type: type_document, document: passport });
      }),
  }),
  type_document: Yup.string().required("Obrigatório"),
  passport_issuing_country: Yup.string()
    .nullable(true)
    .when("type_document", {
      is: "passport",
      then: Yup.string().required("Obrigatório"),
    }),
});

export const schema_without_document = Yup.object().shape({
  label_id: Yup.number().required("Obrigatório"),
});
