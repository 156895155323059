import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  select {
    color: #b1b1b1;
  }

  .row {
    width: 100%;
    margin: 0;
  }

  input::placeholder,
  input {
    color: #b1b1b1;
  }

  .filter-text {
    color: #b1b1b1;
    width: max-content;
    font-family: var(--font-display);
  }

  .buttons-container {
    width: max-content;
  }

  .order-by {
    padding: 0 8px;
    width: max-content;
  }
`;
