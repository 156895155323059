import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  select {
    color: #b1b1b1;
  }

  .row {
    width: 100%;
    margin: 0;
  }

  input::placeholder,
  input {
    color: #b1b1b1;
  }
`;
