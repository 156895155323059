import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  align-items: center;

  padding: 12px 24px;
  border: 2px solid #f0f0f0;
  border-bottom: none;

  img {
    object-fit: contain;
  }

  p {
    margin: 0;
    flex: 1;
  }

  &:nth-child(odd),
  &:nth-child(even) {
    border-bottom: 2px solid #f0f0f0;
    border-top: none;
  }

  &:first-child {
    border-radius: 10px 10px 0px 0px;
    border-top: 2px solid #f0f0f0;
  }

  &:last-child {
    border-radius: 0px 0px 10px 10px;
  }

  &:only-child {
    border-radius: 10px 10px 10px 10px;
    border: 2px solid #f0f0f0;
  }
`;
