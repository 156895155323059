import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { Element } from "react-scroll";

import { Formik, Form } from "formik";
import { Header } from "Pages/HomeOrders/styles";
import { FormDocument, DataPerson } from "Pages/PersonDuplicated/interface";
import {
  ActionButton,
  TitleSectionReview,
  LeftPaddingContainer,
  Footer,
} from "Pages/PersonDuplicated/styles";
import { ReviewForm } from "Pages/RegistrationCleaning/components/Review/ReviewForm";
import { schema } from "Pages/RegistrationCleaning/components/Review/schema";



import { duplicateRegistration } from "Requests/Person";

import { resetPersonDuplicatedFlow } from "Actions/DuplicatePerson";

import { Store } from "Reducers/interface";

import { MaskCPF } from "Utils/Masks";
import { createToast } from "Utils/toastFunc";

import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

export const Preview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formikRef = useRef<any>(null);

  const { PersonDuplicatedFlow } = useSelector((store: Store) => store);

  const [formDocuments, setFormDocuments] = useState<FormDocument>({
    duplicatedRegisters: [],
  });

  useEffect(() => {
    fetchData();
  }, [PersonDuplicatedFlow]);

  async function fetchData(page?: any) {
    try {
      const { document } = PersonDuplicatedFlow;

      const response = await duplicateRegistration(document, page, {
        no_pagination: true,
      });
      const duplicated: DataPerson[] = response.data;

      const formatedDuplicated = duplicated.map((item) => {
        return {
          ...item,
          email: item?.default_email?.email ?? "",
          phone: `${item?.default_phone?.ddi}${item?.default_phone?.ddd}${item.default_phone.phone}` ?? "",
          type_document: "cpf",
          cpf: document,
        };
      });

      setFormDocuments({
        duplicatedRegisters: formatedDuplicated,
      });
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao solicitar as pessoas duplicadas!"
      );
    }
  }

  const handleBack = () => {
    dispatch(resetPersonDuplicatedFlow());
  };

  const handleAdjustment = () => {
    history.push(`/limpeza-cadastro/${PersonDuplicatedFlow.document}/merge`);
  };

  return (
    <PageWrapper className="entry-app-content order-home-list">
      <div className="content-holder">
        <Header>
          <h1>
            Detalhes de Cadastros Duplicados no CPF{" "}
            {PersonDuplicatedFlow.document &&
              MaskCPF(PersonDuplicatedFlow.document)}
          </h1>
          <Breadcrumb
            list={[
              {
                path: "/orcamentos",
                label: "ORÇAMENTOS",
                current: false,
              },
              {
                path: "#",
                label: "Validação das informações",
                current: true,
              },
            ]}
          />
        </Header>

        <LeftPaddingContainer>
          <div className="page-content page-content-list" id="ScrollDomiciliar">
            <Element name="ScrollableDomiciliarOrders" />

            <Formik
              initialValues={formDocuments}
              validationSchema={schema}
              validateOnBlur
              enableReinitialize
              validateOnChange={false}
              onSubmit={() => {}}
              innerRef={formikRef}
            >
              {({ values }) => {
                return (
                  <Form aria-readonly>
                    {values?.duplicatedRegisters?.map(
                      (duplicatedRegister, key) => (
                        <div
                          className="duplicated-register-list"
                          key={duplicatedRegister.id}
                        >
                          <TitleSectionReview>
                            <span>Cadastro {key + 1}</span>
                            <NavLink
                              target="_blank"
                              to={`/ficha-cliente/${duplicatedRegister.id}`}
                            >
                              VER FICHA COMPLETA
                            </NavLink>
                          </TitleSectionReview>
                          <div className="content">
                            <ReviewForm
                              readOnly
                              type_document={duplicatedRegister.type_document}
                              prefix={`duplicatedRegisters[${key}]`}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </LeftPaddingContainer>
      </div>

      <Footer>
        <ActionButton onClick={handleBack}>Voltar</ActionButton>
        <button className="button" onClick={handleAdjustment}>
          AJUSTAR CADASTRO
        </button>
      </Footer>
    </PageWrapper>
  );
};
