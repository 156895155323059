import styled from "styled-components";

export const PatientItem = styled.li`
  padding: 24px 20px;

  &:not(:last-child) {
    border-bottom: 1px dashed rgba(153, 153, 153, 0.5);
  }

  strong {
    display: block;
    font-family: var(--font-display);
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    margin-bottom: 16px;
  }
`;

export const ButtonSelectProduct = styled.button`
  color: var(--color-purple);
  display: flex;
  font-family: var(--font-text);
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  align-items: flex-start;
  text-align: left;

  svg {
    margin-right: 7px;
  }
  background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
`;
