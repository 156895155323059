import {
  LOGIN_SUCCESS,
  LOGOUT,
  MENU_TOGGLE,
  SELECT_PACIENT,
  SELECT_ORDER,
  SELECT_PLOTS,
  REMOVE_PLOTS,
  USER_DUPLICATE,
  PERSON_DUPLICATED_FLOW,
  PERSON_DUPLICATED_FLOW_RESET,
  BUDGET,
  RESET_BUDGET,
} from "./ActionsTypes";

export const handleSuccessLogin = (data) => {
  return {
    type: LOGIN_SUCCESS,
    user: data.user,
    permissions: data.permissions,
  };
};

export const handleLogout = (_) => {
  return {
    type: LOGOUT,
  };
};

export const handleMenu = (_) => {
  return {
    type: MENU_TOGGLE,
  };
};

export const handleSelectPacient = (pacient) => {
  return {
    type: SELECT_PACIENT,
    payload: pacient,
  };
};

export const handleSelectOrder = (order) => {
  return {
    type: SELECT_ORDER,
    payload: order,
  };
};

export const handleSelectPlot = (plot) => {
  return {
    type: SELECT_PLOTS,
    payload: plot,
  };
};

export const handleClearPlots = () => {
  return {
    type: REMOVE_PLOTS,
    payload: {},
  };
};

export const handleUserDuplicate = (data) => {
  return {
    type: USER_DUPLICATE,
    payload: data,
  };
};

export const handlePersonDuplicatedFlow = (data) => {
  return {
    type: PERSON_DUPLICATED_FLOW,
    payload: data,
  };
};

export const handlePersonDuplicatedFlowReset = () => {
  return {
    type: PERSON_DUPLICATED_FLOW_RESET,
  };
};

export const handleSetBudgetInfo = (data) => {
  return {
    type: BUDGET,
    payload: data,
  };
};

export const handleResetBudget = () => {
  return {
    type: RESET_BUDGET,
  };
};
