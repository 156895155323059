import React, { ChangeEvent, useEffect, useState } from "react";

import { Field, FieldArray, useFormikContext } from "formik";
import { Box } from "styles/globalComponents";

import { useProductCombos } from "Hooks/useProductCombos";

import { hasPathInArray } from "Utils/Arrays";

import { comboPreCollections } from "mocks";

import { AccordionCollapse } from "Components/AccordionCollapse";
import { CheckboxList } from "Components/CheckboxList";
import { CustomCheckbox } from "Components/Form/CustomCheckbox";
import Input from "Components/Form/Input";
import Select from "Components/Form/Select";

import { FormValuesProps, HandleChangeFields } from "../../interface";
import { Container, AddButton, DelButton, ContainerFloatLabel } from "./styles";

export const PrepareForm = ({
  handleCheckbox,
  setSelectField,
  handleCheckboxCollection,
}: HandleChangeFields) => {
  const { values, errors, handleChange, touched, handleBlur } =
    useFormikContext<FormValuesProps>();
  const { category_id } = values;
  const categoryId = String(category_id.id);

  const { combos } = useProductCombos({ includeCombo: ["*"] });

  const adjustedWidthInputs = hasPathInArray([1, 2, 3, 7, 8], categoryId)
    ? "w-100"
    : "w-50";

  const [listComboFast, setListComboFast] = useState<any>([]);
  const [visibleButtonAdd, setVisibleButtonAdd] = useState(true);

  const handleChangeSelectFast = (
    e: ChangeEvent<HTMLInputElement>,
    setSelectField: any
  ) => {
    if (e) setSelectField(e);
    else setSelectField(null);

    const selectedValue = e.target.value;

    setVisibleButtonAdd(selectedValue !== "1");
  };

  const handleComboFast = (comboFast: any) => {
    setListComboFast(comboFast || []);

    const visible = values?.fastItems.some((item) => item.fast_id === 1);
    setVisibleButtonAdd(!visible);
  };

  useEffect(() => {
    handleComboFast(combos?.fasts || []);
  }, [combos]);

  return (
    <AccordionCollapse title="Preparo">
      <Container>
        <div className="flex fasting-info">
          {hasPathInArray([1, 2, 3, 7, 8], categoryId) && (
            <CheckboxList
              error={touched.diets && errors.diets ? errors.diets : ""}
              title="Dieta especial*"
            >
              <Box mt="15">
                {combos?.diets?.map((diet) => (
                  <Box key={diet.id} mb="8">
                    <Field
                      name="diets"
                      type="checkbox"
                      component={CustomCheckbox}
                      label={diet.diet}
                      value={String(diet.id)}
                      onChange={handleCheckbox}
                      checked={values.diets?.includes(diet.id)}
                    />
                    {(diet.id === 4 || diet.id === 6) &&
                      values.diets?.find((item) => item === diet.id) && (
                        <Field
                          name={
                            diet.id === 4
                              ? "diet_avoid_preservatives_obs"
                              : "diet_avoid_foods_obs"
                          }
                          id={
                            diet.id === 4
                              ? "diet_avoid_preservatives_obs"
                              : "diet_avoid_foods_obs"
                          }
                          value={
                            diet.id === 4
                              ? values.diet_avoid_preservatives_obs
                              : values.diet_avoid_foods_obs
                          }
                          label=""
                          component={Input}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          placeholder={
                            diet.id === 4
                              ? "Digite aqui os conservantes"
                              : "Digite aqui os alimentos específicos"
                          }
                          error={
                            diet.id === 4
                              ? errors?.diet_avoid_preservatives_obs
                              : errors?.diet_avoid_foods_obs
                          }
                        />
                      )}
                  </Box>
                ))}
              </Box>
            </CheckboxList>
          )}

          <div className="medication">
            <label className="text-gray text-semibold">Jejum</label>
            {hasPathInArray([1, 2, 3, 7, 8], categoryId) && (
              <div className="input-container w-100">
                <FieldArray
                  name="fastItems"
                  render={({ remove, insert }) => (
                    <div>
                      {values?.fastItems?.map((item, index) => (
                        <div key={index}>
                          <div
                            className={`${adjustedWidthInputs} flex gap-20 align-items-end mb-1`}
                          >
                            <Field
                              name={`fastItems[${index}].fast_id`}
                              id={`fastItems[${index}].fast_id`}
                              options={listComboFast}
                              onChange={(e: any) =>
                                handleChangeSelectFast(e, setSelectField)
                              }
                              onBlur={handleBlur}
                              optionName="fast"
                              value={values.fastItems[index].fast_id}
                              component={Select}
                              placeholder="Selecione o tipo de jejum"
                            />
                            <Field
                              name={`fastItems[${index}].fast_hours_min`}
                              id={`fastItems[${index}].fast_hours_min`}
                              label=""
                              type="number"
                              component={Input}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.fastItems[index].fast_hours_min}
                              placeholder="Digite o tempo mínimo"
                            />
                            <Field
                              name={`fastItems[${index}].fast_hours_max`}
                              id={`fastItems[${index}].fast_hours_max`}
                              label=""
                              type="number"
                              component={Input}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.fastItems[index].fast_hours_max}
                              placeholder="Digite o tempo máximo"
                            />

                            {values?.fastItems?.length > 1 && (
                              <DelButton
                                type="button"
                                onClick={() => remove(index)}
                              >
                                X
                              </DelButton>
                            )}
                          </div>

                          {index === values.fastItems.length - 1 &&
                            visibleButtonAdd &&
                            values.fastItems.length <= 1 && (
                              <AddButton
                                type="button"
                                onClick={() =>
                                  insert(index + 1, {
                                    fast_id: "",
                                    fast_hours_min: null,
                                    fast_hours_max: null,
                                  })
                                }
                              >
                                <span className="represent-icon-holder">+</span>{" "}
                                Nova regra
                              </AddButton>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
            )}

            {hasPathInArray([1, 2, 3, 4, 7, 8], categoryId) && (
              <Box className=" w-100 ">
                <span className="text-gray label">
                  Interromper medicamento*
                </span>
                <div className="w-100 gap-20">
                  <div className="input-container w-10">
                    <Field
                      name="stop_medication_id"
                      id="stop_medication_id"
                      value={values.stop_medication_id}
                      onChange={setSelectField}
                      options={combos?.stop_medications}
                      onBlur={handleBlur}
                      optionName="medicament"
                      label=""
                      component={Select}
                      placeholder="Selecione o tipo de medicamento"
                      error={
                        touched.stop_medication_id &&
                        errors.stop_medication_id &&
                        errors.stop_medication_id
                      }
                    />
                  </div>

                  <div className="input-container w-100">
                    <Field
                      name="stop_medication_obs"
                      id="stop_medication_obs"
                      onChange={handleChange}
                      value={values.stop_medication_obs}
                      label=""
                      multiline
                      component={Input}
                      onBlur={handleBlur}
                      placeholder="Digite aqui os medicamentos"
                      error={
                        touched.stop_medication_obs &&
                        errors.stop_medication_obs &&
                        errors.stop_medication_obs
                      }
                    />
                  </div>
                </div>
              </Box>
            )}

            {hasPathInArray([1, 2, 3, 5, 7, 8], categoryId) && (
              <CheckboxList error="" title="Pré-Coleta*">
                <Box mt="15">
                  <FieldArray
                    name="product_pre_collection"
                    render={() => (
                      <div>
                        {comboPreCollections?.map((collection, index) => (
                          <Box key={collection.id} mb="8">
                            <div style={{ position: "relative" }}>
                              <Field
                                id={`product_pre_collection[${index}].collection_id`}
                                name={`product_pre_collection[${index}].collection_id`}
                                type="checkbox"
                                component={CustomCheckbox}
                                label={collection.collection}
                                onChange={handleCheckboxCollection}
                                value={String(collection.id)}
                                checked={
                                  // @ts-ignore
                                  values.product_pre_collection[index]
                                    ?.collection_id
                                }
                              />
                            </div>
                            {collection.id === 2 && (
                              <ContainerFloatLabel>
                                <Field
                                  name={`product_pre_collection[${index}].pre_collection_hours`}
                                  id={`product_pre_collection[${index}].pre_collection_hours`}
                                  label=""
                                  value={
                                    // @ts-ignore
                                    values.product_pre_collection[index]
                                      ?.pre_collection_hours
                                  }
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  component={Input}
                                  error={
                                    errors?.product_pre_collection &&
                                    errors?.product_pre_collection[index]
                                    // @ts-ignore
                                      ?.pre_collection_hours
                                  }
                                />
                              </ContainerFloatLabel>
                            )}
                          </Box>
                        ))}
                      </div>
                    )}
                  />
                </Box>
              </CheckboxList>
            )}
          </div>
        </div>

        <div className="input-container w-100">
          <Field
            name="product_description.preparation_method"
            id="product_description.preparation_method"
            value={values.product_description.preparation_method}
            label="Informações adicionais de preparo"
            component={Input}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            placeholder="Descreva aqui"
          />
        </div>
      </Container>
    </AccordionCollapse>
  );
};
