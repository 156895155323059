import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getPersonByID } from "Requests/Person";

import { Store } from "Reducers/interface";

import validatePermissions from "Utils/validatePermissions";

import Tabs from "Components/Nav/Tabs";
import Tab from "Components/Nav/Tabs/Tab";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { ParamsRouter, Person } from "./interface";
import { BudgetTab } from "./TokenTabs/BudgetTab";
import DashboardTab from "./TokenTabs/Dashboard";
import OwnOrdersTab from "./TokenTabs/OwnOrders";
import PersonalDataTab from "./TokenTabs/PersonalData";
import ResultsTab from "./TokenTabs/Results";

const PersonForm = () => {
  const params = useParams<ParamsRouter>();
  const history = useHistory();

  const user_permissions = useSelector(
    (state: Store) => state.User.permissions
  );

  const [loading, setLoading] = useState(true);
  const [person, setPerson] = useState<Person>();
  const [tabs, setTabs] = useState([
    {
      key: "tab1",
      name: "Dashboard",
      permissions: "Consult Persons",
      component: DashboardTab,
    },
    {
      key: "tab2",
      name: "Dados pessoais",
      permissions: "Consult Persons",
      component: PersonalDataTab,
    },
    {
      key: "tab3",
      name: "Orçamentos",
      permissions: "Consult Orders",
      component: BudgetTab,
    },
    {
      key: "tab4",
      name: "Pedidos",
      permissions: "Consult Orders",
      component: OwnOrdersTab,
    },
    {
      key: "tab5",
      name: "Laudos",
      permissions: "Consult Patient Reports Status",
      component: ResultsTab,
    },
  ]);

  useEffect(() => {
    if (Number(params.id) > 0 && params.id !== null) {
      fetchPersonById(params.id);
    }
  }, [params.id]);

  async function fetchPersonById(id: string) {
    setLoading(true);

    try {
      const data = await getPersonByID(id);
      setPerson(data);
    } catch (error) {
      // @ts-ignore
      const { errors } = error.response.data;

      errors.forEach((error: Error) => {
        if (
          error?.message === "person_id: Person not found" ||
          error?.message === "Internal server error"
        ) {
          toast.error("Oops! Não foi possível encontrar este usuário.");
          history.replace("/pessoas");
        }
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder content-holder-no-filter remove-padding">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Ficha do Cliente">
              <Breadcrumb
                list={[
                  {
                    path: "/pessoas",
                    label: "Pessoas",
                    current: false,
                  },
                  {
                    path: "/pessoas",
                    label: "Todas as pessoas",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Ficha do cliente",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
            <div className="actions-holder">
              <button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                className="link link-danger"
              >
                Voltar
              </button>
              {validatePermissions("Update Persons", user_permissions) && (
                // @ts-ignore
                <Link to={`/pessoa/${params.id}`} className="button _action">
                  Editar
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="xs-12">
              {loading ? (
                <span className="loading-persons">
                  <img src={LoadingImage} alt="" />
                </span>
              ) : (
                <Tabs active={0}>
                  {tabs.map((item) => {
                    if (validatePermissions(item.permissions, user_permissions))
                      return (
                        // @ts-ignore
                        <Tab key={item.key} label={item.name}>
                          <item.component person={person as Person} />
                        </Tab>
                      );
                  })}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PersonForm;
