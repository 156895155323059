
import { useBudgetOrder } from "Stores/budget-order";
import { Box } from "styles/globalComponents";
import { useShallow } from "zustand/react/shallow";

import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";

import Warning from "Assets/Images/atention.svg";

interface IncompleteRegisterModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const IncompleteRegisterModal = ({
  isOpen,
  closeModal,
}: IncompleteRegisterModalProps) => {

  
  const [setCurrentModal] = useBudgetOrder(useShallow((state) => [
    state.setCurrentModal
  ]));


  const closeModalAndSetStore = () => {
    setCurrentModal("")
    closeModal()
  }

  const showRegisterModal = () => {
    setCurrentModal("completeRegister")
    closeModal()
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Warning}
      renderHeading={() => (
        <h2>
          {" "}
          Cadastro incompleto
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          Para prosseguir com a conversão deste orçamento em pedido é necessário completar esse cadastro.
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">

          <Button width="full" onClick={() => showRegisterModal()} minHeight="3.7em" fontWeight="600">
            Incluir Dados
          </Button>

          <Button width="full" outlined onClick={() => closeModalAndSetStore()} minHeight="3.7em" fontWeight="600">
            Cancelar
          </Button>
        </Box>
      )}
    />
  )

}