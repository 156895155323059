import FormatCurrency from "Utils/FormatCurrency";

import { FormatterBudgetParams } from "./interface";

export const FormatterData = (apiData: any) => {
  return apiData.map((itemData: any) => ({
    ...itemData,
    subtotalDisplay: FormatCurrency(itemData?.subtotal),
    totalDisplay: itemData?.total?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    discountsDisplay: FormatCurrency(itemData?.discounts),
    dateDisplay: itemData?.date,
  }));
};

export const FormatterBudget = (apiData: any) => {
  let _formatData = {
    ...apiData,
  };

  Object.keys(apiData).forEach((key) => {
    if (key === "cart") {
      const cartFormatted = apiData[key].map((itemCart: any) => ({
        ...itemCart,
        totalDisplay: FormatCurrency(itemCart.total_item_value),
        discountsDisplay: FormatCurrency(itemCart.total_discount_value),
      }));

      _formatData = {
        ..._formatData,
        [key]: cartFormatted,
      };
    } else if (key === "home_collect_value") {
      _formatData = {
        ..._formatData,
        [key]: parseFloat(apiData[key]),
      };
    }
  });

  _formatData = {
    ...apiData,
    ..._formatData,
    homeCollectValueDisplay: FormatCurrency(apiData.home_collect_value),
    subtotalDisplay: FormatCurrency(apiData.subtotal),
    totalDisplay: FormatCurrency(apiData.total),
    discountsDisplay: FormatCurrency(apiData.discounts),
    dateDisplay: apiData.date,
  };

  return _formatData;
};

export const FormatterToUpdateBudget = ({
  patient,
  person_type,
  product,
  budget = null,
  symptoms = "",
  voucher = "",
  credential_id,
  last_menstruation_period = "",
  zip_code,
}: FormatterBudgetParams) => {
  const isPersonBudget = person_type === "person";

  const leadInfo = {
    leadInfo: {
      lead_id: patient.id,
    },
  };

  const personInfo = {
    personInfo: {
      patient_id: patient.id,
      contact_id: patient.emailId,
      phone_id: patient.phoneId,
    },
  };

  const buyerInfo = isPersonBudget ? personInfo.personInfo : leadInfo.leadInfo;

  return {
    budget_id: budget?.id ?? false,
    coupon_code: budget?.coupon_code ?? "",
    ...(credential_id && { credential_id }),
    ...(zip_code && { zip_code }),
    symptoms,
    last_menstruation_period,
    ...buyerInfo,
    item: {
      product_id: product.product_id ? product.product_id : product.id,
      voucher,
      partner_id: product.partner_id || 1,
      amount: product.amount ?? 1,
    },
  };
};
