import React from "react";

const Tab = ({ className, label, isActive, onClick }) => {
  const tabClass = `tabs-item ${className}`;
  const linkClass = isActive ? "tabs-item-link-active" : "tabs-item-link";

  return (
    <li className={tabClass}>
      <a className={linkClass} onClick={onClick}>
        {label}
      </a>
    </li>
  );
};

export default Tab;
