import {
  handleSelectPacient,
  handleSelectOrder,
  handleSetBudgetInfo,
  handleResetBudget,
} from "./ActionsCreators";

export const selectPacient = (data) => {
  return (dispatch) => {
    dispatch(handleSelectPacient(data));
  };
};

export const selectOrder = (data) => {
  return (dispatch) => {
    dispatch(handleSelectOrder(data));
  };
};

export const setBudgetInfo = (data) => {
  return (dispatch) => {
    dispatch(handleSetBudgetInfo(data));
  };
};

export const resetBudget = () => {
  return (dispatch) => {
    dispatch(handleResetBudget());
  };
};
