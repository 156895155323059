import { Document } from "Dtos/IBudget";
import { Box } from "styles/globalComponents";

import { useBudgetCartModals } from "Hooks/useBudgetCartModals";

import { formatDateBR } from "Utils/Date";
import { formatBRLCurrency } from "Utils/FormatCurrency";
import { MaskCPF } from "Utils/Masks";

import { Button } from "Components/Button";
import { BudgetActionsModals } from "Components/Modals/BudgetActionsModals";
import { BudgetModals } from "Components/Modals/BudgetActionsModals/interface";

import { TableBodyProps } from "./interface";

export const TableBody = ({
  listBudgets,
  handleGetBudgetDetail,
}: TableBodyProps) => {
  const budgetActionsModal = useBudgetCartModals();

  const { onOpenModal, setBudgetModals } = budgetActionsModal;

  const formatDocument = (document: Document) => {
    if (document.type === "cpf") return MaskCPF(document.number);

    return document.number;
  };

  const handleOpenDuplicateBudget = (budget: unknown) => {
    setBudgetModals(budget as BudgetModals);
    onOpenModal("editBudget");
  };

  return (
    <>
      <tbody>
        {listBudgets.map((dataItem) => (
          <tr key={dataItem.id}>
            <td>{dataItem.id}</td>
            <td>{formatDateBR(dataItem.created_at)}</td>
            {dataItem.patient ? (
              <>
                <td>
                  {dataItem.patient?.social_name || dataItem.patient?.full_name}
                </td>
                <td>{formatDocument(dataItem.patient?.documents[0])}</td>
              </>
            ) : (
              <>
                <td>{dataItem.lead?.name}</td>
                <td />
              </>
            )}

            <td>{dataItem?.covenant_data?.covenant_name}</td>
            <td>{formatBRLCurrency(dataItem.total)}</td>

            <td>{dataItem.channel.name}</td>
            <td>{dataItem.status.status}</td>
            <td>
              <Box display="flex" justify="center">
                <Button onClick={() => handleOpenDuplicateBudget(dataItem)}>
                  EDITAR
                </Button>
                <Button
                  onClick={() => handleGetBudgetDetail(dataItem.id)}
                  outlined
                >
                  VISUALIZAR
                </Button>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>

      <BudgetActionsModals {...budgetActionsModal} />
    </>
  );
};
