import Axios from "axios";
import { getToken } from "Services/Authentication";
import { PersonData } from "./types";



export const updatePerson = async (person_id: number, personData: PersonData) => {
  try {
  
    const API = Axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      },
    });
    
    const response = await API.put(`/persons/${person_id}`, {
      ...personData
    });

    return response.data
  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao atualizar a pessoa";
  }
}
