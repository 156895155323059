import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        // Grid settings
        --grid-gutter-width: 16px;
        --grid-columns: 12;

        --screen-sm: 768px;
        --screen-md: 1024px;
        --screen-lg: 1280px;
        --screen-ultra-lg: 1500px;

        --grid-container-max-size: 1280px;

        // Colors settings
        --color-yellow: #f6e71c;
        --color-purple: #572580;
        --color-purple_opacity: rgba(87, 37, 128, 0.8);
        --color-ltgray: #f5f5f5;
        --color-gray: #b1b1b1; // Extracted from layout
        --color-black: #333333;
        --color-green-money: #6BC895;
        --color-success: #3ab572;
        --color-orange: #f0ac4a;
        --color-danger: #c23f3b;
        --color-exit-money: #D16F6C; 
        --color-use-money: #F4C177;
        --color-buy-credits:  #572580;
        --color-lgray-text: #999;
        --color-gray-text: #666;
        --color-hgray-text: #333;
        --color-semi-gray: #CCCCCC;
        --color-border-schedule: #666666;
        --color-bold-gray: #666666;
        --color-light-gray-border: rgba(195, 195, 195, 0.5);

        --font-display: "Saira", sans-serif;
        --font-text: "Open Sans", sans-serif;
        --font-weight-heading: 400;

        --font-color-body: var(--color-black);
        

        --font-size-h1: 48px;
        --font-size-h2: 36px;
        --font-size-h3: 32px;
        --font-size-h4: 28px;
        --font-size-h5: 24px;
        --font-size-lead: 20px;
        --font-size-body: 16px;
        --font-size-sm: 15px;
        --font-size-xs: 12px;

        --font-size-h1-md: 48px;
        --font-size-h2-md: 36px;
        --font-size-h3-md: 32px;
        --font-size-h4-md: 28px;
        --font-size-h5-md: 24px;
        --font-size-lead-md: 20px;
        --font-size-body-md: 14px;
        --font-size-sm-md: 13px;
        --font-size-xs-md: 10px;

        --line-height-h1: 56px;
        --line-height-h2: 44px;
        --line-height-h3: 40px;
        --line-height-h4: 36px;
        --line-height-h5: 32px;

        // Separators
        --separate-base: 32px;
        --separate-title-from-content: var(--separate-base) * 2;
        --separate-text-from-content: var(--separate-base) + 16px;

        // Icons
        --icomoon-font-family: "labiicons" !default;
        --icomoon-font-path: "Fonts" !default;

        --icon-profile-male: "\e900";
        --icon-credit-card: "\e901";
        --icon-money: "\e902";
        --icon-microscope: "\e903";
        --icon-edit: "\e904";
        --icon-trash: "\e905";
        --icon-faq: "\e906";
        --icon-linkedin-fulll: "\e907";
        --icon-loupe: "\e908";
        --icon-map-pin: "\e909";
        --icon-cart: "\e90a";
        --icon-view: "\e90b";
        --icon-help: "\e90c";
        --icon-results: "\e90d";
        --icon-account: "\e90e";
        --icon-call-doctor: "\e90f";
        --icon-discount: "\e910";
        --icon-clock: "\e911";
        --icon-sangue: "\e912";
        --icon-tube: "\e912";
        --icon-angle-double-right: "\e913";
        --icon-angle-double-left: "\e914";
        --icon-bank-slip: "\e915";
        --icon-close: "\e916";
        --icon-heart: "\e917";
        --icon-tests: "\e918";
        --icon-checked: "\ea52";
        --icon-unchecked: "\ea53";
        --icon-fezes: "\e91f";
        --icon-urina: "\e920";
        --icon-unit: "\e921";
        --icon-truck: "\e922";

        // Control z-index
        --z-index-sidebar: 100;
        --z-index-topbar: var(--z-index-sidebar) + 1;
    }

        //padrão da aplicação : 16px
    html {
        @media (max-width: 1080px){
            font-size: 93.75%; //calculo: 16px*0.9375 = 15px
        }
        @media (max-width: 720px){
            font-size: 87.5%; //calculo 16px*.875 = 14px
        }
        height: 100%;
        min-height: 100%;
    }
    

    body {
        margin: 0;
        padding: 0;
        height:100%;

        &.gray-page {
            background-color: var(--color-ltgray);
        }

        &.checkout-page {
            .certificate-buy-safe {
                margin-bottom: 95px;
            }

            @media screen and (min-width: 1024px) {
                .certificate-buy-safe {
                    margin-bottom: 41px;
                }
            }
        }

        .ReactModalPortal {
            position: relative;
            z-index: 9998;
        }
    }

    .invalid {
        border-color: #c23f3b;
        background-color: rgba(224, 159, 157, 0.25);
    }

    .error-message{
        display:block;
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        color: #c23f3b;
    }

    p { 
        font-family: 'Saira', 'sans-serif'
    }
`;
