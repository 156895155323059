import styled from "styled-components";

export const ContainerPaymentForm = styled.section`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-right: 400px;
  margin-top: 50px;

  .field-holder,
  select-field-holder {
    width: 100%;
    min-width: 341px;

    .error-message {
      bottom: initial;
    }
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const FreeAttendanceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 26px;
    color: var(--color-purple);
  }

  img {
    margin-top: 70px;
    width: 500px;
  }
`;

export const FlexItemsInput = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  justify-content: space-between;
  gap: 1rem;

  margin: 1rem 0;
  margin-bottom: 1.5rem;
`;

export const ContainerFields = styled.div`
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerButton = styled.div`
  margin-top: 2rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const PaymentFormResizable = styled.div`
  width: 100%;
  border: 1px solid #f5f5f5;
  color: ${(props) => (props.open ? "#666666" : "#999999")};
  font-weight: ${(props) => (props.open ? "600" : "500")};
  box-shadow: ${(props) =>
    props.open
      ? "0px 0px 0px 1px rgba(130, 136, 148, 0.16), 0px 4px 6px -2px rgba(130, 136, 148, 0.32)"
      : ""};

  &:first-child {
    border-radius: ${(props) => (props.open ? "14px" : "14px 14px 0 0")};
  }

  &:last-child {
    border-radius: ${(props) => (props.open ? "14px" : "0 0 14px 14px")};
  }

  header {
    cursor: ${(props) => (props.open ? "default" : "pointer")};
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-right: 30px;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: "Saira", sans-serif;
      font-weight: 500;
      color: #572580;
      font-size: 16px;
    }

    span {
      margin-left: 30px;
    }
  }

  > div {
    margin: 20px;
    margin-left: 25px;
    margin-top: 0;

    .field-holder {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    h6 {
      margin-top: 20px;
      margin-bottom: 5px;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #666666;
    }
  }
`;

export const NewPaymentFormContainer = styled(PaymentFormResizable)`
  color: #572580;
  margin-top: 50px;
`;

export const SelectedPaymentsContainer = styled.div`
  > div {
    margin-bottom: 30px;
  }
`;

export const CreditInstallmentsContainer = styled.div`
  margin-top: 30px;

  span {
    color: #666666 !important;
  }
`;

export const InstallmentsContainer = styled.div`
  margin-top: 15px;
  border: 1px solid #d8d8d8;
  border-radius: 14px;
`;

export const InstallmentLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  border-left: ${(props) => (props.selected ? "5px solid purple" : "")};
  cursor: pointer;
  border-bottom: ${(props) => (props.selected ? "1px solid #D8D8D8" : "")};
  background-color: ${(props) => (props.selected ? "#F5F5F5" : "")};
  &:first-child {
    border-radius: ${(props) => (props.selected ? "14px 14px 0 0" : "")};
  }
`;
