import styled from "styled-components";
import ArrowIcon from "Assets/Images/arrow-gray.svg";

export const StyledPreviousArrow = styled.img`
  content: url(${ArrowIcon});
  filter: brightness(0) invert(1); 
  transform: rotate(90deg)
`;

export const StyledNextArrow = styled.img`
content: url(${ArrowIcon});
filter: brightness(0) invert(1); 
transform: rotate(270deg)
`;
