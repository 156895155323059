import React from "react";

import { ModalInterface } from "Pages/RegistrationCleaning/interface";

import { ModalInformationWithChildren } from "Components/Modals/ModalInformation/informationWithChildren";

import AtentionIcon from "Assets/Images/atention.svg";
import DocumentIcon from "Assets/Images/documentIcon.svg";
import SuccessIcon from "Assets/Images/successIcon.svg";
import SuccessIconLine from "Assets/Images/successIconLine.svg";
import ValidationIcon from "Assets/Images/validationIcon.svg";

import { ConfirmSigleRegistration } from "./ConfirmSigleRegistration";
import { ContinueWithoutUnifying } from "./ContinueWithoutUnifying";
import { DependentAlert } from "./DependentAlert";
import { DependentContinue } from "./DependentContinue";
import { DependentContinueOrMore } from "./DependentContinueOrMore";
import { DependentEdit } from "./DependentEdit";
import { DependentHasDocument } from "./DependentHasDocument";
import { DuplicatedListModal } from "./DuplicatedListModal";
import { History } from "./History";
import { SendMotionModal } from "./SendMotionModal";
import { SubmitModal } from "./SubmitModal";
import { UnifyRegistration } from "./UnifyRegistration";
import { ValidationModal } from "./ValidationModal";




export const ModalHandler = ({
  isVisible = false,
  withoutDocument = false,
  handleDismiss = () => {},
  modal = "default",
  messageButtonCancelModal = "",
  title = "",
  icon = "",
  messageButton = "",
  handleModal = () => {},
  historyData,
  personsSelect,
  personData,
  handleSubmit = () => {},
}: ModalInterface) => {
  let ModalBody = <></>;
  switch (modal) {
    case "history":
      ModalBody = <History historyData={historyData} />;
      break;
    case "confirm_single_registration":
      ModalBody = <ConfirmSigleRegistration />;
      break;
    case "unify_registration":
      ModalBody = (
        <UnifyRegistration
          personsSelect={personsSelect}
          handleDismiss={handleDismiss}
          handleModal={handleModal}
        />
      );
      break;
    case "success_modal":
      title = "Unificação feita com sucesso!";
      icon = SuccessIcon;
      ModalBody = <></>;
      break;
    case "continue_without_unifying":
      title = "Importante!";
      icon = AtentionIcon;
      ModalBody = <ContinueWithoutUnifying />;
      break;
    case "dependent_edit":
      title = `Confirmação de ${withoutDocument ? "vínculo" : "documento"}`;
      icon = DocumentIcon;
      ModalBody = (
        <DependentEdit
          handleSubmit={handleSubmit}
          personData={personData}
          withoutDocument={withoutDocument}
          handleDismiss={handleDismiss}
        />
      );
      break;
    case "dependent_alert":
      title = "Atenção!";
      icon = AtentionIcon;
      ModalBody = <DependentAlert personData={personData} />;
      break;
    case "dependent_has_document":
      title = "Confirmação de documento";
      icon = DocumentIcon;
      ModalBody = <DependentHasDocument />;
      break;
    case "dependent_continue_or_more":
      title = "Sucesso!";
      icon = SuccessIconLine;
      ModalBody = (
        <DependentContinueOrMore
          handleDismiss={handleDismiss}
          handleModal={handleModal}
        />
      );
      break;
    case "dependent_continue":
      title = "Sucesso!";
      icon = SuccessIconLine;
      ModalBody = <DependentContinue handleModal={handleModal} />;
      break;
    case "validation_modal":
      title = "Quase lá...";
      icon = ValidationIcon;
      ModalBody = <ValidationModal handleModal={handleModal} />;
      break;
    case "submit_modal":
      title = "Informações atualizadas";
      icon = SuccessIconLine;
      ModalBody = <SubmitModal handleModal={handleModal} />;
      break;
    case "duplicated-list-modal":
      title = "Atenção!";
      icon = AtentionIcon;
      ModalBody = <DuplicatedListModal />;
      break;
    case "send-motion-modal":
      title = "Atenção!";
      icon = AtentionIcon;
      ModalBody = <SendMotionModal />;
      break;
    default:
      ModalBody = <></>;
  }

  return (
    <ModalInformationWithChildren
      modalVisible={isVisible}
      modalDismiss={() => handleDismiss()}
      cancelButton={!!messageButtonCancelModal}
      title={title}
      icon={icon}
      messageButton={messageButton}
      messageButtonCancel={messageButtonCancelModal}
      onClick={() => handleModal()}
    >
      {ModalBody}
    </ModalInformationWithChildren>
  );
};
