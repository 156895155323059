export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const MENU_TOGGLE = "MENU_TOGGLE";

export const SELECT_PACIENT = "SELECT_PACIENT";
export const SELECT_PLOTS = "SELECT_PLOTS";
export const REMOVE_PLOTS = "REMOVE_PLOTS";

export const GET_PACIENT = "GET_PACIENT";
export const SELECT_ORDER = "SELECT_ORDER";
export const GET_ORDER = "GET_ORDER";

export const USER_DUPLICATE = "USER_DUPLICATE";
export const PERSON_DUPLICATED_FLOW = "PERSON_DUPLICATED_FLOW";
export const PERSON_DUPLICATED_FLOW_RESET = "PERSON_DUPLICATED_FLOW_RESET";

export const SELECT_COVENANT_ID = "SELECT_COVENANT_ID";
export const CLEAR_COVENANT = "CLEAR_COVENANT";
export const BUDGET = "BUDGET";
export const RESET_BUDGET = "RESET_BUDGET";
