import Axios from "axios";
import { getToken } from "Services/Authentication";
import { UpdateScheduleType } from "./types";


export const rescheduleOrder = async (schedule_id: string, date: string) => {


  const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getToken()}`
    },
  });

  try {
    const canceledOrder = await API.get(`appointments/reschedule/${schedule_id}/date/${date}`)

    return canceledOrder.data

  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao reagendar o pedido";
  }

}

export const updateSchedule = async (address: UpdateScheduleType, schedule_id: string) => {


  const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getToken()}`
    },
  });

  try {
    const canceledOrder = await API.post(`appointments/update/${schedule_id}`, {
      ...address
    })

    return canceledOrder.data

  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao reagendar o pedido";
  }



}