import ApiAcuity from "Services/ApiAcuity";

import { createToast } from "Utils/toastFunc";

export async function getClinicUnitsByUser() {
  try {
    const res = await ApiAcuity.get(`/units-temp`, {
      params: {
        type: "clinica",
      },
    });
    return res;
  } catch (error) {
    createToast("error", "Não foi possível buscar unidades.");
  }
}
