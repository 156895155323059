/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

import { validateArrayType } from "Utils/Arrays";
import { isFileUnder2MB } from "Utils/FileSizeValidator";
import { createToast } from "Utils/toastFunc";
import validatePermissions from "Utils/validatePermissions";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import {
  FormatterUnits,
  UnFormattedUnits,
} from "../../Requests/Formatters/Units";
import { getInsurance } from "../../Requests/RequestInsurance";
import {
  createUnits,
  showUnit,
  editUnit,
  getRegions,
  deleteImage,
} from "../../Requests/Units";
import { AdditionalInfo } from "./components/AdditionalInfo";
import { AddressInfo } from "./components/AddressInfo";
import { Attributes } from "./components/Attributes";
import { GeneralInfo } from "./components/GeneralInfo";
import { ImagesPanel } from "./components/ImagesPanel";
import { Parkings } from "./components/Parkings";
import { Transports } from "./components/Transports";

const parkingInitial = {
  parking: {
    value: "",
    error: "",
  },
  street: {
    value: "",
    error: "",
  },
  cep: {
    value: "",
    error: "",
  },
  number: {
    value: "",
    error: "",
  },
  distance: {
    value: "",
    error: "",
  },
  deleted: false,
  isCreatedInMethodUpdate: true,
};

const transportInitial = {
  transport: "",
  deleted: false,
  isCreatedInMethodUpdate: true,
};

const INITIAL_STATE = {
  name: {
    value: "",
    error: "",
  },
  motion_code: {
    value: "",
    error: "",
  },
  cashier: {
    value: "",
    error: "",
  },
  exam_schedule: {
    value: "",
    error: "",
  },
  channels: {
    value: [],
    error: "",
  },
  result_schedule: {
    value: "",
    error: "",
  },
  covenant: [],
  region: [],
  category_ids: { value: [], error: "" },
  insurances: { value: [], error: "" },
  region_id: { value: [], error: "" },
  // endereços
  neighborhood: {
    value: "",
    error: "",
  },
  street: {
    value: "",
    error: "",
  },
  number: {
    value: "",
    error: "",
  },
  city_id: {
    value: "",
    error: "",
  },
  complement: {
    value: "",
    error: "",
  },
  cep: {
    value: "",
    error: "",
  },
  latitude: {
    value: "",
    error: "",
  },
  longitude: {
    value: "",
    error: "",
  },
  // editor html
  unmet_products: {
    value: "",
    error: "",
  },
  facilities: {
    value: "",
    error: "",
  },
  description: {
    value: "",
    error: "",
  },
  reference: {
    value: "",
    error: "",
  },
  opening_hours: {
    value: "",
    error: "",
  },
  parking_desk: {
    value: "",
    error: "",
  },
  type: {
    value: "",
    error: "",
  },
  professional_code: {
    value: "",
  },
  slug: {
    value: "",
  },
  parking: {
    value: false,
  },
  accessibility: {
    value: false,
  },
  next_subway: {
    value: false,
  },
  test_covid: {
    value: false,
  },
  open_unit: {
    value: true,
  },
  apply_vaccines: {
    value: false,
  },
  coming_soon: {
    value: false,
  },
  toxic_exam: {
    value: false,
  },
};

export function CreateProduct() {
  const user_permissions = useSelector((state) => state.User.permissions);
  const history = useHistory();
  const { id: params_id } = useParams();

  const [objectDataImg, setObjectDataImg] = React.useState({
    image_map: [],
    images: [],

    listImages: { value: [], error: "" },
    imageMap: {
      value: "",
    },
  });
  const [removeImagesIds, setRemoveImagesIds] = useState([]);

  const [objectData, setObjectData] = useState(INITIAL_STATE);

  const [parkings, setParkings] = useState([parkingInitial]);

  const [parkingsActive, setParkingsActive] = useState(false);
  const [transportsActive, setTransportActive] = useState(false);

  const [transports, setTransports] = useState([transportInitial]);

  const [loading, setLoading] = useState(false);

  const [addressesCity, setAddressCity] = useState();

  const [proFiles, setProFiles] = useState([]);
  const [proFilesImageMap, setProFilesImageMap] = useState([]);

  const [editors, setEditors] = useState({
    editorReference: EditorState.createEmpty(),
    editorDescription: EditorState.createEmpty(),
    editorOpenHours: EditorState.createEmpty(),
    editorUnmetProducts: EditorState.createEmpty(),
    editorFacilities: EditorState.createEmpty(),
    editorParkingDesk: EditorState.createEmpty(),
  });

  const resetForm = () => {
    setObjectData(INITIAL_STATE);
    setParkings([parkingInitial]);
    setParkingsActive(false);
    setTransportActive(false);
    setTransports([transportInitial]);
    setLoading(false);
    setProFiles([]);
    setProFilesImageMap([]);
    setAddressCity("");
    setEditors({
      editorReference: EditorState.createEmpty(),
      editorDescription: EditorState.createEmpty(),
      editorOpenHours: EditorState.createEmpty(),
      editorUnmetProducts: EditorState.createEmpty(),
      editorFacilities: EditorState.createEmpty(),
      editorParkingDesk: EditorState.createEmpty(),
    });
    setObjectDataImg({
      image_map: [],
      images: [],

      listImages: { value: [], error: "" },
      imageMap: {
        value: "",
      },
    });
  };

  function toggleImages(id) {
    setRemoveImagesIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((imageId) => imageId !== id);
      }
      return [...prev, id];
    });
  }

  function handleObjectChange(target, name) {
    setObjectData((prev) => ({
      ...prev,
      [name]: {
        value: target.value,
      },
    }));
  }
  function validateForm(data) {
    let validate = true;
    let parkingValidate = true;
    let parkingDistance = true;
    for (const name in data) {
      if (data[name] !== "covenant") {
        if (
          data[name].value === "" &&
          name !== "parking" &&
          name !== "accessibility" &&
          name !== "complement" &&
          name !== "latitude" &&
          name !== "longitude" &&
          name !== "neighborhood" &&
          name !== "imageMap" &&
          name !== "exam_schedule" &&
          name !== "result_schedule" &&
          name !== "professional_code" &&
          name !== "deleted_at"
        ) {
          setObjectData((prev) => ({
            ...prev,
            [name]: {
              ...data[name],
              error: "O campo é obrigatório",
            },
          }));
          validate = false;
        }

        if (
          name === "category_ids" &&
          !validateArrayType(data.category_ids.value)
        ) {
          setObjectData((prev) => ({
            ...prev,
            category_ids: {
              value: prev.category_ids.value,
              error: "O campo é obrigatório",
            },
          }));
        }

        if (isNaN(data.motion_code.value)) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            motion_code: {
              ...data.motion_code,
              error: "Somente número",
            },
          }));
        }

        if (!data.type.value || data.type.value === "undefined") {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            type: {
              ...data.type,
              error: "O campo é obrigatório",
            },
          }));
        }

        if (!data.region_id.value.id) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            region_id: {
              ...data.region_id,
              error: "O campo é obrigatório",
            },
          }));
        }

        if (isNaN(data.latitude.value)) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            latitude: {
              ...data.latitude,
              error: "Somente número",
            },
          }));
        }

        if (isNaN(data.longitude.value)) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            longitude: {
              ...data.longitude,
              error: "Somente número",
            },
          }));
        }

        if (data.region_id.value <= 0) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            region_id: {
              ...data.region_id,
              error: "O campo é obrigatório",
            },
          }));
        }

        if (!data.type.value) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            type: {
              ...data.type,
              error: "O campo é obrigatório",
            },
          }));
        }

        if (parkingsActive === true) {
          parkings.forEach((item) => {
            Object.keys(item).forEach((_item) => {
              if (item[_item].value === "") {
                if (item.deleted === true) return;
                validate = false;
                parkingValidate = false;
              }

              if (isNaN(item.distance.value)) {
                if (item.deleted === true) return;
                validate = false;
                parkingValidate = false;
                parkingDistance = false;
              }
            });
          });
        }

        if (data.insurances.value === [] || data.insurances.value === null) {
          validate = false;
          setObjectData((prev) => ({
            ...prev,
            insurances: {
              ...data.insurances,
              value: [],
              error: "O campo é obrigatório",
            },
          }));
        }
      }
    }
    !validate &&
      createToast("error", "Um ou mais campos não foram preenchidos!");
    !parkingValidate &&
      createToast(
        "error",
        "Estacionamento foi ativado porém nenhum campo foi preenchido!"
      );
    !parkingDistance &&
      createToast("error", "Campo distância é somente para números");

    return validate;
  }

  function objectToFormData(obj) {
    const formData = new FormData();

    Object.entries(obj).forEach(([key, value]) => {
      if (key === "addresses") {
        return formData.append(key, JSON.stringify(value));
      }

      if (key === "category_ids") {
        if (obj.category_ids.length) {
          return obj.category_ids.forEach((category) => {
            formData.append(`category_ids[]`, category);
          });
        }
        return formData.append(`category_ids`, "[]");
      }

      if (key === "channels_ids") {
        if (obj.channels_ids.length) {
          return obj.channels_ids.forEach((channel) => {
            formData.append(`channels_ids[]`, channel);
          });
        }
        return formData.append(`channels_ids`, "[]");
      }

      formData.append(key, value);
    });

    formData.delete("deleted_at");

    return formData;
  }

  async function handleDeleteImg() {
    try {
      setLoading(true);
      const deletePromises = removeImagesIds.map(async (id) => {
        await deleteImage(id);
      });
      await Promise.all(deletePromises);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setRemoveImagesIds([]);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading) return;
    if (!validateForm(objectData)) return;

    try {
      setLoading(true);
      const isCreate = !!(params_id !== "" && params_id === "0");
      const formatter = FormatterUnits(
        objectData,
        parkings,

        transports,
        parkingsActive,
        isCreate
      );

      const formData = objectToFormData(formatter);

      if (validateArrayType(proFiles)) {
        proFiles.forEach((image) => {
          formData.append("images[]", image);
        });
      }

      if (validateArrayType(proFilesImageMap)) {
        formData.append("image_map", proFilesImageMap[0]);
      }

      const id = objectData?.id?.value;
      const { data } =
        params_id !== "" && params_id !== "0"
          ? await editUnit(formData, id)
          : await createUnits(formData);

      await handleDeleteImg();
      history.push("/unidades");
      createToast(
        "success",
        `Unidade ${
          params_id !== "" && params_id !== "0"
            ? "editada"
            : `N°${data.id} criada`
        } com sucesso!`
      );
    } catch (error) {
      const errors = error?.response?.data.errors || undefined;

      if (errors) {
        // eslint-disable-next-line consistent-return
        return Object.entries(errors).forEach(([field, [message]]) => {
          setObjectData((prev) => ({
            ...prev,
            [field]: { value: prev[field].value, error: message },
          }));
        });
      }

      createToast("error", "Um erro inesperado ocorreu");
    } finally {
      setLoading(false);
    }
  }

  async function VerifyUnitsParams() {
    if (params_id == 0) {
      return resetForm();
    }
    if (params_id != 0) {
      try {
        const { data, statusText } = await showUnit(params_id);
        const { obj, parkingsData, transportsData } = UnFormattedUnits(
          data,
          parkingInitial,
          transportInitial,
          setParkingsActive,
          setTransportActive
        );

        setObjectDataImg((prev) => ({
          ...prev,
          listImages: { value: data.images, error: "" },
        }));

        setObjectData({ ...obj, id: { value: obj.id.value } });

        const newArray = obj.city_id.cities.map((item) => ({
          id: item.id,
          city: item.city,
        }));
        setAddressCity(newArray);
        FormatEditors(obj);
        setParkings(parkingsData);
        setTransports(transportsData);

        if (statusText === "OK")
          createToast("success", "Unidade carregada com sucesso!");
      } catch (err) {
        console.log(err);
      }
    }
  }

  function FormatEditors(obj) {
    const blocksFromReference = htmlToDraft(obj.reference.value);
    const blocksFromDescription = htmlToDraft(obj.description.value);
    const blocksFromOpenHours = htmlToDraft(obj.opening_hours.value);
    const blocksFromUnmetProducts = htmlToDraft(obj.unmet_products.value);
    const blocksFromFacilities = htmlToDraft(obj.facilities.value);
    const blocksFromParkingDesk = htmlToDraft(obj.parking_desk.value);

    setEditors((prev) => ({
      ...prev,
      editorReference: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromReference.contentBlocks,
          blocksFromReference.entityMap
        )
      ),

      editorDescription: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromDescription.contentBlocks,
          blocksFromDescription.entityMap
        )
      ),

      editorOpenHours: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromOpenHours.contentBlocks,
          blocksFromOpenHours.entityMap
        )
      ),

      editorUnmetProducts: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromUnmetProducts.contentBlocks,
          blocksFromUnmetProducts.entityMap
        )
      ),

      editorFacilities: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromFacilities.contentBlocks,
          blocksFromFacilities.entityMap
        )
      ),
      editorParkingDesk: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromParkingDesk.contentBlocks,
          blocksFromParkingDesk.entityMap
        )
      ),
    }));
  }

  function formatInsurance(array) {
    if (!Array.isArray(array)) {
      return [];
    }

    const newArray = array.map((item) => ({
      id: item.id,
      covenant: item.covenant,
    }));
    return newArray;
  }

  function formatRegion(array) {
    const newArray = array.map((item) => ({
      id: item.id,
      region: item.region,
    }));
    return newArray;
  }

  async function GetInsuranceCombo() {
    try {
      const response = await getInsurance();
      if (!response?.data?.length) {
        createToast(
          "error",
          "Erro ao carregat lista de convênios. Por favor, tente novamente mais tarde."
        );

        history.push("/unidades");
      }

      setObjectData((prev) => ({
        ...prev,
        covenant: formatInsurance(response.data),
      }));
    } catch (err) {
      console.log(err);
    }
  }

  async function GetRegionsCombo() {
    try {
      const response = await getRegions();
      setObjectData((prev) => ({
        ...prev,
        region: formatRegion(response),
      }));
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchDatas() {
    if (params_id) {
      setLoading(true);
      await VerifyUnitsParams();
      await GetInsuranceCombo();
      await GetRegionsCombo();
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchDatas();
  }, [params_id]);

  // Upload de imagens

  function sendFormDataImageMap(files) {
    const [file] = files;

    const isUnder2MB = isFileUnder2MB(file);

    if (!isUnder2MB) {
      createToast(
        "error",
        "O tamanho da imagem excede 2 MB. Por favor, selecione uma imagem menor."
      );
      return;
    }

    setObjectDataImg((prev) => ({
      ...prev,
      image_map: [file],
    }));
    setProFilesImageMap([file]);
  }
  function sendFormData(files) {
    const [file] = files;
    const isUnder2MB = isFileUnder2MB(file);

    if (!isUnder2MB) {
      createToast(
        "error",
        "O tamanho da imagem excede 2 MB. Por favor, selecione uma imagem menor."
      );
      return;
    }

    let image_length = objectDataImg.images?.length;
    files.forEach((file) => {
      setObjectDataImg((prev) => {
        return {
          ...prev,
          images: [
            ...prev.images,
            {
              [`image_${image_length + 1}`]: file,
            },
          ],
        };
      });
      image_length += 1;
    });
    setProFiles([...proFiles, ...files]);
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder content-holder-no-filter remove-padding">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader
              title={
                params_id !== "0" ? "Editar unidade" : "Cadastrar unidades"
              }
            >
              <Breadcrumb
                list={[
                  {
                    path: "/unidades",
                    label: "Unidades",
                    current: false,
                  },
                  {
                    path: "#",
                    label: `${
                      params_id !== "0"
                        ? "Editar unidade"
                        : "Cadastrar unidades"
                    }`,
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            <div className="actions-holder">
              {validatePermissions("Create Units", user_permissions) && (
                <div className="actions-holder">
                  <button
                    type="button"
                    className="link link-danger"
                    onClick={() => history.push("/unidades")}
                  >
                    Voltar
                  </button>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="button _action"
                    disabled={loading}
                  >
                    {params_id !== "" && params_id !== "0" ? "Editar" : "Criar"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="row">
            <div className="md-8">
              <div className="row">
                <div className="sm-12">
                  <GeneralInfo
                    loading={loading}
                    objectData={objectData}
                    setObjectData={setObjectData}
                    handleObjectChange={handleObjectChange}
                  />
                </div>
              </div>
            </div>
            <div className="md-4">
              <div className="row">
                <div className="sm-12">
                  <Attributes
                    loading={loading}
                    objectData={objectData}
                    setObjectData={setObjectData}
                  />
                </div>
              </div>
            </div>
            <div className="sm-12">
              <AddressInfo
                addressesCity={addressesCity}
                loading={loading}
                objectData={objectData}
                setObjectData={setObjectData}
                handleObjectChange={handleObjectChange}
              />
            </div>
            <div className="sm-12">
              <ImagesPanel
                loading={loading}
                sendFormData={sendFormData}
                proFiles={proFiles}
                proFilesImageMap={proFilesImageMap}
                sendFormDataImageMap={sendFormDataImageMap}
                objectData={objectDataImg}
                removeImagesIds={removeImagesIds}
                onToggleImages={toggleImages}
              />
            </div>

            <AdditionalInfo
              loading={loading}
              editors={editors}
              setEditors={setEditors}
              objectData={objectData}
              setObjectData={setObjectData}
              handleObjectChange={handleObjectChange}
            />

            <div className="md-12">
              <Parkings
                loading={loading}
                parkingInitial={parkingInitial}
                parkings={parkings}
                setParkings={setParkings}
                parkingsActive={parkingsActive}
                setParkingsActive={setParkingsActive}
              />
            </div>

            <div className="md-12">
              <Transports
                loading={loading}
                transports={transports}
                setTransports={setTransports}
                transportInitial={transportInitial}
                transportsActive={transportsActive}
                setTransportActive={setTransportActive}
              />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
