import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { BudgetProps, CartItem } from "Dtos/IBudget";

import {
  AddOrUpdateProductToCart,
  DeleteBudgetByID,
  DeleteDocumentsBudget,
  GetBudgetByID,
  addGeneralInfoAndDocuments,
  linkUnitToBudget,
} from "Requests/Budget";
import { PayloadInfoBudget } from "Requests/Budget/interface";
import { FormatterToUpdateBudget } from "Requests/Formatters/Budget";

import { handleClearPlots } from "Actions/ActionsCreators";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useQueryParams } from "Hooks/useQueryParams";

import { Store } from "Reducers/interface";

import { handleRequestErrors } from "Utils/Errors";
import { createToast } from "Utils/toastFunc";

import { ProductVoucher, Voucher } from "./interface";

export const useCart = () => {
  const {
    budget,
    zipCode,
    resetBudgetStates,
    setPartnerSelected,
    setBudgetInfo,
    setLoadingBudget,
    setDocuments,
    setCovenant,
    selectedCovenant,
    toggleLoadingCartState,
    documents,
    setZipCode,
  } = useBudgetContext();

  const params = useQueryParams();

  const btype = params.get("btype");

  const dispatch = useDispatch();
  const history = useHistory();

  const { person_type } = useParams<{ person_type: string }>();

  const serviceFormState = useSelector((state: Store) => state.service_form);
  const patientSelected = serviceFormState.pacient;

  const defaultFormatterValues = {
    patient: {
      id: patientSelected.id,
      ...(patientSelected.emails &&
        patientSelected.phones && {
          emailId: patientSelected.emails[0] ? patientSelected.emails[0].id : 0,
          phoneId: patientSelected.phones[0] ? patientSelected.phones[0].id : 0,
        }),
    },
    person_type,
    budget,
    ...(selectedCovenant && { credential_id: selectedCovenant?.id }),
    ...(zipCode && { zip_code: zipCode }),
  };

  async function deleteBudget(budgetId: number, successMessage: string) {
    dispatch(handleClearPlots());
    const response = await DeleteBudgetByID(budgetId);
    resetBudgetStates();
    createToast("success", `${successMessage}`);
    history.push(`/ficha-de-atendimento/${person_type}/selecionar-produtos`);
    return response;
  }

  const clearCart = async (budgetId: number, successMessage: string) => {
    if (budgetId) {
      await deleteBudget(budgetId, successMessage);
      setPartnerSelected(0);

      history.push(
        `/ficha-de-atendimento/${person_type}/selecionar-produtos?btype=${btype}`
      );
    }
  };

  const createBudget = async (postData: PayloadInfoBudget) => {
    return await AddOrUpdateProductToCart({
      budget_info: postData,
      budget_type: budget?.is_covenant ? "covenant" : "private",
    });
  };

  async function addGeneralInfoBudget(generalInfo: string) {
    try {
      if (budget?.id) {
        const formData = new FormData();
        formData.append("general_information", generalInfo);
        const response: any = await addGeneralInfoAndDocuments(
          budget?.id,
          formData
        );

        if (response) {
          const newBudgetInfo = {
            ...budget,
            general_information: response.data.general_information,
          };
          setBudgetInfo(newBudgetInfo);
        }

        createToast("success", "Informação registrada com sucesso!");
      }
    } catch (error) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage:
          "Não foi possível adicionar informações gerais ao orçamento.",
      });
    }
  }

  const handleChangeSelectPartner = async (partnerId: string) => {
    try {
      if (!partnerId) {
        createToast("warning", "Selecione um parceiro");
        return;
      }

      if (budget?.id) {
        const postData = getFirstProductInCart();

        if (postData) {
          postData.item.partner_id = Number(partnerId);

          const response: any = await createBudget(postData);

          if (response.info) {
            setPartnerSelected(Number(partnerId));
            setBudgetInfo(response.info);
            createToast("success", "Parceiro alterado com sucesso !");
          }
        }
      }
    } catch (errors: any) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível alterar o parceiro do orçamento.",
      });
    }
  };

  const setInfoRecoverBudget = (budgetInfos: BudgetProps) => {
    if (budgetInfos) {
      setBudgetInfo({ ...budgetInfos });
    }

    if (budgetInfos.documents_base64.length >= 1) {
      setDocuments(budgetInfos.documents_base64);
    }

    if (budgetInfos.zip_code) {
      setZipCode(budgetInfos.zip_code);
    }

    if (budgetInfos.covenant_data) {
      const {
        covenant_name,
        covenant_plan_credential,
        covenant_id,
        covenant_plan_id,
        covenant_plan_name,
        covenant_plan_code,
        credential_id,
      } = budgetInfos.covenant_data;
      setCovenant({
        id: credential_id,
        credential_number: covenant_plan_credential,
        covenant: {
          id: covenant_id,
          covenant: covenant_name,
        },
        covenant_plan: {
          id: covenant_plan_id,
          covenant_plans_name: covenant_plan_name,
          covenant_plans_code: covenant_plan_code,
        },
        front_credential_img_base64: null,
        back_credential_img_base64: null,
      });
    }

    if (budgetInfos.partner_id) {
      setPartnerSelected(budgetInfos.partner_id);
    }
  };

  async function getBudget(budgetId: number) {
    try {
      setLoadingBudget(true);
      const response: any = await GetBudgetByID(budgetId);

      setInfoRecoverBudget(response.info);
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível obter o orçamento.",
      });
      history.push("/ficha-de-atendimento/paciente");
    } finally {
      setLoadingBudget(false);
    }
  }

  const uploadBudgetDocument = async (budgetDocuments: File[]) => {
    try {
      const formData = new FormData();

      budgetDocuments.forEach((document) => {
        formData.append("documents[]", document);
      });

      if (budget?.id) {
        const response = await addGeneralInfoAndDocuments(budget.id, formData);
        const doc = response?.data.documents_base64;

        setDocuments([...doc]);
        createToast("success", "Arquivo anexado com sucesso !");
      }
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível fazer upload dos documentos.",
      });
    }
  };

  async function removeBudgetDocument(document_id: string) {
    try {
      if (budget?.id) {
        const documentResponse = await DeleteDocumentsBudget(
          budget.id,
          document_id
        );

        if (documentResponse.success) {
          setDocuments(documentResponse.info);

          createToast("success", "Ficha médica removida com sucesso.");
        }
      }
    } catch (error) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível remover o documento.",
      });
    }
  }

  async function createProductCourtesyBudget(
    data: Voucher,
    products: ProductVoucher[]
  ) {
    try {
      if (budget) {
        const promises = products.map((product) => {
          const postData = FormatterToUpdateBudget({
            ...defaultFormatterValues,
            product: {
              id: product.product_id,
              amount: product.amount,
            },
            voucher: data.voucher,
          });

          return createBudget(postData);
        });

        const responses = await Promise.all(promises);
        const lastReponse: any = responses.pop();
        if (lastReponse?.success && lastReponse?.info) {
          setBudgetInfo(lastReponse.info);
          createToast("success", "Voucher salvo com sucesso!");
        }
      }
    } catch (errors: any) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível inserir cortesia para este produto.",
      });
    }
  }

  const getFirstProductInCart = (
    couponCodeParam?: string | null
  ): PayloadInfoBudget | false => {
    if (!budget) return false;

    const firstProduct = budget.cart[0];

    const product = {
      product_id: firstProduct.product_id || firstProduct.id,
      amount: firstProduct.amount,
    };

    const budgetInfo = {
      ...budget,
      coupon_code: couponCodeParam ?? budget.coupon_code,
    };

    const regionId = budget?.partner_id;

    return FormatterToUpdateBudget({
      ...defaultFormatterValues,
      budget: budgetInfo,
      product: {
        id: product.product_id,
        amount: product.amount,
        partner_id: regionId,
      },
      voucher: firstProduct?.voucher || undefined,
    });
  };

  async function handleRemoveCoupon() {
    try {
      toggleLoadingCartState(true);
      const postData = getFirstProductInCart();

      if (postData) {
        postData.coupon_code = "";
        const budgetResponse = await createBudget(postData);
        setBudgetInfo(budgetResponse.info);
        createToast("success", "Cupom removido com sucesso!");
      }
    } catch (errors) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível remover o cupom deste orçamento.",
      });
    } finally {
      toggleLoadingCartState(false);
    }
  }

  async function handleUpdateCoupon(coupon_code: string) {
    try {
      const postData = getFirstProductInCart(coupon_code);

      if (postData) {
        const budgetResponse: any = await createBudget(postData);

        if (budgetResponse.info.coupon_code) {
          setBudgetInfo(budgetResponse.info);
          createToast("success", "Cupom adicionado com sucesso!");

          return;
        }

        createToast("error", "Não foi possível adicionar o cupom !");
      }
    } catch (errors: any) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível adicionar o cupom.",
      });
    }
  }

  function showMessageCartIsAnotherPacient() {
    createToast(
      "error",
      "Oops! O carrinho que você está tentando acessar está indisponível"
    );
  }

  const handleAmountQuantity = (type: string, amount: number) => {
    if (type.includes("add")) {
      return amount + 1;
    }
    return amount - 1;
  };

  const updateAmountCartButton = async (
    type: string,
    amount: number,
    product: CartItem
  ) => {
    try {
      const amountUpdated = handleAmountQuantity(type, amount);
      const regionId = budget?.partner_id;

      const productUpdated = {
        ...product,
        amount: amountUpdated,
      };

      if (budget) {
        const postData = FormatterToUpdateBudget({
          ...defaultFormatterValues,
          product: {
            id: productUpdated.product_id,
            amount: productUpdated.amount,
            partner_id: regionId,
          },
          voucher: productUpdated.voucher || undefined,
        });

        const responseUpdateCart: any = await createBudget(postData);

        setBudgetInfo(responseUpdateCart.info);
        createToast("success", "Quantidade atualiza com sucesso!");

        if (
          !responseUpdateCart.info.cart.length &&
          documents.length &&
          budget?.id
        ) {
          documents.forEach(async (item: any) => {
            await DeleteDocumentsBudget(budget.id, item.file_path);
          });

          setDocuments([]);
        }
      }
    } catch (errors: any) {
      handleRequestErrors({
        reqErrors: errors,
        errorMessage: "Não foi possível alterar a quantidade deste produto.",
      });
    }
  };

  function showMessagePacientEmpty() {
    createToast("error", "Oops! Nenhum paciente foi selecionado.");
  }

  const handleLinkUnit = async (budgetId: number, unitId: number | null) => {
    try {
      setLoadingBudget(true);
      const response = await linkUnitToBudget(budgetId, unitId);

      if (response) setInfoRecoverBudget(response);
    } catch (error) {
      const errMessage =
        unitId == null
          ? "Não foi possível desvincular a unidade do orçamento."
          : "Não foi possível vincular a unidade ao orçamento.";

      handleRequestErrors({
        reqErrors: error,
        errorMessage: errMessage,
      });
    } finally {
      setLoadingBudget(false);
    }
  };

  const linkBudgetUnit = async (unitId: number) => {
    if (budget) handleLinkUnit(budget.id, unitId);
  };

  const unlinkBudgetUnit = async () => {
    if (budget) await handleLinkUnit(budget?.id, null);
  };

  return {
    showMessagePacientEmpty,
    updateAmountCartButton,
    handleAmountQuantity,
    showMessageCartIsAnotherPacient,
    handleUpdateCoupon,
    handleRemoveCoupon,
    createProductCourtesyBudget,
    removeBudgetDocument,
    uploadBudgetDocument,
    getBudget,
    handleChangeSelectPartner,
    addGeneralInfoBudget,
    clearCart,
    deleteBudget,
    linkBudgetUnit,
    unlinkBudgetUnit,
  };
};
