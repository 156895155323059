import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FormattedAttendanceType, LastAddressType, useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { PageBody, PageContainer, PageContent } from "Pages/ServiceForm/styles";
import { CartOrderDetails } from "Pages/ServiceForm/components/Cart/CartOrderDetails/CartOrderDeatails";
import { cancelAppointment } from "Requests/CancelAppointment";
import { toast } from "react-toastify";
import { OrderHeading } from "Components/ServiceForm/OrderHeading";
import LinkIcon from "Assets/Images/link.svg"
import { Field, Form, Formik } from "formik";
import { MaskCPF, maskPhoneNumber } from "Utils/Masks";
import Input from "Components/Form/Input";
import { Button } from "Components/Button";
// eslint-disable-next-line import/named
import { createBudgetOrder } from "Requests/Order/index";
import { useShallow } from "zustand/react/shallow";
import { ScheduleTimer } from "../ScheduleTimer";
import { Container, TitleCheckoutCollect } from "../style";
import { Card } from "../Patient/components/PatientCard/styles";
import { PaymentInfoType } from "./types";
import { PaymentConfirmationModal } from "../PaymentConfirmation";
import { OrderConfirmationModal } from "../OrderConfirmation";
import { CancelAppointmentModal } from "../CancelAppointment";

export const CreateOrderPayment = () => {
  const history = useHistory();

  const { order, setOrder } = useBudgetOrderLocalStorage();

  const [booleans, setBooleans] = useState({
    paymentConfirmationModal: false,
    orderConfirmationModal: false,
    cancelAppointmentModal: false
  })

  const [paymentLink, setPaymentLink] = useState("")

  const [orderId, setOrderId] = useState("")

  const [orderTotalPrice, setOrderTotalPrice] = useState("")

  const [setCurrentScreen, isViewBudget] = useBudgetOrder(useShallow((state) => [
    state.setCurrentScreen,
    state.isViewBudget
  ]));

  const [formattedAttendances, setFormattedAttendances] = useState<FormattedAttendanceType[]>([]);
  const [lastAddress, setLastAddress] = useState<LastAddressType | null>(null);

  useEffect(() => {
    setCurrentScreen('payment')
  }, []);

  useEffect(() => {
    if(order.budget_id === 0) history.push('/orcamentos/particular#/')
  },[])

  const payment_info: PaymentInfoType = {
    name: order.payer_patient.name,
    cpf: order.payer_patient.document.type === 'cpf' ? MaskCPF(order.payer_patient.document.number) : order.payer_patient.document.number,
    email: order.payer_patient.email,
    phone: `(${order.payer_patient.phone.ddd}) ${maskPhoneNumber(order.payer_patient.phone.phone)}`,
    value: order.totalPrice
  }

  const submitForm = async (setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      const createdOrder: any = await createBudgetOrder(order.orderData)

      setPaymentLink(createdOrder.ORDER.original.data.payment_link)
      setOrderId(String(createdOrder.ORDER.original.data.id));
      setOrderTotalPrice(order.totalPrice);
      setFormattedAttendances(order.formattedAttendances);
      setLastAddress(order.lastAddress)
      if (order.coupon_percentage_discount === 100) {
        // modal de confirmaca de pagamento
        setBooleans({
          ...booleans,
          orderConfirmationModal: true
        })
      } else {
        setBooleans({
          ...booleans,
          paymentConfirmationModal: true
        })
      }
      setSubmitting(false)
      setOrder({
        budget_id: 0,
        appointment_type_id: "",
        isTaxInserted: false,
        payer_patient: {
          id: 0,
          birthdate: '',
          document: {
            id: 0,
            number: '',
            type: '',
            country_id: 0
          },
          email: '',
          gender_id: 0,
          mother_name: '',
          name: '',
          phone: {
            id: 0,
            ddd: 0,
            ddi: 0,
            phone: 0
          },
          unborn: false,
          social_name: ''
        },
        lead: {
          id: 0,
          ddd: null,
          phone: '',
          email: null,
          name: '',
        },
        attendances: [],
        itemsQuantity: 0,
        totalPrice: '0,00',
        budget_type: 1,
        coupon_percentage_discount: 0,
        homeCollectValue: 0,
        isCovenant: false,
        discounts: 0,
        subtotal: 0,
        deliveryTime: '',
        items: [],
        product_categories: [],
        formattedAttendances: [],
        session_start: "",
        session_end: "",
        lastAddress: {
          street: "",
          number: "",
          uf: "",
          neighborhood: "",
          city: "",
          channel_id: 4,
          complement: '',
          country_id: 26,
          default: true,
          reference_point: '',
          type_id: 1,
          zip_code: ''
        },
        attendanceLabel: [],
        dependents: [],
        patients: [],
        patientWithProducts: [],
        cupon_code: '',
        home_collected: false,
        home_collect_value: '',
        orderData: {
          channel_id: 4,
          order_type: 1,
          appointment_type_id: "",
          commercial_category: 63,
          commercial_document_number: '',
          payer_id: 0,
          order: {
            budget_id: 0,
            subtotal: '',
            discounts: '',
            total: 0,
            cupon_code: null,
            home_collected: false,
            home_collect_value: '',
            home_collect_date: '',
            vaccine_cards: [],
            cart: [],
            home_collect_schedule: {
              id_home_collected: 0,
              home_collect_date: "",
              zip_code: "",
              country: "Brasil",
              uf: "",
              city: "",
              neighborhood: "",
              street: "",
              number: "",
              complement: "",
              reference_point: "",
              address_type_id: 1,
            }
          }
        }
      })
    } catch (err) {
      console.error(err);
      setSubmitting(false)
      toast.error('Erro ao criar pedido')
    }
  }

  const handleCancelSchedule = async () => {

    try {
      const idSchedule = order.formattedAttendances.find((i) => i.id_scheduling != 0 && i.type === "home_collect")?.id_scheduling;
      if (idSchedule) {
        await cancelAppointment(idSchedule);

        setOrder({
          budget_id: 0,
          appointment_type_id: "",
          isTaxInserted: false,
          payer_patient: {
            id: 0,
            birthdate: '',
            document: {
              id: 0,
              number: '',
              type: '',
              country_id: 0
            },
            email: '',
            gender_id: 0,
            mother_name: '',
            name: '',
            phone: {
              id: 0,
              ddd: 0,
              ddi: 0,
              phone: 0
            },
            unborn: false,
            social_name: ''
          },
          lead: {
            id: 0,
            ddd: null,
            phone: '',
            email: null,
            name: '',
          },
          attendances: [],
          itemsQuantity: 0,
          totalPrice: '0,00',
          budget_type: 1,
          coupon_percentage_discount: 0,
          homeCollectValue: 0,
          isCovenant: false,
          discounts: 0,
          subtotal: 0,
          deliveryTime: '',
          items: [],
          product_categories: [],
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 26,
            default: true,
            reference_point: '',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: '',
          home_collected: false,
          home_collect_value: '',
          orderData: {
            channel_id: 4,
            order_type: 1,
            appointment_type_id: "",
            commercial_category: 63,
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              cupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        })
      }

    } catch (err) {
      console.error(err);
      toast.error("Erro ao cancelar o agendamento.");
    }
  };


  const handleCloseCancelModal = () => {
    setBooleans({
      ...booleans,
      cancelAppointmentModal: false
    });
    const { budget_id } = order

    handleCancelSchedule();

    if (isViewBudget) {
      history.push(`/orcamento/${budget_id}`);
    } else {
      history.push(`/ficha-de-atendimento/person/selecionar-produtos/${budget_id}?btype=private`);
    }
  }

  const handleOpenCancelModal = () => {
    setBooleans({ ...booleans, cancelAppointmentModal: true })
  }

  return (
    <PageContainer className="entry-app-content entry-budget">
      {order.budget_id != 0 ? <>
        <PageContent className="content-holder page-budget">
          <OrderHeading
            list={[
              {
                path: "/orcamentos",
                label: "Orçamento",
                current: false,
              },
              {
                path: "#",
                label: "Converter",
                current: false,
              },
              {
                path: "#",
                label: "Pagamento",
                current: true,
              },
            ]}
            title="Converter em Pedido"
            payerPatient={order.payer_patient}
          />
          <PageBody className="cart-order-budget">
            <Container className="card-search-information gray-background">
              <TitleCheckoutCollect>Gerar link para pagamento remoto</TitleCheckoutCollect>
              {order.session_end ?
                <ScheduleTimer
                  expired={() => handleOpenCancelModal()}
                  content="Para garantir que sua reserva seja confirmada, por favor finalize a identificação e o pagamento do seu pedido dentro do prazo ao lado. Depois desse período sua reserva será liberada para venda novamente."
                />
                : <></>}

              <div style={{ width: '100%' }}>
                <Card>
                  <div style={{ width: "100%", display: "flex", margin: 10, alignItems: 'center' }}>
                    <img src={LinkIcon} />
                    <div style={{ marginBottom: -30, marginLeft: 10 }}>
                      <TitleCheckoutCollect>Link de Pagamento</TitleCheckoutCollect>
                    </div>
                  </div>

                  <Formik
                    initialValues={payment_info}
                    onSubmit={(values: PaymentInfoType, { setSubmitting }) => submitForm(setSubmitting)}
                    enableReinitialize
                  >
                    {({ values, handleChange, isSubmitting }) => (
                      <Form>
                        <div
                          style={{ width: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'row', margin: 10, gap: 10 }}
                        >
                          <div style={{ width: '48%' }}>
                            <Field
                              name='name'
                              id='name'
                              label="NOME"
                              component={Input}
                              value={values.name}
                              onChange={handleChange}
                              disabled
                              readOnly
                            />
                          </div>
                          <div style={{ width: '48%' }}>
                            <Field
                              name='cpf'
                              id='cpf'
                              label={order.payer_patient.document.type === 'cpf' ? 'CPF' : 'PASSAPORTE'}
                              component={Input}
                              value={values.cpf}
                              onChange={handleChange}
                              disabled
                              readOnly
                            />
                          </div>
                        </div>
                        <div
                          style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: 10, gap: 10 }}
                        >
                          <div style={{ width: '48%' }}>
                            <Field
                              name='email'
                              id='email'
                              label="EMAIL"
                              component={Input}
                              value={values.email}
                              onChange={handleChange}
                              disabled
                              readOnly
                            />
                          </div>
                          <div style={{ width: '48%' }}>
                            <Field
                              name='phone'
                              id='phone'
                              label="Telefone"
                              component={Input}
                              value={values.phone}
                              onChange={handleChange}
                              disabled
                              readOnly
                            />
                          </div>
                        </div>
                        <div
                          style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: 10, gap: 10 }}
                        >
                          <div style={{ width: '48%' }}>
                            <Field
                              name='value'
                              id='value'
                              label="Valor"
                              component={Input}
                              value={values.value}
                              onChange={handleChange}
                              disabled
                              readOnly
                            />
                          </div>
                          <div style={{ width: '48%', marginTop: '1.8em' }}>
                            <Button
                              width="100%"
                              minHeight="3.7em"
                              type="submit"
                              loading={isSubmitting}
                            >
                              Gerar Link
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card>

                <div style={{
                  bottom: 0,
                  width: '100%',
                  backdropFilter: 'saturate(180%) blur(5px)', // Aplica desfoque e saturação
                  WebkitBackdropFilter: 'saturate(180%) blur(20px)', // Compatibilidade com Safari
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '1em 0',
                  borderRadius: '1em'
                }}>
                  <div style={{ justifyContent: 'left', marginLeft: '-3em', width: '10em' }}>

                    <Button textButton width="full" minHeight="3.7em" fontWeight="600" onClick={() => history.push('/pedidos/criar-pedido/identificacao')}>
                      voltar
                    </Button>

                  </div>


                </div>
              </div>

            </Container>
            <CartOrderDetails />
          </PageBody>
        </PageContent>
      </> : <></>}


      {booleans.paymentConfirmationModal ?
        <PaymentConfirmationModal
          isOpen={booleans.paymentConfirmationModal}
          closeModal={() => setBooleans((booleans) => ({ ...booleans, paymentConfirmationModal: true }))}
          paymentLink={paymentLink}
          orderId={orderId}
          orderPrice={orderTotalPrice}
        />
        : <></>}


      {booleans.orderConfirmationModal ?
        <OrderConfirmationModal
          isOpen={booleans.orderConfirmationModal}
          closeModal={() => setBooleans((booleans) => ({ ...booleans, orderConfirmationModal: true }))}
          formattedAttendances={formattedAttendances}
          lastAddress={lastAddress}
          orderId={orderId}
        />
        : <></>}

      {booleans.cancelAppointmentModal ?
        <CancelAppointmentModal
          isOpen={booleans.cancelAppointmentModal}
          handleClose={() => handleCloseCancelModal()}
        />
        : <></>}

    </PageContainer >
  )

}