interface Option {
  value: string | number;
  label: string | number;
}

interface ArrayItem {
  [key: string]: string | number;
}

export function toOptions(
  array: ArrayItem[],
  value: keyof ArrayItem,
  label: keyof ArrayItem
): Option[] {
  const options = array?.map((item) => {
    return {
      value: item[value],
      label: item[label],
    };
  });

  return options || [];
}
