export const dateFormatter = (obj, yearFull) => {
  const separete = obj.toString().split(" ");
  const getTime = separete[0].split("-");
  const getHour = separete[1].split(":");
  const removeZero = getTime.map((time) => {
    const formatterYear = time > 2000 && time.split("20")[1];

    if (formatterYear && !yearFull) return formatterYear;
    return time;
  });

  const format = [
    `${removeZero[2]}/${removeZero[1]}/${removeZero[0]}`,
    `${getHour[0]}:${getHour[1]}`,
  ];

  return format;
};

export function getDifferenceInMinutes(start, end) {
  const [startHour, startMinute] = start.split(":");
  const [endHour, endMinute] = end.split(":");
  const minutes = (endHour - startHour) * 60 + (endMinute - startMinute);
  return minutes;
}

export function getTimeWithAdd(time, addMinutes) {
  const [hour, minute] = time.split(":");
  const newHour = parseInt(hour) + Math.floor(addMinutes / 60);
  const newMinute = parseInt(minute) + (addMinutes % 60);

  if (newMinute >= 60) {
    return getTimeWithAdd(`${newHour + 1}:00`, newMinute - 60);
  }

  return `${newHour.toString().padStart(2, "0")}:${newMinute
    .toString()
    .padStart(2, "0")}`;
}

export function isTodayByFullDate(day, month, year) {
  if (!day) return false;

  if (typeof day !== "number") {
    day = day.id;
  }

  const today = new Date();

  return (
    day === today.getDate() &&
    month === today.getMonth() &&
    year === today.getFullYear()
  );
}

export const formatDateToBR = (date = "") => {
  if (!date || typeof date !== "string") return "";

  const [year, month, day] = date.split("-");

  return `${day}-${month}-${year}`;
};

export const formatDateToBackend = (date = "") => {
  if (!date || typeof date !== "string") return "";

  const [day, month, year] = date.split("/");

  return `${year}-${month}-${day}`;
};

export const formatDateBR = (date) => {
  if (date) {
    return new Intl.DateTimeFormat("pt-BR").format(new Date(date));
  }
};

export const isFutureDate = (dateString) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const parts = dateString.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();

  const invalidDate =
    year >= currentYear && (day > currentDay || month > currentMonth);

  if (invalidDate) {
    return false;
  }

  return true;
};

export function isValidDate(dateString) {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const parts = dateString.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  if (year < 1000 || year > 9999) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  const daysInMonth = new Date(year, month, 0).getDate();

  return !(day < 1 || day > daysInMonth);
}
