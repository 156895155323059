import PageHeader from "Components/Pages/Header";

import { PageHeading } from "Pages/ServiceForm/styles";
import Breadcrumb from "Components/Shared/Breadcrumb";
import { MaskCPF } from "Utils/Masks";
import { OrderHeadingProps } from "./interface";

export const OrderHeading = ({ list, title, payerPatient }: OrderHeadingProps) => {

  return (
    <PageHeading className="page-heading">
      <div className="wrapper-header-content m-bottom-1 order-budget">
        <PageHeader title={title} isBudget>
          <Breadcrumb list={list} />
          <p className="id-order-budget" style={{ marginTop: '0.5em'}}>#{payerPatient.id}</p>
        </PageHeader>

        <div className="budget-header-user">
          <h5>Criando <strong>pedido</strong> para:</h5>
          <p style={{ color: '#999999' }}>{payerPatient.name}</p>
          <p style={{ color: '#999999', fontWeight: 600 }}>

            {payerPatient.document.type === 'passport' 
             ? `Passaporte ${payerPatient.document.number}` :
             payerPatient.document.number !== "" ? 
             `CPF ${MaskCPF(payerPatient.document.number)}` :
             "CPF NÃO INFORMADO"}
          </p>
          <button style={{ marginTop: '0.5em'}}>
            Ver todos os pedidos
          </button>
        </div>

      </div>
    </PageHeading>
  )

}