import React, { useRef } from "react";

import { Field, Formik } from "formik";
import moment from "moment";
import { Box } from "styles/globalComponents";

import { useBudgetCombos } from "Hooks/useBudgetCombos";

import { createObjParamsTeste } from "Utils/searchFilters";

import { Button } from "Components/Button";
import SelectControlled from "Components/Form/Controlled/SelectControlled";
import Input from "Components/Form/Input";
import InputDateRange from "Components/Form/InputDateRange";
import Select from "Components/Form/Select";

import dataHelper from "../../data.json";
import {
  FiltersProps,
  FiltersValues,
  ParamsFilter,
  SetField,
} from "./interface";
import { StyledForm } from "./styles";

export function Filter({ searchBudgets }: FiltersProps) {
  const { initialValues, order_by } = dataHelper;

  const filters = useRef<ParamsFilter>({
    ...initialValues,
    start_date: "",
    end_date: "",
  });
  const { data: budgetCombos, isLoading: loadingCombos } = useBudgetCombos();

  const { channels, status } = budgetCombos ?? {};

  const handleSubmitSearch = (values: FiltersValues) => {
    const budgetFilters = formatFilters(values);
    filters.current = budgetFilters;

    searchBudgets(budgetFilters);
  };

  const formatFilters = (values: FiltersValues): ParamsFilter => {
    const { order_by: orderBy } = values;

    const orderedBy = orderBy ? orderBy.split("-")[1] : "";

    const filtersParam = {
      ...values,
      search: values.search,
      start_date: values.start_date?.format("YYYY-MM-DD") || "",
      end_date: values.end_date?.format("YYYY-MM-DD") || "",
      order_by: orderedBy,
    };

    return { ...createObjParamsTeste(filtersParam) };
  };

  const handleReset = (formik: any) => {
    formik.resetForm({ values: initialValues });

    const resetForm = {
      ...initialValues,
      start_date: "",
      end_date: "",
      order_type: "",
    };

    filters.current = resetForm;
    searchBudgets(resetForm);
  };

  function handleDatesChange(value: any, formik: any) {
    if (value.startDate) formik.setFieldValue("start_date", value.startDate);
    if (value.endDate) formik.setFieldValue("end_date", value.endDate);
  }

  const handleChangelistOrder = (
    event: React.ChangeEvent<HTMLInputElement>,
    setField: SetField
  ) => {
    const { value } = event.target;
    const [order_type] = value.split("-");

    const { start_date, end_date } = filters.current;

    const newFilters = {
      ...filters.current,
      order_by: value,
      order_type,
    };

    filters.current = newFilters;

    setField("order_by", value);

    const budgetFilters = formatFilters({
      ...newFilters,
      start_date: start_date ? moment(start_date, "YYYY-MM-DD") : null,
      end_date: end_date ? moment(end_date, "YYYY-MM-DD") : null,
    });

    searchBudgets(budgetFilters);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmitSearch}
    >
      {(formik) => {
        const { handleChange, submitForm, setFieldValue } = formik;

        return (
          <StyledForm className="mb-1">
            <div className="row">
              <div className="lg-2 mt-1">
                <Field
                  placeholder="Busque pelo código"
                  name="search"
                  id="search"
                  type="text"
                  value={formik.values.search}
                  component={Input}
                  onChange={handleChange}
                />
              </div>

              <Box
                className="filter-text"
                display="flex"
                mt="16"
                ml="20"
                mr="20"
              >
                <span>Filtrar por:</span>
              </Box>

              <div className="lg-3 mt-1">
                <InputDateRange
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  startDateId="start"
                  endDateId="end"
                  onDatesChange={(value: any) =>
                    handleDatesChange(value, formik)
                  }
                  openInModal={false}
                  dates={{
                    startDate: formik.values.start_date,
                    endDate: formik.values.end_date,
                  }}
                  blockPastChanges={false}
                  withClear={false}
                  error={undefined}
                  small={undefined}
                  purple={undefined}
                  label={undefined}
                />
              </div>
              <div className="lg-1 mt-1">
                <Field
                  placeholder="Status"
                  name="status_id"
                  options={status}
                  readonly={loadingCombos}
                  optionName="status"
                  component={SelectControlled}
                />
              </div>
              <div className="lg-1 mt-1">
                <Field
                  placeholder="Canal"
                  name="channel_id"
                  options={channels}
                  readonly={loadingCombos}
                  optionName="status"
                  component={SelectControlled}
                />
              </div>

              <Box
                className="buttons-container"
                display="flex"
                mt="16"
                ml="20"
                gap="10"
              >
                <Button
                  type="button"
                  onClick={() => handleReset(formik)}
                  outlined
                  borderRadius="111"
                >
                  Limpar
                </Button>
                <Button borderRadius="111" onClick={submitForm}>
                  Filtrar
                </Button>
              </Box>
              <Box mt="16" ml="auto" className="order-by">
                <Field
                  component={Select}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangelistOrder(e, setFieldValue)
                  }
                  id="order_by"
                  value={formik.values.order_by}
                  options={order_by}
                  valueKey="id"
                  labelKey="name"
                  placeholder="ordenar por"
                />
              </Box>
            </div>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
