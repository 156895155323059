import React, { useState, useEffect } from "react";

import { Form, Field, Formik } from "formik";
import {
  FormDocument,
  DependentEditModalInterface,
  DependentCountriesInterface,
  DependentLabelsInterface,
} from "Pages/RegistrationCleaning/interface";
import {
  TextModal,
  ContainerRegisterForm,
} from "Pages/RegistrationCleaning/styles";

import { Combos } from "Requests/Person";

import { Loading } from "Utils/Loading";
import { createToast } from "Utils/toastFunc";

import InputControlled from "Components/Form/Controlled/Input";
import SelectControlled from "Components/Form/Controlled/SelectControlled";




import { schema, schema_without_document } from "./schema";

export const DependentEdit = ({
  personData,
  withoutDocument,
  handleSubmit,
  handleDismiss,
}: DependentEditModalInterface) => {
  const [labels, setLabels] = useState<DependentLabelsInterface[]>([]);
  const [countries, setCountries] = useState<DependentCountriesInterface[]>([]);
  const [formDocuments, setFormDocuments] = useState<FormDocument>({
    label_id: "",
    type_document: "cpf",
    cpf: "",
    passport: "",
    passport_issuing_country: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchLabels = async () => {
    setIsLoading(true);
    try {
      const { family_relationships, countries } = await Combos({
        combos: ["family_relationships", "countries"],
      });
      setLabels(
        family_relationships?.map((label: DependentLabelsInterface) => ({
          ...label,
          name: label.label,
        }))
      );

      setCountries(countries);
    } catch (error) {
      createToast(
        "error",
        "Ocorreu um erro ao solicitar combos!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  const handleSubmitForm = (formData: FormDocument) => {
    if (personData && handleSubmit) {
      const {
        passport_issuing_country,
        cpf,
        passport,
        label_id,
        type_document,
      } = formData;

      personData.documents = [
        {
          ...(personData?.documents && personData.documents[0]),
          type: type_document,
          number: type_document === "cpf" ? cpf : passport,
          country_id:
            type_document === "passport" ? passport_issuing_country : null,
        },
      ];

      !cpf && !passport && delete personData.documents;

      const dependent = {
        ...personData,
        label_id,
      };
      handleSubmit(dependent);
    }
  };

  return (
    <div>
      <TextModal fontWeight={400}>
        Para atribuir esta pessoa como dependente, é necessário a atualização do
        {withoutDocument ? " vínculo correto" : " documento correto"}:
      </TextModal>

      <Formik
        initialValues={formDocuments}
        validationSchema={withoutDocument ? schema_without_document : schema}
        validateOnBlur={false}
        enableReinitialize
        validateOnChange={false}
        onSubmit={handleSubmitForm}
      >
        {({ values }) => {
          return (
            <ContainerRegisterForm>
              {isLoading ? (
                <Loading />
              ) : (
                <Form>
                  <div className="form">
                    <Field
                      label="Vínculo com responsável"
                      placeholder="Selecione aqui"
                      name="label_id"
                      options={labels}
                      optionName="label"
                      component={SelectControlled}
                    />
                    {!withoutDocument && (
                      <>
                        <Field
                          label="documento"
                          name="type_document"
                          placeholder="Selecione o tipo de documento"
                          options={[
                            { name: "CPF", id: "cpf" },
                            { name: "Passaporte", id: "passport" },
                          ]}
                          component={SelectControlled}
                        />
                        {values.type_document === "cpf" ? (
                          <Field
                            placeholder="Insira o documento"
                            mask="999.999.999-99"
                            name="cpf"
                            type="text"
                            value={values.cpf || ""}
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                        ) : (
                          values.type_document === "passport" && (
                            <>
                              <Field
                                placeholder="Insira o documento"
                                name="passport"
                                type="text"
                                component={InputControlled}
                                className="input _opacity-gray"
                              />
                              <Field
                                label="País emissor"
                                placeholder="No caso de passaporte, selecione o país"
                                name="passport_issuing_country"
                                optionName="name"
                                options={countries}
                                component={SelectControlled}
                              />
                            </>
                          )
                        )}
                      </>
                    )}

                    <div
                      className="flex-buttons-modal-information"
                      style={{ marginTop: 30 }}
                    >
                      <div className="container-button-information">
                        <button
                          className="button _white _transparentBorderButton"
                          onClick={() => handleDismiss()}
                        >
                          Voltar
                        </button>
                      </div>
                      <div className="container-button-information">
                        <button className="button _action" type="submit">
                          atualizar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </ContainerRegisterForm>
          );
        }}
      </Formik>
    </div>
  );
};
