import { useState, MouseEvent } from "react";


import { TooltipInformationProps } from "./interface";
import { Container, Content, OverLay } from "./styles";
import { TooltipIcon } from "./TooltipIcon";

function TooltipInformationSmart({
  message,
  right,
  left,
  iconColor = "#AB92BF",
}: TooltipInformationProps) {
  const [showContent, setShowContent] = useState(false);

  const handlerShowContent = (e: MouseEvent<SVGSVGElement>) => {
    setShowContent(!showContent);
    e.stopPropagation();
  };

  const handlerCloseContent = (e: MouseEvent<HTMLElement>) => {
    setShowContent(false);
    e.stopPropagation();
  };

  return (
    <>
      {showContent && (
        <OverLay
          onClick={(e: MouseEvent<HTMLElement>) => handlerCloseContent(e)}
        />
      )}
      <Container>
        <TooltipIcon
          iconColor={iconColor}
          onClick={(e: MouseEvent<SVGSVGElement>) => {
            handlerShowContent(e);
          }}
        />
        {showContent && (
          <>
            <div
              className={`indication ${right ? "indication-right-side" : ""} ${
                left ? "indication-left-side" : ""
              } `}
            />

            <Content
              className={`${right ? "right-side" : ""} ${
                left ? "left-side" : ""
              }`}
            >
              {message}
            </Content>
          </>
        )}
      </Container>
    </>
  );
}

export default TooltipInformationSmart;
