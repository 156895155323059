import styled from "styled-components";

export const ContainerScheduleTimer = styled.div`
  background-color: rgba(171, 146, 191, 0.15);
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 32px;
  max-width: 666px;
  padding: 20px 16px;

  @media screen and (min-width: 768px) {
    align-items: center;
    border-radius: 24px;
    margin-bottom: 32px;
    padding: 24px;
  }
`;

export const TimerDisplay = styled.div`
  color: var(--color-purple);
  font-family: var(--font-display);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  min-width: 50.5px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

export const TimerDescription = styled.p`
  color: var(--color-dark-ltgray);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 17px;
  margin-bottom: 0;
`;
