import styled from "styled-components";

export const SelectTitle = styled.h2`
  color: var(--color-purple);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
`;

export const Container = styled.div`
  &.gray-background {
    background: #f5f5f5;
  }

  &.card-search-information {
    padding-right: 20px;
    padding-left: 20px;
    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0;
  }
`;

export const TitleCheckoutCollect = styled.h2`
  display: flex;
  flex-direction: column;

  text-align: center;
  color: var(--color-purple);

  font-size: 1.45rem;
  line-height: 2rem;
  margin: 0 0px 32px;

  @media screen and (min-width: 768px) {
    text-align: left;
    flex-direction: row;
    margin-left: 0;
  }
`;

export const CollectCards = styled.div`
  margin-bottom: 24px;
  .tooltip {
    div:first-child {
      min-width: 250px;
    }
  }
`;