import React from "react";

import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useCart } from "Hooks/useCart";

import { formatBRLCurrency } from "Utils/FormatCurrency";

import { Button } from "Components/Button";

import { UnitItemProps } from "./interface";
import { Container } from "./style";

export const UnitItem = ({ unitInfo }: UnitItemProps) => {
  const { linkBudgetUnit } = useCart();
  const { budget } = useBudgetContext();

  const {
    address,
    name,
    total_price,
    region_name,
    covered_products_type,
    id: unitId,
  } = unitInfo;

  const formattedValue = formatBRLCurrency(total_price, true);
  const indexCep = address.indexOf("CEP");
  const addressWithoutCep = address.substring(0, indexCep);
  const addressWithCep = address.substring(indexCep);

  const coveredType = (type: string) => {
    switch (type) {
      case "uncovered": {
        return `Não Coberto - <br/> ${formattedValue} em até 12x`;
      }
      case "partial": {
        return `Cobertura Parcial - <br/> ${formattedValue} em até 12x`;
      }
      default: {
        return "Cobertura Total";
      }
    }
  };

  const selectUnit = () => {
    if (budget) linkBudgetUnit(unitId);
  };

  return (
    <Container>
      <td width={30}>{region_name}</td>
      <td>{name}</td>
      <td>
        {addressWithoutCep} <br /> {addressWithCep}
      </td>
      <td
        dangerouslySetInnerHTML={{ __html: coveredType(covered_products_type) }}
      />
      <td>
        <Box display="flex" gap="8" justify="center">
          <Button onClick={selectUnit}>Selecionar</Button>
        </Box>
      </td>
    </Container>
  );
};
