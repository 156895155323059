import { combineReducers } from "redux";

import Menu from "./MenuReducer";
import PersonDuplicatedFlow from "./PersonDuplicatedFlowReducer";
import PersonDuplicate from "./PersonDuplicateReducer";
import ServiceForm from "./ServiceForm";
import User from "./UserReducer";

export default combineReducers({
  User,
  Menu,
  service_form: ServiceForm,
  PersonDuplicate,
  PersonDuplicatedFlow,
});
