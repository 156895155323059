import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import Checkbox from "Components/Form/Checkbox";
import Input from "Components/Form/Input";

import acceptiIcon from "Assets/Images/acceptIcon.svg";

Modal.setAppElement("#root");

function CourtesyVoucherModal({
  modalVisible,
  modalDismiss,
  budget,
  saveProductCourtesy,
}) {
  const [formData, setFormData] = useState({
    voucher: "",
  });
  const [errorVoucher, setErrorVoucher] = useState("");

  const [checkBoxs, setCheckBoxs] = React.useState({});
  const [allSelect, setAllSelect] = React.useState(false);

  const { service_form } = useSelector((store) => store);

  function closeModal() {
    modalDismiss();
  }

  const checkIfAllProductsHasVoucher = budget?.every((item) => item.voucher);

  const handleChangeVoucher = ({ target: { value, id } }) => {
    setErrorVoucher("");
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleChangeSelectedProduct = (value, item) => {
    setCheckBoxs((prev) => ({
      ...prev,
      [item.id]: {
        value:
          prev[item.id] && Object.keys(prev[item.id]).length
            ? !prev[item.id].value
            : value,
      },
    }));
  };

  const handleGetProducts = () => {
    const products = [];
    Object.keys(checkBoxs).forEach((id) => {
      if (!checkBoxs[id]?.value) return;

      const product = budget?.find((item) => item.id === Number(id));
      if (product)
        products.push({
          product_id: product?.product_id,
          amount: product?.amount,
          home_collected: product?.collect_type === "home_collect",
        });
    });

    return products;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!formData.voucher)
      return setErrorVoucher("O campo motivo é obrigatório");

    const products = handleGetProducts();
    saveProductCourtesy(formData, products);
    closeModal();
  };

  const formatterCheckBoxs = (value) => {
    let items = {};

    budget?.cart?.forEach((item) => {
      items = {
        ...items,
        [item.id]: {
          value,
        },
      };
    });

    setCheckBoxs(items);
  };

  useEffect(() => {
    budget && formatterCheckBoxs(allSelect);
  }, [allSelect]);

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        bodyOpenClassName="modal-content-open"
        className="modal-courtesy"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
      >
        {checkIfAllProductsHasVoucher ? (
          <div className="no-courtesy-products">
            <img src={acceptiIcon} alt="accept" />
            <p>Todos os produtos do carrinho já receberam cortesia.</p>
            <button className="button" onClick={modalDismiss}>
              fechar
            </button>
          </div>
        ) : (
          <form className="modal-courtesy-details" onSubmit={handleSubmitForm}>
            <div className="modal-courtesy-body">
              <div className="courtesy-header">
                <h5 className="title-courtesy">
                  Quais produtos você gostaria de <br />
                  bonificar no orçamento {service_form.order.id} ?
                </h5>

                <p className="subtitle-courtesy">
                  Os produtos selecionados receberão 100% de desconto no seu
                  valor.
                </p>
              </div>

              <div className="checkbox-courtesy">
                {budget?.map(
                  (item) =>
                    !item?.voucher && (
                      <div key={item.id}>
                        <Checkbox
                          small
                          descriptionText={`${item.product_code} | ${item.product_name}`}
                          id={item.id}
                          value={
                            checkBoxs[item.id]
                              ? checkBoxs[item.id].value
                              : false
                          }
                          onChange={(value) =>
                            handleChangeSelectedProduct(value, item)
                          }
                        />
                      </div>
                    )
                )}
              </div>

              <div className="courtesy-reason">
                <p>Motivo/origem da cortesia:</p>
                <Input
                  type="text"
                  id="voucher"
                  placeholder="Motivo da cortesia"
                  value={formData.voucher}
                  error={errorVoucher}
                  onChange={handleChangeVoucher}
                />
              </div>

              <div className="actions-buttons-courtesy">
                <button className="button _white" onClick={modalDismiss}>
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button"
                  disabled={errorVoucher}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
}

export default CourtesyVoucherModal;
