import API from "Services/Api";

import { FormatProductToDisplayList } from "Requests/Formatters/Product";

import { createFilterParams } from "Utils/searchFilters";

export const getProducts = async ({
  page = 1,
  filters = {},
  searchBudget = true,
}) => {
  try {
    const response = await API.get(
      `/products?search_budget=${searchBudget}&page=${page}&channel_id=2`,
      {
        params: createFilterParams(filters),
      }
    );

    const dataArrFormatted = FormatProductToDisplayList(response.data.data);

    const responseFormatted = {
      pagination: response.data.meta.pagination,
      data: dataArrFormatted,
    };

    return responseFormatted;
  } catch (error) {
    throw new Error(
      "Não foi possível obter a listagem de produtos. Ocorreu um erro no servidor. Atualize a página e tente novamente"
    );
  }
};

export const getProduct = async (id) => {
  try {
    const response = await API.get(`/products/${id}`);

    return response.data;
  } catch (error) {
    throw new Error(
      "Ocorreu um erro na requisição para obter os dados do produtos desejado. Tente novamente"
    );
  }
};

export const createProduct = async (data) => {
  try {
    const response = await API.post("/products", data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateProduct = async (productId, data) => {
  try {
    const response = await API.put(`/products/${productId}`, data);

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const GetComboInProducts = async () => {
  try {
    const response = await API.get(`products/resource/combo`);

    return response.data;
  } catch (error) {
    return {
      product_category: {
        data: [],
      },
    };
  }
};

export const GetPartners = async () => {
  try {
    const response = await API.get("/partners");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
