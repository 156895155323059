import React from "react";
import { Link } from "react-router-dom";

const BreadcrumbItems = ({ list }) => {
  return list?.map((item, index) => {
    if (item.label === "") return null;

    return (
      <li key={index} className={item.current ? "current" : null}>
        <Link to={item.path}>{item.label}</Link>
      </li>
    );
  });
};

const Breadcrumb = (props) => (
  <ul className="breadcrumbs">
    <BreadcrumbItems list={props?.list} />
  </ul>
);

export default Breadcrumb;
