import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { getToken } from "Services/Authentication";

import {
  budgetToMotion,
  cancelActiveBudget,
  DeleteBudgetByID,
  duplicateBudget,
  SendBudgetEmail,
} from "Requests/Budget";

import { selectPacient } from "Actions/ServiceForm";

import { handleRequestErrors } from "Utils/Errors";
import { createToast } from "Utils/toastFunc";
import { validatorEmail } from "Utils/Validator";

import { BudgetModals } from "Components/Modals/BudgetActionsModals/interface";

import { formatClient } from "./utils";

export const useBudgetCartModals = () => {
  const [modalOpen, setModalOpen] = useState({
    budgetSuccess: false,
    motionBudget: false,
    motionBudgetSuccess: false,
    cancelBudget: false,
    budgetCanceledSuccess: false,
    editBudget: false,
    resendEmail: false,
    motionCovenant: false,
  });

  const [loading, setLoading] = useState(false);

  const [loadingPdf, setLoadingPdf] = useState(false);

  const [budget, setBudget] = useState<BudgetModals | null>(null);

  const history = useHistory();

  const emailToSend = useRef("");

  const dispatch = useDispatch();

  const setEmail = (email: string) => {
    emailToSend.current = email;
  };

  const onOpenModal = (modal: string) => {
    const modalsOpts = {
      budgetSuccess: false,
      motionBudget: false,
      motionBudgetSuccess: false,
      cancelBudget: false,
      budgetCanceledSuccess: false,
      editBudget: false,
      resendEmail: false,
      motionCovenant: false,
    };

    modalsOpts[modal as keyof typeof modalsOpts] = true;

    setModalOpen(modalsOpts);
  };

  const onCloseModal = (modal: string) => {
    setModalOpen((prev) => ({ ...prev, [modal]: false }));
  };

  const setBudgetModals = (currentBudget: BudgetModals) => {
    setBudget(currentBudget);
  };

  const handlePdfDownload = async (budgetId?: number) => {
    if (budgetId) {
      setLoadingPdf(true);
      const url = `${
        process.env.REACT_APP_API_URL
      }/budgets/${budgetId}/pdf?token=${getToken()}`;

      const response = await fetch(url);

      if (response.status === 200) {
        const blob = await response.blob();

        const createdUrl = URL.createObjectURL(blob);

        const element = document.createElement("a");
        element.href = createdUrl;
        element.download = `${budgetId}`;
        element.click();

        URL.revokeObjectURL(createdUrl);

        setLoadingPdf(false);
        createToast("success", "Download concluído");

        return;
      }

      createToast("error", "Não foi possível baixar o pdf do orçamento");
      return;
    }

    createToast("error", "ID do orçamento não foi informado");
  };

  const sendEmailBudget = async (emailParam: string, budgetId?: number) => {
    setLoading(true);
    try {
      if (!budgetId) {
        createToast("error", "ID do orçamento não foi informado");

        return;
      }

      const emailBudget = emailParam;

      if (!emailBudget || emailBudget === "" || !validatorEmail(emailBudget)) {
        createToast(
          "error",
          "O email é obrigatório e precisa ser um email válido!"
        );

        return;
      }

      const emailParams = {
        budget_id: budgetId,
        email: emailBudget,
      };

      await SendBudgetEmail(emailParams);
      createToast("success", "Email enviado com sucesso");
    } catch (error) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível enviar o orçamento por e-mail.  ",
      });
    } finally {
      setLoading(false);
    }
  };

  const motionIntegration = async (
    budget_id: number,
    unit_id: number
  ): Promise<number> => {
    setLoading(true);
    try {
      const motionObj = {
        budget_id,
        unit_id,
      };
      const response = await budgetToMotion(motionObj);

      createToast("success", "Pedido integrado com sucesso.");
      setModalOpen((prev) => ({
        ...prev,
        motionBudget: false,
        motionBudgetSuccess: true,
      }));

      return response.status;
    } catch (error: any) {
      if ([500, 502, 504].includes(error?.response?.status)) {
        createToast(
          "error",
          "Não foi possível realizar a integração com motion. Ocorreu um erro no servidor."
        );
        return error.response.status;
      }

      if (error?.response?.data?.message) {
        createToast("error", error.response.data.message);
        return error.response.status;
      }

      if (error?.response?.data[0]?.errors[0]?.message) {
        createToast("error", error.response.data[0].errors[0].message);
        return error.response.status;
      }

      if (error?.response?.data[0]?.errors[0]) {
        createToast("error", error.response.data[0].errors[0]);
        return error.response.status;
      }
      createToast("error", "Não foi possível integrar o pedido.");
      return error.response.status;
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteBudget = async () => {
    setLoading(true);

    try {
      if (budget) {
        await DeleteBudgetByID(budget?.id);
        createToast("success", "Orçamento deletado com sucesso!");
      }
    } catch (error: any) {
      createToast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelBudget = async () => {
    setLoading(true);

    try {
      if (budget) {
        await cancelActiveBudget(budget?.id);
        createToast("success", "Orçamento cancelado com sucesso!");
      }
    } catch (error: any) {
      createToast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const duplicateBudgetInfo = async () => {
    try {
      if (budget) {
        setLoading(true);
        const { id, is_covenant, lead, patient } = await duplicateBudget(
          budget.id
        );

        const client = formatClient(patient, lead);

        dispatch(selectPacient(client));

        const client_type = lead ? "lead" : "person";
        const budget_type = is_covenant ? "covenant" : "private";

        history.push(
          `/ficha-de-atendimento/${client_type}/selecionar-produtos/${id}?btype=${budget_type}`
        );
      }
    } catch (error) {
      createToast("error", "Não foi possível duplicar este orçamento");
    } finally {
      setLoading(false);
    }
  };

  const redirectToCreateNewBudget = () => {
    history.push("/ficha-de-atendimento/paciente");
  };

  const redirectToBudgetList = () => {
    if (budget?.is_covenant) return history.push("/orcamentos/convenio");

    history.push("/orcamentos/particular");
  };

  return {
    setBudgetModals,
    handlePdfDownload,
    motionIntegration,
    onCloseModal,
    onOpenModal,
    sendEmailBudget,
    modalOpen,
    budget,
    email: emailToSend.current,
    loading,
    setEmail,
    confirmDeleteBudget,
    cancelBudget,
    redirectToCreateNewBudget,
    duplicateBudgetInfo,
    redirectToBudgetList,
    loadingPdf,
  };
};
