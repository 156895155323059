import React from "react";

import {
  TextModal,
  ClientViewContainer,
} from "Pages/RegistrationCleaning/styles";

import AcceptIcon from "Assets/Images/acceptIconGreen.svg";
import RejectedIcon from "Assets/Images/rejectedIcon.svg";

export const ContinueWithoutUnifying = () => {
  return (
    <>
      <TextModal fontWeight={400}>
        Você tem certeza que não deseja unificar os outros cadastros? Caso os
        nomes com requisições sejam apagados, o cliente terá as seguintes
        visualizações:
      </TextModal>

      <ClientViewContainer>
        <span>acesso</span>
        <span>disponibilidade</span>

        <p>Acessar o laudo avulso (protocolo e senha)</p>
        <img src={AcceptIcon} alt="icone" />

        <p>Visualizar histórico de pedidos</p>
        <img src={RejectedIcon} alt="icone" />

        <p>Visualiza histórico de resultados no site</p>
        <img src={RejectedIcon} alt="icone" />
      </ClientViewContainer>
    </>
  );
};
