import React, { useEffect, useState } from "react";

import { GetInstallments } from "Requests/Installments";

import FormatCurrency from "Utils/FormatCurrency";

import { InstallmentsContainer, InstallmentLine } from "./style";


export const InstallmentsSelect = ({
  price,
  selectedInstallment,
  handleSavePlotSelected,
}) => {
  const [installments, setInstallments] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await GetInstallments(price);
      setInstallments(data);
    })();
  }, [price]);

  return (
    <InstallmentsContainer>
      {installments.map((installment, index) => (
        <InstallmentLine
          onClick={() => handleSavePlotSelected(installment)}
          selected={
            selectedInstallment.number_installments ===
            installment.number_installments
          }
        >
          <div>{installment.number_installments}x</div>
          <div className="additional-value">
            R$ {FormatCurrency(installment.installments[0].value)}
          </div>
          <div>{index > 2 ? "Com juros" : "Sem juros"}</div>
          <div>R$ {FormatCurrency(installment.total)}</div>
        </InstallmentLine>
      ))}
    </InstallmentsContainer>
  );
};
