import React, { useState } from "react";

import { PanelContentProps } from "./interface";

const PanelContent = ({
	children,
	loading,
	title,
	error,
	errorMessage,
	classNames,
	expandable,
	disableTitle = false
}: PanelContentProps) => {
	const [expanded, setExpanded] = useState(!expandable)

	return (
		<div
			className={`
				panel panel-content
				${loading ? "panel-loading" : ""}
				${error ? "panel-error" : ""}
				${expandable && expanded ? "panel-opened" : ""}
				${classNames || ''}
      `}
		>
			{!disableTitle ? (
				<div className="panel-heading" onClick={() => expandable ? setExpanded(!expanded) : null }>
					<h5 className="panel-title">{title}</h5>
					<div>
						{errorMessage !== "" && (
							<span className="text-white small text-display">
								{errorMessage}
							</span>
						)}
						{expandable && (
							<i className="arrow-down" />
						)}
					</div>
				</div>
			) : (
				""
			)}

			<div className={`panel-body ${expandable ? expanded ? "open" : "close" : ""}`}>{children}</div>
		</div>
	);
};

export default PanelContent;
