import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ListTableInterface } from "Pages/PersonDuplicated/interface";
import { ActionButton } from "Pages/PersonDuplicated/styles";
import ShowLoading from "Pages/RegistrationCleaning/components/ShowLoading";


import { selectPersonDuplicatedFlow } from "Actions/DuplicatePerson";

export const ListTable = ({ data, loading }: ListTableInterface) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePreview = (document: string) => {
    dispatch(
      selectPersonDuplicatedFlow({
        document,
      })
    );
  };

  const handleAdjustment = (document: string) => {
    history.push(`/limpeza-cadastro/${document}/merge`);
  };
  return (
    <table className="table-content small-separate details">
      <thead>
        <tr>
          <th>CPF</th>
          <th>Paciente Original</th>
          <th>Nome Social</th>
          <th>Telefone Principal</th>
          <th>Email Principal</th>
          <th>
            Quantidade <br /> Duplicados
          </th>
          <th style={{ textAlign: "center" }}>Ação</th>
          <th style={{ textAlign: "center" }}>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <ShowLoading />
        ) : !data?.length ? (
          <tr>
            <td colSpan={9} className="text-center text-purple">
              Oops! Parece que nada foi encontrado...
            </td>
          </tr>
        ) : (
          data.map((item) => (
            <tr key={item.person_id}>
              <td>{item?.document} </td>
              <td>{item?.person?.full_name} </td>
              <td>
                {item?.person?.social_name
                  ? item?.person?.social_name
                  : "Nenhum encontrado"}{" "}
              </td>
              <td>
                {item?.person?.default_phone?.ddd
                  ? `+${item?.person?.default_phone?.ddi} (${item?.person?.default_phone?.ddd}) ${item?.person?.default_phone?.phone}`
                  : "Nenhum encontrado"}{" "}
              </td>
              <td>{item?.person?.default_email?.email} </td>
              <td>{item?.persons}</td>
              <td>
                <ActionButton
                  className="filled"
                  onClick={() => handleAdjustment(item?.document)}
                  style={{ margin: "0 auto" }}
                >
                  Ajustar
                </ActionButton>
              </td>
              <td>
                <ActionButton
                  onClick={() => handlePreview(item?.document)}
                  style={{ margin: "0 auto" }}
                >
                  Visualizar
                </ActionButton>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};
