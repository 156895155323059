import React from "react";

import { BudgetUnit } from "Dtos/IUnit";

import { UnitItem } from "../UnitItem";
import { UnitListContainer } from "./styles";

interface UnitsListProps {
  unitsList: BudgetUnit[];
}

export const UnitsList = ({ unitsList }: UnitsListProps) => {
  return (
    <UnitListContainer className="products-results">
      <table>
        <thead>
          <tr>
            <th>REGIÃO</th>
            <th>UNIDADE</th>
            <th>ENDEREÇOS</th>
            <th>VALOR ORÇAMENTO FINAL</th>
            <th>AÇÕES</th>
          </tr>
        </thead>

        <tbody>
          {unitsList.map((unitList) => (
            <UnitItem unitInfo={unitList} />
          ))}
        </tbody>
      </table>
    </UnitListContainer>
  );
};
