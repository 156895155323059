import styled from "styled-components";


// export const ContainerUnityCheckout = styled.div<
//   Omit<UnityCardProps, "handleSelectUnity" | "activeUnities">
// >`
//   position: relative;
//   background: ${(props) => (props?.active ? "#F5F5F5" : "#FFFFFF")};
//   box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
//     0px 4px 6px -2px rgba(130, 136, 148, 0.32);
//   border-radius: 16px;
//   width: 100%;
//   display: flex;
//   cursor: pointer;

//   p {
//     margin: 0;
//     padding: 0;
//   }

//   .tooltip {
//     font-size: 13px;
//   }

//   button {
//     background-color: ${(props) =>
//       props?.active ? "#F5F5F5 !important" : "#FFFFFF"};
//     border: none;
//   }
// `;

// export const SelectUnity = styled.div<
//   Omit<UnityCardProps, "handleSelectUnity" | "activeUnities">
// >`
//   background: ${(props) => (props?.active ? "#ab92bf" : "")};
//   width: 12px;
//   max-height: 135px;
//   border-top-left-radius: 16px;
//   border-bottom-left-radius: 16px;
// `;

export const CenterUnityCheckout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 197px;
  width: 100%;

  p {
    margin: 0;
    padding: 0;

    font-family: var(--font-text);
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--color-gray-text);
  }

  @media (min-width: 520px) {
    max-width: 358px;
  }
`;

export const TitleUnityCheckout = styled.strong`
  font-family: var(--font-display);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: var(--color-purple);
  margin-bottom: 4px;

  span {
    font-size: 10px;
    line-height: 16px;
    color: var(--color-pink);
    background-color: #e8d8f5;
    border-radius: 5px;
    padding: 4px 8px 2px;
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    span {
      font-size: 12px;
    }
  }
`;

export const FlexItemsCheckout = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ExamsInUnity = styled.div`


  strong {
    font-weight: bold;
  }

  p {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`;

export const TitleExamsUnity = styled.p`
  font-family: var(--font-text);
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--color-gray-text);
`;
