import FormatCurrency from "Utils/FormatCurrency";

function isOneOfFieldsRequireds(fieldName) {
  switch (fieldName) {
    case "name":
    case "code":
    case "mt_code":
    case "regular_price":
    case "category_id":
    case "materials":
    case "dosage":
    case "dose_amount":
    case "imuneweb_code":
    case "indication":
    case "protection":
    case "type_of_application":
      return true;

    default:
      return false;
  }
}

function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.id == current.id;
      }).length == 0
    );
  };
}

function FormatArrayFieldsToSubmit(
  productData,
  key,
  primaryKey,
  primaryNotID = false
) {
  let items = [];

  const originalArr = productData[`${key}_original`];
  const product_value = productData[key].value || [];

  if (originalArr) {
    const deletedItemsFiltered = originalArr.filter(comparer(product_value));

    const deletedItems = originalArr.map(({ id }) => {
      const deletedItem = deletedItemsFiltered.find((item) => item.id === id);

      if (deletedItem) {
        return {
          id: deletedItem.id,
          [primaryKey]: "",
        };
      }

      return null;
    });

    const newItems = product_value.map((item) => {
      return {
        id: item.isUpdated && item.id,
        [primaryKey]: primaryNotID
          ? item[primaryKey]
          : item.isUpdated
          ? item[primaryKey]
          : item.id,
      };
    });

    items = deletedItems.concat(newItems).filter((item) => item != null);
  } else {
    items = product_value.map((item) => {
      return {
        id: item.isUpdated && item.id,
        [primaryKey]: primaryNotID
          ? item[primaryKey]
          : item.isUpdated
          ? item[primaryKey]
          : item.id,
      };
    });
  }

  return items;
}

function FormatProductToDisplayList(inputArr) {
  return inputArr.map((product) => {
    return {
      ...product,
      price: FormatCurrency(product.regular_price),
      promoPrice:
        product.sales_price && product.sales_price !== "0.00"
          ? FormatCurrency(product.sales_price)
          : null,
    };
  });
}

function FormatProductDataToDisplay(productObj) {
  return {
    ...productObj,
    price: FormatCurrency(productObj.default_price.price),
  };
}

function FormatProductToForm(productApiData) {
  let _fields = {};

  Object.keys(productApiData).forEach((el) => {
    if (Array.isArray(productApiData[el])) {
      let array = [];

      array = productApiData[el].map((item) => ({
        ...item,
        isUpdated: true,
      }));

      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value: array,
        },
      };

      _fields = {
        ..._fields,
        [`${el}_original`]: array,
      };
    } else if (el === "product_category") {
      _fields = {
        ..._fields,
        category_id: {
          ..._fields.category_id,
          value:
            productApiData[el] && productApiData[el].id
              ? productApiData[el].id
              : productApiData[el] || "",
          error: "",
        },
      };
    } else if (el === "result_types" && !!productApiData[el]) {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value: productApiData[el]
            .split(",")
            .map((item, index) => ({ id: index, result_type: item })),
        },
      };
    } else if (el === "collect_type") {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          isRequired: !!isOneOfFieldsRequireds(el),
          value: productApiData[el] || "",
        },
      };
    } else if (el === "product_sub_category") {
      _fields = {
        ..._fields,
        sub_category_id: {
          ..._fields.sub_category_id,
          value:
            productApiData[el] && productApiData[el].id
              ? productApiData[el].id
              : productApiData[el] || "",
          error: "",
        },
      };
    } else if (el === "regular_price" || el === "sales_price") {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value:
            productApiData[el] && productApiData[el].id
              ? productApiData[el].id
              : parseFloat(productApiData[el]) ?? "",
          error: "",
        },
      };
    } else {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          isRequired: !!isOneOfFieldsRequireds(el),
          value:
            productApiData[el] && productApiData[el].id
              ? productApiData[el].id
              : productApiData[el],
          error: "",
        },
      };
    }
  });

  return _fields;
}

function FormatProductToFormVaccinesAndInfusion(objectDataApi) {
  let _fields = {};
  let _vaccineRelationship = {};

  Object.keys(objectDataApi).forEach((el) => {
    if (Array.isArray(objectDataApi[el])) {
      let array = [];
      array = objectDataApi[el].map((item) => ({
        ...item,
        isUpdated: true,
      }));

      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value: array,
        },
      };

      _fields = {
        ..._fields,
        [`${el}_original`]: array,
      };
    } else if (el === "product_category") {
      _fields = {
        ..._fields,
        category_id: {
          ..._fields.category_id,
          value:
            objectDataApi[el] && objectDataApi[el].id
              ? objectDataApi[el].id
              : objectDataApi[el] || "",
          error: "",
        },
      };
    } else if (el === "result_types" && !!objectDataApi[el]) {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value: objectDataApi[el]
            .split(",")
            .map((item, index) => ({ id: index, result_type: item })),
        },
      };
    } else if (el === "collect_type") {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          isRequired: !!isOneOfFieldsRequireds(el),
          value: objectDataApi[el] || "",
        },
      };
    } else if (el === "product_sub_category") {
      _fields = {
        ..._fields,
        sub_category_id: {
          ..._fields.sub_category_id,
          value:
            objectDataApi[el] && objectDataApi[el].id
              ? objectDataApi[el].id
              : objectDataApi[el] || "",
          error: "",
        },
      };
    } else if (el === "regular_price" || el === "sales_price") {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value:
            objectDataApi[el] && objectDataApi[el].id
              ? objectDataApi[el].id
              : parseFloat(objectDataApi[el]) || "",
          error: "",
        },
      };
    } else if (el !== "vaccine") {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          isRequired: !!isOneOfFieldsRequireds(el),
          value:
            objectDataApi[el] && objectDataApi[el].id
              ? objectDataApi[el].id
              : objectDataApi[el],
          error: "",
        },
      };
    }
  });

  const vaccineObject = objectDataApi.vaccine || objectDataApi.infusion;
  if (vaccineObject) {
    Object.keys(vaccineObject).forEach((index) => {
      _vaccineRelationship = {
        ..._vaccineRelationship,
        [index]: {
          isRequired: !!isOneOfFieldsRequireds(index),
          value: vaccineObject[index],
          error: "",
        },
      };
    });
  }

  if (_fields.indication) delete _fields.indication;

  _fields = { fields: _fields, vaccine: _vaccineRelationship };
  return _fields;
}

function FormatProductToSubmit(productData, secodaryObjectData) {
  let submitForm;
  let newObjectData;
  Object.keys(productData).forEach((key) => {
    if (key === "result_eta") {
      submitForm = {
        ...submitForm,
        [key]: parseInt(productData[key].value),
      };
    } else if (key === "regular_price") {
      submitForm = {
        ...submitForm,
        [key]: productData[key].value,
      };
    } else if (key === "category_id" || key === "sub_category_id") {
      submitForm = {
        ...submitForm,
        [key]: parseInt(productData[key].value),
      };
    } else if (key === "dimension_result") {
      submitForm = {
        ...submitForm,
        [key]: productData[key].value,
      };
    } else if (key === "genders_permited") {
      const items = FormatArrayFieldsToSubmit(productData, key, "gender_id");

      submitForm = {
        ...submitForm,
        genders_permited: items,
      };
    } else if (key === "materials") {
      const items = FormatArrayFieldsToSubmit(productData, key, "id", true);
      const selected = items.map((item) => ({
        material_id: item?.id,
      }));
      submitForm = {
        ...submitForm,
        materials: selected,
      };
    } else if (key === "result_types") {
      const content_value =
        productData[key].value && productData[key].value.length;

      const items = content_value
        ? productData[key].value
            .map(({ result_type }) => {
              return result_type;
            })
            .join(",")
        : "";

      submitForm = {
        ...submitForm,
        result_type: items,
      };
    } else if (key === "product_channels") {
      const items = FormatArrayFieldsToSubmit(productData, key, "channel_id");

      submitForm = {
        ...submitForm,
        product_channels: items,
      };
    } else if (key === "exams" || key === "vaccines_package") {
      const items = FormatArrayFieldsToSubmit(
        productData,
        key,
        "id_product_children"
      );

      submitForm = {
        ...submitForm,
        [key]: items,
      };
    } else if (key === "units" && !productData.all_units.value) {
      const unitsMapped = productData[key]?.value?.map(({ id }) => ({ id }));
      const filterExistsId = unitsMapped?.filter((item) => item.id);

      submitForm = {
        ...submitForm,
        [key]: filterExistsId,
      };
    } else if (key === "units" && productData.all_units.value) {
      submitForm = {
        ...submitForm,
        [key]: [],
      };
    } else if (
      productData[key].value !== undefined &&
      !Array.isArray(productData[key].value)
    ) {
      submitForm = {
        ...submitForm,
        [key]: productData[key].value,
      };
    } else {
      submitForm = {
        ...submitForm,
        [key]: productData[key],
      };
    }
  });

  if (secodaryObjectData) {
    const newObject = secodaryObjectData;
    Object.keys(newObject).forEach((index) => {
      newObjectData = {
        ...newObjectData,
        [index]: newObject[index].value,
      };

      if (index in submitForm) {
        delete submitForm[index];
      }
    });

    submitForm = {
      ...submitForm,
      [secodaryObjectData.typeObjectData === "infusion"
        ? "infusion"
        : "vaccine"]: newObjectData,
    };
  }

  return submitForm;
}

export {
  FormatProductDataToDisplay,
  FormatProductToDisplayList,
  FormatProductToSubmit,
  FormatProductToForm,
  FormatProductToFormVaccinesAndInfusion,
};
