import React from "react";

import { TextModal } from "Pages/RegistrationCleaning/styles";

export const ConfirmSigleRegistration = () => {
  return (
    <TextModal fontWeight={400}>
      Existem outros cadastros{" "}
      <span style={{ fontWeight: 700 }}>
        com requisições, mesma data de nascimento
      </span>{" "}
      e <span style={{ fontWeight: 700 }}>mesmo gênero</span>, deseja
      unifica-los em um único cadastro?
    </TextModal>
  );
};
