import * as yup from "yup";

import { GENDERS } from "Constants/Combos";

import { isCPF } from "Services/schema/validations";

import { isValidDate, isFutureDate } from "Utils/Date";
import { isValidPhoneMask } from "Utils/Phone";
import { isWhitespaceOnly } from "Utils/String";

export const covenantSchema = yup.object({
  covenant: yup.number().nullable(true).required("Convênio é obrigatório"),
  plan: yup.number().nullable(true).required("Plano é obrigatório"),
  plan_card_number: yup.number().nullable(true),
});

export const covenantMotionSchema = covenantSchema.shape({
  plan_card_number: yup
    .number()
    .nullable(true)
    .required("Número da carteirinha é obrigatório"),
  front_card: yup.mixed().required("Upload obrigatório"),
  back_card: yup.mixed().required("Upload obrigatório"),
  medical_order: yup.mixed().required("Upload obrigatório"),
});

export const schemaRegister = covenantSchema.shape({
  name: yup.string().required("Nome obrigatório"),
  gender: yup.string().required("Gênero é obrigatório"),
  social_name: yup
    .string()
    .when("gender", {
      is: (gender: string) =>
        [GENDERS.trans, GENDERS.undefined].includes(gender),
      then: yup.string().required("Nome social obrigatório"),
      otherwise: yup.string(),
    })
    .test("social_name", "Nome inválido", isWhitespaceOnly),
  birthdate: yup
    .string()
    .required("Data de nascimento obrigatória")
    .test("birthdate", "Data inválida", (value) => isValidDate(value))
    .test("birthdate", "Data é maior que a atual", (value) =>
      isFutureDate(value)
    ),
  country: yup.string().when("doc_type", {
    is: (doctype: string) => doctype === "passport",
    then: yup.string().required("Campo obrigatório"),
    otherwise: yup.string().notRequired(),
  }),
  doc_type: yup.string().required("Informe o tipo de documento"),
  document: yup.string().when("doc_type", {
    is: (doc_type: string) => doc_type === "cpf",
    then: yup.string().test("validateCpf", "CPF inválido", isCPF),
    otherwise: yup.string().required("Documento é obrigatório"),
  }),
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
  phone: yup
    .string()
    .required("Telefone obrigatório")
    .test("phone", "Telefone inválido", (value) => isValidPhoneMask(value)),
});
