import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { Box } from "styles/globalComponents";

import { unitsBudgetAvaliable } from "Requests/Budget";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useCart } from "Hooks/useCart";

import { handleRequestErrors } from "Utils/Errors";

import { Button } from "Components/Button";
import { Loader } from "Components/Loader";
import { LoadingText } from "Components/LoadingText";
import { Modal } from "Components/Modals/Modal";
import { Pagination } from "Components/Pagination";
import { Heading } from "Components/ServiceForm/Heading";

import Attention from "Assets/Images/atention.svg";

import CartDetails from "../components/Cart/CartDetails";
import { PageBody, PageContainer, PageContent } from "../styles";
import { UnitsList } from "./components/UnitsList";
import { Container } from "./styles";

const UnitSelect = () => {
  const [unitsPage, setUnitsPage] = useState(1);
  const [loadingUnlinkUnit, setLoadingUnlinkUnit] = useState(false);

  const { budget_id } = useParams<{ budget_id: string }>();

  const { data, isLoading } = useQuery(
    ["budget-units", budget_id, unitsPage],
    () => unitsBudgetAvaliable(Number(budget_id), unitsPage),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error) =>
        handleRequestErrors({
          reqErrors: error,
          errorMessage: "Não foi possível obter a listagem de unidades.",
        }),
    }
  );
  const history = useHistory();

  const { resetBudgetInformation } = useBudgetContext();

  const { getBudget, unlinkBudgetUnit } = useCart();

  const goBackToCreateBudget = async () => {
    setLoadingUnlinkUnit(true);
    await unlinkBudgetUnit();
    setLoadingUnlinkUnit(false);
    history.goBack();
  };

  useEffect(() => {
    getBudget(+budget_id);
  }, []);

  useEffect(() => {
    return () => resetBudgetInformation();
  }, []);

  return (
    <PageContainer className="entry-app-content entry-budget">
      <PageContent className="content-holder page-budget">
        <Heading
          list={[
            {
              path: "/orcamentos",
              label: "Orçamento",
              current: false,
            },
            {
              path: "#",
              label: "Criar novo",
              current: false,
            },
            {
              path: "#",
              label: "Selecionar unidade",
              current: true,
            },
          ]}
          title="Orçamentos"
        />

        <PageBody className="cart-order-budget">
          <Container className="units-content card-search-information">
            {isLoading ? (
              <LoadingText text="Aguarde, estamos carregando as unidades disponíveis !" />
            ) : (
              <>
                {data ? (
                  <>
                    <Box mt="32">
                      <h2 className="heading-title">
                        Selecione a unidade para finalizar o orçamento
                      </h2>
                    </Box>

                    <Box mt="32">
                      {data?.data && data.data.length > 0 && (
                        <UnitsList unitsList={data.data} />
                      )}
                    </Box>

                    <Box mt="58">
                      <Pagination
                        pageLimit={1}
                        pageNeighbours={2}
                        totalRecords={data?.meta.pagination.total_pages}
                        currentPageSelected={unitsPage}
                        onPageChanged={(pageObject) =>
                          setUnitsPage(pageObject.currentPage)
                        }
                      />
                    </Box>
                  </>
                ) : (
                  <Box mt="32">
                    <h2 className="heading-title">
                      Nenhuma unidade encontrada.
                    </h2>
                  </Box>
                )}
              </>
            )}
          </Container>

          <CartDetails>
            <Box mt="20">
              <Button onClick={goBackToCreateBudget} width="full" outlined>
                VOLTAR
              </Button>
            </Box>
          </CartDetails>
        </PageBody>
      </PageContent>

      <Modal
        isOpen={loadingUnlinkUnit}
        modalIcon={Attention}
        closeIcon={false}
        closeOverlay={false}
        renderHeading={() => (
          <h2 className="text-center">
            Removendo unidade do carrinho, aguarde !
          </h2>
        )}
        renderDescription={() => <Loader />}
      />
    </PageContainer>
  );
};

export default UnitSelect;
