import styled, { css } from "styled-components";
import { Box } from "styles/globalComponents";

export const GridAcccordion = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
`;

export const GridAccordionContent = styled(GridAcccordion)`
  grid-auto-flow: column;
  grid-template-rows: repeat(4, auto);
`;

interface BoxInfoProps {
  colStart?: string;
}

export const BoxInfo = styled(Box)<BoxInfoProps>`
  ${(props) =>
    props.colStart &&
    css`
      grid-column: ${props.colStart};
    `};

  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
`;

export const Title = styled.h6`
  color: #666;
  font-family: var(--font-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
`;

export const TextContent = styled.p`
  font-weight: 400;
  margin: 0;
`;

export const GridItens = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 120px 120px;
  strong {
    color: #666;
  }
`;

export const GridItensCovenant = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 300px;
  strong {
    color: #666;
  }
`;

export const ButtonsActionsContainer = styled(Box)`
  gap: 15px;

  button {
    font-size: 13px;
    font-weight: 500;
    padding: 13px 21px;
  }

  @media (min-width: 1480px) {
    gap: 30px;

    button {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
