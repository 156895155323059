import Axios from "axios";
import { getToken } from "Services/Authentication";

export const cancelOrder = async (order_id: number) => {


  const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getToken()}`
    },
  });


  try {
    const canceledOrder = await API.get(`/orders/cancel/${order_id}`)

    return canceledOrder.data

  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao cancelar o pedido";
  }

}