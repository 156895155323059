import React from "react";

import InfoImage from "Pages/Orders/ListPlansOrders/components/DetailsInfoImage";
import { ContainerDocumet } from "Pages/Orders/ListPlansOrders/components/ModalDatails/style";
import { PhonesPersonProps } from "Pages/ServiceForm/SelectPacient/interface";

import { FormatDate } from "Utils/FormatterDate";
import { MaskCPF, MaskCelphone, MaskCEP, MaskLandline } from "Utils/Masks";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { AddressType, CovenantsCredentialsProps, TabProps } from "../interface";

function PersonalDataTab({ person }: TabProps) {
  const [passport] = person.documents.filter(
    (document) => document.number && document.type !== "cpf"
  );

  const [document] = person.documents.filter(({ type }) => type === "cpf");

  const infoPerson = [
    {
      title: "Nome",
      data: (person && person?.full_name) || "Não informado",
    },
    {
      title: "Nome Social",
      data: (person && person?.social_name) || "Não informado",
    },
    {
      title: "Data de Nascimento",
      data:
        (person && person?.birthdate && FormatDate(person?.birthdate)) ||
        "Não informado",
    },
    {
      title: "CPF",
      data: (person && MaskCPF(document?.number)) || "Não informado",
    },
    {
      title: "Sexo",
      data:
        (person && person?.gender && person.gender?.gender) || "Não informado",
    },
    {
      title: "Passaporte",
      data: (person && passport && passport.number) || "Não informado",
    },
    {
      title: "País Emissor",
      data: (person && passport && passport.country.name) || "Não informado",
    },

    {
      title: "Nome da mãe",
      data: (person && person?.mother_name) || "Não informado",
    },
    {
      title: "Recém nascido",
      data: person && person?.unborn ? "Sim" : "Não",
    },
  ];

  const handleListModalImages = (arr: string[]) => {
    const arrImg: string[] = [];

    arr.forEach((item) => {
      if (item) arrImg.push(`data:image/jpeg;base64,${item}`);
    });

    return arrImg;
  };

  function renderPersonAddress(addresses: AddressType[]) {
    return addresses ? (
      <>
        <h5 className="text-purple">Endereço</h5>
        {addresses.map((address, i) => (
          <dl key={i} className="multiple-list">
            <div className="list-item">
              <dt className="item-title">Tipo:</dt>
              <dd className="item-data">
                {address?.address_type && address.address_type.type}
                {address?.type && address.type.type}
              </dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Rua:</dt>
              <dd className="item-data">{address.street}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Nº:</dt>
              <dd className="item-data">{address.number}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Complemento:</dt>
              <dd className="item-data">{address.complement}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Bairro:</dt>
              <dd className="item-data">{address.neighborhood}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Cidade:</dt>
              <dd className="item-data">{address.city}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Estado:</dt>
              <dd className="item-data">{address.uf}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">CEP:</dt>
              <dd className="item-data">{MaskCEP(address.zip_code)}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">País:</dt>
              <dd className="item-data">
                {address?.country && address.country.name}
              </dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Ponto de Referencia:</dt>
              <dd className="item-data">{address.reference_point}</dd>
            </div>
          </dl>
        ))}
      </>
    ) : (
      <>
        <h5 className="text-purple">Endereços</h5>
        <p className="text-center text-bold text-danger">
          Dados não informados
        </p>
      </>
    );
  }

  function renderPersonPhone(phones: PhonesPersonProps[]) {
    return phones ? (
      <>
        <h5 className="text-purple">Telefone</h5>
        {phones?.map((phone, i) => (
          <dl key={i} className="multiple-list">
            <div className="list-item">
              <dt className="item-title">DDI:</dt>
              <dd className="item-data">{phone.ddi}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">DDD:</dt>
              <dd className="item-data">{phone.ddd}</dd>
            </div>
            <div className="list-item">
              <dt className="item-title">Número:</dt>
              <dd className="item-data">
                {JSON.stringify(phone.phone).length === 8
                  ? MaskLandline(phone.phone)
                  : MaskCelphone(phone.phone)}
              </dd>
            </div>
          </dl>
        ))}
      </>
    ) : (
      <>
        <h5 className="text-purple">Telefones</h5>
        <p className="text-center text-bold text-danger">
          Dados não informados
        </p>
      </>
    );
  }

  function renderPersonCovenant(covenants: CovenantsCredentialsProps[]) {
    return covenants ? (
      <AccordionCollapse title="Convênios">
        {covenants?.map((covenant, i) => (
          <div key={i}>
            <dl className="multiple-list-covenant">
              <div className="list-item">
                <dt className="item-title">Convênio:</dt>
                <dd className="item-data">
                  {covenant.covenant?.covenant || "Não informado"}
                </dd>
              </div>
              <div className="list-item">
                <dt className="item-title">Plano:</dt>
                <dd className="item-data">
                  {covenant.covenant_plan?.covenant_plans_name ||
                    "Não informado"}
                </dd>
              </div>
              <div className="list-item">
                <dt className="item-title">Carteirinha:</dt>
                <dd className="item-data">
                  {covenant.credential_number || "Não informado"}
                </dd>
              </div>
              <br />
            </dl>

            <dl className="multiple-list-covenant">
              <div className="list-item">
                <dt className="item-title">Fotos dos documentos:</dt>
                <dd className="item-documents">
                  {covenant?.front_credential_img_base64 ? (
                    <InfoImage
                      url={`data:image/jpeg;base64,${covenant?.front_credential_img_base64}`}
                      name={covenant?.front_credential_img_name}
                      type="image/png"
                      imagesModal={handleListModalImages([
                        covenant?.front_credential_img_base64,
                        covenant?.back_credential_img_base64,
                      ])}
                    />
                  ) : (
                    <ContainerDocumet className="item-data">
                      Frente da carteirinha não informada
                    </ContainerDocumet>
                  )}
                  {covenant?.back_credential_img_base64 ? (
                    <InfoImage
                      url={`data:image/jpeg;base64,${covenant?.back_credential_img_base64}`}
                      name={covenant?.back_credential_img_name}
                      type="image/png"
                      imagesModal={handleListModalImages([
                        covenant?.back_credential_img_base64,
                        covenant?.front_credential_img_base64,
                      ])}
                    />
                  ) : (
                    <ContainerDocumet className="item-data">
                      Verso da carteirinha não informado
                    </ContainerDocumet>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        ))}
      </AccordionCollapse>
    ) : (
      <p className="text-center text-bold text-danger">Dados não informados</p>
    );
  }

  return (
    <div className="token-area">
      <div className="info-personal">
        {infoPerson.map((eachInfo, i) => (
          <div className="list-item" key={i}>
            <dt className="item-title">{eachInfo.title}:</dt>
            <dd className="item-data">{eachInfo.data}</dd>
          </div>
        ))}
      </div>

      <div>
        {person?.addresses &&
          person.addresses?.length > 0 &&
          renderPersonAddress(person.addresses)}
      </div>

      <div>
        {person?.phones &&
          person.phones?.length > 0 &&
          renderPersonPhone(person.phones)}
      </div>

      <div>
        {person?.covenants_credentials &&
          person.covenants_credentials?.length > 0 &&
          renderPersonCovenant(person.covenants_credentials)}
      </div>
    </div>
  );
}

export default PersonalDataTab;
