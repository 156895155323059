import React from "react";
import { useParams } from "react-router";

import { RouteParamsInterface } from "Pages/RegistrationCleaning/interface";

import { DependentsSelection } from "./components/DependentsSelection";
import { MainSelection } from "./components/MainSelection";
import { Review } from "./components/Review";

export const RegistrationCleaning = () => {
  const { step } = useParams<RouteParamsInterface>();

  switch (step) {
    case "merge":
      return <MainSelection />;
    case "dependent":
      return <DependentsSelection />;
    case "review":
      return <Review />;
    default:
      return <MainSelection />;
  }
};
