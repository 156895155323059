import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nome muito curto")
    .max(50, "Nome muito longo")
    .required("Obrigatório"),
  phone: Yup.string()
    .min(14, "Número inválido")
    .max(15, "Número inválido")
    .required("Obrigatório"),
  email: Yup.string().email("Email inválido").required("Obrigatório"),
  gender: Yup.string().required("Obrigatório"),
  birthday: Yup.string()
    .min(10, "Data inválida")
    .max(10, "Data inválida")
    .required("Obrigatório"),
  document: Yup.string()
    .min(11, "Documento inválido")
    .max(20, "Documento inválido")
    .required("Obrigatório"),
  country: Yup.string().when("type_document", {
    is: "passport",
    then: Yup.string().required("Obrigatório"),
    otherwise: Yup.string(),
  }),
  type_document: Yup.string().required("Obrigatório"),
});
