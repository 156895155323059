import React from "react";
import { useParams } from "react-router-dom";

import { MaskCEP } from "Utils/Masks";

import Input from "Components/Form/Input";
import PanelContent from "Components/Panels/PanelContent";

import { getCity } from "../../../Requests/Units";
import AutocompleteCity from "./AutocompleteCity";

export function AddressInfo({
  objectData,
  addressesCity,
  setObjectData,
  handleObjectChange,
  loading,
}) {
  const params = useParams();
  const { longitude, latitude, neighborhood, street, cep, complement, number } =
    objectData;
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [search, setSearch] = React.useState({
    id: "",
    value: "",
    error: "",
  });

  async function handleSuggestion({ target }) {
    setSearch((prev) => ({
      ...prev,
      value: target.value,
    }));

    if (target.value.length < 3) return;

    try {
      const res = await getCity(target.value);
      setFilteredSuggestions(res);
      setShowSuggestions(true);
    } catch (error) {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }

  function handleClickSuggestion(e) {
    const text = e.currentTarget.innerText;

    setSearch({
      value: text,
      error: "",
    });

    setShowSuggestions(false);
    setObjectData((prevState) => ({
      ...prevState,
      city_id: {
        value: e.currentTarget.id,
        error: "",
      },
    }));
  }

  React.useEffect(() => {
    if (addressesCity) {
      if (params.id !== "0") {
        setFilteredSuggestions(addressesCity);
        return setSearch((prev) => ({
          ...prev,
          value: addressesCity[0].city,
        }));
      }
    }

    setSearch({
      id: "",
      value: "",
      error: "",
    });
  }, [addressesCity]);

  return (
    <PanelContent title="Informações de Endereço" loading={loading}>
      <div className="row">
        <div className="md-6">
          <AutocompleteCity
            label="Buscar Cidade*"
            placeholder="digite o nome da cidade"
            oSuggestionMessage="Nenhuma cidade encontrada!"
            id="search_city"
            activeSuggestion={0}
            showSuggestions={showSuggestions}
            filteredSuggestions={filteredSuggestions}
            value={search.value}
            error={objectData.city_id.error}
            onChange={handleSuggestion}
            onClick={handleClickSuggestion}
          />
        </div>

        <div className="md-6">
          <Input
            label="Cep*"
            type="text"
            id="cep"
            placeholder={MaskCEP("00000000")}
            maxLength="9"
            value={MaskCEP(cep.value)}
            error={cep.error}
            onChange={({ target }) => handleObjectChange(target, "cep")}
          />
        </div>

        <div className="md-6">
          <Input
            label="Bairro"
            type="text"
            id="neighborhood"
            maxLength={255}
            value={neighborhood.value}
            error={neighborhood.error}
            onChange={({ target }) =>
              handleObjectChange(target, "neighborhood")
            }
          />
        </div>

        <div className="md-6">
          <Input
            label="Rua*"
            type="text"
            id="street"
            maxLength={255}
            value={street.value}
            error={street.error}
            onChange={({ target }) => handleObjectChange(target, "street")}
          />
        </div>

        <div className="md-6">
          <Input
            label="Complemento"
            type="text"
            id="complement"
            maxLength={145}
            value={complement.value}
            error={complement.error}
            onChange={({ target }) => handleObjectChange(target, "complement")}
          />
        </div>
        <div className="md-6">
          <Input
            label="Numero*"
            type="text"
            id="number"
            maxLength={5}
            value={number.value}
            error={number.error}
            onChange={({ target }) => handleObjectChange(target, "number")}
          />
        </div>
        <div className="md-6">
          <Input
            label="longitude"
            type="text"
            id="longitude"
            maxLength={15}
            value={longitude.value}
            error={longitude.error}
            onChange={({ target }) => handleObjectChange(target, "longitude")}
          />
        </div>
        <div className="md-6">
          <Input
            label="latitude"
            type="text"
            id="latitude"
            maxLength={15}
            value={latitude.value}
            error={latitude.error}
            onChange={({ target }) => handleObjectChange(target, "latitude")}
          />
        </div>
      </div>
    </PanelContent>
  );
}
