import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { useBudgetOrder } from "Stores/budget-order";
import { Box } from "styles/globalComponents";
import { useShallow } from "zustand/react/shallow"

import { CATEGORIES } from "Constants/Categories";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useCart } from "Hooks/useCart";
import { useProductCombos } from "Hooks/useProductCombos";

import { handleRequestErrors } from "Utils/Errors";

import { Button } from "Components/Button";
import Select from "Components/Form/Select";

import PencilIcon from "Assets/Images/pencil.png";
import TrashIconClose from "Assets/Images/trashIcon.png";

import { ModalsBudgetActions } from "./Modals";
import { Container } from "./styles";

const includeCombo = [
  "categories",
  "subcategories",
  "status",
  "services",
  "partners",
];

export const CartActions = () => {
  const [modals, setModals] = useState({
    courtesy: false,
    coupon: false,
    delete: false,
    medicalAttach: false,
    adittionalInfo: false,
    credentials: false,
    budgetSuccess: false,
    motionBudget: false,
    motionBudgetSuccess: false,
    motionCovenant: false,
    incompleteRegister: false,
    completeLeadRegister: false,
  });

  const { budget, partnerSelected, documents, isCovenantUnitAttendance } =
    useBudgetContext();

  const [currentModal] = useBudgetOrder(useShallow((state) => [
    state.currentModal
  ]));

  useEffect(() => {

    if (currentModal === 'incompleteRegister') {
      setModals((prev) => ({ ...prev, incompleteRegister: true }));
    }
    if (currentModal === 'completeRegister') {
      setModals((prev) => ({ ...prev, completeLeadRegister: true }))
    }

  }, [currentModal])

  const isPartnerProduct =
    budget?.budget_type === CATEGORIES.infusion ||
    budget?.budget_type === CATEGORIES.image;

  const { combos } = useProductCombos({
    includeCombo,
    searchBudget: true,
    enabled: isPartnerProduct,
  });

  const history = useHistory();

  const { person_type } = useParams<{ person_type: string }>();

  const location = useLocation();

  const { handleChangeSelectPartner, clearCart } = useCart();

  const { is_covenant } = budget ?? {};

  const isProductImage = budget?.budget_type === CATEGORIES.image;

  const hasCourtesy = budget?.cart.some((product) => product.voucher);

  const { partners } = combos ?? {};

  const showCourtesyButton = !isProductImage && !is_covenant;

  const selectUnitPage = location.pathname.includes("selecionar-unidade");

  const buttonContent =
    isCovenantUnitAttendance && !selectUnitPage
      ? "Continuar"
      : "Salvar e enviar";

  const blockBtn = isCovenantUnitAttendance && !budget?.unit && selectUnitPage;

  const openModal = (modal: string) => {
    const modalsState = {
      courtesy: false,
      coupon: false,
      delete: false,
      medicalAttach: false,
      adittionalInfo: false,
      credentials: false,
      budgetSuccess: false,
      motionBudget: false,
      motionBudgetSuccess: false,
      motionCovenant: false,
      incompleteRegister: false,
      completeLeadRegister: false,
    };
    modalsState[modal as keyof typeof modalsState] = true;
    setModals(modalsState);
  };


  const closeModal = (modal: string) => {
    setModals((prev) => ({ ...prev, [modal]: false }));
  };

  const clearBudgetCart = async () => {
    try {
      if (budget?.id) {
        await clearCart(budget?.id, "Carrinho limpado com sucesso");
        return;
      }

      throw new Error("O ID do orçamento não foi fornecido !");
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível limpar o carrinho.  ",
      });
    }
  };

  function clearHTML(value: any) {
    return !!value
      ?.trim()
      ?.replace(/(<([^>]+)>)/gi, "")
      ?.replace(/&nbsp;/g, "");
  }

  const clickButtonAction = () => {
    if (isCovenantUnitAttendance && !selectUnitPage) {
      return history.push(
        `/ficha-de-atendimento/${person_type}/${budget?.id}/selecionar-unidade`
      );
    }
    if (budget) {
      openModal("budgetSuccess");
    }
  };

  return (
    <Container className="actions-cart-holder">
      <ModalsBudgetActions
        closeModal={closeModal}
        modals={modals}
        openModal={openModal}
      />
      <div className="actions-cart-holder">
        {showCourtesyButton && (
          <Button
            onClick={() => openModal("courtesy")}
            classButton={hasCourtesy ? "active" : ""}
          >
            Inserir Cortesia
          </Button>
        )}

        {!is_covenant && (
          <Button
            classButton={clearHTML(budget?.general_information) ? "active" : ""}
            onClick={() => openModal("adittionalInfo")}
          >
            Informações gerais{" "}
            {clearHTML(budget?.general_information) && (
              <img src={PencilIcon} width={16} alt="pencil-icon" />
            )}
          </Button>
        )}

        {!is_covenant && (
          <Button
            classButton={documents?.length > 0 ? "active" : ""}
            onClick={() => openModal("medicalAttach")}
          >
            Anexar pedido medico{" "}
            <span>
              {documents?.length > 0 &&
                `${documents?.length} arquivo${documents?.length > 1 ? "s" : ""
                }`}
            </span>
            {documents?.length > 0 && (
              <img src={PencilIcon} width={16} alt="pencil-icon" />
            )}
          </Button>
        )}

        {!is_covenant && (
          <Button
            classButton={budget?.coupon_code ? "active" : ""}
            onClick={() => openModal("coupon")}
          >
            inserir cupom <span className="coupon">{budget?.coupon_code}</span>
            {budget?.coupon_code && (
              <img src={TrashIconClose} width={16} alt="" />
            )}
          </Button>
        )}
      </div>

      <div className={`container-partner ${!isProductImage && "partners"} `}>
        {isProductImage && (
          <div className="partner">
            {/* @ts-ignore */}
            <Select
              label="Parceiro:"
              placeholder="-"
              optionName="list"
              value={partnerSelected}
              error=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeSelectPartner(e.target.value)
              }
              options={partners}
            />
          </div>
        )}

        <Button
          textButton
          classButton="clear-cart"
          onClick={clearBudgetCart}
          padding="0"
        >
          <p>Limpar carrinho</p>
        </Button>
      </div>

      <p className="time-of-delivery-title">
        O tempo máximo para o(s) resultado(s){" "}
        {budget?.result_eta.toLowerCase() ?? ""}
      </p>

      <div className="actions-cart-budget">
        {budget?.budget_type !== CATEGORIES.consult && (
          <Box display="flex" direction="column">
            <Button
              width="full"
              onClick={clickButtonAction}
              disabled={blockBtn}
            >
              {buttonContent}
            </Button>
            <Button width="full" onClick={() => openModal("delete")} outlined>
              EXCLUIR ORÇAMENTO
            </Button>
          </Box>
        )}
      </div>
    </Container>
  );
};
