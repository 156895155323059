import { useState } from "react";

export interface ModalState {
  texts: {
    head: string;
    additional: string;
    buttons: {
      accept: string;
      cancel: string;
    };
  };
  accept: () => void;
  cancel: () => void;
}

export const useModal = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalState | null>(null);

  const modalNonValueProduct = () => {
    setOpenConfirmModal(true);

    return new Promise((resolve, reject) => {
      const modalInfo = {
        texts: {
          head: "Atenção",
          additional:
            "Você está cadastrando um produto com valor zerado. Deseja confirmar a edição ?",
          buttons: {
            accept: "Confirmar",
            cancel: "Cancelar",
          },
        },
        accept: () => {
          resolve("");
          setOpenConfirmModal(false);
        },
        cancel: () => {
          reject("");
          setOpenConfirmModal(false);
        },
      };
      setModalOptions(modalInfo);
    });
  };

  const modalConfirmEditing = () => {
    setOpenConfirmModal(true);

    return new Promise((resolve, reject) => {
      const modalEditingInfo = {
        texts: {
          head: "Salvar alterações",
          additional: "Essa ação não poderá ser desfeita.",
          buttons: {
            accept: "Salvar",
            cancel: "Cancelar",
          },
        },
        accept: () => {
          resolve("");
          setOpenConfirmModal(false);
        },
        cancel: () => {
          reject("form editing canceled");
          setOpenConfirmModal(false);
        },
      };
      setModalOptions(modalEditingInfo);
    });
  };

  return {
    modalNonValueProduct,
    modalConfirmEditing,
    openConfirmModal,
    modalOptions,
  };
};
