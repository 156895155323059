import styled from "styled-components";

export const CreatableContainer = styled.div`
  .input-holder {
    display: flex;
    gap: 32px;

    input {
      width: 100%;
      flex: 1;
      border: 1px solid #d8d8d8;

      box-shadow: 0px 0px 0px 1px rgba(102, 102, 102, 0.16),
        0px 1px 2px -1px rgba(102, 102, 102, 0.1);
      border-radius: 10px;
      padding: 13px 0px 13px 16px;
      font-family: var(--font-text);

      &:placeholder {
        font-weight: 400;
        font-size: 13px;
        line-height: 160%;
      }

      &:focus-visible {
        outline: 1px solid #d8d8d8;
      }
    }
  }
`;

export const DraggableItems = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 5px;
  }
`;
