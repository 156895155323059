import styled, { css } from "styled-components";

interface StylesProps {
  open: boolean;
}

export const Container = styled.div<StylesProps>`
  overflow: hidden;
  position: relative;
  width: 200px;

  &.checkbox-units {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 200px;

    .head {
      display: flex;
      gap: 20px;
      align-items: center;
      position: relative;
      z-index: 1;
      margin-bottom: 10px;

      label {
        margin: 0;
      }

      background: inherit;

      .arrow-container {
        display: flex;
        align-items: center;

        .arrow-button {
          cursor: pointer;
          transition: all 0.3s linear;
        }
      }
    }

    ul {
      list-style: none;
      position: relative;
      margin: 0;
      padding-left: 22px;
      transition: all 0.3s cubic-bezier(0.42, 0, 0.54, 0.74);

      li {
        padding-right: 10px;
        margin-bottom: 8px;
      }
    }

    ${({ open }) =>
      open &&
      css`
        .arrow-button {
          transform: rotateZ(180deg);
        }
        ul {
          transform: translate(0px, 0px);
        }
      `}

    ${({ open }) =>
      !open &&
      css`
        .arrow-button {
          transform: rotateZ(0deg);
        }

        ul {
          overflow: hidden;
          opacity: 0;
          height: 0;
          position: absolute;
          transform: translate(0px, -100%);
        }
      `}
  }
`;
