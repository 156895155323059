import { useState } from "react";
import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { CovenantInfo } from "Pages/HealthInsurance/EditHealthInsurance/interface";

import { getInsurance, getInsuranceId } from "Requests/RequestInsurance";

import { handleRequestErrors } from "Utils/Errors";
import { Convenants } from "Utils/Units/Interface";

export interface CovenantRequest {
  data: Convenants[];
}

type PlansList = Omit<CovenantInfo, "covenant_plans"> & {
  covenant_plans: {
    covenant_plans_name: string;
    id: number;
  }[];
};

export const useCovenants = () => {
  const [selectedCovenantId, setSelectedCovenantId] = useState<number | null>();

  const { data, isLoading } = useQuery<CovenantRequest>(
    ["covenants-search"],
    getInsurance,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: plansList, isLoading: plansLoading } = useQuery<
    PlansList,
    AxiosError
  >(
    ["covenant", selectedCovenantId],
    () => getInsuranceId(selectedCovenantId),
    {
      refetchOnMount: false,
      enabled: !!selectedCovenantId,
      refetchOnWindowFocus: false,
      onError: (err) => handleError(err),
    }
  );

  const getCovenantId = (id: number) => {
    setSelectedCovenantId(id);
  };

  const handleError = (err: AxiosError) => {
    handleRequestErrors({
      reqErrors: err,
      errorMessage: "Não foi possível obter a listagem de planos.",
    });
  };

  return {
    getCovenantId,
    loading: isLoading || plansLoading,
    covenantList: data?.data,
    plansList: plansList?.covenant_plans,
  };
};
