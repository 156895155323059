import React from "react";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { PropsColumnsItem, PropsTable } from "./interface";

const Table = ({ loading, data, columns }: PropsTable) => {
  return (
    <div className="page-content-list">
      <table className="table-content small-separate details">
        <thead>
          <tr>
            {columns.map((columnsItem: PropsColumnsItem) => (
              <th key={columnsItem.key}>{columnsItem.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={7} className="text-center">
                <span className="loading-persons">
                  <img src={LoadingImage} alt="" />
                </span>
              </td>
            </tr>
          ) : (
            <>
              {!data.length ? (
                <tr>
                  <td colSpan={7} className="text-center text-purple">
                    Oops! Parece que nada foi encontrado...
                  </td>
                </tr>
              ) : (
                data.map((dataItem: any, dataItemIdx: number) => (
                  <tr key={dataItemIdx}>
                    {columns.map(
                      (
                        columnsItem: PropsColumnsItem,
                        columnsItemKey: number
                      ) => (
                        <td
                          key={columnsItemKey}
                          className={columnsItem.className}
                        >
                          {columnsItem.render
                            ? columnsItem.render(dataItem)
                            : dataItem[columnsItem.key]}
                        </td>
                      )
                    )}
                  </tr>
                ))
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
