import { ControlledCheckboxProps } from "./types";


export const ControlledCheckbox = ({ id, name, label, value, error, onChange, readOnly }: ControlledCheckboxProps) => {

  return (
    <div
      className={`
         field-holder
         checkbox-field 
         field-checkbox-holder
         ${error ? "field-holder-invalid" : ""}
      `}
    >
      <label className="checkbox-holder" style={{ color: 'rgba(102, 102, 102, 1)', fontSize: '13px', fontWeight: 600 }}>
        <input
          type="checkbox"
          className="input _checkbox"
          name={id}
          id={id}
          onChange={input => {
            const event: any = {
              target: {
                name,
                value: !value,
              },
            };
            onChange(event);
          }}
          value={String(value)}
          checked={value}
          disabled={readOnly}
        />
        <span className="box-check" />
        {label}
        {error !== "" && (
          <span
            className={`error-message ${!label ? "error-without-label" : ""}`}
          >
            {error}
          </span>
        )}
      </label>
    </div>
  )

}