import Select from "react-select";
import { RegionOptionType, SelectRegionsProps } from "./types";
 
export const SelectRegions = ({
  id,
  label,
  error,
  small,
  options,
  value,
  loading,
  onChange,
  placeholder
}: SelectRegionsProps) => {

  const getValue = (options: RegionOptionType[], value: string) => {
    return options.filter((o) => value === o.region);
  }

  return (
    <div className={`
      field-holder 
      ${error ? "field-holder-invalid" : ""}
      ${small ? "field-holder-small" : ""}
  `}>
      <label
        htmlFor={id}
        className='text-gray text-semibold _purple'
      >
        {label}
      </label>
      <Select
        menuPosition="fixed"
        classNamePrefix="react-select"
        className="react-select input _opacity-gray"
        id={id}
        value={getValue(options, value.region)}
        onChange={(selectedOption) => {
          selectedOption ? onChange(selectedOption) : null
        }}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        getOptionValue={(option) => option.region}
        getOptionLabel={(option) => option.region}
      />
    </div>
  )

}