import { PatientOrderType, PatientType } from "Stores/budget-order";
import moment from "moment";
import { MaskCPF } from "Utils/Masks";
import { SelectedPatientProducts } from "../CardSelection/types";

export const fromToFormFields = (field: string) => {
  return field.split('need_')[1]
}

export const fromToFormLabel = (label: string) => {
  switch (label) {
    case "mother_name":
      return "Nome da Mãe"
    case "symptoms":
      return "Inicio dos Sintomas"
    case "diseases":
      return "Doenças"
    case "height":
      return "Altura *"
    case "last_menstruation":
      return "Última menstruação"
    case "weight":
      return "Peso *"
    case "medicament":
      return "Medicamentos"
    default:
      return ''
  }
}

export const fromToFormPlaceholder = (label: string) => {
  switch (label) {
    case "mother_name":
      return "Insira nome da mãe"
    case "symptoms":
      return "EX: 01/03/2024 ou 'sem sintomas'"
    case "diseases":
      return "Descreva as Doenças"
    case "height":
      return "Insira a Altura"
    case "last_menstruation":
      return "Última menstruação"
    case "weight":
      return "Insira o Peso"
    case "medicament":
      return "Insira o Medicamentos"
    default:
      return ''
  }
}

export const verifyIfHasVaccineLinked = (patientWithProducts: SelectedPatientProducts[], patients: PatientOrderType[], index: number) => {
  if (patientWithProducts.length > 0) {
    const findIndex = patientWithProducts.findIndex((i) => i.patient_id === patients[index].index);

    if (findIndex != -1) {
      const hasVaccineLinkedToPatient = patientWithProducts[findIndex].products.some((a) => a.category === 'Vacinas');

      return hasVaccineLinkedToPatient
    }
    return false
  }
  return false
}


export const fromToFormMask = (label: string) => {
  switch (label) {
    case "height":
      return "height"
    case "weight":
      return "weight"
    case "last_menstruation":
      return "menstruation"
    default:
      return ''
  }
}

export const verifyPatientAge = (patient: PatientOrderType) => {
  let result = false;

  if (patient.birthdate === "") result = true;

  const birthdate = moment(patient.birthdate, 'YYYY-MM-DD');
  const diffYears = moment().diff(birthdate, 'years');

  const hasFile = patient.file;

  if (diffYears >= 5) result = false;

  if (
    diffYears < 5 &&
    !hasFile
  ) {
    result = true;
  }

  return result;
}

export const formatPayer = (payer_patient: PatientType) => {
  return {
    id: payer_patient.id,
    index: 0,
    birthdate: moment(payer_patient.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    email: payer_patient.email,
    phone: payer_patient.phone,
    name: payer_patient.name,
    social_name: payer_patient.social_name,
    document: {
      id: payer_patient.document.id,
      number: payer_patient.document.type === "cpf" ? MaskCPF(payer_patient.document.number) : payer_patient.document.number,
      type: payer_patient.document.type,
      country_id: 0,
    },
    mother_name: !payer_patient.mother_name ? '' : payer_patient.mother_name,
    gender_id: payer_patient.gender_id,
    unborn: payer_patient.unborn,
    isPayer: true,
    isRequestor: true,
    date: moment(payer_patient.birthdate, "YYYY-MM-DD").toDate(),
    formattedPhone: `+${payer_patient.phone.ddi}${payer_patient.phone.ddd}${payer_patient.phone.phone}`,
    family_relationship_id: 0,
    responsible_id: 0,
    symptoms: "",
    diseases: "",
    height: "",
    last_menstruation: "",
    medicament: "",
    weight: "",
  }
}