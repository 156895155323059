import React from "react";

const SwitchInputControlled = ({
  name,
  label,
  purple,
  textOn,
  cssName,
  textOff,
  reverse,
  small,
  description,
  iconOn,
  iconOff,
  field,
  form,
  disabled,
}) => {
  const { setFieldValue } = form;
  const value = field?.value;

  return (
    <div
      className={`
        field-holder 
        ${cssName || ""}
        ${small ? "field-holder-small" : ""}
      `}
    >
      {label && (
        <label className={`text-gray text-semibold ${purple ? "_purple" : ""}`}>
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}

      <input id={field?.name} {...field} style={{ display: "none" }} />

      <button
        onClick={() => setFieldValue(field?.name, !value)}
        type="button"
        className={` 
          switch-holder 
          switch-button 
          ${value ? "_active" : ""}
          ${reverse ? "switch-ltf" : ""}
          ${iconOn ? "with-icons" : ""}
        `}
        disabled={disabled}
      >
        <span className="switch-item">
          <small>{value ? iconOn : iconOff}</small>
        </span>
        <span className="switch-text">{value ? textOn : textOff}</span>
      </button>
    </div>
  );
};

export default SwitchInputControlled;
