import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Field, Form, Formik } from "formik";

import { SearchPersons, PreRegisterPerson } from "Requests/Person";

import {
  selectPersonDuplicatedFlow,
  resetPersonDuplicatedFlow,
} from "Actions/DuplicatePerson";
import { selectPacient } from "Actions/ServiceForm";

import { handleRequestErrors } from "Utils/Errors";
import { MaskCelphoneDDD, MaskCPF } from "Utils/Masks";
import { removeSpecialCharacters } from "Utils/RemoveSpecialCharacters";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import InputControlled from "Components/Form/Controlled/Input";
import Input from "Components/Form/Input";
import { ModalInformationWithChildren } from "Components/Modals/ModalInformation/informationWithChildren";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import AtentionIcon from "Assets/Images/atention.svg";
import CanceledIcon from "Assets/Images/canceledIcon.svg";
import LoadingImage from "Assets/Images/loading-purple.gif";

import { formatterResponseSearchPacient } from "./helper";
import { DocumentsProps, PersonProps, PreRegisterProps } from "./interface";
import { schemaPacient } from "./schema";
import { ModalContent } from "./styles";


const ServiceFormPacient = (props: any) => {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");

  const [persons, setPersons] = useState<PersonProps[]>([]);
  const [personSelected, setPersonSelected] = useState<PersonProps>();
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingPerson, setLoadingPerson] = useState(false);
  const [showList, setShowList] = useState(false);
  const [buttonForcePreRegister, setButtonForcePreRegister] = useState(false);
  const [loadingPreRegister, setLoadingPreRegister] = useState(false);
  const [isModalCanceled, setisModalCanceled] = useState(false);
  const [isVisibleModal, setisVisibleModal] = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setLoadingTable(true);

    try {
      if (searchInput === "") return setShowList(false);

      const responsePacients = await SearchPersons(searchInput);

      const { data } = responsePacients;

      const leads = data?.leads || [];
      const persons = data?.persons || [];

      const formatter = formatterResponseSearchPacient({ leads, persons });

      setPersons(formatter);
      setShowList(true);
    } catch (error: any) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível buscar o paciente.",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handlePreRegister(value: PreRegisterProps) {
    try {
      setLoadingPreRegister(true);

      const responsePreRegister = await PreRegisterPerson(value);

      const { data } = responsePreRegister;

      if (data?.leads || data?.persons) {
        setLoadingPreRegister(false);

        const leads = data?.leads || [];
        const persons = data?.persons || [];

        const formatter = formatterResponseSearchPacient({ leads, persons });

        setPersons(formatter);
        setShowList(true);
        setButtonForcePreRegister(true);

        return createToast(
          "warning",
          "Já existe um paciente com essas informações"
        );
      }

      const createdLead = {
        ...responsePreRegister.data,
        isLead: true,
        full_name: responsePreRegister.data.name,
      };

      handleDispatchSelectPacient(createdLead);
      setButtonForcePreRegister(false);
    } catch (error: any) {
      setButtonForcePreRegister(false);

      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível criar o lead.  ",
      });
    } finally {
      setLoadingPreRegister(false);
    }
  }

  async function handleDispatchSelectPacient(person: any) {
    setLoadingPerson(true);
    setPersonSelected(undefined);

    try {
      if (person.cpf) {
        const { data } = await SearchPersons(person.cpf);
        const leads = data?.leads || [];
        const persons = data?.persons || [];

        const formatter = formatterResponseSearchPacient({ leads, persons });

        if (formatter.length > 1) {
          setPersonSelected(person);
          return setisVisibleModal(true);
        }
      }

      dispatch(selectPacient(person));
      const type = person.isPerson ? "person" : "lead";

      redirectToNextPage(type);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPerson(false);
    }
  }

  function handleChangeInput(value: string) {
    const formattedValue = removeSpecialCharacters(value);

    setSearchInput(formattedValue);
    if (buttonForcePreRegister) setButtonForcePreRegister(false);

    if (!value) return setShowList(false);
  }

  function redirectToNextPage(type: any) {
    props.history.push(`/ficha-de-atendimento/${type}/orcamentos`);
  }

  const handleModal = () => {
    if (isModalCanceled) {
      setisVisibleModal(false);
      return setisModalCanceled(false);
    }

    dispatch(resetPersonDuplicatedFlow());

    dispatch(
      selectPersonDuplicatedFlow({
        origin: "budget",
      })
    );

    props.history.push(
      `/limpeza-cadastro/${personSelected?.documents[0].number}/merge`
    );
  };

  const handleModalDismiss = () => {
    if (!isModalCanceled) {
      return setisModalCanceled(true);
    }

    return setisModalCanceled(false);
  };

  const patientDocument = useCallback(
    (documents: DocumentsProps[]) => {
      if (!documents || documents.length < 1) return "Não informado";

      const searchReplacedCharacter = searchInput.replace(/[^a-zA-Z0-9]/g, "");

      const documentSearched = documents.filter((document) =>
        String(document.number).includes(searchReplacedCharacter)
      );

      if (documentSearched.length >= 1) {
        const [doc] = documentSearched;
        return doc.type === "cpf" ? MaskCPF(doc.number) : doc.number;
      }

      return documents[0].type === "cpf"
        ? MaskCPF(documents[0]?.number)
        : documents[0].number;
    },
    [persons]
  );

  useEffect(() => {
    dispatch(selectPacient({}));
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Criar orçamento">
              <Breadcrumb
                list={[
                  {
                    path: "/orcamentos",
                    label: "Orçamentos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Criar novo",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
        </div>
        <section className="service-form-page">
          <div className="container">
            <div
              className={`sub-container ${showList ? "padding-bottom-2" : ""}`}
            >
              <form onSubmit={handleSubmit} className="form-holder">
                <div className="container-search-pacient">
                  <h5 className="title-service-form-page">Buscar cliente</h5>
                  <div className="box-search-flex-patient">
                    {/* @ts-ignore */}
                    <Input
                      id="search-pacient"
                      value={searchInput}
                      type="search"
                      placeholder="Buscar paciente por nome, cpf, ddd + telefone ou email"
                      onChange={({ target: { value } }: any) =>
                        handleChangeInput(value)
                      }
                    />
                    <Button
                      classButton="radiusButton"
                      type="submit"
                      loading={loadingTable}
                      disabled={loadingTable}
                    >
                      Buscar
                    </Button>
                  </div>
                </div>
              </form>
              {showList ? (
                <div className="table-list-cards table-budgets text-separate-text">
                  <ul className="list-header">
                    <li className="item">Nome</li>
                    <li className="item">Documento</li>
                    <li className="item">Telefone</li>
                    <li className="item">email</li>
                    <li className="item" />
                  </ul>
                  <div className="list-content">
                    {loadingTable ? (
                      <div className="loading loading-holder">
                        <img src={LoadingImage} alt="" />
                      </div>
                    ) : persons?.length > 0 ? (
                      persons?.map((person) => (
                        <div key={person?.id} className="card card-table">
                          <span>{person?.full_name}</span>
                          <span>{patientDocument(person?.documents)}</span>
                          <span>
                            {person?.phones?.length
                              ? MaskCelphoneDDD(
                                  `(${person?.phones[0]?.ddd}) ${person?.phones[0]?.phone}`
                                )
                              : "Não informado"}
                          </span>
                          <span>
                            {person?.emails?.length
                              ? person?.emails[0]?.email
                              : "Não informado"}
                          </span>
                          <div className="text-right action">
                            <button
                              className={`button _action ${
                                loadingPerson ? "_loading" : ""
                              }`}
                              type="button"
                              onClick={() =>
                                handleDispatchSelectPacient(person)
                              }
                            >
                              <span className="details-text">Selecionar</span>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="card card-table-empty">
                        <p className="text-danger text-display text-center lead">
                          Oops! Nenhum paciente encontrado...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="table-list-cards text-separate-text">
                  <div className="list-content">
                    <div className="card card-table-empty">
                      <p className="text-danger text-display text-center">
                        Você precisa preencher o campo de busca para visualizar
                        a lista de pacientes...
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="sub-container">
              <Formik
                initialValues={{
                  first_name: "",
                  telphone: "",
                  fixo: "",
                  email: "",
                  cpf: "",
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schemaPacient}
                onSubmit={handlePreRegister}
              >
                {() => (
                  <Form>
                    <div className="pre-register">
                      <h5 className="title-service-form-page pre-register">
                        Fazer pré-cadastro de um novo cliente
                      </h5>
                      <div className="row row-center acenter">
                        <div className=" md-3 ">
                          <Field
                            label="Nome"
                            id="first_name"
                            name="first_name"
                            type="text"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                        </div>

                        <div className=" md-3 ">
                          <Field
                            label="Celular"
                            id="telphone"
                            name="telphone"
                            type="text"
                            mask="(99) 9 9999-9999"
                            placeholder="(11) 1 1111-1111"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                        </div>
                        <div className="box-email-or-phone">
                          <p>ou</p>
                        </div>
                        <div className=" md-3">
                          <Field
                            label="E-mail"
                            id="email"
                            name="email"
                            type="email"
                            component={InputControlled}
                            className="input _opacity-gray"
                          />
                        </div>
                        <button
                          type="submit"
                          className="button _action pre-register"
                          disabled={loadingPreRegister}
                        >
                          Criar pré-cadastro
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>

        <ModalInformationWithChildren
          modalVisible={isVisibleModal}
          modalDismiss={() => handleModalDismiss()}
          cancelButton
          title={isModalCanceled ? "Atendimento Interrompido!" : "Atenção!"}
          icon={isModalCanceled ? CanceledIcon : AtentionIcon}
          messageButton={
            isModalCanceled ? "CANCELAR ATENDIMENTO" : "AJUSTAR CADASTRO"
          }
          onClick={() => handleModal()}
        >
          <ModalContent>
            <p>
              {isModalCanceled
                ? "Para prosseguir com o atendimento deste CPF, você precisa realizar o ajuste no cadastro."
                : "O cliente solicitante, possui mais de uma pessoa cadastrada em seu CPF. Para prosseguir com o atendimento deste cliente você precisa  realizar o ajuste de cadastro."}
            </p>

            {!isModalCanceled && (
              <div>
                <p>Para realizar o ajuste, basta seguir os passos abaixo:</p>

                <span>
                  1. Identificar o(s) cadastro(s) correto(s) do cliente.
                </span>
                <span>
                  2. Realizar a limpeza do cadastro, desvinculando os nomes ao
                  CPF do cliente.
                </span>
                <span>
                  3. Conferir os dados do cliente, validando as informações de{" "}
                  <strong>identidade</strong> e <strong>contato</strong>.
                </span>
              </div>
            )}
          </ModalContent>
        </ModalInformationWithChildren>
      </div>
    </PageWrapper>
  );
};

export default ServiceFormPacient;
