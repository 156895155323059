import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { SearchOrders } from "Requests/Orders";

import Pagination from "Components/Nav/Pagination";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { DataOrder, MetaOrderProps, TabProps } from "../interface";

function OwnOrdersTab({ person }: TabProps) {
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [orders, setOrders] = useState<DataOrder[]>([]);
  const [paginationOrders, setPaginationOrders] = useState<MetaOrderProps>();
  const [lastPagePaginationOrder, setLastPagePaginationOrder] = useState(1);

  useEffect(() => {
    searchOrdersByUser();
  }, []);

  async function searchOrdersByUser() {
    setLoadingOrders(true);
    try {
      const orderObject = await SearchOrders(`?person=${person.id}&page=${1}`);

      setOrders(orderObject?.data);
      if (orderObject?.meta) {
        setPaginationOrders(orderObject.meta.pagination);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOrders(false);
    }
  }

  async function fetchPaginationOrder(currentPage: number) {
    setLoadingOrders(true);

    try {
      const ordersResponse = await SearchOrders(
        `?page=${currentPage}&person=${person.id}`
      );

      setOrders(ordersResponse?.data);

      if (ordersResponse?.meta) {
        setPaginationOrders(ordersResponse.meta.pagination);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOrders(false);
    }
  }

  function handlePagination(e: any) {
    const lastPagePagination = lastPagePaginationOrder;

    if (lastPagePagination === e.currentPage) return;

    fetchPaginationOrder(e.currentPage);

    setLastPagePaginationOrder(e.currentPage);
  }

  return (
    <div className="token-area">
      <div className="table-list-cards">
        {loadingOrders ? (
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        ) : (
          <>
            <ul className="list-header">
              <li className="item">Número</li>
              <li className="item">Data</li>
              <li className="item">Total</li>
              <li className="item">Cupom</li>
              <li className="item">Origem</li>
              <li className="item" />
            </ul>

            {orders.length > 0 ? (
              orders.map((order) => (
                <div key={order.order_id} className="card card-table">
                  <span>{order.order_id}</span>
                  <span>{order.date}</span>
                  <span>{order.totalDisplay}</span>
                  <span>{order.coupon_code || "Nenhum cupom"}</span>
                  <span>{order.order_source}</span>
                  <div className="text-right action">
                    {/* @ts-ignore */}
                    <Link
                      className={"button _action" as never}
                      to={`/ficha-de-pedido/${order.order_id}` as never}
                    >
                      <span className="details-text">Visualizar</span>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="card card-table-empty">
                <p className="text-danger text-display text-center">
                  Oops! Não encontramos nenhum pedido para este paciente. :(
                </p>
              </div>
            )}
          </>
        )}

        {paginationOrders?.total_pages !== undefined && (
          <div className="pagination-wrapper">
            <Pagination
              totalRecords={paginationOrders.total_pages}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e: any) => handlePagination(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OwnOrdersTab;
