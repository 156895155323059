import {
    LOGIN_SUCCESS,
    LOGOUT
  } from "Actions/ActionsTypes";

const defaultState = {
    userData: {},
    permissions: []
};
  
export default function User(state = defaultState, action) {
    switch (action.type) {
        case  LOGIN_SUCCESS:
            return {
                userData: action.user,
                permissions: action.permissions
            }
            
        case LOGOUT:
            return defaultState
  
        default:
            return state;
    }
}
  