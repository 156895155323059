import React from "react";

import { Field, useFormikContext } from "formik";

import { CATEGORIES, SUBCATEGORIES } from "Constants/Categories";

import { useProductCombos } from "Hooks/useProductCombos";

import { hasPathInArray } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";
import SwitchInputControlled from "Components/Form/Controlled/SwitchInputControlled";
import Input from "Components/Form/Input";
import Select from "Components/Form/Select";
import { SelectListItems } from "Components/Form/SelectListItems";
import { ProductsResultsProps } from "Components/Form/SelectListItems/interface";

import { FormValuesProps, HandleChangeFields } from "../../interface";
import { Container } from "./styles";

export const CompositionForm = ({ setSelectField }: HandleChangeFields) => {
  const { values, handleChange, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<FormValuesProps>();

  const { category_id } = values;
  const categoryId = String(category_id.id);

  const { combos } = useProductCombos({ includeCombo: ["*"] });

  const adjustedWidthInputs = hasPathInArray([1, 2, 3], categoryId)
    ? "w-50"
    : "w-100";

  const handleAddToPackage = (value: ProductsResultsProps) => {
    const kitsValues = values?.kits ? [...values.kits] : [];
    setFieldValue("kits", [...kitsValues, value]);
  };

  const handleDeletePackageItem = (item: ProductsResultsProps) => {
    const kitsValues = values?.kits ? [...values.kits] : [];
    const activePackageProducts = kitsValues.filter(
      (kit) => kit.id !== item.id
    );
    setFieldValue("kits", activePackageProducts);
  };

  const requestVaccineProducts = {
    categories: [category_id.id],
    ...(values.subcategories?.includes(Number(SUBCATEGORIES.vaccine_club)) && {
      subcategories: [Number(SUBCATEGORIES.vaccine_package)],
    }),
  };

  const requestProductCategories =
    category_id.id === Number(CATEGORIES.vaccine)
      ? requestVaccineProducts
      : { categories: [1, 3] };

  return (
    <AccordionCollapse title="Composição">
      <Container>
        {hasPathInArray([1, 2, 3], categoryId) && (
          <div className={`input-container ${adjustedWidthInputs}`}>
            <Field
              name="sample_id"
              id="sample_id"
              options={combos?.samples}
              value={values.sample_id}
              optionName="sample"
              label="Tipo de amostra"
              onChange={setSelectField}
              component={Select}
              placeholder="Selecione o tipo de amostra"
            />
          </div>
        )}
        {hasPathInArray([4], categoryId) && (
          <div className="input-container switch w-100 ">
            <Field
              name="rule.attenuated_virus"
              id="rule.attenuated_virus"
              label="Vírus atenuado"
              value={values.rule.attenuated_virus}
              type="text"
              component={SwitchInputControlled}
            />
          </div>
        )}
        {hasPathInArray([4], categoryId) && (
          <>
            <div className="input-container w-50 ">
              <Field
                name="dose_amount"
                id="dose_amount"
                maxlength="4"
                type="number"
                value={values.dose_amount}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Quantidade de doses"
                component={Input}
                placeholder="Digite a quantidade de doses do produto"
                error={
                  touched.dose_amount &&
                  errors.dose_amount &&
                  errors.dose_amount
                }
              />
            </div>
            {Number(values.dose_amount) > 1 && (
              <div className="input-container w-50">
                <Field
                  name="dose_interval"
                  maxlength="8"
                  type="number"
                  id="dose_interval"
                  value={values.dose_interval}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Digite o intervalo de doses do produto"
                  component={Input}
                  placeholder="Informações aqui"
                  error={
                    touched.dose_interval &&
                    errors.dose_interval &&
                    errors.dose_interval
                  }
                />
              </div>
            )}
          </>
        )}
        {hasPathInArray([4, 8], categoryId) && (
          <div className="input-container w-100">
            <Field
              name="application_id"
              id="application_id"
              options={combos?.applications}
              value={values.application_id}
              optionName="application"
              onChange={setSelectField}
              label="Aplicação"
              component={Select}
              placeholder="Selecione o tipo de aplicação do produto"
            />
          </div>
        )}
        {hasPathInArray([1, 2, 3], categoryId) && (
          <div className={`input-container ${adjustedWidthInputs}`}>
            <Field
              name="methodology_id"
              id="methodology_id"
              value={values.methodology_id}
              options={combos?.methodologies}
              optionName="methodology"
              label="Metodologia de análise"
              placeholder="Selecione o tipo de metodologia do produto"
              onChange={setSelectField}
              onBlur={handleBlur}
              component={Select}
            />
          </div>
        )}
        {hasPathInArray([2, 3, 4], categoryId) && (
          <div className="input-container w-100">
            <Field
              name="isPackage"
              id="isPackage"
              label="Pacote"
              component={SwitchInputControlled}
            />
          </div>
        )}
        {values.isPackage && hasPathInArray([2, 3, 4], categoryId) && (
          <div className="input-container w-100">
            <Field
              component={SelectListItems}
              name="kits"
              id="kits"
              error={touched.kits && errors.kits && errors?.kits}
              label="Pacotes*"
              placeholder='Digite os tópicos aqui e aperte "enter" para adicionar'
              cssClass="w-100"
              productsCategories={requestProductCategories}
              addToPackage={handleAddToPackage}
              deleteItem={handleDeletePackageItem}
              mapValues={values.kits}
            />
          </div>
        )}
      </Container>
    </AccordionCollapse>
  );
};
