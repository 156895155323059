import React from "react";

import { DetailsInfoProps } from "./interface";
import { ContainerDetailsInfo } from "./style";

export const DetailsInfo = ({ label, content }: DetailsInfoProps) => {
  return (
    <ContainerDetailsInfo>
      <dt>{label}</dt>
      <dd>{content}</dd>
    </ContainerDetailsInfo>
  );
};
