import { useEffect, useState } from "react";
import { scroller } from "react-scroll";

import moment from "moment";

import {
  getOrdersHome,
  getStatusPayment,
  getCalendars,
} from "Requests/HomeOrder";

import { createToast } from "Utils/toastFunc";

import { initialDates } from "./data";

export const useGetMotionOrders = () => {
  const [typesStatus, setTypesStatus] = useState([]);
  const [dates, setDates] = useState(initialDates);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [statusSearch, setStatusSearch] = useState("");
  const [itensPerPage, setItensPerPage] = useState({ id: "100", name: "100" });
  const [value, setValue] = useState("");
  const [orderType, setOrderType] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [calendars, setCalendars] = useState({
    data: [],
    isLoading: false,
    selected: null,
  });

  async function fetchStatus() {
    try {
      const res = await getStatusPayment();
      const formatter = res.data.map((item) => ({
        id: item.id,
        name: item.status,
      }));
      setTypesStatus(formatter);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar os status!");
    }
  }

  async function fetchData(rest) {
    try {
      setLoading(true);
      const res = await getOrdersHome({ ...rest });
      res.status === 200 && setData(res.data.data);
      res.status === 200 && setPagination(res.data.meta.pagination);

      return res;
    } catch (error) {
      createToast("error", "Ocorreu um erro ao solicitar os dados do pedido!");
    } finally {
      setLoading(false);
    }
  }

  async function filterOrders() {
    setPagination({});
    sessionStorage.removeItem("page");

    const {
      dates: { startDate, endDate },
    } = dates;

    const hasSameFilters = checkFilterChanges();

    const { data } = await fetchData({
      page: hasSameFilters ? pagination.current_page : 1,
      ...(value ? { [typeSearch.id]: value } : {}),
      status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
      compound_order: orderType ? orderType.type : null,
      page_size: itensPerPage.id,
      start: startDate == null ? null : moment(startDate).format("YYYY-MM-DD"),
      end: endDate == null ? null : moment(endDate).format("YYYY-MM-DD"),
      calendar_id: calendars.selected
        ? calendars.selected.map((calendar) => calendar.value)
        : null,
    });

    return saveFilters(data.meta.pagination);
  }

  function keepPaginationTrack(currentPage) {
    const getStorageFilter = JSON.parse(
      sessionStorage.getItem("motion-orders")
    );

    if (getStorageFilter) {
      const updateStorage = {
        ...getStorageFilter,
        pages: {
          ...getStorageFilter.pages,
          current_page: currentPage,
        },
      };

      return sessionStorage.setItem(
        "motion-orders",
        JSON.stringify(updateStorage)
      );
    }

    sessionStorage.setItem(
      "page",
      JSON.stringify({
        page: currentPage,
      })
    );
  }

  async function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;
    keepPaginationTrack(e.currentPage);

    const {
      dates: { startDate, endDate },
    } = dates;

    fetchData({
      page: e.currentPage,
      ...(value ? { [typeSearch.id]: value } : {}),
      status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
      compound_order: orderType ? orderType.type : null,
      page_size: itensPerPage.id,
      start: startDate == null ? null : moment(startDate).format("YYYY-MM-DD"),
      end: endDate == null ? null : moment(endDate).format("YYYY-MM-DD"),
      calendar_id: calendars.selected
        ? calendars.selected.map((calendar) => calendar.value)
        : null,
    });

    scroller.scrollTo("ScrollableDomiciliarOrders", {
      duration: 500,
      delay: 50,
      smooth: true,
      containerId: "ScrollDomiciliar",
      offset: -350,
    });
  }

  function checkFilterChanges() {
    const getStorage = JSON.parse(sessionStorage.getItem("motion-orders"));

    if (getStorage) {
      delete getStorage.pages;

      const storageValues = Object.values(getStorage);

      const filtersValues = Object.values({
        date: {
          dates: {
            startDate: JSON.stringify(dates.dates.startDate).replaceAll(
              '"',
              ""
            ),
            endDate: JSON.stringify(dates.dates.endDate).replaceAll('"', ""),
          },
        },
        status: statusSearch,
        itens: itensPerPage,
        order: orderType,
        type: typeSearch,
        searchText: value,
        calendars: calendars.selected,
      });

      const stringifiedStorage = JSON.stringify(storageValues);
      const stringifiedFilters = JSON.stringify(filtersValues);

      return stringifiedStorage === stringifiedFilters;
    }
  }

  async function reloadPage(res = { status: 200 }) {
    res.status === 200 &&
      (await fetchData({
        page: pagination.current_page,
        ...(value ? { [typeSearch.id]: value } : {}),
        status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
        compound_order: orderType ? orderType.type : null,
        calendar_id: calendars.selected
          ? calendars.selected.map((calendar) => calendar.value)
          : null,
      }));
  }

  function saveFilters(pagination) {
    sessionStorage.setItem(
      "motion-orders",
      JSON.stringify({
        date: dates,
        pages: pagination,
        status: statusSearch,
        itens: itensPerPage,
        order: orderType,
        type: typeSearch,
        searchText: value,
        calendars: calendars.selected,
      })
    );
  }

  async function loadLastSearchContext() {
    const filters = JSON.parse(sessionStorage.getItem("motion-orders"));
    const page = JSON.parse(sessionStorage.getItem("page"));

    if (page) {
      return fetchData({ page: page.page });
    }

    const { pages, status, type, searchText, order, itens, calendars } =
      filters;

    const {
      dates: { startDate, endDate },
    } = filters.date;

    const searchedDates = {
      startDate: startDate == null ? null : moment(startDate),
      endDate: endDate == null ? null : moment(endDate),
    };

    setDates({ dates: searchedDates });

    setPagination(pages);
    setStatusSearch(status);
    setItensPerPage(itens);
    setOrderType(order);
    setTypeSearch(type);
    setValue(searchText);
    setCalendars((state) => ({ ...state, selected: calendars }));
    fetchData({
      page: pages.current_page,
      ...(searchText ? { [type.id]: searchText } : {}),
      status_id: status ? status.map((item) => item.id) : null,
      compound_order: order ? order.type : null,
      page_size: itens.id,
      start:
        searchedDates.startDate == null
          ? null
          : searchedDates.startDate.format("YYYY-MM-DD"),
      end:
        searchedDates.endDate == null
          ? null
          : searchedDates.endDate.format("YYYY-MM-DD"),
      calendar_id: calendars ? calendars.map((item) => item.value) : null,
    });
  }

  async function fetchCalendars() {
    setCalendars((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const calendars = await getCalendars();

      setCalendars((prevState) => ({ ...prevState, data: calendars }));
    } catch (err) {
      createToast("error", "Ocorreu um erro ao buscar os calendários!");
    } finally {
      setCalendars((prevState) => ({ ...prevState, isLoading: false }));
    }
  }

  const clearFields = () => {
    setValue("");
    setStatusSearch(null);
    setTypeSearch(null);
    setOrderType(null);
    setDates(initialDates);
    setItensPerPage({ id: "100", name: "100" });
    sessionStorage.removeItem("motion-orders");
    sessionStorage.removeItem("page");
    fetchData();
    fetchCalendars();
    setPagination({});
    setCalendars((state) => ({ ...state, selected: null }));
  };

  useEffect(() => {
    const storageItem = sessionStorage.getItem("motion-orders");
    const page = sessionStorage.getItem("page");

    if (!storageItem && !page) {
      fetchData();
    }

    if (storageItem || page) {
      loadLastSearchContext();
    }
    fetchStatus();
    fetchCalendars();
  }, []);

  return {
    typesStatus,
    loading,
    data,
    pagination,
    statusSearch,
    dates,
    value,
    itensPerPage,
    orderType,
    typeSearch,
    calendars,
    setDates,
    filterOrders,
    setStatusSearch,
    handlePagination,
    reloadPage,
    setValue,
    setCalendars,
    setItensPerPage,
    setOrderType,
    setTypeSearch,
    setCalendars,
    fetchData,
    setPagination,
    clearFields,
    loadLastSearchContext,
  };
};
