import React from 'react';

export const useDebounce = (func, wait, dependence) => {
    const timeout = React.useRef();
  
    return React.useCallback(
      (...args) => {
        const later = () => {
          clearTimeout(timeout.current);
          func(...args);
        };
  
        clearTimeout(timeout.current);
        timeout.current = setTimeout(later, wait);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [...dependence]
    );
};