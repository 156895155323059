import { Box } from "styles/globalComponents";

import { formatDateBR } from "Utils/Date";
import { formatBRLCurrency } from "Utils/FormatCurrency";

import { Button } from "Components/Button";

import { TableBodyProps } from "./interface";

export const TableBody = ({
  listBudgets,
  handleGetBudgetDetail,
}: TableBodyProps) => {
  return (
    <tbody>
      {listBudgets.map((dataItem) => (
        <tr key={dataItem.id}>
          <td>{dataItem.id}</td>
          <td>{formatDateBR(dataItem.created_at)}</td>
          <td>{dataItem.is_covenant ? "CONVÊNIO" : "PARTICULAR"}</td>
          <td>{formatBRLCurrency(dataItem.total)}</td>
          <td>{dataItem.channel.name}</td>
          <td>{dataItem.status.status}</td>
          <td>
            <Box display="flex" justify="center">
              <Button
                onClick={() => handleGetBudgetDetail(dataItem.id)}
                outlined
              >
                VISUALIZAR
              </Button>
            </Box>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
