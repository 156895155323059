import { useEffect, useState } from "react";

import { ReactComponent as CheckIcon } from "Assets/Images/check-small-purple.svg";
import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";
import { Box } from "styles/globalComponents";
import Warning from "Assets/Images/atention.svg";
import { AttendanceItemType, AttendanceType, FormattedAttendanceType } from "Stores/budget-order";
import { SelectProductModalAttendanceProps } from "./types";


export const SelectProductModalAttendance = ({
  selectedId,
  isOpen,
  handleClose,
  attendances,
  currentAttendance,
  order,
  setOrder,
  hasAllAttendances
}: SelectProductModalAttendanceProps) => {

  const [filteredAttendances, setFilteredAttendances] = useState<AttendanceType[]>([])


  useEffect(() => {
    const formattedAttendances: AttendanceType[] = attendances.filter((i) =>
      (i.type === selectedId) || (i.type === 'both' && (selectedId === 'unity_collect' || selectedId === 'home_collect'))
    );

    formattedAttendances.map((i) => {
      i.items.map((a) => {
        if (i.id === currentAttendance.id) {
          a.checked = true
        }
      })
    })

    setFilteredAttendances(formattedAttendances)
  }, [])

  const handleSelectProduct = (product_id: number, attendance_id: number) => {
    const clonedAttendances = [...filteredAttendances]

    clonedAttendances.map((i) => {
      if (i.id === attendance_id) {
        i.items.map((a) => {
          if (a.product_id === product_id) {
            a.checked = !a.checked
          }
        })
      }
    });

    setFilteredAttendances(clonedAttendances)
  }

  const proceedAttendance = () => {

    const attendancesWithProducts: FormattedAttendanceType = attendances
      .reduce(
        (acc, attendance) => {
          const isUnityCollect = selectedId === 'unity_collect';

          let checkedItems: AttendanceItemType[] = [];

          if (isUnityCollect) {
            checkedItems = attendance.items.filter(
              (item) => item.checked);
          } else if (!isUnityCollect) {
            checkedItems = attendance.items.filter(
              (item) => item.checked);
          }

          if (checkedItems.length > 0) {
            // Adiciona os ids de atendimento e os ids dos produtos ao array acumulador
            acc.ids_attendance.push(attendance.id);
            acc.products_ids.push(...checkedItems.map((item) => item.product_id));

            // Define o tipo condicionalmente
            acc.type = selectedId === 'unity_collect' ? 'unity_collect' : 'home_collect'
          }

          return acc;
        },
        {
          ids_attendance: [] as number[], // Inicializa como array vazio
          type: selectedId == 'unity_collect' ? 'unity_collect' : 'home_collect', // Tipo padrão (será atualizado conforme a condição)
          products_ids: [] as number[], // Inicializa como array vazio
          region_id: 0,
          unity_id: 0,
          id_scheduling: 0,
          session_start: '',
          session_end: '',
          home_collect_date: '',
          calendar_id: '',
          unity_name: '',
          home_collect_hour: '',
        }
      );

    const formattedAttendances: FormattedAttendanceType[] = [{ ...attendancesWithProducts }];

    const clonedAttendances = [...order.attendances]

    clonedAttendances.map((i) => {
      i.items.map((a) => {
        a.checked = false
      })
    })

    setOrder({
      ...order,
      formattedAttendances: [...order.formattedAttendances, ...formattedAttendances],
      attendances: clonedAttendances,
      attendanceLabel: order.formattedAttendances.length === 0 ? [] : order.attendanceLabel
    })

    handleClose(false)
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => handleClose(true)}
      modalIcon={Warning}
      renderHeading={() => (
        <h2>
          <strong>Atenção</strong>
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          Você possui outros produtos no seu carrinho que podem se encaixar no{" "}
          <strong style={{ color: '#999' }}>
            atendimento {selectedId === 'unity_collect' ? 'na unidade' : 'em casa'}
          </strong>. Selecione abaixo com quais produtos você quer seguir neste atendimento:
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column"  >
          {filteredAttendances.map((attendance) => (
            <div key={attendance.id}>
              {attendance.items.map((item) => {

                // const isDefaultSellect = (currentAttendance.type === 'both' && (selectedId === 'unity_collect' || selectedId === 'home_collect')) && (attendance.type === 'unity_collect' || attendance.type === 'home_collect');
                const isDefaultHome = currentAttendance.type === 'home_collect' && attendance.type === 'both'
                const isDefaultUnity = currentAttendance.type === 'unity_collect' && attendance.type === 'both'

                const isDefaultSellect = currentAttendance.type === 'home_collect' ? isDefaultHome : isDefaultUnity;

                return (
                  <div
                    key={item.product_id}
                    style={{
                      position: 'relative',
                      border: '2px solid var(--color-purple)',
                      borderRadius: '3.125em',
                      padding: '10px 20px',
                      fontFamily: 'var(--font-display)',
                      fontSize: '0.875rem',
                      lineHeight: '1.125rem',
                      color: 'var(--color-purple)',
                      textTransform: 'uppercase',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '0.2rem',
                      marginTop: '0.5em',
                      opacity: !isDefaultSellect ? '0.5' : '1',
                      cursor: !isDefaultSellect ? 'not-allowed' : 'pointer',
                      width: '18em'
                    }}
                    onClick={() => isDefaultSellect ? handleSelectProduct(item.product_id, attendance.id) : null}
                  >
                    {item.product_name}
                    <div style={{
                      display: !isDefaultSellect || item.checked ? 'block' : 'none',
                      position: 'absolute',
                      right: '20px'
                    }}>
                      <CheckIcon className="check-icon" />
                    </div>
                  </div>
                );
              })}
            </div>
          ))}

          <Button width="full" minHeight="3.7em" fontWeight="600" onClick={() => proceedAttendance()}>
            Avançar
          </Button>
          <Button width="full" outlined onClick={handleClose} minHeight="3.7em" fontWeight="600">
            Voltar
          </Button>
        </Box>
      )}
    />
  );
};
