import styled, { css } from "styled-components";

interface LoadingProps {
  flex: boolean;
  mgBottom: number;
}

export const LoadingContainer = styled.div<LoadingProps>`
  display: flex;

  ${({ flex }) => css`
    flex-direction: ${flex ? "row" : "column"};
  `}

  ${({ flex }) => css`
    gap: ${flex ? "10px" : "0"};
  `}

  align-items: center;
  width: 100%;

  ${({ flex }) => css`
    margin-top: ${flex ? "10px" : "50px"};
  `}

  ${({ mgBottom }) => css`
    margin-bottom: ${mgBottom ? `${mgBottom}px` : "0px"};
  `}

  p {
    font-family: var(--font-display);
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--color-purple);
    text-align: center;
    ${({ flex }) => css`
      margin-bottom: ${flex ? "0px" : "inherit"};
    `}
  }
`;
