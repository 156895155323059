import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import Modal from "react-modal";

import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import PageHeader from "Components/Pages/Header";

import LoadingImage from "Assets/Images/loading.gif";

export function GeneralInformationModal({
  modalVisible,
  modalDismiss,
  addInformation,
  generalInformation = "",
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [textHtml, setTextHtml] = useState("");

  async function handleSave() {
    try {
      setIsLoading(true);
      await addInformation(textHtml);
      modalDismiss();
    } catch (err) {
      return err;
    } finally {
      setIsLoading(false);
    }
  }

  const onEditorStateChange = (editorState) => {
    const textHtmlDrafted = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setTextHtml(textHtmlDrafted);
  };

  function closeModal() {
    modalDismiss();
  }

  const blocksFromHTML = convertFromHTML(generalInformation);

  const htmlBlocks = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const draftValue = EditorState.createWithContent(htmlBlocks);

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        bodyOpenClassName="modal-content-open"
        className="modal-content modal-content-cart"
        overlayClassName={{
          base: "modal",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
      >
        <div className="modal-wrapper-header">
          <PageHeader title="Informações Gerais" children={undefined} />
        </div>
        <div className="modal-wrapper-body">
          <Editor
            defaultEditorState={draftValue}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "link",
                "embedded",
                "emoji",
                "history",
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
          <div className="modal-button">
            <button
              className="button _action"
              type="button"
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? (
                <img
                  className="loading medium"
                  src={LoadingImage}
                  alt="loading"
                />
              ) : (
                "Salvar"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
