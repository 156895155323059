import React, { ChangeEvent, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Box } from "styles/globalComponents";

import { Button } from "Components/Button";
import { ListPackageItem } from "Components/ListPackageItem";

import { CreatableCustomProps } from "./interface";
import { CreatableContainer, DraggableItems } from "./styles";

export const CreatableTopicList = ({
  placeholder,
  label,
  cssClass = "",
  field,
  form,
  mapValues,
  draggable = false,
  addToPackage,
  handleDragEnd,
  deleteItem,
}: CreatableCustomProps) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddProductToPackage = () => {
    if (inputValue) {
      addToPackage(inputValue);
      setInputValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (inputValue) handleAddProductToPackage();
      e.preventDefault();
    }
  };

  return (
    <CreatableContainer className={`exams-list-included ${cssClass}`}>
      <div
        className={`
                field-holder 
            `}
      >
        {label && (
          <label htmlFor={field.name} className="text-gray text-semibold">
            {label}
          </label>
        )}

        <div className="input-holder">
          <input
            {...field}
            placeholder={placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInputValue(e.target.value)
            }
            onKeyDown={handleKeyDown}
            value={inputValue}
            autoComplete="off"
          />

          {form.errors[field.name] && form.touched[field.name] && (
            <span
              className={`error-message ${!label ? "error-without-label" : ""}`}
            />
          )}

          <Button borderRadius="111" onClick={handleAddProductToPackage}>
            Adicionar
          </Button>
        </div>
      </div>

      {draggable ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="textos">
            {(provided) => (
              <DraggableItems
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="show-border"
              >
                {mapValues.length >= 1
                  ? mapValues?.map((item, index) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(providedInside, snapshot) => (
                          <div
                            {...providedInside.draggableProps}
                            {...providedInside.dragHandleProps}
                            ref={providedInside.innerRef}
                            style={{
                              backgroundColor: snapshot.isDragging
                                ? "#ab92bf"
                                : "transparent",
                              ...providedInside.draggableProps.style,
                            }}
                          >
                            <ListPackageItem
                              key={
                                typeof item === "string"
                                  ? `${item + index}`
                                  : item.label
                              }
                              productName={
                                typeof item === "string" ? item : item.label
                              }
                              deleteProduct={() => deleteItem(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </DraggableItems>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box mt="16" mb="16">
          {mapValues.length >= 1
            ? mapValues.map((item, index) => (
                <ListPackageItem
                  key={
                    typeof item === "string" ? `${item + index}` : item.label
                  }
                  productName={typeof item === "string" ? item : item.label}
                  deleteProduct={() => deleteItem(index)}
                />
              ))
            : null}
        </Box>
      )}
    </CreatableContainer>
  );
};
