import React from "react";
import Modal from "react-modal";

import PageHeader from "Components/Pages/Header";

export function ModalConfirm({
  modalVisible,
  modalDismiss,
  handleClick,
  text,
  title,
}) {
  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="modal-wrapper-header h5-center">
        <PageHeader title={title} />
      </div>
      <div className="description-list modal-close-badge-flex">
        <div className="cart-flex-buttons">
          <button className="button" onClick={handleClick}>
            {text}
          </button>

          <button className="button closeButton" onClick={modalDismiss}>
            Voltar
          </button>
        </div>
      </div>
    </Modal>
  );
}
