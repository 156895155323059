import React from "react";

import { TextModal } from "Pages/RegistrationCleaning/styles";

export const DependentHasDocument = () => {
  return (
    <TextModal fontWeight={400}>
      Esta pessoa possui CPF ou passaporte próprio?
    </TextModal>
  );
};
