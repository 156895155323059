import React from "react";
import { CgClose } from "react-icons/cg";

import { Button } from "Components/Button";

import DotsAdd from "Assets/Images/dots-add.png";

import { ListVaccinePackageItemProps } from "./interface";
import { Container } from "./style";

export const ListPackageItem = ({
  deleteProduct,
  productName,
  showDotsIcon = true,
}: ListVaccinePackageItemProps) => {
  return (
    <Container>
      {showDotsIcon && <img src={DotsAdd} alt="add-item" />}
      <p>{productName}</p>
      <Button textButton onClick={deleteProduct} minHeight="initial">
        <CgClose />
      </Button>
    </Container>
  );
};
