function validatePermissions(permissions, userPermissionsList) {
  const userPermissions = userPermissionsList.map((item) => item.name);

  const valid = userPermissions.some((item) => {
    return Array.isArray(permissions)
      ? permissions.includes(item)
      : item === permissions;
  });

  return valid;
}

export default validatePermissions;
