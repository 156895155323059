import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { PageHeading } from "Pages/ServiceForm/styles";

import { selectPacient } from "Actions/ServiceForm";

import { Store } from "Reducers/interface";

import { MaskCPF } from "Utils/Masks";

import PageHeader from "Components/Pages/Header";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { HeadingProps } from "./interface";

export const Heading = ({ list, title }: HeadingProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const serviceFormState = useSelector((state: Store) => state.service_form);
  const patientSelected = serviceFormState.pacient;

  const cpfDoc = serviceFormState.pacient.documents?.find(
    (doc) => doc.type === "cpf"
  );

  const passportDoc = serviceFormState.pacient.documents?.find(
    (doc) => doc.type === "passport"
  );

  function redirectToNextPage() {
    history.push("/ficha-de-atendimento/orcamentos");
  }

  function handleDispatchSelectPacient() {
    dispatch(selectPacient(patientSelected));
    redirectToNextPage();
  }

  return (
    <PageHeading className="page-heading">
      <div className="wrapper-header-content m-bottom-1 order-budget">
        <PageHeader title={title} isBudget>
          <Breadcrumb list={list} />
          <p className="id-order-budget">#{patientSelected.id}</p>
        </PageHeader>

        <div className="budget-header-user">
          <h5>Criando orçamento para:</h5>
          <p>{patientSelected.full_name}</p>
          <p>
            {cpfDoc ? `CPF ${MaskCPF(cpfDoc.number)}` : null}

            {!cpfDoc && passportDoc ? `Passaporte ${passportDoc.number}` : null}

            {!cpfDoc && !passportDoc ? "CPF Não informado" : null}
          </p>
          <button onClick={handleDispatchSelectPacient}>
            Ver todos orçamentos
          </button>
        </div>
      </div>
    </PageHeading>
  );
};
