import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import { CopyToClipboard } from "Utils/Copy";

import AttentionIcon from "Assets/Images/atention.svg";

import { DocumentsProps, ResendDocumentsProps } from "./interface";
import { Header } from "./style";



export const ResendDocuments = ({
  documents,
  modalDismiss,
  modalVisible,
}: ResendDocumentsProps) => {
  const [documentsList, setDocumentsList] = useState<DocumentsProps[]>([]);

  function handleCopyLink(url: string) {
    CopyToClipboard({
      message: "Copiado!",
      text: url,
    });
  }

  useEffect(() => {
    if (documents) {
      setDocumentsList(documents);
    }
  }, [documents]);

  return (
    <Modal
      isOpen={!!modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <Header>
        <img src={AttentionIcon} alt="Atenção" width={105} />
        <h3>Reenviar Documentos</h3>

        <p>
          Na lista abaixo você encontra todos os documentos que foram gerados
          durante a consulta. Para reenviá-los ao paciente é só copiar o link do
          documento em uma das opções abaixo:
        </p>

        <div>
          {documentsList.map((document, key) => (
            <button
              onClick={() => handleCopyLink(document.url)}
              key={key}
              className="button danger"
            >
              Documento {key + 1} - copiar link
            </button>
          ))}
        </div>
        <div>
          <button className="button" onClick={modalDismiss}>
            Fechar
          </button>
        </div>
      </Header>
    </Modal>
  );
};
