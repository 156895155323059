import React from "react";

import { hasPathInArray, validateArrayType } from "Utils/Arrays";
import { TextEllipsis } from "Utils/String";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";
import { Container, UnitsContainer } from "./style";

export const ProductRestriction = ({ product }: ProductProps) => {
  const {
    units,
    category,
    services,
    services_periods,
    product_description,
    rule,
  } = product;

  const { sell_note } = product_description ?? {};

  const { save_budget, need_pre_scheduling, need_medicament } = rule ?? {};

  const unitHeight =
    units
      ?.map((unit) => unit.units?.length * 30.8)
      .sort((a, b) => a - b)
      .filter(Boolean)
      .at(-1) ?? 0;

  return (
    <AccordionCollapse title="Restrições" cssClass="restriction">
      <Container>
        <div className="restrictions-attendance">
          <span role="heading" className="heading" aria-level={5}>
            Atendimento
          </span>
          <div className="flex">
            {services ? (
              services?.map((service) => (
                <S.RowContent
                  role="row"
                  className="row-content"
                  key={service?.id}
                >
                  {service.attendance}
                </S.RowContent>
              ))
            ) : (
              <S.RowContent>Não informado</S.RowContent>
            )}
          </div>
        </div>

        {hasPathInArray([1, 2, 3, 4, 5, 8], category?.id) && (
          <>
            <div className="restrictions-unities">
              <span role="heading" className="heading" aria-level={5}>
                Unidades
              </span>
              <UnitsContainer className="flex" height={unitHeight}>
                {units?.map((region) => (
                  <ul className="row-content" key={region.id}>
                    {region.region}
                    {validateArrayType(region.units) &&
                      region.units.map((unit) => (
                        <li key={unit.id}>{TextEllipsis(unit.name, 15)}</li>
                      ))}
                  </ul>
                ))}
              </UnitsContainer>
            </div>

            {services_periods &&
              hasPathInArray([1, 2, 3, 4, 5, 8], category?.id) && (
                <table className="restrictions-schedule">
                  <thead>
                    <tr>
                      <th>Dia de atendimento</th>
                      <th>Horário de atendimento</th>
                    </tr>
                  </thead>

                  {services_periods?.map((service) => (
                    <tbody key={service.id}>
                      <tr>
                        <td>{service.week_day}</td>
                        <td>
                          {service.start_hr}hrs às {service.end_hr}hrs
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              )}
          </>
        )}

        {hasPathInArray([1, 2, 3, 4, 8], category?.id) && (
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Nota de venda
            </span>
            <S.RowContent role="row" className="row-content">
              {sell_note || "Não informado"}
            </S.RowContent>{" "}
          </div>
        )}

        {hasPathInArray([1, 2, 3, 4, 8, 7], category?.id) && (
          <div className="flex restrictions-additional-info">
            {hasPathInArray([1, 2, 3, 8], category?.id) && (
              <div className="column-1">
                <span role="heading" className="heading" aria-level={5}>
                  Agendamento prévio p/ unidade
                </span>
                <S.RowContent role="row" className="row-content">
                  {need_pre_scheduling ? "Obrigatório" : "Não necessário"}
                </S.RowContent>{" "}
              </div>
            )}

            {hasPathInArray([1, 2, 3, 4, 8], category?.id) && (
              <div className="column-2">
                <span role="heading" className="heading" aria-level={5}>
                  Necessita pedido médico
                </span>
                <S.RowContent role="row" className="row-content">
                  {need_medicament ? "Sim" : "Não"}
                </S.RowContent>{" "}
              </div>
            )}

            {hasPathInArray([1, 2, 3, 4, 8, 7], category?.id) && (
              <div className="column-3">
                <span role="heading" className="heading" aria-level={5}>
                  Salvar orçamento
                </span>
                <S.RowContent role="row" className="row-content">
                  {save_budget ? "Sim" : "Não"}
                </S.RowContent>{" "}
              </div>
            )}
          </div>
        )}

        {hasPathInArray([7], category?.id) && (
          <div className="flex restrictions-additional-info">
            <div className="column-2">
              <span role="heading" className="heading" aria-level={5}>
                Necessita pedido médico
              </span>
              <S.RowContent role="row" className="row-content">
                {need_medicament ? "Sim" : "Não"}
              </S.RowContent>{" "}
            </div>
          </div>
        )}
      </Container>
    </AccordionCollapse>
  );
};
