import React, { useMemo } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import { getDifferenceInMinutes, getTimeWithAdd } from "Utils/Date";
import { sortTimes } from "Utils/SortTimes";

import BoxScheduleInfo from "../BoxScheduleInfo";
import { LinesCalendar } from "../LinesCalendar";

export function CardTableSchedule({
  day,
  month,
  title,
  data,
  setModalVisible,
  setDataModal,
}) {
  const formattedSchedules = useMemo(() => {
    const hours = sortTimes(Object.keys(data));

    const differenceInMinutes = getDifferenceInMinutes(hours[0], hours[1]);

    const returningSchedules = { ...data };

    const maxLength = Math.max(
      ...Object.values(returningSchedules).map((schedules) => schedules.length)
    );

    Object.entries(returningSchedules).forEach(([hour, _schedules]) => {
      _schedules = _schedules.filter(Boolean);

      _schedules.forEach((schedule, index) => {
        if (!schedule) return;

        const periodOfSchedule = getDifferenceInMinutes(
          schedule.time,
          schedule.endTime
        );

        const schedulesRepeatedIds = _schedules
          .filter((s) => !!s.repeated)
          .map((s) => s.id);

        if (
          periodOfSchedule > differenceInMinutes &&
          !schedulesRepeatedIds.includes(schedule.id)
        ) {
          const numberOfRepetitions =
            periodOfSchedule / differenceInMinutes - 1;

          for (let i = 0; i < numberOfRepetitions; i++) {
            const newHour = getTimeWithAdd(hour, differenceInMinutes * (i + 1));

            if (!returningSchedules[newHour]) {
              returningSchedules[newHour] = [];
            }

            returningSchedules[newHour].unshift(schedule);
          }

          schedule.repeated = true;

          if (index !== 0 && index !== -1) {
            _schedules = [
              schedule,
              ..._schedules.filter((s) => s.id !== schedule.id),
            ];
          }
        }
      });

      if (_schedules.length < maxLength) {
        const difference = maxLength - _schedules.length;

        for (let i = 0; i < difference; i++) {
          _schedules.push(null);
        }
      }

      returningSchedules[hour] = _schedules;
    });

    return returningSchedules;
  }, [data]);

  const times = sortTimes(Object.keys(data));

  return (
    <div className="calendars-card-table" key={day}>
      <div
        className={`header ${title === "SEG" ? "no-border" : ""} ${
          title === "SAB" ? "border-right" : ""
        }`}
      >
        {title}, {day}/{month}
      </div>

      <ScrollContainer
        className="schedule-week-main"
        vertical={false}
        activationDistance={100}
      >
        {times.map((name) => (
          <div className="week-flex" key={name}>
            {formattedSchedules[name].map((schedule, i) =>
              schedule !== null ? (
                <div
                  className="calendar-container-box"
                  key={schedule?.id + i}
                  onClick={() => {
                    setModalVisible(true);
                    setDataModal(schedule);
                  }}
                >
                  <BoxScheduleInfo type="week" hour={name} info={schedule} />
                </div>
              ) : (
                <div className="calendar-line no-line-center">
                  <LinesCalendar only />
                </div>
              )
            )}
          </div>
        ))}
      </ScrollContainer>
    </div>
  );
}
