import { BudgetItemProps } from "Pages/Budgets/ViewDetails/interface";

import { formatBRLCurrency } from "Utils/FormatCurrency";

import { AccordionCollapse } from "Components/AccordionCollapse";
import {
  GridItens,
  GridItensCovenant,
} from "Components/ViewBudgetDetails/style";

export const ItemsDetail = ({ data }: { data: BudgetItemProps }) => {
  const {
    cart,
    total,
    subtotal,
    discounts,
    home_collect_value,
    home_collected,
    is_covenant,
    covenant_data,
  } = data?.info ?? {};

  return (
    <AccordionCollapse title="Itens do Orçamento" cssClass="lg-12">
      <GridItens mt="12">
        <strong>Nome</strong>
        <strong className="text-center">Quantidade</strong>
        <strong className="text-right">Preço</strong>
      </GridItens>

      {cart?.map((item) => (
        <GridItens mt="8" key={item.id}>
          <span>{item.product_name}</span>
          <span className="text-center">{item.amount} </span>
          <span className="text-right">
            {is_covenant &&
              (item.total_item_value === 0
                ? "Coberto"
                : formatBRLCurrency(item.item_unit_price))}
            {!is_covenant && formatBRLCurrency(item.item_unit_price)}
          </span>
        </GridItens>
      ))}

      {is_covenant ? (
        <GridItensCovenant mt="22">
          <strong>Convênio</strong>
          <span className="text-right">{covenant_data?.covenant_name}</span>
        </GridItensCovenant>
      ) : (
        <GridItens mt="22">
          <strong>Subtotal</strong>
          <span className="text-center" />
          <span className="text-right">{formatBRLCurrency(subtotal)}</span>
        </GridItens>
      )}

      {Boolean(home_collect_value) && !is_covenant && (
        <GridItens mt="22">
          <strong>Coleta Domiciliar</strong>
          <span className="text-center" />
          <span className="text-right">
            {formatBRLCurrency(home_collect_value)}
          </span>
        </GridItens>
      )}

      {is_covenant ? (
        <GridItensCovenant mt="8">
          <strong>Plano</strong>
          <span className="text-right">
            {covenant_data?.covenant_plan_name}
          </span>
        </GridItensCovenant>
      ) : (
        <GridItens mt="8">
          <strong>Descontos</strong>
          <span className="text-center" />
          <span className="text-right">{formatBRLCurrency(discounts)}</span>
        </GridItens>
      )}

      {is_covenant ? (
        <GridItensCovenant mt="8">
          <strong>Tipo de atendimento</strong>
          <span className="text-right">
            {home_collected
              ? `Domiciliar - ${
                  home_collect_value === 0
                    ? "Coberto"
                    : `Não coberto ${formatBRLCurrency(home_collect_value)}`
                }`
              : "Unidade"}
          </span>
        </GridItensCovenant>
      ) : (
        <GridItens mt="8">
          <strong>Total</strong>
          <span className="text-center" />
          <strong className="text-right">{formatBRLCurrency(total)}</strong>
        </GridItens>
      )}
    </AccordionCollapse>
  );
};
