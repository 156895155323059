import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 41px 25px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  gap: 25px;

  .custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
    margin: 0;
    height: 18px;
    width: 18px;
  }

  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border: 1px solid #666;
  }

  .custom-checkbox input:checked ~ .checkmark {
    background-color: var(--color-purple);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-checkbox input:checked ~ .checkmark:after {
    display: none;
  }

  .custom-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .covenant-info {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      margin-right: auto;
      font-size: 16px;
    }
  }
`;
