import React from "react";

import { Box } from "styles/globalComponents";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";

export const ProductStatus = ({ product }: ProductProps) => {
  const { status } = product ?? {};
  return (
    <AccordionCollapse title="Status do Produto" cssClass="status">
      <Box mt="16">
        <span role="heading" className="heading" aria-level={5}>
          Status
        </span>
        <S.RowContent role="row" className="row-content">
          {status?.status}
        </S.RowContent>
      </Box>
    </AccordionCollapse>
  );
};
