import { Box } from "styles/globalComponents";

import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";

import Attention from "Assets/Images/atention.svg";
import CompleteRegister from "Assets/Images/complete-register.png";
import Success from "Assets/Images/successIcon.svg";

import { CompleteRegisterForm } from "./CompleteRegister";
import { CovenantForm } from "./CovenantForm";
import { CovenantsAvaliable } from "./CovenantsAvaliable";
import { ModalClientProps } from "./interface";
import { useModalClient } from "./useModalsClient";

export const ModalsClientCovenant = ({
  initialModalsState,
  personInfo,
  resetModalClient,
}: ModalClientProps) => {
  const {
    buttonIncompleteRegister,
    headingIncompleteRegister,
    modalDynamicInfo,
    modals,
    selectedCovenant,
    clientInfo,
    loadingInfo,
    closeModal,
    confirmSelectedCovenant,
    includeNewCovenant,
    combos,
    setCovenantId,
    redirectAttendanceType,
    addMissingData,
    submitRegisterForm,
    handleCovenantRegister,
  } = useModalClient({
    initialModalsState,
    resetModalClient,
    personInfo,
  });

  const closIncompletedRegister = () => {
    resetModalClient();
    closeModal("incompleteRegister");
  };

  return (
    <>
      <Modal
        closeOverlay={false}
        isOpen={modals.incompleteRegister}
        modalIcon={Attention}
        closeIcon={false}
        renderActions={() => (
          <Box display="flex" direction="column" gap="24">
            <Button
              width="full"
              minHeight="48"
              loading={loadingInfo}
              disabled={loadingInfo}
              onClick={addMissingData}
            >
              {" "}
              {buttonIncompleteRegister}
            </Button>
            <Button
              width="full"
              outlined
              minHeight="48"
              loading={loadingInfo}
              disabled={loadingInfo}
              onClick={closIncompletedRegister}
            >
              {" "}
              Cancelar
            </Button>

            <Box />
          </Box>
        )}
        renderHeading={() => <h2> {headingIncompleteRegister}</h2>}
        renderDescription={() => (
          <p>
            Para prosseguir com o orçamento de convênio para essa pessoa é
            necessário completar esse cadastro com as informações sobre o
            convênio.
          </p>
        )}
      />

      <Modal
        closeOverlay={false}
        closeIcon={false}
        maxHeight="663"
        isOpen={modals.updateRegisterForm}
        modalIcon={CompleteRegister}
        renderActions={() => (
          <CompleteRegisterForm
            closeModalUpdate={() => closeModal("updateRegisterForm")}
            onSubmit={submitRegisterForm}
            clientInitialValues={clientInfo}
            combos={combos}
          />
        )}
        renderHeading={() => <h2> Atualização de cadastro</h2>}
        renderDescription={() => (
          <p>
            Preencha os campos abaixo para finalizar a atualização do cadastro
            dessa pessoa e prosseguir com o orçamento de convênio:
          </p>
        )}
      />

      <Modal
        closeOverlay={false}
        isOpen={modals.updatedSuccessfully}
        closeIcon={false}
        modalIcon={Success}
        renderHeading={() => <h2>Cadastro atualizado</h2>}
        renderDescription={() => (
          <p>
            O cadastro foi atualizado com sucesso. Agora você já pode seguir com
            o orçamento de convênio para esse cliente.
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            <Button
              onClick={redirectAttendanceType}
              width="full"
              minHeight="48"
            >
              SEGUIR COM ORÇAMENTO DE CONVÊNIO
            </Button>
            <Button
              onClick={() => closeModal("updatedSuccessfully")}
              width="full"
              minHeight="48"
              outlined
            >
              CANCELAR
            </Button>
          </Box>
        )}
      />

      <Modal
        closeOverlay={false}
        isOpen={modals.selectCovenant}
        closeIcon={false}
        modalIcon={Attention}
        renderHeading={() => <h2>Selecione o convênio</h2>}
        maxHeight="871"
        renderDescription={() => (
          <p>
            Para prosseguir com o orçamento de convênio, por favor selecione uma
            das opções de convênios disponíveis no cadastro deste cliente:
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            <CovenantsAvaliable
              getCovenantId={setCovenantId}
              confirmSelectedCovenant={confirmSelectedCovenant}
              includeNewCovenant={includeNewCovenant}
              covenantsAvaliable={personInfo?.covenants_credentials}
              closeModal={closeModal}
            />
          </Box>
        )}
      />

      <Modal
        closeOverlay={false}
        isOpen={modals.covenantInfoOnly}
        closeIcon={false}
        modalIcon={CompleteRegister}
        renderHeading={() => <h2>{modalDynamicInfo.heading} </h2>}
        maxHeight="871"
        renderDescription={() => (
          <Box pr="10" pl="10">
            <p>{modalDynamicInfo.description}</p>
          </Box>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            <CovenantForm
              onSubmit={handleCovenantRegister}
              closeModal={() => closeModal("covenantInfoOnly")}
              covenantSelected={selectedCovenant.current}
            />
          </Box>
        )}
      />
    </>
  );
};
