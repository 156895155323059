import React from "react";

import { Box } from "styles/globalComponents";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";

export const ProductFeatured = ({ product }: ProductProps) => {
  const { product_description } = product ?? {};
  const { partner_description, partner_title } = product_description ?? {};

  return (
    <AccordionCollapse title="Banner de Destaque" cssClass="banner">
      <Box mt="16">
        <div>
          <span role="heading" className="heading" aria-level={5}>
            Texto de Título
          </span>
          <S.RowContent role="row" className="row-content">
            {partner_title || "Não informado"}
          </S.RowContent>
        </div>

        <div>
          <span role="heading" className="heading" aria-level={5}>
            Texto de Apoio
          </span>
          <S.RowContent role="row" className="row-content">
            {partner_description || "Não informado"}
          </S.RowContent>
        </div>
      </Box>
    </AccordionCollapse>
  );
};
