import styled from "styled-components";
import { Box } from "styles/globalComponents";

export const Container = styled(Box)`
  span {
    font-family: var(--font-display);
    font-size: 14px;
    line-height: 22px;
    color: rgb(102, 102, 102);
  }
`;
