import axios from "axios";
import { createHashHistory } from "history";


import { getToken, logout } from "./Authentication";

const history = createHashHistory();

const headers = {
  "Content-Type": "application/json",
};

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

API.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    const { data } = error?.response;

    if (
      error.response &&
      error.response.status === 401 &&
      data?.status !== "DENIED"
    ) {
      logout();

      history.push("/login", {
        unauthenticated: true,
      });
    }

    return Promise.reject(error);
  }
);

export default API;
