import { Box } from "styles/globalComponents";

import { Loader } from "Components/Loader";
import { ProductsResultSearch } from "Components/ServiceForm/ProductsResultSearch";

import { OverlayProductListProps } from "./interface";
import { Container } from "./styles";

export const OverlayProductList = ({
  productsList,
  loadingProducts,
  hasSearched,
}: OverlayProductListProps) => {
  return (
    <Container
      display="flex"
      justify="center"
      className={loadingProducts || productsList ? "quick-list" : ""}
    >
      {loadingProducts && <Loader />}

      {productsList && !loadingProducts && (
        <Box display="flex" direction="column" align="flex-start" gap="0">
          <p>Produtos Sugeridos:</p>
          <ProductsResultSearch productList={productsList} />
        </Box>
      )}

      {hasSearched && !productsList && !loadingProducts && (
        <Box mt="10" display="flex" justify="center">
          <p>Nenhum resultado encontrado</p>
        </Box>
      )}
    </Container>
  );
};
