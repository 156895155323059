import styled from "styled-components";

export const Container = styled.div`
  padding: 30px 0;
  .modal-title-suborders {
    color: var(--color-purple);
  }
  .unit-select {
    width: 100%;
    max-width: 350px;
  }
  .modal-description {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    margin-top: 0;
  }
`;
