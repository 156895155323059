import { Box } from "styles/globalComponents";

import { Button } from "Components/Button";

import { CovenantItem } from "./CovenantItem";
import { CovenantListProps } from "./interface";
import { Container } from "./style";

export const CovenantsAvaliable = ({
  getCovenantId,
  closeModal,
  confirmSelectedCovenant,
  includeNewCovenant,
  covenantsAvaliable,
}: CovenantListProps) => {
  return (
    <Container>
      <Box className="covenant-list-holder">
        <div className="covenant-list">
          {covenantsAvaliable?.map((covenant) => (
            <CovenantItem
              covenantData={covenant}
              key={covenant.id}
              onChange={getCovenantId}
            />
          ))}
        </div>
      </Box>

      <Box display="flex" direction="column" mt="48">
        <Button onClick={confirmSelectedCovenant} width="full" minHeight="48">
          avançar
        </Button>
        <Button
          onClick={includeNewCovenant}
          width="full"
          minHeight="48"
          outlined
        >
          incluir novo convênio
        </Button>
        <Button
          onClick={() => closeModal("selectCovenant")}
          width="full"
          minHeight="48"
          outlined
          textButton
        >
          cancelar
        </Button>
      </Box>
    </Container>
  );
};
