import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
      margin-left: 3rem;
      margin-top: 2rem;

      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      line-height: 120%;
      color: purple;
    }

    > form {
      margin-top: 4rem;
      margin-left: 3rem;
      margin-right: 3rem;

      display: flex;

      div:first-child {
        button {
          margin-left: 20px;
        }
      }

      div:last-child {
        margin-left: auto;

        button {
          &:first-child {
            border-radius: 16px 0 0 16px;
          }

          &:nth-child(2) {
            border-radius: 0;
          }

          &:last-child {
            border-radius: 0 16px 16px 0;
          }

          &.active {
            background: none;
            color: purple;
          }

        }

      }
    } 

`;

export const ChartContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      color: purple;
      font-size: 26px;
      margin-top: .3rem;
      margin-left: 1rem;

    }
`

export const Header = styled.header`
    display: flex;
    align-items: center;
    margin-left: 2.5rem;

    div:first-child {
      background-color: transparent;
      border: none;
      

      span {
          color: purple;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
      }

    }

    > div {
      margin: 0.5rem;
      margin-top: 1rem;
      background-color: #F0F0F0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 92px;

      border-radius: 3px;
      border: 1px solid #C4C4C4;

      > div {
        width: 250px;
        height: 92px;
        margin-top: 10px;
        display: flex;
        flex-direction: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: purple;
          margin-left: 15px;
          font-family: "Saira", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
        }

        p {
          font-family: "Saira", sans-serif;
          margin-right: 15px;
          font-style: normal;
          font-weight: 500;
          transform: translate(0, 50%);
          font-size: 32px;
          line-height: 18px;
        }
      }
    }
`
export const Charts = styled.div`
    display: flex;
    padding-left: 8rem;
    padding-right: 8rem;
    justify-content: space-between;
    margin-top: 1rem;

`

export const TopTen = styled.div`
  width: 90%;
  margin-left: 3rem;
  margin-right: 3rem;

`

export const Lists = styled.div`
  display: flex;
  margin: auto;

`