import { create } from 'zustand';


export type RescheduleAddressType = {
  date: string;
  hour: string;
  fullAddress: string;
}

type RescheduleAppointmentStore = {
  rescheduleAddress: RescheduleAddressType;
  setRescheduleAddress: (value: RescheduleAddressType) => void;
}


export const useRescheduleAppointment = create<RescheduleAppointmentStore>((set) => ({
  rescheduleAddress: {
    date: '',
    hour: '',
    fullAddress: ''
  },
  setRescheduleAddress: (value) => set({ rescheduleAddress: value })
}));