import { CSSProperties } from "react";
import TooltipInformation from "Components/TooltipInformation";
import { CenterUnityCheckout, ExamsInUnity, FlexItemsCheckout, TitleExamsUnity, TitleUnityCheckout } from "./style"
import { UnityCardProps } from "./types"


export const UnityCard = ({ handleSelectUnity, unity, selectedUnity, lengthItems }: UnityCardProps) => {

  const isCovenant = false;

  const isSelected = unity.id === selectedUnity.id;

  const styles: { [key: string]: CSSProperties } = {
    containerStyle: {
      position: 'relative',
      backgroundColor: isSelected ? "#F5F5F5" : "#FFFFFF",
      boxShadow: '0px 0px 0px 1px rgba(130, 136, 148, 0.16), 0px 4px 6px -2px rgba(130, 136, 148, 0.32)',
      borderRadius: '1em',
      width: '100%',
      display: 'flex',
      cursor: 'pointer',
    },
    paragraphStyle: {
      margin: 0,
      padding: 0,
    },
    tooltipStyle: {
      fontSize: '0.8em'
    },
    buttonStyle: {
      backgroundColor: isSelected ? "#F5F5F5 !important" : "#FFFFFF",
      border: 'none'
    },
    selectUnityStyle: {
      backgroundColor: isSelected ? "#ab92bf" : "",
      width: '0.75em',
      maxHeight: '8.43em',
      borderTopLeftRadius: '1em',
      borderBottomLeftRadius: '1em'
    },
  }


  const totalProductUnity = 1;

  const showProductsInTooltip = unity?.products?.map(
    (product) => product?.product
  );

  return (
    <>
      <div style={styles.containerStyle} id="container" onClick={() => handleSelectUnity(unity)}>
        <div style={styles.selectUnityStyle} id="selectUnity" />
        <FlexItemsCheckout>
          <CenterUnityCheckout>
            <TitleUnityCheckout>
              {unity?.name}
              {unity.type === "clinica" ? <span>Labi Clínica</span> : null}
            </TitleUnityCheckout>
            <p>
              {unity?.street}, {unity?.number}
            </p>
            <p>
              CEP {unity?.cep} - {unity?.city}
            </p>
          </CenterUnityCheckout>
          <ExamsInUnity>
            {isCovenant ? (
              <p>
                <strong> 1 </strong> / {totalProductUnity}
              </p>
            ) : (
              <p>
                <strong> {unity?.products?.length} </strong> / {lengthItems}
                <TooltipInformation
                  left
                  message={`Serviços Realizados: <strong> ${showProductsInTooltip}</strong>`}
                />
              </p>
            )}
            <TitleExamsUnity>Serviços</TitleExamsUnity>
          </ExamsInUnity>
        </FlexItemsCheckout>
      </div>
    </>
  )

}