import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Spinner } from "Components/Spinner";
import trashIcon from "Assets/Images/delete_purple.svg";
import Check from "Assets/Images/check_green.svg";
import documentIcon from "Assets/Images/document-annex.svg";
import ImportDocument from "Assets/Images/ImportDocument.svg";
import * as S from "./styles";
import { UploadFileDocumentProps } from "./types";

export const UploadFileDocument = ({
  addIcon,
  label,
  accept,
  onChange,
  file,
  name,
  error,
}: UploadFileDocumentProps) => {

  const [booleans, setBooleans] = useState({
    loading: false,
    uploadVisible: true,
    fileError: false
  });

  useEffect(() => {
    setBooleans((booleans) => ({
      ...booleans,
      uploadVisible: !file
    }))
  }, [file]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFile = e.target.files[0];
      if (newFile) {
        const splitedString = newFile.name.slice(((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
        const isHeic = splitedString === 'heic' ? true : false;

        if (!isHeic) {
          if (
            newFile.type !== "image/jpeg" &&
            newFile.type !== "image/png" &&
            newFile.type !== "image/jpg" &&
            newFile.type !== "image/bmp" &&
            newFile.type !== "image/webp" &&
            newFile.type !== "application/pdf" &&
            newFile.type !== "image/gif"
          ) {
            setBooleans((booleans) => ({ ...booleans, fileError: true }));
            return toast.error('Formato da Carteirinha da Vacinação é Inválido');
          }
        }

        const fileSizeInMB = newFile.size / (1024 * 1024); // Converte bytes para megabytes
        if (fileSizeInMB > 2) {
          setBooleans((booleans) => ({ ...booleans, fileError: true }));
          return toast.error('O arquivo selecionado excede o tamanho máximo permitido de 2MB. Por favor, escolha um arquivo menor.')
        }
        setBooleans((booleans) => ({ ...booleans, fileError: false }));
        
        onChange(newFile);
      }
    }
  };

  const onDeleteFile = () => {
    setBooleans((booleans) => ({
      ...booleans,
      loading: true
    }))
    onChange(null)
    setBooleans((booleans) => ({
      ...booleans,
      loading: false
    }))
  }

  return (
    <div>
      {file && (
        <S.ContainerFile>
          <S.ContainerImg>
            {file?.type?.startsWith("image/") ? (
              <img src={URL.createObjectURL(file)} alt='Imagem' />
            ) : file?.type === "application/pdf" ? (
              <img src={documentIcon} alt="PDF Document" />
            ) : (
              <img src={Check} alt="check" />
            )}
            <p style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '250px'
            }}
            >{file?.name}</p>
          </S.ContainerImg>

          <button type="button" onClick={() => onDeleteFile()}>
            {booleans.loading ? (
              <Spinner color="#572580" style={{ marginRight: "40px" }} />
            ) : (
              <img src={trashIcon} alt="remover" />
            )}
          </button>
        </S.ContainerFile>
      )}
      {booleans.uploadVisible && (
        <S.ContainerFile hasError={booleans.fileError || Boolean(error)} className="addAnexo">
          <img src={addIcon} alt={label} />
          <p>{label}</p>
          <label htmlFor={name} style={{ cursor: "pointer" }}>
            <img src={ImportDocument} alt="" />
          </label>
          <input
            type="file"
            id={name}
            name={name}
            accept={accept}
            onChange={handleChange}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />
          <span>{error}</span>
        </S.ContainerFile>
      )}
    </div>
  )

}
