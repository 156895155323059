/* eslint-disable react/no-this-in-sfc */
import React, { useState } from "react";
import { useHistory } from "react-router";


import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { uniqueDocument, validateFullname } from "Services/schema/validations";

import { createPerson, deletePersonLead } from "Requests/Person";

import { formikComboTypes } from "Utils/LocalTypes";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button/index";
import CheckboxControlled from "Components/Form/Controlled/CheckBoxControlled";
import InputControlled from "Components/Form/Controlled/Input";
import SelectControlled from "Components/Form/Controlled/SelectControlled";

import {
  FormatDocuments,
  FormatEmail,
  FormatPhones,
} from "../../../Pages/Person/utils";
import {
  ErrorProps,
  FormProps,
  ObjectSubmit,
  RegisterFormProps,
} from "./interface";
import { schema, validateDocument } from "./schema";
import { ContainerButton, ContainerFields, ContainerRegisterForm, FlexItemsInput } from "./style";

export const RegisterForm = ({
  budget_id,
  lead,
  leadId,
}: RegisterFormProps) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [valueGender, setValue] = useState(false);

  const form = {
    is_ghost: false,
    name: lead.name,
    email: lead.email || "",
    phone: `(${lead.ddd}) ${lead.phone}` || "",
    gender: "",
    birthday: "",
    type_document: "",
    document: "",
    country: "",
    social_name: "",
    passport: "",
  };

  const editSchema = schema.shape({
    document: Yup.string()
      .min(11, "Documento inválido")
      .max(20, "Documento inválido")
      .when("type_document", {
        is: (value: string) => value === "cpf",
        then: (schema) =>
          schema.test("birthday", "Obrigatório", (value: any, context: any) => {
            const {birthday} = context.parent;
            return validateDocument(value, birthday);
          }),
        otherwise: Yup.string().notRequired(),
      })
      .when("type_document", {
        is: (value: string) => value === "cpf",
        then: (schema) =>
          schema.test("uniqueDocument", "CPF já foi cadastrado", function () {
            // eslint-disable-next-line react/no-this-in-sfc
            const { type_document, document } = this.parent;

            return uniqueDocument({
              type: type_document,
              document,
              ignore_duplicated: false,
            });
          }),
      }),
    passport: Yup.string().when("type_document", {
      is: (value: string) => value === "passport",
      then: Yup.string().required("Obrigatório"),
      otherwise: Yup.string().notRequired(),
    }),
    social_name: Yup.string().when([], {
      is: () => valueGender,
      then: Yup.string()
        .test("social_name", "Nome e Sobrenome", validateFullname)
        .required("Obrigatório"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const formatDate = (date: string) => {
    if (!date) return "";
    const newDate = date.split("/");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  };

  const findError = (error: ErrorProps[] = []) => {
    const errorDocument = error?.find(
      (item) =>
        item?.message?.includes("documents") &&
        item?.message?.includes("exists")
    );

    if (errorDocument) {
      return {
        message: "CPF já cadastrado",
      };
    }
  };

  async function onSubmit(values: FormProps) {
    setIsLoading(true);
    try {
      const isCPF = values?.type_document === "cpf";
      const personObject: ObjectSubmit = {
        is_ghost: values.is_ghost,
        full_name: values.name,
        social_name: values.social_name || undefined,
        channel_id: 2,
        birthdate: formatDate(values.birthday) || undefined,
        gender_id: Number(values.gender) || undefined,
        documents: [
          {
            country_id: isCPF ? 26 : Number(values.country) || undefined,
            number: isCPF
              ? values.document.replace(/\D/g, "")
              : values.passport,
            type: values.type_document,
          },
        ],
        emails: [
          {
            channel_id: 2,
            default: true,
            email: values.email,
          },
        ],
        phones: [
          {
            default: true,
            channel_id: 2,
            ddd: Number(values.phone.substring(1, 3)),
            ddi: 55,
            phone: Number(values.phone.substring(5).replace("-", "")),
          },
        ],
      };

      if (!FormatDocuments(personObject.documents).length) {
        delete personObject.documents;
      }

      if (!FormatPhones(personObject.phones).length) {
        delete personObject.phones;
      }

      if (!FormatEmail(personObject.emails).length) {
        delete personObject.emails;
      }

      const person = await createPerson(personObject);

      if (findError(person.errors)?.message) {
        createToast("error", findError(person.errors)?.message);
        return;
      }

      if (lead.id) {
        await deletePersonLead(person.id, lead.id);
      }

      history.push(`/pedido/agendar/${budget_id}`);
    } catch (error) {
      createToast("error", "Algo de errado aconteceu");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Formik
      initialValues={form}
      validationSchema={editSchema}
      validateOnBlur={false}
      enableReinitialize
      validateOnChange={false}
      onSubmit={(values, { validateForm }) => {
        validateForm(values).then(() => {
          onSubmit(values);
        });
      }}
    >
      {({ values, submitForm }) => {
        const isCPF = values?.type_document === "cpf";
        const isTrans =
          values?.gender === "2" ||
          values?.gender === "4" ||
          values?.gender === "4";

        setValue(isTrans);

        return (
          <ContainerRegisterForm>
            <Form>
              <ContainerFields>
                <Field
                  name="is_ghost"
                  descriptionText="É fantasma ?"
                  component={CheckboxControlled}
                />
                <Field
                  name="name"
                  type="text"
                  className="input _opacity-gray"
                  label={isTrans ? "Nome Civil" : "Nome Completo"}
                  placeholder="Nome e Sobrenome"
                  component={InputControlled}
                />

                <FlexItemsInput>
                  <Field
                    placeholder="Selecione o gênero"
                    name="gender"
                    label="Gênero:"
                    options={formikComboTypes.genders.data}
                    onChange={(value: any) => setValue(value)}
                    component={SelectControlled}
                  />
                  {isTrans && (
                    <Field
                      name="social_name"
                      type="text"
                      className="input _opacity-gray"
                      label="Nome Social"
                      placeholder="Nome Social"
                      component={InputControlled}
                    />
                  )}
                  <Field
                    placeholder="email@gmail.com"
                    name="email"
                    type="email"
                    label="Email"
                    className="input _opacity-gray"
                    component={InputControlled}
                  />

                  <Field
                    placeholder="(99) 99999-9999"
                    name="phone"
                    mask="(99) 99999-9999"
                    type="tel"
                    label="Telefone"
                    className="input _opacity-gray"
                    component={InputControlled}
                  />

                  <Field
                    placeholder="DD/MM/AAAA"
                    name="birthday"
                    mask="99/99/9999"
                    type="tel"
                    className="input _opacity-gray"
                    label="Data de Nascimento"
                    component={InputControlled}
                  />

                  <article>
                    <Field
                      label="Tipo de documento"
                      name="type_document"
                      placeholder="Selecione o tipo do documento"
                      options={[
                        { name: "CPF", id: "cpf" },
                        { name: "Passaporte", id: "passport" },
                      ]}
                      component={SelectControlled}
                    />
                    {values?.type_document === "cpf" && (
                      <Field
                        placeholder="Insira o número do documento"
                        mask={isCPF ? "999.999.999-99" : ""}
                        name="document"
                        className="input _opacity-gray"
                        type="text"
                        component={InputControlled}
                      />
                    )}
                  </article>
                  {values?.type_document === "passport" && (
                    <>
                      <Field
                        label="Número do passaporte"
                        placeholder="Número do passaporte"
                        name="passport"
                        className="input _opacity-gray"
                        type="text"
                        component={InputControlled}
                      />

                      <Field
                        label="País emissor"
                        placeholder="No caso de passaporte, selecione o país"
                        name="country"
                        className="input _opacity-gray"
                        options={formikComboTypes.countrys.data}
                        component={SelectControlled}
                      />
                    </>
                  )}
                </FlexItemsInput>
              </ContainerFields>
              <ContainerButton>
                <Button
                  disabled={isLoading}
                  type="button"
                  classButton="_action radiusButton"
                  loading={isLoading}
                  onClick={submitForm}
                >
                  Finalizar Cadastro
                </Button>
              </ContainerButton>
            </Form>
          </ContainerRegisterForm>
        );
      }}
    </Formik>
  );
};
