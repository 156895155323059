import { CampFile } from "Components/Form/UploadFiles/styles";

import IconPDF from "Assets/Images/icon-pdf.svg";
import trashIcon from "Assets/Images/trashTransparent.svg";

interface AttachedFilesProps {
  documents: {
    id: number;
    isImage: boolean;
    preview: string;
    filename: string;
    file_path: string;
  };
  deleteDocument: (arg: string) => void;
}

export const AttachedFiles = ({
  documents,
  deleteDocument,
}: AttachedFilesProps) => {
  const { filename, file_path, id, isImage, preview } = documents;
  return (
    <CampFile key={id} className="active">
      <div className="space-file-name">
        {isImage ? (
          <img src={preview} alt="imagem arquivo" />
        ) : (
          <img src={IconPDF} alt="PDF icon" />
        )}
        <span>{filename}</span>
      </div>
      <button
        className="button-trash"
        onClick={() => deleteDocument(file_path)}
        style={{
          cursor: "pointer",
          border: "none",
          backgroundColor: "transparent",
        }}
      >
        <img src={trashIcon} alt="remover" />
      </button>
    </CampFile>
  );
};
