export const convertBase64ToBlob = (base64String, mimeType) => {
  try {
    const base64 = `data:${mimeType};base64,${base64String}`;
    const bytes = atob(base64.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(bytes.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < bytes.length; i++) {
      uint8Array[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeType });
    const fileUrl = URL.createObjectURL(blob);

    return { fileUrl, mimeType };
  } catch (error) {
    console.error("Erro ao converter Base64 para Blob:", error);
    return "";
  }
};

export function ConvertBase64ToFile(base64Strings, fileNames) {
  const files = base64Strings.map((base64String, index) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const fileName = fileNames[index] || `file_${index}`; // Nome padrão se não for fornecido
    const file = new File([byteArray], fileName, { type: "image/jpeg" }); // Substitua 'image/jpeg' pelo tipo de arquivo adequado

    return file;
  });

  return files;
}

export const getMimeTypeFromUrl = async (url) => {
  const lowercasedUrl = url.toLowerCase();

  if (
    lowercasedUrl.endsWith(".jpg") ||
    lowercasedUrl.endsWith(".jpeg") ||
    lowercasedUrl.endsWith(".png") ||
    lowercasedUrl.endsWith(".gif")
  )
    return "image/jpeg";

  return "application/pdf";
};

export function base64ToImageAndPdf(base64String) {
  const binaryString = atob(base64String);

  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: "application/octet-stream" });

  return URL.createObjectURL(blob);
}
