import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .toggles-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 56px;
  }

  .checkbox-list {
    display: flex;
    gap: 26px;
    margin-top: 15px;
  }
`;

export const AttendanceDays = styled.div`
  width: 100%;

  .attendance-days {
    width: 100%;
    display: grid;
    grid-template-columns: 100px minmax(400px, 580px);
    align-items: center;
    gap: 50px;

    .field-holder {
      margin-right: 20px;
    }

    label {
      white-space: nowrap;
      font-weight: 500;
      color: var(--color-gray-text);
    }
  }
`;

export const CheckboxSublist = styled.div<{ height: number }>`
  ${({ height }) =>
    css`
      height: ${height ? `${height + 20}px` : "initial"};
    `};

  &.checkbox-units {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
