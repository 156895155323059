import { useState } from "react";
import { useQuery } from "react-query";

import { GetDetails } from "Requests/Orders";

import { validateObjectType } from "Utils/Objects";
import { createToast } from "Utils/toastFunc";

import { ErrorProps, Order, UseProduct } from "./interface";

export const useOrderShow = (orderIdSelected?: number): UseProduct => {
  const [orderId, setOrderId] = useState<number | undefined>(() => {
    if (orderIdSelected) {
      return orderIdSelected;
    }
    return undefined;
  });

  const { data, isLoading, isError, error } = useQuery<Order, ErrorProps>(
    ["order-show", orderId],
    () => GetDetails(orderIdSelected),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!orderIdSelected,
      onError: ({ message }) => createToast("error", message),
    }
  );

  const setNewOrderId = (newOrderId: number) => {
    setOrderId(newOrderId);
  };

  return {
    orderInfo: validateObjectType(data?.data) ? data?.data : undefined,
    loadingOrderInfo: isLoading,
    OrderInfoError: isError ? error.message : null,
    setNewOrderId,
  };
};
