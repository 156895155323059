import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { Loading } from "Utils/Loading";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import Clock from "../../Assets/Images/clock.png";
import Gift from "../../Assets/Images/gift.png";
import Scissors from "../../Assets/Images/scissors.png";
import { WalletLabiCredits } from "../../Requests/LabiCredits";
import { dateFormatter } from "../../Utils/Date";
import { createToast } from "../../Utils/toastFunc";
import CardExtractAccount from "./CardsLabiCredits/CardExtractAccount";
import CreditsAvaliable from "./CardsLabiCredits/CreditsAvaliable";
import { ModalUsersSearch } from "./Modals/ModalUsersSearch";
import { PageContent } from "./styles";

export default function MyAccountLabiCredits() {
  const [users, setUsers] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({});
  const [modalVisible, setModalVisible] = React.useState(false);
  const [orderData, setOrderData] = React.useState({});

  const { userID } = useParams();
  const history = useHistory();

  async function fetchData(page) {
    !page && setPagination({});
    try {
      setLoading(true);
      const res = await WalletLabiCredits(page, { promoter_id: userID });

      if (res.errorMessage) {
        createToast(
          "error",
          "Não foi possível buscar as informações dessa carteira"
        );
        return history.push("/labi-creditos/minha-conta");
      }

      setUsers(res[0]);
      setPagination(res[0].pagination[0]);
    } catch (error) {
      createToast(
        "error",
        "Não foi possível buscar as informações dessa carteira"
      );
    } finally {
      setLoading(false);
    }
  }

  function formatterDate(date) {
    return date !== "None" ? dateFormatter(date, true)[0] : "";
  }

  function handlePagination(e) {
    if (Number(pagination.page) === e.currentPage) return;
    fetchData(e.currentPage);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <ModalUsersSearch
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        data={orderData}
        username={users.name ? users.name : "Nome não encontrado"}
      />
      <div className="content-holder">
        <div className="page-heading inputs-solid center-items">
          <div className="wrapper-header-content">
            <PageHeader
              title={`Carteira de ${
                users.name ? users.name : "usuário não encontrado"
              }`}
            >
              <Breadcrumb
                list={[
                  {
                    path: "/labi-creditos",
                    label: "Labi Créditos",
                    current: false,
                  },
                  {
                    path: "/labi-creditos/minha-conta",
                    label: "Minha conta",
                    current: false,
                  },
                  {
                    path: "#",
                    label: `Conta de ${
                      users.name ? users.name : "usuário não encontrado"
                    }`,
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>

          {Object.keys(users).length ? (
            <div className="cards-flex-labi-credits">
              <CreditsAvaliable
                image={Gift}
                alt="Imagem de um presente"
                title="Créditos Disponivieis"
                subtitle={new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(
                  users.avaliable_amount === "None" ? 0 : users.avaliable_amount
                )}
              />

              <CreditsAvaliable
                image={Clock}
                alt="Imagem de um relogio"
                type="default"
                title="Créditos a liberar"
                subtitle={
                  users.to_available.amount && users.to_available.valid_at
                    ? `${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(users.to_available.amount)} em ${
                        dateFormatter(users.to_available.valid_at)[0]
                      }`
                    : "Nenhum crédito a liberar"
                }
              />

              <CreditsAvaliable
                image={Scissors}
                type="default"
                title="Créditos a vencer"
                subtitle={
                  users.to_expire.amount && users.to_expire.expire_at
                    ? `${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(users.to_expire.amount)} em ${
                        dateFormatter(users.to_expire.expire_at)[0]
                      }`
                    : "Nenhum crédito a vencer"
                }
              />
            </div>
          ) : null}
        </div>
        <PageContent className="page-content">
          <div className="center-page generate-margin-1">
            {!loading ? (
              <>
                {users.movements &&
                  users.movements.map((item) => (
                    <React.Fragment key={item.id}>
                      {item.operation_id === 1 && (
                        <CardExtractAccount
                          type="money"
                          text="Seu saldo foi atualizado e já está disponível para utilizar em compras!"
                          firstDate={formatterDate(item.valid_at)}
                          amount={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount)}
                        />
                      )}
                      {item.operation_id === 5 && (
                        <CardExtractAccount
                          type="due-date"
                          text="Seu código de indicação foi utilizado! Aguarde a liberação do seu crédito."
                          firstDate={formatterDate(item.created_at)}
                          secondText={formatterDate(item.valid_at)}
                          amount={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount)}
                        />
                      )}
                      {[4].includes(item.operation_id) && (
                        <CardExtractAccount
                          type="exit"
                          text="Seu crédito expirou"
                          firstDate={formatterDate(item.created_at)}
                          amount={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount)}
                        />
                      )}
                      {[3, 6].includes(item.operation_id) && (
                        <CardExtractAccount
                          type="exit"
                          text="Seu crédito foi estornado."
                          amount={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount)}
                        />
                      )}
                      {item.operation_id === 7 && (
                        <CardExtractAccount
                          type="exit"
                          text="Seu crédito foi cancelado"
                          firstDate={formatterDate(item.created_at)}
                          amount={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount)}
                        />
                      )}
                      {item.operation_id === 2 && (
                        <CardExtractAccount
                          type="buy-with-credits"
                          text="Você economizou comprando com Labi Créditos!"
                          secondText="Ver pedido"
                          firstDate={formatterDate(item.created_at)}
                          item={item}
                          setOrderData={setOrderData}
                          setModalVisible={setModalVisible}
                          isPurple
                          isLink="/"
                          amount={new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount)}
                        />
                      )}
                    </React.Fragment>
                  ))}

                <div>
                  {pagination.total_pages && (
                    <Pagination
                      currentPageSelected={Number(pagination.page)}
                      totalRecords={pagination.total_pages}
                      pageLimit={1}
                      pageNeighbours={1}
                      onPageChanged={(e) => handlePagination(e)}
                    />
                  )}
                </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </PageContent>
      </div>
    </PageWrapper>
  );
}
