import * as yup from "yup";

export const schemaPacient = yup.object().shape({
  first_name: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\u0020\u0021-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007e]*$/,
      "Somente letras são permitidas"
    )
    .required("Campo obrigatório"),
  email: yup.string().email(),
  telphone: yup.string().when("email", {
    is: (email: string) => !email,
    then: yup
      .string()
      .required("Campo obrigatório")
      .min(16, "Celular inválido"),
    otherwise: yup.string(),
  }),
});
