import { TableBody } from "../Table/BodyTable";
import { TableHeader } from "../Table/HeaderTable";
import { ListBudgetProps } from "./interface";

export const ListBudgets = ({ data }: ListBudgetProps) => {
  return (
    <table className="table-content small-separate details">
      <TableHeader />
      <TableBody listBudgets={data} />
    </table>
  );
};
