import styled, { css } from "styled-components";

export const FlexDetailsItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
`;

export const Centering = styled.div`
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
`;

export const ContainerRevision = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  flex-wrap: wrap;
  gap: 1.1rem;
  padding: 1rem 1.5rem;

  padding-bottom: 0;
`;

export const ContainerUnity = styled.div`
  padding: 0 1.5rem;
`;

export const BorderSeparate = styled.div`
  margin: 1.125rem 1.5rem;
  border-bottom: 1px solid #ebebeb;
`;

export const ContentArticle = styled.article`
  ${(props) =>
    props?.open &&
    css`
      section:first-child {
        height: 100%;
      }
    `}
`;

export const TitleUnity = styled.p`
  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;

  letter-spacing: 0.5px;

  color: var(--color-purple);
`;

export const ContainerUnityForm = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  div:first-child {
    flex: 0 0 60%; 
  }

  .paid-value {
    justify-content: flex-end;

    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    div:last-child {
      margin-top: 0;
    }
  }

  margin-bottom: 1.125rem;
`;

export const TitleFinalValue = styled.p`
  &,strong {
    margin: 0;
    padding: 0;
    font-family: var(--font-display);
  }

  font-style: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #999999;

  strong,
  span {
    color: var(--color-purple);
    font-weight: 600;
  }

  strong {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const ContainerButtonRevision = styled.div`
  margin-top: 1rem;
  text-align: right;
  button {
    padding: 8px 36px !important;
  }
`;
