import React, { useState } from "react";
import { useHistory } from "react-router";

import { File } from "buffer";
import { Field, FieldArray, Form, Formik } from "formik";
import { SubListCheckbox } from "Pages/Product/Components/SubListCheckbox";
import { CheckboxSublist } from "Pages/Product/ProductForm/SectionsForm/Restriction/styles";
import { Box } from "styles/globalComponents";

import { deleteImageCovenant } from "Requests/RequestInsurance";

import { useRegions } from "Hooks/useRegions";
import { useSelectUnits } from "Hooks/useSelectUnits";

import { createToast } from "Utils/toastFunc";
import { formatUnitsRequest } from "Utils/Units/Units";

import { CheckboxList } from "Components/CheckboxList";
import { CreatableTopicList } from "Components/Form/CreatebleTopicList";
import { CustomCheckbox } from "Components/Form/CustomCheckbox";
import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";
import UploadInsurance from "Components/Form/UploadInsurance";
import PanelContent from "Components/Panels/PanelContent";
import SwitchInput from "Components/Switch/Input";

import CloseIcon from "Assets/Images/close.svg";
import AnexDocument from "Assets/Images/document-annex.svg";
import AddDocument from "Assets/Images/icon-add.svg";
import ImportDocument from "Assets/Images/import.svg";
import trashIcon from "Assets/Images/trash_cancel.png";

import { useHealthInsurance } from "../useHealthInsurance";
import {
  AddPlan,
  DradDrop,
  Droppable,
  FormHealthInsuranceProps,
  FormInitialValues,
  SetField,
} from "./interface";
import { schema } from "./schema";
import { CampFile, PositionAnnex, Container } from "./styles";
import { alreadyExistsPlan, convertToFormData } from "./utils";

export const HealthInsuranceForm = ({
  onSubmit,
  initialValues,
}: FormHealthInsuranceProps) => {
  const [plan, setPlan] = useState("");

  const { regions, loadingRegions, regionsError } = useRegions();

  const { channel, loadingChannel } = useHealthInsurance();

  const { selectAllUnits, selectRegion, selectUnit } = useSelectUnits();

  const history = useHistory();

  const isEdit = history.location.pathname.includes("editar");

  const handleSubmit = (values: FormInitialValues) => {
    try {
      const formattedValues = {
        ...values,
        docs: values.docs.filter((item: any) => !item.id),
        covenant_channels: values.covenant_channels.map(
          (covenant_channel) => covenant_channel.id
        ),
        covenant_plans: values.covenant_plans_list,
        units: {
          ...formatUnitsRequest(values.units, regions),
        },
      };
      if (!formattedValues.external_link) {
        delete formattedValues.external_link;
      }

      const formattedDocs = convertToFormData({
        docs: formattedValues.docs,
        file: formattedValues.file,
      });

      onSubmit(formattedValues, formattedDocs);
    } catch (error) {
      createToast("error", "Não foi possível cadastrar o convenio");
    }
  };

  const handleDragEnd = ({ result, setField, values }: DradDrop) => {
    if (!result.destination) return;
    const items = Array.from(values);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setField("plans_list", items);
  };

  const handleAddPlan = ({ event, setField, values }: AddPlan) => {
    const plansList = values?.covenant_plans_list || [];
    const plansSelected = values.covenant_plans;

    if (event.key === "Enter") {
      const plans = plan.split(";");

      plans.forEach((plan) => {
        if (alreadyExistsPlan(plan, plansSelected)) {
          return createToast("error", "Plano ja adicionado");
        }
        if (plan.trim() === "") {
          return createToast(
            "error",
            "Não é possível adicionar um valor vazio."
          );
        }

        setField("covenant_plans", [...plansSelected, plan]);
        setField("covenant_plans_list", [
          ...plansList,
          { covenant_plans_name: plan },
        ]);
      });

      setPlan("");
      event.preventDefault();
    }
  };

  const handleRemovePlan = (
    id: number,
    plan: string,
    remove: any,
    values: any,
    setFieldValue: any
  ) => {
    const plansList = values?.covenant_plans_list || [];
    remove(id);

    setFieldValue(
      "covenant_plans_list",
      plansList.filter((item: any) => item.covenant_plans_name !== plan)
    );
  };

  const addAttachment = (
    e: React.ChangeEvent<HTMLInputElement>,
    currValues: File[],
    setField: SetField
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const uploadFiles = [...currValues, file];
      setField("docs", uploadFiles);
    }
  };

  const onDeleteImage = async (idImage: number, index: number, remove: any) => {
    let statusCode = 200;

    if (idImage) {
      const status: any = await deleteImageCovenant(initialValues?.id, idImage);
      statusCode = status;
    }

    if (statusCode === 200) remove(index);
  };

  const handleHomeCollect = (
    setFieldValue: any,
    name: string,
    value: boolean
  ) => {
    setFieldValue(name, value);

    if (!value) setFieldValue("free_visit", false);
  };

  const heighestUnitblock =
    regions
      ?.map((unit) => unit?.units?.length)
      .filter(Boolean)
      .sort((a, b) => a - b)
      .at(-1) ?? 0;

  return (
    <Container className="content-holder holderPanelActive">
      <PanelContent title="Informações Convênio" loading={loadingRegions}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue, touched, errors }) => {
            const handleSelectAllUnits = () => {
              const units = selectAllUnits(values.units);
              setFieldValue("units", units);
            };
            const handleSelectRegion = (regionId: number) => {
              const units = selectRegion(regionId, values.units);
              setFieldValue("units", units);
            };
            const handleSelectUnit = (unitId: number) => {
              const units = selectUnit(unitId, values.units);
              setFieldValue("units", units);
            };

            return (
              <Form id="submit">
                <div className="insurance-flex">
                  <div className="input-fields">
                    <Field
                      component={Input}
                      onChange={handleChange}
                      label="Nome do Convênio *"
                      id="covenant"
                      type="text"
                      value={values.covenant}
                      error={touched.covenant && errors.covenant}
                    />

                    <Field
                      component={Input}
                      onChange={handleChange}
                      label="URL Externa"
                      id="external_link"
                      type="text"
                      value={values.external_link}
                      error={touched.external_link && errors.external_link}
                    />
                  </div>

                  <Field
                    component={UploadInsurance}
                    importPath="/product/import"
                    fileAccept=".png, .jpg, .jpeg"
                    allowExtensions={["png", "jpg", "jpeg"]}
                    files={values.file}
                    sendFormData={(file: File[]) => {
                      setFieldValue("file", file);
                    }}
                    name="file"
                    id="file"
                    error={touched.file && errors.file}
                  />
                </div>
                <div className="insurance-flex">
                  {/* @ts-ignore */}
                  <SwitchInput
                    label="Permite coleta domiciliar?"
                    id="home_collect_permission"
                    onChange={(isChecked: any) =>
                      handleHomeCollect(
                        setFieldValue,
                        "home_collect_permission",
                        !!isChecked
                      )
                    }
                    value={values.home_collect_permission === true}
                    textOn="Sim"
                    textOff="Não"
                  />
                  {/* @ts-ignore */}
                  <SwitchInput
                    label="Possui isenção da taxa de coleta domiciliar?"
                    id="free_visit"
                    onChange={(isChecked: any) =>
                      setFieldValue("free_visit", !!isChecked)
                    }
                    disabled={!values.home_collect_permission}
                    value={values.free_visit === true}
                    textOn="Sim"
                    textOff="Não"
                  />
                </div>

                <Field
                  component={Input}
                  onChange={handleChange}
                  label="Resumo"
                  id="description"
                  type="text"
                  value={values.description}
                  error={errors.description}
                  multiline
                />
                <div className="row avaliable-channels">
                  <div className="lg-6 md-6 sm-12">
                    <SelectSearch
                      label="Canais disponíveis *"
                      id="covenant_channels"
                      value={values.covenant_channels}
                      isMulti
                      options={channel}
                      loading={loadingChannel}
                      readOnly={!channel.length}
                      placeholder={
                        channel.length
                          ? "Selecione os canais"
                          : "Sem opções disponíveis"
                      }
                      valueKey="id"
                      labelKey="name"
                      onChange={(value: { id: number; name: string }[]) =>
                        setFieldValue("covenant_channels", [...value])
                      }
                      error={
                        touched.covenant_channels && errors.covenant_channels
                      }
                    />
                  </div>
                </div>

                {!loadingRegions && !regionsError && (
                  <CheckboxList
                    error={
                      touched.units?.include && errors?.units?.include
                        ? errors.units.include
                        : ""
                    }
                    title=""
                  >
                    <label htmlFor="" className="text-gray text-semibold">
                      Unidades que atendem
                    </label>
                    <Box mt="5">
                      <Field
                        component={CustomCheckbox}
                        id="units"
                        type="checkbox"
                        label="Todas unidades"
                        name="units"
                        onChange={handleSelectAllUnits}
                        checked={values.units.include.includes(0)}
                      />

                      <Box mt="10">
                        <CheckboxSublist
                          className="checkbox-units"
                          height={heighestUnitblock * 31}
                        >
                          {regions?.map((region) => (
                            <SubListCheckbox
                              key={region.id}
                              region={region}
                              handleSelectAllRegionUnits={(regionId) =>
                                handleSelectRegion(regionId)
                              }
                              handleSelectUnits={(unitId) =>
                                handleSelectUnit(unitId)
                              }
                            />
                          ))}
                        </CheckboxSublist>
                      </Box>
                    </Box>
                  </CheckboxList>
                )}

                <label htmlFor="" className="text-gray text-semibold">
                  Planos que atendem *
                </label>
                <div
                  className={`input _opacity-gray tag ${
                    touched.covenant_plans && errors.covenant_plans && "invalid"
                  }`}
                >
                  <input
                    type="text"
                    placeholder='Insira aqui os planos adicionando "; ” (ponto e vírgula + ”espaço")  entre os itens e aperte ”Enter” ao final  para cadastros em massa'
                    name="add_plans"
                    id="add_plans"
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      handleAddPlan({
                        event: e,
                        values,
                        setField: setFieldValue,
                      })
                    }
                  />

                  <FieldArray
                    name="covenant_plans"
                    render={({ remove }) => {
                      return values.covenant_plans?.map((plan, idx) => (
                        <div className="tag-item" key={idx}>
                          <p>{plan.substring(0, 20)}</p>{" "}
                          <div
                            onClick={() =>
                              handleRemovePlan(
                                idx,
                                plan,
                                remove,
                                values,
                                setFieldValue
                              )
                            }
                            className="close-item"
                            role="button"
                            onKeyDown={() =>
                              handleRemovePlan(
                                idx,
                                plan,
                                remove,
                                values,
                                setFieldValue
                              )
                            }
                            tabIndex={-1}
                          >
                            <img src={CloseIcon} alt="" />
                          </div>
                        </div>
                      ));
                    }}
                  />
                </div>

                <div
                  className={`checklist ${
                    touched.covenant_checklist && errors.covenant_checklist
                      ? "invalid"
                      : ""
                  }`}
                >
                  <FieldArray
                    name="covenant_checklist"
                    render={(arrayHelper) => {
                      return (
                        <Field
                          component={CreatableTopicList}
                          label="CHECKLIST *"
                          name="covenant_checklist"
                          mapValues={values.covenant_checklist}
                          cssClass="w-100"
                          addToPackage={(value: string) =>
                            arrayHelper.push(value)
                          }
                          deleteItem={(index: number) =>
                            arrayHelper.remove(index)
                          }
                          draggable
                          handleDragEnd={(result: Droppable) =>
                            handleDragEnd({
                              result,
                              setField: setFieldValue,
                              values: values.covenant_checklist,
                            })
                          }
                        />
                      );
                    }}
                  />
                  {errors.covenant_checklist && touched.covenant_checklist && (
                    <span className="error-message">
                      {errors.covenant_checklist}
                    </span>
                  )}
                </div>
                <div className="row flex">
                  <div className="md-12 xs-12 sm-12">
                    <Field
                      component={Input}
                      onChange={handleChange}
                      label="Acessos"
                      multiline
                      className="input _opacity-gray tag max-wigth"
                      id="access"
                      value={values.access}
                      placeholder="Insira aqui as informações gerais sobre acesso ao portal do convênio"
                      rows={6}
                    />
                  </div>

                  <div className="md-12 xs-12 sm-12">
                    <Field
                      component={Input}
                      label="Contato"
                      multiline
                      onChange={handleChange}
                      className="input _opacity-gray tag max-wigth"
                      id="contact"
                      value={values.contact}
                      placeholder="Insira aqui as informações de contato para suporte junto ao convênio"
                      rows={6}
                    />
                  </div>
                </div>
                <PositionAnnex>
                  <label htmlFor="" className="text-gray text-semibold">
                    Anexos
                  </label>
                  {/* ? `${process.env.REACT_APP_API_URL_IMAGE}${file.img}` */}
                  <div className="flex-wrap">
                    <FieldArray
                      name="docs"
                      render={({ remove }) => {
                        return values.docs?.map((file: any, index: any) => (
                          <CampFile key={file.name + index}>
                            <img
                              src={
                                file.type.startsWith("image/")
                                  ? URL.createObjectURL(file)
                                  : AnexDocument
                              }
                              alt={file.name}
                            />
                            <span>{file.name}</span>
                            <button
                              type="button"
                              style={{
                                cursor: "pointer",
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              onClick={() =>
                                onDeleteImage(file?.id, index, remove)
                              }
                            >
                              <img src={trashIcon} alt="remover" />
                            </button>
                          </CampFile>
                        ));
                      }}
                    />

                    <CampFile className="addAnexo">
                      <img src={AddDocument} alt="" />
                      <p>PÁGINA ADICIONAL</p>

                      <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                        <img src={ImportDocument} alt="" />
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          addAttachment(e, values.docs, setFieldValue)
                        }
                        multiple
                        style={{ display: "none" }}
                      />
                    </CampFile>
                  </div>
                </PositionAnnex>
              </Form>
            );
          }}
        </Formik>
      </PanelContent>
    </Container>
  );
};
