import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  z-index: 202;
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 0;
  flex-direction: column;
`;
