import API from "Services/Api";

import {
  formatterSchedule,
  formatterSearch,
  formatterScheduleWeek,
} from "./Formatters/Schedule";

export async function getAppointments(data, hours) {
  try {
    const res = await API.post("/appointments", data);
    const formatter =
      data.type === "days"
        ? formatterSchedule(res)
        : formatterScheduleWeek(res, hours);
    return formatter;
  } catch (error) {
    console.log(error);
  }
}

export async function getSearchAppointments(data) {
  try {
    const res = await API.post("/appointments", data);
    return formatterSearch(res);
  } catch (error) {
    console.log(error);
  }
}

export async function getAppointmentsMonth(_data) {
  try {
    const { data } = await API.post("/appointments", _data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getCalendars() {
  try {
    const { data } = await API.get("/appointments/calendars");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getHistoric(email) {
  try {
    const { data } = await API.get(`/appointments/historic/${email}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getHoursAvaliableSchedule(_data) {
  try {
    const { data } = await API.post(`/appointments/times`, _data);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function rescheduleAppointments(id, date) {
  try {
    const { data } = await API.get(
      `appointments/reschedule/${id}/date/${date}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function cancelAppointments(id, _data) {
  try {
    const data = await API.post(`appointments/cancel/${id}`, _data);
    return data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}

export async function editAppointment(id, data) {
  try {
    const res = await API.post(`/appointments/update/${id}`, data);
    return res;
  } catch (error) {
    return error.response;
  }
}
