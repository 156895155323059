import { useEffect, useState } from "react";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { Container } from "./styles";
import { BooleansType, CardAttendanceTypeProps } from "./types";

export const CardAttendanceType = ({
  id,
  image,
  webpImage,
  label,
  formattedAttendances,
  currentAttendance,
  validations,
  handleSelect,
  selected,
  disableOption,
  homeCollectValue,
  order,
  homeCollectTax
}: CardAttendanceTypeProps) => {

  const [booleans, setBooleans] = useState<BooleansType>({
    disabledCard: false,
  });

  useEffect(() => {
    setBooleans((booleans) => ({
      ...booleans,
      disabledCard: verifyDisabledCard()
    }));
  }, [currentAttendance]);

  useEffect(() => {
    setBooleans((booleans) => ({
      ...booleans,
      disabledCard: verifyDisabledCard()
    }))
  }, [formattedAttendances]);



  const verifyDisabledCard = () => {
    let result = true;

    if (currentAttendance.type === id || currentAttendance.type === 'both') {
      result = false;
    }

    if (formattedAttendances.length > 0) {
      if (currentAttendance.type === 'both') {
        const hasUnityChecked = formattedAttendances.some((a) => a.type === 'unity_collect');
        const hasHomeChcked = formattedAttendances.some((a) => a.type === 'home_collect')

        result = id === 'unity_collect' ? hasUnityChecked : hasHomeChcked
      }
    }

    if (currentAttendance.type === 'both') {
      result = false
    }

    return result
  }

  const renderCollectLabel = () => {

    if (id === "unity_collect") return '';

    if (id === "home_collect" && currentAttendance.type === 'unity_collect') return '';

    if (homeCollectTax != 0 && ((currentAttendance.type === 'both' || currentAttendance.type === 'home_collect') && id === 'home_collect')) return `R$ ${replaceStringNumber(homeCollectTax)}`;

    const hasInsentTax =
      (order.homeCollectValue === 0 || order.homeCollectValue === '0,00') &&
      (currentAttendance.type === "home_collect" || currentAttendance.type === "both") && homeCollectTax === 0;

    const renderTextSpan = (text: string, width: string) => (
      <span
        style={{
          color: "#666",
          display: "block",
          width,
          textAlign: "right",
          lineHeight: "18px",
          fontSize: "12px",
        }}
      >
        {text}
      </span>
    );

    if (currentAttendance.type === "both") {
      if (id === "unity_collect") return ``;

      if (id === "home_collect" && hasInsentTax && homeCollectValue === 0) {
        return (
          <span>
            <span className="text-through">R$ 49,00</span>
            <br />
            ISENTO
          </span>
        );
      }

      if (id === "home_collect" && homeCollectValue !== 0) {
        return (
          <span>
            <span>R$ 49,00</span>
          </span>
        );
      }
    }

    if (currentAttendance.type === "unity_collect" && id === "unity_collect") {
      if (validations.partnerProduct) {
        return renderTextSpan("LEVAR VOUCHER À UNIDADE", "64px");
      }

      if (validations.isCorona) {
        return renderTextSpan("PAGAMENTO EXCLUSIVAMENTE NAS UNIDADES", "123px");
      }

      return ``;
    }

    if (currentAttendance.type === "home_collect" && id === "home_collect") {
      if (validations.inactive && !validations.isCorona) return "INDISPONÍVEL";

      if (validations.partnerProduct) return "GRATUITO";

      if (!validations.inactive && !validations.isCorona && hasInsentTax) {
        return (
          <span>
            <span className="text-through">
              R$ {replaceStringNumber(order.homeCollectValue)}
            </span>
            <br />
            ISENTO
          </span>
        );
      }

      return `R$ ${replaceStringNumber(order.homeCollectValue)}`;
    }

    if (hasInsentTax && currentAttendance.type === "home_collect" && id === "home_collect") {
      return (
        <span>
          <span className="text-through">
            R$ {replaceStringNumber(order.homeCollectValue)}
          </span>
          <br />
          ISENTO
        </span>
      );
    }


  

    return `R$ ${typeof order.homeCollectValue === 'number' ? replaceStringNumber(order.homeCollectValue) : order.homeCollectValue}`
  };

  return (
    <Container
      className={`
          collect-cards
          ${selected === id ? "_selected" : ""}
          ${booleans.disabledCard || disableOption ? "_inactive" : ""}
      `}

      onClick={() => {
        if (booleans.disabledCard || disableOption) return
        handleSelect(id)
      }}
    >
      <div className="budgeting-header">
        <div className="info-item">
          <figure>
            <picture>
              <source srcSet={webpImage} type="image/webp" />
              <img src={image} alt={label} loading="lazy" />
            </picture>
          </figure>
          <p className="text-lgray text-no-margin text-left text-info-title" style={{ fontWeight: 600 }}>
            {label}
          </p>
        </div>
        <div className="info-item">
          <div className="container-values-fee">
            <p className="text-lgray text-no-margin text-center" style={{ fontWeight: 600 }}>
              {renderCollectLabel()}
            </p>
          </div>
        </div>
      </div>
    </Container>
  )

}