import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "Services/Authentication";

const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			!isAuthenticated() === true ? (
				<Layout {...rest}>
					<Component {...props} />
				</Layout>
			) : (
				<Redirect
					to={{
						pathname: "/pessoas",
						state: {
							from: props.location
						}
					}}
				/>
			)
		}
	/>
);

export default PublicRoute;
