import { useEffect } from "react";
import { useHistory, useParams } from "react-router";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useProducts } from "Hooks/useProducts";
import { useStoreHandler } from "Hooks/useStoreFilters";

import { arrayOfServices } from "mocks";

export const useProductList = () => {
  const { budget_id } = useParams<{ budget_id: string }>();
  const { getStoredFilters } = useStoreHandler();

  const history = useHistory() as any;

  const hasToLoadProducts =
    history.location.state?.from === "from-select-attendance-page" ||
    history.location.state?.from === "from-budget-type-page";

  const {
    isCovenantBudget,
    selectedCovenant,
    productsCategoriesSelected,
    zipCode,
    budget,
  } = useBudgetContext();

  const initialFilters = {
    categories: productsCategoriesSelected?.join(","),
    ...(isCovenantBudget && {
      plan_id: isCovenantBudget ? selectedCovenant?.covenant_plan.id : "",
    }),
    ...(zipCode && { zip_code: zipCode || "" }),
    ...(isCovenantBudget && {
      collect_type: zipCode ? "home_collect" : "unity_collect",
    }),
  };

  const {
    productsList,
    loadingProducts,
    setProductFilters,
    fetchNewPage,
    pageSelected,
    productsPagination,
  } = useProducts({
    enableReq: hasToLoadProducts,
    initialFilters: !budget_id && hasToLoadProducts ? initialFilters : {},
  });

  const isHomeCollect = zipCode || budget?.zip_code;

  const savedFilters = getStoredFilters("savedFilters");

  const getProducts = (searchFilters: Object) => {
    setProductFilters(searchFilters);
  };

  const retrieveProductsListOnPageRefresh = () => {
    const retrivedCategoriesOptions = arrayOfServices.find((service) => {
      if (productsCategoriesSelected)
        return service.categories_included.includes(
          productsCategoriesSelected[0]
        );

      if (budget) {
        return service.categories_included.includes(budget.budget_type);
      }
    });

    if (retrivedCategoriesOptions) {
      const commonFilterObj = {
        ...(selectedCovenant && {
          plan_id: selectedCovenant?.covenant_plan.id,
        }),
        ...(isHomeCollect && { zip_code: zipCode }),
        ...(selectedCovenant && {
          collect_type: isHomeCollect ? "home_collect" : "unity_collect",
        }),
        categories: retrivedCategoriesOptions?.categories_included.join(","),
      };

      const filters = {
        ...(!!savedFilters && {
          ...savedFilters,
          ...(savedFilters.services && {
            services: JSON.parse(savedFilters.services)
              .map((service: { id: number }) => service.id)
              .join(","),
          }),
        }),
        ...commonFilterObj,
      };

      getProducts({ ...filters });
    }
  };

  useEffect(() => {
    if (!hasToLoadProducts) retrieveProductsListOnPageRefresh();
  }, []);

  return {
    loadingProducts,
    getProducts,
    productsList,
    fetchNewPage,
    pageSelected,
    productsPagination,
  };
};
