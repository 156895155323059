import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  margin-top: 16px;

  .prepare-products-column-1 {
    gap: 200px;
    align-items: start;
  }

  .additional-info {
    min-width: 100%;
  }
`;
