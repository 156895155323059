import styled, { css } from "styled-components";

interface DefaultStyles {
  last?: any;
  first?: any;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0 !important;
  flex-direction: column;
  border-radius: 24px;
`;

export const Button = styled.button<DefaultStyles>`
  display: flex;
  padding: 30px 0;
  width: 100% !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.last &&
    css`
      border-radius: 0 0 24px 24px;
    `}

  ${(props) =>
    props.first &&
    css`
      border-radius: 24px 24px 0 0;
    `}

  &:hover {
    background-color: #f7f7f7;
    transition: 0.5s;
  }

  p {
    margin: 0;
    margin-left: 27px;
    color: #999999;
  }

  &.checked {
    &::before {
      content: "";
      display: block;
      width: 0px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgb(171, 146, 191);
      width: 8px;
      height: 100%;
      z-index: 10;
      ${(props) =>
        props.first &&
        css`
          border-radius: 50px 0 0 0;
          height: 99px;
          margin-top: 5px;
        `}
      ${(props) =>
        props.last &&
        css`
          border-radius: 0 0 0 50px;
          height: 99px;
          margin-bottom: 5px;
        `}
    }
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #f7f7f7;
  }
`;

export const ContainerButton = styled.div<DefaultStyles>`
  width: 100% !important;
  background-color: transparent;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  ${(props) =>
    props.last &&
    css`
      border-bottom: 1px solid transparent !important;
    `}
`;

export const ContainerItemText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 27px;
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  margin-right: 42px;
  img {
    margin-left: 5px;
  }
  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: #999999;
  }
`;

export const ContainerAditionalInformation = styled.div`
  position: absolute;
  right: 0;
  top: 44px;
  z-index: 2000;
`;
