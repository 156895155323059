import React from 'react'

export  function LinesCalendar({only}) {
    return (
        <td className={`calendar-lines ${only ? "no-line-center" : ""}`}>
            <tr />
            <tr />
        </td>
    )
}
