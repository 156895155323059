import React from "react";

import { TextEllipsis } from "Utils/Uploads";

const SelectUnits = ({
  id,
  label = "",
  value = undefined,
  error = undefined,
  required = false,
  purple = undefined,
  onChange,
  placeholder = "",
  options = [],
  small = false,
  onFocus = undefined,
  optionName = undefined,
  readOnly = false,
}) => {
  return (
    <div
      className={`
            field-holder 
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple && "_purple"}`}
        >
          {label}
        </label>
      )}
      <div className="select-field-holder">
        <select
          className="input select _opacity-gray"
          name={id}
          id={id}
          value={value}
          onChange={onChange}
          required={required && "required"}
          disabled={readOnly}
          onFocus={onFocus}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option
              key={option.id}
              value={JSON.stringify({
                id: option.id,
                name: option.name,
                motion_code: option.motion_code,
              })}
            >
              {option[optionName] || TextEllipsis(option.name, 50)}
            </option>
          ))}
        </select>
        {!readOnly && <span className="arrow-icon">{">"}</span>}
      </div>
      {error && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default SelectUnits;
