import React from "react";
import Modal from "react-modal";

import PageHeader from "Components/Pages/Header";
import CouponCart from "Components/ServiceForm/CouponCart";

function CoupomModal({
  modalVisible,
  modalDismiss,
  budget,
  handleRemoveCoupon,
  handleUpdateCoupon,
}) {
  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="modal-wrapper-header">
        <PageHeader title={`Insira um cupom de desconto `} />
      </div>
      <div className="modal-coupom-cart">
        <CouponCart
          coupon_code={budget?.coupon_code || ""}
          removeCoupon={() => handleRemoveCoupon()}
          updateCoupon={handleUpdateCoupon}
        />
      </div>
    </Modal>
  );
}

export default CoupomModal;
