import React from "react"

import Login from "Pages/Login";

import HeaderLogin from "Components/Shared/HeaderLogin";

export default [
	{
		component: Login,
		path: "/",
		header: <HeaderLogin />,
		footer: false,
		// exact: true
	},
];
