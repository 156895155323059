import { CovenantItemProps } from "./interface";
import { Container } from "./styles";

export const CovenantItem = ({ covenantData, onChange }: CovenantItemProps) => {
  const { covenant: covenantInfo, covenant_plan } = covenantData;

  const { id, covenant } = covenantInfo;
  const { covenant_plans_name } = covenant_plan;

  return (
    <Container>
      <label className="custom-checkbox">
        <input
          type="radio"
          name="covenant"
          id={String(id)}
          onChange={() => onChange(covenantData)}
          value={id}
        />
        <span className="checkmark" />
      </label>

      <div className="covenant-info">
        <p>{covenant}</p>
        <p>
          <strong>Plano </strong> {covenant_plans_name}
        </p>
      </div>
    </Container>
  );
};
