import React from "react";

import { getIn } from "formik";

import CurrencyInput from "../rootInputCurrency";

const InputCurrencyControlled = ({
  label,
  small,
  name,
  purple,
  required,
  placeholder,
  readOnly,
  description,
  field,
  form,
}) => {
  const { setFieldValue } = form;
  const error = getIn(form.errors, field.name);

  return (
    <div
      className={`
            field-holder 
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={name}
          className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
        >
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      <CurrencyInput
        id={name}
        className="input _opacity-gray"
        required={required ? "required" : ""}
        placeholder={placeholder}
        readOnly={readOnly}
        {...field}
        onChangeEvent={(event, maskedvalue, floatvalue) => {
          event.preventDefault();

          setFieldValue(field?.name, floatvalue);
        }}
        decimalSeparator=","
        thousandSeparator="."
      />
      {error && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default InputCurrencyControlled;
