import styled, { css } from "styled-components";

interface ScheduleDayProps {
  selected?: boolean;
  today?: boolean;
}

interface DayProps {
  selected?: boolean;
  available?: boolean;
}

export const Container = styled.div`
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

export const WeekContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  justify-content: space-between;
  overflow-x: auto;
  width: 100%;
`;

export const ScheduleDay = styled.div<ScheduleDayProps>`
  width: 100%;

  @media screen and (max-width: 1364px) {
    width: 162px;
  }

  header {
    padding: 16px 0;
    border-bottom: ${(props) =>
      props.selected ? "6px solid #572580" : "3px solid #572580"};

    ${({ today }) =>
      today &&
      css`
        background: #572580;
        border-radius: 10px;

        p {
          color: #fff !important;
        }
      `};

    p {
      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #572580;
      text-align: center;
      letter-spacing: 0.5px;
    }
  }
`;

export const Days = styled.div`
  text-align: center;
  margin-top: 8px;
`;

export const Day = styled.button<DayProps>`
  width: 70px;
  height: 70px;
  margin: 2px;
  border-radius: 8px;
  border: none;
  border-top: ${(props) => (props.selected ? "7px solid #AB92BF" : "none")};
  background-color: ${(props) =>
    props.selected ? "#F5F5F5" : props.available ? "#FFFFFF" : "#E5E5E5"};
  box-shadow: 0px 0px 0px rgba(130, 136, 148, 0.16),
    0px 1px 2px rgba(130, 136, 148, 0.48);

  color: #666;

  transition: all 0.2s;
  cursor: ${(props) => (props.available ? "pointer" : "not-allowed")};

  @media screen and (max-width: 1364px) {
    width: 50px;
    height: 50px;
  }

  &:hover {
    filter: ${(props) => (props.available ? "brightness(.9)" : "")};
  }
`;

export const ScheduleFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-top: 2rem;

  span {
    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    margin: 5px;
    letter-spacing: 0.5px;
    text-align: center;

    color: #572580;
  }

  div {
    display: flex;
    align-items: center;

    p {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: black;

      margin: 5px;
    }

    button {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      background-color: #572580;
      color: white;
      border: none;

      cursor: pointer;

      transition: filter 0.2s;

      &:disabled {
        background-color: #ab92bf;
        cursor: not-allowed;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;
