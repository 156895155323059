import moment from "moment";

function isOneOfFieldsRequireds(fieldName) {
  switch (fieldName) {
    case "name":
    case "coupon_code":
    case "expiration_date":
      return true;

    default:
      return false;
  }
}

function comparer(otherArray, comparerKey) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other[comparerKey] == current[comparerKey];
      }).length == 0
    );
  };
}

function FormatCategoriesFieldsToSubmit(productData, key) {
  let items = [];

  const originalArr = productData[`${key}_original`];
  const dataArr = productData[key].value ? productData[key].value : [];

  if (originalArr) {
    const deletedItemsFiltered = originalArr.filter(
      comparer(dataArr, "displayId")
    );

    const deletedItems = originalArr.map(({ id }) => {
      const deletedItem = deletedItemsFiltered.find(
        (item) => item.displayId === id
      );

      if (deletedItem) {
        return {
          id: deletedItem.displayId,
          category_id: "",
          sub_category_id: "",
        };
      }

      return null;
    });

    const newItems = dataArr.map((item) => {
      return {
        id: item.isUpdated ? item.displayId : null,
        category_id: item.isUpdated
          ? item.category_id
          : item.category
          ? item.category
          : item.id,
        sub_category_id: item.isUpdated
          ? item.sub_category_id
          : item.category
          ? item.id
          : null,
      };
    });

    items = deletedItems.concat(newItems).filter((item) => item != null);
  } else {
    items = dataArr.map((item) => {
      return {
        category_id: item.category_id
          ? item.category_id
          : item.category
          ? item.category
          : item.id,
        sub_category_id: item.category ? item.id : null,
      };
    });
  }

  return items;
}

function FormatArrayFieldsToSubmit(productData, key, primaryKey) {
  let items = [];

  const originalArr = productData[`${key}_original`];
  const dataArr = productData[key].value ? productData[key].value : [];

  if (originalArr) {
    const deletedItemsFiltered = originalArr.filter(comparer(dataArr, "id"));

    const deletedItems = originalArr.map(({ id }) => {
      const deletedItem = deletedItemsFiltered.find((item) => item.id === id);

      if (deletedItem) {
        return {
          id: deletedItem.id,
          [primaryKey]: "",
        };
      }

      return null;
    });

    const newItems = dataArr.map((item) => {
      return {
        id: item.isUpdated && item.id,
        [primaryKey]: item.isUpdated ? item[primaryKey] : item.id,
      };
    });

    items = deletedItems.concat(newItems).filter((item) => item != null);
  } else {
    items = dataArr.map((item) => {
      return {
        [primaryKey]: item.id,
      };
    });
  }

  return items;
}

function FormatCouponToForm(productApiData) {
  let _fields = {};

  Object.keys(productApiData).forEach((el) => {
    if (Array.isArray(productApiData[el])) {
      let array = [];

      array = productApiData[el].map((item) => ({
        ...item,
        isUpdated: true,
      }));

      if (el === "allowed_category" || el === "not_allowed_category") {
        const newArray = productApiData[el].map((item) => ({
          ...item,
          displayId: item.id,
          id: item.sub_category_id ? item.sub_category_id : item.category_id,
          name: item.sub_category_name
            ? item.sub_category_name
            : item.category_name,
          isUpdated: true,
        }));

        array = productApiData[el].map((item) => ({
          ...item,
          displayId: item.id,
          isUpdated: true,
        }));

        _fields = {
          ..._fields,
          [el]: {
            ..._fields[el],
            value: newArray,
          },
          [`${el}_original`]: array,
        };
      } else {
        _fields = {
          ..._fields,
          [el]: {
            ..._fields[el],
            value: array,
          },
          [`${el}_original`]: array,
        };
      }
    } else if (el === "expiration_date") {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          isRequired: !!isOneOfFieldsRequireds(el),
          value: moment(productApiData[el], "DD-MM-YYYY"),
          error: "",
        },
      };
    } else if (
      el === "value_discount" ||
      el === "maximum_order_value" ||
      el === "minimum_order_value"
    ) {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          value:
            productApiData[el] && productApiData[el].id
              ? productApiData[el].id
              : parseFloat(productApiData[el]) || "",
          error: "",
        },
      };
    } else {
      _fields = {
        ..._fields,
        [el]: {
          ..._fields[el],
          isRequired: !!isOneOfFieldsRequireds(el),
          value:
            productApiData[el] && productApiData[el].id
              ? productApiData[el].id
              : productApiData[el],
          error: "",
        },
      };
    }

    if (el === "percentage_discount" || el === "value_discount") {
      let type_discount = 1;

      if (
        productApiData.value_discount &&
        !productApiData.percentage_discount
      ) {
        type_discount = 2;
      }

      _fields = {
        ..._fields,
        type_discount: {
          ..._fields.type_discount,
          value: type_discount,
          error: "",
        },
      };
    }
  });

  return _fields;
}

function FormatCouponToSubmit(productData) {
  let submitForm = {};

  Object.keys(productData).forEach((key) => {
    if (key === "expiration_date") {
      submitForm = {
        ...submitForm,
        [key]: productData[key].value.format("DD-MM-YYYY"),
      };
    } else if (key.includes("_discount")) {
      const type_discount = productData.type_discount.value;

      if (parseInt(type_discount) === 1) {
        submitForm = {
          ...submitForm,
          percentage_discount: productData.percentage_discount.value,
          value_discount: null,
        };
      } else {
        submitForm = {
          ...submitForm,
          value_discount: productData.value_discount.value,
          percentage_discount: null,
        };
      }
    } else if (key === "allowed_category" || key === "not_allowed_category") {
      const items = FormatCategoriesFieldsToSubmit(productData, key);

      submitForm = {
        ...submitForm,
        [key]: items,
      };
    } else if (key === "allowed_products" || key === "not_allowed_products") {
      const items = FormatArrayFieldsToSubmit(productData, key, "product_id");

      submitForm = {
        ...submitForm,
        [key]: items,
      };
    } else if (
      productData[key].value !== undefined &&
      !Array.isArray(productData[key].value)
    ) {
      submitForm = {
        ...submitForm,
        [key]: productData[key].value,
      };
    } else {
      submitForm = {
        ...submitForm,
        [key]: productData[key],
      };
    }
  });

  return submitForm;
}

export { FormatCouponToSubmit, FormatCouponToForm };
