import React from "react";

import { Uf } from "Utils/LocalTypes";
import { MaskCEP, unmaskCEP } from "Utils/Masks";
import { getNameWeekSearch, getScheduleInfo } from "Utils/Schedules";

import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";

export default function ModalEdit({
  readOnly,
  data,
  objectData,
  setObjectData,
}) {
  const _split = data.datetime.split("/");
  const labelWeek = getNameWeekSearch([..._split], true);
  const month =
    _split[1] < 10 ? Number(_split[1].split("0")[1]) : Number(_split[1]);
  const { months } = getScheduleInfo();

  const handleChange = ({ target }) => {
    setObjectData((prev) => ({
      ...prev,
      [target.id]: target.value,
    }));
  };

  const handleCepChange = async ({ target }) => {
    setObjectData((prev) => ({
      ...prev,
      [target.id]: target.value,
    }));
    try {
      if (unmaskCEP(target.value).length >= 8) {
        const cepFind = await fetch(
          `http://viacep.com.br/ws/${unmaskCEP(target.value)}/json/`
        );
        const res = await cepFind.json();
        setObjectData((prev) => ({
          ...prev,
          city: res.localidade,
          neighborhood: res.bairro,
          state: res.uf,
          street: res.logradouro,
          number: "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="content-modal-schedule">
      <div className="header-details-modal">
        <p className="title-modal-details decoration">
          {data.firstName} {data.lastName}
        </p>

        <div className="schedule-details-information">
          <p>
            {labelWeek}, {_split[0]} de {months[month - 1]} de {_split[2]}
          </p>
          <p>
            {data.time} - {data.endTime}
          </p>
          <a>Pedido # {data.id}</a>
        </div>
      </div>

      <div className="modal-details">
        <p className="subtitle-modal-details">Detalhes</p>

        <div className="details-info margin">
          <div className="margin">
            <Input label="Agenda:" readOnly value={objectData.calendar} />
          </div>

          <div className="margin">
            <Input label="Tipo de Evento" readOnly value={objectData.type} />
          </div>

          <div>
            <Input
              label="Notas"
              value={objectData.notes ? objectData.notes : ""}
              placeholder="Sem notas sobre o agendamento"
              id="notes"
              onChange={handleChange}
            />
          </div>

          <div className="modal-details-flex-inputs margin">
            <div>
              <Input
                mask="(99) 9 9999-9999"
                label="Telefone"
                value={objectData.phone}
                id="phone"
                onChange={handleChange}
              />
            </div>

            <div>
              <Input
                label="Email"
                value={objectData.email}
                id="email"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="margin">
            <Input
              label="Protocólo Central"
              id="protocol"
              value={objectData.protocol ? objectData.protocol : ""}
              onChange={handleChange}
            />
          </div>

          <div className="margin">
            <Input label="Voucher taxa de coleta domiciliar" value="XPTO" />
          </div>

          <div className="margin">
            <Input
              label="Voucher pedido no site"
              id="order_voucher"
              value={objectData.order_voucher ? objectData.order_voucher : ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="modal-details-address">
        <p className="subtitle-modal-details">Endereço</p>

        <div className="details-info">
          <div>
            <Input
              label="CEP"
              value={MaskCEP(objectData.cep)}
              id="cep"
              onChange={handleCepChange}
            />
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input
                label="Numero"
                readOnly={readOnly}
                value={objectData.number}
                id="number"
                onChange={handleCepChange}
              />
            </div>
            <div>
              <Input
                label="Logradouro"
                readOnly={readOnly}
                value={objectData.street}
                onChange={handleCepChange}
              />
            </div>
          </div>

          <div className="modal-details-flex-inputs">
            <div>
              <Input
                label="Complemento"
                value={objectData.complement}
                id="complement"
                onChange={handleChange}
              />
            </div>

            <div>
              <Input
                label="Bairro"
                value={objectData.neighborhood}
                id="neighborhood"
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <Input
              label="Cidade"
              value={objectData.city}
              id="city"
              onChange={handleChange}
            />
          </div>

          <div>
            <SelectSearch
              label="Estado"
              id="state"
              type="text"
              value={
                objectData.state.hasOwnProperty("id")
                  ? objectData.state
                  : { id: objectData.state, name: objectData.state }
              }
              error=""
              isMulti
              isMoreSelect={false}
              options={Uf}
              valueKey="name"
              labelKey="name"
              placeholder="Selecione um estado"
              onChange={(value) =>
                setObjectData((prev) => ({ ...prev, state: value }))
              }
            />
          </div>
        </div>
      </div>

      <div className="modal-details-data-pacient">
        <p className="subtitle-modal-details">Dados do Paciente</p>

        <div className="details-info">
          <div>
            <Input
              label="Nome completo"
              value={`${objectData.firstName} ${objectData.lastName}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
