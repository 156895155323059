export const STATUS = {
  unavaliable: Number(process.env.REACT_APP_STATUS_UNAVALIABLE ?? ""),
  avaliable: Number(process.env.REACT_APP_STATUS_AVALIABLE ?? ""),
  inactive: Number(process.env.REACT_APP_STATUS_INACTIVE ?? ""),
};

export const SERVICES = {
  labiExam: process.env.REACT_APP_SERVICES_LABIEXAM ?? "",
  labiClinic: process.env.REACT_APP_SERVICES_LABICLINIC ?? "",
  partner: process.env.REACT_APP_SERVICES_PARTNER ?? "",
  home: process.env.REACT_APP_SERVICES_HOME ?? "",
  tele: process.env.REACT_APP_SERVICES_TELE ?? "",
};

export const GENDERS = {
  trans:
    (process.env.REACT_APP_TRANS_MAN || process.env.REACT_APP_TRANS_WOMAN) ??
    "",
  undefined: process.env.REACT_APP_UNDEFINED_GENDER ?? "",
};
