import { UseCheduleProps } from "./interface";

// Custom Hook Created When Attendance Type is Home Collect
export const useSchedule = () => {

  const getZipcodeStateConverted = ({ address }: UseCheduleProps) => {

    const clonedCity = address.city.toUpperCase();

    // Mapeamento de bairros vizinhos a Campo Grande
    const campoGrandeNeighborhoods = [
      "Campo Grande", "Santíssimo", "Sen. Vasconcelos", "Senador Vasconcelos",
      "Inhoaíba", "Cosmos", "Bangu", "Senador Camará"
    ];

    // Mapeamento de cidades e estados
    const cityStateMapping: { [key: string]: string } = {
      "CURITIBA|PR": "CURITIBA",
      "SÃO JOSÉ DOS PINHAIS|PR": "CURITIBA",
      "PIRAQUARA|PR": "CURITIBA",
      "COLOMBO|PR": "CURITIBA",
      "ALM. TAMANDARÉ|PR": "CURITIBA",
      "ALMIRANTE TAMANDARÉ|PR": "CURITIBA",
      "CAMPINA GRANDE DO SUL|PR": "CURITIBA",
      "QUATRO BARRAS|PR": "CURITIBA",
      "IGUAÇU|PR": "CURITIBA",
      "FAZENDA RIO GRANDE|PR": "CURITIBA",
      "CAMPO LARGO|PR": "CURITIBA",
      "BALSA NOVA|PR": "CURITIBA",
      "TIJUCAS DO SUL|PR": "CURITIBA",
      "ARAUCÁRIA|PR": "CURITIBA",
      "PINHAIS|PR": "CURITIBA",
      "NITERÓI|RJ": "NT",
      "TAUBATÉ|SP": "TAUBATE",
      "TREMEMBÉ|SP": "TAUBATE",
      "PINDAMONHANGABA|SP": "TAUBATE",
      "SÃO GONÇALO|RJ": "SAO_GONCALO",
      "PIRACICABA|SP": "PIRACICABA",
      "VALINHOS|SP": "CAMPINAS",
      "CAMPINAS|SP": "CAMPINAS",
      "MOGI DAS CRUZES|SP": "MOGI_DAS_CRUZES",
      "SUZANO|SP": "MOGI_DAS_CRUZES",
      "SÃO VICENTE|SP": "SAO_VICENTE",
      "SANTOS|SP": "SAO_VICENTE",
      "PRAIA GRANDE|SP": "SAO_VICENTE",
      "SÃO JOSÉ DOS CAMPOS|SP": "SAO_JOSE_DOS_CAMPOS",
      "JACAREÍ|SP": "SAO_JOSE_DOS_CAMPOS",
      "CAÇAPAVA|SP": "SAO_JOSE_DOS_CAMPOS",
      "CUBATÃO|SP": "CUBATAO",
      "ITATIBA|SP": "ITATIBA",
      "ITU|SP": "ITU",
      "SOROCABA|SP": "SOROCABA",
      "SÃO PAULO|SP": "SP",
      "GUARULHOS|SP": "SP",
      "SÃO BERNARDO DO CAMPO|SP": "SP",
      "SANTO ANDRÉ|SP": "SP",
      "OSASCO|SP": "SP",
      "MAUÁ|SP": "SP",
      "DIADEMA|SP": "SP",
      "CARAPICUÍBA|SP": "SP",
      "ITAQUAQUECETUBA|SP": "SP",
      "BARUERI|SP": "SP",
      "COTIA|SP": "SP",
      "TABOÃO DA SERRA|SP": "SP",
      "EMBU DAS ARTES|SP": "SP",
      "ITAPEVI|SP": "SP",
      "FERRAZ DE VASCONCELOS|SP": "SP",
      "SÃO CAETANO DO SUL|SP": "SP",
      "ITAPECERICA DA SERRA|SP": "SP",
      "SANTANA DE PARNAÍBA|SP": "SP",
      "CAJAMAR|SP": "SP",
      "RIBEIRÃO PIRES|SP": "SP",
      "JANDIRA|SP": "SP",
      "POÁ|SP": "SP",
      "ITAIM PAULISTA|SP": "SP",
      "MAIRIPORÃ|SP": "SP"
    };

    // Verifica se o bairro e a cidade são vizinhos de Campo Grande
    if (campoGrandeNeighborhoods.includes(address.neighborhood) && clonedCity === "RIO DE JANEIRO" && address.state?.toUpperCase() === "RJ") {
      return "CAMPO_GRANDE";
    }

    // Verifica se há uma combinação de cidade e estado no mapeamento
    const cityStateKey = `${clonedCity}|${address.state?.toUpperCase()}`;
    if (cityStateMapping[cityStateKey]) {
      return cityStateMapping[cityStateKey];
    }

    // Retorna o estado se nenhuma cidade específica for encontrada
    return address.state?.toUpperCase();

  }

  return {
    getZipcodeStateConverted
  }

}