import React from "react";

import CorrectInput from "Assets/Images/corret-input.svg";

import { StyledCheckboxProps } from "./interface";
import { CheckboxContainer } from "./styles";

export const CustomCheckbox = ({
  label,
  field,
  checked,
  onChange,
  id,
}: StyledCheckboxProps) => {
  return (
    <CheckboxContainer className="label-checkbox" htmlFor={id}>
      <input
        {...field}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div className="checked-img">
        <img src={CorrectInput} alt="checked" />
      </div>
      {label}
    </CheckboxContainer>
  );
};
