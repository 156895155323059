import { AttendanceType, FormattedAttendanceType, OrderType } from "Stores/budget-order";

export const mountSkusFromAttenndance = (order: OrderType, currentIndex: number) => {

  let formattedSkus = '';

  const result = order.formattedAttendances.find((item) =>
    item.ids_attendance.includes(order.attendances[currentIndex].id)
  );

  const productsIds = result ? result.products_ids : [];

  formattedSkus = order.items
    .filter((item) => productsIds.includes(item.product_id))
    .map((item) => item.product_code)
    .join(',');


  return formattedSkus;
}

export const getNextAttendanceIndex = (attendances: AttendanceType[], formattedAttendances: FormattedAttendanceType[]) => {
  // Extrai os ids que já estão em formattedAttendances.ids_attendance
  const excludedIds = formattedAttendances.flatMap((att) => att.ids_attendance);

  // Encontra o índice do próximo atendimento que não está nos ids excluídos
  const nextIndex = attendances.findIndex(
    (attendance) => !excludedIds.includes(attendance.id)
  );

  return nextIndex !== -1 ? nextIndex : -1; // Retorna o índice ou null se não houver mais
}


export const verifyAllAtendancesChecked = (ids_attendances: number[], formattedAttendances: FormattedAttendanceType[]) => {
  return ids_attendances.every(id =>
    formattedAttendances.some(attendance => attendance.ids_attendance.includes(id))
  );
}

// Função para verificar e retornar os atendimentos que não estão em formattedAttendances
export const findMissingAttendances = (
  attendances: AttendanceType[],
  formattedAttendances: FormattedAttendanceType[],
  exceptionId: number
) => {
  // Obter os ids_attendance de formattedAttendances
  const formattedIds = formattedAttendances.flatMap((att) => att.ids_attendance);

  // Filtrar os atendimentos que não estão em formattedAttendances, exceto o que tem o exceptionId
  return attendances.filter((att) =>
    !formattedIds.includes(att.id) && att.id !== exceptionId
  );
};


export const extractProductsIds = (attendances: AttendanceType[], currentIndex: number): number[] => {
  return attendances[currentIndex].items.map((i) => i.product_id)
}