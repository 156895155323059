import React from "react";

import { hasPathInArray, validateArrayType } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";
import { Container } from "./styles";

export const ProductPrepare = ({ product }: ProductProps) => {
  const {
    category,
    diets,
    fasts,
    stop_medication,
    stop_medication_obs,
    product_description,
    product_pre_collection,
  } = product ?? {};

  const { preparation_method } = product_description ?? {};

  return (
    <AccordionCollapse title="Preparo" cssClass="prepare">
      <Container>
        {hasPathInArray([1, 2, 3, 7, 8], category?.id) && (
          <div className="flex prepare-products-column-1">
            <div className="prepare-medication">
              <span role="heading" className="heading" aria-level={5}>
                Dieta especial
              </span>
              <div className="column-1">
                {validateArrayType(diets) ? (
                  diets?.map((diet) => (
                    <S.RowContent
                      role="row"
                      className="row-content"
                      mb="4"
                      key={diet.id}
                    >
                      {diet.diet}
                    </S.RowContent>
                  ))
                ) : (
                  <S.RowContent role="row" className="row-content" mb="4">
                    Não informado
                  </S.RowContent>
                )}
              </div>
            </div>

            <div>
              <div className="prepare-medication">
                <span role="heading" className="heading" aria-level={5}>
                  Jejum
                </span>

                {fasts?.length > 0 ? (
                  fasts.map((item: any) => (
                    <div className="column-1">
                      <S.RowContent role="row" className="row-content" mb="4">
                        {item.fast?.fast} - mínimo {item?.fast_hours_min}h e no
                        máximo {item?.fast_hours_max}h
                      </S.RowContent>
                    </div>
                  ))
                ) : (
                  <S.RowContent role="row" className="row-content" mb="4">
                    Não informado
                  </S.RowContent>
                )}
              </div>

              <div className="prepare-medication">
                <span role="heading" className="heading" aria-level={5}>
                  Interromper medicamento
                </span>
                <div className="column-1">
                  <S.RowContent role="row" className="row-content" mb="4">
                    {stop_medication?.medicament}
                  </S.RowContent>
                  <S.RowContent role="row" className="row-content">
                    {stop_medication_obs}
                  </S.RowContent>
                </div>
              </div>
            </div>
          </div>
        )}

        {hasPathInArray([1, 2, 3, 5, 7, 8], category?.id) && (
          <div className="prepare-medication">
            <span role="heading" className="heading" aria-level={5}>
              Pré-coleta
            </span>
            <div className="column-1">
              {product_pre_collection.map((item: any) => (
                <S.RowContent role="row" className="row-content" mb="4">
                  {item.collection_id === 1
                    ? "Trazer exames anteriores"
                    : `Não urinar por no mínimo ${item.pre_collection_hours}h antes do  exame`}
                </S.RowContent>
              ))}
            </div>
          </div>
        )}

        {hasPathInArray([4, 7], category?.id) && (
          <div className="flex prepare-products-column-1">
            <div className="prepare-medication">
              <span role="heading" className="heading" aria-level={5}>
                Interromper medicamento
              </span>
              <div className="column-1">
                <S.RowContent role="row" className="row-content" mb="4">
                  {stop_medication?.medicament}
                </S.RowContent>
                <S.RowContent role="row" className="row-content">
                  {stop_medication_obs}
                </S.RowContent>
              </div>
            </div>
          </div>
        )}

        <div className="additional-info">
          <span role="heading" className="heading" aria-level={5}>
            Informações adicionais de preparo
          </span>
          <S.RowContent role="row" className="row-content" mb="0">
            {preparation_method || "Não informado"}
          </S.RowContent>{" "}
        </div>
      </Container>
    </AccordionCollapse>
  );
};
