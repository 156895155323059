import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { GetBudgetsByUserID } from "Requests/Budget";

import { selectOrder } from "Actions/ServiceForm";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { handleRequestErrors } from "Utils/Errors";
import { formatBRLCurrency } from "Utils/FormatCurrency";

import Pagination from "Components/Nav/Pagination";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

const ServiceFormOrderList = (props) => {
  const patientSelected = useSelector((state) => state.service_form.pacient);
  const dispatch = useDispatch();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const { type } = useParams();

  const { resetBudgetInformation } = useBudgetContext();

  const history = useHistory();

  async function fetchBudgets(page) {
    try {
      setLoading(true);
      !page && setPagination({});

      const user_id = await patientSelected.id;

      const budgetsResponse = await GetBudgetsByUserID(user_id, page, type);

      setOrders(budgetsResponse.data);

      const { current_page, count, total, total_pages, per_page } =
        budgetsResponse.meta.pagination;
      setPagination({
        current_page,
        count,
        per_page,
        total,
        total_pages,
      });
    } catch (error) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage:
          "Não foi possível obter a listagem de orçamentos deste usuário. ",
      });
    } finally {
      setLoading(false);
    }
  }

  function showMessageErrorFindBudget() {
    toast.error("Oops! Não foi possível encontrar este orçamento.");
  }

  function showMessagePacientEmpty() {
    toast.error("Oops! Nenhum paciente foi selecionado.");
  }

  function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    fetchBudgets(e.currentPage);
  }

  function handleDispatchSelectOrder(order) {
    dispatch(selectOrder(order));

    redirectToNextPage(order.id || 0);
  }

  function redirectToNextPage(order_id) {
    if (order_id) {
      props.history.push(
        `/ficha-de-atendimento/${type}/selecionar-produtos/${order_id}`
      );
    }
    // props.history.push(`/ficha-de-atendimento/${type}/carrinho`);
    history.push(`/ficha-de-atendimento/${type}/tipo-de-orcamento`);
  }

  function createNewBudget() {
    resetBudgetInformation();
    handleDispatchSelectOrder({});
  }

  useEffect(() => {
    if (
      props.history.action === "PUSH" &&
      (props.history.location.pathname ===
        "/ficha-de-atendimento/selecionar-produtos" ||
        props.history.location.pathname.includes(
          "/ficha-de-atendimento/resumo"
        ))
    ) {
      showMessageErrorFindBudget();
    }

    if (patientSelected.id) {
      fetchBudgets();
    } else {
      showMessagePacientEmpty();

      props.history.push("/ficha-de-atendimento/paciente");
    }
  }, [patientSelected.id, props.history]);

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome do paciente</th>
            <th>Data</th>
            <th>Cupom aplicado</th>
            <th>Total (R$)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && _showLoading()}
          {!loading && _tableList()}
        </tbody>
      </table>
    );
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="5" className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }

  function _tableList() {
    return !orders.length ? (
      <tr>
        <td colSpan="5" className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {orders.map((order) => (
          <tr key={order.id}>
            <td>{order.id}</td>

            {order.lead ? (
              <td>{order.lead.name || "Não informado"}</td>
            ) : (
              <td>
                {order.patient.social_name ||
                  order.patient.full_name ||
                  "Não informado"}
              </td>
            )}

            <td>
              {(function () {
                const data = new Date(order?.created_at);
                return data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
              })()}
            </td>
            <td>{order.coupon_code || "Não informado"}</td>
            <td>{formatBRLCurrency(order.total, true)}</td>
            <td className="text-right table-action">
              <Link
                className="button _action _blue"
                to={`/orcamento/${order.id}`}
              >
                <span className="details-text">Visualizar</span>
              </Link>
            </td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder page-budget">
        <div className="page-heading">
          <div className="wrapper-header-content m-bottom-1">
            <PageHeader title="Orçamentos do Paciente">
              <Breadcrumb
                list={[
                  {
                    path: "/ficha-de-atendimento",
                    label: "Ficha de atendimento",
                    current: false,
                  },
                  {
                    path: "#",
                    label: `Paciente ${
                      patientSelected.name || patientSelected.full_name
                    }`,
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
        </div>

        <section className="service-form-page">
          <div className="container">
            {!!orders.length && (
              <h5 className="title-service-form-page text-center text-light text-display text-hgray text-separate-text">
                O Paciente selecionado possui orçamentos não finalizados,
                selecione um abaixo para editar ou crie um novo:
              </h5>
            )}

            <div className="text-right button-holder text-separate-text">
              <button
                type="button"
                onClick={createNewBudget}
                className="button _action"
              >
                Criar novo orçamento
              </button>
            </div>

            <div className="table-list-cards text-separate-text">
              {_renderTable()}
              <div className="pagination-wrapper">
                {pagination.total_pages ? (
                  <>
                    <Pagination
                      totalRecords={pagination.total_pages}
                      pageLimit={1}
                      pageNeighbours={1}
                      onPageChanged={(e) => handlePagination(e)}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageWrapper>
  );
};

export default ServiceFormOrderList;
