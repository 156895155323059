import React from "react";

import Input from "Components/Form/Input";

const AutocompleteCity = ({
  id,
  purple,
  label,
  onChange,
  onClick,
  placeholder,
  value,
  error,
  activeSuggestion,
  showSuggestions,
  filteredSuggestions,
  noSuggestionMessage,
  withCreate,
}) => {
  let suggestionsListComponent;

  if (showSuggestions && value) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li
                className={`${className} suggestion`}
                key={index}
                id={suggestion.id}
                onClick={onClick}
              >
                <strong style={{ fontSize: "12px" }}>
                  {suggestion.city} ({suggestion.state}) &nbsp;
                </strong>
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>
            {withCreate ? (
              <span style={{ cursor: "pointer" }}>{noSuggestionMessage}</span>
            ) : (
              noSuggestionMessage
            )}
          </em>
        </div>
      );
    }
  }
  return (
    <>
      <div className="suggestion-wrapper">
        {label && (
          <label
            htmlFor={id}
            className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
          >
            {label}
          </label>
        )}
        <Input
          type="text"
          id={id}
          name={id}
          error={error}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className="input _opacity-gray suggestions-input"
          autoComplete="off"
        />
        <div className="suggestions-list">{suggestionsListComponent}</div>
      </div>
    </>
  );
};

export default AutocompleteCity;
