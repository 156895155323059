/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { connect } from "react-redux";

import API from "Services/Api";
import { logout } from "Services/Authentication";

import { handleLogin } from "Actions/Authentication";

import ValidatorFields from "Utils/ValidatorFields";

import Input from "Components/Form/Input";
import PageWrapper from "Components/Pages/Wrapper";

import LoadingPurple from "Assets/Images/loading-purple.gif";

class Login extends Component {
  state = {
    loading: false,
    form: {
      email: {
        error: "",
        value: "",
      },
      password: {
        error: "",
        value: "",
      },
    },
    message: {
      success: false,
      text: "",
    },
    tokenaccess: "",
    recaptcha: {
      token: "",
      validate: false,
    },
    formError: true,
  };

  handleFocus = ({ target: { value, id: name, required } }) => {
    if (required) {
      if (value === "") {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: {
              ...prevState.form[name],
              error: "O Campo é obrigatório",
            },
          },
          formError: true,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: {
              ...prevState.form[name],
              error: "",
            },
          },
        }));
      }
    }
  };

  handleChange = ({ target: { value, id: name, required } }) => {
    const field = ValidatorFields(name, value, required);

    this.setState(
      (prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: {
            ...field,
          },
        },
      }),
      () => {
        this.hasFormError();
      }
    );
  };

  validRecaptcha = (verifyToken) => {
    this.setState((prevState) => ({
      ...prevState,
      recaptcha: {
        ...prevState.recaptcha,
        token: verifyToken,
      },
    }));
  };

  hasFormError = () => {
    const { form } = this.state;
    let formError = false;

    Object.keys(form).forEach((field) => {
      if (this.state.form[field].error !== "") {
        formError = true;
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      formError,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state.form;
    const { tokenaccess } = this.state;

    const data = {
      email: email.value,
      password: password.value,
      tokenaccess,
    };

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
      message: {
        success: false,
        text: "",
      },
    }));

    return API.post("/admin/user/login", data)
      .then(({ data, status }) => {
        if (status === 200) {
          this.props.handleLogin({
            token: data.token,
            user: data.user,
            permissions: data.all_permissions,
          });

          this.props.history.push("/dashboard");
        }
      })
      .catch((e) => {
        const { data } = e.response;

        this.setState((prevState) => ({
          ...prevState,
          loading: false,
          message: {
            text: "Não foi possível efetuar o login",
            success: false,
          },
          recaptcha: {
            ...prevState.recaptcha,
            validate: data.grecaptcha,
          },
          tokenaccess: data.tokenaccess,
        }));

        logout();
      });
  };

  setToken = () => {
    localStorage.setItem("@Token", "ABCDE");
  };

  handTempSub = (e) => {
    e.preventDefault();

    if (
      this.state.form.email.value === "admin@admin.com" &&
      this.state.form.password.value === "admin"
    ) {
      this.setToken();

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        message: {
          text: "Dados de acesso incorretos, tente novamente!",
          success: false,
        },
      }));
    }
  };

  render() {
    const { form, loading, formError, message } = this.state;

    return (
      <PageWrapper>
        <div className="container">
          <div className="app-auth-panel">
            <div className="panel-header">
              <h3 className="text-purple text-no-margin">Entrar</h3>
            </div>
            <form
              onSubmit={this.handleSubmit}
              method="POST"
              className="panel-content"
            >
              <div className="row">
                {message.text !== "" && (
                  <div className="xs-12">
                    <div
                      className={`message-form small-separate ${
                        message.success ? "_success" : "_error"
                      }`}
                    >
                      <p>{message.text}</p>
                    </div>
                  </div>
                )}
                <div className="xs-12">
                  <Input
                    maskChar={null}
                    mask=""
                    placeholder="email@exemplo.com.br"
                    label="Email"
                    id="email"
                    type="email"
                    required
                    value={form.email.value}
                    error={form.email.error}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                  />
                </div>
                <div className="xs-12">
                  <Input
                    label="Senha"
                    id="password"
                    type="password"
                    required
                    value={form.password.value}
                    error={form.password.error}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                  />
                </div>

                <div className="xs-12">
                  <div className="button-holder">
                    <button
                      type="submit"
                      disabled={formError}
                      className={`
                                            button 
                                            ${
                                              loading
                                                ? "button-loading _white"
                                                : ""
                                            }
                                        `}
                    >
                      {loading ? (
                        <img src={LoadingPurple} alt="" className="gif" />
                      ) : (
                        "Entrar"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapDispatchToProps = {
  handleLogin,
};

export default connect(null, mapDispatchToProps)(Login);
