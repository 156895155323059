import React from "react";

import { set, getHours, format } from "date-fns";

import { getNameWeek, getWeek, getScheduleInfo , getMaxDaysMonth } from "Utils/Schedules";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import DayCalendar from "Components/Schedule/Calendars/DayCalendar";
import MonthCalendar from "Components/Schedule/Calendars/MonthCalendar";
import { TitleCalendars } from "Components/Schedule/Calendars/TitleCalendars";
import { SearchBoxSchedule } from "Components/Schedule/SearchBoxSchedule";
import SelectTypeSchedule from "Components/Schedule/SelectTypeSchedule";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { WeekCalendar } from "../../Components/Schedule/Calendars/WeekCalendar";

export default function ListSchedule() {
  const { day, year: yearinfo, month_number, months } = getScheduleInfo();

  const [menuSelect, setMenuSelect] = React.useState("days");
  const [buttonPrevOrNext, setButtonPrevOrNext] = React.useState(0);
  const [buttonClick, setButtonClick] = React.useState(0);
  const [total_schedules, setTotalSechedules] = React.useState(0);
  const [IsSearch, setIsSearch] = React.useState({
    search: false,
    result: "",
    type: "name",
  });
  const [dateMonth, setDateMonth] = React.useState("");
  const [calendarSelect, setCalendarSelect] = React.useState({
    id: 4167028,
    name: "Exames (SP)",
  });

  const [year, setYear] = React.useState(yearinfo);
  const [monthLabel, setMonthLabel] = React.useState(month_number);
  const [dayLabel, setDayLabel] = React.useState(Number(day));
  const { labelWeek } = getNameWeek(monthLabel, year, dayLabel);
  const [weekDay, setWeekDay] = React.useState(labelWeek);
  const [WeekData, setWeekData] = React.useState({
    day,
    month: month_number + 1,
    year,
  });

  const GetWeek = getWeek();

  const [hours, setHours] = React.useState([
    "07:30",
    "08:30",
    "09:30",
    "10:30",
    "11:30",
    "12:30",
    "13:30",
    "14:00",
    "15:00",
  ]);

  const HandlePrevAndNextButton = React.useCallback(() => {
    if (menuSelect === "month") {
      if (monthLabel + buttonPrevOrNext === 12 && menuSelect === "month") {
        setButtonPrevOrNext(0);
        setMonthLabel(0);
        setYear((prev) => prev + 1);
        return;
      }

      if (monthLabel + buttonPrevOrNext === -1) {
        setButtonPrevOrNext(0);

        setMonthLabel(11);
        setYear((prev) => prev - 1);
        return;
      }
    }

    if (menuSelect === "days") {
      const monthDays = getMaxDaysMonth(monthLabel, year);

      if (dayLabel + buttonPrevOrNext === 0) {
        const monthDays = getMaxDaysMonth(monthLabel - 1, year);
        setButtonPrevOrNext(0);

        if (monthLabel === 0) {
          setYear((prev) => prev - 1);
          setMonthLabel(11);
          setDayLabel(31);
          return;
        }

        setMonthLabel((prev) => prev - 1);
        setDayLabel(monthDays);
        return;
      }

      if (dayLabel + buttonPrevOrNext === monthDays + 1) {
        setButtonPrevOrNext(0);

        if (monthLabel === 11) {
          setMonthLabel(0);
          setDayLabel(1);
          setYear((prev) => prev + 1);
          return;
        }

        setMonthLabel((prev) => prev + 1);
        setDayLabel(1);

        return;
      }
    }
  }, [buttonPrevOrNext, monthLabel, dayLabel, menuSelect, year]);

  const resetLabels = () => {
    if (dateMonth === "" && menuSelect !== "days") {
      setButtonPrevOrNext(0);
      setMonthLabel(month_number);
      setYear(yearinfo);
      setDayLabel(day);
      setButtonClick(0);
      setIsSearch({ search: false, result: "" });
    }
  };

  const handleClick = (state, reset_date) => {
    if (reset_date) {
      setMenuSelect(state);
      return resetLabels();
    }

    setDateMonth(`${year}-${monthLabel + buttonPrevOrNext + 1}-${state}`);
    setMenuSelect("days");
    setDayLabel(state);
    setMonthLabel(monthLabel + buttonPrevOrNext);
    setButtonPrevOrNext(0);
    setButtonClick(0);
  };

  const changeHours = () => {
    if (calendarSelect.name.includes("SP")) {
      setHours([
        "07:30",
        "08:30",
        "09:30",
        "10:30",
        "11:30",
        "12:30",
        "13:30",
        "14:00",
        "15:00",
      ]);
    }
    if (
      calendarSelect.name.includes("RJ") ||
      calendarSelect.name.includes("Niteroi")
    ) {
      setHours([
        "07:30",
        "08:30",
        "09:30",
        "10:30",
        "11:30",
        "12:30",
        "13:30",
        "14:30",
      ]);
    }
    // horários de clínica
    if (calendarSelect.name.includes("Clínica")) {
      // horários de 8h as 15:45 com intervalos de 15 minutos

      const date = new Date(0);
      const hours = [];
      const initialHour = set(date, { minutes: 0, hours: 8, seconds: 0 });
      let currentHour = getHours(initialHour);

      while (currentHour < 16) {
        hours.push(format(initialHour, "HH:mm"));
        initialHour.setMinutes(initialHour.getMinutes() + 15);
        currentHour = getHours(initialHour);
      }

      setHours(hours);
    }
  };

  React.useEffect(() => {
    HandlePrevAndNextButton();
  }, [HandlePrevAndNextButton]);

  React.useEffect(() => {
    menuSelect !== "search" && resetLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuSelect]);

  React.useEffect(() => {
    const { labelWeek } = getNameWeek(
      monthLabel,
      year,
      dayLabel + buttonPrevOrNext
    );
    setWeekDay(labelWeek);
  }, [dayLabel, monthLabel, year, buttonPrevOrNext]);

  React.useEffect(() => {
    changeHours();
  }, [calendarSelect]);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Agendamentos">
              <Breadcrumb
                list={[
                  {
                    path: "#",
                    label: "Agendamentos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "todos os agendamentos",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>

          <div className="schedule-box-header">
            <SelectTypeSchedule
              state={menuSelect}
              setState={handleClick}
              setButtonPrevOrNext={setButtonPrevOrNext}
              setButtonClick={setButtonClick}
              setIsSearch={setIsSearch}
              search={IsSearch}
              calendarSelect={calendarSelect}
              setCalendarSelect={setCalendarSelect}
              setMenuSelect={setMenuSelect}
            />
          </div>
        </div>

        <div className="page-content page-schedule">
          {!IsSearch.search && (
            <>
              {menuSelect === "days" && (
                <>
                  <div className="title-calendar">
                    <TitleCalendars
                      title={`${dayLabel + buttonPrevOrNext} de ${
                        months[monthLabel]
                      } de ${year}`}
                      total_schedules={
                        total_schedules > 0 ? total_schedules : 0
                      }
                    />
                  </div>
                  <DayCalendar
                    nameWeek={weekDay}
                    day={dayLabel + buttonPrevOrNext}
                    month={monthLabel + 1}
                    buttonClick={buttonClick}
                    setTotalSechedules={setTotalSechedules}
                    setDateMonth={setDateMonth}
                    dateMonth={dateMonth}
                    calendarSelect={calendarSelect}
                    year={year}
                    hours={hours}
                  />
                </>
              )}

              {menuSelect === "month" && (
                <>
                  <div className="title-calendar">
                    <TitleCalendars
                      title={`${
                        months[monthLabel + buttonPrevOrNext]
                      } de ${year}`}
                      total_schedules="764"
                    />
                  </div>
                  <MonthCalendar
                    daysWeek={GetWeek}
                    day={day}
                    month={monthLabel + buttonPrevOrNext}
                    year={year}
                    setDateMonth={setDateMonth}
                    calendarSelect={calendarSelect}
                    handleClick={handleClick}
                    buttonClick={buttonClick}
                  />
                </>
              )}

              {menuSelect === "week" && (
                <>
                  <div className="title-calendar">
                    <TitleCalendars
                      title={`Semana de ${WeekData.day} ${
                        months[WeekData.month - 1]
                      } de ${WeekData.year}`}
                      total_schedules={total_schedules}
                    />
                  </div>
                  <WeekCalendar
                    calendarSelect={calendarSelect}
                    buttonClick={buttonClick}
                    setTotalSechedules={setTotalSechedules}
                    setWeekData={setWeekData}
                    hours={hours}
                  />
                </>
              )}
            </>
          )}
          {IsSearch.search && (
            <>
              <div className="title-calendar">
                <TitleCalendars
                  title="Resultados"
                  total_schedules={total_schedules}
                />
              </div>
              <SearchBoxSchedule
                setTotalSechedules={setTotalSechedules}
                IsSearch={IsSearch}
                calendarSelect={calendarSelect}
                count={buttonClick}
              />
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}
