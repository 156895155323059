import React, { useState } from "react";

import { Button } from "Components/Button";

const CouponCart = ({ coupon_code, removeCoupon, updateCoupon }) => {
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);

  const deleteCoupon = async () => {
    setLoading(true);
    await removeCoupon();
    setLoading(false);
  };

  const getCouponValue = async () => {
    setLoading(true);
    await updateCoupon(coupon);
    setLoading(false);
  };

  return (
    <div>
      {coupon_code ? (
        <>
          <Button onClick={deleteCoupon} type="submit" loading={loading}>
            Remover
          </Button>

          <p className="text-center text-success small-separate">
            Cupom <span className="uppercase text-bold">{coupon_code}</span>{" "}
            aplicado com sucesso! :)
          </p>
        </>
      ) : (
        <div className="order-home-filter">
          <div className="filters-order">
            <div className="select-search-submit">
              <input
                type="text"
                className="input _sm _transparent _lgray"
                name="coupon_code"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder="Insira o cupom de desconto"
              />
              <Button
                onClick={getCouponValue}
                classButton="button"
                disabled={!coupon}
                loading={loading}
              >
                Validar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponCart;
