import { ReactNode } from "react";

import { Content } from "./styles";

interface ModalIconProps {
  children: ReactNode | string;
}

export const ModalText = ({ children }: ModalIconProps) => {
  return (
    <Content display="flex" direction="column" justify="center" mb="48">
      {children}
    </Content>
  );
};
