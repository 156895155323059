import { ConvertBase64ToFile } from "./ConvertBase64";

export const ConvertBase64ToPDFAndOpenNewTab = (pdf: any, filename: any) => {
  const byteCharacters = window.atob(pdf);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: "application/pdf;base64" });
  const fileURL = URL.createObjectURL(file);

  setTimeout(() => {
    window.open(fileURL, filename);
  }, 1000);

  return fileURL;
};


export const getFile = (base64: string, name: string) => {
  if (base64 && name) {
    const arrFiles = ConvertBase64ToFile([base64], [name]);
    arrFiles[0].deleteApi = true;
    return arrFiles[0];
  }
  return null;
};