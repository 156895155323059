import React from "react";

import Input from "Components/Form/Input";
import PanelContent from "Components/Panels/PanelContent";

export function Transports({
  transports,
  setTransports,
  transportInitial,
  loading,
  transportsActive,
  setTransportActive,
}) {
  function handleFormCreateTransport() {
    setTransports([...transports, transportInitial]);
  }

  function handleChangeTransport(value, index) {
    const trp = [...transports];

    trp[index] = {
      ...trp[index],
      transport: value,
    };

    setTransports([...trp]);
  }

  function handleActiveNearestTransports() {
    setTransportActive(true);
  }

  function handleDelete(callback, array, callbackActivePanel, index) {
    const filtered = array.filter((e, i) => i === index);
    const arrayCallback = array.filter((e, i) => i !== index);
    const newFind = { ...filtered[0], deleted: true };

    const results = [...arrayCallback, newFind];
    const isDeleted = results.every((transport) => transport.deleted);

    if (isDeleted) {
      callbackActivePanel(false);
      callback([transportInitial]);
      return;
    }

    callback(results);
  }

  return (
    <PanelContent title="Meios de Transporte" loading={loading}>
      {!transportsActive && (
        <div
          className="xs-12"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            className="button _white _icon-add"
            style={{ marginBottom: "1rem" }}
            onClick={handleActiveNearestTransports}
          >
            <span className="represent-icon-holder">+</span>
            Adicionar novo
          </button>
        </div>
      )}

      {transportsActive &&
        transports.map(
          (_, i) =>
            !transports[i].deleted && (
              <>
                <div className="xs-12">
                  <Input
                    label={`Endereço ${i + 1}`}
                    type="text"
                    id={`transport${i}`}
                    maxLength={255}
                    value={transports[i].transport}
                    onChange={({ target }) =>
                      handleChangeTransport(target.value, i)
                    }
                  />
                </div>
                <div className="xs-12 parking-itens-flex">
                  <div className="parking-grid-buttons">
                    <button
                      type="button"
                      className="button _white _icon-add"
                      onClick={handleFormCreateTransport}
                    >
                      <span className="represent-icon-holder">+</span>
                      Adicionar
                    </button>

                    <button
                      type="button"
                      onClick={() =>
                        handleDelete(
                          setTransports,
                          transports,
                          setTransportActive,
                          i
                        )
                      }
                      className="link link-danger"
                    >
                      Excluir
                    </button>
                  </div>
                </div>
              </>
            )
        )}
    </PanelContent>
  );
}
