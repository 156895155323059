import React from "react";
import { Editor } from "react-draft-wysiwyg";

import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import { InputHTMLEditProps } from "./interface";
import { InputContainer } from "./style";

export const InputHTMLEdit = ({
  id,
  label,
  value,
  error,
  onChange,
  placeholder,
  description,
}: InputHTMLEditProps) => {
  const onEditorStateChange = (editorState: EditorState) => {
    const textHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(textHtml);
  };

  const blocksFromHTML = convertFromHTML(value);

  const htmlBlocks = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const draftValue = EditorState.createWithContent(htmlBlocks);

  return (
    <InputContainer
      className={`
            field-holder 
            ${error ? "field-holder-invalid" : ""}
        `}
    >
      {label && (
        <label htmlFor={id} className={`text-gray text-semibold `}>
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      <Editor
        defaultEditorState={draftValue}
        wrapperClassName="input-html-wrapper"
        editorClassName="input-html-editor"
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "link",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
        }}
      />

      {error !== "" && (
        <span
          className={`error-message ${
            !label && !error ? "error-without-label" : ""
          } `}
        >
          {error}
        </span>
      )}
    </InputContainer>
  );
};
