import { Box } from "styles/globalComponents";

import { Button } from "Components/Button";
import { Loader } from "Components/Loader";

import CloseIcon from "Assets/Images/close.svg";

import { ModalContentProps } from "./interface";
import { ModalActions } from "./ModalActions";
import { ModalHeading } from "./ModalHeading";
import { ModalIcon } from "./ModalIcon";
import { ModalText } from "./ModalText";
import { ModalContent } from "./ModalWrapper";

export const Modal = ({
  closeOverlay = true,
  isOpen = false,
  loading = false,
  maxWidth = "556",
  closeIcon = true,
  requestClose,
  modalIcon,
  renderActions = null,
  renderDescription = null,
  renderHeading = null,
  maxHeight,
}: ModalContentProps) => {
  return (
    <ModalContent
      isOpen={isOpen}
      closeOnOverlay={closeOverlay}
      requestClose={requestClose}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {closeIcon && (
            <Box display="flex" justify="flex-end" onClick={requestClose}>
              <Button textButton width="auto" padding="0">
                <img src={CloseIcon} alt="" style={{ marginTop: '-1.5em'}}/> 
              </Button>
            </Box>
          )}

          {modalIcon && (
            <ModalIcon>
              <img src={modalIcon} alt="" style={{ marginTop: '-2em'}} />
            </ModalIcon>
          )}

          {renderHeading && <ModalHeading>{renderHeading()}</ModalHeading>}

          {renderDescription && <ModalText>{renderDescription()}</ModalText>}

          {renderActions && <ModalActions>{renderActions()}</ModalActions>}
        </>
      )}
    </ModalContent>
  );
};
