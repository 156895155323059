import API from "./Api";

const COGNITO_TOKEN_KEY = "@colabi/cognito";

export function isCognitoAuthenticated() {
  return !!localStorage.getItem(COGNITO_TOKEN_KEY);
}

export function getCognitoToken(ctx?: any) {
  return localStorage.getItem(COGNITO_TOKEN_KEY);
}

export function cognitoStartSession(token: string) {
  return localStorage.setItem(COGNITO_TOKEN_KEY, token);
}

export function cognitoEndSession() {
  return localStorage.removeItem(COGNITO_TOKEN_KEY);
}

export async function getCognitoAuthToken() {
  const response = await API.get('/cognito');

  return response.data?.access_token;
}
