import { useState, useEffect } from "react";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useProductCombos } from "Hooks/useProductCombos";
import { useStoreHandler } from "Hooks/useStoreFilters";

import { validateArrayType } from "Utils/Arrays";

import {
  BudgetFiltersProps,
  ProductsFilterState,
} from "Components/ServiceForm/Filters/interface";

const initialFilter = {
  search: "",
  partner_id: "",
  status_id: "",
  subcategories: "",
  services: "",
  categories: "",
  order_type: "",
};

const combosParams = {
  includeCombo: [
    "categories",
    "subcategories",
    "status",
    "services",
    "partners",
  ],
  searchBudget: true,
};

export const useFilters = ({
  getProducts,
  fetchNewPage,
}: BudgetFiltersProps) => {
  const {
    budget,
    selectedCovenant,
    zipCode,
    productsCategoriesSelected: productsCategories,
  } = useBudgetContext();

  const [search, setSearch] = useState<ProductsFilterState>({
    ...initialFilter,
    categories: productsCategories?.join(", ") ?? "",
  });

  const { storeFilters, clearStore } = useStoreHandler();
  const { combos } = useProductCombos(combosParams);

  const { getStoredFilters } = useStoreHandler();

  const { services, status, subcategories, categories, partners } =
    combos ?? {};

  const categoriesOpts = categories?.filter((category) =>
    productsCategories?.includes(category.id)
  );

  const subcategoriesOpts = subcategories?.filter(
    (subcategory) => subcategory.category_id === Number(search.categories)
  );

  const isHomeCollect = zipCode || budget?.zip_code;

  const baseSearchObject = {
    ...search,
    services: Array.isArray(search.services)
      ? search.services?.map((service) => service.id).join(",")
      : "",
    ...(selectedCovenant && {
      plan_id: selectedCovenant?.covenant_plan.id,
    }),
    ...(isHomeCollect && { zip_code: zipCode || budget?.zip_code }),
    ...(selectedCovenant && {
      collect_type: isHomeCollect ? "home_collect" : "unity_collect",
    }),
  };

  const handleTypeSearch = (typeSearch: string) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      search: typeSearch,
    }));

    storeUpdatedFilters("search", typeSearch);
  };

  const updateFiltersOnChange = (key: string, value: string | Object) => {
    if (key === "categories") {
      if (value) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          [key]: String(value),
          subcategories: "",
        }));
        return;
      }

      setSearch((prevSearch) => ({
        ...prevSearch,
        [key]: productsCategories ? productsCategories?.join(", ") : "",
        subcategories: "",
      }));
      return;
    }

    setSearch((prevSearch) => ({ ...prevSearch, [key]: value }));
  };

  const storeUpdatedFilters = (key: string, value: string | Object) => {
    const filters = getStoredFilters("savedFilters");

    const newFilters = {
      ...filters,
    };

    if (key === "categories" && value) {
      newFilters[key] = String(value);
      newFilters.subcategories = "";
      storeFilters(newFilters, "savedFilters");

      return;
    }

    if (key === "categories" && !value) {
      newFilters[key] = productsCategories
        ? productsCategories?.join(", ")
        : "";
      newFilters.subcategories = "";
      storeFilters(newFilters, "savedFilters");

      return;
    }

    if (key === "services") {
      newFilters[key] = JSON.stringify(value);
      storeFilters(newFilters, "savedFilters");

      return;
    }

    newFilters[key] = String(value);
    storeFilters(newFilters, "savedFilters");
  };

  const handleChangeFilter = (key: string, value: string | Object) => {
    updateFiltersOnChange(key, value);
    storeUpdatedFilters(key, value);
  };

  const handleSearchProducts = () => {
    const productsFilter = {
      ...baseSearchObject,
    };
    getProducts(productsFilter);
    fetchNewPage(1);
  };

  const orderProductsAlphabetically = (value: string) => {
    setSearch((prevState) => ({ ...prevState, order_type: value }));

    const searchFilters = {
      ...baseSearchObject,
      order_type: value,
    };

    getProducts(searchFilters);
  };

  const clearFilter = async () => {
    if (productsCategories && validateArrayType(productsCategories)) {
      const filtersCleared = {
        ...initialFilter,
        categories: productsCategories.join(","),
        ...(selectedCovenant && {
          plan_id: selectedCovenant?.covenant_plan.id,
        }),
        ...(isHomeCollect && { zip_code: zipCode || budget?.zip_code }),
        ...(selectedCovenant && {
          collect_type: isHomeCollect ? "home_collect" : "unity_collect",
        }),
      };

      getProducts(filtersCleared);

      setSearch(filtersCleared);
      clearStore("savedFilters");
    }
  };

  const retrieveFiltersOnPageRefresh = () => {
    const savedFilters = getStoredFilters("savedFilters");

    if (savedFilters) {
      return {
        ...savedFilters,
        categories: savedFilters.categories,
        services: savedFilters.services
          ? JSON.parse(savedFilters.services)
          : "",
      };
    }
  };

  useEffect(() => {
    const retrievedFilters = retrieveFiltersOnPageRefresh();

    if (retrievedFilters) setSearch(retrievedFilters);
    return () => clearStore("savedFilters");
  }, []);

  return {
    handleTypeSearch,
    search,
    productsCategories,
    handleSearchProducts,
    partners,
    handleChangeFilter,
    categoriesOpts,
    subcategoriesOpts,
    services,
    clearFilter,
    orderProductsAlphabetically,
    status,
  };
};
