import styled from "styled-components";

export const TitlePageSymptoms = styled.h3`
  margin: 0;
  padding: 0;

  margin-top: 0.5rem;

  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;

  letter-spacing: 0.5px;

  color: var(--color-purple);
`;

export const ScheduleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ScheduleHeader = styled.div`
  padding: 5px 1rem;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    padding: 0;
    margin-bottom: 2.5rem;

    font-family: var(--font-text);
    font-style: normal;
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 2.5rem;

    color: #999999;
  }

  select {
    width: 640px;
    min-width: 350px;

    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #b1b1b1;
  }
`;

export const ContainerReception = styled.div`
  padding: 1.75rem 0.5rem;
  background-color: #f5f5f5;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  .image-labi-schedule {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 80px;

    p {
      margin-top: 24px;
      text-align: center;
      color: var(--color-buy-credits);
      font-family: var(--font-display);
      font-weight: 500;
      font-size: 19px;
      line-height: 120%;
    }
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const BoxReception = styled.div`
  padding: 0.5rem 1rem;
`;

export const FlexCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ReceptionButtons = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  button {
    &:disabled {
      background: #ab92bf !important;
      color: white;
    }
  }
`;

export const SchedulesContainer = styled.div`
  margin: 2.2rem auto;
  padding: 2.2rem 3.375rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1.75rem;
      color: #999;
    }
  }

  .no-schedules {
    flex: 1;
    padding: 6rem 0;

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 90%;
      margin: 0 auto;
      max-width: 600px;
    }

    > p {
      margin-top: 24px;
      font-weight: 500;
      font-size: 19px;
      line-height: 22.8px;
      text-align: center;
      color: #572580;

      span {
        font-weight: 600;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 2rem 0;
    }
  }

  @media screen and (max-width: 1364px) {
    margin: 0;
    padding: 1rem;
  }
`;
