import styled from "styled-components";

export const Container = styled.div`
  input {
    border: none;
  }

  input::placeholder,
  textarea::placeholder {
    font-family: "Open Sans";
  }
`;

export const CheckListConvent = styled.ul`
  background: transparent;
  border-radius: 10px;
  width: 100%;
  padding: 0;

  &.show-border {
    border: 1px solid #f0f0f0 !important;
    border-radius: 10px;
  }
`;

export const CampChecklist = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 17px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }

  border-bottom: 1px solid #f0f0f0;

  img {
    height: 10px;
  }

  .text-img {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
      height: 18px;
    }
  }
`;

export const CampFile = styled.div`
  margin-right: 22px;
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 96px;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-bottom: 36px;

  img {
    max-width: 109px;
    max-height: 96px;
  }

  &.addAnexo {
    margin-right: 0 !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 168px;
  }

  @media screen and (max-width: 768px) {
    width: 45%;
  }

  @media screen and (max-width: 495px) {
    width: 100%;
  }
`;

export const Flex = styled.div`
  label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #999999;
    font-weight: 400;
    font-size: 13px;
  }

  input {
    display: none;
  }

  span {
    width: 12px;
    height: 12px;
    display: block;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-right: 5px;

    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      margin-top: 13px;
    }
  }

  input:checked + span {
    background-color: #ab92bf;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.space {
    margin-left: 30px !important;
  }
`;

export const CheckListInput = styled.div`
  display: flex;

  .label-input {
    position: relative;
    width: 88%;
    margin-right: 16px;
  }

  button {
    height: 34px;
    position: relative;
    top: 30px;
  }
`;

export const PositionAnnex = styled.div`
  position: relative;
  margin-bottom: 93px;

  .positionButton {
    position: absolute;
    right: 6px;
    margin-top: 15px;
  }
`;
