import { MotionSelectedCovenant } from "Dtos/ICovenant";
import { BudgetMotion } from "Dtos/IMotion";

import { Modal } from "Components/Modals/Modal";

import AttentionIcon from "Assets/Images/atention.svg";

import { ModalForm } from "./ModalForm";

interface MotionBudget {
  isOpen: boolean;
  closeModal: () => void;
  covenantSelected: MotionSelectedCovenant;
  onSubmit: (vals: BudgetMotion) => Promise<void>;
}

export const ModalCovenantMotion = ({
  closeModal,
  covenantSelected,
  isOpen,
  onSubmit,
}: MotionBudget) => {
  const { covenant, covenant_plan, credential_number } = covenantSelected;

  const initialValues = {
    covenant: covenant.id,
    plan: covenant_plan.id,
    plan_card_number: credential_number,
    front_card: null,
    back_card: null,
    medical_order: null,
  };

  return (
    <Modal
      closeOverlay
      closeIcon={false}
      requestClose={closeModal}
      maxHeight="663"
      isOpen={isOpen}
      modalIcon={AttentionIcon}
      renderActions={() => (
        <ModalForm
          closeModal={closeModal}
          onSubmit={onSubmit}
          initialValues={initialValues}
        />
      )}
      renderHeading={() => <h2> Enviar orçamento para o Motion</h2>}
      renderDescription={() => (
        <p>
          Para enviar para o Motion é necessário completar todas as informações
        </p>
      )}
    />
  );
};
