export function getBreadcrumbs(personId: string, orderId: string) {
  return [
    {
      path: "/pessoas",
      label: "Pessoas",
      current: false,
    },
    {
      path: "/pessoas",
      label: "Todas as pessoas",
      current: false,
    },
    {
      path: `/ficha-cliente/${personId}`,
      label: "Ficha do cliente",
      current: false,
    },
    {
      path: `/ficha-cliente/${personId}/appointments/${orderId}`,
      label: "Visualizar pedido",
      current: true,
    },
  ];
}
