import React, { useMemo } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import Skeleton from "react-loading-skeleton";

import { getAppointments } from "Requests/Schedules";

import { getDifferenceInMinutes, getTimeWithAdd } from "Utils/Date";

import { useDebounce } from "../../../../Hooks/useDebounce";
import BoxScheduleInfo from "../../BoxScheduleInfo";
import { LinesCalendar } from "../../LinesCalendar";
import { ModalDetailsAppointments } from "../../Modals/ModalDetailsAppointments";

import "react-loading-skeleton/dist/skeleton.css";

export default function DayCalendar({
  hours,
  nameWeek,
  day,
  month,
  buttonClick,
  setTotalSechedules,
  dateMonth,
  setDateMonth,
  year,
  calendarSelect,
}) {
  const [schedules, setSchedules] = React.useState({
    reducer: {},
    length_total: [],
  });
  const [modalVisible, setModalVisible] = React.useState(false);
  const [data, setData] = React.useState({});

  const [loading, setLoading] = React.useState(false);
  const fetchAppointments = useDebounce(
    async () => {
      try {
        setLoading(true);
        if (day === 0) return;

        const _dateMonth = `${year}-${month}-${day}`;
        dateMonth && setDateMonth(`${year}-${month}-${day}`);

        const res = dateMonth
          ? await getAppointments({
              type: "days",
              date: _dateMonth,
              calendarId: calendarSelect.id,
            })
          : await getAppointments({
              type: "days",
              count: buttonClick,
              calendarId: calendarSelect.id,
            });
        setSchedules(res);

        setTotalSechedules(res.total_schedules);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    1000,
    [day, calendarSelect]
  );

  const formattedSchedules = useMemo(() => {
    if (!schedules) return schedules;

    const differenceInMinutes = getDifferenceInMinutes(hours[0], hours[1]);

    const returningSchedules = schedules?.reducer;

    Object.keys(schedules?.reducer).forEach((hour) => {
      const _schedules = schedules?.reducer[hour];

      _schedules.forEach((schedule) => {
        const periodOfSchedule = getDifferenceInMinutes(
          schedule.time,
          schedule.endTime
        );

        const schedulesRepeatedIds = _schedules
          .filter((s) => !!s.repeated)
          .map((s) => s.id);

        if (
          periodOfSchedule > differenceInMinutes &&
          !schedulesRepeatedIds.includes(schedule.id)
        ) {
          const numberOfRepetitions =
            periodOfSchedule / differenceInMinutes - 1;

          for (let i = 0; i < numberOfRepetitions; i++) {
            const newHour = getTimeWithAdd(hour, differenceInMinutes * (i + 1));

            if (!returningSchedules[newHour]) {
              returningSchedules[newHour] = [];
            }

            returningSchedules[newHour].unshift(schedule);
          }

          schedule.repeated = true;
        }
      });
    });

    return returningSchedules;
  }, [schedules?.reducer]);

  React.useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  return (
    <>
      <ModalDetailsAppointments
        modalDismiss={() => setModalVisible(false)}
        modalVisible={modalVisible}
        data={data}
      />
      <ScrollContainer
        className={`schedule-calendar ${loading ? "schedule-center" : ""}`}
        vertical={false}
        activationDistance={100}
        hideScrollbars={false}
      >
        {!loading && schedules?.reducer ? (
          Object.keys(schedules?.reducer).length > 0 ? (
            <table className="day" border="0">
              <tr className="schedule-header">
                <th className="schedule-label-hour">Hora</th>
                <th colSpan={schedules.length_total} className="schedule-date">
                  {nameWeek}, {day}/{month}
                </th>
              </tr>
              {hours.map((hour) => (
                <tr key={hour}>
                  <td rowSpan="1" key={hour} className="schedule-hour">
                    {" "}
                    {hour}{" "}
                  </td>

                  {formattedSchedules[hour] ? (
                    <>
                      {formattedSchedules[hour].map((schedule) => (
                        <td
                          className="calendar-container-box"
                          key={schedule.id}
                          onClick={() => {
                            setModalVisible(true);
                            setData(schedule);
                          }}
                        >
                          <BoxScheduleInfo color hour={hour} info={schedule} />
                        </td>
                      ))}
                      {schedules.length_total
                        .slice(
                          0,
                          schedules.length_total.length -
                            formattedSchedules[hour].length
                        )
                        .map((_) => (
                          <td className="calendar-line no-line-center">
                            <LinesCalendar only />
                          </td>
                        ))}
                    </>
                  ) : (
                    schedules.length_total.map((_, i) => (
                      <td className="calendar-line no-line-center" key={i}>
                        <LinesCalendar only />
                      </td>
                    ))
                  )}
                </tr>
              ))}
            </table>
          ) : (
            <div
              className={`schedule-center  ${
                !loading && schedules?.total_schedules === 0 ? "" : "hidden"
              }`}
            >
              <p className="text-saira text-purple text-center text-size text-normal">
                Nenhum agendamento disponível...
              </p>
            </div>
          )
        ) : (
          <div className="skeleton">
            <Skeleton height={47} width={1635} />
            <Skeleton height={90} width={1635} count={6} />
          </div>
        )}
      </ScrollContainer>
    </>
  );
}
