import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 15px;

  .fasting-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 10px;
    .medication {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

export const AddButton = styled.button`
  background-color: white;
  color: #666666;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const DelButton = styled.button`
  color: #572580;
  font-size: 22px;
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
  padding: 0;
`;

export const ContainerFloatLabel = styled.div`
  width: 60px;
  position: absolute;
  left: 178px;
  bottom: -7px;
  @media (max-width: 1427px) {
    bottom: 10px;
  }
`;
