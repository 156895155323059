import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GetReports, GetComboReports, SendReports } from "Requests/Reports";

import validatePermissions from "Utils/validatePermissions";

import Input from "Components/Form/Input";
import InputDate from "Components/Form/InputDate";
import Select from "Components/Form/Select";
import DetailsResultsModal from "Components/Modals/DetailsResultsModal";
import Pagination from "Components/Nav/Pagination";

import LoadingImage from "Assets/Images/loading-purple.gif";

const DEFAULT_FILTERS = {
  requisition_code: "",
  status: "",
  period_start: null,
  period_end: null,
};

function ResultsTab({ person }) {
  const user_permissions = useSelector((state) => state.User.permissions);

  const [resultVisible, setResultVisible] = useState(false);
  const [resultSelected, setResultSelected] = useState(null);
  const [LoadingResults, setLoadingResults] = useState(false);
  const [results, setResults] = useState([]);
  const [paginationResults, setPaginationResults] = useState({});
  const [lastPagePaginationResults, setLastPagePaginationResults] = useState(1);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [statusCombo, setStatusCombo] = useState([]);
  const [resultsFilter, setResultsFilter] = useState(DEFAULT_FILTERS);

  useEffect(() => {
    searchResultsByUser();
  }, []);

  function clearFilters() {
    setResultsFilter(DEFAULT_FILTERS);
  }

  async function searchResultsByUser() {
    setLoadingResults(true);

    const resultsObject = await GetReports(`?person_id=${person.id}`);
    const filterObject = await GetComboReports();

    setStatusCombo(filterObject.data.Reports_Status);

    setResults(resultsObject.data);

    if (resultsObject.meta) setPaginationResults(resultsObject.meta.pagination);

    setLoadingResults(false);
  }

  async function fetchPaginationResults(currentPage) {
    setLoadingResults(true);

    const resultsObject = await GetReports(
      `?person_id=${person.id}`,
      `&page=${currentPage}`
    );

    setResults(resultsObject.data);

    if (resultsObject.meta) setPaginationResults(resultsObject.meta.pagination);

    setLoadingResults(false);
  }

  function createToast(style, message) {
    toast.dismiss();

    setTimeout(() => {
      toast[style](message);
    }, 400);
  }

  function handleChangeResultsFilter({ target: { value, name } }) {
    setResultsFilter({
      ...resultsFilter,
      [name]: value,
    });
  }

  function handlePagination(e, type) {
    if (lastPagePaginationResults === e.currentPage) return;

    fetchPaginationResults(e.currentPage);

    setLastPagePaginationResults(e.currentPage);
  }

  function handleOpenDetails(code) {
    setResultSelected(code);

    setResultVisible(true);
  }

  async function handleFilterResults(e) {
    setLoadingResults(true);

    e.preventDefault();

    const startDate = resultsFilter.period_start
      ? resultsFilter.period_start.format("YYYY-MM-DD")
      : "";
    const endDate = resultsFilter.period_end
      ? resultsFilter.period_end.format("YYYY-MM-DD")
      : "";

    const filterURL = `?person_id=${person.id}&requisition_code=${resultsFilter.requisition_code}&status=${resultsFilter.status}&period_start=${startDate}&period_end=${endDate}`;

    const resultsObject = await GetReports(filterURL);

    setResults(resultsObject.data);

    if (resultsObject.meta) setPaginationResults(resultsObject.meta.pagination);

    setLoadingResults(false);
  }

  async function handleSendViaEmail(code) {
    setSendingEmail(true);

    const response = await SendReports(code);

    createToast(response.success ? "success" : "error", response.message);

    setSendingEmail(false);
  }

  return (
    <div>
      <DetailsResultsModal
        resultSelected={resultSelected}
        modalVisible={resultVisible}
        modalDismiss={() => setResultVisible(false)}
        resendEmail={handleSendViaEmail}
        loading={LoadingImage}
        sendingEmail={sendingEmail}
      />
      <div className="token-area">
        <form onSubmit={handleFilterResults} className="separate-base">
          <div className="row">
            <div className="sm-6 md-3 lg-2 small-separate">
              <div className="search-input">
                <Input
                  id="requisition_code"
                  type="search"
                  placeholder="Buscar pelo código"
                  value={resultsFilter.requisition_code}
                  onChange={handleChangeResultsFilter}
                />
              </div>
            </div>
            <div className="sm-6 md-3 lg-2 small-separate">
              <Select
                id="status"
                value={resultsFilter.status}
                onChange={handleChangeResultsFilter}
                options={statusCombo}
                loading={statusCombo.length === 0}
                readOnly={statusCombo.length === 0}
                placeholder={
                  statusCombo.length > 0 ? "Todos os status" : "Sem opções"
                }
              />
            </div>
            <div className="sm-6 md-3 lg-2 small-separate">
              <InputDate
                id="period_start"
                onDateChange={(value) => {
                  handleChangeResultsFilter({
                    target: {
                      name: "period_start",
                      value,
                    },
                  });
                }}
                openInModal
                value={resultsFilter.period_start}
                labelInline
                label="De"
                blockPastChanges={false}
              />
            </div>
            <div className="sm-6 md-3 lg-2 small-separate">
              <InputDate
                id="period_end"
                onDateChange={(value) => {
                  handleChangeResultsFilter({
                    target: {
                      name: "period_end",
                      value,
                    },
                  });
                }}
                openInModal
                value={resultsFilter.period_end}
                labelInline
                label="Até"
                blockPastChanges={false}
              />
            </div>
            <div className="sm-6 lg-2 small-separate">
              <div className="search-button">
                <button type="submit" className="button _action _block">
                  Filtrar
                </button>
              </div>
            </div>
            <div className="sm-6 lg-2 small-separate">
              <div className="search-button">
                <button
                  type="button"
                  className="button _white _action _block"
                  onClick={clearFilters}
                >
                  Limpar filtros
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="table-list-cards --result">
          {LoadingResults ? (
            <span className="loading-persons">
              <img src={LoadingImage} alt="" />
            </span>
          ) : (
            <>
              <ul className="list-header">
                <li className="item">Cód. Requisição/Protocolo</li>
                <li className="item">Carteirinha</li>
                <li className="item">Unidade</li>
                <li className="item">Data da Abertura Protocolo</li>
                <li className="item">Status</li>
                <li className="item">E-mail</li>
                <li className="item" />
              </ul>

              {results.length > 0 ? (
                results.map((result) => (
                  <div key={result.id} className="card card-table --result">
                    <span>{result.requisition_code}</span>
                    <span>
                      {result.commercial_document_number || "Não informado"}
                    </span>
                    <span>{result.responsible_unit_name}</span>
                    <span>{result.displayDate}</span>
                    <span>{result.status}</span>
                    <span>{result.contact}</span>
                    <div className="text-right action">
                      {validatePermissions(
                        "Send Mail Reports",
                        user_permissions
                      ) && (
                        <button
                          className={`button _blue _action ${
                            sendingEmail ? "_loading" : ""
                          }`}
                          onClick={() =>
                            handleSendViaEmail(result.requisition_code)
                          }
                        >
                          <span className="details-text">
                            Enviar por e-mail
                          </span>
                        </button>
                      )}
                      <button
                        onClick={() =>
                          handleOpenDetails(result.requisition_code)
                        }
                        className="button _white _action"
                      >
                        <span className="details-text">Visualizar</span>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="card card-table-empty">
                  <p className="text-danger text-display text-center">
                    Oops! Não encontramos nenhum resultado para este paciente.
                    :(
                  </p>
                </div>
              )}
            </>
          )}

          {paginationResults && paginationResults.total_pages > 1 && (
            <div className="pagination-wrapper">
              <Pagination
                totalRecords={paginationResults.total_pages}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={(e) => handlePagination(e, "results")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultsTab;
