import React, { useState } from "react";

import { Field, useFormikContext } from "formik";
import { FormValuesProps } from "Pages/Product/ProductForm/interface";

import { TextEllipsis } from "Utils/String";

import { CustomCheckbox } from "Components/Form/CustomCheckbox";

import Arrow from "Assets/Images/arrowAccordion.svg";

import { CheckboxListProps } from "./interface";
import { Container } from "./style";

export const SubListCheckbox = ({
  region,
  handleSelectUnits,
  handleSelectAllRegionUnits,
}: CheckboxListProps) => {
  const [open, setOpen] = useState(true);
  const { values } = useFormikContext<FormValuesProps>();
  return (
    <Container open={open} className="checkbox-units">
      <div className="head">
        <Field
          component={CustomCheckbox}
          type="checkbox"
          name="units"
          value={region?.id}
          label={region?.region}
          checked={values.units.include.includes(region?.id)}
          onChange={() => handleSelectAllRegionUnits(region?.id)}
        />
        <div
          role="button"
          onKeyDown={() => setOpen(!open)}
          onClick={() => setOpen(!open)}
          tabIndex={0}
          className="arrow-container "
        >
          <img src={Arrow} alt="arrow" className="arrow-button" />
        </div>
      </div>

      {region?.units?.map((unit) => (
        <ul key={unit.id}>
          <li>
            <Field
              component={CustomCheckbox}
              type="checkbox"
              name="units"
              value={unit.id}
              label={TextEllipsis(unit.name, 17)}
              checked={values.units.include.includes(unit.id)}
              onChange={() => handleSelectUnits(unit.id)}
            />
          </li>
        </ul>
      ))}
    </Container>
  );
};
