import moment from "moment";
import * as Yup from "yup";

import { validateFullname } from "Services/schema/validations";

export function validateDocument(value, birthday) {
  if (!birthday) return true;

  const [day, month, year] = birthday.split("/");

  if (!year || year.length !== 4) {
    return true;
  }

  const age = moment().diff(`${year}-${month}-${day}`, "years");

  if (age < 18) {
    return true;
  }
  if (!value) return false;

  return true;
}

export const schema = Yup.object().shape({
  is_ghost: Yup.boolean(),
  name: Yup.string()
    .test("name", "Nome e Sobrenome", validateFullname)
    .required("Obrigatório"),
  phone: Yup.string()
    .min(10, "Número inválido")
    .max(15, "Número inválido"),
  email: Yup.string().email("Email inválido").required("Obrigatório"),
  gender: Yup.string().required("Obrigatório"),
  birthday: Yup.string()
    .min(10, "Data inválida")
    .max(10, "Data inválida")
    .required("Obrigatório"),
  type_document: Yup.string().when("is_ghost", {
    is: false,
    then: (schema) =>
      schema.test("birthday", "Obrigatório", (value, context) => {
        const { birthday } = context.parent;
        return validateDocument(value, birthday);
      }),
  }),
  document: Yup.string()
    .min(11, "Documento inválido")
    .max(20, "Documento inválido")
    .when("type_document", {
      is: (value) => value === "cpf",
      then: (schema) =>
        schema.test("birthday", "Obrigatório", (value, context) => {
          const { birthday } = context.parent;

          return validateDocument(value, birthday);
        }),
      otherwise: Yup.string().notRequired(),
    }),
  passport: Yup.string().when("type_document", {
    is: (value) => value === "passport",
    then: Yup.string().required("Obrigatório"),
    otherwise: Yup.string().notRequired(),
  }),
  country: Yup.string().when("type_document", {
    is: "passport",
    then: Yup.string().required("Obrigatório"),
    otherwise: Yup.string().notRequired(),
  }),

  is_responsible: Yup.boolean(),
  is_link_responsible_form: Yup.boolean(),

  responsible_name: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string()
      .min(2, "Nome muito curto")
      .max(50, "Nome muito longo")
      .required("Obrigatório"),
    otherwise: Yup.string(),
  }),

  responsible_phone: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string()
      .min(14, "Número inválido")
      .max(15, "Número inválido")
      .required("Obrigatório"),
    otherwise: Yup.string(),
  }),

  responsible_email: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string().email("Email inválido").required("Obrigatório"),
    otherwise: Yup.string(),
  }),

  responsible_gender: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string().required("Obrigatório"),
    otherwise: Yup.string(),
  }),

  responsible_birthday: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string()
      .min(10, "Data inválida")
      .max(10, "Data inválida")
      .required("Obrigatório"),
    otherwise: Yup.string(),
  }),

  responsible_document: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string()
      .min(14, "Documento inválido")
      .max(14, "Documento inválido")
      .required("Obrigatório"),
    otherwise: Yup.string(),
  }),


  responsible_type_document: Yup.string().when("is_link_responsible_form", {
    is: true,
    then: Yup.string().required("Obrigatório"),
    otherwise: Yup.string(),
  }),
});
