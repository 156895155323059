import React from "react";

import { Field, useFormikContext, FieldArray } from "formik";
import { Box } from "styles/globalComponents";

import { hasPathInArray } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";
import { CreatableTopicList } from "Components/Form/CreatebleTopicList";
import Input from "Components/Form/Input";
import { InputHTMLEdit } from "Components/Form/InputHTMLEdit";

import { FormValuesProps } from "../../interface";

export const DescriptionForm = () => {
  const { values, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<FormValuesProps>();
  const { category_id } = values;
  const categoryId = String(category_id.id);

  const changeInputEditorHtml = (textEditor: string) => {
    setFieldValue("product_description.description", textEditor);
    setFieldTouched("product_description.description");
  };

  return (
    <AccordionCollapse title="Descrição">
      <Box mt="15">
        <div className="input-container">
          <Field
            name="product_description.description"
            id="product_description.description"
            label="descrição (o que é)"
            onChange={changeInputEditorHtml}
            value={values.product_description.description}
            component={InputHTMLEdit}
            placeholder="Descreva aqui"
          />
        </div>

        {hasPathInArray([1, 2, 3, 5, 7], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.why_do"
              id="product_description.why_do"
              value={values.product_description.why_do}
              onChange={handleChange}
              label="Por que fazer"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([1, 2, 3, 5, 7, 8], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.how_to"
              value={values.product_description.how_to}
              id="product_description.how_to"
              onChange={handleChange}
              label="Como é feito"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([1, 2, 3], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.results"
              id="product_description.results"
              value={values.product_description.results}
              label="Resultados possíveis"
              component={Input}
              onChange={handleChange}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.reactions"
              id="product_description.reactions"
              label="Possíveis reações"
              value={values.product_description.reactions}
              component={Input}
              onChange={handleChange}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.composition"
              id="product_description.composition"
              value={values.product_description.composition}
              onChange={handleChange}
              label="Composição"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([5, 7], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.orientation"
              id="product_description.orientation"
              onChange={handleChange}
              value={values.product_description.orientation}
              label="Orientações"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([7], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.restriction"
              id="product_description.restriction"
              value={values.product_description.restriction}
              onChange={handleChange}
              label="Restrições"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([8], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.presentation"
              id="product_description.presentation"
              value={values.product_description.presentation}
              onChange={handleChange}
              label="Apresentação do medicamento"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4, 8], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.protection"
              id="product_description.protection"
              value={values.product_description.protection}
              onChange={handleChange}
              label="Protege contra"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4, 8], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.indicated"
              id="product_description.indicated"
              value={values.product_description.indicated}
              onChange={handleChange}
              label="indicado para"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4, 5, 8], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.not_indicated"
              id="product_description.not_indicated"
              value={values.product_description.not_indicated}
              onChange={handleChange}
              label="contraindicao para"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.dosage"
              id="product_description.dosage"
              value={values.product_description.dosage}
              onChange={handleChange}
              label="por que esquema de doses"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4, 8], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.application"
              id="product_description.application"
              value={values.product_description.application}
              onChange={handleChange}
              label="como é aplicada"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4], categoryId) && (
          <div className="input-container ">
            <Field
              name="product_description.availability_sus"
              id="product_description.availability_sus"
              value={values.product_description.availability_sus}
              onChange={handleChange}
              label="disponibilidade no sus"
              component={Input}
              multiline
              placeholder="Descreva aqui"
            />
          </div>
        )}

        {hasPathInArray([4], categoryId) && (
          <>
            <Box mb="0" className="w-100">
              <FieldArray
                name="product_description.public_differentials"
                render={(arrayHelper) => {
                  return (
                    <Field
                      component={CreatableTopicList}
                      label="Diferenciais vacina pública"
                      name="public_differentials"
                      placeholder='Digite os tópicos aqui e aperte "enter" para adicionar'
                      mapValues={
                        values.product_description.public_differentials
                      }
                      cssClass="w-100"
                      addToPackage={(value: string) => arrayHelper.push(value)}
                      deleteItem={(index: number) => arrayHelper.remove(index)}
                    />
                  );
                }}
              />
            </Box>

            <div className="input-container w-100">
              <FieldArray
                name="product_description.private_differentials"
                render={(arrayHelper) => {
                  return (
                    <Field
                      component={CreatableTopicList}
                      name="private_differentials"
                      label="Diferenciais vacina privada"
                      placeholder='Digite os tópicos aqui e aperte "enter" para adicionar'
                      cssClass="w-100"
                      mapValues={
                        values.product_description.private_differentials
                      }
                      addToPackage={(value: string) => arrayHelper.push(value)}
                      deleteItem={(index: number) => arrayHelper.remove(index)}
                    />
                  );
                }}
              />
            </div>
          </>
        )}

        <div className="input-container">
          <Field
            name="product_description.sell_note"
            id="product_description.sell_note"
            value={values.product_description.sell_note}
            label="nota de venda"
            multiline
            onChange={handleChange}
            component={Input}
            placeholder="Descreva aqui"
          />
        </div>
      </Box>
    </AccordionCollapse>
  );
};
