import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

const SERVICE_FORM_NAVIGATOR = (patientSelected) => {
  const componentHavePacient = (
    <>
      Paciente selecionado:
      <p className="text-purple text-no-margin">
        <strong>Nome:</strong>{" "}
        {patientSelected?.first_name || patientSelected?.name}
      </p>
    </>
  );

  return [
    {
      icon: "icon-account",
      label: patientSelected.id ? componentHavePacient : "Selecionar paciente",
      path: "/ficha-de-atendimento/paciente",
      matchPaths: ["/ficha-de-atendimento/paciente"],
      completed: !!patientSelected.id,
    },
    {
      icon: "icon-cart",
      label: "Ver orçamentos",
      path: "/ficha-de-atendimento/:type/orcamentos",
      matchPaths: [
        "/ficha-de-atendimento/:type/orcamentos",
        "/ficha-de-atendimento/selecionar-produtos/:id",
      ],
    },
  ];
};

const PageSteper = ({ patientSelected }) => {
  let list = [];

  const location = useLocation();

  if (location.pathname.includes("/ficha-de-atendimento")) {
    list = SERVICE_FORM_NAVIGATOR(patientSelected);
  }

  function isPathActive(item) {
    return item.matchPaths.indexOf(location.pathname) !== -1;
  }

  return (
    <section className="page-stepper-holder">
      <div className="container">
        <div className="page-stepper-list">
          {list.map((item, index) => (
            <Fragment key={item.path}>
              <Link
                to={item.path}
                className={`page-stepper-item ${
                  isPathActive(item) || item.completed ? "active" : ""
                }`}
              >
                {item.icon && (
                  <div className="icon-holder">
                    <i
                      className={item.completed ? "icon-checked" : item.icon}
                    />
                  </div>
                )}
                <span>{item.label}</span>
              </Link>

              {index < list.length - 1 && (
                <i className="icon-angle-double-right" />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PageSteper;
