import React, { useState, useEffect, useRef } from "react";

import { TextEllipsis } from 'Utils/Uploads'

import InputDateRange from "Components/Form/InputDateRange";
import Select from 'Components/Form/Select';
import Pagination from "Components/Nav/Pagination";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";


import LoadingImage from "Assets/Images/loading-purple.gif";


import ModalRNDS from "../../Components/Modals/ModalRNDS";
import { getLogSalesForce, getModules } from "../../Requests/Salesforce";

const Salesforce = () => {
    const typingTimeout = useRef();

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filterParams, setFilterParams] = useState(null);

    const [filterForm, setFilterForm] = useState({
        dates: {
            startDate: null,
            endDate: null
        }
    });

    const [moduleOptions, setModuleOptions] = React.useState([])
    const [moduleSelected, setModuleSelected] = React.useState("");

    const [modalVisible, setModalVisible] = React.useState(false);
    const [description, setDescription] = React.useState("");

    useEffect(() => {
        fetchData();
        fetchModules()
    }, []);

    useEffect(() => {
        if (!filterParams) return

        fetchData();
    }, [filterParams]);




    async function fetchModules() {
        try {
            const response = await getModules();
            setModuleOptions(response);
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchData(page, per_page) {
        setLoading(true);
        !page && setPagination({});

        try {
            const params = filterParams || {};

            const _per_page = per_page || pagination.per_page;

            const _items = await getLogSalesForce(params, page, moduleSelected, _per_page);

            setItems(_items.data);

            setPagination(_items.pagination);

        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    }


    function handleDatesChange(value) {
        setFilterForm({
            ...filterForm,
            dates: value
        });
    };

    function handlePagination(e) {
        if (pagination.current_page === e.currentPage) return;

        fetchData(e.currentPage);
    }

    function handleChangePerPage(value) {
        if (typingTimeout.current) clearTimeout(typingTimeout.current);

        typingTimeout.current = setTimeout(() => {
            fetchData(null, value);
        }, 1000);

        setPagination({
            ...pagination,
            per_page: value
        });
    }

    function _showLoading() {
        return (
            <tr>
                <td colSpan="7" className="text-center">
                    <span className="loading-persons">
                        <img src={LoadingImage} alt="" />
                    </span>
                </td>
            </tr>
        );
    }

    function _tableList() {
        return !items.length ? (
            <tr>
                <td colSpan="7" className="text-center text-purple">Oops! Parece que nada foi encontrado...</td>
            </tr>
        ) : (
            <>
                {items.map(item => {
                    const separete = item.created_at.toString().split(" ");
                    const getTime = separete[0].split("-");

                    const getHour = separete[1].split(":")
                    const format = `${getTime[2]}/${getTime[1]}/${getTime[0]} - ${getHour[0]}:${getHour[1]}`

                    return (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.id_origin}</td>
                            <td>{item.operator_id}</td>
                            <td>{item.module}</td>
                            <td
                                className="text-click"
                                onClick={() => { setModalVisible(true); setDescription(item.observation) }}
                            >
                                {TextEllipsis(item.observation, 15)}
                            </td>
                            <td>{format}</td>
                            <td className="text-purple text-semibold text-display">{item.action}</td>
                        </tr>
                    )
                })}
            </>
        );
    }

    function _renderTable() {
        return (
            <table className="table-content small-separate details">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Id de origem</th>
                        <th>Id do operador</th>
                        <th>Modulo</th>
                        <th>Observação</th>
                        <th>Data</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && _showLoading()}
                    {!loading && _tableList()}
                </tbody>
            </table>
        );
    }

    function handleSubmitFilter(event) {
        event.preventDefault();

        const _filterParams = {
            ...filterForm,
            period_start: filterForm.dates.startDate ? filterForm.dates.startDate.format("YYYY-MM-DD") : '',
            period_end: filterForm.dates.endDate ? filterForm.dates.endDate.format("YYYY-MM-DD") : (
                filterForm.dates.startDate ? filterForm.dates.startDate.format("YYYY-MM-DD") : ''
            )
        };

        delete _filterParams.dates;

        setFilterParams(_filterParams);
    };


    return (
        <PageWrapper className="entry-app-content">
            <ModalRNDS externalCss text="Descrição Salesforce" modalVisible={modalVisible} modalDismiss={() => setModalVisible(false)} errorRNDS={description} />

            <div className="content-holder">
                <div className="page-heading">
                    <div className="wrapper-header-content">
                        <PageHeader title="Lista Salesforce">
                            <Breadcrumb
                                list={[
                                    {
                                        path: "#",
                                        label: "Logs",
                                        current: false
                                    },
                                    {
                                        path: "#",
                                        label: "Lista Salesforce",
                                        current: true
                                    }
                                ]}
                            />
                        </PageHeader>
                    </div>
                    <form id="form-filter" onSubmit={handleSubmitFilter} className="form-heading margin-2">
                        <div className="row">
                            <div className="row sm-12 md-4 lg-12">

                                <div className="sm-12 md-4 lg-4">
                                    <div className="search-input">
                                        <Select
                                            id="module"
                                            value={moduleSelected}
                                            placeholder="Todos os modulos"
                                            onChange={({ target }) => setModuleSelected(target.value)}
                                            options={moduleOptions}
                                            readOnly={!moduleOptions.length}
                                        />
                                    </div>
                                </div>

                                <div className="sm-12 md-3 lg-6">
                                    <div className="search-input">
                                        <InputDateRange
                                            startDatePlaceholderText="Data inicial"
                                            endDatePlaceholderText="Data final"
                                            startDateId="start_date"
                                            endDateId="end_date"
                                            onDatesChange={(value) => handleDatesChange(value)}
                                            openInModal={false}
                                            dates={filterForm.dates}
                                            blockPastChanges={false}
                                            withClear
                                        />
                                    </div>
                                </div>
                                <div className="sm-12 md-2 lg-2">
                                    <div className="search-button">
                                        <button
                                            type="submit"
                                            className="button _action"
                                        >
                                            Filtrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div className="page-content page-content-list">
                    {_renderTable()}
                </div>
            </div>
            <div className="pagination-wrapper">
                {pagination.total_pages && (
                    <>
                        <Pagination
                            totalRecords={pagination.total_pages}
                            pageLimit={1}
                            pageNeighbours={1}
                            onPageChanged={e => handlePagination(e)}
                            perPage={pagination.per_page}
                            onChangePerPage={handleChangePerPage}
                        />
                    </>
                )}
            </div>
        </PageWrapper>
    );
}

export default Salesforce;
