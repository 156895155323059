import { useEffect, useState, useRef, useCallback } from "react";
// @ts-ignore
import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm';
import { AppointmentScheduleType, AppointmentType, CalendarDateType } from "./types";
import { StyledNextArrow, StyledPreviousArrow } from "./styles";


interface SlideDateProps {
  handleSelect: (appointment: AppointmentScheduleType) => void;
  appointment: AppointmentScheduleType;
  loadingDate: boolean;
  dates: CalendarDateType;
  type: AppointmentType;
  query?: string;
}

export const RescheduleSliderDates = ({
  handleSelect,
  loadingDate,
  appointment,
  dates,
  type,
  query = type,
}: SlideDateProps) => {
  const [activeSlider, setActiveSlider] = useState(0);
  const glideRef = useRef<Glide | null>(null);
  const glideId = `${type}-slider`;

  const sliderGoTo = useCallback(
    (direction: string) => {
      if (glideRef.current) {
        glideRef.current.go(direction);
        setActiveSlider(glideRef.current.index);
      }
    },
    []
  );

  useEffect(() => {
    const elementExists = document.querySelector(`#${glideId}`);
    if (elementExists) {
      const glideInstance = new Glide(`#${glideId}`, {
        type: "slider",
        rewind: false,
        bound: true,
        startAt: 0,
        perView: 1,
      });

      glideInstance.mount({ Controls });
      glideInstance.on("move.after", () => {
        setActiveSlider(glideInstance.index);
      });

      glideRef.current = glideInstance;

      return () => {
        glideInstance.destroy();
      };
    }
  }, []);

  return (
    <section>
      <div className="container" style={{ paddingRight: 0 }}>
        <div
          id={glideId}
          className={`glide slider-dates-sm ${loadingDate ? "-hidden" : ""}`}
        >
          {dates && (
            <div className="glide__arrows">
              <button
                style={{ opacity: activeSlider === 0 ? "0.25" : "1" }}
                onClick={() => sliderGoTo("<")}
                className="glide__arrow glide__arrow--prev"
              >
                <StyledPreviousArrow />
              </button>
            </div>
          )}

          <div data-glide-el="track" className="glide__track">
            <ul className="glide__slides">
              {dates.map((each, index) => (
                <div key={index} className="each-slide">
                  {each.map((data: any, indexDate: number) => (
                    <div
                      key={indexDate}
                      onClick={() =>
                        handleSelect({
                          query_date: data[query],
                          weekday: data.day,
                          appointment_id: data.appointmentID || "",
                          calendar_id: data.calendarID || "",
                          query_hour: data.hour_query || "",
                          date: data.date || "",
                          type: appointment.type,
                          hour: ""
                        })
                      }
                      className={`card card-dates-sm ${appointment.date === data.date ? "_selected" : ""} `}
                    >
                      {data.day && (
                        <div className="day">{data.day.substring(0, 3)}</div>
                      )}
                      <div className="date">
                        {data[type]}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </ul>
          </div>
          {dates &&
            <div className="glide__arrows _right">
              <button
                style={{
                  opacity: activeSlider + 1 === dates.length ? "0.25" : "1",
                }}
                onClick={() => sliderGoTo(">")}
                className="glide__arrow glide__arrow--next"
              >
                <StyledNextArrow />
              </button>
            </div>}
        </div>
        <div className="bullets slider-dates-home-collect">
          <div className="glide__bullets calendar-slider">
            {dates?.map((_, index) => (
              <button
                key={index}
                className={`glide__bullet ${activeSlider === index ? "glide__bullet--active" : ""}`}
                onClick={() => sliderGoTo(`=${index}`)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
