import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { CancelSchedule } from "Requests/ClinicalSchedule";

import { Loading } from "Utils/Loading";
import { createToast } from "Utils/toastFunc";

import AttentionIcon from "Assets/Images/attention.png";
import LoadingPurple from "Assets/Images/loading-purple.gif";

import { CancelScheduleModalProps } from "./interface";
import { Header } from "./styles";

export function CancelScheduleModal({
  modalVisible,
  appointment_id,
  modalDismiss,
  loading,
  person_id,
  personData,
  order_id,
  refreshData,
}: CancelScheduleModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const user_logged = useSelector((state: any) => state.User.userData);

  async function cancelSchedule() {
    try {
      setIsLoading(true);

      await CancelSchedule(
        appointment_id,
        "Consulta cancelada",
        user_logged,
        person_id,
        order_id
      );

      setCancelled(true);
    } catch (err) {
      createToast("error", "Esse agendamento não pode ser alterado.");
    } finally {
      setIsLoading(false);
    }
  }

  function cancelAttendance() {
    modalDismiss();
    return refreshData(order_id);
  }
  
  function handleDismissModal(submitted?: boolean) {
    modalDismiss(!!submitted);
    setCancelled(false);
  }

  return (
    <Modal
      isOpen={!!modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={() => handleDismissModal(cancelled)}
    >
      {loading ? (
        <div style={{ marginTop: "1rem" }}>
          <Loading classCss="loading-centered" sizeImg={undefined} />
        </div>
      ) : (
        <>
          <Header>
            <img src={AttentionIcon} alt="Atenção" width={105} />
            <h3>
              {cancelled ? "Consulta cancelada com sucesso!" : "Atenção!"}
            </h3>

            {cancelled ? (
              <p>
                A consulta foi cancelada com sucesso, agora é só orientar o
                cliente os próximos passos.
              </p>
            ) : (
              <p>
                Você está prestes a <strong>cancelar a consulta.</strong>{" "}
                Certifique-se que o cliente está ciente dessa informação antes
                de prosseguir com a ação.
              </p>
            )}

            {cancelled ? (
              <div>
                <button className="button" onClick={cancelAttendance}>
                  Encerrar atendimento
                </button>
              </div>
            ) : (
              <div>
                <button className="button danger" onClick={() => handleDismissModal()}>
                  Não, desfazer ação
                </button>
                <button
                  className={`button ${isLoading && "button-loading _white"}`}
                  onClick={cancelSchedule}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src={LoadingPurple} alt="" className="gif" />
                  ) : (
                    "Sim, cancelar a consulta"
                  )}
                </button>
              </div>
            )}
          </Header>
        </>
      )}
    </Modal>
  );
}
