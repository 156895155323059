import React from "react";

import { DependentContinueInterface } from "Pages/RegistrationCleaning/interface";
import { TextModal } from "Pages/RegistrationCleaning/styles";

export const SubmitModal = ({ handleModal }: DependentContinueInterface) => {
  return (
    <>
      <TextModal fontWeight={400}>
        O cadastro foi ajustado e as informações foram atualizadas com sucesso.
        Agora você pode prosseguir com o atendimento normalmente!
      </TextModal>
      <div
        className="flex-buttons-modal-information"
        style={{ marginTop: 30, flexDirection: "column", gap: 32 }}
      >
        <div className="container-button-information">
          <button className="button _action" onClick={() => handleModal()}>
            continuar atendimento
          </button>
        </div>
      </div>
    </>
  );
};
