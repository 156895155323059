import styled from "styled-components";

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  font-family: var(--font-text);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  text-transform: capitalize;
  color: var(--color-lgray-text);
  margin: 0;
  border-radius: 4px;

  input {
    display: none;
  }

  .checked-img {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-right: 5px;
    border-radius: 2px;
    border-radius: 3px;
  }

  input:checked + .checked-img {
    background-color: #ab92bf;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
