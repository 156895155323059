import { useQuery } from "react-query";
import { useParams } from "react-router";

import { BudgetItemProps } from "Pages/Budgets/ViewDetails/interface";
import { Box } from "styles/globalComponents";

import { GetBudgetByID } from "Requests/Budget";

import { LoadingText } from "Components/LoadingText";

import { BudgetActions } from "./BudgetActions";
import { BudgetDetails } from "./BudgetDetails";

export const DetailedBudgetItem = () => {
  const budgetId = useParams<{ id: string }>();

  const budgetSelectedId = budgetId.id;

  const { isError, isLoading, data } = useQuery<BudgetItemProps, string>(
    ["budget-item", budgetSelectedId],
    () => GetBudgetByID(budgetSelectedId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <Box mt="100">
        <LoadingText text="Estamos buscando os dados do orçamento selecionado. Aguarde! " />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box mt="100" display="flex" justify="center" align="flex-start">
        <h3 className="text-purple">Não foi possível visualizar o orçamento</h3>
      </Box>
    );
  }

  return (
    <Box>
      {data && (
        <>
          <BudgetDetails data={data} />
          <BudgetActions data={data} />
        </>
      )}
    </Box>
  );
};
