import styled, { keyframes } from "styled-components";

export interface ContainerProps {
  size: number;
  hoverColor: string;
  color: string;
}

export interface LoaderProps {
  size: number;
  width: number;
}

const RotationAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  min-height: ${(props) => props.size}px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  *:after {
    border-color: ${(props) => props.color} transparent !important;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    *:after {
      border-color: ${(props) => props.hoverColor || props.color} transparent !important;
    }
  }
`;

export const Loader = styled.div<LoaderProps>`
  border: 3px solid transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: ${RotationAnimation} 1s linear infinite;
  transition: all 0.3s ease-in-out;
  border-color: transparent;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(${(props) => props.size}px);
    height: calc(${(props) => props.size}px);
    border-radius: 50%;
    border: ${props => props.width}px solid;
    transition: all 0.3s ease-in-out;
  }
`;
