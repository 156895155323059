import React from "react";

import { Loader } from "Components/Loader";

import { LoadingTextProps } from "./interface";
import * as S from "./style";

export const LoadingText = ({
  text,
  flex = false,
  imgSize = 30,
  mgBottom = 50,
}: LoadingTextProps) => {
  return (
    <S.LoadingContainer flex={flex} mgBottom={mgBottom}>
      <p>{text}</p>
      <Loader deactivateHover size={imgSize} />
    </S.LoadingContainer>
  );
};
