import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLogin = () => (
    <header className="app-header app-header-auth app-header-logo-only app-header-logo-center">
        <div className="container">
            <h1 className="logo _yellow">
                <Link to="#">Labi Saúde - Melhores preços em exames de qualidade</Link>
            </h1>
        </div>
    </header>
);

export default HeaderLogin;