import { PatientType } from "../interface";

export const mountSkusProducts = (data: PatientType[]) => {

  let skus = ''

  const concatenatedCodes = data.map(patient => {
    const codes = patient.exams.map(exam => exam.product.sku).join(",");
    return codes;
  });

  skus = concatenatedCodes
    .join(',');

  return skus
}