import { CSSProperties } from "react";
import { Link, useHistory } from "react-router-dom";

import { OrderType, useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { Box } from "styles/globalComponents";
import { useShallow } from "zustand/react/shallow";

import { useBudgetCartModals } from "Hooks/useBudgetCartModals";
import { useBudgetContext } from "Hooks/useBudgetContext";

import { CopyToClipboard } from "Utils/Copy";

import { Button } from "Components/Button";
import { SendEmailButton } from "Components/Form/SendEmailButton";
import { Modal } from "Components/Modals/Modal";

import Success from "Assets/Images/successIcon.svg";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { mountAttendances, mountCartItems } from "./utils";


interface SuccessModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const SuccessModal = ({
  isOpen,
  closeModal,
}: SuccessModalProps) => {
  const { budget } = useBudgetContext();


  const { handlePdfDownload, sendEmailBudget, loadingPdf } =
    useBudgetCartModals();

  const [setCurrentModal, setOrder] = useBudgetOrder(useShallow((state) => [
    state.setCurrentModal,
    state.setOrder,
  ]));

  const { setOrder: setOrderStore } = useBudgetOrderLocalStorage()

  const covenantLink = budget?.is_covenant ? "convenio" : "particular";

  const history = useHistory();

  const copyBudgetLink = () => {
    const urlOrigin = window.location.origin;
    const url = `${urlOrigin}/orcamento/${budget?.id}`;
    CopyToClipboard({ message: "Copiado!", text: url });
  };

  const paragraphStyle: CSSProperties = {
    fontWeight: 400,
    fontSize: '1em',
    lineHeight: '1.6em',
    textTransform: 'uppercase',
    color: "#333333",
    textAlign: 'center'
  }

  const verifyPatientInfo = () => {
    if (budget) {

      const isLead = budget?.lead;

      if (isLead) {

        const leadOrderObject: OrderType = {
          budget_id: budget ? budget.id : 0,
          budget_type: budget.budget_type,
          appointment_type_id: "",
          isTaxInserted: false,
          payer_patient: {
            id: 0,
            birthdate: '',
            document: {
              id: 0,
              number: '',
              type: '',
              country_id: 0,
            },
            email: '',
            gender_id: 0,
            mother_name: '',
            name: '',
            phone: {
              id: 0,
              ddd: 0,
              ddi: 0,
              phone: 0
            },
            unborn: false,
            social_name: ''
          },
          lead: {
            id: budget.lead.id,
            ddd: budget.lead.ddd,
            email: budget.lead.email,
            name: budget.lead.name,
            phone: `+55${budget.lead.ddd}${budget.lead.phone}`
          },
          attendances: [],
          itemsQuantity: 0,
          totalPrice: budget.home_collect_value != 0 && budget.home_collect_value && !Number.isNaN(budget.home_collect_value) ? String(replaceStringNumber(Number(budget.total) - Number(budget.home_collect_value))) : budget.totalDisplay,
          homeCollectValue: 0,
          isCovenant: false,
          discounts: 0,
          subtotal: 0,
          deliveryTime: '',
          items: [],
          product_categories: [],
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 1,
            default: true,
            reference_point: '0',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: budget.coupon_code,
          home_collected: budget.home_collected,
          home_collect_value: '',
          coupon_percentage_discount: budget.coupon_percentage_discount,
          orderData: {
            order_type: 1,
            channel_id: 4,
            commercial_category: 63,
            appointment_type_id: "",
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              cupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        }

        setCurrentModal("incompleteRegister");
        setOrder(leadOrderObject);
        setOrderStore(leadOrderObject)
      }

      if (!isLead) {
        const formattedAttendances = mountAttendances(budget.cart ?? []);

        const mountedItems = mountCartItems(budget.cart, budget)

        const patientOrderObject: OrderType = {
          budget_id: budget ? budget.id : 0,
          appointment_type_id: "",
          budget_type: budget ? budget.budget_type : 0,
          lead: {
            id: 0,
            ddd: null,
            phone: '',
            email: null,
            name: '',
          },
          isTaxInserted: false,
          payer_patient: {
            id: budget.patient ? budget?.patient.id : 0,
            name: budget.patient ? budget.patient.name : '',
            birthdate: budget.patient ? budget?.patient.birthdate : '',
            document: {
              id: 0,
              type: budget.patient ? budget.patient.document.type : '',
              number: budget.patient ? budget.patient.document.number : '',
              country_id: 0,
            },
            email: budget.patient ? budget.patient.email : '',
            gender_id: budget.patient ? budget.patient.gender_id : 0,
            mother_name: budget.patient ? budget.patient.mother_name : '',
            phone: {
              id: budget.patient ? budget.patient.phone.id : 0,
              ddd: budget.patient ? budget.patient.phone.ddd : 0,
              ddi: budget.patient ? budget.patient.phone.ddi : 0,
              phone: budget.patient ? budget.patient.phone.phone : 0,
            },
            unborn: budget.patient ? budget.patient.unborn : false,
            social_name: budget.patient?.social_name ?? "",
          },
          homeCollectValue: 0,
          attendances: formattedAttendances,
          itemsQuantity: budget.cart.length ?? 0,
          totalPrice: budget.home_collect_value != 0 && budget.home_collect_value && !Number.isNaN(budget.home_collect_value) ? String(replaceStringNumber(Number(budget.total) - Number(budget.home_collect_value))) : budget.totalDisplay,
          isCovenant: budget.is_covenant,
          discounts: budget.discounts,
          subtotal: budget.subtotal,
          deliveryTime: budget.result_eta,
          items: mountedItems,
          product_categories: budget.product_categories,
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 1,
            default: true,
            reference_point: '0',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: budget.coupon_code,
          home_collected: budget.home_collected,
          home_collect_value: '',
          coupon_percentage_discount: budget.coupon_percentage_discount,
          orderData: {
            order_type: 1,
            channel_id: 4,
            commercial_category: 63,
            appointment_type_id: "",
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              cupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        }

        setOrder(patientOrderObject);
        setOrderStore(patientOrderObject)
        history.push('/pedidos/criar-pedido/coleta')
      }

      closeModal()
    }
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      renderHeading={() => (
        <h2>
          {" "}
          Seu orçamento está <strong>salvo!</strong>
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          Agora é só compartilhar o orçamento com o cliente por uma das opções
          abaixo:
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">
          {!budget?.is_covenant && (
            <Button
              onClick={() =>
                CopyToClipboard({
                  message: "Copiado!",
                  text: budget?.site_link || "",
                })
              }
              width="full"
              minHeight="3.7em"
              fontWeight="600"
            >
              COPIAR LINK PAGÁVEL DO SITE
            </Button>
          )}
          <Button
            onClick={() => handlePdfDownload(budget?.id)}
            width="full"
            outlined
            loading={loadingPdf}
            disabled={loadingPdf}
            minHeight="3.7em"
            fontWeight="600"
          >
            Baixar como .PDF
          </Button>
          <Button width="full" outlined onClick={copyBudgetLink} minHeight="3.7em" fontWeight="600">
            {" "}
            Copiar código do orçamento
          </Button>
          <SendEmailButton
            placeholder="Email do paciente"
            value={budget?.patient?.email || budget?.lead?.email || ""}
            label="Enviar por e-mail"
            submit={(budgetEmail) => sendEmailBudget(budgetEmail, budget?.id)}
            minHeight="3em"
          />

          <p style={{ ...paragraphStyle, marginTop: '1em' }}>ou</p>
          <p style={{ ...paragraphStyle, textTransform: 'none', marginTop: '-1em' }}>O cliente quer já aprovou o orçamento e quer seguir com o pedido? Utilize as opções abaixo:</p>


          {!budget?.is_covenant ? <Button width="full" outlined onClick={() => verifyPatientInfo()} minHeight="3.7em" fontWeight="600">
            {" "}
            Converter em Pedido
          </Button>
            : <></>}

          <Button width="full" textButton fontWeight="600">
            <Link to={`/orcamentos/${covenantLink}`}>ver todos orçamentos</Link>
          </Button>
        </Box>
      )}
    />
  );
};
