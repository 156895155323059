import styled from "styled-components";

export const PatientsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Patient = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const PatientItems = styled.div`
  margin-left: 2rem;

  dd,
  dt {
    margin: 0;
    padding: 0;
  }

  dt {
    font-family: var(--font-text);
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;

    color: var(--color-bold-gray);
  }

  dd {
    font-family: var(--font-display);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.375rem;

    color: var(--color-bold-gray);

    mix-blend-mode: normal;
  }
`;
