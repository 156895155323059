import styled from "styled-components";

import { TitleModalStyleProps } from "./interface";

export const TitleDocument = styled.h3`
  font-family: "Saira", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #572580;
  margin-top: 100px;
`;

export const TitleSectionReview = styled.h3`
  font-family: "Saira", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #572580;
  margin-top: 100px;
  border-bottom: 1px solid #d8d8d8;

  display: flex;
  justify-content: space-between;

  a,
  a:visited,
  a:hover {
    color: #572580;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }
`;

export const TextModal = styled.p<TitleModalStyleProps>`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 16px;
  line-height: 26px;
  color: #666666;
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const ClientViewContainer = styled.div`
  background-color: #f5f5f5;
  max-width: 351px;
  width: 100%;
  border-radius: 20px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 32px auto 0 auto;
  text-align: center;
  align-items: center;

  span {
    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #666666;
    text-transform: uppercase;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    color: #666666;
  }

  img {
    height: 15px;
    width: 15px;
    margin: 0 auto;
  }
`;

export const TableSelectPerson = styled.table`
  border-spacing: 0;
  width: 100%;

  thead {
    th {
      text-align: start;
      padding: 24px 0;

      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #333;
    }
  }

  tbody {
    tr td {
      > div {
        padding: 34px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        height: 38px;
        display: flex;
        align-items: center;
      }
      &:first-child {
        > div {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          border-left: 1px solid #e5e5e5;
          padding: 34px 15px;
        }
      }

      &:last-child {
        > div {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          border-right: 1px solid #e5e5e5;
        }
      }

      padding: 0 0 15px;
    }
  }
`;
export const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 18px !important;
  border: 1px solid #572580;
  border-radius: 111px !important;
  background-color: transparent;
  cursor: pointer;
  width: max-content;
  min-width: 100px;

  color: #572580;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Saira", sans-serif;
  font-style: normal;
  font-weight: 500;

  &.filled {
    background-color: #572580;
    color: #fff;
  }
`;
export const TableHistory = styled.table`
  border-spacing: 0;
  width: 100%;

  thead {
    th {
      width: 50%;
      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      padding: 16px 0;
      color: #333;

      &:first-child {
        text-align: start;
      }

      &:last-child {
        text-align: end;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background: #f5f5f5;
      }

      &:nth-child(even) {
        background: #fff;
      }
    }

    td {
      width: 50%;
      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #666666;
      padding: 4px 8px;

      &:first-child {
        text-align: start;
      }

      &:last-child {
        text-align: end;
      }
    }
  }
`;
export const ContainerRegisterForm = styled.section`
  width: 100%;
  margin: 0 auto;
  margin-top: 32px;

  .field-holder,
  select-field-holder {
    width: 100%;
    .error-message {
      bottom: initial;
      &.error-without-label {
        bottom: -15px;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 296px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const LeftPaddingContainer = styled.div`
  padding-left: 100px;
  width: calc(100% - 100px);
  margin-top: 3rem;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .duplicated-register-list {
      & + .duplicated-register-list {
        margin-top: 5rem;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 720px;
      flex-wrap: wrap;
      gap: 2rem;

      .column {
        display: flex;
        flex-direction: column;
        gap: 32px;
        h5 {
          text-transform: uppercase;
          font-family: "Saira", sans-serif;
          font-weight: 500;
          font-size: 16px;

          margin: 0;
          color: #572580;
        }

        flex: 1;
        max-width: 300px;
        min-width: 300px;
      }
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 30px;
  }

  @media (max-width: 1024px) {
    padding-left: 0;
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 76px;
  border-top: 1px solid #e5e5e5;
  margin: 18px;
  gap: 10px;

  button {
    border-radius: 111px;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px;
  margin-bottom: 11px;
  width: 100%;

  .field-holder {
    width: 40%;
  }
`;
