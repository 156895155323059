import React from "react";

import { validateArrayType } from "Utils/Arrays";

import { ListItem } from "../ListItem";
import { ProductsListProps } from "./interface";
import * as S from "./styles";

export const ListProductsItems = ({
  productsList,
  selectIdItem,
}: ProductsListProps) => {
  return (
    <S.ListProductsContainer className="page-content ">
      {validateArrayType(productsList) ? (
        <table className="table-content small-separate details">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Código</th>
              <th>Categoria</th>
              <th>Subcategoria</th>
              <th>Preço</th>
              <th>Status</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {productsList.map((product) => (
              <ListItem
                key={product.id}
                product={product}
                selectId={selectIdItem}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center text-purple product-not-found">
          Oops! Parece que nada foi encontrado...
        </div>
      )}
    </S.ListProductsContainer>
  );
};
