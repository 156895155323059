import axios from "axios";

import { getToken } from "Services/Authentication";

export async function getCognitoToken() {
  try {
    const authToken = getToken();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cognito`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.log(error);
  }
}
