export const TableHeader = () => {
  const columns = [
    {
      title: "Orçamento",
      key: "budgetId",
    },
    {
      title: "Data",
      key: "dataAg",
    },
    {
      title: "Cliente",
      key: "client",
    },
    {
      title: "Documento",
      key: "document",
    },
    {
      title: "Convênio",
      key: "covenant",
    },
    {
      title: "Total (R$)",
      key: "total",
    },
    {
      title: "Canal",
      key: "channel",
    },
    {
      title: "Status",
      key: "status",
    },
    {
      title: "Ações",
      key: "actions",
      className: "text-center",
    },
  ];

  return (
    <thead>
      <tr>
        {columns.map((columnsItem) => (
          <th
            key={columnsItem.key}
            className={columnsItem.className}
            style={{ color: "#333" }}
          >
            {columnsItem.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};
