import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "Services/Authentication";

import { createToast } from "Utils/toastFunc";
import validatePermissions from "Utils/validatePermissions";

const PrivateRoute = ({
  component: Component,
  permissions,
  layout: Layout,
  ...rest
}) => {
  const user_permissions = useSelector((state) => state.User.permissions);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated())
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        if (!validatePermissions(permissions, user_permissions)) {
          createToast(
            "error",
            "Você não tem permissão para acessar este recurso."
          );
          return <Redirect to="/*" />;
        }

        return (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;
