import { useState } from "react";
import { useQuery } from "react-query";

import { getProduct } from "Requests/Products";

import { validateObjectType } from "Utils/Objects";
import { createToast } from "Utils/toastFunc";

import { ErrorProps, ListProduct, UseProduct } from "./interface";

export const useProduct = (idSelected: number | null): UseProduct => {
  const [productId, setProductId] = useState<number | null>(idSelected);

  const { data, isLoading, isError, error } = useQuery<ListProduct, ErrorProps>(
    ["product", productId],
    () => getProduct(productId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!productId,
      onError: ({ message }) => createToast("error", message),
    }
  );

  const setNewProductId = (newProductId: number) => {
    setProductId(newProductId);
  };

  return {
    product: validateObjectType(data) ? data : undefined,
    loadingProduct: isLoading,
    productError: isError ? error.message : null,
    setNewProductId,
  };
};
