import React from 'react'

import {TextEllipsis} from 'Utils/Uploads'

export default function BoxScheduleInfo({type, color, info}) {
    const {firstName, lastName, time, endTime, appointmentType} = info
    const formatterType = appointmentType.name.indexOf("(Manhã)") !== -1 ? appointmentType.name.replace("(Manhã)", "") : appointmentType.name.replace("(Tarde)", "")
    const formatterDay = formatterType.indexOf("(manhã)") !== -1 ? formatterType.replace("(manhã)", "") : formatterType.replace("(tarde)", "")
    const _split = formatterDay.split(":");
    
    return (
        <div className={`${type === "week" ? "schedule-week-box-info" : "schedule-box-info "} ${color ? "color" : ""}`} style={{backgroundColor: appointmentType.color}}>
            <div>
                <h4>{ TextEllipsis(`${firstName} ${lastName}`, 25) }</h4>
                <p>{formatterDay.indexOf(":") !== -1 ? _split[1] : formatterDay}</p>
                <p>{time} - {endTime}</p>
            </div>
        </div>
    )
}
