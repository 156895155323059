import styled from "styled-components";

export const SelectTitle = styled.h2`
  color: var(--color-purple);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
`;

export const ContainerInput = styled.div`
  max-width: 328px;
`;

export const Container = styled.div`
  &.gray-background {
    background: #f5f5f5;
  }
`;
export const TitleUnit = styled.p`
  margin-top: 40px;
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  color: #333333;
`;

export const SubTitleUnit = styled.p`
  font-size: 13px;
  font-weight: 300;
  line-height: 21px;
  color: #333333;
  span {
    font-weight: 600;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ColumnItem = styled.div`
  width: calc(50% - 10px);
`;
