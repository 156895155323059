import React from "react";

import { CATEGORIES } from "Constants/Categories";

import { hasPathInArray } from "Utils/Arrays";
import { formatBRLCurrency } from "Utils/FormatCurrency";

import { AccordionCollapse } from "Components/AccordionCollapse";

import { ProductProps } from "../interface";
import * as S from "../styles";
import { Container } from "./styles";

export const ProductIdentification = ({ product }: ProductProps) => {
  const {
    product: prodName,
    slug,
    product_description,
    category,
    sku,
    mt_code,
    rule,
    channels,
    genders,
    partners,
  } = product ?? {};

  const { synonym } = product_description || {};
  const {
    max_items_cart,
    isolated_sale,
    only_central,
    free_visit,
    commercial_code,
  } = rule || {};

  const showDeadLine = (time: number, deadline: string) => {
    if (time === 0) {
      return "Resultado na hora";
    }

    return `${time} ${deadline}`;
  };

  return (
    <AccordionCollapse title="Identificação" cssClass="identification">
      <Container>
        <div className="identification-column-1">
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Nome do produto
            </span>
            <S.RowContent role="row" className="row-content">
              {prodName}
            </S.RowContent>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Slug
            </span>
            <S.RowContent role="row" className="row-content">
              {slug || "Não informado"}
            </S.RowContent>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Sinonímia
            </span>
            <S.RowContent role="row" className="row-content" maxWidth="220">
              {synonym || "Não informado"}
            </S.RowContent>
          </div>

          <S.RowContent>
            <table className="partner-holder">
              <thead>
                <tr>
                  <th />
                  <th>Prazo</th>
                  <th>Preço</th>
                </tr>
              </thead>
              <tbody>
                {partners?.map((partner) => (
                  <tr key={partner.id}>
                    <td className="partner">{partner.partner}</td>
                    <td>
                      {partner.time || partner.deadline
                        ? showDeadLine(partner.time, partner.deadline)
                        : "Não informado"}
                    </td>
                    <td>{formatBRLCurrency(partner.price, true)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </S.RowContent>
        </div>

        <div className="identification-column-2">
          {hasPathInArray([CATEGORIES.vaccine], category?.id) &&
            commercial_code && (
              <div>
                <span role="heading" className="heading" aria-level={5}>
                  Cliente Comercial Motion
                </span>
                <S.RowContent role="row" className="row-content">
                  {commercial_code}
                </S.RowContent>
              </div>
            )}

          <div>
            <span role="heading" className="heading" aria-level={5}>
              Código site/Colabi (SKU)
            </span>
            <S.RowContent role="row" className="row-content">
              {sku}
            </S.RowContent>
          </div>

          <div>
            <span role="heading" className="heading" aria-level={5}>
              Código Motion
            </span>
            <S.RowContent role="row" className="row-content">
              {mt_code || "Não informado"}
            </S.RowContent>
          </div>

          <div>
            <span role="heading" className="heading" aria-level={5}>
              Quantidade de venda
            </span>
            <S.RowContent role="row" className="row-content">
              {max_items_cart}
            </S.RowContent>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Venda isolada
            </span>
            <S.RowContent role="row" className="row-content">
              {isolated_sale ? "Sim" : "Não"}
            </S.RowContent>
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Venda apenas via central
            </span>
            <S.RowContent role="row" className="row-content">
              {only_central ? "Sim" : "Não"}
            </S.RowContent>
          </div>

          <div>
            <span role="heading" className="heading" aria-level={5}>
              Taxa de domiciliar gratuíta
            </span>
            <S.RowContent role="row" className="row-content">
              {free_visit ? "Sim" : "Não"}
            </S.RowContent>
          </div>
        </div>
        <div className="identification-column-3">
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Canais
            </span>

            {channels?.map((channel) => (
              <S.RowContent
                key={channel.id}
                role="row"
                className="row-content"
                mb="4"
              >
                {channel.name}
              </S.RowContent>
            ))}
          </div>
          <div>
            <span role="heading" className="heading" aria-level={5}>
              Gênero permitido
            </span>

            {genders?.map((gender) => (
              <S.RowContent
                key={gender.id}
                role="row"
                className="row-content"
                mb="4"
              >
                {gender.gender}
              </S.RowContent>
            ))}
          </div>
        </div>
      </Container>
    </AccordionCollapse>
  );
};
