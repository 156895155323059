import { Modal } from "Components/Modals/Modal";
import { Box } from "styles/globalComponents";
import { ReactComponent as CheckIcon } from "Assets/Images/check-small-purple.svg";
import { Button } from "Components/Button";
import { useBudgetOrderLocalStorage } from "Stores/budget-order";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ArraysType, SelectProductModalProps } from "./types";
import { SelectedPatientProducts } from "../CardSelection/types";

export const SelectProductModal = ({
  isOpen,
  closeModal,
  patients,
  index,
  items
}: SelectProductModalProps) => {

  const { order, setOrder } = useBudgetOrderLocalStorage();

  useEffect(() => {
    setArrays((arrays) => ({
      ...arrays,
      patientsWithProducts: order.patientWithProducts
    }))
  }, [])

  const [arrays, setArrays] = useState<ArraysType>({
    patientsWithProducts: []
  })

  const renderPatientName = () => {
    if (patients[index].name === '') return `Paciente ${index + 1}`
    return patients[index].name
  }

  const handleClickProduct = (id: number, name: string, category: string) => {
    const clonedPatientsWithproducts = [...arrays.patientsWithProducts];

    const isLinkedToAnotherPatient = clonedPatientsWithproducts.some(
      (i) => i.patient_id !== patients[index].index && i.products.some((p) => p.id === id)
    );
  
    if (isLinkedToAnotherPatient) {
      return 
    }

    const existingLinkIndex = clonedPatientsWithproducts.findIndex(
      (i) => i.patient_id === patients[index].index
    );

    if (existingLinkIndex >= 0) {
      const existingProducts = clonedPatientsWithproducts[existingLinkIndex].products;
      const productIndex = existingProducts.findIndex((p) => p.id === id);

      // Se o produto já estiver vinculado, remover
      if (productIndex >= 0) {
        clonedPatientsWithproducts[existingLinkIndex] = {
          ...clonedPatientsWithproducts[existingLinkIndex],
          products: existingProducts.filter((p) => p.id !== id),
        };
      } else {
        // Se não, adicionar
        if (!canLinkMoreProducts(patients[index].index, clonedPatientsWithproducts)) {
          return toast.error('Máximo de produtos vinculados a este paciente');
        }

        clonedPatientsWithproducts[existingLinkIndex] = {
          ...clonedPatientsWithproducts[existingLinkIndex],
          products: [...existingProducts, { id, name, category }],
        };
      }
    } else {
      // Se o paciente não tiver nenhum produto vinculado, criar nova entrada
      clonedPatientsWithproducts.push({
        patient_id: patients[index].index,
        products: [{ id, name, category }],
      });
    }

    setArrays((arrays) => ({
      ...arrays,
      patientsWithProducts: clonedPatientsWithproducts,
    }));
  };

  const canLinkMoreProducts = (
    patient_id: number,
    clonedPatientsWithproducts: SelectedPatientProducts[]
  ) => {
    // Encontra o paciente especificado
    const patient = clonedPatientsWithproducts.find(p => p.patient_id === patient_id);

    // Total de produtos e de pacientes
    const totalProducts = items.length;
    const totalPatients = patients.length;

    // Produtos já vinculados a todos os pacientes
    const totalLinkedProducts = clonedPatientsWithproducts.reduce((sum, p) => sum + p.products.length, 0);

    // Quantidade mínima de produtos que precisam ser reservados para garantir que cada paciente tenha pelo menos um produto
    const patientsWithoutProducts = totalPatients - clonedPatientsWithproducts.length;
    const remainingProducts = totalProducts - totalLinkedProducts;

    if (patient) {
      // Verifica se ainda existem produtos suficientes para garantir que pacientes sem vínculo ainda possam receber um produto
      return remainingProducts > patientsWithoutProducts;
    }

    // Se o paciente ainda não tem produto vinculado, permite o vínculo.
    return true;
  };

  const linkPatientWithProduct = () => {
    setOrder({
      ...order,
      patientWithProducts: arrays.patientsWithProducts
    })
    closeModal();
  }

  const handleClose = () => {
    closeModal()
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon=""
      renderHeading={() => (
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <h4>
            Quais produtos o {renderPatientName()} vai receber em seu atendimento?
          </h4>
        </div>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-2em' }}>
          Para prosseguir com a conversão deste orçamento em pedido é necessário completar esse cadastro.
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column" >
          {items.map((item) => {
            const isSelected = arrays.patientsWithProducts.some((i) => i.products.find((a) => item.product_id === a.id))

            return (
              <div
                key={item.product_id}
                style={{
                  position: 'relative',
                  border: '2px solid var(--color-purple)',
                  borderRadius: '3.125em',
                  padding: '10px 20px',
                  fontFamily: 'var(--font-display)',
                  fontSize: '0.875rem',
                  lineHeight: '1.125rem',
                  color: 'var(--color-purple)',
                  textTransform: 'uppercase',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.2rem',
                  opacity: isSelected ? '0.5' : '1',
                  cursor: 'pointer',
                  width: '18em'
                }}
                onClick={() => handleClickProduct(item.product_id, item.product_name, item.category)}
              >
                {item.product_name}
                <div style={{
                  display: isSelected ? 'block' : 'none',
                  position: 'absolute',
                  right: '20px'
                }}>
                  <CheckIcon className="check-icon" />
                </div>
              </div>
            )
          })}

          <Button width="full" minHeight="3.7em" fontWeight="600" onClick={() => linkPatientWithProduct()}>
            Salvar
          </Button>
          <Button width="full" outlined onClick={handleClose} minHeight="3.7em" fontWeight="600">
            Cancelar
          </Button>
        </Box>
      )}
    />
  )

}