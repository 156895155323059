/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import API from "Services/Api";

import { handleSuccessLogout } from "Actions/Authentication";
import { handleToggleMenu } from "Actions/Menu";

import ConfirmAlert from "Components/Alert/ConfirmAlert";



class Topbar extends Component {
  _logoutAlertLayout = (_, onClose, onSubmit) => (
    <div className="custom-ui">
      <h5 className="h5 text-gray text-light">
        Você tem certeza que deseja sair da aplicação?
      </h5>
      <div className="wrapper-buttons">
        <button className="link link-danger" onClick={onClose}>
          Cancelar
        </button>
        <button
          className="button _action"
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Sim
        </button>
      </div>
    </div>
  );

  handleLogout = async () => {
    await API.get("/admin/user/logout")
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    this.props.handleSuccessLogout();

    this.props.history.push("/login");
  };

  render() {
    const { handleLogout, _logoutAlertLayout } = this;

    return (
      <header className="nav-topbar">
        <div className="navbar-container">
          <div className="top-menu-hamburger">
            <GiHamburgerMenu
              onClick={() => {
                this.props.handleToggleMenu();
              }}
            />
          </div>
          <div className="navbar-logo">
            <h1 className="logo _yellow">
              <Link to="/">CRM Labi Saúde</Link>
            </h1>
          </div>
          <div className="navbar-content">
            <div className="menu-right">
              <button
                onClick={() => {
                  ConfirmAlert(null, _logoutAlertLayout, handleLogout);
                }}
                className="link link-topbar"
              >
                Sair
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapDispatchToProps = {
  handleSuccessLogout,
  handleToggleMenu,
};

export default withRouter(connect(null, mapDispatchToProps)(Topbar));
