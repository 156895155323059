import styled from "styled-components";

export const Container = styled.div`
  .budget-collect-type {
    display: flex;
    align-items: center;
    gap: 1rem;

    article {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      div {
        img {
          width: 50px;
          height: 50px;

          &:first-child {
            margin-right: -5px;
          }
        }
      }
    }

    .cart-home-or-unit-title {
      color: var(--color-purple);
      margin-bottom: 0;

      & strong {
        font-weight: 600;
      }
    }

    .color-purple {
      color: var(--color-purple);
    }
  }
`;
