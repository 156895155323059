import styled, { css } from "styled-components";

interface DefaultStyles {
  active: any;
}

export const ContainerFile = styled.div<DefaultStyles>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;
  margin-top: 26px;
  padding: 15px 19px;
  height: 66px;
  img {
    max-width: 109px;
    max-height: 96px;
  }

  &.addAnexo {
    margin-right: 0 !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 168px;
    font-size: 13px;
    line-height: 21px;
    padding: 5px;
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid #44b878;
      background-color: #f1fff7;
    `}
`;

export const ImgCardCovenant = styled.img`
  width: 25px;
`;

export const LabelDocuments = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 16px;
`;
