import React from "react";

import { Field, useFormikContext } from "formik";
import { Box } from "styles/globalComponents";

import { useSubCategories } from "Hooks/useSubCategories";
import { Subcategory } from "Hooks/useSubCategories/interface";

import { hasPathInArray, validateArrayType } from "Utils/Arrays";

import { AccordionCollapse } from "Components/AccordionCollapse";
import { CheckboxList } from "Components/CheckboxList";
import { ErrorMessage } from "Components/ErrorMessage";
import { CustomCheckbox } from "Components/Form/CustomCheckbox";
import Select from "Components/Form/Select";
import { LoadingText } from "Components/LoadingText";

import { FormValuesProps } from "../../interface";
import { Container } from "./styles";

export const CategoryForm = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<FormValuesProps>();

  const { category_id } = values;
  const categoryId = String(category_id.id);
  const { subcategoriesError, subcategoriesList, loadingSubcategories } =
    useSubCategories(category_id.id);

  const addIdCategories = (parentUnit: Subcategory) => {
    const idCategories = [...values.subcategories!];
    idCategories.push(parentUnit.id);
    parentUnit.subcategories?.forEach((subcategory) =>
      idCategories.push(subcategory.id)
    );
    setFieldValue("subcategories", [...new Set(idCategories)]);
  };

  const removeIdCategories = (parentUnit: Subcategory) => {
    const idUnits = [...values.subcategories!];

    const parentUnitIndex = idUnits?.indexOf(parentUnit.id);

    idUnits?.splice(parentUnitIndex, 1);

    parentUnit.subcategories?.forEach((subcategory) => {
      const subcategoryIndex = idUnits?.indexOf(subcategory.id);
      idUnits?.splice(subcategoryIndex, 1);
    });

    setFieldValue("subcategories", [...new Set(idUnits)]);
  };

  const handleSubcategory = (id: number) => {
    const currentSubcategories = [...values.subcategories!];

    const subcategorySelected = subcategoriesList?.find(
      (subcategory) => subcategory.id === id
    );

    if (subcategorySelected) {
      if (currentSubcategories?.includes(subcategorySelected.id))
        return removeIdCategories(subcategorySelected);
      return addIdCategories(subcategorySelected);
    }
  };

  const handleSubsubcategory = (id: number) => {
    const currentSubcategories = [...values.subcategories!];

    const parentSubcategory = subcategoriesList?.find((subcategory) =>
      subcategory.subcategories?.find((sub) => sub.id === id)
    );

    const childIdsSubcategories = parentSubcategory?.subcategories?.map(
      (sub) => sub.id
    );

    const hasOtherSiblingIdSelected = currentSubcategories.some(
      (sub) => childIdsSubcategories?.includes(sub) && sub !== id
    );

    if (parentSubcategory) {
      if (currentSubcategories.includes(id)) {
        const subIndex = currentSubcategories.indexOf(id);
        currentSubcategories.splice(subIndex, 1);

        if (hasOtherSiblingIdSelected) {
          return setFieldValue("subcategories", [
            ...new Set(currentSubcategories),
          ]);
        }

        const parentIdIndex = currentSubcategories.indexOf(
          parentSubcategory.id
        );
        currentSubcategories.splice(parentIdIndex, 1);

        return setFieldValue("subcategories", [
          ...new Set(currentSubcategories),
        ]);
      }

      currentSubcategories.push(id);
      currentSubcategories.push(parentSubcategory?.id);
    }

    setFieldValue("subcategories", [...new Set(currentSubcategories)]);
  };

  const orderedVaccinesSubcategories = subcategoriesList?.reduce(
    (acc: Subcategory[], cur: Subcategory) => {
      if (cur.slug.includes("pacote")) {
        acc.splice(1, 0, cur);
        return acc;
      }
      if (cur.slug.includes("clube")) {
        acc.splice(0, 0, cur);
        return acc;
      }
      acc.push(cur);
      return acc;
    },
    []
  );

  const orderedSubcategories = validateArrayType(orderedVaccinesSubcategories)
    ? orderedVaccinesSubcategories
    : subcategoriesList;

  return (
    <AccordionCollapse title="Categoria do Produto">
      <Container>
        <div className="input-container">
          <Field
            name="product_category"
            id="product_category"
            label="Categoria"
            component={Select}
            readOnly
            value={category_id}
          />
        </div>

        {hasPathInArray([2, 3, 7, 8, 4, 5], categoryId) &&
          (!subcategoriesError ? (
            <div
              className={`input-container column w-100 ${
                touched.subcategories && errors.subcategories ? "invalid" : ""
              }`}
            >
              {loadingSubcategories ? (
                <LoadingText text="Carregando subcategorias" />
              ) : (
                <CheckboxList
                  error={
                    touched.subcategories && errors.subcategories
                      ? errors.subcategories
                      : ""
                  }
                  title="Subcategoria"
                >
                  <div
                    className={`checkbox-list sub-subcategories ${
                      categoryId === "2" ? "checkup" : ""
                    }`}
                  >
                    {orderedSubcategories?.map((subcategory: Subcategory) => (
                      <ul key={subcategory.id} className="parent-checkbox">
                        <li>
                          <Box mb="8">
                            <Field
                              component={CustomCheckbox}
                              name="subcategories"
                              type="checkbox"
                              label={subcategory.subcategory}
                              value={subcategory.id}
                              onChange={() => handleSubcategory(subcategory.id)}
                              checked={values.subcategories?.includes(
                                subcategory.id
                              )}
                            />
                          </Box>
                          {hasPathInArray([4, 2], categoryId) &&
                            subcategory.subcategories?.map((subsubcategory) => (
                              <ul
                                key={subsubcategory.id}
                                className="child-checkbox"
                              >
                                <li>
                                  <Box mt="8" mb="8">
                                    <Field
                                      component={CustomCheckbox}
                                      name="subcategories"
                                      type="checkbox"
                                      label={subsubcategory.subcategory}
                                      value={subsubcategory.id}
                                      onChange={() =>
                                        handleSubsubcategory(subsubcategory.id)
                                      }
                                      checked={values.subcategories?.includes(
                                        subsubcategory.id
                                      )}
                                    />
                                  </Box>
                                </li>
                              </ul>
                            ))}
                        </li>
                      </ul>
                    ))}
                  </div>
                </CheckboxList>
              )}
            </div>
          ) : (
            <ErrorMessage text="Não foi possível obter a listagem de subcategorias" />
          ))}
      </Container>
    </AccordionCollapse>
  );
};
