import React from "react";
import Skeleton from "react-loading-skeleton";

import { isTodayByFullDate } from "Utils/Date";
import { gerenerateTableDays } from "Utils/Schedules";

import { useDebounce } from "../../../../Hooks/useDebounce";
import { getAppointmentsMonth } from "../../../../Requests/Schedules";
import { BoxMonth } from "../BoxMonth";

import "react-loading-skeleton/dist/skeleton.css";

export default function MonthCalendar({
  daysWeek,
  year,
  month,
  setDateMonth,
  handleClick,
  calendarSelect,
  buttonClick,
}) {
  const [appointments, setAppointments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { names: label_week } = daysWeek;

  const fetchAppointments = useDebounce(
    async () => {
      try {
        setLoading(true);
        const weeks = gerenerateTableDays(year, month);

        const res = await getAppointmentsMonth({
          type: "month",
          calendarId: calendarSelect.id,
          count: buttonClick,
        });
        const array = Object.keys(res).map((item) => {
          if (item < 10) res[item].id = Number(item.toString().split("0")[1]);
          else res[item].id = Number(item);

          return res[item];
        });

        const _weeks = [...weeks];

        weeks.forEach((week, index_week) => {
          week.forEach((day, i) => {
            const _find = array.find((item) => item.id === day) || day;
            _weeks[index_week][i] = _find;
          });
        });

        return setAppointments(_weeks);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    1000,
    [buttonClick, calendarSelect]
  );

  React.useEffect(() => {
    setDateMonth("");
  }, []);

  React.useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  return (
    <div
      className={`schedule-calendar ${
        !appointments.length ? "schedule-center" : ""
      }`}
    >
      <table className="month" border="0">
        {!loading ? (
          appointments.length ? (
            <>
              <tr>
                {label_week.map((name) => (
                  <th key={name}>{name}</th>
                ))}
              </tr>
              {appointments.map((week) => (
                <tr>
                  {week.map((day) => (
                    <td
                      className={`box-month ${
                        isTodayByFullDate(day, month, year) ? "today" : ""
                      }`}
                      onClick={() =>
                        handleClick(typeof day === "object" ? day.id : day)
                      }
                    >
                      {day !== null && (
                        <BoxMonth
                          appointment={day}
                          calendarSelect={calendarSelect}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <div
              className={`schedule-center ${
                !loading && !!appointments ? "hidden" : ""
              }`}
            >
              <p className="text-saira text-purple text-center text-size text-normal">
                Nenhum agendamento disponível...
              </p>
            </div>
          )
        ) : (
          <div className="skeleton">
            <Skeleton height={47} width={1635} />
            <Skeleton height={90} width={1635} count={6} />
          </div>
        )}
      </table>
    </div>
  );
}
