import styled from "styled-components";

export const Header = styled.header`
  padding-left: 24px;

  h1 {
    margin-top: 14px;
    margin-bottom: -10px;
    font-size: 24px;
    color: #666666;
  }
`;

export const NavWrapper = styled.main`
  padding-left: 24px;
  margin-top: 8px;
  margin-right: 24px;

  nav {
    display: flex;

    a {
      display: block;
      background: #f5f5f5;
      padding: 4px 8px;
      color: #999999;
      border-radius: 8px 8px 0 0;
      transition: all ease 0.2s;

      &.current {
        background: #ab92bf;
        color: #ffffff;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  form {
    display: flex;
    align-items: center;
    margin-top: 14px;
    padding-left: 22px;

    .status {
      margin-left: 12px;
    }

    .type {
      margin-right: 12px;
    }

    input {
      width: 160px;
    }

    .buttonsWrapper {
      margin-left: auto;
      button:last-child {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  > div {
    border: 1px solid #ebebeb;
    border-radius: 0 12px 12px 12px;
  }
`;

export const PaginationWrapper = styled.div`
  margin: 24px;
  padding-bottom: 24px;
  justify-content: center;
  display: flex;
  align-items: center;

  & > div {
    margin: auto;
  }

  > button:last-child {
    height: 35px;

    background: #f8ed55;
    color: #572580;
    border: none;

    border-radius: 24px;

    transition: all ease 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
