import { Field, useFormikContext } from "formik";
import { Box } from "styles/globalComponents";

import { AccordionCollapse } from "Components/AccordionCollapse";
import Input from "Components/Form/Input";

import { FormValuesProps } from "../../interface";

export const Featured = () => {
  const { values, handleChange } = useFormikContext<FormValuesProps>();

  return (
    <AccordionCollapse title="Banner de Destaque">
      <Box mt="15">
        <div className="input-container ">
          <Field
            name="product_description.partner_title"
            id="product_description.partner_title"
            label="Texto de título"
            onChange={handleChange}
            value={values.product_description.partner_title}
            component={Input}
            placeholder="Descreva aqui"
          />
        </div>

        <div className="input-container ">
          <Field
            name="product_description.partner_description"
            id="product_description.partner_description"
            value={values.product_description.partner_description}
            onChange={handleChange}
            label="Texto de apoio"
            component={Input}
            multiline
            placeholder="Descreva aqui"
          />
        </div>
      </Box>
    </AccordionCollapse>
  );
};
