import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { GetClinicsBudgets, getBudgets } from "Requests/Budget";

import validatePermissions from "Utils/validatePermissions";

import { initialStateMessages } from "mocks";

import Input from "Components/Form/Input";
import InputDate from "Components/Form/InputDate";
import Pagination from "Components/Nav/Pagination";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import LoadingImage from "Assets/Images/loading-purple.gif";

const ListCLinicsBudgets = () => {
  const history = useHistory();

  const user_permissions = useSelector((state) => state.User.permissions);

  const [loading, setLoading] = useState(true);
  const [budgets, setBudgets] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filterForm, setFilterForm] = useState({
    date: {
      value: null,
    },
    search: {
      value: "",
    },
  });
  const [messages] = useState(initialStateMessages);

  function handleChange({ target: { value, name } }) {
    if (name === "search") {
      if (value === "") {
        fetchBudgets();
      }
    }

    setFilterForm({
      ...filterForm,
      [name]: {
        ...filterForm[name],
        value,
      },
    });
  }

  async function fetchBudgets() {
    setLoading(true);
    setPagination({});

    const budgetsResponse = await GetClinicsBudgets();

    setBudgets(budgetsResponse.data);

    const {
      current_page,
      count,
      per_page,
      total,
      total_pages,
      last_page_url,
      next_page_url,
      last_page,
    } = budgetsResponse;
    setPagination({
      current_page,
      count,
      per_page,
      total,
      total_pages,
      last_page_url,
      next_page_url,
      last_page,
    });

    setLoading(false);
  }

  async function fetchSearchBudgets(search, date) {
    setLoading(true);
    setPagination({});

    const budgetsResponse = await getBudgets(`?search=${search}&data=${date}`);

    setBudgets(budgetsResponse.data);

    if (budgetsResponse.pagination) setPagination(budgetsResponse.pagination);

    setLoading(false);
  }

  async function fetchPagination(e) {
    setLoading(true);

    const search = filterForm.search.value;
    const date = filterForm.date.value
      ? filterForm.date.value.format("DD-MM-YYYY")
      : null;

    let budgetsResponse;

    if (search !== "" || date !== "") {
      budgetsResponse = await getBudgets(
        `?search=${search}&data=${date || ""}&page=${e || 1}`
      );
    } else {
      budgetsResponse = await GetClinicsBudgets(e);
    }

    setBudgets(budgetsResponse.data);
    if (budgetsResponse.pagination) setPagination(budgetsResponse.pagination);

    setLoading(false);
  }

  async function handleReception(budget_id, person_id) {
    const person_type = person_id ? "person" : "lead";
    history.push(`/orcamento/pre-acolhimento/${person_type}/${budget_id}`);
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="5" className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }

  function _tableList() {
    return !budgets.length ? (
      <tr>
        <td colSpan="5" className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {budgets.map((budget) => (
          <tr key={budget.id}>
            <td>{budget.id}</td>
            <td>
              {budget.first_name ||
                budget.lead_name ||
                budget.name ||
                "Não informado"}
            </td>
            <td>
              {budget.dateDisplay ||
                (function () {
                  const data = new Date(budget.created_at);
                  return data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
                })()}
            </td>
            <td>{budget.coupon_code || "Não informado"}</td>
            <td>{budget.totalDisplay}</td>
            <td className="text-right table-action">
              {validatePermissions("Update Budgets", user_permissions) && (
                <button
                  onClick={() => handleReception(budget.id, budget.person_id)}
                  className="button _action"
                  type="button"
                >
                  <span className="details-text">Fazer check-in</span>
                </button>
              )}
              {validatePermissions("Consult Budgets", user_permissions) && (
                <Link
                  className="button _action _blue"
                  to={`/ficha-de-atendimento/resumo/${budget.id}`}
                >
                  <span className="details-text">Visualizar</span>
                </Link>
              )}
            </td>
          </tr>
        ))}
      </>
    );
  }

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome do paciente</th>
            <th>Data</th>
            <th>Cupom aplicado</th>
            <th>Total (R$)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && _showLoading()}
          {!loading && _tableList()}
        </tbody>
      </table>
    );
  }

  function _renderMessages({ success, wait, error }) {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  }

  function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    fetchPagination(e.currentPage);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (filterForm.search.value == "" && filterForm.date.value == "") {
      fetchBudgets();

      return;
    }

    const search = filterForm.search.value;
    const date = filterForm.date.value
      ? filterForm.date.value.format("DD-MM-YYYY")
      : "";

    fetchSearchBudgets(search, date);
  }

  useEffect(() => {
    fetchBudgets();
  }, []);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Orçamentos de Labi Clínicas">
              <Breadcrumb
                list={[
                  {
                    path: "/orcamentos",
                    label: "Orçamentos",
                    current: false,
                  },
                  {
                    path: "/orcamentos/",
                    label: "Todos os orçamentos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Orçamentos de Labi Clínicas",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
          <form onSubmit={handleSubmit} className="form-heading">
            <div className="row">
              <div className="sm-12 md-4 lg-2">
                <div className="search-input">
                  <Input
                    id="search"
                    type="search"
                    placeholder="Filtrar por ID do orçamento ou nome do paciente"
                    value={filterForm.search.value}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="sm-12 md-4 lg-2">
                <div className="search-input">
                  <InputDate
                    placeholder="Filtrar por data"
                    id="expiration_date"
                    onDateChange={(value) => {
                      setFilterForm({
                        ...filterForm,
                        date: {
                          ...filterForm.date,
                          value,
                        },
                      });
                    }}
                    openInModal={false}
                    value={filterForm.date.value}
                    blockPastChanges={false}
                    withClear
                  />
                </div>
              </div>
              <div className="sm-12 md-3">
                <div className="search-button">
                  <button type="submit" className="button _action">
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
          </form>
          {_renderMessages(messages)}
        </div>
        <div className="page-content page-content-list">{_renderTable()}</div>
      </div>
      <div className="pagination-wrapper">
        {pagination.total_pages && (
          <>
            <Pagination
              totalRecords={pagination.total_pages}
              pageLimit={1}
              pageNeighbours={1}
              onPageChanged={(e) => handlePagination(e)}
            />
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default ListCLinicsBudgets;
