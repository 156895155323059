import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import { Loading } from "Utils/Loading";
import { TextEllipsis } from "Utils/Uploads";
import validatePermissions from "Utils/validatePermissions";

import Checkbox from "Components/Form/Checkbox";
import Input from "Components/Form/Input";
import InputDateRange from "Components/Form/InputDateRange";
import SelectSearch from "Components/Form/SelectSearch";
import PageWrapper from "Components/Pages/Wrapper";
import { Pagination } from "Components/Pagination";
import Breadcrumb from "Components/Shared/Breadcrumb";

import {
  getOrdersHome,
  getStatusPayment,
  getUnitsUser,
  managerExportHomeOrder,
} from "../../Requests/HomeOrder";
import { MaskCPF } from "../../Utils/Masks";
import { createToast } from "../../Utils/toastFunc";
import { ModalDetailsErrors } from "./Modals/ModalDetailsErrors";
import { Header, NavWrapper, PaginationWrapper } from "./styles";

const types = [
  { id: "", name: "Tipo de busca" },
  { id: "name", name: "Nome" },
  { id: "email", name: "Email" },
  { id: "cpf", name: "CPF" },
  { id: "id_source", name: "Id de Origem" },
  { id: "id", name: "Id do Pedido" },
];

const initialDates = {
  dates: {
    startDate: null,
    endDate: null,
  },
};

const ordersType = [
  { type: false, name: "Pedido Simples" },
  { type: true, name: "Pedido Composto" },
];

export default function HomeOrders() {
  const user_permissions = useSelector((state) => state.User.permissions);

  const [typeSearch, setTypeSearch] = useState();
  const [value, setValue] = useState("");
  const [statusSearch, setStatusSearch] = useState([]);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [typesStatus, setTypesStatus] = useState([]);
  const [errors, setErrors] = useState([]); // domiciliar array de erros
  const [ordersSelect, setOrdersSelect] = useState([]);
  const [dates, setDates] = useState(initialDates);
  const [orderType, setOrderType] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  function handleDatesChange(value) {
    setDates({
      dates: value,
    });
  }

  function handleCheckBoxSelected(id, value) {
    if (id === "check-all") {
      const currentOrders = data.map((item) => item.order_id);
      if (!value)
        return setOrdersSelect((prev) =>
          prev.filter((item) => !currentOrders.includes(item))
        );
      return setOrdersSelect((prev) => [...prev, ...currentOrders]);
    }

    if (!value)
      return setOrdersSelect((prev) => prev.filter((item) => item !== id));

    setOrdersSelect((prev) => [...prev, id]);
  }

  async function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    await fetchData({
      page: e.currentPage,
      ...(value ? { [typeSearch?.id]: value } : {}),
      status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
      compound_order: orderType ? orderType.type : null,
    });

    scroller.scrollTo("ScrollableDomiciliarOrders", {
      duration: 500,
      delay: 50,
      smooth: true,
      containerId: "ScrollDomiciliar",
      offset: -350,
    });
  }

  async function fetchData(rest) {
    try {
      setLoading(true);

      const date = {
        start: dates.dates.startDate
          ? dates.dates.startDate.format("YYYY-MM-DD")
          : null,
        end: dates.dates.endDate
          ? dates.dates.endDate.format("YYYY-MM-DD")
          : null,
      };
      const res = await getOrdersHome({ ...rest, ...date });
      res.status === 200 && setData(res.data.data);

      if (res.data.meta && res.status === 200) {
        setPagination(res.data.meta.pagination);
      }

      return res;
    } catch (error) {
      createToast("error", "Ocorreu um erro ao solicitar os dados do pedido!");
    } finally {
      setLoading(false);
    }
  }

  async function fetchUnits() {
    try {
      await getUnitsUser();
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar as unidades!");
    }
  }

  async function fetchStatus() {
    try {
      const res = await getStatusPayment();
      const formatter = res.data.map((item) => ({
        id: item.id,
        name: item.status,
      }));
      setTypesStatus(formatter);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar os status!");
    }
  }

  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
  }, []);

  function _showLoading() {
    return (
      <tr>
        <td colSpan="9" className="text-center">
          <span className="loading-persons">
            <Loading />
          </span>
        </td>
      </tr>
    );
  }

  function downloadExcel(tdData) {
    const blob = new Blob([tdData], { type: "text/csv" });

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(
        blob,
        ` Planilha Domiciliar | ${new Date().toDateString()}.xlsx`
      );
    } else {
      const createEl = window.document.createElement("a");
      createEl.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      createEl.download = ` Planilha Domiciliar | ${new Date().toDateString()}.xlsx`;
      document.body.appendChild(createEl);
      createEl.click();
      document.body.removeChild(createEl);
    }
  }

  async function reloadPage(res) {
    res.status === 200 &&
      (await fetchData({
        page: pagination.current_page,
        ...(value ? { [typeSearch?.id]: value } : {}),
        status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
        compound_order: orderType ? orderType.type : null,
      }));
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      setErrors([]);

      const res = await managerExportHomeOrder({ orders: ordersSelect });

      if (ordersSelect.length <= 10) {
        downloadExcel(res.data);
        createToast("success", "Planilha gerada com sucesso!");
      } else {
        createToast("success", res.data.message);
      }

      reloadPage(res);
    } catch (error) {
      console.log(error);
      createToast("error", "Ocorreu um erro ao gerar a planilha!");
    } finally {
      setLoading(false);
    }
  }

  async function filterOrders(e) {
    e.preventDefault();
    try {
      setOrdersSelect([]);
      setPagination({});
      fetchData({
        page: pagination.current_page,
        ...(value ? { [typeSearch?.id]: value } : {}),
        status_id: statusSearch ? statusSearch.map((item) => item.id) : null,
        compound_order: orderType ? orderType.type : null,
      });
    } catch (error) {
      console.log(error);
      createToast("error", "Ocorreu um erro ao realizar o filtro.");
    }
  }

  const clearFields = () => {
    setValue("");
    setErrors([]);
    setStatusSearch(null);
    setTypeSearch(null);
    setDates(initialDates);
  };

  return (
    <PageWrapper className="entry-app-content order-home-list">
      <ModalDetailsErrors
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        errors={errors}
      />
      <div className="content-holder">
        <Header>
          <h1>Exportar Pedidos</h1>
          <Breadcrumb
            list={[
              {
                path: "/pedidos-domiciliar",
                label: "DOMICILIAR",
                current: false,
              },
              {
                path: "#",
                label: "EXPORTAR PEDIDOS",
                current: true,
              },
            ]}
          />
        </Header>

        <NavWrapper>
          <nav className="orders-tabs">
            <Link to="#" className="current">
              EXPORTAR
            </Link>
            <Link to="/pedidos/importar">IMPORTAR</Link>
          </nav>
          <div className="order-home-filter">
            <form className="filters-order" onSubmit={filterOrders}>
              <div>
                <InputDateRange
                  startDatePlaceholderText="Data inicial"
                  endDatePlaceholderText="Data final"
                  startDateId="start_date"
                  endDateId="end_date"
                  onDatesChange={(value) => handleDatesChange(value)}
                  openInModal={false}
                  dates={dates.dates}
                  blockPastChanges={false}
                  withClear
                />
              </div>
              <div className="sm-2 pl-0">
                <SelectSearch
                  id="status_id"
                  type="text"
                  value={statusSearch}
                  error=""
                  isMulti
                  isMoreSelect
                  options={typesStatus}
                  loading={!typesStatus.length}
                  readOnly={!typesStatus.length}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Status"
                  onChange={(value) => setStatusSearch(value)}
                />
              </div>
              <div className="sm-2 pl-0">
                <SelectSearch
                  id="compound_order"
                  type="text"
                  value={orderType}
                  error=""
                  isMulti
                  isMoreSelect={false}
                  options={ordersType}
                  loading={!ordersType.length}
                  readOnly={!ordersType.length}
                  valueKey="type"
                  labelKey="name"
                  placeholder="Tipo de Pedido"
                  onChange={(value) => setOrderType(value)}
                />
              </div>
              <div className="sm-2 pl-0">
                <SelectSearch
                  id="type_id"
                  type="text"
                  value={typeSearch}
                  error=""
                  isMulti
                  isMoreSelect={false}
                  options={types}
                  loading={!types.length}
                  readOnly={!types.length}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Tipo de busca"
                  onChange={(value) => {
                    if (value.id === "") setValue("");
                    setTypeSearch(value);
                  }}
                />
              </div>
              <Input
                readOnly={!typeSearch}
                mask={SelectSearch.id === "cpf" ? "999.999.999-99" : ""}
                placeholder="Buscar por nome, CPF, e e-mail ou ID do pedido"
                value={value}
                onChange={({ target }) => setValue(target.value)}
                className="textInput"
              />
              <div className="full-width">
                <button
                  className="button mr-10"
                  onClick={filterOrders}
                  type="submit"
                >
                  {" "}
                  Buscar{" "}
                </button>
                <button className="button _white mr-10" onClick={clearFields}>
                  {" "}
                  Limpar{" "}
                </button>
              </div>
            </form>
            <div
              className="page-content page-content-list"
              id="ScrollDomiciliar"
            >
              <Element name="ScrollableDomiciliarOrders" />
              <table className="table-content small-separate details">
                <thead>
                  <tr>
                    <th className="checkbox">
                      <Checkbox
                        id="check-all"
                        small
                        readOnly={loading}
                        onChange={(id, value) =>
                          handleCheckBoxSelected(id, value)
                        }
                        value={
                          !loading &&
                          !!data.length &&
                          data.every((item) =>
                            ordersSelect.includes(item.order_id)
                          )
                        }
                      />
                    </th>
                    <th>Data/Hora Coleta</th>
                    <th>Id Pedido</th>
                    <th>Id de Origem</th>
                    <th>Unidade</th>
                    <th>Canal</th>
                    <th>Paciente</th>
                    <th>CPF</th>
                    <th>Email</th>
                    <th>Status Intregação</th>
                    <th>Status Pagamento</th>
                    <th>Pedido Composto</th>
                    <th>Valor final</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    _showLoading()
                  ) : !data.length ? (
                    <tr>
                      <td colSpan="9" className="text-center text-purple">
                        Oops! Parece que nada foi encontrado...
                      </td>
                    </tr>
                  ) : (
                    data.map((item) => (
                      <tr key={item.order_id}>
                        <td className="checkbox">
                          <Checkbox
                            id={item.order_id}
                            small
                            onChange={(id, value) =>
                              handleCheckBoxSelected(id, value)
                            }
                            value={ordersSelect.some(
                              (_item) => _item === item.order_id
                            )}
                          />
                        </td>
                        <td>
                          {item.home_collect_date ?? "Nenhuma data encontrada"}{" "}
                        </td>
                        <td>{item.order_id} </td>
                        <td>{item.id_source} </td>
                        <td>
                          {item.unit_name
                            ? item.unit_name
                            : "Unidade não encontrada"}{" "}
                        </td>
                        <td>{item.order_source} </td>
                        <td>{TextEllipsis(item.pacient_name, 25)} </td>
                        <td>
                          {item.pacient_cpf ? MaskCPF(item.pacient_cpf) : ""}{" "}
                        </td>
                        <td>
                          {item.pacient_email
                            ? item.pacient_email
                            : "Email não encontrado"}{" "}
                        </td>
                        <td>{item.motion_integration_status}</td>
                        <td>{item.status}</td>
                        <td>{item.compound_order ? "Sim" : "Não"}</td>
                        <td>
                          {" "}
                          {new Intl.NumberFormat("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.total)}
                        </td>
                        <td>
                          <Link
                            to={`/ficha-de-pedido/${item.order_id}`}
                            className="button _white _action _table"
                            type="button"
                          >
                            <span className="details-text">Visualizar</span>
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </NavWrapper>
      </div>

      <PaginationWrapper>
        {pagination.total_pages && (
          <Pagination
            totalRecords={pagination?.total_pages}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e) => handlePagination(e)}
          />
        )}
        <button
          className="button"
          onClick={
            validatePermissions("Send Orders to Motion", user_permissions) &&
            handleSubmit
          }
          disabled={
            ordersSelect.length === 0 ||
            !validatePermissions("Send Orders to Motion", user_permissions)
          }
        >
          {loading ? <Loading sizeImg /> : "EXPORTAR SELEÇÃO"}
        </button>
      </PaginationWrapper>
    </PageWrapper>
  );
}
