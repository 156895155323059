import React from "react";

import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { defaultBreadcrumbList } from "./interface";

export const ContentPagesWrapper = ({
  listBreadcrumb = defaultBreadcrumbList,
  titleHeader,
  children,
  ContentWrapperHeader,
  ContentPageHeader,
  resizePaddignBottom,
}) => {
  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div
          className={`page-heading ${
            resizePaddignBottom ? "resize-heading" : ""
          }`}
        >
          <div className="wrapper-header-content">
            <PageHeader title={titleHeader}>
              <Breadcrumb list={listBreadcrumb} />
            </PageHeader>

            {ContentWrapperHeader && <ContentWrapperHeader />}
          </div>

          {ContentPageHeader && <ContentPageHeader />}
        </div>

        {children}
      </div>
    </PageWrapper>
  );
};
