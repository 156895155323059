
import { useEffect, useState } from "react";
import { SwitchInput } from "Components/Form/Switch"
import { Field, useFormikContext } from "formik";
import Input from "Components/Form/Input"
import { SelectDocumentType } from "Components/Form/SelectDocumentType";
import { SelectGenderType } from "Components/Form/SelectGender";
import { InputCpf } from "Components/Form/InputCpf";
import { InputPhone } from "Components/Form/PhoneInput";
import { SelectCountries } from "Components/Form/SelectCountries";
import { SelectFamilyRelationship } from "Components/Form/SelectFamillyRelationship";
import frontCard from "Assets/Images/front-card.png";
import moment from "moment";
import { MaskCPF } from "Utils/Masks";
import { ControlledCheckbox } from "Components/Form/ControlledCheckbox";
import { PatientOrderType, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { CustomFieldType, PatientCardProps, RuleType } from "./types"
import { Card } from "./styles"
import { DependentSelect } from "../DependentSelect";
import { UploadFileDocument } from "../UploadFileDocument";
import { DependentType } from "../DependentSelect/types";
import { formatPayer, fromToFormFields, fromToFormLabel, fromToFormMask, fromToFormPlaceholder, verifyIfHasVaccineLinked, verifyPatientAge } from "./functions";


export const PatientCard = ({
  payer_patient,
  patient,
  index,
  dependents,
  fullDependents,
  loadingDependents,
  handleChange,
  genders,
  countries,
  family_relationships,
  errors,
  hasVaccineInCart,
  patients,
  items,
  patientWithProducts,
  removePatients,
  products_rule,
}: PatientCardProps) => {

  const { setFieldValue, setValues } = useFormikContext();

  const { order, setOrder } = useBudgetOrderLocalStorage();

  const [booleans, setBooleans] = useState({
    showFileInput: false,
    fileError: false
  });

  const [objects, setObjects] = useState({
    dependent: {
      id: 0,
      full_name: '',
      isPayer: false
    }
  })

  useEffect(() => {
    if (patient.id != 0) {
      const foundDependent = dependents.find((a => a.id === patient.id && !patient.isPayer))
      if (foundDependent) {
        setObjects((objects) => ({
          ...objects,
          dependent: foundDependent
        }));
      }
    }
  }, [dependents])

  useEffect(() => {
    if (hasVaccineInCart || verifyIfHasVaccineLinked(patientWithProducts, patients, index)) {
      const showFileInput = showFileVerification()
      const fileError = verifyPatientAge(patient)

      setBooleans((booleans) => ({
        ...booleans,
        showFileInput,
        fileError
      }))
    }
  }, [patients])

  useEffect(() => {
    if (patientWithProducts.length > 0) {
      const hasVaccineLinked = verifyIfHasVaccineLinked(patientWithProducts, patients, index);
      setBooleans((booleans) => ({
        ...booleans,
        showFileInput: hasVaccineLinked
      }))
    }
  }, [patientWithProducts]);

  const renderFieldsWhenSinglePatient = () => {
    if (products_rule) {
      const fieldsToRender: any[] = [];
      const errorFields = [];

      // if (patients.length === 1) {
      Object.keys(products_rule.showFields).forEach((field) => {
        const key = field as keyof RuleType;
        if (products_rule.showFields[key]) {
          const formattedKey: string = fromToFormFields(key);
          const formattedLabel: string = fromToFormLabel(formattedKey);
          const formattedPlaceholder: string = fromToFormPlaceholder(formattedKey)
          const mask: string = fromToFormMask(formattedKey)

          errorFields.push(formattedKey)

          fieldsToRender.push(
            <div style={{ width: '100%' }} key={`patients[${index}].${formattedKey}`}>
              <Field
                name={`patients[${index}].${formattedKey}`}
                id={`patients[${index}].${formattedKey}`}
                label={formattedLabel}
                component={Input}
                value={patient[formattedKey as keyof PatientOrderType]}
                onChange={(e: React.ChangeEvent<any>) => handleChangeCustomField(e, mask, formattedKey)}
                placeholder={formattedPlaceholder}
                error={formatError(`${formattedKey}`)}
              />
            </div>
          );
        }
      })

      if (fieldsToRender.length > 0) {
        return (
          <div style={{ width: '98%', marginTop: 10, padding: 10, gap: 5 }}>
            <div style={{ width: '100%', marginBottom: 10 }}>
              <div className="save-profile-patient-border" style={{ marginTop: 10 }} />
              <span style={{ fontSize: '16px', color: 'rgba(102, 102, 102, 1)', textTransform: 'uppercase', fontWeight: 600 }}>
                Informações Adicionais
              </span><br />
            </div>

            {/* Ajuste para quebrar os componentes automaticamente */}
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
              {fieldsToRender.map((field, index) => (
                <div
                  style={{
                    flex: '0 1 calc(33% - 10px)', // 33% de largura menos o gap
                    boxSizing: 'border-box', // Garante que padding/margins não quebrem o layout,
                  }}
                  key={index}
                >
                  {field}
                </div>
              ))}
            </div>
          </div>
        )
      }
    }
  }

  const renderFieldWhenMultiplePatients = () => {
    if (products_rule) {
      const fieldsToRender: any[] = [];
      const custom_fields: CustomFieldType[] = []
      const errorFields: string[] = [];

      const { showFields, ...restValues } = products_rule

      // neste caso, o field é o id do produto.
      for (const field in restValues) {
        custom_fields.push({
          product_id: Number(field),
          field: restValues[field]
        })
      }

      custom_fields.forEach((customField) => {
        const exists = patientWithProducts.some((patientProduct) =>
          patientProduct.products.some((product) => (product.id === customField.product_id) && patient.index === patientProduct.patient_id)
        );

        if (exists) {
          Object.keys(products_rule.showFields).forEach((field) => {
            const key = field as keyof RuleType
            if (customField.field[key]) {
              const formattedKey: string = fromToFormFields(key);
              const formattedLabel: string = fromToFormLabel(formattedKey);
              const formattedPlaceholder: string = fromToFormPlaceholder(formattedKey)
              const mask: string = fromToFormMask(formattedKey)
              errorFields.push(formattedKey)

              fieldsToRender.push(
                <div style={{ width: '100%', marginTop: '1em' }} key={`patients[${index}].${formattedKey}`}>
                  <Field
                    name={`patients[${index}].${formattedKey}`}
                    id={`patients[${index}].${formattedKey}`}
                    label={formattedLabel}
                    component={Input}
                    value={patient[formattedKey as keyof PatientOrderType]}
                    onChange={(e: React.ChangeEvent<any>) => handleChangeCustomField(e, mask, formattedKey)}
                    placeholder={formattedPlaceholder}
                    error={formatError(`${formattedKey}`)}
                  />
                </div>
              );
            }
          });
        }
      });

      if (fieldsToRender.length > 0) {
        return (
          <div style={{ width: '98%', marginTop: 10, padding: 10, gap: 5 }}>
            <div style={{ width: '100%', marginBottom: 10 }}>
              <div className="save-profile-patient-border" style={{ marginTop: 10 }} />
              <span style={{ fontSize: '16px', color: 'rgba(102, 102, 102, 1)', textTransform: 'uppercase', fontWeight: 600 }}>
                Informações Adicionais
              </span><br />
            </div>

            {/* Ajuste para quebrar os componentes automaticamente */}
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
              {fieldsToRender.map((field, index) => (
                <div
                  style={{
                    flex: '0 1 calc(33% - 10px)',

                    boxSizing: 'border-box'
                  }}
                  key={index}
                >
                  {field}
                </div>
              ))}
            </div>
          </div>
        )
      }
    }
  }

  const showFileVerification = () => {
    let result = false;
    if (patients.length === 1 && hasVaccineInCart) {
      result = true
    }

    if (patients.length > 1) {
      if (items.length === 1 && patients.length > 1 && hasVaccineInCart) {
        return true;
      }

      const hasVaccineLinked = verifyIfHasVaccineLinked(patientWithProducts, patients, index);

      if (hasVaccineLinked) return true

      setFieldValue(`patients[${index}].file`, null)
    }

    return result
  }

  const formatError = (name: string) => {
    const nameParts = name.split('.');

    const getNestedValue = (obj: any, keys: string[]) =>
      keys.reduce((acc, key) => (acc && typeof acc === 'object' ? acc[key] : undefined), obj);

    return errors.patients &&
      Array.isArray(errors.patients) &&
      typeof errors.patients[index] === 'object' &&
      !Array.isArray(errors.patients[index]) &&
      getNestedValue(errors.patients[index], nameParts) // Acessa o valor aninhado
      ? getNestedValue(errors.patients[index], nameParts)
      : false;
  };

  const handleFileChange = (file: File | null, name: string) => {
    setFieldValue(name, file);

    const clonedPatients = [...patients];

    clonedPatients[index].file = file ?? undefined;

    setDataInsideStorage(clonedPatients);

    setBooleans((prevState) => ({
      ...prevState,
      fileError: verifyPatientAge(patient)
    }))
  }

  const handleChangeDependent = (value: DependentType) => {

    const foundDependent = fullDependents.find((i) => i.id == value.id)

    const updatedPatients = [...patients];


    if (foundDependent) {
      updatedPatients[index] = {
        id: foundDependent.id,
        index: updatedPatients[index].index,
        name: foundDependent?.full_name,
        social_name: foundDependent.social_name ?? '',
        mother_name: foundDependent.mother_name ?? '',
        birthdate: moment(foundDependent.birthdate, "YYYY-MM-DD").format('DD/MM/YYYY'),
        date: moment(foundDependent.birthdate, "YYYY-MM-DD").toDate(),
        family_relationship_id: foundDependent.family_relationship ? foundDependent.family_relationship.id : 0,
        phone: foundDependent.phones.length > 0 ? foundDependent.phones[0] : { id: 0, ddd: 0, phone: 0, ddi: 0 },
        formattedPhone: `+${foundDependent.phones[0].ddi}${foundDependent.phones[0].ddd}${foundDependent.phones[0].phone}`,
        email: foundDependent.emails.length > 0 ? foundDependent.emails[0].email : '',
        document: foundDependent.documents.length > 0 ?
          {
            ...foundDependent.documents[0],
            number: foundDependent.documents[0].type === 'cpf' ? MaskCPF(foundDependent.documents[0].number) : foundDependent.documents[0].number,
            country_id: foundDependent.documents[0].type === 'passport' && foundDependent.documents[0].country ? foundDependent.documents[0].country.id : 0
          }
          : {
            id: 0,
            country_id: 0,
            number: '',
            type: 'cpf'
          },
        gender_id: foundDependent.gender.id,
        isPayer: false,
        isRequestor: false,
        unborn: foundDependent.unborn,
        responsible_id: foundDependent.responsible_id,
        symptoms: "",
        diseases: "",
        height: "",
        last_menstruation: "",
        medicament: "",
        weight: "",
      }
    }

    setValues({
      ...patients,
      patients: updatedPatients
    });


    setOrder({
      ...order,
      patients: updatedPatients
    })

    setObjects((objects) => ({
      ...objects,
      dependent: value
    }));
  }

  const handleChangeCustomField = (e: React.ChangeEvent<any>, mask: string, formattedKey: string) => {
    let { value } = e?.target;
    const { name } = e?.target;
    const clonedPatients = [...patients];


    if (mask === 'height') {
      let height = value.replace(/\D/g, '');

      if (height.length > 3) {
        height = height.slice(0, 3);
      }

      if (height.length <= 3) {
        height = height.replace(/(\d{1})(\d{2})/, '$1,$2');
      }

      value = height;

      clonedPatients[index].height = value;
      setDataInsideStorage(clonedPatients);
      return setFieldValue(name, value)
    }

    if (mask === 'weight') {
      let weight = value.replace(/\D/g, '');

      if (weight.length > 5) {
        weight = weight.slice(0, 5);
      }

      if (weight.length <= 5) {
        weight = weight.replace(/(\d{1,3})(\d{2})/, '$1,$2');
      }

      if (weight.length >= 7) {
        return
      }

      value = weight;


      clonedPatients[index].weight = value;
      setDataInsideStorage(clonedPatients);

      return setFieldValue(name, value)
    }

    if (mask === 'menstruation') {
      let date = value.replace(/\D/g, '');

      if (date.length > 8) {
        date = date.slice(0, 8);
      }

      if (date.length <= 2) {
        date = date.replace(/(\d{1,2})/, '$1');
      } else if (date.length <= 4) {
        date = date.replace(/(\d{1,2})(\d{1,2})/, '$1/$2');
      } else {
        date = date.replace(/(\d{1,2})(\d{1,2})(\d{1,4})/, '$1/$2/$3');
      }

      if (date.length >= 11) {
        return;
      }

      value = date;

      clonedPatients[index].menstruation = value;
      setDataInsideStorage(clonedPatients);

      return setFieldValue(name, value);
    }

    clonedPatients[index][formattedKey] = value;
    setDataInsideStorage(clonedPatients);

    setFieldValue(name, value)
  }

  const filteredDependents = patient.isRequestor ? dependents : dependents.filter((i) => i.isPayer == false)

  const handleChangeDocumentType = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const clonnedPatients = [...patients];

    clonnedPatients[index].document.type = value;

    setFieldValue(name, value)

    if ((patient.document.type === 'passport' || patient.document.type === 'cpf') && !patient.isRequestor) {
      setFieldValue(`patients[${index}].document.number`, '');
      clonnedPatients[index].document.number = '';
    }

    setDataInsideStorage(clonnedPatients)
  }

  const handleChangeSwitch = (name: string, value: boolean) => {

    const clonnedPatients = [...patients];

    if (!value) {
      clonnedPatients[index] = {
        index: clonnedPatients[index].index,
        isRequestor: value,
        id: 0,
        isPayer: false,
        birthdate: "",
        document: {
          id: 0,
          type: "",
          number: "",
          country_id: 0,
        },
        formattedPhone: "",
        email: "",
        gender_id: 0,
        mother_name: "",
        name: "",
        phone: {
          id: 0,
          ddd: 0,
          ddi: 55,
          phone: 0,
        },
        social_name: "",
        unborn: false,
        family_relationship_id: 0,
        responsible_id: 0,
        symptoms: "",
        diseases: "",
        height: "",
        last_menstruation: "",
        medicament: "",
        weight: "",
      }
    } else {
      clonnedPatients[index] = formatPayer(payer_patient)
    }


    setDataInsideStorage(clonnedPatients)
    setValues({
      ...patients,
      patients: clonnedPatients
    });
  }

  const handleChangeBirthDate = (e: React.ChangeEvent<any>) => {

    let { value } = e.target;
    const { name } = e.target
    const clonedPatients = [...patients]
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    let date = value.replace(/\D/g, '');

    if (date.length > 8) {
      date = date.slice(0, 8);
    }

    if (date.length <= 2) {
      date = date.replace(/(\d{1,2})/, '$1');
    } else if (date.length <= 4) {
      date = date.replace(/(\d{1,2})(\d{1,2})/, '$1/$2');
    } else {
      date = date.replace(/(\d{1,2})(\d{1,2})(\d{1,4})/, '$1/$2/$3');
    }

    if (date.length >= 11) {
      return;
    }

    value = date;
    clonedPatients[index].birthdate = value;

    if (dateRegex.test(value)) {
      clonedPatients[index].date = convertToDate(value);
    }

    setDataInsideStorage(clonedPatients);
    setFieldValue(name, value);
  }

  const handleChangeDocumentNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e?.target;
    const { name } = e?.target;

    const clonnedPatients = [...patients];

    if (patient.document.type === 'cpf') {
      value = MaskCPF(value)
    }

    clonnedPatients[index].document.number = value;

    setDataInsideStorage(clonnedPatients);
    setFieldValue(name, value)
  }

  const convertToDate = (dateString: string) => {
    return moment(dateString, "DD/MM/YYYY").toDate();
  }

  const setDataInsideStorage = (patients: PatientOrderType[]) => {
    setOrder({
      ...order,
      patients
    });
  }

  const handleChangeStorage = (e: React.ChangeEvent<HTMLInputElement>, formKey: string) => {
    const { name, value } = e.target;
    const clonnedPatients = [...patients];

    if (formKey === 'country_id') {
      clonnedPatients[index].document.country_id = Number(value)
    } else {
      clonnedPatients[index][formKey] = value;
    }

    setDataInsideStorage(clonnedPatients)
    setFieldValue(name, value)
  }

  return (
    <Card>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <h5 className="patient-title">Paciente {index + 1}</h5>
        {index === 0 ? <SwitchInput
          enabledText="Paciente Solicitante"
          disabledText="Outro paciente"
          label="teste"
          name={`patients[${index}].isRequestor`}
          value={patient.isRequestor}
          handleChange={handleChangeSwitch}
        /> : <></>}
      </div>
      <div style={{ width: '98%', padding: 10 }}>
        <DependentSelect
          id="dependent"
          options={filteredDependents}
          loading={loadingDependents}
          placeholder="Selecionar dependente"
          value={{
            id: patient.isPayer ? patient.id : objects.dependent.id,
            full_name: patient.isPayer ? patient.name : objects.dependent.full_name,
            isPayer: patient.isPayer ?? false
          }}
          onChange={(value) => handleChangeDependent(value)}
          isDisabled={patient.isRequestor}
        />
      </div>
      <div style={{ width: '98%', padding: 10, display: 'flex', gap: 10 }}>
        <div style={{ width: '33%' }}>
          <Field
            name={`patients[${index}].name`}
            id={`patients[${index}].name`}
            label="NOME:"
            component={Input}
            value={patient.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "name")}
            placeholder="Digite o nome completo"
            readOnly={patient.isRequestor}
            error={formatError('name')}
          />
        </div>
        <div style={{ width: '33%' }}>
          <Field
            name={`patients[${index}].birthdate`}
            id={`patients[${index}].birthdate`}
            label="Data de Nascimento:"
            component={Input}
            value={patient.birthdate}
            onChange={handleChangeBirthDate}
            placeholder="DD/MM/YYYY"
            readOnly={patient.isRequestor}
            error={formatError('birthdate')}
          />
        </div>
        <div style={{ width: '33%' }}>
          <Field
            name={`patients[${index}].email`}
            id={`patients[${index}].email`}
            label="E-MAIL:"
            component={Input}
            value={patient.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "email")}
            placeholder="Digite o e-mail"
            readOnly={patient.isRequestor}
            type="email"
            error={formatError('email')}
          />
        </div>
      </div>
      <div style={{ width: '98%', paddingLeft: 10, display: 'flex', gap: 10 }}>
        {!patient.isPayer ?
          <div style={{ width: '100%' }}>
            <ControlledCheckbox
              label="DEPENDENTE SEM DOCUMENTO"
              id={`patients[${index}].unborn`}
              name={`patients[${index}].unborn`}
              value={patient.unborn}
              error={formatError('unborn')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "unborn")}
            />
          </div>
          : <></>}
      </div>
      <div style={{ width: '98%', padding: 10, display: 'flex', gap: 10 }}>
        <div style={{ width: patient.document.type === '' || patient.document.type === 'cpf' ? '50%' : '33%' }}>
          <SelectDocumentType
            id={`patients[${index}].document.type`}
            label="DOCUMENTO:"
            name={`patients[${index}].document.type`}
            placeholder="Selecione o Documento"
            handleChange={(e) => handleChangeDocumentType(e)}
            value={patient.document.type}
            error={formatError('document.type')}
            disabled={patient.isRequestor || patient.unborn}
          />
        </div>
        <div style={{ width: patient.document.type === '' || patient.document.type === 'cpf' ? '50%' : '33%' }}>
          <InputCpf
            id={`patients[${index}].document.number`}
            // name={`patients[${index}].document.number`}
            label={patient.document.type === 'passport' ? 'Nº DO PASSAPORTE' : 'Nº DOCUMENTO:'}
            value={patient.document.number}
            placeholder="Digite o nº de Documento"
            disabled={patient.isRequestor || patient.unborn}
            // isCpf={patient.document.type === 'cpf'}
            handleChange={(e) => handleChangeDocumentNumber(e)}
            error={formatError('document.number')}
          />
        </div>
        {patient.document.type === 'passport' ?
          <div style={{ width: '33%' }}>
            <SelectCountries
              name={`patients[${index}].document.country_id`}
              id={`patients[${index}].document.country_id`}
              label="PAÍS EMISSOR:"
              value={patient.document.country_id}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "country_id")}
              placeholder="Selecione o País"
              disabled={patient.isRequestor || patient.unborn}
              options={countries}
              error={formatError("document.country_id")}
            />
          </div>
          : <></>}
      </div>

      <div style={{ width: '98%', padding: 10, display: 'flex', gap: 10 }}>
        <div style={{ width: '50%' }}>
          <SelectGenderType
            name={`patients[${index}].gender_id`}
            id={`patients[${index}].gender_id`}
            label="GENERO:"
            value={patient.gender_id}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "gender_id")}
            placeholder="Selecione o Genero"
            disabled={false}
            options={genders}
            error={formatError("gender_id")}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Field
            id={`patients[${index}].formattedPhone`}
            label="TELEFONE*"
            placeholder="(00) 00000-0000"
            component={InputPhone}
            value={patient.formattedPhone}
            name={`patients[${index}].formattedPhone`}
            // onChange={(phone: string) => setFieldValue(`patients[${index}].formattedPhone`, phone)}
            onChange={(phone: string) => {
              const clonnedPatients = [...patients];
              clonnedPatients[index].formattedPhone = phone;
              setDataInsideStorage(clonnedPatients);
              setFieldValue(`patients[${index}].formattedPhone`, phone);
            }}
            error={formatError("formattedPhone")}
          />
        </div>
      </div>

      <div style={{ width: '98%', padding: 10, display: 'flex', marginTop: '-1em', gap: 10 }}>
        {patient.gender_id == 2 || patient.gender_id == 4 ?
          <div style={{ width: '33%' }}>
            <Field
              name={`patients[${index}].social_name`}
              id={`patients[${index}].social_name`}
              label="NOME SOCIAL:"
              component={Input}
              value={patient.social_name}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "social_name")}
              placeholder="Digite o nome social"
              readOnly={patient.isRequestor}
              error={formatError('social_name')}
            />
          </div>
          : <></>}
      </div>
      {booleans.showFileInput ?
        <div style={{ width: '98%', marginTop: 10, padding: 10, maxHeight: '10em' }}>
          <div className="save-profile-patient-border" />

          <div style={{ marginBottom: '1em' }}>
            <span style={{ fontSize: '16px', color: 'rgba(102, 102, 102, 1)', textTransform: 'uppercase', fontWeight: 600 }}> Upload de Documentos</span><br />

          </div>

          {booleans.fileError ? <span
            className="field-holder-invalid"
            style={{
              color: '#c23f3b',
              fontSize: '0.8em',
              marginLeft: 10,
              paddingBottom: 10,
              paddingTop: 10
            }}
          >
            Campo Obrigatório*
          </span>
            : <></>}
          <UploadFileDocument
            label="CARTEIRINHA DE VACINAÇÃO"
            accept="image/*,.pdf"
            addIcon={frontCard}
            name={`patients[${index}].file`}
            file={patient.file}
            onChange={(file: File | null) => handleFileChange(file, `patients[${index}].file`)}
            error={booleans.fileError}
          />
        </div>
        : <></>}

      {!patient.isPayer ? <>
        <div style={{ padding: 10 }}>
          <div className="save-profile-patient-border" style={{ marginTop: 10 }} />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <h5 className="delete-button" onClick={() => removePatients(index)}>Excluir Paciente</h5>
          <div style={{ width: '33%' }}>
            <SelectFamilyRelationship
              name={`patients[${index}].family_relationship_id`}
              id={`patients[${index}].family_relationship_id`}
              value={patient.family_relationship_id}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeStorage(e, "family_relationship_id")}
              placeholder="Grau de Parentesco"
              disabled={patient.isRequestor}
              options={family_relationships}
              error={formatError("family_relationship_id")}
            />
          </div>
        </div>
      </> : <></>}

      {patients.length === 1 && patientWithProducts.length === 0 ? renderFieldsWhenSinglePatient() : <></>}

      {patients.length > 1 && patientWithProducts.length > 0 ? renderFieldWhenMultiplePatients() : <></>}

    </Card>
  )
}