import API from "Services/Api";

import {
  ConvertBase64ToFile,
  convertBase64ToBlob,
  getMimeTypeFromUrl,
} from "Utils/ConvertBase64";

export const createInsurance = async (data) => {
  try {
    const response = await API.post(`/covenant`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editInsurance = async (data, id) => {
  try {
    const response = await API.post(`/covenant/update/${id}`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getInsurance = async () => {
  try {
    const { data } = await API.get(`/covenant?page_size=100`);

    return data;
  } catch (error) {
    console.log({ error });
  }
};

export const getImageCovenant = async (url, file = false, name = "") => {
  try {
    const { data, status } = await API.get(
      `/covenant/documents/order?url=${url}`
    );

    if (status === 200) {
      if (file) return ConvertBase64ToFile([data], [name]);

      const mimeType = await getMimeTypeFromUrl(url);
      return convertBase64ToBlob(data, mimeType);
    }

    return "";
  } catch (error) {
    console.log(error);
  }
};

export const getImageCovenantOrder = async (orderId, params, url) => {
  try {
    const { data, status } = await API.get(
      `/covenant/order/${orderId}/documents?${params}`
    );

    if (status === 200) {
      const mimeType = await getMimeTypeFromUrl(url);
      return convertBase64ToBlob(data, mimeType);
    }

    return "";
  } catch (error) {
    return console.log(error);
  }
};

export const getImageCovenantOrderMedical = async (
  orderId,
  orderMedicalId,
  url
) => {
  try {
    const { data, status } = await API.get(
      `covenant/order/${orderId}/documents/order_medical/${orderMedicalId}`
    );

    if (status === 200) {
      const mimeType = await getMimeTypeFromUrl(url);
      return convertBase64ToBlob(data, mimeType);
    }

    return "";
  } catch (error) {
    return console.log(error);
  }
};

export const deleteImageCovenant = async (idCovenant, idImage) => {
  try {
    const { status } = await API.delete(
      `/covenant/${idCovenant}/images/${idImage}`
    );
    return status;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImageCovenant = async (idCovenant, data) => {
  try {
    const { status } = await API.post(`/covenant/${idCovenant}/images`, data);
    return status;
  } catch (error) {
    console.log(error);
  }
};

export const deleteInsurance = async (id) => {
  try {
    const { data } = await API.delete(`/covenant/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getInsuranceId = async (id) => {
  const { data } = await API.get(`/covenant/${id}`);
  return data;
};

export const getComboRegionsInsurance = async () => {
  try {
    const { data } = await API.get(`/unit/all/groupbyregion`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadAttachmentsInsurance = async (data) => {
  try {
    const response = await API.post(`/covenant/documents/upload`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const DownloadAnnex = async (id, name) => {
  return API.get(`/covenant/documents/download/${id}`, {
    responseType: "blob",
  })
    .then((response) => {
      const contentType = response.headers["content-type"];
      const fileExtension = contentType.split("/")[1]; // obtém a extensão do arquivo a partir do Content-Type
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const deleteAnexoInsurance = async (data) => {
  try {
    const response = await API.post("/covenant/documents/remove", data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUnitsInsurance = async (id) => {
  const { data } = await API.get(`/budgets/plan/available_units/${id}`);
  return data;
};
