import { Fragment, useState } from "react"
import { CardSelection } from "../CardSelection"
import { ProductsCardsProps } from "./types"
import { SelectProductModal } from "../SelectProductModal"

export const ProductsCards = ({ patients, patientWithProducts, items }: ProductsCardsProps) => {

  const [booleans, setBooleans] = useState({
    selectProductModal: false
  });

  const [numbers, setNumbers] = useState({
    curentIndex: -1,
  });

  const openSelectProductModal = (index: number) => {
    setNumbers((numbers) => ({ ...numbers, curentIndex: index }));
    setBooleans((booleans) => ({ ...booleans, selectProductModal: true }))
  }

  return (
    <div className="card card-selection">
      <div className="card-header">
        Indique quais produtos cada um vai receber
      </div>
      <div className="card-body">
        <ul className="patients-list">
          {patients?.map((item, index) => (
            <Fragment key={index}>
              <CardSelection
                index={index}
                count={index + 1}
                patients={patients}
                selectedProducts={patientWithProducts}
                hasVaccineInCart={false}
                onClick={(index) => openSelectProductModal(index)}
              />
            </Fragment>
          ))}
        </ul>
      </div>
      {booleans.selectProductModal ?
        <SelectProductModal
          isOpen={booleans.selectProductModal}
          closeModal={() => setBooleans((booleans) => ({ ...booleans, selectProductModal: false }))}
          patients={patients}
          index={numbers.curentIndex}
          items={items}
        />
        : <></>}
    </div>
  )


}