export const CATEGORIES = {
  exam: Number(process.env.REACT_APP_CATEGORY_EXAM ?? ""),
  checkup: Number(process.env.REACT_APP_CATEGORY_CHECKUP ?? ""),
  test: Number(process.env.REACT_APP_CATEGORY_TEST ?? ""),
  vaccine: Number(process.env.REACT_APP_CATEGORY_VACCINE ?? ""),
  consult: Number(process.env.REACT_APP_CATEGORY_CONSULT ?? ""),
  image: Number(process.env.REACT_APP_CATEGORY_IMAGE ?? ""),
  infusion: Number(process.env.REACT_APP_CATEGORY_INFUSION ?? ""),
};

export const SUBCATEGORIES = {
  vaccine_club: Number(process.env.REACT_APP_VACCINE_CLUB),
  vaccine_package: Number(process.env.REACT_APP_VACCINE_PACKAGE),
};
