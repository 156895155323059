const ShowFileFormat = (format) => {
    let formatString = '';

    switch(format) {
        case 'application/pdf':
            formatString = 'PDF';
            break;

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            formatString = 'Planilha Excel (.xlsx ou .xls)';
            break;

        case 'application/vnd.ms-excel':
            formatString = 'Planilha Excel (.xlsx ou .xls)';
            break;
        
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            formatString = 'Documento Word (.docx ou .doc)';
            break;

        case 'image/svg+xml':
            formatString = 'Imagem vetorizada (SVG)';
            break;

        case 'image/jpeg':
            formatString = 'Imagem JPEG';
            break;

        case 'image/png':
            formatString = 'Imagem PNG';
            break;

        case 'text/html':
            formatString = 'Arquivo HTML';
            break;

        default:
            formatString = 'Formato não reconhecido';
            break;
    }

    return formatString;
}

export default ShowFileFormat;