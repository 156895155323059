import FormatCurrency from "./FormatCurrency";

const CalculateCartWithDiscount = (amount, price) => {
    const _amount = +amount;
    const _price = parseFloat(price).toFixed(2);
    
    const calculate = _price - ((_price/100) * parseFloat(_amount));
    
    return {
        calculate: FormatCurrency(calculate),
        price: FormatCurrency(_price - calculate)
    };
}

export default CalculateCartWithDiscount;