import moment from "moment";

import API from "Services/Api";

export const GetDashData = async (type, initialDate, finalDate) => {
  try {
    if (initialDate) {
      const initial = moment(initialDate).format("YYYY[-]MM[-]DD");
      const final = moment(finalDate).format("YYYY[-]MM[-]DD");

      if (type === "order") {
        const response = await API.get(
          `/dashboard/orders?start=${initial}&end=${final}`
        );
        return response.data;
      }

      if (type === "rnds") {
        const response = await API.get(
          `/dashboard/rnds?start=${initial}&end=${final}`
        );
        return response.data;
      }

      if (type === "results") {
        const response = await API.get(
          `/dashboard/reports?start=${initial}&end=${final}`
        );
        return response.data;
      }
    } else {
      const initial = moment().subtract(7, "days").format("MM[-]DD[-]YYYY");
      const final = moment().format("MM[-]DD[-]YYYY");

      if (type === "order") {
        const response = await API.get(
          `/dashboard/orders?start=${initial}&end=${final}`
        );
        return response.data;
      }

      if (type === "rnds") {
        const response = await API.get(
          `/dashboard/rnds?start=${initial}&end=${final}`
        );
        return response.data;
      }

      if (type === "results") {
        const _initial = moment().subtract(7, "days").format("YYYY[-]MM[-]DD");
        const _final = moment().format("YYYY[-]MM[-]DD");
        const response = await API.get(
          `/dashboard/reports?start=${_initial}&end=${_final}`
        );
        return response.data;
      }
    }
  } catch (error) {
    return error;
  }
};

export function transformResponseToChartData(response, type) {
  const daysToChart = {
    labels: [],
    success: [],
    errors: [],
  };

  const weeksToChart = {
    labels: [],
    success: [],
    errors: [],
  };

  const monthsToChart = {
    labels: [],
    success: [],
    errors: [],
  };

  Object.keys(response.list_day).forEach((key) => {
    daysToChart.labels.push(moment(key).format("DD/MM/YYYY"));
    daysToChart.success.push(
      type === "rnds"
        ? response.list_day[key].sent || 0
        : response.list_day[key].success || 0
    );
    daysToChart.errors.push(
      type === "rnds"
        ? response.list_day[key].errors || 0
        : response.list_day[key].error || 0
    );
  });

  Object.keys(response.list_week).forEach((key) => {
    weeksToChart.labels.push(
      moment()
        .week(Number(key) + 1)
        .format("L")
    );
    weeksToChart.success.push(
      type === "rnds"
        ? response.list_week[key].sent || 0
        : response.list_week[key].success || 0
    );
    weeksToChart.errors.push(
      type === "rnds"
        ? response.list_week[key].errors || 0
        : response.list_week[key].error || 0
    );
  });

  Object.keys(response.list_month).forEach((key) => {
    monthsToChart.labels.push(
      moment()
        .month(key - 1)
        .format("MMMM")
    );
    monthsToChart.success.push(
      type === "rnds"
        ? response.list_month[key].sent || 0
        : response.list_month[key].success || 0
    );
    monthsToChart.errors.push(
      type === "rnds"
        ? response.list_month[key].errors || 0
        : response.list_month[key].error || 0
    );
  });

  return {
    daysToChart,
    weeksToChart,
    monthsToChart,
  };
}
