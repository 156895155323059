import styled from "styled-components";

interface ContainerProps {
  heightFull?: boolean;
  imageCardWidth?: string;
}

export const Container = styled.tr<ContainerProps>`
  width: 100%;
  padding: 24px 20px;
  box-sizing: border-box;
  }
`;
