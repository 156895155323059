import React, { useRef, useState } from "react";

import { DropzoneProps } from "./interface";

const DropzoneAnnex = (props: DropzoneProps) => {
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  function fileListToArray(list: FileList | null): File[] {
    const array = [];

    if (list) {
      for (let i = 0; i < list.length; i++) {
        array.push(list.item(i)!);
      }
    }

    return array;
  }

  function openFileDialog() {
    if (props.disabled) {
      return;
    }

    fileInputRef.current?.click();
  }

  function onFilesAdded(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.disabled) {
      return;
    }

    if (props.onFilesAdded) {
      const files = fileListToArray(event.target.files);

      props.onFilesAdded(files);
    }
  }

  function onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    if (props.disabled) {
      return;
    }

    setHighlight(true);
  }

  function onDragLeave() {
    setHighlight(false);
  }

  function onDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    if (props.disabled) {
      return;
    }

    const {files} = event.dataTransfer;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);

      props.onFilesAdded(array);
    }

    setHighlight(false);
  }

  return (
    <div
      className={`
        dropzoneInsurance-area border-none
       
      `}
      onClick={openFileDialog}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <input
        ref={fileInputRef}
        className="input-file"
        type="file"
        multiple={props.multi}
        onChange={onFilesAdded}
        accept={props.fileAccept}
      />
      <span>{props.elementDescr}</span>
    </div>
  );
};

DropzoneAnnex.defaultProps = {
  multi: false,
};

export default DropzoneAnnex;
