import { BudgetItemProps } from "Pages/Budgets/ViewDetails/interface";
import { Box } from "styles/globalComponents";

import { useBudgetOrder } from "Stores/budget-order";
import { useShallow } from "zustand/react/shallow";
import { useEffect } from "react";
import { GridAcccordion } from "../style";
import { ItemsDetail } from "./ItemsDetail";
import { BudgetDetail } from "./OrderDetail";
import { PatientDetail } from "./PatientDetail";

export const BudgetDetails = ({ data }: { data: BudgetItemProps }) => {

  const [setIsViewBudget] = useBudgetOrder(useShallow((state) => [
    state.setIsViewBudget,
  ]));

  useEffect(() => {
    setIsViewBudget(true)
  },[])

  return (
    <Box display="flex" wrap="wrap" cgap="24" rgap="24" justify="space-between">
      <GridAcccordion>
        <BudgetDetail data={data} />
        <PatientDetail data={data} />
      </GridAcccordion>
      <ItemsDetail data={data} />
    </Box>
  );
};
