import React from "react";

import { Loading } from "Utils/Loading";

const ShowLoading: React.FC = () => {
  return (
    <tr>
      <td colSpan={9} className="text-center">
        <span className="loading-persons">
          <Loading />
        </span>
      </td>
    </tr>
  );
};

export default ShowLoading;
