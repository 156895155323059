import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { PaginationWrapper } from "Pages/HomeOrders/styles";
import ShowLoading from "Pages/RegistrationCleaning/components/ShowLoading";
import {
  ComponentPaginationProps,
  DataPersonPagination,
  DataPerson,
  UnifyRegistrationModalInterface,
  RouteParamsInterface,
} from "Pages/RegistrationCleaning/interface";
import {
  TextModal,
  TableSelectPerson,
} from "Pages/RegistrationCleaning/styles";

import { duplicateRegistration } from "Requests/Person";

import { createToast } from "Utils/toastFunc";

import Checkbox from "Components/Form/Checkbox";
import Pagination from "Components/Nav/Pagination";

export const UnifyRegistration = ({
  personsSelect,
  handleDismiss,
  handleModal,
}: UnifyRegistrationModalInterface) => {
  const [data, setData] = useState<DataPerson[]>();
  const [pagination, setPagination] = useState<DataPersonPagination>();
  const [loading, setLoading] = useState(false);
  const [modalPersonsSelected, setModalPersonsSelected] = useState<
    DataPerson[]
  >([]);
  const { document } = useParams<RouteParamsInterface>();
  const person = personsSelect?.[0];

  async function handlePagination(e: ComponentPaginationProps) {
    if (pagination?.current_page === e.currentPage) return;
    await fetchData(e.currentPage);
  }

  async function fetchData(page?: any) {
    try {
      setLoading(true);

      const response = await duplicateRegistration(document, page, {
        birthdate: person?.birthdate,
        gender: person?.gender,
        exclude: [person?.id],
      });
      const {data} = response;
      setData(data.data);

      setPagination({
        current_page: data.current_page,
        total_pages: data.last_page,
        total: data.total,
      });

      return data.data;
    } catch (error) {
      createToast("error", "Ocorreu um erro ao solicitar os dados do pedido!");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (personsSelect?.length === 1) {
      fetchData();
    } else {
      setData(personsSelect);
      setModalPersonsSelected(personsSelect?.slice(1) || []);
    }
  }, []);

  const handleModalCheckBoxSelected = (person: DataPerson, value: boolean) => {
    if (!value)
      return setModalPersonsSelected((prev: DataPerson[]) =>
        prev.filter((item: DataPerson) => item.id !== person.id)
      );
    setModalPersonsSelected((prev: DataPerson[]) => [...prev, person]);
  };

  return (
    <div>
      <TextModal fontWeight={400}>
        {personsSelect?.length === 1
          ? "Selecione os nomes que deseja unificar ao cadastro principal"
          : "Ao unificar duas ou mais pessoas em um cadastro único todo o histórico de orçamentos e pedidos será unificado."}
      </TextModal>

      <TableSelectPerson>
        <thead>
          <tr>
            {personsSelect?.length === 1 && <th className="checkbox" />}
            <th>Nome</th>
            <th>Qtd Solicitações</th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <ShowLoading />
          ) : !data?.length ? (
            <tr>
              <td colSpan={9} className="text-center text-purple">
                Oops! Parece que nada foi encontrado...
              </td>
            </tr>
          ) : (
            data?.map((item) => (
              <tr key={item.id}>
                {personsSelect?.length === 1 && (
                  <td className="checkbox">
                    <div>
                      <Checkbox
                        id={`check_modal_${item.id}`}
                        small
                        onChange={
                          handleModalCheckBoxSelected
                            ? (_, value) =>
                                handleModalCheckBoxSelected(item, value)
                            : () => {}
                        }
                        value={modalPersonsSelected?.some(
                          (_item: any) => _item.id === item.id
                        )}
                      />
                    </div>
                  </td>
                )}

                <td>
                  <div style={{ paddingRight: 5 }}>{item?.full_name}</div>
                </td>
                <td>
                  <div style={{ display: "block", textAlign: "center" }}>
                    {item?.budgets_count +
                      item?.orders_count +
                      item?.reports_count}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </TableSelectPerson>

      {pagination?.total_pages && (
        <PaginationWrapper>
          <Pagination
            totalRecords={pagination.total_pages}
            pageLimit={1}
            pageNeighbours={1}
            onPageChanged={(e: ComponentPaginationProps) => handlePagination(e)}
          />
        </PaginationWrapper>
      )}
      <div className="flex-buttons-modal-information">
        <div className="container-button-information">
          <button
            className="button _white _transparentBorderButton"
            onClick={handleDismiss}
          >
            Cancelar
          </button>
        </div>
        <div className="container-button-information">
          <button
            disabled={
              (personsSelect?.length ?? 0) <= 1 && !modalPersonsSelected?.length
            }
            className="button _action"
            onClick={() => handleModal(modalPersonsSelected, person)}
          >
            Unificar Dados
          </button>
        </div>
      </div>
    </div>
  );
};
