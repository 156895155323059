import moment, { isMoment } from "moment";

export function getDayInWeek(
  date: string | Date | moment.Moment,
  addDays = 0,
  returnNumber = false
) {
  const jsDate = new Date(isMoment(date) ? date.toDate() : date);
  const weekDay = jsDate.getDay() + addDays;

  if (returnNumber) {
    return weekDay;
  }

  const weekDayMap: Record<number, string> = {
    0: "Domingo",
    1: "Segunda",
    2: "Terça",
    3: "Quarta",
    4: "Quinta",
    5: "Sexta",
    6: "Sábado",
  };

  return weekDayMap[weekDay] || "";
}

export function getMonthNumber(month: number) {
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  return months[month];
}

export function getMonthName(month: number) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return months[month];
}

export function getDayAndMonth(
  date: string | Date | moment.Moment,
  plusDays = 0,
  returnYear = false
) {
  const jsDate = new Date(isMoment(date) ? date.toDate() : date);

  const finalDate = new Date(jsDate.getTime());
  finalDate.setDate(jsDate.getDate() + plusDays);

  if (returnYear) {
    return `${getMonthName(finalDate.getMonth())} ${finalDate.getFullYear()}`;
  }

  return finalDate.getDate() < 10
    ? `0${`${finalDate.getDate()}/${getMonthNumber(finalDate.getMonth())}`} `
    : `${finalDate.getDate()}/${getMonthNumber(finalDate.getMonth())}`;
}
