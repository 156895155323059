import { useHistory } from "react-router";

import { Document } from "Dtos/IBudget";
import { Box } from "styles/globalComponents";

import { formatDateBR } from "Utils/Date";
import { formatBRLCurrency } from "Utils/FormatCurrency";
import { MaskCPF } from "Utils/Masks";

import { Button } from "Components/Button";

import { TableBodyProps } from "./interface";

export const TableBody = ({ listBudgets }: TableBodyProps) => {
  const history = useHistory();

  const formatDocument = (document: Document) => {
    if (!document) return;
    if (document.type === "cpf") return MaskCPF(document.number);

    return document.number;
  };

  const viewDetailedBudget = (budget_id: number) => {
    history.push(`/orcamento/${budget_id}`);
  };

  return (
    <tbody>
      {listBudgets.map((dataItem) => (
        <tr key={dataItem.id}>
          <td>{dataItem.id}</td>
          <td>{formatDateBR(dataItem.created_at)}</td>
          {dataItem.patient ? (
            <>
              <td>
                {dataItem.patient?.social_name || dataItem.patient?.full_name}
              </td>
              <td>{formatDocument(dataItem.patient?.documents[0])}</td>
            </>
          ) : (
            <>
              <td>{dataItem.lead?.name}</td>
              <td />
            </>
          )}

          <td>{dataItem.coupon_code}</td>
          <td>{formatBRLCurrency(dataItem.total)}</td>

          <td>{dataItem.channel.name}</td>
          <td>{dataItem.status.status}</td>
          <td>
            <Box display="flex" justify="center">
              <Button onClick={() => viewDetailedBudget(dataItem.id)} outlined>
                VISUALIZAR
              </Button>
            </Box>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
