import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";

import { getUnitsUser, integrationMotionOrder } from "Requests/HomeOrder";
import { editCovenantOrder } from "Requests/Orders";
import {
  getImageCovenantOrder,
  getImageCovenantOrderMedical,
} from "Requests/RequestInsurance";

import { useOrdersCombos } from "Hooks/useOrdersCombo";
import { useOrderShow } from "Hooks/useOrderShow";

import { FormatDate } from "Utils/FormatterDate";
import { MaskCPF } from "Utils/Masks";
import { createToast } from "Utils/toastFunc";

import { Accordion } from "Components/Accordion";
import { Button } from "Components/Button";
import { DetailsInfo } from "Components/DetailsInfo";
import SelectUnits from "Components/Form/SelectUnits";
import { LoadingText } from "Components/LoadingText";
import { ModalInformation } from "Components/Modals/ModalInformation";

import AtentionIcon from "Assets/Images/atention.svg";

import InfoImage from "../DetailsInfoImage";
import * as S from "./style";

export function ModalDetails({ modalVisible, onRequestClose, orderId }: any) {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState<any>({});
  const [imagesCovenant, setImagesCovenant] = useState<any>(null);
  const [loadingImages, setLoadingImages] = useState(false);

  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    geral: true,
    payment: true,
    items: true,
    patient: true,
    plans: true,
  });

  const status = useRef("");

  const { OrderInfoError, loadingOrderInfo, orderInfo } = useOrderShow(orderId);
  const { ordersCombo } = useOrdersCombos();

  const blockStatusButtons = ["cancelado", "aprovado"].includes(
    orderInfo?.status.toLowerCase()
  );

  function handleOpen(key: string) {
    setOpen({
      ...open,
      [key]: Object.keys(open).includes(key) ? !open[key] : false,
    });
  }

  const [consultationApproved, setconsultationApproved] = useState(false);

  const updateOrder = async () => {
    let status_id;
    const operationStatus =
      status?.current === "canceled" ? "recusado" : "aprovado";

    ordersCombo?.status?.map((item) => {
      if (status?.current === "canceled") {
        if (item?.status === "Cancelado") status_id = item?.id;
      } else if (item?.status === "Aprovado") status_id = item?.id;
    });

    try {
      const response = await editCovenantOrder({
        order_id: orderInfo?.id,
        status_id,
      });
      if (response.success) {
        setconsultationApproved(true);
        return createToast(
          "success",
          `Pré-pedido ${operationStatus} com sucesso.`
        );
      }

      return createToast(
        "error",
        "Não foi possível alterar o status deste pré-pedido"
      );
    } catch (err) {
      createToast(
        "error",
        "Não foi possível alterar o status deste pré-pedido"
      );
    } finally {
      setOpenConfirmModal(false);
      onRequestClose(false);
    }
  };

  useEffect(() => {
    if (consultationApproved) {
      window.location.reload();
    }
  }, [consultationApproved]);

  async function fetchUnits() {
    try {
      const res = await getUnitsUser();
      setUnitsOptions(res);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar as unidades!");
    }
  }

  const motionIntegration = async (motionObj: any) => {
    try {
      const response: any = await integrationMotionOrder({ ...motionObj });

      if (response?.status === 200) {
        return createToast("success", "Pedido integrado com sucesso.");
      }
      if (response?.error.status === 412) {
        const errorMessage = response.error.data[0].errors[0];
        return createToast("error", errorMessage);
      }
      return createToast("error", "Não foi possível integrar o pedido.");
    } catch (error) {
      createToast("error", "Não foi possível integrar o pedido.");
    }
  };

  const handleImagesCovenant = async () => {
    setLoadingImages(true);
    if (orderInfo) {
      const order = await Promise.all(
        orderInfo.order_covenant.order_medical.map(
          async (item: any, index: number) => {
            if (item.medical) {
              item.name = `Pedido Médico ${index + 1}`;
              item.url = await getImageCovenantOrderMedical(
                orderInfo?.id,
                item.id,
                item.medical
              );
            }
            return item;
          }
        )
      );

      const frontCard: any = await getImageCovenantOrder(
        orderInfo?.id,
        "card=front",
        orderInfo.order_covenant.card_front
      );

      const backCard: any = await getImageCovenantOrder(
        orderInfo?.id,
        "card=back",
        orderInfo.order_covenant.card_back
      );

      setImagesCovenant({
        front_card: {
          url: frontCard?.fileUrl || "",
          name: "Frente da Carteirinha",
          type: frontCard?.mimeType,
        },
        back_card: {
          url: backCard?.fileUrl || "",
          name: "Verso da Carteirinha",
          type: backCard?.mimeType,
        },
        order_medical: order,
      });
    }
    setLoadingImages(false);
  };

  const handleUnitCollect = (id: number) => {
    if (id) {
      const unit = orderInfo.units.find((item: any) => item.id === id);
      return unit?.name;
    }
    return "-";
  };

  useEffect(() => {
    fetchUnits();
  }, []);

  useEffect(() => {
    handleImagesCovenant();
  }, [orderInfo]);

  return (
    <Modal
      isOpen={modalVisible}
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      onRequestClose={() => onRequestClose(false)}
    >
      {loadingOrderInfo ? (
        <LoadingText text="Carregando dados do pedido" />
      ) : (
        <>
          <S.ContainerSendMotion>
            <label>Enviar para o motion:</label>
            <SelectUnits
              placeholder="Selecione a unidade"
              onChange={(event: any) =>
                setSelectedUnit(JSON.parse(event.target.value))
              }
              options={unitsOptions}
              id="Unidades"
              label=""
              value={undefined}
              error={undefined}
              required={undefined}
              purple={undefined}
              small
              onFocus={undefined}
              optionName={undefined}
              readOnly={orderInfo.motion_integration_date !== ""}
            />

            <Button
              onClick={() =>
                motionIntegration({
                  orders_id: [orderInfo?.id],
                  unit_id: selectedUnit?.id,
                })
              }
              disabled={!selectedUnit?.id}
              classButton="button _action"
              type="button"
              loading={false}
            >
              Enviar
            </Button>
          </S.ContainerSendMotion>

          <S.HeaderModal>Detalhes do pré-pedido</S.HeaderModal>
          <S.Hr />
          <S.ContainerInformation>
            <div className="row">
              <div className="md-12">
                <Accordion
                  title="Dados Gerais"
                  open={open?.geral}
                  handleOpen={() => handleOpen("geral")}
                >
                  <S.Hr />

                  <div className="row">
                    <div className="md-3">
                      <DetailsInfo
                        label="Nº da solicitação:"
                        content={orderInfo?.id || "Não informado"}
                      />
                    </div>
                    <div className="md-3">
                      <DetailsInfo
                        label="Data do pedido:"
                        content={
                          FormatDate(orderInfo.created_at) || "Não informado"
                        }
                      />
                    </div>
                    <div className="md-3">
                      <DetailsInfo
                        label="Tipo de coleta"
                        content={
                          orderInfo?.home_collected ? "Domiciliar" : "Unidade"
                        }
                      />
                    </div>
                    <div className="md-3">
                      {!orderInfo?.home_collected && (
                        <DetailsInfo
                          label="Unidade:"
                          content={handleUnitCollect(orderInfo.unit_id)}
                        />
                      )}
                    </div>
                  </div>
                </Accordion>
              </div>
              <div className="md-12">
                <Accordion
                  title="Paciente"
                  open={open?.patient}
                  handleOpen={() => handleOpen("patient")}
                >
                  <S.Hr />
                  {orderInfo.pacient.map((pacient: any) => (
                    <div className="row" key={pacient.patient_id}>
                      <div className="md-4">
                        <DetailsInfo
                          label="Nome:"
                          content={pacient.name || "Não informado"}
                        />
                        <DetailsInfo
                          label="Data de Nascimento:"
                          content={pacient.birthdate || "Não informado"}
                        />
                        <DetailsInfo
                          label="CEP:"
                          content={
                            !orderInfo?.attendance?.home?.collect_address
                              .zip_code ||
                            orderInfo?.attendance?.home?.collect_address
                              .zip_code === "n/a"
                              ? "Não informado"
                              : orderInfo?.attendance?.home?.collect_address
                                  .zip_code
                          }
                        />
                        <DetailsInfo
                          label="Rua:"
                          content={
                            !orderInfo?.attendance?.home?.collect_address
                              .address ||
                            orderInfo?.attendance?.home?.collect_address
                              .address === "n/a"
                              ? "Não informado"
                              : orderInfo?.attendance?.home?.collect_address
                                  .address
                          }
                        />
                        <DetailsInfo
                          label="Bairro:"
                          content={
                            !orderInfo?.attendance?.home?.collect_address
                              .neighborhood ||
                            orderInfo?.attendance?.home?.collect_address
                              .neighborhood === "n/a"
                              ? "Não informado"
                              : orderInfo?.attendance?.home?.collect_address
                                  .neighborhood
                          }
                        />
                      </div>
                      <div className="md-4">
                        <DetailsInfo
                          label="CPF"
                          content={MaskCPF(pacient.document) || "Não informado"}
                        />
                        <DetailsInfo
                          label="Telefone:"
                          content={pacient.phone || "Não informado"}
                        />
                        <DetailsInfo
                          label="Complemento:"
                          content={
                            !orderInfo?.attendance?.home?.collect_address
                              .complement ||
                            orderInfo?.attendance?.home?.collect_address
                              .complement === "n/a"
                              ? "Não informado"
                              : orderInfo?.attendance?.home?.collect_address
                                  .complement
                          }
                        />
                        <DetailsInfo label="Número:" content="Não informado" />
                        <DetailsInfo
                          label="Cidade/Estado:"
                          content={
                            !orderInfo?.attendance?.home?.collect_address
                              ?.city ||
                            orderInfo?.attendance?.home?.collect_address
                              ?.city === "n/a"
                              ? "Não informado"
                              : orderInfo?.attendance?.home?.collect_address
                                  ?.city
                          }
                        />
                      </div>
                      <div className="md-4">
                        <DetailsInfo
                          label="E-mail:"
                          content={pacient.email || "Não informado"}
                        />

                        <DetailsInfo
                          label="Data de coleta:"
                          content={
                            orderInfo?.attendance?.home?.collect_address
                              .schedule_date || "Não informado"
                          }
                        />
                        <DetailsInfo
                          label="Dia da semana:"
                          content={
                            orderInfo?.attendance?.home?.collect_address
                              .week_day || "Não informado"
                          }
                        />
                        <DetailsInfo
                          label="Taxa de coleta:"
                          content={
                            orderInfo?.attendance?.home?.collect_address
                              .home_collect_tax || "Não informado"
                          }
                        />
                        <DetailsInfo
                          label="Identificador/Agendamento:"
                          content={
                            orderInfo?.attendance?.home?.collect_address
                              .schedule_id || "Não informado"
                          }
                        />
                      </div>
                    </div>
                  ))}
                </Accordion>
              </div>
              <div className="md-12">
                <Accordion
                  title="Convênio"
                  open={open?.plans}
                  handleOpen={() => handleOpen("plans")}
                >
                  <S.Hr />
                  <div className="row">
                    <div className="md-6">
                      <DetailsInfo
                        label="Convênio"
                        content={
                          orderInfo.order_covenant.covenant_plan.covenant
                            ?.covenant_name || "Não informado"
                        }
                      />
                    </div>
                    <div className="md-6">
                      <DetailsInfo
                        label="Plano"
                        content={
                          orderInfo.order_covenant.covenant_plan
                            .covenant_plans_name || "Não informado"
                        }
                      />
                    </div>
                    <div className="md-12">
                      {loadingImages ? (
                        <LoadingText text="Carregando Documentos" />
                      ) : (
                        <S.DetailsInfoImage>
                          <span>Fotos dos documentos</span>
                          <div className="row">
                            <div className="md-4">
                              {imagesCovenant?.front_card && (
                                <InfoImage
                                  url={imagesCovenant?.front_card?.url}
                                  name={imagesCovenant?.front_card?.name}
                                  type={imagesCovenant?.front_card?.type}
                                />
                              )}
                            </div>
                            <div className="md-4">
                              {imagesCovenant?.back_card && (
                                <InfoImage
                                  url={imagesCovenant?.back_card?.url}
                                  name={imagesCovenant?.back_card?.name}
                                  type={imagesCovenant?.back_card?.type}
                                />
                              )}
                            </div>
                            {imagesCovenant?.order_medical &&
                              imagesCovenant?.order_medical.map((item: any) => (
                                <div className="md-4">
                                  <InfoImage
                                    url={item?.url?.fileUrl}
                                    name={item?.name}
                                    type={item?.url?.mimeType}
                                  />
                                </div>
                              ))}
                          </div>
                        </S.DetailsInfoImage>
                      )}
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>

            <S.ActionButtons>
              <Button
                onClick={() => {
                  setOpenConfirmModal(true);
                  status.current = "canceled";
                }}
                classButton="button _action _danger"
                type="button"
                loading={false}
                disabled={blockStatusButtons}
              >
                Marcar como cancelada
              </Button>
              <Button
                onClick={() => {
                  setOpenConfirmModal(true);
                  status.current = "approved";
                }}
                classButton="button _action"
                type="button"
                loading={false}
                disabled={blockStatusButtons}
              >
                Marcar como aprovada
              </Button>
            </S.ActionButtons>
          </S.ContainerInformation>
          <ModalInformation
            modalVisible={openConfirmModal}
            title="Salvar alterações"
            icon={AtentionIcon}
            message="Ao aprovar ou recusar um pré-pedido essa ação não poderá ser alterada ou desfeita."
            messageButton="Salvar"
            cancelButton="Cancelar"
            onClick={() => updateOrder()}
            modalDismiss={() => setOpenConfirmModal(false)}
            children={undefined}
            borderButton={undefined}
          />
        </>
      )}
    </Modal>
  );
}
