import React, { useState } from "react";
import Modal from "react-modal";

import { Button } from "Components/Button";

import { InformationProps } from "./interface";

export const ModalInformationWithChildren = ({
  onClick,
  modalDismiss,
  modalVisible,
  title,
  icon,
  messageButton,
  cancelButton,
  messageButtonCancel,
  children,
}: // loading,
InformationProps) => {
  const [loading, setLoading] = useState(false);

  const submitPartner = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-content-information"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <div className="container-modal-information">
        <div className="box-image-modal-information">
          <img src={icon} alt="" />
        </div>

        <h2>{title}</h2>

        {children}

        <div
          className="flex-buttons-modal-information"
          style={{ marginTop: 32 }}
        >
          {cancelButton && (
            <div className="container-button-information">
              <button
                className="button _white _transparentBorderButton"
                onClick={modalDismiss}
              >
                {messageButtonCancel || "Cancelar"}
              </button>
            </div>
          )}

          {messageButton && (
            <div className="container-button-information">
              <Button
                classButton="button _action"
                onClick={submitPartner}
                loading={loading}
              >
                {messageButton}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
