import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  img {
    margin: auto;
    margin-top: 32px;
  }

  h3 {
    color: #572580;
    margin-top: 25px;
    font-size: 30px;
  }

  p {
    margin: auto;
    max-width: 557px;
  }

  > div {
    margin-top: 48px;

    button {
      border-radius: 126px;
      font-family: var(--font-display);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      padding: 16px 24px;
    }

    .danger {
      background: transparent;
      color: #572580;

      &:hover {
        background: var(--color-buy-credits);
        color: white;
      }
      margin-right: 32px;
    }
  }
`;
