import React from "react";
import { Link } from "react-router-dom";

import { Loading } from "Utils/Loading";

import Input from "Components/Form/Input";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { getUsersLabi } from "../../Requests/LabiCredits";
import { createToast } from "../../Utils/toastFunc";

const types = [
  { id: "", name: "Buscar por:" },
  { id: "name", name: "Nome" },
  { id: "email", name: "Email" },
  { id: "cpf", name: "CPF" },
];

export default function LabiWallet() {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({});

  const [typeSearch, setTypeSearch] = React.useState();
  const [value, setValue] = React.useState("");

  async function SubmitSearch(e) {
    e.preventDefault();
    try {
      if (!typeSearch?.id)
        return createToast("error", "Selecione um tipo de busca");
      if (!value)
        return createToast("error", "O campo de texto não deve ser vazio");

      setPagination({});
      await fetchData(pagination.current_page);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao realizar a busca");
    }
  }

  async function ClearSearch() {
    setPagination({});
    setTypeSearch(null);
    setValue("");
    setUsers([]);
  }

  async function fetchData(page, clear) {
    try {
      setLoading(true);
      const { promoters, pagination } = await getUsersLabi(
        page,
        !clear ? { [typeSearch?.id]: value || null } : null
      );
      setUsers(promoters);
      setPagination(pagination);
    } catch (error) {
      console.log(error);
      createToast("error", "Ocorreu um erro ao buscar os usuários");
    } finally {
      setLoading(false);
    }
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="7" className="text-center">
          <span className="loading-persons">
            <Loading />
          </span>
        </td>
      </tr>
    );
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder">
        <div className="page-heading inputs-solid">
          <div className="wrapper-header-content">
            <PageHeader title="Carteira Labi">
              <Breadcrumb
                list={[
                  {
                    path: "/labi-creditos",
                    label: "Labi Créditos",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Carteira do Labi Creditos",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
          </div>
          <form
            className="flex-items generate-margin-1"
            onSubmit={SubmitSearch}
          >
            <div className="select-search-submit">
              <div className="select-search-submit-types">
                <SelectSearch
                  id="calendars_id"
                  type="text"
                  value={typeSearch}
                  error=""
                  isMulti
                  isMoreSelect={false}
                  options={types}
                  loading={!types.length}
                  readOnly={!types.length}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Buscar por:"
                  onChange={(value) => {
                    if (!value.id) setValue("");
                    setTypeSearch(value);
                  }}
                />
              </div>

              <Input
                mask={typeSearch?.id === "cpf" ? "999.999.999-99" : ""}
                placeholder="Buscar por nome, CPF, e e-mail ou ID do pedido"
                value={value}
                onChange={({ target }) => setValue(target.value)}
              />
            </div>
            <button className="button" type="submit">
              Buscar
            </button>
            <button className="button _white" onClick={ClearSearch}>
              Limpar
            </button>
          </form>
        </div>
        <div className="page-content page-content-list">
          <table className="table-content small-separate details">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nome</th>
                <th>cpf</th>
                <th>email</th>
                <th>Ver</th>
              </tr>
            </thead>
            <tbody>
              {!loading && !typeSearch && users.length < 1 && (
                <tr>
                  <td colSpan="7" className="text-center text-purple">
                    Busque os dados através dos filtros acima !
                  </td>
                </tr>
              )}

              {!loading && typeSearch && users.length < 1 && (
                <tr>
                  <td colSpan="7" className="text-center text-purple">
                    Oops! Parece que nada foi encontrado...
                  </td>
                </tr>
              )}

              {!loading && users.length >= 1 && (
                <>
                  {users.map((item) => (
                    <tr key={item.external_id}>
                      <td>{item.external_id}</td>
                      <td>
                        {item.name ? item.name : "Usuário não encontrado"}
                      </td>
                      <td>{item.cpf ? item.cpf : "CPF não encontrado"}</td>
                      <td>
                        {item.email ? item.email : "Email não encontrado"}
                      </td>
                      <td className="table-action-order">
                        <Link
                          to={{
                            pathname: `/labi-creditos/minha-conta/${item.external_id}`,
                            state: {
                              username: item.name
                                ? item.name
                                : "usuário não encontrada",
                            },
                          }}
                          className="button _blue _action"
                          type="button"
                        >
                          <span className="details-text">Ver Carteira</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              )}

              {loading && _showLoading()}
            </tbody>
          </table>
        </div>
      </div>
    </PageWrapper>
  );
}
