import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  cognitoStartSession,
  getCognitoAuthToken,
  isCognitoAuthenticated,
} from "Services/Cognito";

import { ScheduleATime } from "Requests/ClinicalSchedule";
import { CreateOrder } from "Requests/Order";
import { DestroyOrder } from "Requests/Orders";

import { handleClearPlots } from "Actions/ActionsCreators";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { hasPathInArray } from "Utils/Arrays";
import { searchForErrorSatus } from "Utils/Errors";
import { FakePromise } from "Utils/Promise";
import { createToast } from "Utils/toastFunc";

import { ModalInformation } from "Components/Modals/ModalInformation/index";
import { ModalSelectNewDate } from "Components/Modals/ModalSelectNewDate";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import { PaymentFormsForm } from "Components/ServiceForm/PaymentFormsForm";
import Breadcrumb from "Components/Shared/Breadcrumb";

import AcceptIcon from "Assets/Images/acceptIcon.svg";

import CartDetails from "../components/Cart/CartDetails";

const form = {
  name: "",
  email: "",
  phone: "",
  gender: "",
  birthday: "",
  type_document: "",
  document: "",
  country: "",
};

const PaymentFormsSelect = () => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [orderId, setOrderId] = useState();
  const [openModalNewSchedule, setOpenModalNewSchedule] = useState(false);
  const [loadingNewSchedule, setLoadingNewSchedule] = useState(false);
  const formRef = useRef();

  const history = useHistory();
  const { budget_id } = useParams();

  const dispatch = useDispatch();

  const { budget } = useBudgetContext();

  const handleToggleModal = () => {
    setConfirmModalVisible(!confirmModalVisible);
  };

  async function chooseNewSchedule(orderId) {
    setLoadingNewSchedule(true);
    try {
      await DestroyOrder(orderId);

      await FakePromise(2000);

      history.push(`/pedido/agendar/${budget_id}`);
    } catch (error) {
      createToast("error", "Não foi possível excluir seu pedido.");
      history.push("/");
    } finally {
      setLoadingNewSchedule(false);
    }
  }

  function validateError(errorResponse) {
    if (errorResponse) {
      const errorMessage = errorResponse[0].message;
      const messages = [
        "Time is no longer available.",
        "Appointment isn't valid anymore",
      ];

      return hasPathInArray(messages, errorMessage);
    }
  }

  async function onSubmit(order) {
    let orderId = "";
    try {
      setIsPaymentLoading(true);

      if (!isCognitoAuthenticated()) {
        const cognitoToken = await getCognitoAuthToken();
        cognitoStartSession(cognitoToken);
      }

      const unit = JSON.parse(localStorage.getItem("@scheduleUnitId"));
      const unit_id =
        unit.id === "tele-medicine"
          ? process.env.REACT_APP_TELEMEDICINE_UNITY_ID ?? ""
          : unit.id;

      const orderWithUnit = {
        ...order,
        unit_id,
      };

      const schedulePayload = JSON.parse(
        localStorage.getItem("@schedulePayload")
      );

      const selectedContacts = budget?.selected_contacts;
      const contacts = {};

      if (selectedContacts?.contact) contacts.email = selectedContacts.contact;
      if (selectedContacts?.phone) contacts.celular = selectedContacts.phone;

      if (!schedulePayload.birthdate || schedulePayload.birthdate === "--") {
        createToast("error", "A pessoa não tem uma data de nascimento válida!");
        return;
      }

      if (schedulePayload.email === "" && !contacts.email) {
        createToast("error", "O email do usuário está inválido!");
        return;
      }

      const orderData = await CreateOrder(orderWithUnit);
      orderId = orderData.data.id;
      setOrderId(orderData.data.id);

      if (!orderData.success) {
        setIsPaymentLoading(false);
        createToast(
          "error",
          orderData.data.message ?? orderData.data.budget_id
        );
        return;
      }

      // Remove old object from object
      delete schedulePayload.dataNascimento;

      if (selectedContacts?.phone) contacts.celular = selectedContacts.phone;

      await ScheduleATime({
        ...schedulePayload,
        ...contacts,
        channel_id: 2,
        person_id: Number(schedulePayload.person_id),
        order_id: Number(orderData.data.id),
      });

      setIsPaymentLoading(false);
      handleToggleModal();
      dispatch(handleClearPlots());
    } catch (err) {
      const errorFounded = searchForErrorSatus(err, 500);

      if ((!err.response || errorFounded) && orderId) {
        await DestroyOrder(orderId);
        return createToast(
          "error",
          "Não foi possível realizar o agendamento. Tente novamente."
        );
      }

      const {
        data: { errors },
      } = err.response;

      const errorValidated = validateError(errors);

      if (errorValidated) {
        return setOpenModalNewSchedule(true);
      }

      createToast(
        "error",
        "Não foi possível realizar o agendamento :/, Tente novamente mais tarde."
      );
    } finally {
      setIsPaymentLoading(false);
    }
  }

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder page-budget">
        <div className="page-heading with-cart">
          <div className="wrapper-header-content m-bottom-1 order-budget">
            <PageHeader title="Formas de pagamento" isBudget>
              <Breadcrumb
                list={[
                  {
                    path: "/orcamento",
                    label: "Orçamento",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "Criar novo",
                    current: false,
                  },
                  {
                    path: "#",
                    label: "FInalizar cadastro",
                    current: true,
                  },
                ]}
              />
              <p className="register-form-sub-title">
                Selecione a forma de pagamento
              </p>
            </PageHeader>
          </div>
        </div>

        <div className="cart-order-budget">
          <PaymentFormsForm
            initialValues={form}
            budget_id={budget_id}
            price={
              budget?.totalDisplay &&
              parseFloat(budget?.totalDisplay.replace(",", "."))
            }
            onSubmit={onSubmit}
            ref={formRef}
            isLoading={isPaymentLoading}
          />

          <CartDetails />
        </div>
      </div>

      <ModalSelectNewDate
        isOpen={openModalNewSchedule}
        onClick={() => chooseNewSchedule(orderId)}
        loading={loadingNewSchedule}
      />
      <ModalInformation
        modalVisible={confirmModalVisible}
        modalDismiss={handleToggleModal}
        title="Quase lá!"
        icon={AcceptIcon}
        message="Seu pedido está quase finalizado, agora só falta revisá-lo e envia-lo para o sistema Amplimed."
        messageButton="REVISAR PEDIDO"
        onClick={() => {
          history.push(`/pedido/revisar/${orderId}`);
        }}
      />
    </PageWrapper>
  );
};

export default PaymentFormsSelect;
