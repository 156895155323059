import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";

import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { CardProduct } from "Components/Cards/CardProduct";
import Select from "Components/Form/Select";
import { Modal } from "Components/Modals/Modal";
import { ModalConfirm } from "Components/Modals/ModalConfirm";

import AtentionIcon from "Assets/Images/atention.svg";

import { ProductsResultSearchProps } from "./interface";
import { ContainerProductList } from "./styles";
import { useCartAmount } from "./useCartAmount";

export const ProductsResultSearch = ({
  openProductDetails,
  productList,
  addDetailButton,
}: ProductsResultSearchProps) => {
  const {
    handleAddProduct,
    partnersOptions,
    handleRemoveToCart,
    closeModal,
    modal,
    productSelected,
  } = useCartAmount();

  const {
    loadingCart,
    toggleLoadingCartState,
    setPartnerSelected,
    partnerSelected,
  } = useBudgetContext();

  function goToTypeForm() {
    window.open("https://labiexames.typeform.com/to/ct1hTzh9");
    closeModal("unavailable");
  }

  const handleChangePartner = (value: React.ChangeEvent<HTMLInputElement>) => {
    setPartnerSelected(Number(value.target.value));
  };

  const handleConfirmPartnerSelection = async () => {
    if (!partnerSelected) {
      createToast("warning", "Selecione um parceiro");
      return;
    }

    if (productSelected) {
      toggleLoadingCartState(true);
      await handleAddProduct(productSelected);
      toggleLoadingCartState(false);
    }
  };

  return (
    <>
      <ContainerProductList className="products-results">
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th>PRODUTO</th>
              <th>SINONÍMIA</th>
              <th>STATUS/VALOR</th>
              <th>AÇÕES</th>
            </tr>
          </thead>

          <tbody>
            {productList.map((product) => (
              <CardProduct
                key={product.id}
                product={product}
                addToCart={() => handleAddProduct(product)}
                removeToCart={() => handleRemoveToCart(product)}
              >
                {addDetailButton && (
                  <Button
                    outlined
                    onClick={() => openProductDetails(product.id)}
                  >
                    {" "}
                    Saiba Mais
                  </Button>
                )}
              </CardProduct>
            ))}
          </tbody>
        </table>
      </ContainerProductList>

      <ModalConfirm
        modalVisible={modal.unavailable}
        modalDismiss={() => closeModal("unavailable")}
        handleClick={goToTypeForm}
        text="Adicionar"
        title="Este produto está no momento está em lista de espera, deseja adicionar o cliente a lista?"
      />

      <Modal
        isOpen={modal.partnerSelect}
        requestClose={() => closeModal("partnerSelect")}
        closeOverlay
        modalIcon={AtentionIcon}
        renderHeading={() => <h2>Selecione o parceiro.</h2>}
        renderActions={() => (
          <Box>
            {/* @ts-ignore */}
            <Select
              label="Parceiro"
              id="type"
              value={partnerSelected}
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                handleChangePartner(value)
              }
              options={partnersOptions}
            />
            <Box display="flex" justify="center" mt="30">
              <Button
                onClick={handleConfirmPartnerSelection}
                loading={loadingCart}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        )}
      />
    </>
  );
};
