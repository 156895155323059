import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 19px 39px;

  h2 {
    font-size: 24px;
    line-height: 38px;
    border-bottom: 1px solid rgba(195, 195, 195, 0.5);
    color: var(--color-purple);
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .buttons-action {
    display: flex;
    gap: 37px;
    justify-content: flex-end;

    svg {
      margin-top: 7px;
    }
  }

  .status {
    grid-column: 1/2;
  }
  .category {
    grid-column: 2/3;
  }
  .identification {
    grid-column: 1/3;
  }
  .composition {
    grid-column: 1/3;
  }

  .banner {
    grid-column: 1/3;
  }
  .description {
    grid-column: 1/3;
  }
  .restriction {
    grid-column: 1/3;
  }
  .prepare {
    grid-column: 1/3;
  }
  .anamnesis {
    grid-column: 1/3;
  }

  .content-holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, auto);
    column-gap: 20px;
    row-gap: 24px;
    justify-content: space-between;

    box-sizing: border-box;

    .heading {
      font-family: var(--font-text);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--color-bold-gray);
      display: block;
      white-space: nowrap;
    }
  }
`;

interface RowContentProps {
  mt?: string;
  mb?: string;
  maxWidth?: string;
}

export const RowContent = styled.span<RowContentProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: var(--font-text);
  color: var(--color-bold-gray);
  display: block;
  ${({ maxWidth }) => css`
    max-width: ${maxWidth ? `${maxWidth}px` : "initial"};
  `}
  ${({ mb }) => css`
    margin-bottom: ${mb || "17"}px;
  `}
  ${({ mt }) => css`
    margin-top: ${mt || "0"}px;
  `}
`;

export const LinkTo = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;
