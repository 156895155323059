import { unmaskCPF } from "./Masks";
import { validatorCPF, validatorEmail } from "./Validator";

const isEmpty = (value) => {
  return value === "";
};

const ValidatorFields = (fieldName, value, required) => {
  let field = {
    error: "",
    value,
  };

  switch (fieldName) {
    case "email": {
      const emailValid = validatorEmail(value);
      field = {
        ...field,
        error: required
          ? isEmpty(value)
            ? "Campo obrigatório"
            : !emailValid
            ? "O Email não é válido"
            : ""
          : "",
      };
      break;
    }

    case "password": {
      const passwordValid = value.length >= 5; // temp return to 6
      field = {
        ...field,
        error: required
          ? isEmpty(value)
            ? "Campo obrigatório"
            : !passwordValid
            ? "A senha deve ter no mínimo 5 caracteres" // temp return to 6
            : ""
          : "",
      };

      break;
    }

    case "cpf2":
      {
        const cpfValid = validatorCPF(unmaskCPF(value));

        field = {
          ...field,
          error: required
            ? isEmpty(value)
              ? "Campo obrigatório"
              : !cpfValid
              ? "Digite um CPF válido"
              : ""
            : !cpfValid
            ? "Digite um CPF válido"
            : "",
        };
      }
      break;

    default:
      break;
  }

  return field;
};

export default ValidatorFields;
