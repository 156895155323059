import React from "react";

import { HeaderProps } from "./interface";
import { HeaderContainer } from "./style";

const PageHeader = ({
  children,
  title,
  className = "",
  icon = undefined,
  isBudget = false,
}: HeaderProps) => {
  return (
    <HeaderContainer
      className={`page-header ${className} ${isBudget && "with-cart"}`}
    >
      <div className="flex-header">
        {icon ? <img src={icon} alt="" /> : null}
        <h2 className="h5 text-gray text-light" style={{ fontWeight: 400 }}>{title}</h2>
      </div>
      {children}
    </HeaderContainer>
  );
};

export default PageHeader;
