import { useBudgetContext } from "Hooks/useBudgetContext";

import {
  separateAttendments,
  separateCovenantAttendment,
} from "Utils/Attendments";

import { AttendmentItem } from "../AttendmentItem";
import { AttendmentCollect } from "../CartDetails/interface";

export const AttendmentsCartList = () => {
  const { budget, zipCode, isCovenantUnitAttendance } = useBudgetContext();

  const { is_covenant } = budget ?? {};

  let attendments: AttendmentCollect[] = [];

  if (is_covenant) {
    if (zipCode) {
      attendments = separateCovenantAttendment(
        budget?.cart,
        budget?.budget_type,
        "home_collect"
      );
    }

    if (isCovenantUnitAttendance) {
      attendments = separateCovenantAttendment(
        budget?.cart,
        budget?.budget_type,
        "unity_collect"
      );
    }
  }

  if (!is_covenant) {
    attendments = separateAttendments(budget?.cart, budget?.budget_type);
  }

  return (
    <>
      {attendments.map((attendment) =>
        attendment.cartAttendments.length >= 1 ? (
          <AttendmentItem attendment={attendment} key={attendment.type} />
        ) : null
      )}
    </>
  );
};
