import { Container, Loader } from "./styles";

interface LoaderProps {
  color?: string;
  size?: number;
  hoverColor?: string;
  style?: React.CSSProperties;
  width?: number;
}

export function Spinner({
  color = "#572580",
  size = 20,
  hoverColor = "#fff",
  style = {},
  width = 2,
}: LoaderProps) {
  return (
    <Container style={style} size={size} hoverColor={hoverColor} color={color}>
      <Loader size={size} width={width} />
    </Container>
  );
}
