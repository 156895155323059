export const FormatterUser = (data) => {
    if (!(data.units.length)) return data;
    const _units = data.units?.map((unit) => unit.id);
    let _data = {};
    Object.keys(data).forEach((name) => {
        if (name === "units") {
            _data = {
                ..._data,
                units: _units,
            };
            return;
        }
        _data = {
            ..._data,
            [name]: data[name],
        };
    });
    return _data;
};
