import styled, { css } from "styled-components";

export const TitleAccordion = styled.p`
  margin: 0;
  padding: 0;

  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;

  letter-spacing: 0.5px;

  color: var(--color-purple);
`;

export const HeaderAccordion = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  img {
    transform: rotate(180deg);
    margin-bottom: 13px;
  }

  ${(props) =>
    props?.open &&
    css`
      img {
        transform: rotate(0);
      }
    `}

  ${(props) =>
    props?.notCenter &&
    css`
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
    `}
`;

export const ContainerAccordion = styled.section`
  padding: 0.563rem 1rem 1.063rem;

  background: #ffffff;

  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  border-radius: 16px;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: none;
    `}

  min-width: 215px;
  width: 100%;
  margin-bottom: 24px;
`;

export const ContentInformationAccordion = styled.div`
  animation: fadeIn 0.8s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
