import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getUsersList, deleteUser, clearFilters } from "Requests/Users";

import validatePermissions from "Utils/validatePermissions";

import { initialStateMessages } from "mocks";

import ConfirmAlert from "Components/Alert/ConfirmAlert";
import DefaultProvider from "Components/DefaultProvider/index";
import Input from "Components/Form/Input";
import { Pagination } from "Components/Pagination";

import LoadingImage from "Assets/Images/loading-purple.gif";

const ListUsers = () => {
  const user_permissions = useSelector((state) => state.User.permissions);
  const user_logged = useSelector((state) => state.User.userData);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState("");
  const [pagination, setPagination] = useState({});
  const [messages, setMessages] = useState(initialStateMessages);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      Object.keys(messages).some(
        (item) => item !== "wait" && messages[item].length
      )
    ) {
      setTimeout(() => {
        setMessages && setMessages(initialStateMessages);
      }, 3000);
    }
  }, [messages]);

  async function fetchClearFilters() {
    setLoading(true);
    setPagination({});
    setValue("");

    try {
      const _items = await clearFilters();

      setUsers(_items.data);
      setPagination(_items.pagination);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  async function fetchData(page, params) {
    setLoading(true);
    !page && setPagination({});

    try {
      const _items = await getUsersList(page, params);

      setUsers(_items.data);
      setPagination(_items.pagination);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  function _showLoading() {
    return (
      <tr>
        <td colSpan="5" className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }

  function _tableList() {
    return !users.length ? (
      <tr>
        <td colSpan="5" className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {users.map((user) => (
          <tr key={user.id}>
            <td>{user.name}</td>
            <td>{user.email || "Não informado"}</td>
            <td>{user.roles.length ? user.roles[0].name : "Não informado"}</td>
            <td className="text-right table-action">
              {validatePermissions("Update Users", user_permissions) && (
                <Link
                  to={`/usuarios/${user.id}`}
                  className="button _blue _action"
                  type="button"
                >
                  <span className="details-text">Editar</span>
                </Link>
              )}
              {validatePermissions("Inactive Users", user_permissions) && (
                <button
                  disabled={!!user.deleted_at || user_logged.id === user.id}
                  className="button _yellow _action"
                  type="button"
                  onClick={() => {
                    ConfirmAlert(
                      user,
                      _inactivationUserLayout,
                      handleUserInactivation
                    );
                  }}
                >
                  <span className="details-text">
                    {!user.deleted_at ? "Inativar" : "Inativado"}
                  </span>
                </button>
              )}
            </td>
          </tr>
        ))}
      </>
    );
  }

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Nível de Acesso</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && _showLoading()}
          {!loading && _tableList()}
        </tbody>
      </table>
    );
  }

  function _renderMessages({ success, wait, error }) {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  }

  function _inactivationUserLayout({ id, name, email }, onClose, onSubmit) {
    return (
      <div className="custom-ui">
        <h5 className="h5 text-gray text-light">
          Você tem certeza que deseja inativar esse usuário?
        </h5>
        <div className="informations-ui">
          <strong>Nome: </strong>
          <p>{name}</p>
          <strong>Email: </strong>
          <p>{email || "Não informado"}</p>
        </div>
        <div className="wrapper-buttons">
          <button className="link link-danger" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="button _action"
            onClick={() => {
              onSubmit(id);
              onClose();
            }}
          >
            Sim
          </button>
        </div>
      </div>
    );
  }

  function handlePagination(e) {
    if (pagination.current_page === e.currentPage) return;

    fetchData(e.currentPage);
  }

  async function handleUserInactivation(id) {
    setMessages({
      sucess: "",
      wait: "Inativando usuário...",
      error: "",
    });

    try {
      await deleteUser(id);

      setMessages({
        success: "Usuário inativado com sucesso!",
        wait: "",
        error: "",
      });

      fetchData(pagination.current_page);
    } catch (error) {
      setMessages({
        success: "",
        wait: "",
        error: "Ocorreu um erro durante a inativação do usuário!",
      });
    }
  }

  const searchUsers = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!value)
        return setMessages({
          sucess: "",
          error: "Você deve passar um valor no campo de busca",
        });

      await fetchData(pagination.currentPage, { filter: value });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumb = [
    {
      path: "/dashboard",
      label: "Dashboard",
      current: false,
    },
    {
      path: "#",
      label: "Todos os usuários",
      current: true,
    },
  ];

  const rightHeaderComponent = (
    <div className="actions-holder">
      {validatePermissions("Create Users", user_permissions) && (
        <Link to="/usuarios/0" className="button _white _action">
          Adicionar Novo
        </Link>
      )}
    </div>
  );

  const paginationWrapper = (
    <>
      {!loading && (
        <Pagination
          totalRecords={pagination?.total_pages}
          currentPageSelected={pagination?.current_page}
          pageLimit={1}
          pageNeighbours={1}
          onPageChanged={(e) => handlePagination(e)}
        />
      )}
    </>
  );

  return (
    <>
      <DefaultProvider
        pageHeaderTitle="Todos os usuários"
        breadcrumb={breadcrumb}
        haveComponentOnHeader
        rightHeaderComponent={rightHeaderComponent}
        paginationWrapper={paginationWrapper}
      >
        <>
          <div className="order-home-filter mt-1">
            <form className="select-search-submit" onSubmit={searchUsers}>
              <Input
                placeholder="Buscar por nome ou email"
                value={value}
                onChange={({ target }) => setValue(target.value)}
              />
              <button className="button" type="submit">
                Buscar
              </button>
              <button className="button _white" onClick={fetchClearFilters}>
                Limpar
              </button>
            </form>
          </div>
          {_renderMessages(messages)}
        </>
        <div className="page-content page-content-list">{_renderTable()}</div>
      </DefaultProvider>
    </>
  );
};

export default ListUsers;
