import styled, { css } from "styled-components";

interface UnitsProps {
  height: number;
}

export const UnitsContainer = styled.div<UnitsProps>`
  ${({ height }) => css`
    height: ${height}px;
  `}
`;

export const Container = styled.div`
  margin-top: 16px;
  .restrictions-attendance div {
    gap: 30px;
    margin-top: 4px;
  }

  .restrictions-unities {
    margin-bottom: 17px;
    div {
      flex-direction: column;
      flex-wrap: wrap;

      ul {
        list-style-type: none;
        align-self: flex-start;
        font-size: 14px;
        padding: 0px;
        padding-left: 20px;
        margin-top: 8px;

        color: #666;
        font-family: var(--font-text);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;

        li {
          margin-left: 15px;
          margin-bottom: 6px;
          color: #666;
          font-family: var(--font-text);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
        }
      }
    }
  }

  .restrictions-schedule {
    margin-top: 17px;
    thead th {
      color: var(--color-gray-text);
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      width: 200px;
    }

    tbody td {
      color: var(--color-gray-text);
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      width: 200px;
    }
    margin-bottom: 17px;
  }

  .restrictions-additional-info {
    justify-content: space-between;
  }
`;
