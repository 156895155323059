import LoadingPurple from "Assets/Images/tinyficated/loading-purple.gif";
import { SliderDates } from "../SliderDate";
import { CollectCallendarProps } from "./types";
import { TitleCheckoutCollect } from "../../../style";
import { SliderHour } from "../SlideHour";

export const CollectCallendar = ({
  dates,
  hours,
  appointment,
  loadingDate,
  loadingHours,
  handleSelectDate,
  handleSelectHour,
}: CollectCallendarProps) => {


  return (
    <>
      <TitleCheckoutCollect>Qual a melhor data para você?</TitleCheckoutCollect>

      {loadingDate ?
        <div className="wrapper-loading">
          <img
            className="loading loading-small loading-align-center"
            src={LoadingPurple}
            alt="Labi Saúde - Carregando"
            loading="lazy"
          />
        </div>
        : <SliderDates
          handleSelect={handleSelectDate}
          appointment={appointment}
          dates={dates}
          loadingDate={loadingDate}
          type="date"
          query="query"
        />}


      {loadingHours ?
        <div className="wrapper-loading">
          <img
            className="loading loading-small loading-align-center"
            src={LoadingPurple}
            alt="Labi Saúde - Carregando"
            loading="lazy"
          />
        </div> :
        <>
        </>}

      {appointment.date && !loadingHours && hours.length > 0 ? <>
        <TitleCheckoutCollect>E qual o melhor horário?</TitleCheckoutCollect>
        <SliderHour
          handleSelect={handleSelectHour}
          appointment={appointment}
          hours={hours}
          loadingHour={loadingHours}
          type="hour"
          query="query"
        /></> : <></>}
    </>
  )

}