import { Field, Form, Formik } from "formik";
import { Box } from "styles/globalComponents";

import { GENDERS } from "Constants/Combos";

import { validateArrayType } from "Utils/Arrays";

import { docType } from "mocks";

import { Button } from "Components/Button";
import Input from "Components/Form/Input";
import { InputPhone } from "Components/Form/PhoneInput";
import Select from "Components/Form/Select";
import { ScrollToFieldError } from "Components/ScrollFiedError";

import { CovenantInputFields } from "../CovenantInputFields";
import { FormRegisterValues } from "../interface";
import { schemaRegister } from "../schemas";
import { CompleteRegisterProps } from "./interface";
import { Container } from "./style";

export const CompleteRegisterForm = ({
  closeModalUpdate,
  onSubmit,
  clientInitialValues,
  combos,
}: CompleteRegisterProps) => {
  const { countries, genders } = combos;

  const {
    cpf,
    passport,
    birthdate,
    country,
    covenant,
    doc_type,
    document,
    email,
    gender,
    name,
    phone,
    plan,
    plan_card_number,
    social_name,
  } = clientInitialValues;

  const initialValuesForm = {
    birthdate,
    country,
    covenant,
    doc_type,
    document,
    email,
    gender,
    name,
    phone,
    plan,
    plan_card_number,
    social_name,
  };

  const submit = async (
    values: FormRegisterValues,
    setFieldError: (field: string, message: string) => void
  ) => {
    try {
      await onSubmit(values);
    } catch (errors: any) {
      if (errors.response.data.errors) {
        errors.response.data.errors.forEach((error: any) => {
          setFieldError(error.field, error.message);
        });
      }
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValuesForm}
        onSubmit={(values, { setFieldError }) =>
          submit(values, setFieldError).then().catch()
        }
        validationSchema={schemaRegister}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          setFieldValue,
          initialValues,
          isSubmitting,
        }) => {
          const docMask = values.doc_type === "cpf" ? "999.999.999-99" : null;

          const handleChangeDocType = (e: React.ChangeEvent<any>) => {
            const currentDocType = e.target.value;
            setFieldValue("doc_type", currentDocType);

            if (currentDocType === "cpf" && cpf) {
              setFieldValue("document", cpf);
              return;
            }

            if (currentDocType === "passport" && passport) {
              setFieldValue("document", passport);
              return;
            }

            if (currentDocType === initialValues.doc_type) {
              setFieldValue("document", initialValues.document);
              return;
            }
            setFieldValue("document", "");
          };

          const covenantErrors = {
            plan: errors.plan,
            covenant: errors.covenant,
            plan_card_number: errors.plan_card_number,
          };

          const covenantTouched = {
            plan: touched.plan,
            covenant: touched.covenant,
            plan_card_number: touched.plan_card_number,
          };

          const covenantValues = {
            plan: values.plan,
            covenant: values.covenant,
            plan_card_number: values.plan_card_number,
          };

          return (
            <Form className="form-container">
              <ScrollToFieldError />
              <div className="form-items">
                <h3>DADOS DE IDENTIDADE</h3>

                <div className="form-item">
                  <Field
                    name="name"
                    id="name"
                    label="NOME COMPLETO*"
                    component={Input}
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && errors.name && errors.name}
                    placeholder="Digite o nome completo"
                  />
                </div>

                <div className="form-item">
                  <Field
                    name="gender"
                    id="gender"
                    label="GÊNERO*"
                    options={genders}
                    value={values.gender}
                    optionName="sub_gender"
                    readOnly={!validateArrayType(genders)}
                    onChange={handleChange}
                    component={Select}
                    placeholder="Selecione o gênero"
                    error={touched.gender && errors.gender && errors.gender}
                  />
                </div>

                {[GENDERS.trans, GENDERS.undefined].includes(values.gender) && (
                  <div className="form-item">
                    <Field
                      name="social_name"
                      id="social_name"
                      label="COMO PODEMOS TE CHAMAR ?*"
                      component={Input}
                      value={values.social_name}
                      onChange={handleChange}
                      error={
                        touched.social_name &&
                        errors.social_name &&
                        errors.social_name
                      }
                      placeholder="Digite o nome social"
                    />
                  </div>
                )}

                <div className="form-item">
                  <Field
                    name="birthdate"
                    id="birthdate"
                    label="DATA DE NASCIMENTO*"
                    mask="99/99/9999"
                    component={Input}
                    value={values.birthdate}
                    onChange={handleChange}
                    error={
                      touched.birthdate && errors.birthdate && errors.birthdate
                    }
                    placeholder="Digite a data de nascimento"
                  />
                </div>

                <div className="form-item">
                  <Field
                    name="doc_type"
                    id="doc_type"
                    label="TIPO DE DOCUMENTO*"
                    options={docType}
                    value={values.doc_type}
                    optionName="type"
                    onChange={(e: React.ChangeEvent<any>) =>
                      handleChangeDocType(e)
                    }
                    component={Select}
                    placeholder="Selecione o tipo de documento"
                    error={
                      touched.doc_type && errors.doc_type && errors.doc_type
                    }
                  />
                </div>

                {values.doc_type === "passport" && (
                  <div className="form-item">
                    <Field
                      name="country"
                      id="country"
                      label="PAÍS DE EMISSÃO*"
                      options={countries}
                      value={values.country}
                      optionName="name"
                      onChange={handleChange}
                      component={Select}
                      placeholder="Selecione o país de emissão"
                      error={
                        touched.country && errors.country && errors.country
                      }
                    />
                  </div>
                )}

                <div className="form-item">
                  <Field
                    name="document"
                    id="document"
                    label="NÚMERO DE DOCUMENTO*"
                    component={Input}
                    mask={docMask}
                    value={values.document}
                    onChange={handleChange}
                    error={
                      touched.document && errors.document && errors.document
                    }
                    placeholder="Digite o número de documento"
                  />
                </div>

                <h3>DADOS DE IDENTIDADE</h3>

                <div className="form-item">
                  <Field
                    name="email"
                    id="email"
                    label="E-MAIL*"
                    type="email"
                    component={Input}
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && errors.email && errors.email}
                    placeholder="usuario@usuario.com.br"
                  />
                </div>

                <div className="form-item">
                  <Field
                    id="phone"
                    label="TELEFONE*"
                    placeholder="55 (11) 99999-1111"
                    component={InputPhone}
                    value={values.phone}
                    onChange={(phone: string) => setFieldValue("phone", phone)}
                    error={touched.phone && errors.phone && errors.phone}
                  />
                </div>

                <h3>DADOS DO CONVÊNIO</h3>

                <CovenantInputFields
                  errors={covenantErrors}
                  touched={covenantTouched}
                  values={covenantValues}
                  onChange={setFieldValue}
                />
              </div>

              <Box display="flex" direction="column" mt="16">
                <Button
                  width="full"
                  minHeight="48"
                  type="submit"
                  loading={isSubmitting}
                >
                  SALVAR CADASTRO
                </Button>

                <Button
                  width="full"
                  minHeight="48"
                  outlined
                  onClick={closeModalUpdate}
                  loading={isSubmitting}
                >
                  CANCELAR
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
