import API from "Services/Api";

import { FormatterList, FormatterComboList, FormatterDetail } from "./Formatters/Reports";

export const GetReports = async (filter, page = '&page=1') => {
  try {
    const response = await API.get(`/reports/pacient${filter}${page}`);

    return {
      status: response.status,
      data: response.data.data ? FormatterList(response.data.data) : [],
      meta: response.data.meta
    }
  } catch (error) {
    return {
      status: error.response.status,
      data: [],
      meta: {}
    };
  }
}

export const GetReportsDetail = async code => {
  try {
    const response = await API.get(`/reports/detail/${code}`);

    return {
      status: response.status,
      data: FormatterDetail(response.data)
    }
  } catch (error) {
    return {
      status: error.response.status,
      data: {}
    };
  }
}

export const GetComboReports = async () => {
  try {
    const response = await API.get(`/reports/pacient/data/combolist`);

    return {
      status: response.status,
      data: FormatterComboList(response.data)
    }
  } catch (error) {
    return {
      status: error.response.status,
      data: {}
    };
  }
}

export const SendReports = async code => {
  try {
    const response = await API.get(`/reports/sendmail/requisition/${code}`);

    return {
      success: true,
      status: response.status,
      message: "E-mail enviado com sucesso!",
    }
  } catch (error) {
    return {
      status: error.response.status,
      success: false,
      message: "Oops! Não foi possivel enviar o e-mail."
    };
  }
};