import styled, { css } from "styled-components";

interface ContainerProps {
  error?: boolean;
  small?: boolean;
  purple?: boolean;
  fullSize?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  .error-message {
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: #c23f3b;
  }

  label {
    min-height: 22px;

    display: flex;
    align-items: center;
  }

  .checkbox-holder {
    height: 44px;
    font-size: 10px;
  }

  .select-field-holder {
    position: relative;

    .arrow-icon {
      transform: scaleY(0.65) scaleX(1.3) rotate(90deg);
      transition: transform 0.3s ease-in-out;
      display: inline-block;
      font-size: 10px;
      color: #666;
      margin-left: 13px;
      position: absolute;
      top: 16px;
      right: 10px;
      pointer-events: none;
    }
  }

  ${(props) =>
    props.error &&
    css`
      &.checkbox-field {
        .error-message {
          font-size: 16px;
          left: 5px;
          right: auto;
          bottom: -5px !important;
        }
      }

      .error-message {
        position: absolute;

        right: 8px;

        &.error-without-label {
          bottom: 49px;
        }
      }

      .input {
        border-color: #c23f3b;
        background-color: rgba(224, 159, 157, 0.25);

        .react-select__control {
          background-color: rgba(224, 159, 157, 0.25);

          .react-select__indicator-separator {
            background-color: #fff;
          }

          .react-select__indicator {
            svg {
              fill: #fff;
            }
          }
        }
      }
    `}

  ${(props) =>
    props.fullSize &&
    css`
      min-width: 40rem;

      @media screen and (max-width: 1564px) {
        min-width: 32rem;
      }

      @media screen and (max-width: 1564px) {
        min-width: 20rem;
      }

      @media screen and (max-width: 576px) {
        min-width: auto;
      }
    `}

  ${(props) =>
    props.small &&
    css`
      .checkbox-holder {
        height: 38px;
      }

      label {
        font-size: 10px;
        margin-bottom: 0;
      }

      input,
      select {
        font-size: 12px;
        line-height: 16px;
      }

      .switch-holder {
        height: 38px;
      }

      .select-field-holder {
        .arrow-icon {
          top: 12px;
        }
      }
    `}

  &.field-checkbox-holder {
    .checkbox-holder {
      position: relative;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 30px;
      white-space: normal;
      overflow: visible;
      text-overflow: unset;

      line-height: 16px;

      .box-check {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid #572580;
        position: absolute;
        left: 0;

        &:before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          background-color: #572580;
          position: relative;
          top: 2px;
          left: 2px;
          transform: scale(0);
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }
      }

      input {
        display: none !important;

        &:checked + .box-check {
          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }

    &.field-holder-block {
      > .text-gray {
        display: none;
      }
    }
  }

  &.field-holder-read {
    display: flex;
    flex-direction: column;

    .label-field {
      margin-bottom: 5px;
    }
  }

  &.field-holder-inline {
    flex-direction: row;
    align-items: center;

    .label-field {
      margin-bottom: 0px;
    }

    .value-field {
      margin-left: 15px;
    }
  }

  > label {
    font-size: 0.875rem;
    font-weight: 500;
    display: block;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 8px 8px;

    color: ${(props) => (props.purple ? "572580" : "#999")};
  }

  .input {
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 1px 2px -1px rgba(130, 136, 148, 0.48);
    border-radius: 8px;
    background-color: #fff;
    display: block;
    padding: 10px 12px;
    font-size: $font-size-body-md;
    line-height: 22px;
    color: $color-gray-text;
    text-align: left;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #fff;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      border: 1px solid rgba(187, 225, 250, 1) !important;
    }

    &::placeholder {
      color: rgba(153, 153, 153, 0.5);
    }

    &.textarea {
      height: 200px;
      resize: none;
    }

    &._lgray {
      color: $color-lgray-text;

      &::placeholder {
        color: $color-lgray-text;
      }
    }

    &._transparent {
      background-color: transparent;
    }

    &._invalid {
      border-color: #c23f3b;
    }

    &._checkbox {
      appearance: checkbox;
      box-shadow: none;
    }

    &.-hidden-checkbox {
      opacity: 0;
    }

    &.select {
      padding-right: 22px;
    }

    &._border-purple {
      border: 1px solid $color-purple;
    }

    &._circle {
      border-radius: 30px;
    }

    &._sm {
      padding: 5px 145px 7px 20px;

      line-height: 18px;

      font-size: 13px;
    }

    &._number {
      width: 40px;

      padding: 4px 7px;

      text-align: center;

      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &._pagination {
      padding: 7px;
      min-width: 40px;
      text-align: center;
      line-height: 22px;
      background-color: white;
      border-color: $color-lgray-text;
      color: $color-lgray-text;
      font-size: 14px;

      &:hover {
        border-color: $color-purple;
        color: $color-purple;
      }
    }
  }

  .suggestion-wrapper {
    position: relative;

    margin-bottom: 20px;

    .suggestions-list {
      background-color: #eee;
      max-height: 200px;
      position: absolute;
      overflow: auto;
      width: 100%;
      z-index: 999;

      .suggestions {
        list-style: none;
        margin: 0;
        padding: 0;

        .suggestion {
          cursor: pointer;
          padding: 12px 10px;
          transition: all 0.3s ease-in-out;
          font-size: 10px;

          &:hover {
            background-color: #ddd;
          }
        }
      }

      .no-suggestions {
        > * {
          display: block;
          padding: 15px;
          text-align: center;
        }
      }
    }
  }
`;
