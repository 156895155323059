import { ChangeEvent, useEffect, useState } from "react";

import { MotionUnit } from "Dtos/IUnit";
import { Box } from "styles/globalComponents";

import { getUnitsUser } from "Requests/HomeOrder";

import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import SelectUnits from "Components/Form/SelectUnits";
import { Modal } from "Components/Modals/Modal";

import Attention from "Assets/Images/atention.svg";

interface MotionBudget {
  isOpen: boolean;
  closeModal: () => void;
  showUnitsOptions: boolean | undefined;
  motionIntegration: (id: number | null) => void;
}

export const MotionModal = ({
  isOpen,
  closeModal,
  motionIntegration,
  showUnitsOptions,
}: MotionBudget) => {
  const [unitIdMotion, setUnitId] = useState<null | number>(null);

  const [units, setUnits] = useState<[] | MotionUnit[]>([]);
  const [loading, setLoading] = useState(false);

  const disableButton = (showUnitsOptions && !unitIdMotion) || loading;

  async function fetchUnits() {
    try {
      setLoading(true);
      const res = await getUnitsUser();
      setUnits(res);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar as unidades!");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (showUnitsOptions) fetchUnits();
  }, []);

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Attention}
      renderActions={() => (
        <Box display="flex" direction="column" gap="48">
          {showUnitsOptions && (
            <Box>
              <SelectUnits
                placeholder="Selecione a unidade"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { id } = JSON.parse(event.target.value);
                  setUnitId(id);
                }}
                options={units.length >= 1 ? units : []}
                id="Unidades"
                label="SELECIONE A UNIDADE"
              />
            </Box>
          )}

          <Box>
            <Button
              width="full"
              minHeight="48"
              onClick={() => motionIntegration(unitIdMotion)}
              disabled={disableButton}
            >
              ENVIAR PARA O MOTION
            </Button>
            <Box mt="24" />
            <Button
              width="full"
              minHeight="48"
              outlined
              onClick={closeModal}
              disabled={loading}
            >
              CANCELAR
            </Button>
          </Box>
        </Box>
      )}
      renderHeading={() => <h2> Enviar orçamento para o Motion</h2>}
      renderDescription={() => (
        <p>
          <strong>Atenção!</strong> Após envio, essa ação não poderá ser
          desfeita.Se você não estiver vendo a unidade desajada, entre em
          contato com o suporte.
        </p>
      )}
    />
  );
};
