import styled from "styled-components";

export const ContainerDetailsInfo = styled.dl`
  dd,
  dt {
    margin: 0;
    padding: 0;
  }
  
  dt {
    font-family: var(--font-text);
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;

    color: var(--color-bold-gray);
  }

  dd {
    font-family: var(--font-display);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.375rem;

    color: var(--color-bold-gray);

    mix-blend-mode: normal;
  }
`;
