import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;

  .checkbox-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
    margin-top: 15px;
  }

  .partner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;

    .deadline-info {
      display: flex;
      gap: 16px;

      & .field-holder {
        width: 50%;
      }
    }

    button {
      align-self: flex-end;
      margin-bottom: 5px;
      margin-left: 5px;
      position: absolute;
      right: -20px;
    }
  }

  .sales-note-toggles {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .toggles {
      display: flex;
      gap: 10px;
    }
  }
`;
