import React from 'react';

export function CardHours({hours}){
    return (
        <div className="calendar-card-hours">
            <div className="header"><p>Hora</p></div>
            {hours.map(hour => (
                <div className="box-hours">
                    <div className="hour"> <p>{hour}</p> </div>
                </div>
            ))}
        </div>
    )
}
