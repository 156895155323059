import styled from "styled-components";

export const PhoneContainer = styled.div`
  border: 1px solid #fff;

  .custom-phone {
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 1px 2px -1px rgba(130, 136, 148, 0.48);
    padding: 10px 12px;
    border-radius: 8px;

    &.error {
      border: 1px solid #c23f3b;
      background-color: rgba(224, 159, 157, 0.25);
    }

    .PhoneInputCountrySelectArrow {
      display: none;
    }

    .PhoneInputCountryIconImg {
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }

    .PhoneInputCountryIcon--border {
      background-color: initial;
      box-shadow: none;
      width: auto;
    }

    .PhoneInputCountryIcon {
      height: auto;
    }

    .PhoneInputCountry {
      margin-right: 5px;
    }

    input {
      border-radius: 8px;
      background-color: #fff;
      display: block;
      font-size: var(--font-size-body-md);
      line-height: 22px;
      color: var(--color-gray-text);
      text-align: left;
      outline: none;
      appearance: none;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid transparent;
      background: transparent;
    }
  }
`;
