import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .headerCancelled {
    color: #572580;
    margin-top: 25px;
    font-size: 28px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 108px;
      width: 108px;
      margin-bottom: 16px;
    }
  }

  > h3 {
    color: #572580;
    margin: 25px 0 0;
    font-size: 30px;
    text-align: center;
  }

  p {
    margin: auto;

    &.subtitle-modal {
      margin-top: 0.5rem;
      text-align: center;
      margin-bottom: 16px;
      font-size: 16px;
    }
  }

  .buttons {
    display: flex;
    margin-top: 38px;
    justify-content: center;
    align-items: center;

    button {
      border-radius: 126px;
      padding: 16px 24px;
      max-height: 46px;
      display: flex;
      align-items: center;

      &.danger {
        background: transparent;
        color: #572580;
        border: none;
      }
      &:disabled {
        background-color: #ab92bf !important;
        opacity: 0.5;
        color: white;
        cursor: not-allowed;
      }
    }

    button:first-child {
      margin-right: 14px;
    }
  }
`;
