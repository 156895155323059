import { useState } from "react";

import { Button } from "Components/Button";
import { ModalContent } from "Components/Modals/Modal/ModalWrapper";

import anexDocument from "Assets/Images/document-annex.svg";
import downloadIcon from "Assets/Images/download-icon.png";

import * as S from "../ModalDatails/style";

const InfoImage = ({ url, name, type, imagesModal }: any) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesModal.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesModal.length - 1 : prevIndex - 1
    );
  };

  const downloadImage = async (imageUrl: string, name: string) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const element = document.createElement("a");

      element.href = URL.createObjectURL(blob);
      element.download = name;
      element.click();
    } catch (error) {
      console.error("Erro ao fazer o download da imagem:", error);
    }
  };

  const openModal = async () => {
    if(type === 'application/pdf') {
      const convertedFile = await (await fetch(url)).blob();
      if(convertedFile) {
        const url = URL.createObjectURL(convertedFile);
        return window.open(url, '_blank');
      }
    }
    setModalVisible(true)
  }

  return (
    <>
      <S.ContainerImg>
        <div>
          <img
            src={type === "application/pdf" ? anexDocument : url}
            alt={name}
          />
          <span>{name}</span>
        </div>
        <S.ContainerButtons>
          <Button
            onClick={() => openModal()}
            classButton="button _action"
            type="button"
            loading={false}
          >
            Visualizar
          </Button>
          <Button
            onClick={() => downloadImage(url, name)}
            classButton="button _white _action"
            type="button"
            loading={false}
          >
            Baixar
          </Button>
        </S.ContainerButtons>
      </S.ContainerImg>
      {/* @ts-ignore */}
      <ModalContent
        isOpen={modalVisible}
        requestClose={() => setModalVisible(false)}
        notRadius
        maxWidth="80%"
        maxHeight="80%"
      >
        {imagesModal ? (
          <S.Carousel>
            <img
              src={
                type === "application/pdf"
                  ? anexDocument
                  : imagesModal[currentIndex]
              }
              alt={`Imagem ${currentIndex}`}
            />
            <S.ButtonsCarousel>
              <button onClick={goToPrevSlide}>{"<"}</button>
              <button onClick={goToNextSlide}>{">"}</button>
            </S.ButtonsCarousel>
            <S.DownloadButton
              onClick={() => downloadImage(imagesModal[currentIndex], "imagem")}
            >
              <img src={downloadIcon} alt="Baixar" />
            </S.DownloadButton>
          </S.Carousel>
        ) : (
          <img
            src={type === "application/pdf" ? anexDocument : url}
            alt={name}
            style={{ width: "55em", height: '100%'}}
          />
        )}
      </ModalContent>
    </>
  );
};

export default InfoImage;
