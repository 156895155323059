/* eslint-disable import-helpers/order-imports */
import React from "react";
import { Route, Switch } from "react-router-dom";

// Layouts
import NotFound from "Pages/NotFound";

import AuthRoute from "./Bases/Authenticate";
import PublicRoute from "./Bases/Public";
import AuthenticateLayout from "./Layouts/Authenticate";
import PublicLayout from "./Layouts/Public";

// Bases

// Not Found

// Paths
import RoutesAuth from "./Paths/Auth";
import RoutesPublic from "./Paths/Public";

const Routes = () => (
  <Switch>
    {RoutesAuth.map((route) => (
      <AuthRoute key={route.path} {...route} layout={AuthenticateLayout} />
    ))}

    {RoutesPublic.map((route) => (
      <PublicRoute key={route.path} {...route} layout={PublicLayout} />
    ))}

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
