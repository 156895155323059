import { useHistory } from "react-router-dom";

import { Box } from "styles/globalComponents";

import { Button } from "Components/Button";
import { Modal } from "Components/Modals/Modal";

import Success from "Assets/Images/successIcon.svg";

interface MotionBudgetSuccess {
  isOpen: boolean;
  closeModal: () => void;
  budgetId: number | undefined;
}

export const MotionSuccessModal = ({
  closeModal,
  isOpen,
  budgetId,
}: MotionBudgetSuccess) => {
  const history = useHistory();

  const redirectToCreateNewBudget = () =>
    history.push("/ficha-de-atendimento/paciente");

  const redirectToBudgetList = () => history.push("/orcamentos/particular");

  const budgetDetail = () => {
    if (budgetId) {
      closeModal();
      return history.push(`/orcamento/${budgetId}`);
    }
  };

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      renderActions={() => (
        <Box display="flex" direction="column">
          {" "}
          <Button minHeight="48" onClick={redirectToBudgetList} width="full">
            VOLTAR PARA ORÇAMENTOS
          </Button>
          <Button minHeight="48" outlined width="full" onClick={budgetDetail}>
            VER DETALHES DO ORÇAMENTO
          </Button>
          <Button
            minHeight="48"
            textButton
            onClick={redirectToCreateNewBudget}
            width="full"
          >
            CRIAR NOVO ORÇAMENTO
          </Button>
        </Box>
      )}
      renderHeading={() => (
        <h2 style={{ textAlign: "center" }}>
          Seu orçamento foi enviado para o Motion com sucesso
        </h2>
      )}
      renderDescription={() => (
        <p>
          O orçamento enviado foi transformado em uma ficha de no motion. Para
          seguir com o atendimento, localize a ficha no motion utilizando o
          número de documento do cliente.
        </p>
      )}
    />
  );
};
