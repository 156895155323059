import { ReactNode } from "react";

import { Content } from "./styles";

interface ModalIconProps {
  children: ReactNode;
}

export const ModalActions = ({ children }: ModalIconProps) => {
  return (
    <Content display="flex" direction="column">
      {children}
    </Content>
  );
};
