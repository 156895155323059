import moment from "moment";

const FormatCompleteDate = (date) => {
  if (date === null) return null;
  const unformatDate = new Date(date);
  const day =
    unformatDate.getDate() < 10
      ? `0${unformatDate.getDate()}`
      : unformatDate.getDate();
  const month =
    unformatDate.getMonth() + 1 < 10
      ? `0${unformatDate.getMonth() + 1}`
      : unformatDate.getMonth() + 1;
  const year = unformatDate.getFullYear();

  return `${day}/${month}/${year}`;
};

const ConvertDateDBToFront = (date) => {
  const dateArr = date.split("-");

  return `${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`;
};

const GetYearsDiff = (date) => {
  return Math.floor(moment().diff(date, "years", true));
};

const FormatDateWithHours = (date) => {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
};

const FormatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export {
  FormatCompleteDate,
  ConvertDateDBToFront,
  GetYearsDiff,
  FormatDateWithHours,
  FormatDate,
};
