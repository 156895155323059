import { useQuery } from "react-query"
import { Combos } from "Requests/Person";
import { CombosType } from "./types"

const combos: CombosType = {
  countries: [],
  genders: [],
  family_relationships: []
};

export const useCombos = () => {


  const { data = combos, isLoading } = useQuery<CombosType>(
    ["genders", "countries", "family_relationships"],
    () =>
      Combos({
        combos: ["genders", "countries", "family_relationships"],
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  
  if (data.genders && !data.genders.some((item) => item.gender === "Selecione")) {
    data.genders.unshift({ id: 0, gender: "Selecione", sub_gender: "Selecione" });
  }

  if (data.countries && !data.countries.some((item) => item.name === "Selecione")) {
    data.countries.unshift({ id: 0, name: "Selecione" });
  }

  if (data.family_relationships && !data.family_relationships.some((item) => item.label === "Selecione")) {
    data.family_relationships.unshift({ id: 0, label: "Selecione" });
  }
  
  return {
    combos: data,
    isLoading
  }

}