import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .checkbox-list-title {
    font-family: var(--font-display);
    font-size: 14px;
    font-weight: 600;
    display: block;
    line-height: 22px;
    text-transform: uppercase;
    white-space: nowrap;
    color: var(--color-gray-text);
  }

  .checkbox-list-error {
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: var(--color-danger);
    display: block;
  }
`;
