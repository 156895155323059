import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { GetCoupon, CreateCoupon, UpdateCoupon } from "Requests/Coupons";
import {
  FormatCouponToForm,
  FormatCouponToSubmit,
} from "Requests/Formatters/Coupon";

import { useProductCombos } from "Hooks/useProductCombos";

import { initialStateMessages } from "mocks";

import Input from "Components/Form/Input";
import InputCurrency from "Components/Form/InputCurrency";
import InputDate from "Components/Form/InputDate";
import Select from "Components/Form/Select";
import SelectSearch from "Components/Form/SelectSearch";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import PanelContent from "Components/Panels/PanelContent";
import ExamsListIncluded from "Components/Product/ExamsListIncluded";
import Breadcrumb from "Components/Shared/Breadcrumb";
import SwitchInput from "Components/Switch/Input";

const emptyForm = {
  id: {
    error: "",
    value: "",
  },
  name: {
    isRequired: true,
    error: "",
    value: "",
  },
  coupon_code: {
    isRequired: true,
    error: "",
    value: "",
  },
  coupon_description: {
    isRequired: true,
    error: "",
    value: "",
  },
  status: {
    error: "",
    value: true,
  },
  type_discount: {
    error: "",
    value: 1,
  },
  percentage_discount: {
    error: "",
    value: "",
  },
  value_discount: {
    error: "",
    value: "",
  },
  expiration_date: {
    isRequired: true,
    error: "",
    value: moment(),
  },
  minimum_order_value: {
    error: "",
    value: "",
  },
  maximum_order_value: {
    error: "",
    value: "",
  },
  individual_use: {
    error: "",
    value: false,
  },
  exclude_products_on_sale: {
    error: "",
    value: false,
  },
  allowed_products: {
    error: "",
    value: [],
  },
  not_allowed_products: {
    error: "",
    value: [],
  },
  allowed_category: {
    error: "",
    value: undefined,
  },
  not_allowed_category: {
    error: "",
    value: undefined,
  },
};

function ViewCoupon({ match }) {
  const history = useHistory();

  const [objectData, setObjectData] = useState(emptyForm);
  const [loading, setLoading] = useState(false);

  const { combos } = useProductCombos(["categories", "subcategories"]);

  const [messages, setMessages] = useState(initialStateMessages);

  const { subcategories = [], categories = [] } = combos || {};

  const categoriesAndSub = [...categories, ...subcategories];

  const types = categoriesAndSub.map((tip) => ({
    name: tip.category || tip.subcategory,
    id: tip.id,
    ...(tip.category_id && { category: tip.category_id }),
  }));

  const avaliableCategoriesIds = types?.map((type) => type.id);

  const categoriesIds = [...new Set(avaliableCategoriesIds)];

  const selectedCategory =
    objectData?.allowed_category?.value?.map((allowed) => allowed.id) ||
    objectData?.not_allowed_category?.value?.map((notAllowed) => notAllowed.id);

  const categoriesExceptSelected = categoriesIds.filter((cat) => {
    if (selectedCategory) {
      return !selectedCategory.includes(cat);
    }
    return categoriesIds;
  });

  const productSearch = objectData?.allowed_category?.value
    ? { allowed: categoriesExceptSelected, notAllowed: categoriesIds }
    : { allowed: categoriesIds, notAllowed: categoriesExceptSelected };

  async function fetchCouponByID() {
    setLoading(true);

    let _message = {};

    setMessages({
      ...messages,
      wait: "Carregando informações do cupom!",
    });

    const couponResponse = await GetCoupon(match.params.id);

    if (couponResponse.status >= 200 && couponResponse.status <= 299) {
      const couponFormData = FormatCouponToForm(couponResponse.data);
      setObjectData({
        ...objectData,
        ...couponFormData,
      });

      setLoading(false);

      _message = {
        success: "Carregado com sucesso.",
        wait: "",
        error: "",
      };
    } else {
      setLoading(false);

      _message = {
        success: "",
        wait: "",
        error:
          "Ocorreu um erro durante o carregamento das informações do cupom.",
      };
    }

    setMessages({
      ...messages,
      ..._message,
    });

    setTimeout(() => {
      setMessages(initialStateMessages);
    }, 2000);
  }

  function _renderMessages({ success, wait, error }) {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  }

  async function postCoupon(formattedData) {
    const createCouponResponse = await CreateCoupon(formattedData);

    if (createCouponResponse.success) {
      setMessages({
        ...messages,
        error: "",
        wait: "",
        success: "Cupom cadastrado com sucesso!",
      });

      setTimeout(() => {
        history.push("/cupons");
      }, 2000);
    } else {
      setMessages({
        ...messages,
        success: "",
        wait: "",
        error:
          "Oops! Parece que algo deu errado, revise o formulário abaixo...",
      });

      Object.keys(createCouponResponse.errors).forEach((key) => {
        setObjectData({
          ...objectData,
          [key]: {
            ...objectData[key],
            error: createCouponResponse.errors[key],
          },
        });
      });
    }
  }

  async function updateCoupon(formattedData) {
    const updateCouponResponse = await UpdateCoupon(
      match.params.id,
      formattedData
    );

    if (updateCouponResponse.success) {
      setMessages({
        ...messages,
        error: "",
        wait: "",
        success: "Cupom atualizado com sucesso.",
      });

      setTimeout(() => {
        history.push("/cupons");
      }, 2000);
    } else {
      setMessages({
        ...messages,
        success: "",
        wait: "",
        error:
          "Oops! Parece que algo deu errado, revise o formulário abaixo...",
      });

      Object.keys(updateCouponResponse.errors).forEach((key) => {
        setObjectData({
          ...objectData,
          [key]: {
            ...objectData[key],
            error: updateCouponResponse.errors[key],
          },
        });
      });
    }
  }

  function validateFields(obj) {
    let _isValid = true;
    let _errorMessage = "Um ou mais campos obrigatórios não foram preenchidos!";

    let _validatedObject = objectData;

    for (const name in obj) {
      if (obj[name].isRequired) {
        if (Array.isArray(obj[name].value) && obj[name].value.length === 0) {
          _isValid = false;

          _validatedObject = {
            ..._validatedObject,
            [name]: {
              ...objectData[name],
              error: "O campo é obrigatório",
            },
          };
        } else if (
          obj[name].value === "" ||
          obj[name].value === 0 ||
          obj[name].value === null
        ) {
          _isValid = false;

          _validatedObject = {
            ..._validatedObject,
            [name]: {
              ...objectData[name],
              error: "O campo é obrigatório",
            },
          };
        }
      } else if (
        (name === "maximum_order_value" || name === "minimum_order_value") &&
        obj[name].value !== ""
      ) {
        if (
          parseFloat(obj.minimum_order_value.value) >
          parseFloat(obj.maximum_order_value.value)
        ) {
          _isValid = false;

          _errorMessage = "Oops! Foi encontrado um erro no formulário.";

          _validatedObject = {
            ..._validatedObject,
            maximum_order_value: {
              ...objectData.maximum_order_value,
              error: "O valor máximo deve ser maior que o mínimo",
            },
          };
        }
      } else {
        const otherField =
          name === "percentage_discount"
            ? "value_discount"
            : "percentage_discount";

        if (
          (name === "percentage_discount" || name === "value_discount") &&
          obj[name].value === "" &&
          obj[otherField].value === ""
        ) {
          _isValid = false;

          _validatedObject = {
            ..._validatedObject,
            [name]: {
              ...objectData[name],
              error: "Pelo menos uma das formas de desconto é obrigatória",
            },
          };
        }
      }
    }

    setObjectData(_validatedObject);

    if (!_isValid) {
      setMessages({
        ...messages,
        error: _errorMessage,
      });
    }

    return _isValid;
  }

  function removeValidationOnFields(value, name) {
    if (value === "" || value === 0) return;

    setObjectData({
      ...objectData,
      [name]: {
        ...objectData[name],
        error: "",
      },
    });
  }

  function validateBeforePublishing() {
    if (!validateFields(objectData)) return false;

    return true;
  }

  function handleChange({ target: { value, id: name } }) {
    setObjectData({
      ...objectData,
      [name]: {
        ...objectData[name],
        error: "",
        value,
      },
    });

    if (name === "percentage_discount" || name === "value_discount") {
      const otherField =
        name === "percentage_discount"
          ? "value_discount"
          : "percentage_discount";

      setObjectData({
        ...objectData,
        [name]: {
          ...objectData[name],
          error: "",
          value,
        },
        [otherField]: {
          ...objectData[otherField],
          error: "",
        },
      });
    }
  }

  function handleChangeSwitch(value, id) {
    setObjectData({
      ...objectData,
      [id]: {
        ...objectData[id],
        value,
      },
    });
  }

  function handleFocus({ target: { value, id: name } }) {
    removeValidationOnFields(value, name);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!validateBeforePublishing()) return;

    const formattedData = FormatCouponToSubmit(objectData);

    setMessages({
      ...messages,
      error: "",
      wait: "Validando as informações...",
    });

    match.params.id > 0
      ? updateCoupon(formattedData)
      : postCoupon(formattedData);
  }

  function __fieldValueDiscount() {
    let component = (
      <Input
        label="Valor do desconto"
        id="percentage_discount"
        type="number"
        value={objectData.percentage_discount.value}
        error={objectData.percentage_discount.error}
        placeholder="Porcentagem em número"
        onChange={handleChange}
        onFocus={handleFocus}
        description="Valor do cupom em porcentagem (com base no total do orçamento)."
      />
    );

    if (parseInt(objectData.type_discount.value) === 2) {
      component = (
        <InputCurrency
          label="Desconto em valor"
          id="value_discount"
          value={objectData.value_discount.value}
          error={objectData.value_discount.error}
          onChange={handleChange}
          onFocus={handleFocus}
          currencyPrefix="R$"
          description="Valor exato do cupom."
        />
      );
    }

    return component;
  }

  useEffect(() => {
    if (match.params.id && match.params.id > 0) {
      fetchCouponByID();
    } else {
      setObjectData(emptyForm);
    }
  }, [match.params.id]);

  return (
    <PageWrapper className="entry-app-content">
      <form className="content-holder content-holder-no-filter remove-padding">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader
              title={match.params.id > 0 ? "Editar cupom" : "Criar novo"}
            >
              <Breadcrumb
                list={[
                  {
                    path: "/cupons",
                    label: "Cupons",
                    current: false,
                  },
                  {
                    path: "/cupons",
                    label: "Todos os cupons",
                    current: false,
                  },
                  {
                    path: "#",
                    label: match.params.id > 0 ? "Editar cupom" : "Criar cupom",
                    current: true,
                  },
                ]}
              />
            </PageHeader>
            <div className="actions-holder">
              <button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                className="link link-danger"
              >
                Voltar
              </button>
              <button
                onClick={handleSubmit}
                type="submit"
                className="button _action"
              >
                {match.params.id > 0 ? "Editar" : "Criar"}
              </button>
            </div>
          </div>
          {_renderMessages(messages)}
        </div>
        <div className="page-content">
          <div className="row">
            <div className="sm-12">
              <div className="row">
                <div className="sm-12">
                  <PanelContent title="Informações Básicas" loading={loading}>
                    <div className="row">
                      <div className="md-6">
                        <Input
                          label="Nome"
                          id="name"
                          type="text"
                          value={objectData.name.value}
                          error={objectData.name.error}
                          placeholder="Nome"
                          onChange={handleChange}
                          onFocus={handleFocus}
                        />
                      </div>
                      <div className="md-6">
                        <Input
                          label="Código"
                          id="coupon_code"
                          type="text"
                          value={objectData.coupon_code.value}
                          error={objectData.coupon_code.error}
                          placeholder="Código"
                          onChange={handleChange}
                          onFocus={handleFocus}
                        />
                      </div>
                      <div className="md-6">
                        <Input
                          label="Descrição"
                          id="coupon_description"
                          type="text"
                          value={objectData.coupon_description.value}
                          error={objectData.coupon_description.error}
                          placeholder="Descrição"
                          onChange={handleChange}
                          onFocus={handleFocus}
                        />
                      </div>
                      <div className="md-6">
                        <SwitchInput
                          label="Status"
                          id="status"
                          onChange={handleChangeSwitch}
                          value={objectData.status.value}
                          textOn="Ativo"
                          textOff="Inativo"
                        />
                      </div>
                    </div>
                  </PanelContent>
                  <PanelContent
                    title="Dados gerais"
                    classNames="panel-visible"
                    loading={loading}
                  >
                    <div className="row">
                      <div className="md-4">
                        <Select
                          label="Tipo do desconto"
                          id="type_discount"
                          type="text"
                          value={objectData.type_discount.value}
                          options={[
                            {
                              id: 1,
                              name: "Desconto em %",
                            },
                            {
                              id: 2,
                              name: "Desconto em valor fixo",
                            },
                          ]}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="md-4">{__fieldValueDiscount()}</div>
                      <div className="md-4">
                        <InputDate
                          id="expiration_date"
                          onDateChange={(value) => {
                            setObjectData({
                              ...objectData,
                              expiration_date: {
                                ...objectData.expiration_date,
                                error: "",
                                value,
                              },
                            });
                          }}
                          openInModal
                          value={objectData.expiration_date.value}
                          error={objectData.expiration_date.error}
                          label="Data de expiração"
                        />
                      </div>
                    </div>
                  </PanelContent>
                  <PanelContent
                    title="Restrições de uso"
                    classNames="panel-visible"
                    loading={loading}
                  >
                    <div className="row">
                      <div className="md-6 lg-3">
                        <InputCurrency
                          label="Gasto mínimo"
                          id="minimum_order_value"
                          type="number"
                          value={objectData.minimum_order_value.value}
                          error={objectData.minimum_order_value.error}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          currencyPrefix="R$"
                          description="Este campo permite que você defina o valor mínimo no pedido necessário para utilizar o cupom."
                        />
                      </div>
                      <div className="md-6 lg-3">
                        <InputCurrency
                          label="Gasto máximo"
                          id="maximum_order_value"
                          value={objectData.maximum_order_value.value}
                          error={objectData.maximum_order_value.error}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          currencyPrefix="R$"
                          description="Este campo permite que você defina o valor máximo no pedido quando o cupom estiver sendo utilizado."
                        />
                      </div>
                      <div className="md-6 lg-3">
                        <SwitchInput
                          label="Excluir itens em oferta"
                          id="exclude_products_on_sale"
                          onChange={handleChangeSwitch}
                          value={objectData.exclude_products_on_sale.value}
                          textOn="Habilitado"
                          textOff="Desabilitado"
                          description="Marque esta caixa se o cupom não se aplica aos itens em oferta. Cupons por item irão funcionar apenas quando o item não está em oferta. Cupons por carrinho irão funcionar apenas quando não houver itens em oferta no carrinho."
                        />
                      </div>
                      <div className="md-6">
                        <SelectSearch
                          label="Categorias de produto"
                          id="allowed_category"
                          type="text"
                          value={objectData.allowed_category.value}
                          error={objectData.allowed_category.error}
                          isMulti
                          options={types}
                          loading={types.length === 0}
                          readOnly={types.length === 0}
                          placeholder={
                            types.length > 0
                              ? "Qualquer categoria"
                              : "Sem categorias disponíveis"
                          }
                          valueKey="name"
                          labelKey="name"
                          onChange={(value) => {
                            setObjectData({
                              ...objectData,
                              allowed_category: {
                                ...objectData.allowed_category,
                                error: "",
                                value: value || [],
                              },
                            });
                          }}
                          onFocus={handleFocus}
                          description="Categorias de produtos que o cupom será aplicado, ou que precisam estar no orçamento para que o cupom seja aplicado."
                        />
                      </div>

                      <div className={`${"md-6"}`}>
                        <SelectSearch
                          label="Excluir categorias"
                          id="not_allowed_category"
                          type="text"
                          value={objectData.not_allowed_category.value}
                          error={objectData.not_allowed_category.error}
                          isMulti
                          options={types}
                          loading={types.length === 0}
                          readOnly={types.length === 0}
                          placeholder={
                            types.length > 0
                              ? "Nenhuma categoria"
                              : "Sem categorias disponíveis"
                          }
                          valueKey="name"
                          labelKey="name"
                          onChange={(value) => {
                            setObjectData({
                              ...objectData,
                              not_allowed_category: {
                                ...objectData.not_allowed_category,
                                error: "",
                                value: value || [],
                              },
                            });
                          }}
                          onFocus={handleFocus}
                          description="Categorias de produtos que o cupom não será aplicado, ou que não podem estar no orçamento quando o cupom estiver sendo utilizado."
                        />
                      </div>
                     
                        <div className={`${"md-6"}`}>
                          <ExamsListIncluded
                            idKey="id"
                            label="Produtos permitidos"
                            displayCodeKey="product"
                            placeholder="Pesquisar produtos..."
                            fieldName="allowed_products"
                            list={objectData.allowed_products.value}
                            error={objectData.allowed_products.error}
                            clearOptions={() => {
                              setObjectData((prev) => ({
                                ...prev,
                                allowed_products: {
                                  error: "",
                                  value: [],
                                },
                              }));
                            }}
                            manageListExam={(value) =>
                              setObjectData({
                                ...objectData,
                                allowed_products: {
                                  ...objectData.allowed_products,
                                  error: "",
                                  value: value || [],
                                },
                              })
                            }
                            description="Produtos que o cupom será aplicado, ou que precisam estar no orçamento para que o cupom seja aplicado."
                            productType={productSearch.allowed}
                          />
                        </div>
                      

                      <div className={`${"md-6"}`}>
                        <ExamsListIncluded
                          codeKey="code"
                          nameKey="name"
                          displayCodeKey="product"
                          idKey="id"
                          label="Produtos não permitidos"
                          placeholder="Pesquisar produtos..."
                          fieldName="not_allowed_products"
                          list={objectData.not_allowed_products.value}
                          clearOptions={() => {
                            setObjectData((prev) => ({
                              ...prev,
                              not_allowed_products: {
                                error: "",
                                value: [],
                              },
                            }));
                          }}
                          manageListExam={(value) =>
                            setObjectData({
                              ...objectData,
                              not_allowed_products: {
                                ...objectData.not_allowed_products,
                                error: "",
                                value: value || [],
                              },
                            })
                          }
                          productType={productSearch.notAllowed}
                          description="Produtos que o cupom não será aplicado, ou que não podem estar no orçamento quando o cupom estiver sendo utilizado."
                        />
                      </div>
                    </div>
                  </PanelContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageWrapper>
  );
}

export default ViewCoupon;
