/* eslint-disable react/default-props-match-prop-types */
import React, { useState } from "react";

import { createToast } from "Utils/toastFunc";

import AddDocument from "../../../Assets/Images/AddDocument.svg";
import IconPDF from "../../../Assets/Images/icon-pdf.svg";
import ImportDocument from "../../../Assets/Images/ImportDocument.svg";
import trashIcon from "../../../Assets/Images/trashTransparent.svg";
import DropzoneAnnex from "../UploadFiles";
import { CampFile } from "../UploadFiles/styles";
import { UploadAreaProps } from "./interface";
import { ButtonAnnex } from "./styles";

const UploadAreaAnnex: React.FC<UploadAreaProps> = (props) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [successUpload, setSuccessUpload] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [showFields, setShowFields] = useState(true);

  const handleFileRemove = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  function onFilesAdded(newFiles: File[]) {
    const listFiles = files.concat(newFiles);
    setShowFields(true);
    if (props.only && listFiles.length > 1) {
      createToast("error", "Você pode importar somente um arquivo.");
      setFiles([]);
    } else {
      setFiles(listFiles);
    }
  }

  function uploadFiles() {
    if (props?.uploadFile) {
      props.uploadFile(files);
    }
  }

  return (
    <div className="">
      <div className="content">
        <div className="file-info">
          <div style={{ display: showFields ? "block" : "none" }}>
            {files.map((file, index) => {
              const imageUrl = URL.createObjectURL(file);
              const isPdf = file.name.toLowerCase().endsWith(".pdf");
              return (
                <CampFile key={file.name}>
                  <div className="space-file-name">
                    {isPdf ? (
                      <img src={IconPDF} alt="PDF icon" />
                    ) : (
                      <img src={imageUrl} alt="imagem arquivo" />
                    )}
                    <span>{file.name}</span>
                  </div>
                  <button
                    className="button-trash"
                    onClick={() => handleFileRemove(index)}
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img src={trashIcon} alt="remover" />
                  </button>
                </CampFile>
              );
            })}
          </div>
        </div>
        <CampFile className="addAnexo">
          <div className="space-file-name">
            <img className="img-add-page" src={AddDocument} alt="" />
            <p>PÁGINA ADICIONAL</p>
          </div>
          <DropzoneAnnex
            fileAccept={props.fileAccept}
            multi={props.multi}
            onFilesAdded={onFilesAdded}
            disabled={uploading || successUpload}
            elementDescr={
              <div className="button-trash">
                <img src={ImportDocument} alt="" />
              </div>
            }
          />
        </CampFile>
      </div>

      <ButtonAnnex>
        {props.children}

        <button
          className="button radiusButtonBorder"
          disabled={files.length === 0 || uploading}
          onClick={uploadFiles}
        >
          PROSSEGUIR
        </button>
      </ButtonAnnex>
    </div>
  );
};

UploadAreaAnnex.defaultProps = {
  multi: false,
  fileAccept: ".xls, .xlsx, .csv",
};

export default UploadAreaAnnex;
