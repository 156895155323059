import { TableBody } from "../Table/BodyTable";
import { TableHeader } from "../Table/HeaderTable";
import { ListBudgetProps } from "./interface";

export const ListBudgets = ({
  data,
  handleGetBudgetDetail,
}: ListBudgetProps) => {
  return (
    <table className="table-content small-separate details">
      <TableHeader />
      <TableBody
        listBudgets={data}
        handleGetBudgetDetail={handleGetBudgetDetail}
      />
    </table>
  );
};
