import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";

import { Box } from "styles/globalComponents";

import { getUnitsUser } from "Requests/HomeOrder";

import { MaskCPF } from "Utils/Masks";
import validatePermissions from "Utils/validatePermissions";

import SelectUnits from "Components/Form/SelectUnits";

import { sendSubOrderToMotion } from "../../../Requests/Orders";
import { createToast } from "../../../Utils/toastFunc";
import { Container } from "./style";

export function SubOrdersModal({
  modalVisible,
  modalDismiss,
  subOrders,
  userPermissions,
}) {
  const [unitIdMotion, setUnitId] = useState(null);
  const [sendMotionUnit, setSendMotionUnit] = useState(false);

  const [units, setUnits] = useState([]);

  const { home_collected, unit_id } = subOrders;

  async function sendToMotion(id) {
    const orderId = [id];
    const unitId = unitIdMotion;

    try {
      if (!unitIdMotion)
        throw new Error("Selecione uma unidade para integrar ao motion");

      const orderRequest = {
        sub_orders_id: orderId,
        unit_id: unitId,
      };
      const { data } = await sendSubOrderToMotion(orderRequest);
      if (data.result[0].status == "Sucesso") {
        createToast("success", "Pedido enviado com sucesso!");
        setSendMotionUnit(true);
      } else {
        createToast("error", data.result[0].message);
      }
    } catch (error) {
      createToast("error", error.message);
    }
  }

  function _tableList() {
    return (
      <>
        {subOrders.sub_orders?.length &&
          subOrders.sub_orders.map((order) => (
            <tr key={order.order_id + order.id}>
              <td>{order.order_id}</td>
              <td>{order.pacient.name}</td>
              <td>
                {order.pacient.cpf
                  ? MaskCPF(order.pacient.cpf)
                  : order.pacient.passport}
              </td>
              <td>{order.pacient.email}</td>
              <td className="table-action-order">
                {order.requisition_code === null && (
                  <button
                    type="button"
                    className="button _blue _action"
                    onClick={() => sendToMotion(order.id)}
                    disabled={
                      !validatePermissions(
                        "Send Orders to Motion",
                        userPermissions
                      )
                    }
                  >
                    Enviar
                  </button>
                )}

                {order.requisition_code && "Pedido enviado"}
              </td>
              <td>R$ {order.descontos}</td>
              <td>R$ {order.total}</td>
            </tr>
          ))}
      </>
    );
  }

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>
              ID pedido
              <br />
              na origem
            </th>
            <th>Paciente</th>
            <th>Documento</th>
            <th>E-mail</th>
            <th>Integração</th>
            <th>Descontos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>{_tableList()}</tbody>
      </table>
    );
  }

  async function fetchUnits() {
    try {
      const res = await getUnitsUser();
      setUnits(res);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar as unidades!");
    }
  }

  const setUnitIdMotion = (event) => {
    if (event.target.value) {
      const { id } = JSON.parse(event.target.value);

      setUnitId(id);
      return;
    }
    setUnitId(null);
  };

  const [unit] = useMemo(() => {
    return units.filter((unitItem) => unitItem.id === unit_id);
  }, [subOrders, units]);

  useEffect(() => {
    fetchUnits();
  }, []);

  useEffect(() => {
    setSendMotionUnit(false);

    if (subOrders.unit_id) {
      setUnitId(subOrders.unit_id);
    }

    return () => setUnitId(null);
  }, [subOrders, units]);

  return (
    <Modal
      isOpen={modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header"
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      <Container>
        <h3 className="modal-title-suborders">Sub-Pedidos</h3>

        {home_collected && (
          <Box mb="15" className="unit-select">
            <SelectUnits
              placeholder={unit?.name || "Selecione a unidade"}
              onChange={setUnitIdMotion}
              options={units.length >= 1 ? units : []}
              id="Unidades"
              label="SELECIONE A UNIDADE"
              readOnly={sendMotionUnit || !!unit_id}
            />
          </Box>
        )}

        <div className="description-list modal-close-badge-flex modal-table modal-description">
          {_renderTable()}
        </div>
      </Container>
    </Modal>
  );
}
