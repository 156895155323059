import { Modal } from "Components/Modals/Modal";
import Warning from "Assets/Images/atention.svg";
import { Box } from "styles/globalComponents";
import { Button } from "Components/Button";
import { CancelAppointmentModalProps } from "./types";


export const CancelAppointmentModal = ({ isOpen, handleClose }: CancelAppointmentModalProps) => {
  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={() => handleClose()}
      modalIcon={Warning}
      renderHeading={() => (
        <h2>
          <strong>Atenção</strong>
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          O tempo para finalização do pagamento esgotou e a sua pré-reserva foi cancelada. Por favor, faça um novo agendamento.        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column" >
           <Button width="full" outlined onClick={() => handleClose()} minHeight="3.7em" fontWeight="600">
            Fechar
          </Button>
        </Box>
      )}
    />
  )

}