import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";

import { IPerson } from "Dtos/IPerson";
import { IUnit } from "Dtos/IUnit";

import { GetBudgetByID } from "Requests/Budget";
import { getPersonByID } from "Requests/Person";
import { getClinicUnitsByUser } from "Requests/Unit";
import { showUnit } from "Requests/Units";

import { createToast } from "Utils/toastFunc";
import { formatterUnits } from "Utils/Units/Units";

import { ContentPagesWrapper } from "layouts/ContentPagesWrapper/index";

import { Button } from "Components/Button/index";
import { TypedSelect } from "Components/Form/TypedSelect";
import { ModalInformation } from "Components/Modals/ModalInformation/index";
import { ClinicalSchedulesWeek } from "Components/Schedule/ClinicalScheduleWeeks/index";

import AcceptIcon from "Assets/Images/acceptIcon.svg";
import WithoutUnitImage from "Assets/Images/without-unit.svg";

import {
  BoxReception,
  ReceptionButtons,
  SchedulesContainer,
  TitlePageSymptoms,
} from "./styles";

export const ClinicalSchedule = () => {
  const history = useHistory();
  const [units, setUnits] = useState<IUnit[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<IUnit | undefined>();
  const [person, setPerson] = useState<IPerson | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [procedure, setProcedure] = useState(0);

  const budgetRef = useRef<any | null>(null);

  const { order_id } = useParams() as { order_id: string };

  const user_logged = useSelector((state: any) => state.User.userData);
  const cart = useSelector((state: any) => state.service_form?.order?.cart);

  const isTelemedicine = useMemo(() => {
    return (
      cart?.length === 1 &&
      ["CONS-TELE-MED", "RET-CTM"].includes(cart[0].product.sku)
    );
  }, [cart]);

  useEffect(() => {
    (async () => {
      try {
        const allUnits = await getClinicUnitsByUser();

        const budget = (await GetBudgetByID(order_id)) as {
          success: boolean;
          info: any;
        };

        const personId = budget.info.patient.id;

        const data = await getPersonByID(personId);
        budgetRef.current = budget.info.cart;
        const { cart } = budget.info;
        const procedure = cart[0].product.sku.includes("CONS-TELE-MED") ? 9 : 5;

        setProcedure(procedure);

        if (allUnits?.data[3]) {
          const { units: unitsResponse } = formatterUnits(allUnits?.data);

          setUnits(unitsResponse);
        }

        setPerson(data);
      } catch {
        createToast(
          "error",
          "Não foi possivel buscar as unidades. Tente novamente mais tarde"
        );
      }
    })();
  }, []);

  function getMedialProcedure() {
    if (budgetRef.current) {
      const medialProcedure =
        budgetRef.current[0].product.sku.includes("CONS-TELE-MED");
      return medialProcedure ? 9 : 5;
    }
  }

  const handleToggleModal = async () => {
    setModalVisible(!modalVisible);
  };

  const handleSubmit = async () => {
    if (!id) return;

    const payload = {
      hour: id,
      document: person?.documents?.length ? person?.documents[0].number : "",
      name: person?.full_name,
      birthdate: person?.birthdate,
      biological_sex: person?.gender?.gender,
      phone: person?.phones.length
        ? person?.phones[0]?.ddd?.toString() +
          person?.phones[0]?.phone?.toString()
        : "",
      email: person?.emails.length ? person?.emails[0].email : "",
      person_id: person?.id.toString(),
      order_id: order_id.toString(),
      zip_code: "",
      country: "",
      city: "",
      neighborhood: "",
      street: "",
      number: "",
      complement: "",
      state: "",
      user_id: user_logged.id,
      professional_code: selectedUnit?.professional_code,
      procedure: getMedialProcedure(),
      is_reserve: false,
    };

    try {
      localStorage.setItem("@schedulePayload", JSON.stringify(payload));
      localStorage.setItem(
        "@scheduleUnitId",
        JSON.stringify(isTelemedicine ? { id: "tele-medicine" } : selectedUnit)
      );
    } catch {
      return;
    }

    return handleToggleModal();
  };

  const handleBackButton = () => {
    history.goBack();
  };

  function handleChangeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;

    if (value) {
      (async () => {
        const response = await showUnit(value);

        if (!response?.data?.professional_code) {
          return createToast(
            "error",
            "Está unidade não tem um código de profissional"
          );
        }

        setSelectedUnit(response.data);
      })();
    } else {
      setSelectedUnit(undefined);
    }
  }

  return (
    <ContentPagesWrapper
      titleHeader="Agendamento"
      resizePaddignBottom
      listBreadcrumb={[
        {
          path: "/orcamentos",
          label: "Orçamentos",
          current: false,
        },
        {
          path: "/ficha-de-atendimento/paciente",
          label: "Criar novo",
          current: false,
        },
        {
          path: "/orcamento/pre-acolhimento",
          label: "Agendamento",
          current: true,
        },
      ]}
      ContentWrapperHeader={() => (
        <Link to="/orcamentos" className="button _white _transparentButton">
          Todos os Orçamentos
        </Link>
      )}
      ContentPageHeader={() => (
        <TitlePageSymptoms>Horários e Unidades</TitlePageSymptoms>
      )}
    >
      <BoxReception>
        <ModalInformation
          modalVisible={modalVisible}
          modalDismiss={handleToggleModal}
          title="Agendamento finalizado!"
          icon={AcceptIcon}
          message="Pronto, agora você deve seguir para o pagamento. "
          messageButton="PROSSEGUIR PARA O PAGAMENTO"
          onClick={() => {
            history.push(
              `/ficha-de-atendimento/clinicas/pagamento/${order_id}`
            );
          }}
          cancelButton={false}
          borderButton={false}
          children={undefined}
        />

        <SchedulesContainer>
          <div className="header">
            <h2>Selecione entre os horários disponíveis:</h2>

            {!isTelemedicine && (
              <TypedSelect
                onChange={handleChangeSelect}
                placeholder="Selecione a unidade aqui"
                options={
                  units?.map((unit) => ({
                    key: unit.unit_id.toString(),
                    value: unit.name,
                  })) ?? []
                }
                fullSize
              />
            )}
          </div>

          {selectedUnit?.professional_code || (isTelemedicine && procedure) ? (
            <ClinicalSchedulesWeek
              code={
                isTelemedicine
                  ? "tele-medicine"
                  : selectedUnit?.professional_code
              }
              setId={setId}
              procedure={procedure}
              return_released={false}
            />
          ) : (
            <div className="no-schedules">
              <img src={WithoutUnitImage} alt="No one unit selected" />

              {isTelemedicine ? (
                <p>
                  Aguarde enquanto carregamos os horários para <br /> sua &nbsp;
                  <span>consulta de telemedicina.</span>
                </p>
              ) : (
                <p>
                  Para exibir os horários, selecione uma unidade <br /> no
                  &nbsp;
                  <span>canto superior direito.</span>
                </p>
              )}
            </div>
          )}
        </SchedulesContainer>

        <ReceptionButtons>
          <Button
            classButton="_white radiusButton"
            loading={false}
            onClick={handleBackButton}
          >
            Voltar
          </Button>
          <Button
            classButton="radiusButton"
            disabled={!id}
            loading={false}
            onClick={id && handleSubmit}
          >
            Avançar
          </Button>
        </ReceptionButtons>
      </BoxReception>
    </ContentPagesWrapper>
  );
};
