import styled from "styled-components";

export const Container = styled.div`
  margin-top: 15px;

  .sub-subcategories {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    &.checkup {
      .parent-checkbox {
        width: 100%;
      }
    }

    .parent-checkbox {
      padding: 0;
      width: 150px;
      margin: 0;

      li {
        list-style: none;
      }

      .child-checkbox {
        padding-left: 20px;

        li {
          list-style: none;
        }
      }
    }
  }

  @media (min-width: 1140px) {
    .sub-subcategories {
      max-height: 750px;
      flex-wrap: wrap;

      &.checkup {
        ul {
          max-width: initial;
        }
      }

      ul {
        max-width: 200px;
      }
    }
  }
`;
