/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from "react";
import { CgClose } from "react-icons/cg";

import { Field, FieldArray } from "formik";
import {
  EmailsProps,
  PhonesPersonProps,
} from "Pages/ServiceForm/SelectPacient/interface";

import { DeleteCredencialPerson, DeleteImgCovenant } from "Requests/Person";
import { getInsuranceId } from "Requests/RequestInsurance";

import { Uf } from "Utils/LocalTypes";

import { CardUploadCovenant } from "Components/Cards/CardUploadCovenant";
import CheckboxControlled from "Components/Form/Controlled/CheckBoxControlled";
import InputControlled from "Components/Form/Controlled/Input";
import SelectControlled from "Components/Form/Controlled/SelectControlled";
import Select from "Components/Form/Select";
import PanelContent from "Components/Panels/PanelContent";

import backCard from "Assets/Images/back-card.png";
import frontCard from "Assets/Images/front-card.png";
import trashIcon from "Assets/Images/trashIcon.png";

import { Person } from "../interface";
import { PatientProps } from "./interface";
import {
  AddButton,
  ButtonClose,
  ButtonDelete,
  ContainerInfos,
  LabelDocuments,
} from "./styles";

function Patient({
  comboTypes,
  objectData,
  setFieldValue,
  errors,
}: PatientProps) {
  const [loading, setLoading] = useState(false);

  const handleRemoveItemArray = (
    field: keyof Person,
    index: number,
    id: number
  ) => {
    const dataField = objectData[field];
    // @ts-ignore
    const deleteDataField = objectData[`remove_${field}`] || [];
    // @ts-ignore
    dataField.splice(index, 1);

    deleteDataField.push(id);
    setFieldValue(`remove_${field}`, deleteDataField);
    setFieldValue(field, dataField);
  };
  const disabledCheckbox = (array: EmailsProps[] | PhonesPersonProps[]) => {
    return array.length === 1;
  };

  const customOnChange = (name: keyof Person, fieldIndex: number) => {
    const fields = objectData[name];
    // @ts-ignore
    const newFields = fields?.map((field, index) => {
      if (index === fieldIndex) {
        return { ...field, default: true };
      }
      return { ...field, default: false };
    });

    setFieldValue(name, newFields);
  };

  const handleGetPlans = async (value: number, index: number) => {
    setLoading(true);
    setFieldValue(`covenants_credentials.${index}.covenant_plan_id`, "");
    setFieldValue(`covenants_credentials.${index}.covenant_id`, value);

    if (value) {
      const response = await getInsuranceId(value);
      if (response?.covenant_plans) {
        setFieldValue(`covenants_credentials.${index}.comboPlans`, [
          { id: "", covenant_plans_name: "Selecione" },
          ...response.covenant_plans,
        ]);
      }
    } else {
      setFieldValue(`covenants_credentials.${index}.comboPlans`, [
        { id: "", covenant_plans_name: "Selecione" },
      ]);
    }

    setLoading(false);
  };

  const handleDeleteImage = async (
    name: string,
    value: any,
    id: number,
    typeImg: string
  ) => {
    let success = true;
    if (value.deleteApi) {
      setLoading(true);
      success = await DeleteImgCovenant(objectData.id, id, typeImg);
      setLoading(false);
    }

    if (success) setFieldValue(name, null);
  };

  const onDeleteCredencial = async (
    remove: any,
    index: number,
    idCredencial: number
  ) => {
    let sucess = true;
    if (idCredencial) {
      setLoading(true);
      sucess = await DeleteCredencialPerson(objectData?.id, idCredencial);
      setLoading(false);
    }

    if (sucess) remove(index);
  };

  return (
    <>
      <div className="row">
        <div className="md-6">
          {/* @ts-ignore */}
          <PanelContent loading={loading} title="Informações básicas">
            <div className="row">
              <div className="sm-12">
                <Field
                  name="full_name"
                  type="text"
                  label="Nome Completo*"
                  placeholder="Nome"
                  className="input _opacity-gray"
                  component={InputControlled}
                />
              </div>

              <div className="md-6  sm-6">
                <Field
                  name="social_name"
                  type="text"
                  label="Nome social"
                  placeholder="Nome social"
                  className="input _opacity-gray"
                  component={InputControlled}
                />
              </div>

              <div className="md-6 lg-6 sm-6">
                <Field
                  name="mother_name"
                  type="text"
                  placeholder="Nome da mãe"
                  label="Nome da mãe"
                  className="input _opacity-gray"
                  component={InputControlled}
                />
              </div>

              <div className="sm-12">
                <Field
                  name="unborn"
                  descriptionText="Não nascido"
                  component={CheckboxControlled}
                />
              </div>

              <div className="md-6  sm-6">
                <Field
                  name="birthdate"
                  type="text"
                  label="Data de Nascimento*"
                  placeholder="DD/MM/AAAA"
                  mask="99/99/9999"
                  className="input _opacity-gray"
                  component={InputControlled}
                />
              </div>

              <div className="md-6 lg-6 sm-6">
                <Field
                  name="gender_id"
                  type="text"
                  placeholder="Sexo"
                  label="Sexo*"
                  options={comboTypes?.genders}
                  optionName="sub_gender"
                  component={SelectControlled}
                />
              </div>

              {[2, 4, 5].includes(Number(objectData?.gender_id)) && (
                <div className="md-12 lg-6 sm-12">
                  <Field
                    name="social_name"
                    type="text"
                    className="input _opacity-gray"
                    label="Nome Social*"
                    placeholder="Nome"
                    component={InputControlled}
                  />
                </div>
              )}
            </div>

            <div className="divider-panel" />
            <div className="row">
              <div className="sm-12">
                <label className="label-section-title">Documentos</label>
              </div>

              {!!objectData.documents.length &&
                objectData.documents.map((item, i) => (
                  <Fragment key={i}>
                    {item.type === "cpf" && (
                      <div className="md-4 sm-4">
                        <Field
                          name={`documents[${i}].number`}
                          type="text"
                          label={item.type === "cpf" ? "CPF" : "PASSAPORTE"}
                          placeholder={
                            item.type === "cpf"
                              ? "XXX.XXX.XXX-XX"
                              : "Passaporte"
                          }
                          mask={item.type === "cpf" ? "999.999.999-99" : false}
                          className="input _opacity-gray"
                          component={InputControlled}
                        />
                      </div>
                    )}

                    {item.type === "passport" && (
                      <>
                        <div className="md-4 sm-4">
                          <Field
                            name={`documents[${i}].number`}
                            type="text"
                            label="Passaporte"
                            placeholder=""
                            className="input _opacity-gray"
                            component={InputControlled}
                          />
                        </div>

                        <div className="md-4">
                          <Field
                            name={`documents[${i}].country_id`}
                            type="text"
                            placeholder="-"
                            options={comboTypes?.countries}
                            label="País Emissor"
                            optionName="name"
                            component={SelectControlled}
                          />
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
            </div>

            <div className="divider-panel" />

            <div className="row">
              <div className="sm-12">
                <label className="label-section-title">Formas de contato</label>
              </div>

              {!!objectData.phones.length &&
                objectData.phones.map((item, i) => (
                  <div className="sm-12 row" key={i}>
                    <div className="md-2 sm-4">
                      <Field
                        name={`phones[${i}].ddi`}
                        type="text"
                        label="DDI"
                        placeholder="DDI"
                        mask="+99"
                        className="input _opacity-gray"
                        component={InputControlled}
                      />
                    </div>
                    <div className="md-2 sm-4">
                      <Field
                        name={`phones[${i}].ddd`}
                        type="text"
                        label="DDD"
                        placeholder="DDD"
                        mask="(99)"
                        className="input _opacity-gray"
                        component={InputControlled}
                      />
                    </div>
                    <div className="md-4">
                      <Field
                        name={`phones[${i}].phone`}
                        type="text"
                        label="NÚMERO"
                        placeholder="Número"
                        mask={
                          JSON.stringify(item?.phone)?.length < 9
                            ? "9999-9999"
                            : "99999-9999"
                        }
                        component={InputControlled}
                        className="input _opacity-gray"
                      />
                    </div>
                    <div className="md-2">
                      <Field
                        name={`phones[${i}].default`}
                        label="Padrão"
                        onChange={() => {
                          if (objectData.phones.length > 1) {
                            customOnChange("phones", i);
                            return;
                          }

                          setFieldValue(
                            `phones[${i}].default`,
                            !objectData.phones[i].default
                          );
                        }}
                        component={CheckboxControlled}
                        readOnly={disabledCheckbox(objectData.phones)}
                      />
                    </div>
                    {objectData.phones.length > 1 && !item?.default &&(
                       <ButtonDelete className="md-2 margin">
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveItemArray(
                              "phones",
                              i,
                              item.id as number
                            )
                          }
                        >
                          <img src={trashIcon} alt="Excluir" />
                        </button>
                      </ButtonDelete>
                    )}
                  </div>
                ))}

              <div className="xs-12 md-12">
                {!!objectData?.emails?.length &&
                  objectData.emails.map((item, i) => (
                    <div className="sm-12 row" key={i}>
                      <div className="md-8">
                        <Field
                          name={`emails[${i}].email`}
                          type="text"
                          label="EMAIL"
                          placeholder="exemplo@email.com"
                          component={InputControlled}
                          className="input _opacity-gray"
                        />
                      </div>
                      <div className="md-2">
                        <Field
                          component={CheckboxControlled}
                          name={`emails[${i}].default`}
                          label="Padrão"
                          readOnly={disabledCheckbox(objectData.emails)}
                          onChange={() => {
                            if (objectData.emails.length > 1) {
                              customOnChange("emails", i);
                              return;
                            }

                            setFieldValue(
                              `emails[${i}].default`,
                              !objectData.emails[i].default
                            );
                          }}
                        />
                      </div>
                      {objectData.emails.length > 1 && !item.default && (
                        <ButtonDelete className="md-2 margin">
                          <button
                            type="button"
                            onClick={() =>
                              handleRemoveItemArray(
                                "emails",
                                i,
                                item.id as number
                              )
                            }
                          >
                            <img src={trashIcon} alt="Excluir" />
                          </button>
                        </ButtonDelete>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </PanelContent>
        </div>

        <div className="md-6">
          {objectData.addresses.map((item, i) => (
            <div className="md-12">
              {/* @ts-ignore */}
              <PanelContent loading={loading} title="Informações de Endereço">
                <div className="sm-12 row" key={i}>
                  <div className="md-4 lg-3 sm-4">
                    <Field
                      name={`addresses[${i}].zip_code`}
                      type="text"
                      className="input _opacity-gray"
                      label="CEP"
                      placeholder="00000-000"
                      mask="99999-999"
                      component={InputControlled}
                    />
                  </div>

                  <div className="md-3 lg-2 sm-4">
                    <Field
                      name={`addresses[${i}].type_id`}
                      type="number"
                      placeholder="Selecione"
                      options={comboTypes?.address_types}
                      label="TIPO"
                      optionName="type"
                      component={SelectControlled}
                    />
                  </div>

                  <div className="md-4 sm-4">
                    <Field
                      name={`addresses[${i}].street`}
                      type="text"
                      label="Rua"
                      placeholder="Rua"
                      className="input _opacity-gray"
                      component={InputControlled}
                    />
                  </div>

                  <div className="md-3 sm-2">
                    <Field
                      name={`addresses[${i}].number`}
                      type="text"
                      label="NÚMERO"
                      placeholder="Número"
                      component={InputControlled}
                      className="input _opacity-gray"
                    />
                  </div>

                  <div className="md-6 sm-5">
                    <Field
                      name={`addresses[${i}].complement`}
                      type="text"
                      label="Complemento"
                      placeholder="Complemento"
                      component={InputControlled}
                      className="input _opacity-gray"
                    />
                  </div>

                  <div className="md-6 sm-5">
                    <Field
                      name={`addresses[${i}].neighborhood`}
                      type="text"
                      label="Bairro"
                      placeholder="Bairro"
                      component={InputControlled}
                      className="input _opacity-gray"
                    />
                  </div>

                  <div className="md-4">
                    <Field
                      name={`addresses[${i}].uf`}
                      type="text"
                      placeholder="Selecione"
                      options={Uf}
                      label="Uf"
                      optionName="name"
                      component={SelectControlled}
                    />
                  </div>

                  <div className="md-4">
                    <Field
                      name={`addresses[${i}].country_id`}
                      type="text"
                      placeholder="Selecione"
                      options={comboTypes?.countries}
                      label="País"
                      optionName="name"
                      component={SelectControlled}
                    />
                  </div>

                  <div className="md-4">
                    <Field
                      name={`addresses[${i}].city`}
                      type="text"
                      label="Cidade"
                      placeholder="Cidade"
                      className="input _opacity-gray"
                      component={InputControlled}
                    />
                  </div>

                  <div className="md-12 row">
                    <Field
                      name={`addresses[${i}].default`}
                      label="Padrão"
                      component={CheckboxControlled}
                      onChange={() => {
                        if (objectData.addresses.length > 1) {
                          customOnChange("addresses", i);
                          return;
                        }

                        setFieldValue(
                          `addresses[${i}].default`,
                          !objectData.addresses[i].default
                        );
                      }}
                    />

                  {objectData.addresses.length > 1 && !item.default && (
                    <ButtonDelete>
                      <button
                        type="button"
                        onClick={() =>
                          handleRemoveItemArray(
                            "addresses",
                            i,
                            item.id as number
                          )
                        }
                      >
                        <img src={trashIcon} alt="Excluir" />
                      </button>
                    </ButtonDelete>
                  )}
                  </div>
                </div>
              </PanelContent>
            </div>
          ))}
        </div>

        <div className="md-12">
          <PanelContent loading={loading} expandable title="Convênios">
            <FieldArray
              name="covenants_credentials"
              render={({ remove }) => {
                return objectData?.covenants_credentials.map(
                  (itemCovenant: any, index: any) => (
                    <ContainerInfos key={index} className="row">
                      <div className="md-4">
                        <Field
                          name={`covenants_credentials.${index}.covenant_id`}
                          type="text"
                          label="Convênio"
                          options={comboTypes?.covenants}
                          value={itemCovenant.covenant_id}
                          optionName="name"
                          error={
                            errors?.covenants_credentials &&
                            errors?.covenants_credentials[index]?.covenant_id
                          }
                          component={Select}
                          onChange={(e: any) =>
                            handleGetPlans(e.target.value, index)
                          }
                        />
                      </div>
                      <div className="md-4">
                        <Field
                          name={`covenants_credentials.${index}.covenant_plan_id`}
                          type="text"
                          label="Plano"
                          options={itemCovenant?.comboPlans}
                          optionName="covenant_plans_name"
                          readOnly={!itemCovenant.covenant_id}
                          component={SelectControlled}
                        />
                      </div>
                      <div className="md-4">
                        <Field
                          name={`covenants_credentials.${index}.credential_number`}
                          type="number"
                          label="Carteirinha"
                          component={InputControlled}
                          className="input _opacity-gray"
                        />
                      </div>
                      <div className="md-12 mt-1">
                        <LabelDocuments>Foto dos documentos:</LabelDocuments>
                      </div>
                      <div className="md-4">
                        <CardUploadCovenant
                          initialImgCard={frontCard}
                          accept="image/*"
                          value={itemCovenant.frontCard}
                          name={`covenants_credentials.${index}.frontCard`}
                          label="FRENTE DA CARTEIRINHA"
                          onDelete={(name: string, value: any) =>
                            handleDeleteImage(
                              name,
                              value,
                              itemCovenant?.id,
                              "front"
                            )
                          }
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            file.new = true;
                            setFieldValue(
                              `covenants_credentials.${index}.frontCard`,
                              file
                            );
                          }}
                        />
                      </div>
                      <div className="md-4">
                        <CardUploadCovenant
                          initialImgCard={backCard}
                          accept="image/*"
                          value={itemCovenant.backCard}
                          name={`covenants_credentials.${index}.backCard`}
                          label="VERSO DA CARTEIRINHA"
                          onDelete={(name: string, value: any) =>
                            handleDeleteImage(
                              name,
                              value,
                              itemCovenant?.id,
                              "back"
                            )
                          }
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            file.new = true;
                            setFieldValue(
                              `covenants_credentials.${index}.backCard`,
                              file
                            );
                          }}
                        />
                      </div>
                      <ButtonClose
                        type="button"
                        onClick={() =>
                          onDeleteCredencial(remove, index, itemCovenant?.id)
                        }
                      >
                        <CgClose color="#572580" />
                      </ButtonClose>
                    </ContainerInfos>
                  )
                );
              }}
            />
            <AddButton
              type="button"
              onClick={() => {
                objectData?.covenants_credentials.push({
                  covenant_id: "",
                  covenant_plan_id: "",
                  credential_number: "",
                  comboPlans: [],
                });
                setFieldValue(
                  "covenants_credentials",
                  objectData?.covenants_credentials
                );
              }}
            >
              + NOVO CONVÊNIO
            </AddButton>
          </PanelContent>
        </div>
      </div>
    </>
  );
}

export default Patient;
